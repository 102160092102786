import React, { createRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Button, { buttonClasses, ButtonProps } from '@mui/material/Button';

import { PrompterViewportProps } from '..';

import AppConfig from '../../../utils/AppConfig';
import { useAppController } from '../../../controllers/AppController';
import { Trans, useTranslation } from 'react-i18next';
import useApplicationContext from '../../../hooks/useApplicationContext';
import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import useResizeObserver from '@react-hook/resize-observer';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import './WordLimitNotice.scss';

const WordLimitButton = styled(Button)({
  maxWidth: '575px',
  color: 'inherit',
  backgroundColor: 'rgba(255, 255, 255, 0.08)',
  flex: 1,

  // Our color transition styles will be on the container, this button has `color: inherit;`
  transition: 'transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

  lineHeight: 1.5,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    transform: 'scaleX(1.05) scaleY(1.05)',
  },
  '.WordLimitButtonLayout': {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'flex-start',
    alignItems: 'center',
  }
});

const LinkButton = styled(Button)({
  color: 'inherit',
  background: 'none',
  margin: '0 auto',
  fontSize: '1.2rem',
  height: '1em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: 1.5,

  // Our color transition styles will be on the container, this button has `color: inherit;`
  transition: 'none',

  '&:hover': {
    background: 'none',
    textDecoration: 'underline',
  },
});

// interface WordLimitNoticeProps
//   extends PrompterViewportProps
// {
//   //
// }

const WordLimitNotice = (props: PrompterViewportProps) => {
  const { flipHorizontal, flipVertical, contentWidth, leftGutter, textSize } = props;

  const { WORDLIMIT_SIGNEDOUT_USER, WORDLIMIT_FREE_USER } = AppConfig.current;

  const {
    appContext,
  } = useApplicationContext();
  const { internetOnline, isLoading, isAuthenticated, userProfile } = appContext;
  const fluidprompter_plan = userProfile?.fluidprompter_plan;

  const { t } = useTranslation('prompter');

  const prompterSession = usePrompterSession(state => ({
    isEditing: state.isEditing,
    wordLimit: state.wordLimit,
    textMetrics: state.textMetrics,
    getNodeTextMetricsByPath: state.getNodeTextMetricsByPath,
    scriptNodesMeta: state.scriptNodesMeta,
    // f: state.setScriptNodesMeta
    getNodeMetaByPath: state.getNodeMetaByPath,
    isAtEndOfScript: state.isAtEndOfScript,
  }), shallow);

  const { wordLimit, scriptNodesMeta, getNodeMetaByPath, textMetrics, isAtEndOfScript } = prompterSession;

  const wordLimitEnabled = wordLimit !== undefined
    && (
      !isAuthenticated
        || !userProfile?.fluidprompter_plan
        || userProfile.fluidprompter_plan === 'free'
    );

  const { wordLimitNodePath } = textMetrics;

  const appController = useAppController();

  const [wordThresholdMaskTop, setWordThresholdMaskTop] = useState<number>(0);
  //
  // This will be undefined if we have no wordLimit node detected either because we have no word
  // limit set, or because we have no node that exceeds the set word limit.
  //
  if(wordLimitNodePath) {
    //
    // This will return undefined if the nodePath requested is not available in the current node
    // tree. This could potentially happen as a matter of latency when you are editing the script
    // and we re-rending a prompter element before we've had a change to recalculate the script
    // nodes meta information.
    //
    const wordLimitNodeMeta = getNodeMetaByPath(wordLimitNodePath);
    if(wordLimitNodeMeta && wordThresholdMaskTop !== wordLimitNodeMeta.top) {
      setWordThresholdMaskTop(wordLimitNodeMeta.top);
    }
  }

  let endElementTop = 0;
  if(scriptNodesMeta) {
    // prompterSession.scriptNodesMeta.contentHeight
    const { contentHeight, nodesMeta } = scriptNodesMeta;
    const rootNodesCount = nodesMeta.length;
    const lastRootNodeMeta = nodesMeta[rootNodesCount - 1];
    endElementTop = contentHeight - lastRootNodeMeta.top;
  }

  const wordLimitRef = createRef<HTMLDivElement>();
  const cuePositionOffset = scriptNodesMeta?.cuePositionOffset || 0;
  const viewportHeight = scriptNodesMeta?.viewportHeight || 0;
  const [stickyTop, setStickyTop] = useState<number>(cuePositionOffset);
  const [containerBreakpoint, setContainerBreakpoint] = useState<string>('xs');

  //
  // Whenever the WordLimit container is resized, we need to re-evaluate our container breakpoint
  // based on the information in the current language translation.
  //
  const handleWordLimitResized = useCallback(async (e?: ResizeObserverEntry) => {
    const wordLimitEl = wordLimitRef.current;
    if(!wordLimitEl) {
      return;
    }

    // Allow localizations to change the responsive breakpoint
    let CSS_BREAKPOINT_SM = 420;
    let CSS_BREAKPOINT_MD = 768;
    try {
      CSS_BREAKPOINT_SM = Number.parseInt(t('wordlimit.CSS_BREAKPOINT_SM'), 10);
    } catch(err) {
      // If the config value is not a string or an unparseable string...
    }
    try {
      CSS_BREAKPOINT_MD = Number.parseInt(t('wordlimit.css_breakpoint_md'), 10);
    } catch(err) {
      // If the config value is not a string or an unparseable string...
    }
    let breakpoint = 'xs';
    if(wordLimitEl.clientWidth >= CSS_BREAKPOINT_MD) {
      breakpoint = 'md';
    } else if (wordLimitEl.clientWidth >= CSS_BREAKPOINT_SM) {
      breakpoint = 'sm';
    }
    setContainerBreakpoint(breakpoint);

    const adjustedCuePosition = flipVertical
      ? viewportHeight - cuePositionOffset
      : cuePositionOffset;
    setStickyTop(adjustedCuePosition - Math.floor(wordLimitEl.clientHeight / 2));
  }, [wordLimitRef, t, setContainerBreakpoint, flipVertical, viewportHeight, cuePositionOffset, setStickyTop]);
  useResizeObserver(wordLimitRef, handleWordLimitResized);

  //
  // When the user toggles screen flip, the element will not "resize" but still needs its position
  // recalculated.
  //
  useEffect(() => {
    handleWordLimitResized();
  }, [handleWordLimitResized, flipVertical]);


  //
  // If we don't yet have an endElementTop/wordThresholdMaskTop value - we haven't yet had a chance
  // to measure our script content (prompterSession.scriptNodesMeta is undefined).
  //
  if(endElementTop === 0 || wordThresholdMaskTop === 0) {
    return (<></>);
  }

  //
  // If we don't need a word limit notice because we are signed-in and/or don't exceed the word
  // limit, then let's just return an empty element.
  //
  if(!wordLimitEnabled || !wordLimitNodePath) {
    return (<></>);
  }

  return (<div
    className={classNames('WordLimitNoticeContainer', {
      FlipHorizontal: flipHorizontal,
      FlipVertical: flipVertical,
    })}
    style={{
      top: flipVertical ? endElementTop : wordThresholdMaskTop,
      bottom: flipVertical ? wordThresholdMaskTop : endElementTop,
      width: `${contentWidth}%`,
      marginLeft: `${leftGutter}%`,
      fontSize: `${textSize}pt`,
    }}
  >
    <div
      ref={wordLimitRef}
      className={classNames('WordLimitNotice', {
        isAtEndOfScript,
      })}
      style={{
        top: `${stickyTop}px`,
        margin: flipVertical ? '1em 1em 0 1em' : '0 1em 1em 1em',
        paddingBottom: fluidprompter_plan === 'free' ? '1rem' : undefined,
      }}
    >
      <p
        className='NoticeTitle'
        style={{
          margin: 0,
          height: t(`wordlimit.title_cssheight_${containerBreakpoint}`),
          fontSize: t(`wordlimit.title_fontsize_${containerBreakpoint}`),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {fluidprompter_plan === undefined && t('wordlimit.title_signedout', { wordlimit: WORDLIMIT_SIGNEDOUT_USER })}
        {fluidprompter_plan === 'free' && t('wordlimit.title_freeuser', { wordlimit: WORDLIMIT_FREE_USER })}
      </p>
      <div className='WordLimitNoticeButtonContainer'>
        {fluidprompter_plan === undefined && <WordLimitButton
          onClick={() => {
            appController.dispatchMessage('prompter.local.signup');
          }}
        >
          <div className='WordLimitButtonLayout'>
            <span style={{
              fontSize: t(`wordlimit.cta_fontsize_eyebrow_${containerBreakpoint}`),
            }}>
              <Trans
                ns='prompter'
                i18nKey="wordlimit.cta_freesignup_eyebrow"
                values={{ wordlimit: WORDLIMIT_FREE_USER }}
              />
            </span>
            <span style={{
              fontSize: t(`wordlimit.cta_fontsize_caption_${containerBreakpoint}`),
            }}>
              <Trans
                ns='prompter'
                i18nKey="wordlimit.cta_freesignup_caption"
                components={{ bold: <strong />, italic: <i />, underline: <u /> }}
              />
            </span>
          </div>
        </WordLimitButton>}
        <WordLimitButton
          onClick={() => {

            // Unregistered/unauthetnicated user
            if(fluidprompter_plan === undefined) {
              appController.dispatchMessage('prompter.local.signup');
              return;
            }

            // Authenticated free user - go to Stripe upgrade flow.
            appController.dispatchMessage('prompter.local.upgrade');

          }}
        >
          <div className='WordLimitButtonLayout'>
            <span style={{
              fontSize: t(`wordlimit.cta_fontsize_eyebrow_${containerBreakpoint}`),
            }}>
              <Trans
                ns='prompter'
                i18nKey="wordlimit.cta_paidsignup_eyebrow"
                components={{ bold: <strong />, italic: <i />, underline: <u /> }}
              />
            </span>
            <span style={{
              fontSize: t(`wordlimit.cta_fontsize_caption_${containerBreakpoint}`),
            }}>
              <Trans
                ns='prompter'
                i18nKey="wordlimit.cta_paidsignup_caption"
              />
            </span>
          </div>
        </WordLimitButton>
      </div>
      {fluidprompter_plan === undefined && <LinkButton
        className='ExistingSignin'
        disableRipple={true}
        style={{
          height: t(`wordlimit.title_cssheight_${containerBreakpoint}`),
          fontSize: t(`wordlimit.title_fontsize_${containerBreakpoint}`),
        }}
        onClick={() => {
          appController.dispatchMessage('prompter.local.login');
        }}
      >{t('wordlimit.cta_signin')}</LinkButton>}
    </div>
  </div>);
};

export default WordLimitNotice;