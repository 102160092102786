import React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import AccountPanelOutlined from '../../AccountPanelOutlined/AccountPanelOutlined';

import useApplicationState from '../../../../state/ApplicationState';
import { shallow } from 'zustand/shallow';

const ProPlanPanel = () => {
  const appState = useApplicationState(state => ({
    setComparePlansOpen: state.setComparePlansOpen,  
  }), shallow);

  const handleComparePlans = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    appState.setComparePlansOpen(true);
  };

  return (<AccountPanelOutlined
    title={<List disablePadding>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="FluidPrompter Pro" src={process.env.PUBLIC_URL + '/android-chrome-192x192.png'} />
        </ListItemAvatar>
        <ListItemText
          primary={'FluidPrompter Pro'}
          secondary={'Pro version with extra features for individual video creators working alone.'}
        />
      </ListItem>
    </List>}
    className="PlanFeatures"
  >
    <Grid container spacing={{ sm: 0, md: 3 }}>
      <Grid item sm={12} md={6}>
        <ul className="ListIncluded">
          <li className="awesome"><b><em>Everything in the Free Plan, plus:</em></b></li>
          <li>Analog foot-pedal control</li>
          <li>Basic API automation</li>
          <li>Community support</li>
        </ul>
      </Grid>
      <Grid item sm={12} md={6}>
        Not Included:
        <ul className="ListNotIncluded">
          {/*
          <li>Automation cues</li>
          <li>Import script from rich text files (*.rtf)</li>
          <li>Import script from Google Docs</li>
          <li>Import script from Notion</li>
          */}
          <li>Full API automation</li>
          <li>Priority Email Support</li>
        </ul>
        <a href="#plans" onClick={handleComparePlans}>See all features and compare plans</a>
      </Grid>
    </Grid>
  </AccountPanelOutlined>);
};

export default ProPlanPanel;