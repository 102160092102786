import React from 'react';
// import { styled } from '@mui/material/styles';
import { Portal } from '@mui/base';
import { ToastContainer, ToastContainerProps } from 'react-toastify';

import './main.scss';

/*
const StyledToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {}
  .Toastify__toast {}
  .Toastify__toast-body {}
  .Toastify__progress-bar {}
`;
*/

// const StyledToastContainer = styled(ToastContainer)({
//   marginTop: '64px',
// });

const StyledToastContainerPortal = (props: ToastContainerProps) => {
  return (
    <Portal>
      <ToastContainer
        {...props}
        position="top-right"
        autoClose={5000}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        pauseOnHover={true}
      />
    </Portal>
  );
};

export default StyledToastContainerPortal;