import React from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const DeviceCountBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: '1em',
    right: '-1.5em',
    height: '1.5em',
    width: '2em',
    borderRadius: '5px',
    padding: '0',
    backgroundColor: '#0082FC',
    // border: `2px solid ${theme.palette.background.paper}`,
    color: theme.palette.primary.contrastText,
  },
}));

export default DeviceCountBadge;