import { useCallback } from 'react';
import { MessageHandlerEvent, useMessageHandler } from '../../controllers/AppController';
import usePrompterSession from '../../state/PrompterSessionState';
import { GenericMessage, PlayMessage, PauseMessage } from '@fluidprompter/core';

function usePrompterTogglePlayHandler() {
  const doTogglePlay = useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    e.dispatchMessage(
      usePrompterSession.getState()
        ? new PauseMessage()
        : new PlayMessage()
    );
  }, []);
  useMessageHandler('prompter.state.toggleplay', doTogglePlay);

  return doTogglePlay;
}

export default usePrompterTogglePlayHandler;