import { BaseControlMessage } from './BaseControlMessage';
export const NODECHANGEDMESSAGE_KEY = 'nodechanged';
/**
 * Represents the NodeChangedMessage type.
 */
export class NodeChangedMessage extends BaseControlMessage {
    constructor(nodePath, nodeHeight, nodeChildrenTop, nodeChildrenHeight, position) {
        super();
        this.type = NODECHANGEDMESSAGE_KEY;
        this.nodePath = nodePath;
        this.nodeHeight = nodeHeight;
        this.nodeChildrenTop = nodeChildrenTop;
        this.nodeChildrenHeight = nodeChildrenHeight;
        this.position = position;
    }
    static fromJson(data) {
        if (data.type !== NODECHANGEDMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for NodeChangedMessage.');
        }
        const input = data;
        if (!input.sender
            || input.sender.n === undefined
            || input.sender.n.length === 0
            || input.sender.nh === undefined
            || input.sender.nct === undefined
            || input.sender.nch === undefined
            || input.sender.np === undefined) {
            throw new SyntaxError('Invalid JSON for NodeChangedMessage. Missing sender.');
        }
        const instance = new NodeChangedMessage(input.sender.n, input.sender?.nh, input.sender?.nct, input.sender?.nch, input.sender?.np);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
        };
    }
}
NodeChangedMessage.MESSAGE_NAME = NODECHANGEDMESSAGE_KEY;
