import BaseControlMessage from './BaseControlMessage';
export const GETSESSIONMESSAGE_KEY = 'getsession';
/**
 * Represents the GetSessionMessage type.
 */
export class GetSessionMessage extends BaseControlMessage {
    constructor() {
        super();
        this.type = GETSESSIONMESSAGE_KEY;
    }
    static fromJson(data) {
        if (data.type !== GETSESSIONMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for GetSessionMessage.');
        }
        const instance = new GetSessionMessage();
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
        };
    }
}
GetSessionMessage.MESSAGE_NAME = GETSESSIONMESSAGE_KEY;
