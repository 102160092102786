import { Descendant } from 'slate';
import { ElementTypes } from '../models/EditorTypes';

const InitialScript: Descendant[] = [{
  type: ElementTypes.STARTSCRIPT,
  children: [{ text: '' }]
},{
  type: ElementTypes.SCRIPT_SEGMENT,
  number: 1,
  title: 'Welcome to Fluid Prompter!',
  children: [{
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'Press '
    }, {
      text: 'Enter',
      kbd: true
    }, {
      text: ' or '
    }, {
      text: 'Space',
      kbd: true
    }, {
      text: ' to get started!'
    }]
  // }, {
  //   type: 'image',
  //   url: 'https://images.unsplash.com/photo-1663174494200-7802f72775ba?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1024&q=80',
  //   caption: 'Unsplash Image',
  //   children: [{ text: "" }]
  }]
},{
  type: ElementTypes.SCRIPT_SEGMENT,
  number: 2,
  title: 'Teleprompter Introduction',
  children: [{
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'Please read along as I show you around!'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'A Teleprompter can make business presentations or YouTube Videos much easier to produce by cutting down on errors (which also reduces video editing time later).'
    }]
  }]
},{
  type: ElementTypes.SCRIPT_SEGMENT,
  number: 3,
  title: 'How to Play/Pause',
  children: [{
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'Press '
    }, {
      text: 'Enter',
      kbd: true
    }, {
      text: ' or '
    }, {
      text: 'Space',
      kbd: true
    }, {
      text: ' again to toggle the teleprompter\'s play or pause state.'
    }]
  }]
},{
  type: ElementTypes.SCRIPT_SEGMENT,
  number: 4,
  title: 'How to Edit Script',
  children: [{
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'If you want to edit the script, press '
    }, {
      text: 'Ctrl',
      kbd: true
    }, {
      text: '+'
    }, {
      text: 'Enter',
      kbd: true
    }, {
      text: ' or '
    }, {
      text: 'Ctrl',
      kbd: true
    }, {
      text: '+'
    }, {
      text: 'Space',
      kbd: true
    }, {
      text: ' and click or tap on the segment you want to edit.'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{ text: '' }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'When done editing, click outside the segment to release the keyboard control.'
    }]
  }]
},{
  type: ElementTypes.SCRIPT_SEGMENT,
  number: 5,
  title: 'Using the Mouse',
  children: [{
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'All of these functions can also be performed using a mouse or touch screen.'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{ text: '' }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'To reduce distractions, the mouse cursor and toolbars will hide after a few seconds without movement. '
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{ text: '' }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'To use the mouse again, just move the mouse around until the cursor and toolbars reappear.'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{ text: '' }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'On a touch screen a single tap will also reshow the toolbars.'
    }]
  }]
},{
  type: ElementTypes.SCRIPT_SEGMENT,
  number: 6,
  title: 'Adjusting Scroll Speed',
  children: [{
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'You can adjust the scrolling speed of the teleprompter with the '
    }, {
      text: '←',
      kbd: true
    }, {
      text: ' and '
    }, {
      text: '→',
      kbd: true
    }, {
      text: ' arrow keys.'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'There is also a slider control in the bottom right of the prompter.'
    }]
  }]
},{
  type: ElementTypes.SCRIPT_SEGMENT,
  number: 7,
  title: 'Get More Help!',
  children: [{
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'There are '
    }, {
      text: 'A LOT more',
      bold: true,
      italic: true
    }, {
      text: ' keyboard shortcuts for fluidprompter. Learn more by pressing '
    }, {
      text: 'F1',
      kbd: true
    }, {
      text: ' or '
    }, {
      text: 'Alt',
      kbd: true
    }, {
      text: '+'
    }, {
      text: 'H',
      kbd: true
    }, {
      text: ' to show the help screen.'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'There is also a help toolbar icon in the upper right of the prompter.'
    }]
  }]
},{
  type: ElementTypes.PAUSE,
  children: [{ text: '' }],
},{
  type: ElementTypes.SCRIPT_SEGMENT,
  number: 8,
  children: [{
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'This app is very similar to a typical teleprompter. You can adjust the size of the text on screen for readability. You can also adjust the margins on the left and right side of the screen to help keep your eyes relatively close to the center of the screen where your camera lens will be.'
    }]
  }]
},{
  type: ElementTypes.SCRIPT_SEGMENT,
  number: 9,
  children: [{
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'You can control the teleprompter operations with your keyboard.'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'The spacebar will start and stop the prompter,'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'left and right arrows keys will slow down and speed up the prompter'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'and up and down will skip backwards and forwards in your script.'
    }]
  }]
},{
  type: ElementTypes.SCRIPT_SEGMENT,
  children: [{
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'You can also pair an AirTurn Digit III bluetooth remote for teleprompter control.'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'With this remote the center button will start and stop the prompter,'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'left and right will slow down and speed up the prompter'
    }]
  }, {
    type: ElementTypes.PARAGRAPH,
    children: [{
      text: 'and up and down will skip backwards or forwards in your script.'
    }]
  }]
},{
  type: ElementTypes.ENDSCRIPT,
  children: [{ text: '' }],
}];

export default InitialScript;