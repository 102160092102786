import EventEmitter from 'eventemitter3';

import {
  ConnectionState,
  ConnectMessage,
  BaseControlMessage,
  HeartbeatResponse,
  PrompterSessionEndpoint,
  PlayMessage,
  EditMessage,
  PauseMessage,
  MessageUtils,
  BinaryReceiver,
  DisconnectMessage,
  ConnectResponse,
  GetSessionMessage
} from '@fluidprompter/core';

import usePrompterSession from '../../state/PrompterSessionState';
import AppController from './AppController';
import { BaseMessageJson } from '@fluidprompter/core/lib/messages/BaseControlMessage';

import _ from 'lodash';

import { Logger } from 'browser-bunyan';
import logger from '../../utils/Logger';

interface WebsocketChannelEvents {
  connecting: (e: Event) => void;
  connected: (e: Event) => void;
  disconnecting: (e: Event) => void;
  disconnected: (e: Event) => void;
  message: (e: MessageEvent) => void;
}

/**
 * Build our websocket connection URL given a prompterId, taking
 * into account the current browser window's location.
 * @param prompterId
 * @returns
 */
const buildWebsocketUrl = (prompterId: string) => {
  const currentUrl = window.location.protocol + '//' + window.location.host + '/';  // window.location.pathname
  const websocketUrl = new URL(process.env.REACT_APP_API_URL || currentUrl);
  websocketUrl.protocol = 'wss:';
  websocketUrl.port = '443';
  if(
    'localhost' === window.location.hostname
    || '127.0.0.1' === window.location.hostname
    || window.location.hostname.startsWith('192.168')
  ) {
    websocketUrl.hostname = window.location.hostname;
    websocketUrl.protocol = 'ws:';
    websocketUrl.port = '8787';
  }
  websocketUrl.pathname = `/api/prompter/${prompterId}/websocket`;
  return websocketUrl.href;
};

export class WebsocketChannel
  extends EventEmitter<WebsocketChannelEvents>
{
  private log: Logger;

  private _appController: AppController;
  private _webSocket: WebSocket | undefined;
  private _binaryReceiver = new BinaryReceiver();

  constructor(appController: AppController) {
    super();

    this.log = logger.child({
      childName: 'WebsocketChannel',
    });
    this.log.trace(this.getLogState(), 'WebsocketChannel.constructor()');

    this._onSocketOpenedBound = this.onSocketOpened.bind(this);
    this._onSocketMessageBound = this.onSocketMessage.bind(this);
    this._onSocketClosedBound = this.onSocketClosed.bind(this);
    this._onSocketErrorBound = this.onSocketError.bind(this);

    this._appController = appController;

    // This will connect our websocket, as long as we have a prompterId and instanceId
    this.subscribeToPrompterId();

    // Subscribe to page lifecycle events.
    window.addEventListener('focus', this.handleWindowFocus.bind(this));
    window.addEventListener('blur', this.handleWindowBlur.bind(this));
    window.addEventListener('pageshow', this.handleWindowPageShow.bind(this));
    document.addEventListener('visibilitychange', this.handleDocumentVisibilityChange.bind(this));

    this.requestCheckConnection = _.debounce(this.checkConnection, 100, {
      leading: false,
      trailing: true,
    });
  }

  get logLevel() {
    return this.log.level();
  }
  set logLevel(levelNumber: number) {
    this.log.level(levelNumber);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getLogState(initialState?: any) {
    //
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const logState: any = {
      ...initialState,
      websocketConnectionState: this.connectionState,
      reconnectAttempts: this.reconnectAttempts,
    };

    return logState;
  }

  /**
   * Subscribe to our PrompterSession zustand store, watching for changes in the promtperId.
   */
  private subscribeToPrompterId() {
    // const { prompterId, instanceId } = usePrompterSession.getState();
    // console.log(`subscribeToPrompterId(), prompterId=${prompterId}, instanceId=${instanceId}`);
    this._unsubscribeToPrompterId = usePrompterSession.subscribe(async (state, prevState) => {
      //
      // We are only interested in changes to prompterId or instanceId - for all other changes
      // we want to exit this handler early.
      //
      if(
        (state.prompterId === prevState.prompterId)
        && (state.instanceId === prevState.instanceId)
      ) {
        return;
      }

      //
      // If we get here, either our instanceId or prompterId value changed.
      //
      this._instanceId = state.instanceId;
      this._prompterId = state.prompterId;

      //
      // We don't care when they change to undefined or empty string.
      //
      if(!this._prompterId
        || !this._instanceId
      ) {
        // We are missing a prompterId or instanceId
        return;
      }

      //
      // If we get here, we have a string value for instanceId and prompterId!
      // Check if we are good to go with a websocket connection.
      //
      // await this.checkConnection();
      this.requestCheckConnection();
    });
  }
  private _instanceId: string | undefined;
  private _prompterId: string | undefined;
  private _unsubscribeToPrompterId: (() => void) | undefined;

  private _connectionState: ConnectionState = ConnectionState.Disconnected;
  get connectionState() {
    return this._connectionState;
  }

  /**
   * This will be set to true after the fluidprompter has made a successful websocket connection.
   */
  private hasBeenConnected = false;

  private async transitionState(proposedConnectionState: ConnectionState) {
    const prevConnectionState = this._connectionState;
    this.log.info(this.getLogState(), `WebsocketChannel.transitionState(from ${prevConnectionState} to ${proposedConnectionState})`);

    this._connectionState = proposedConnectionState;
    usePrompterSession.getState().setWebsocketState(proposedConnectionState);

    switch(proposedConnectionState) {
      case ConnectionState.Connecting:
        // *DONE* trigger: connection enabled [disconnected -> connecting]
        // *DONE* trigger: connection lost (unexpected) [connected -> connecting]
        // trigger: connection timeout (websocket failed to connect) [connecting -> connecting]
        await this.handleConnecting();
        break;
      case ConnectionState.Connected:
        // *DONE* trigger: connection opened
        await this.handleConnected();
        break;
      case ConnectionState.Disconnecting:
        // *DONE* trigger: connection disabled [connected -> disconnecting]
        await this.handleDisconnecting();
        break;
      case ConnectionState.Disconnected:
        // *DONE* trigger: connection closed [disconnecting -> disconnected]
        // *DONE* trigger: connection disabled [connecting -> disconnected]
        await this.handleDisconnected();
        break;
    }
  }

  /**
   * Evaluate all the necessary conditions for us to transition to connecting.
   * We will not try to reconnect while the browser is currently hidden.
   * @returns
   */
  private shouldBeConnected() {
    return this._isOnline
      && this._documentVisible
      && this._prompterId !== undefined
      && this._prompterId.length > 0
      && this._instanceId !== undefined
      && this._instanceId.length > 0;
  }

  /**
   * Evaluate all the necessary conditions for to force a disconnect.
   * Note: we do NOT want to disconnect if we are hidden.
   * @returns
   */
  private shouldBeDisconnected() {
    return !this._isOnline
      || !this._prompterId
      || !this._instanceId;
  }

  // private _pendingDisconnectTimeout: number | undefined;
  private async checkConnection() {

    //
    // Evaluate all the necessary conditions for us to transition to connecting.
    // We will not try to reconnect while the browser is currently hidden.
    //
    const shouldBeConnected = this.shouldBeConnected();

    //
    // Evaluate all the necessary conditions for to force a disconnect.
    // Note: we do NOT want to disconnect if we are hidden.
    //
    const shouldBeDisconnected = this.shouldBeDisconnected();

    // console.log(`${new Date().toLocaleString()} WebsocketChannel.checkConnection() - webSocket.readyState = ${this._webSocket?.readyState}, app websocket connection state = ${this._connectionState}`);
    if(
      shouldBeConnected
        && this._connectionState === ConnectionState.Disconnected
    ) {
      return this.transitionState(ConnectionState.Connecting);
    }

    if(
      shouldBeDisconnected
        && this._connectionState === ConnectionState.Connected
    ) {
      return this.transitionState(ConnectionState.Disconnecting);
    }
    /*
    if(
      connectionEnabled
        && this._connectionState === ConnectionState.Connected
        && this._pendingDisconnectTimeout
    ) {
      window.clearTimeout(this._pendingDisconnectTimeout);
      this._pendingDisconnectTimeout = undefined;
    }
    */

    if(
      shouldBeDisconnected
        && this._connectionState === ConnectionState.Connecting
    ) {
      return this.transitionState(ConnectionState.Disconnected);
    }

    //
    // In the event that we are verifying the connection but don't believe anything has changed
    // with connection state - let's send a connect message (which also disables, then enables the
    // heartbeat interval). This might be redundant in some cases - but handling of connect
    // messages should be idempotent across the application.
    //
    if(shouldBeConnected && this._windowFocused) {
      // this.sendConnectMessage();
      this.checkWebsocketAlive(); // Just do a ping, if it fails, reconnect websocket.
    }
  }
  protected requestCheckConnection: _.DebouncedFunc<() => Promise<void>>;

  /**
   * Start the peer Heartbeat interval that will time our roundtrip time and keep the channel open.
   */
  private enableHeartbeat() {
    if(this._heartbeatTimer) {
      return;
    }

    this.log.trace(this.getLogState(), 'WebsocketChannel.enableHeartbeat()');

    // Just in case we had a strange transition that never become disconnected.
    // For example "connection lost" transitions from connected to connecting.
    window.clearInterval(this._heartbeatTimer);
    // Now create our new heartbeat timer.
    this._heartbeatTimer = window.setInterval(this.sendPing.bind(this), 15 * 1000);
  }

  /**
   * Clear the peer heartbeat interval that is used to time our roundtrip time and keep the channel open.
   */
  private disableHeartbeat() {
    if(!this._heartbeatTimer) {
      return;
    }

    this.log.trace(this.getLogState(), 'WebsocketChannel.disableHeartbeat()');

    window.clearInterval(this._heartbeatTimer);
    this._heartbeatTimer = 0;
  }

  private _heartbeatTimer = 0;

  private async sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public setOnline(online: boolean) {
    this.log.trace(this.getLogState(), `WebsocketChannel.setOnline(Prev: ${this._isOnline}, New: ${online})`);
    if(this._isOnline === online) {
      return;
    }
    this._isOnline = online;
    this.log.info(this.getLogState(), `WebsocketChannel.isOnlne = ${this._isOnline}`);
    this.requestCheckConnection();
  }
  private _isOnline = true;

  private async handleWindowFocus(e: FocusEvent) {
    if(this._windowFocused === true) {
      return;
    }
    this._windowFocused = true;
    this.log.info(this.getLogState(), `handleWindowFocus(window.focused = ${this._windowFocused}), _webSocket?.readyState = ${this._webSocket?.readyState}`);
    this.requestCheckConnection();
  }

  private async handleWindowBlur(e: FocusEvent) {
    if(this._windowFocused === false) {
      return;
    }
    this._windowFocused = false;

    /*
    switch(this._webSocket?.readyState) {
      case WebSocket.CONNECTING:  // 0
        break;
      case WebSocket.OPEN:  // 1
        break;
      case WebSocket.CLOSING: // 2
        break;
      case WebSocket.CLOSED:  // 3
        break;
    }
    */
    this.log.info(this.getLogState(), `handleWindowBlur(window.focused = ${this._windowFocused}), _webSocket?.readyState = ${this._webSocket?.readyState}`);
    this.requestCheckConnection();
  }
  private _windowFocused = true;

  private async handleWindowPageShow(e: PageTransitionEvent) {
    this._windowFocused = document.hasFocus();
    this._documentVisible = (document.visibilityState === 'visible');
    this.requestCheckConnection();
  }

  private async handleDocumentVisibilityChange(e: Event) {
    // "hidden" | "visible"
    const newDocumentVisible = (document.visibilityState === 'visible');
    if(this._documentVisible === newDocumentVisible) {
      return;
    }
    this._documentVisible = newDocumentVisible;
    this.log.info(this.getLogState(), `handleDocumentVisibilityChange(document.visible = ${this._documentVisible})`);
    this.requestCheckConnection();
  }
  private _documentVisible = true;

  private createWebSocket() {
    if(!this._prompterId) {
      throw new Error('Cannot connect websocket before we have a prompterId');
    }

    this.websocketConnectTimeout = window.setTimeout(() => {
      // if (self.debug || ReconnectingWebSocket.debugAll) {
      //   console.debug('ReconnectingWebSocket', 'connection-timeout', self.url);
      // }
      console.debug('ReconnectingWebSocket', 'connection-timeout', websocketUrl);
      this.timedOut = true;
      this.destroyWebSocket();
      this.timedOut = false;
    }, this.timeoutInterval);

    // Just in case we are trying to connect multiple times in a row... make sure everything was
    // cleaned-up.
    this.destroyWebSocket();

    // Now let's create our new websocket instance.
    // console.log(`Creating WebSocket instance: document.readyState:${document.readyState}`);
    const websocketUrl = buildWebsocketUrl(this._prompterId);
    // console.log(`new websocketUrl: ${websocketUrl}`);
    const newWebSocket = new WebSocket(websocketUrl);
    newWebSocket.binaryType = 'arraybuffer';

    newWebSocket.addEventListener('open', this._onSocketOpenedBound);
    newWebSocket.addEventListener('message', this._onSocketMessageBound);
    newWebSocket.addEventListener('close', this._onSocketClosedBound);
    newWebSocket.addEventListener('error', this._onSocketErrorBound);

    this._webSocket = newWebSocket;
  }
  private destroyWebSocket() {
    // This is just in case we try to connect more than one time in a row without a
    // destroyWebSocket() in between (multiple connection attempts).
    clearTimeout(this.websocketConnectTimeout);

    const currentWebSocket = this._webSocket;
    if(!currentWebSocket) {
      return;
    }

    currentWebSocket.removeEventListener('open', this._onSocketOpenedBound);
    currentWebSocket.removeEventListener('message', this._onSocketMessageBound);
    currentWebSocket.removeEventListener('close', this._onSocketClosedBound);
    currentWebSocket.removeEventListener('error', this._onSocketErrorBound);

    if(currentWebSocket.readyState === WebSocket.OPEN) {
      try {
        currentWebSocket.close();
      } catch(err) {
        console.error('Error closing websocket.', err);
      }
    }

    this._webSocket = undefined;
  }

  private async handleConnecting() {
    if(!this._prompterId) {
      return;
    }

    // Don't reconnect our websocket when the browser window is not visible or browser not online.
    if(!this.shouldBeConnected()) {
      return;
    }

    this.emit('connecting', new CustomEvent('connecting'));

    // If we believe we already have an open WebSocket connection, let's verify it.
    // If the verification fails, checkWebsocketAlive() will again initiate connecting.
    if(this._webSocket?.readyState === WebSocket.OPEN) {
      return this.checkWebsocketAlive();
    }

    // console.log(`WebsocketChannel.handleConnecting(connectAtempt=${this.reconnectAttempts})`);
    if(this.reconnectAttempts) {
      const timeout = this.reconnectInterval * Math.pow(this.reconnectDecay, this.reconnectAttempts);
      const waitDuration = timeout > this.maxReconnectInterval ? this.maxReconnectInterval : timeout;
      await this.sleep(waitDuration);
    }

    this.reconnectAttempts += 1;

    if(
      this.reconnectAttempts > 1
      && this.maxReconnectAttempts
      && this.reconnectAttempts > this.maxReconnectAttempts
    ) {
      // We've exhausted the number of reconnect attempts we are allowed to make.
      console.error('WebsocketChannel exhausted maximum number of reconnect attempts.');
      return;
    }

    if(this._webSocket && this._webSocket.readyState !== WebSocket.CLOSED) {
      console.error('WebsocketChannel.connectSocket() already has a websocket instance that is not closed.');
      return;
    }

    this.createWebSocket();
  }

  private async handleConnected() {
    // Clear our connection timeout
    clearTimeout(this.websocketConnectTimeout);

    // Reset the reconnection attempt counter after a successful connection.
    this.reconnectAttempts = 0;

    // True after we have established a connection at least once (used to differentiate between
    // first connection and subsequent re-connections).
    this.hasBeenConnected = true;

    this.emit('connected', new CustomEvent('connected'));
  }

  /**
   * Send a 'connect' message via websocket connection to announce this prompter's presence.
   *
   * This message should be handled in an idempotent way. If the prompter was already connected,
   * peer's should just carry on. But if there was a missing or broken peer connection, we should
   * establish or re-establish that peer connection.
   */
  private async sendConnectMessage() {
    // We will re-enable the heartbeat at the end of this method after we have re-calculated our
    // round trip time with a manual heartbeat execution and also sent our connect message.
    this.disableHeartbeat();

    if(this._webSocket?.readyState !== WebSocket.OPEN) {
      this.destroyWebSocket();
      return this.transitionState(ConnectionState.Connecting);
    }

    if(!this._instanceId) {
      throw new Error('Missing instanceId in Websocketchannel.sendConnectMessage()');
    }

    //
    // Let's send a heartbeat message right away to establish a cloudLatency (rount trip time).
    //
    let heartbeatResponse: HeartbeatResponse | undefined;
    for(let pingAttempt = 0; pingAttempt < 1; pingAttempt++) {  // Was 3 attempts
      // Back-off when a ping fails to send. Sleep for 100ms, 200ms, 400ms.
      await this.sleep(100 *  Math.pow(2, pingAttempt));

      let pingSent = false;
      try {
        pingSent = this.sendPing();
      } catch(err) {
        // This will fail if we aren't really connected. It's also a race condition if we get
        // connected but then fail again fast.
        console.error(`Error sending websocket ping after connected. Attempt #${pingAttempt}`, err);
      }
      if(!pingSent) {
        continue;
      }

      // Let's wait (up to 1500ms) to receive a heartbeatResponse from the cloud before we continue.
      try {
        heartbeatResponse = await this.waitForHeartbeatResponse(1500);
        break;  // We are done with the retry loop! Get out of here.
      } catch(err) {
        // We didn't receive the heartbeat response within the timeout period.
        console.error(`Error waiting for websocket ping-response after connected. Attempt #${pingAttempt}`, err);
      }

      // if(!heartbeatResponse) {
      //   // If we got no response, is the websocket actually connected or buffering outgoing data?
      //   console.log(`WebsocketChannel got no response to ping message (readyState=${this._webSocket?.readyState}, bufferedAmount=${this._webSocket?.bufferedAmount}).`);
      // }
    } // end for() retry loop

    if(!heartbeatResponse) {
      // It seams there is something wrong with our connection!
      // The websocket.readyState === connected, but we aren't getting a response from the server.
      // Let's just reconnect and start fresh.
      this.destroyWebSocket();
      this.transitionState(ConnectionState.Connecting);
      return;
    }

    // Set our most recent local cloud latency value. We are going to pass this information to
    // other peers or system components.
    this._appController.localCloudLatency = Math.round(performance.now() - heartbeatResponse.senderTimestamp);

    // Retrieve an endpoint description model
    const thisEndpoint = this._appController.getLocalEndpointDescription();

    // TODO: if we just reloaded the browser window, send the rebuildConnection flag, then clear
    // the flag for reloaded browser window so subsequent websocket reconnection won't get the
    // rebuild flag.
    const connectMsg = new ConnectMessage(thisEndpoint);
    connectMsg.rebuildConnection = !this.hasBeenConnected;
    await this.sendMessage(connectMsg);

    this.enableHeartbeat();
  }

  private async checkWebsocketAlive() {
    this.log.trace(this.getLogState(), `checkWebsocketAlive(): readyState = ${this._webSocket?.readyState}`);

    //
    // If we are already in the process of reconnecting, no need to test the connection below.
    //
    // When a browser "hibernates" a tab and later comes back, it will sometimes trigger the
    // websocket error and close events, other times you won't get these message but the socket
    // will still be non-functional. This is a race condition between the browser will terminate
    // a websocket for non-activity vs when the server will terminate a websocket for an
    // undeliverable message.
    //
    // If we got the websocket error/close events, then we may have already began re-connecting.
    // If we did not get any websocket error/close events, we want to test the socket is still
    // functional by manually performing a ping.
    //
    if(this._webSocket?.readyState === WebSocket.CONNECTING) {
      this.log.trace(this.getLogState(), 'checkWebsocketAlive() WebSocket is currently connecting');
      return;
    }

    // We will re-enable the heartbeat at the end of this method after we have re-calculated our
    // round trip time with a manual heartbeat execution and also sent our connect message.
    this.disableHeartbeat();

    //
    // We will perform a manual heartbeat ping, if we don't get a response, we will reconnect the
    // websocket.
    //
    let heartbeatResponse: HeartbeatResponse | undefined;
    for(let pingAttempt = 0; pingAttempt < 1; pingAttempt++) {  // Was 3 attempts
      // Back-off when a ping fails to send. Sleep for 100ms, 200ms, 400ms.
      await this.sleep(100 *  Math.pow(2, pingAttempt));

      let pingSent = false;
      try {
        pingSent = this.sendPing();
      } catch(err) {
        // This will fail if we aren't really connected. It's also a race condition if we get
        // connected but then fail again fast.
        this.log.error(this.getLogState({
          error: err
        }), `Error sending websocket ping after connected. Attempt #${pingAttempt}`);
      }
      if(!pingSent) {
        continue;
      }

      // Let's wait (up to 1500ms) to receive a heartbeatResponse from the cloud before we continue.
      try {
        heartbeatResponse = await this.waitForHeartbeatResponse(1500);
        break;  // We are done with the retry loop! Get out of here.
      } catch(err) {
        // We didn't receive the heartbeat response within the timeout period.
        this.log.error(this.getLogState({
          error: err
        }), `Error waiting for websocket ping-response after connected. Attempt #${pingAttempt}`);
      }

      // if(!heartbeatResponse) {
      //   // If we got no response, is the websocket actually connected or buffering outgoing data?
      //   console.log(`WebsocketChannel got no response to ping message (readyState=${this._webSocket?.readyState}, bufferedAmount=${this._webSocket?.bufferedAmount}).`);
      // }
    } // end for() retry loop

    if(!heartbeatResponse) {
      this.log.trace(this.getLogState(), 'checkWebsocketAlive() never received heartbeat response, destroy websocket and reconnect');

      // It seams there is something wrong with our connection!
      // The websocket.readyState === connected, but we aren't getting a response from the server.
      // Let's just reconnect and start fresh.
      this.destroyWebSocket();
      this.transitionState(ConnectionState.Connecting);
      return;
    }

    // Make sure we are accurately reflected our current WebSocket state.
    if(this._connectionState !== ConnectionState.Connected) {
      await this.transitionState(ConnectionState.Connected);
    }

    //
    // If we get here, we got a heartbeatResponse on the websocket!
    // Let's fire off a GetSessionMessage
    //
    this.log.trace(this.getLogState(), 'checkWebsocketAlive() send GetSessionMessage()');
    await this.sendMessage(new GetSessionMessage());

    this.enableHeartbeat();
  }

  /**
   * There is only one path to get to disconnecting, which is an intentional disconnect.
   *   trigger: connection disabled [connected -> disconnecting]
   */
  private async handleDisconnecting() {
    this.emit('disconnecting', new CustomEvent('disconnecting'));

    //
    // Disconnecting is triggered when the WebsocketChannel is disabled (perhaps because the
    // browser/device went offline).
    //
    // On some devices the websocket close event will already have fired before we get a chance
    // to gracefully shut down the websocket. On other platforms we may get to call websocket
    // close before the socket event is triggered.
    //
    if(this._webSocket?.readyState === WebSocket.OPEN) {
      //
      // Let's give a best effort to let the server know why we are disconnecting.
      //
      if(this._instanceId) {
        // The only reason we get a chance to send a DisconnectMessage from the browser app is
        // when the page is gracefully disconnecting because the page has been hidden.
        //
        // On MacBook pro when you close the lid, the websocket is immediately disconnected,
        // though we are able to reconnect immediately too (weird). I don't count that as an
        // intentional disconnection.
        await this.sendMessage(new DisconnectMessage(this._instanceId));

        await this.waitForSendComplete();
      }

      //
      // Once our disconnect message has been dispatched, we can go ahead and close the websocket.
      //
      this.destroyWebSocket();
      return;
    }

    // If we weren't actually connected - just skip straight to cleanup and disconnected.
    await this.transitionState(ConnectionState.Disconnected);
  }

  /**
   * There are 2 paths to get to disconnected
   *   trigger: connection closed [disconnecting -> disconnected]
   *   trigger: connection disabled [connecting -> disconnected]
   */
  private async handleDisconnected() {
    //
    // Clean-up the current websocket before we update the state machine.
    //
    this.disableHeartbeat();

    //
    // Clean-up. We will recreate the websocket if we want to reconnect.
    //
    this.destroyWebSocket();

    //
    // Let our AppController know we are disconnected.
    //
    this.emit('disconnected', new CustomEvent('disconnected'));

    //
    // On my MacBook Pro, when I close the lid, the websocket is immediately closed, but the page
    // visibility change event to notify of hidden doesn't happen for ~1 second. If we attempt to
    // reconnect instantly, then we will reconnect with the laptop lid closed and again be
    // disconnected later after some time of the lid being closed.
    //
    const reconnectDelayMs = 1250;
    setTimeout(async () => {
      this.requestCheckConnection();
    }, reconnectDelayMs);

    //
    // on iOS when the browser loses focus the websocket is instantly killed, but the error/close
    // events don't fire until/unless the browser tab gets focus again.
    //
    // We may end up handling the error/close event at a time when we really want to reconnect
    // as the page became active again.
    //
    // this.requestCheckConnection();
  }

  private websocketConnectTimeout = 0;
  private timedOut = false;

  private async onSocketOpened(/*e: Event*/) {
    // console.log(`WebsocketChannel.onSocketOpened(${this._webSocket?.readyState})`);

    if(this._webSocket?.readyState === WebSocket.CONNECTING) {
      // It appears onSocketOpened is called twice, once with connecting state, then again with
      // connected state.
      return;
    }

    await this.sendConnectMessage();
  }
  private _onSocketOpenedBound: (e: Event) => void;

  private async onSocketClosed(e: CloseEvent) {

    //
    // WebSocket CloseEvent codes: https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
    //
    // When MacBook Pro lid is closed, WebSocket CloseEvent code=1006, wasClean=false
    // When MacBook Wi-Fi is turned off, websocket is pro-actively disconnected when navigator onLine=false
    //
    this.log.info(this.getLogState({
      event: e,
    }), `WebsocketChannel.onSocketClosed(code = ${e.code}, wasClean = ${e.wasClean}), connectionState=${this._connectionState}. timedOut=${this.timedOut}, document.visibilityState=${document.visibilityState}`);
    console.log(e);

    return this.transitionState(ConnectionState.Disconnected);
  }
  private _onSocketClosedBound: (e: CloseEvent) => void;
  private reconnectAttempts = 0;

  /**
   * The number of milliseconds to delay before attempting to reconnect.
   */
  private reconnectInterval = 1000;

  /**
   * The maximum number of milliseconds to delay a reconnection attempt.
   */
  private maxReconnectInterval = 10000;

  /**
   * The maximum number of reconnection attempts to make. Unlimited if null.
   */
  private maxReconnectAttempts: number | undefined;

  /**
   * The rate of increase of the reconnect delay. Allows reconnect attempts to back off when
   * problems persist.
   */
  private reconnectDecay = 1.5;

  /**
   * The maximum time in milliseconds to wait for a connection to succeed before closing and retrying.
   */
  private timeoutInterval = 5000;


  private async onSocketError(e: Event) {
    this.log.error(this.getLogState({
      error: e,
    }), 'WebsocketChannel.onSocketError()');
  }
  private _onSocketErrorBound: (e: Event) => void;

  private async onSocketMessage(e: MessageEvent) {
    // console.log(`WebsocketChannel.onSocketMessage(${typeof e.data})`, e);

    let msg: Array<BaseMessageJson> | BaseMessageJson;
    if(e.data instanceof ArrayBuffer) {
      // We are receiving binary data!
      const assemblerResults = this._binaryReceiver.receiveBinaryChunk(e.data);
      if(!assemblerResults.complete || !assemblerResults.result) {
        return;
      }
      try {
        msg = JSON.parse(assemblerResults.result);
      } catch(err) {
        this.log.error(this.getLogState({
          error: err,
          msg: assemblerResults.result,
        }), 'Error parsing BinaryAssembler result\n${assemblerResults.result}');
        return;
      }
    } else if(e.data === 'pong') {   // Special case
      return this._appController.handleWebsocketMessage(this.handlePong());
    } else {
      // We have a string payload.
      try {
        msg = JSON.parse(e.data);
      } catch(err) {
        // Invalid JSON. Could not deserialize JSON.
        // We are logging a trace and not error, as we don't care a lot about this failure and
        // only want this for development/debugging use and not to show up on customer browser
        // console.
        this.log.trace(this.getLogState({
          content: e.data,
        }), 'onSocketMessage() invalid JSON payload');
        return;
      }
    }

    // console.log(`Received websocket message`, msg);

    if(Array.isArray(msg)) {
      msg.forEach(async (cmd) => {
        await this.processMessage(cmd);
      });
    } else {
      await this.processMessage(msg);
    }
  }
  private _onSocketMessageBound: (e: MessageEvent) => void;

  private async processMessage(msg: BaseMessageJson) {
    const currentMsg = MessageUtils.deserializeJsonMessage(msg);
    if(!currentMsg) {
      return;
    }

    //
    // Special case:
    // If we are receiving a 'connect.response' we should transition to connected state.
    // We are delaying the transition to connected until the websocket connection is established, a
    // connect message is sent and a connect.response is received.
    //
    if(
      currentMsg.type === ConnectResponse.MESSAGE_NAME
        && this.connectionState === ConnectionState.Connecting
    ) {
      await this.transitionState(ConnectionState.Connected);
    }

    //
    // Fire application event handlers.
    //
    this._appController.handleWebsocketMessage(currentMsg);
  }

  public async sendBinary(data: ArrayBuffer | ArrayBufferView | ArrayBufferLike) {
    if(this._webSocket?.readyState !== WebSocket.OPEN) {
      // throw new Error(`Cannot send message while connecting (${message.type}).`);
      console.warn(`Cannot send binary while connecting (${data.byteLength} bytes).`);
      return;
    }

    this._webSocket?.send(data);
  }

  /**
   * Concept: https://stackoverflow.com/a/30506051
   * @returns
   */
  private async waitForSendComplete() {
    const webSocket = this._webSocket;
    if(webSocket === undefined) {
      return;
    }

    return new Promise<void>((resolve, reject) => {
      const timeoutId = window.setTimeout(() => {
        // console.log('webSocket.bufferedAmount never reached zero');
        reject();
      }, 1000);

      //
      // polling loop
      //
      (function waitForEmptyBuffer(){
        if (webSocket.bufferedAmount === 0) {
          // console.log('webSocket.bufferedAmount === 0');
          window.clearTimeout(timeoutId);
          return resolve();
        }
        setTimeout(waitForEmptyBuffer, 30);
      })();
    });
  }

  public async sendMessage(message: BaseControlMessage) {
    //
    // Some messages should never be sent via WebSocket relay
    //
    switch(message.type) {
      case PlayMessage.MESSAGE_NAME:
      case EditMessage.MESSAGE_NAME:
      case PauseMessage.MESSAGE_NAME: {
        return;
      }
    }

    let serializedMessage: string | undefined;
    try {
      serializedMessage = JSON.stringify(message);
    } catch(err) {
      console.error('Error serializing app message in WebsocketChannel.sendMessage().', err);
    }
    if(!serializedMessage) {
      return;
    }

    if(this._webSocket?.readyState !== WebSocket.OPEN) {
      // throw new Error(`Cannot send message while connecting (${message.type}).`);
      console.warn(`Cannot send message while connecting (${message.type}).`);
      return;
    }

    this._webSocket?.send(serializedMessage);
  }

  private sendPing(): boolean {
    if(this._webSocket === undefined) {
      throw new Error('_webSocket instance undefined');
    }
    // console.log(`WebsocketChannel.sendPing(${this._webSocket.readyState}) - bufferedAmount: ${this._webSocket.bufferedAmount}`);
    if(this._webSocket.readyState !== WebSocket.OPEN) {
      return false;
    }

    this._lastPingTimestamp = Math.round(performance.now());
    this._webSocket.send('ping');
    return true;
  }
  private _lastPingTimestamp?: number;

  private async waitForHeartbeatResponse(timeout?: number): Promise<HeartbeatResponse> {
    const promise = new Promise<HeartbeatResponse>((resolve, reject) => {
      const promiseTimeout = setTimeout(() => {
        reject();
      }, timeout);

      this._heartbeatResolve = (value: HeartbeatResponse) => {
        clearTimeout(promiseTimeout);
        resolve(value);
        this._heartbeatResolve = undefined;
      };
    });
    return promise;
  }
  private _heartbeatResolve?: (value: HeartbeatResponse) => void;

  private handlePong() {
    const heartbeatResponse = new HeartbeatResponse();

    // If we have a last heartbeat send timestamp, then let's use that value to process the
    // heartbeat response and calculate roundtrip latency.
    if(this._lastPingTimestamp) {
      heartbeatResponse.senderTimestamp = this._lastPingTimestamp;
    }

    // If we have an open promise waiting for the heartbeat response, then let's resolve it.
    if(this._heartbeatResolve) {
      this._heartbeatResolve(heartbeatResponse);
    }

    // console.log(`WebsocketChannel.handlePong(${heartbeatResponse.roundtripLatency}ms)`, heartbeatResponse);

    return heartbeatResponse;
  }

}

export default WebsocketChannel;