import React, { useState, useCallback } from 'react';
import { Editor, Node, Transforms } from 'slate';
import { ReactEditor, useSlateStatic, RenderElementProps, useFocused, useSelected } from 'slate-react';
import { BRoll, ElementTypes } from '../../../models/EditorTypes';

import { useAppController } from '../../../controllers/AppController';
import { useConfirm } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';
import usePrompterSession from '../../../state/PrompterSessionState';

import PrompterIconButton from '../../PrompterIconButton';
import TheatersIcon from '@mui/icons-material/Theaters';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import BRollEditor from './BRollEditor';

import classNames from 'classnames';
import './BRollElement.scss';

// BRoll Icon -> https://www.shutterstock.com/image-vector/film-strip-letter-b-icon-logo-1989540806

function BRollElement(props: RenderElementProps) {
  const { attributes, children, element } = props;
  const brollNode = element as BRoll;

  const appController = useAppController();

  const editor = useSlateStatic();
  const editorIsReadOnly = ReactEditor.isReadOnly(editor);

  const confirm = useConfirm();

  const selected = useSelected();  // Get the current selected state of an element.
  const focused = useFocused();    // Get the current focused state of the editor.

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onEditorCancel = () => {
    setIsEditing(false);
  };

  const onEditorSave = useCallback((updatedNode: BRoll) => {

    // This will be the path to the broll element.
    const nodePath = ReactEditor.findPath(editor, element);
    if (!nodePath) {
      return;
    }

    // if (captionInput != null) {
    //   setCaption(captionInput);
    // }

    Transforms.setNodes<BRoll>(
      editor,
      updatedNode,
      { at: nodePath }
    );

    setIsEditing(false);
  }, [editor, element]);

  return (
    <>
      <div
        className={classNames('BRollElement', { isSelected: (selected && focused), hidden: editorIsReadOnly })}
        contentEditable={false}
        {...attributes}
      >
        <h4><TheatersIcon sx={{
          verticalAlign: 'text-bottom'
        }} /> B-ROLL: {brollNode.description}{children}</h4>
        <PrompterIconButton
          size="large"
          edge="start"
          aria-label="Edit BRoll Details"
          sx={{ mr: 1 }}
          disabled={editorIsReadOnly}
          onClick={
            async (e) => { setIsEditing(true); }
          }
        >
          <EditIcon />
        </PrompterIconButton>
        <PrompterIconButton
          size="large"
          edge="start"
          // color="inherit"
          aria-label="Delete BRoll Element"
          sx={{
            mr: 1,
            color: editorIsReadOnly ? 'transparent' : 'inherit'
          }}
          disabled={editorIsReadOnly}
          onClick={
            async (e) => {
              await confirm({ description: 'Are you sure you want to delete this b-roll element?' });

              // If the local prompter believes it is the leader, then the following node removal
              // will be sent to all other connected prompters.
              //
              // After a script modification has been sent to all other connected prompters, those
              // other prompters will also recognize this prompter as the current leader.
              const path = ReactEditor.findPath(editor, element);
              Transforms.removeNodes(editor, { at: path });

              appController.setLeaderIsSelf();
            }
          }
        >
          <DeleteForeverIcon />
        </PrompterIconButton>
      </div>
      {isEditing && <BRollEditor editorNode={brollNode} onCancel={onEditorCancel} onSave={onEditorSave} />}
    </>
  );
}

export default BRollElement;