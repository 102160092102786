import { ShepherdOptionsWithType } from 'react-shepherd';
import { offset} from '@floating-ui/dom';

import { TourStepFactoryMethod, TourStepFactoryParams } from './TourStepTypes';

const SpeedControlTourStepFactory: TourStepFactoryMethod = (params: TourStepFactoryParams) => {
  const { t } = params;

  const SpeedControlTourStep: ShepherdOptionsWithType = {
    id: 'SpeedControl',
    text: [t('welcometour.speedstep.body')],
    // text: [
    //   `<p>You can adjust the prompter scrolling speed here.</p>  
    //   <p>
    //   You can also use the <kbd>←</kbd> or <kbd>→</kbd> arrow keys to decrease or increase the scrolling speed.
    //   </p>`
    // ],
    attachTo: { element: '.TourTargetSpeedControl', on: 'top-end' },
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: t('back')
      },
      {
        type: 'next',
        text: t('next')
      }
    ],
    when: {
      show: () => {
        // const currentStep = Shepherd.activeTour?.getCurrentStep();
        // const currentStepElement = currentStep?.getElement();
        const shepherdElements = document.querySelectorAll('[data-shepherd-step-id="SpeedControl"]');
        if(shepherdElements.length === 0) {
          return;
        }

        const currentStepElement = shepherdElements.item(0);
        if(!currentStepElement) {
          return;
        }

        const footer = currentStepElement.querySelector('.shepherd-footer');
        const progress = document.createElement('span');
        progress.className = 'shepherd-progress';
        // progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1} of ${Shepherd.activeTour?.steps.length}`;
        // progress.innerText = `Tip 2 of 3`;
        progress.innerHTML = t('tip', { number: '2', total: '3' });

        footer?.insertBefore(progress, currentStepElement.querySelector('.shepherd-button:first-child'));
      },
      hide: () => {
        console.log('hide step');
      }
    },
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 12, crossAxis: 0 })]
    }
  };

  return SpeedControlTourStep;
};

export default SpeedControlTourStepFactory;