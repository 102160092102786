import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

import BaseDevice from '../../BaseDevice';

interface AirturRemoteMenuProps {
  device?: BaseDevice;
  deviceMenuAnchor: HTMLDivElement | undefined;
  setDialogOpen: (open: boolean) => void;
  onClose: (closeAll?: boolean) => void;
}

const AirturRemoteMenu = (props: AirturRemoteMenuProps) => {
  const { device, deviceMenuAnchor, setDialogOpen, onClose } = props;

  const { t } = useTranslation('prompter');

  return (<Menu
    id="airturn-remote-menu"
    anchorEl={deviceMenuAnchor}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}            
    open={Boolean(deviceMenuAnchor)}
    keepMounted={false}
    onClose={() => {
      onClose();
    }}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
  >
    {/* <MenuItem onClick={() => {
      setDialogOpen(true);
    }}>
      <ListItemIcon>
        <SettingsIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Configure{/-*t('helpmenu.welcometour')*-/}</ListItemText>
    </MenuItem>
    <Divider /> */}
    <MenuItem onClick={() => {
      onClose(true);
      device?.disconnect();
    }}>
      {/* <ListItemIcon>
        <CloseIcon fontSize="small" />
      </ListItemIcon> */}
      <ListItemText inset={false}>Disconnect{/*t('helpmenu.welcometour')*/}</ListItemText>
    </MenuItem>
  </Menu>);
};

export default AirturRemoteMenu;