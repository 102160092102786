import { BaseControlMessage } from './BaseControlMessage';
export const PLAYMESSAGE_KEY = 'prompter.state.play';
/**
 * Represents the PlayMessage type.
 */
export class PlayMessage extends BaseControlMessage {
    constructor() {
        super();
        this.type = PLAYMESSAGE_KEY; // 'prompter.state.play';
        this.play = true;
    }
    static fromJson(data) {
        if (data.type !== PLAYMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for PlayMessage.');
        }
        const input = data;
        const instance = new PlayMessage();
        instance.deserializeSenderInfo(data.sender);
        instance.play = input.play;
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            play: this.play,
        };
    }
}
PlayMessage.MESSAGE_NAME = PLAYMESSAGE_KEY; // 'prompter.state.play';
