import React, { createRef, useCallback, useEffect, useState } from 'react';

import { PrompterViewportProps } from '..';

import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import RepeatIcon from '@mui/icons-material/Repeat';
import ShotLogButton from '../ShotLogModal/ShotLogButton';

import { NavigateMessage } from '@fluidprompter/core';
import { useAppController } from '../../../controllers/AppController';
import { Trans, useTranslation } from 'react-i18next';
import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import useResizeObserver from '@react-hook/resize-observer';
import _ from 'lodash';
import classNames from 'classnames';
import './EndElementOffScript.scss';

const EndElementOffScript = (props: PrompterViewportProps) => {
  const { flipHorizontal, flipVertical, contentWidth, leftGutter, textSize } = props;

  const { t } = useTranslation('prompter');

  const prompterSession = usePrompterSession(state => ({
    textMetrics: state.textMetrics,
    getNodeTextMetricsByPath: state.getNodeTextMetricsByPath,
    scriptNodesMeta: state.scriptNodesMeta,
    // f: state.setScriptNodesMeta
    getNodeMetaByPath: state.getNodeMetaByPath,
    isAtEndOfScript: state.isAtEndOfScript,
  }), shallow);
  const { isAtEndOfScript } = prompterSession;

  const shotloggingEnabled = usePrompterSession(state => state.shotloggingEnabled);

  const appController = useAppController();

  let endElementTop = 0;
  if(prompterSession.scriptNodesMeta) {
    const { nodesMeta } = prompterSession.scriptNodesMeta;
    const rootNodesCount = nodesMeta.length;
    const lastRootNodeMeta = nodesMeta[rootNodesCount - 1];
    endElementTop = lastRootNodeMeta.top;
  }

  const [autoContinueMilliseconds, setAutoContinueMilliseconds] = useState<number | undefined>();

  const endElementRef = createRef<HTMLDivElement>();
  const viewportHeight = prompterSession.scriptNodesMeta?.viewportHeight || 0;
  const [stickyTop, setStickyTop] = useState<number>(0);

  //
  // Whenever the WordLimit container is resized, we need to re-evaluate our container breakpoint
  // based on the information in the current language translation.
  //
  const requestCalculatePosition = useCallback(_.debounce(async (e?: ResizeObserverEntry) => {
    const endElementEl = endElementRef.current;
    if(!endElementEl) {
      return;
    }

    setStickyTop(flipVertical ? viewportHeight - endElementEl.clientHeight - 2 : 2);
  }, 50, {
    leading: false,
    trailing: true,
  }), [endElementRef, t, flipVertical, viewportHeight, endElementTop, setStickyTop]);

  //
  // When the element is resized, we need to recalculate it's position.
  //
  useResizeObserver(endElementRef, requestCalculatePosition);

  //
  // When the user toggles screen flip, the element will not "resize" but still needs its position
  // recalculated.
  //
  useEffect(() => {
    requestCalculatePosition();
  }, [requestCalculatePosition, flipHorizontal, flipVertical, prompterSession.scriptNodesMeta]);

  //
  // If we don't yet have an endElementTop value - we haven't yet had a chance to measure our
  // script content (prompterSession.scriptNodesMeta is undefined).
  //
  if(endElementTop === 0) {
    return (<></>);
  }

  return (<div
    className={classNames('EndElementOffScriptContainer', {
      FlipHorizontal: flipHorizontal,
      FlipVertical: flipVertical,
    })}
    style={{
      top: flipVertical ? 0 : endElementTop,
      bottom: flipVertical ? endElementTop : 0,
      width: `${contentWidth}%`,
      marginLeft: `${leftGutter}%`,
      fontSize: `${textSize}pt`,
    }}
  >
    <div
      ref={endElementRef}
      className={classNames('EndElementOffScript', {
        isEndOfScript: isAtEndOfScript,
      })}
      style={{
        top: `${stickyTop}px`,
      }}
    >
      <h1>{t('endelement.defaultcaption')}</h1>
      <div className={classNames('EndElementActionsInline')}>
        {shotloggingEnabled && <ShotLogButton
          className="ShotLogButton FlexReverse"
          // ref={menuAnchorRef}
          endIcon={<SpeakerNotesIcon />}
          // disabled={isPlaying}
          highlighted={isAtEndOfScript}
          // aria-expanded={menuOpen ? 'true' : undefined}
          aria-label="Additional shot logging options"
          aria-haspopup="menu"
          onFocus={(e) => { e.target.blur(); }}
          onClick={() => {
            setAutoContinueMilliseconds(undefined);

            appController.dispatchMessage('prompter.shotlog.show');
          }}
        >Review<br/>Shot Log</ShotLogButton>}

        <ShotLogButton
          className="ShotLogButton FlexReverse"
          endIcon={<RepeatIcon />}
          // disabled={isPlaying}
          highlighted={isAtEndOfScript}
          autoClickMilliseconds={autoContinueMilliseconds}
          onFocus={(e) => { e.target.blur(); }}
          onClick={() => {
            setAutoContinueMilliseconds(undefined);

            appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Start));
          }}
        >
          <Trans>{t('endelement.restartscript')}</Trans>
        </ShotLogButton>
      </div>
    </div>
  </div>);
};

export default EndElementOffScript;