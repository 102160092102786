import { BaseControlMessage } from './BaseControlMessage';
export const SETSCROLLSPEEDMESSAGE_KEY = 'setscrollspeed';
/**
 * Represents the SetScrollSpeedMessage type.
 */
export class SetScrollSpeedMessage extends BaseControlMessage {
    constructor(proposedScrollSpeed, reverseMode) {
        super();
        this.type = SETSCROLLSPEEDMESSAGE_KEY;
        let newScrollSpeed = proposedScrollSpeed;
        if (newScrollSpeed < 0) {
            newScrollSpeed = 0;
        }
        if (newScrollSpeed > 500) {
            newScrollSpeed = 500;
        }
        this.speed = newScrollSpeed;
        this.reverse = reverseMode;
    }
    static fromJson(data) {
        if (data.type !== SETSCROLLSPEEDMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for SetScrollSpeedMessage.');
        }
        const input = data;
        if (input.sender?.s === undefined) {
            throw new SyntaxError('Invalid JSON for SetScrollSpeedMessage. Missing sender.');
        }
        const instance = new SetScrollSpeedMessage(input.sender.s, input.sender.sr);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
        };
    }
}
SetScrollSpeedMessage.MESSAGE_NAME = SETSCROLLSPEEDMESSAGE_KEY;
