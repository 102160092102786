import React from 'react';
import { Descendant } from 'slate';
import { ElementTypes } from '../../../models/EditorTypes';

import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';

import './SegmentInsertToolbar.scss';

const StyledButton = styled(Button)({
  color: 'inherit',
  padding: '5px 11px',
  fontSize: '0.5em',
  /*verticalAlign: 'unset',*/
  // backgroundColor: 'rgba(0, 0, 0, 0.75)',
  '&:hover': {
    backgroundColor: 'rgba(28, 28, 28, 0.75);',
  },
});

interface SegmentInsertToolbarProps {
  onInsertNode: (node: Descendant) => void;
}

function SegmentInsertToolbar(props: SegmentInsertToolbarProps) {

  const { t } = useTranslation('prompter');

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className='SegmentInsertToolbar'
        contentEditable={false}
      >
        <div className='ToolbarContents'>
          <StyledButton 
            size="large"
            startIcon={ <AddIcon /> }
            onFocus={(e) => { e.target.blur(); }}
            onClick={handleMenu}
          >
            {t('inserttoolbar.caption')}
          </StyledButton>
          <Menu
            id="bluetooth-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={
              () => {
                setAnchorEl(null);
                // props.onInsertSegment();
                const proposedNode: Descendant = {
                  type: ElementTypes.SCRIPT_SEGMENT,
                  children: [{
                    type: ElementTypes.PARAGRAPH,
                    children: [{
                      text: 'New segment...'
                    }]
                  }]
                };
                props.onInsertNode(proposedNode);
              }
            }>{t('segmentelement.defaultcaption')}</MenuItem>
            <MenuItem onClick={
              () => {
                setAnchorEl(null);
                // props.onInsertPause();
                const proposedNode: Descendant = {
                  type: ElementTypes.PAUSE,
                  children: [{ text: '' }]
                };
                props.onInsertNode(proposedNode);
              }
            }>{t('pauseelement.defaultcaption')}</MenuItem>
            {/*<MenuItem onClick={
              () => {
                setAnchorEl(null);
                // props.onInsertPause();
                const proposedNode: BRoll = {
                  type: ElementTypes.BROLL,
                  description: '',
                  children: [{ text: "" }]
                };
                props.onInsertNode(proposedNode);
              }
            }>BRoll Note</MenuItem>*/}
          </Menu>
        </div>
      </div>
    </>
  );
}

export default SegmentInsertToolbar;