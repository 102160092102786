
import React, { useState } from 'react';

import { DeviceComponent, DeviceComponentProps } from '../../../BaseDevice';
import ShuttleProMenu from './ShuttleProMenu';
// import ShuttleProDialog from './ShuttleProDialog';

const ShuttleProUI: DeviceComponent = (props: DeviceComponentProps) => {
  const { onClose } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (<>
    <ShuttleProMenu {...props} setDialogOpen={setDialogOpen} />
    {/* {dialogOpen && <ShuttleProDialog onClose={() => {
      // Close dialog - should we close menu too?
      setDialogOpen(false);
      onClose();
    }} />} */}
  </>);
};

export default ShuttleProUI;