import React, { useRef } from 'react';
import {
  Editor,
  Transforms,
  Text,
  Descendant,
  Range, Node, Path, BaseSelection, BaseRange,
} from 'slate';
import { useSlate, useFocused } from 'slate-react';
import { PrompterEditor, PrompterElement } from '../../../models/EditorTypes';

import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import HoveringToolbarToggleButtonGroup from './HoveringToolbarToggleButtonGroup';
import HoveringToolbarToggleButton from './HoveringToolbarToggleButton';
import HoveringToolbarButton from './HoveringToolbarButton';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import HighlightIcon from '@mui/icons-material/Highlight';
import FormatClearIcon from '@mui/icons-material/FormatClear';

import FormatColorTextIcon from '@mui/icons-material/FormatColorText';  // For Text color
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';  // For background color or fill color

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';  // This will be a block level element rather than a text style.
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';  // This will be a block level element rather than a text style.
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';  // This will be a block level element rather than a text style.

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { ArrowLeftCircle, Crown } from 'lucide-mui';

import { isMacOs, isIOS } from 'react-device-detect';

const CtrlOrCmdString = (isMacOs || isIOS) ? 'Cmd' : 'Ctrl';

const HoveringToolbarSelect = styled(Select)({
  '&:before': {
    border: 0,
  }
});

/*
function changeBlockType(editor: PrompterEditor, selection: BaseRange) {
  Editor.withoutNormalizing(editor, () => {

    const nodesMatchedWithFormat = Editor.nodes(editor, {
      at: selection,
      match: (n: Node) => {
        const node = n as PrompterText;
        return node[mark] === true;
      },
      mode: 'all',
    });

    const isActive = isBlockActive(editor, type);
    const isList = LIST_TYPES.includes(type);

    Transforms.unwrapNodes(editor, {
      match: n =>
        LIST_TYPES.includes(
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
        ),
      split: true,
    });
    const newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : type,
    };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: type, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  });
}
*/

interface SelectionCollapsedToolsProps {
  editor: PrompterEditor;
  selection: BaseRange;
}

const SelectionCollapsedTools = (props: SelectionCollapsedToolsProps) => {
  const { editor, selection } = props;

  const [age, setAge] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleBlockTypeChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    // appController.dispatchMessage('prompter.editor.focusatcue');
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // Collapsed:
  // + | Change Block Type | Image | @ | Comment | Format | Delete | Undo | Redo
  //
  // Expanded:
  // << B I U S A | Comment | Link | Code | Equation
  return (<>
    {/*<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Age</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={''}
        label="Age"
        // onChange={handleBlockTypeChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}><ArrowLeftCircle />Paragraph</MenuItem>
        <MenuItem value={20}>Quote</MenuItem>
      </Select>
    </FormControl>*/}
    {/*<Crown />*/}
    {/*<Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <HoveringToolbarSelect
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          variant='standard'
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          value={age}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
            transformOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            }
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </HoveringToolbarSelect>
      </FormControl>
    </Box>*/}
  </>);
};

export default SelectionCollapsedTools;