import { useEffect, useState } from 'react';
import NoSleep from 'nosleep.js';

type ListenerType = () => void;
type NoSleepSetter = (nextGlobalState: boolean) => void;
type NoSleepHook = () => [state: boolean, setNoSleepEnabled: NoSleepSetter];

interface NoSleepExports {
  setNoSleepEnabled: NoSleepSetter;
  useNoSleepState: NoSleepHook;
}

const createContainer = (): NoSleepExports => {
  let globalNoSleepState = false;
  const noSleep = new NoSleep();
  const listeners = new Set<ListenerType>();

  const setNoSleepEnabled: NoSleepSetter = (nextGlobalState: boolean) => {
    if(globalNoSleepState === nextGlobalState) {
      // Don't do anything if we are being requested for the existing state.
      return;
    }

    if (nextGlobalState) {
      noSleep.enable();
    } else {
      noSleep.disable();
    }

    globalNoSleepState = nextGlobalState;
    listeners.forEach(listener => listener());
  };

  const useNoSleepState: NoSleepHook = () => {
    const [state, setState] = useState(globalNoSleepState);

    useEffect(() => {
      const listener = () => {
        setState(globalNoSleepState);
      };
      listeners.add(listener);
      listener(); // in case it's already changed

      // cleanup
      return () => {
        listeners.delete(listener);
      };
    }, [state]);

    return [state, setNoSleepEnabled];
  };

  return {
    setNoSleepEnabled,
    useNoSleepState,
  };
};

const singletonInstance: NoSleepExports = createContainer();
export default singletonInstance;