import BaseControlMessage from './BaseControlMessage';
/**
 * Represents the TestMessage type.
 */
export class TestMessage extends BaseControlMessage {
    constructor() {
        super(...arguments);
        this.type = TestMessage.MESSAGE_NAME;
    }
    static fromJson(data) {
        const instance = new TestMessage();
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
        };
    }
}
TestMessage.MESSAGE_NAME = 'prompter.test';
