import React from 'react';
import Collapse from '@mui/material/Collapse';
import { useAppController } from '../../../controllers/AppController';

import Stack from '@mui/material/Stack';
// import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';

import DeviceAvatar from './DeviceAvatar';
import DeviceListItemButton from './DeviceListItemButton';
import { DeviceConnectionType, IDeviceState } from '../../../devices/BaseDevice';
import { IPrompterState } from '../../../devices/prompterpeer/PrompterPeerInstance';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

// import BatteryUnknown from '@mui/icons-material/BatteryUnknown';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';

import Battery20Icon from '@mui/icons-material/Battery20';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryFull from '@mui/icons-material/BatteryFull';

import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import BatteryCharging30Icon from '@mui/icons-material/BatteryCharging30';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import BatteryChargingFull from '@mui/icons-material/BatteryChargingFull';

// import SwapVertIcon from '@mui/icons-material/SwapVert';
import CloudIcon from '@mui/icons-material/Cloud';
// import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
// import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import LeakAddIcon from '@mui/icons-material/LeakAdd';        // For Peer Connection followed by latency
import LeakRemoveIcon from '@mui/icons-material/LeakRemove';  // For Peer non-connected
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';
import LanIcon from '@mui/icons-material/Lan';
import NetworkPingIcon from '@mui/icons-material/NetworkPing';
import CircularProgress from '@mui/material/CircularProgress';

import KeyboardIcon from '@mui/icons-material/Keyboard';
import CableIcon from '@mui/icons-material/Cable';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
// import BluetoothIcon from '@mui/icons-material/Bluetooth';
import BluetoothConnectedIcon from '@mui/icons-material/BluetoothConnected';
import { AppLifecycleState, ConnectionState } from '@fluidprompter/core';
// import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';

import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SettingsIcon from '@mui/icons-material/Settings';

import './PeerDevice.scss';

const getBatteryIcon = (batteryPercentage: number | undefined, batteryCharging: boolean) => {

  let batteryColor = '#4caf50'; // GREEN
  if (batteryPercentage === undefined) {
    batteryColor = 'rgba(0, 0, 0, 0.26)'; // GREY
    // rgba(0, 0, 0, 0.6) // DARK GREY
  } else if (batteryPercentage <= 20) {
    batteryColor = '#ff0000'; // RED  // ef5350
  } else if (batteryPercentage <= 50) {
    batteryColor = '#ffeb3b'; // YELLOW
  }

  const iconStyles = {  // : SxProps<Theme>
    color: batteryColor,
    // fontSize: 16,
    // verticalAlign: 'text-bottom'
    verticalAlign: 'middle'
  };

  // if(batteryPercentage === undefined || batteryCharging === undefined) {
  //   // We have no battery information to display...
  //   return (<><BatteryUnknown sx={iconStyles} fontSize="small" /></>);
  // }

  let batteryIcon: React.ReactNode;
  // Not charging... so let's evaluate the battery level to determine color.
  if(batteryPercentage === undefined) {
    // Unknown battery status - don't show a battery icon.
    batteryIcon = undefined;
  } else if (batteryPercentage < 0) {
    // This means the device does not report battery status.
    batteryIcon = undefined;
  } else if (!batteryCharging && batteryPercentage <= 10) {
    batteryIcon = <BatteryAlertIcon sx={iconStyles} fontSize="small" />;
  } else if (batteryPercentage <= 20) {
    batteryIcon = batteryCharging ? <BatteryCharging20Icon sx={iconStyles} fontSize="small" /> : <Battery20Icon sx={iconStyles} fontSize="small" />;
  } else if (batteryPercentage <= 30) {
    batteryIcon = batteryCharging ? <BatteryCharging30Icon sx={iconStyles} fontSize="small" /> : <Battery30Icon sx={iconStyles} fontSize="small" />;
  } else if (batteryPercentage <= 50) {
    batteryIcon = batteryCharging ? <BatteryCharging50Icon sx={iconStyles} fontSize="small" /> : <Battery50Icon sx={iconStyles} fontSize="small" />;
  } else if (batteryPercentage <= 60) {
    batteryIcon = batteryCharging ? <BatteryCharging60Icon sx={iconStyles} fontSize="small" /> : <Battery60Icon sx={iconStyles} fontSize="small" />;
  } else if (batteryPercentage <= 80) {
    batteryIcon = batteryCharging ? <BatteryCharging80Icon sx={iconStyles} fontSize="small" /> : <Battery80Icon sx={iconStyles} fontSize="small" />;
  } else if (batteryPercentage <= 98) {
    batteryIcon = batteryCharging ? <BatteryCharging90Icon sx={iconStyles} fontSize="small" /> : <Battery90Icon sx={iconStyles} fontSize="small" />;
  } else {
    batteryIcon = batteryCharging ? <BatteryChargingFull sx={iconStyles} fontSize="small" /> : <BatteryFull sx={iconStyles} fontSize="small" />;
  }

  return batteryIcon;
};

const batteryStatus = (connectedDevice: IPrompterState) => {
  const { batteryPercentage, batteryCharging, connectionType } = connectedDevice;

  const batteryIcon = getBatteryIcon(batteryPercentage, batteryCharging === true);

  return (<>
    {(connectionType === DeviceConnectionType.Hid) && <CableIcon sx={{
      color: '#0082FC',  // 'rgba(0, 0, 0, 0.3)',
      verticalAlign: 'middle'
    }} fontSize="small" />}
    {(connectionType === DeviceConnectionType.Gamepad) && <SportsEsportsIcon sx={{
      color: '#0082FC',  // 'rgba(0, 0, 0, 0.3)',
      verticalAlign: 'middle'
    }} fontSize="small" />}
    {(connectionType === DeviceConnectionType.Bluetooth) && <BluetoothConnectedIcon sx={{
      color: '#0082FC',  // 'rgba(0, 0, 0, 0.3)',
      verticalAlign: 'middle'
    }} fontSize="small" />}
    {(connectionType === DeviceConnectionType.Keyboard) && <KeyboardIcon sx={{
      color: '#0082FC',  // 'rgba(0, 0, 0, 0.3)',
      verticalAlign: 'middle'
    }} fontSize="small" />}
    {batteryIcon && <Stack direction={'row'}>
      {batteryIcon} <span>{batteryPercentage}%</span>
    </Stack>}
  </>);
};


const getCloudIcon = (connectionState?: ConnectionState, mr?: number) => {
  let cloudIcon: React.ReactNode;
  switch(connectionState) {
    case ConnectionState.Connected:
      cloudIcon = (<CloudIcon sx={{
        color: '#0082FC',
        verticalAlign: 'middle',
        mr,
      }} fontSize="small" />);
      break;
    case ConnectionState.Unknown:
    case ConnectionState.Connecting:
    case ConnectionState.Disconnecting:
    case ConnectionState.Disconnected:
    case ConnectionState.Reconnecting:
      cloudIcon = (<CloudOffIcon sx={{
        color: '#0082FC',
        verticalAlign: 'middle',
        mr,
      }} fontSize="small" />);
      break;
  }
  return cloudIcon;
};

/**
 * Returns a cloud icon and latency information if relevant for this device.
 * @param connectedDevice
 */
const cloudStatus = (connectedDevice: IPrompterState) => {
  if(connectedDevice.cloudConnectionState === undefined) {
    // This device does not have a cloud connection. We don't want to show any kind of cloud
    // connection state UI elements.
    return;
  }

  const cloudIcon = getCloudIcon(connectedDevice.cloudConnectionState);

  let cloudLatency: React.ReactNode = (<span>{connectedDevice.cloudLatency}<sup>ms</sup></span>);
  if(
    connectedDevice.cloudLatency === undefined
    || connectedDevice.cloudConnectionState === ConnectionState.Connecting
    || connectedDevice.cloudConnectionState === ConnectionState.Reconnecting
  ) {
    // cloudLatency = (<CircularProgress sx={{
    //   verticalAlign: 'middle'
    // }} size={'1.25em'} />);
    cloudLatency = (<span>n/c</span>);
  }

  return (<Stack direction={'row'} spacing={0.5} alignItems={'center'}>
    {cloudIcon}
    {cloudLatency}
  </Stack>);
};

const getPeerIcon = (connectionState?: ConnectionState, mr?: number) => {
  let peerIcon: React.ReactNode;
  switch(connectionState) {
    case ConnectionState.Connected:
      peerIcon = (<LeakAddIcon sx={{
        color: '#0082FC',
        verticalAlign: 'middle',
        mr,
      }} fontSize="small" />);
      break;
    case ConnectionState.Connecting:
    case ConnectionState.Disconnecting:
    case ConnectionState.Disconnected:
    case ConnectionState.Reconnecting:
    case ConnectionState.Unknown:
      peerIcon = (<LeakRemoveIcon sx={{
        color: '#ff0000',
        verticalAlign: 'middle',
        mr,
      }} fontSize="small" />);
      break;
  }
  return peerIcon;
};

const peerStatus = (connectedDevice: IPrompterState) => {
  // We will only show a peer connection state icon if the connection state is either 'connecting'
  // or 'connected' (we are either in progress measuring the connection RTT or have a measurement).
  if(
    connectedDevice.connectionState !== ConnectionState.Connecting
    && connectedDevice.connectionState !== ConnectionState.Connected
  ) {
    return;
  }

  const peerIcon = getPeerIcon(connectedDevice.connectionState, 0);

  let peerLatency: React.ReactNode = (<span>{connectedDevice.peerLatency}<sup>ms</sup></span>);
  if(
    connectedDevice.peerLatency === undefined
    || connectedDevice.connectionState === ConnectionState.Connecting
  ) {
    peerLatency = (<CircularProgress sx={{
      verticalAlign: 'middle'
    }} size={'1.25em'} />);
  }

  return (<Stack direction={'row'} spacing={0.5} alignItems={'center'}>
    {peerIcon}
    {/* <AirlineStopsIcon sx={{
      color: '#ff0000',
      verticalAlign: 'middle'
    }} fontSize="small" /> */}
    {peerLatency}
  </Stack>);
};


const deviceStatusRow = (connectedDevice: IPrompterState) => {

  if(
    connectedDevice.cloudConnectionState === ConnectionState.Disconnected
      && connectedDevice.connectionState === ConnectionState.Disconnected
  ) {
    // This peer has both been disconnected from the cloud and peer connection.
    return (<Stack
      display={'inline-block'}
      direction={'row'}
      spacing={1}
      alignItems={'center'}
      // bgcolor={'rgb(253, 237, 237)'}
      color={'rgb(95, 33, 32)'}
      borderRadius={'8px'}
      // border={'1px solid rgba(95, 33, 32, 0.6)'}
      // paddingX={1}
      // paddingY={0.5}
    >
      <WifiOffOutlinedIcon sx={{
        // color: '#0082FC',
        color: 'rgb(95, 33, 32)',
        verticalAlign: 'middle',
      }} fontSize="small" />
      <span>Disconnected</span>
    </Stack>);
  }
  return (<Stack direction={'row'} spacing={1}>
    {/* <Crown style={{
      color: '#f1c40f',
      verticalAlign: 'middle'
    }} fontSize="small" /> */}

    {batteryStatus(connectedDevice)}
    {cloudStatus(connectedDevice)}
    {peerStatus(connectedDevice)}
    {connectedDevice.appState === AppLifecycleState.Hidden && (<VisibilityOffIcon style={{
      // color: '#4caf50',
      color: '#d32f2f',  // '#aaaaaa',
      // background: '#fff',
      borderRadius: '50%',
      border: 0,
      verticalAlign: 'bottom'
    }} fontSize='small' />)}
  </Stack>);
};

interface PeerDeviceProps {
  depth: number;
  device: IDeviceState;
  index: number;
  array: IDeviceState[];
  hasChildren: boolean;
  expanded: boolean;
  onExpand: () => void;
}

const PeerDevice = (props: PeerDeviceProps) => {
  const { depth, device: device2, index, array, hasChildren, expanded } = props;
  const deviceState = device2 as IPrompterState;

  const appController = useAppController();

  const handleDeviceClick = (
    event: React.MouseEvent<HTMLDivElement>,
    deviceId: string,
  ) => {
    if(deviceState.connectionState !== undefined) {
      props.onExpand();
      return;
    }

    appController.dispatchMessage('devices.devicemenu.open', {
      anchorEl: event.currentTarget,
      deviceId
    });
  };

  const handleManageDeviceClick = (
    event: React.MouseEvent<HTMLDivElement>,
    deviceId: string,
  ) => {
    appController.dispatchMessage('devices.managedevice', {
      deviceId
    });
  };

  const fullyDisconnected = (
    deviceState.cloudConnectionState === ConnectionState.Disconnected
      && deviceState.connectionState === ConnectionState.Disconnected
  );

  let badgeContent: React.ReactNode = undefined;
  if(deviceState.endpointRole !== undefined) {
    badgeContent = (<PlayCircleIcon style={{
      color: '#4caf50',
      background: '#fff',
      borderRadius: '50%',
      border: 0,
      verticalAlign: 'bottom'
    }} />);
  }

  return (<>
    <DeviceListItemButton
      className='PeerDeviceButton'
      depth={depth}
      isLast={index >= (array.length - 1)}
      expanded={expanded}
      showBorder={deviceState.connectionState !== undefined}
      onClick={(event) => handleDeviceClick(event, deviceState.id)}
    >
      {deviceState.icon && <ListItemAvatar>
        <DeviceAvatar /*src={connectedDevice.icon} MuiAvatar-img */
          variant="square"
          style={{
            opacity: fullyDisconnected ? '0.6' : '1',
          }}
          badgeContent={badgeContent}
          badgeVisible={deviceState.isLeader}
        >
          <img src={deviceState.icon} className="DeviceAvatarImage"/>
          {deviceState.iconOverlay && <span className="DeviceAvatarOverlay">{deviceState.iconOverlay}</span>}
        </ DeviceAvatar>
      </ListItemAvatar>}
      <ListItemText
        primary={deviceState.name}
        primaryTypographyProps={{
          style: {
            opacity: fullyDisconnected ? '0.6' : '1',
          }
        }}
        secondary={deviceStatusRow(deviceState)}
        secondaryTypographyProps={{
          component: 'span',
        }}
      />
      <ListItemSecondaryAction style={{ height: '24px' }}>
        {props.expanded ? <ExpandLess /> : <ChevronRightIcon />}
      </ListItemSecondaryAction>
    </DeviceListItemButton>
    <Collapse
      key={`${deviceState.id}-menu`}
      sx={{
        pt: 0,
        pr: 2,
        pb: 1,
        pl: 7,
        position: 'relative',
      }}
      in={props.expanded}
      timeout="auto"
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {hasChildren && <div style={{
        position: 'absolute',
        width: '12px',
        height: '100%',
        top: 0, left: (2 * 16),
        borderLeft: '1px solid #BDBDBD',
      }}></div>}
      <List
        disablePadding
        sx={{
          background: 'rgba(25, 118, 210, 0.08)',
          'li': {
            borderLeft: '1px solid #BDBDBD',
            borderRight: '1px solid #BDBDBD',
            borderBottom: '1px solid #BDBDBD',
          },
          'li:last-child': {
            borderRadius: '0 0 6px 6px'
          }
        }}
      >
        {/* {(depth > 0) && !isLast && <div style={{
          position: 'absolute',
          width: '12px',
          height: '50%',
          top: '50%', left:  (depth * 16),
          borderLeft: '1px solid #BDBDBD',
        }}></div>} */}
        {deviceState.osName !== undefined && <ListItem dense>
          <ListItemText>{`${deviceState.osName} ${deviceState.osVersion}, ${deviceState.browserName} ${deviceState.browserVersion}`}</ListItemText>
        </ListItem>}

        {deviceState.cloudConnectionState !== undefined && <ListItem dense>
          {getCloudIcon(deviceState.cloudConnectionState, 1)}
          {deviceState.cloudConnectionState === ConnectionState.Connected && <ListItemText>Cloud Latency{/*t('helpmenu.welcometour')*/}</ListItemText>}
          {deviceState.cloudConnectionState !== ConnectionState.Connected && <ListItemText>Cloud Disconnected{/*t('helpmenu.welcometour')*/}</ListItemText>}
          {deviceState.cloudConnectionState === ConnectionState.Connected && <Typography component="span" sx={{
            sup: {
              color: 'rgba(0, 0, 0, 0.6);', // '#0082FC',
              verticalAlign: 'top'
            }
          }}>{deviceState.cloudLatency} <sup>ms</sup></Typography>}
        </ListItem>}

        {(deviceState.connectionState === ConnectionState.Connecting || deviceState.connectionState === ConnectionState.Connected) && <ListItem dense>
          {getPeerIcon(deviceState.connectionState, 1)}
          {deviceState.connectionState === ConnectionState.Connected && <ListItemText>Peer Latency{/*t('helpmenu.welcometour')*/}</ListItemText>}
          {deviceState.connectionState !== ConnectionState.Connected && <ListItemText>Peer Disconnected{/*t('helpmenu.welcometour')*/}</ListItemText>}
          {deviceState.connectionState === ConnectionState.Connected && <Typography component="span" sx={{
            sup: {
              color: 'rgba(0, 0, 0, 0.6);', // '#0082FC',
              verticalAlign: 'top'
            }
          }}>{deviceState.peerLatency} <sup>ms</sup></Typography>}
        </ListItem>}

        {deviceState.peerDistance !== undefined && <ListItem dense>
          <SocialDistanceIcon sx={{
            color: '#0082FC',
            verticalAlign: 'middle',
            mr: 1
          }} fontSize="small" />
          <ListItemText>Peer Distance</ListItemText>
          {<Typography component="span" sx={{
            sup: {
              color: 'rgba(0, 0, 0, 0.6);', // '#0082FC',
              verticalAlign: 'top'
            }
          }}>~{deviceState.peerDistance} <sup>km</sup></Typography>}
        </ListItem>}

        {deviceState.peerIsLocal !== undefined && <ListItem dense>
          <LanIcon sx={{
            color: '#0082FC',
            verticalAlign: 'middle',
            mr: 1
          }} fontSize="small" />
          <ListItemText>Local Connection</ListItemText>
          {<Typography component="span" sx={{
            sup: {
              color: 'rgba(0, 0, 0, 0.6);', // '#0082FC',
              verticalAlign: 'top'
            }
          }}>{deviceState.peerIsLocal ? 'yes' : 'no'}</Typography>}
        </ListItem>}

        {deviceState.peerIsRelayed !== undefined && <ListItem dense>
          <NetworkPingIcon sx={{
            color: '#0082FC',
            verticalAlign: 'middle',
            mr: 1
          }} fontSize="small" />
          <ListItemText>Relayed Connection</ListItemText>
          {<Typography component="span" sx={{
            sup: {
              color: 'rgba(0, 0, 0, 0.6);', // '#0082FC',
              verticalAlign: 'top'
            }
          }}>{deviceState.peerIsRelayed ? 'yes' : 'no'}</Typography>}
        </ListItem>}

        {/*
          * This is a UI element that adds a button to each non local prompter peer with the
          * caption "Manage Device". The UI work has been completed to look consistent with
          * other read-only meta data above, but we have not yet written the "Manage Device"
          * functionality. In future feature branch we will implement a pop-up diaglog where
          * user's can rename a device, review more detailed meta data about the device such as
          * platform type, browser type, screen size, etc and ideally also be able to change
          * the prompter visual appearance settings remotely (change text size, margins, screen
          * flipping from another connected prompter peer)
          *
        {!deviceState.representsLocal && <ListItem dense disablePadding>
          <ListItemButton sx={{
            paddingX: 1,
            paddingY: 0.5,
          }}
          onClick={(event) => handleManageDeviceClick(event, deviceState.id)}
          >
            <ListItemText primary="Manage Device" primaryTypographyProps={{
              color: '#1976DC', // #0000ff
              align: 'right',
              sx: { textDecoration: 'underline', paddingRight: 0.5 },
            }} />
            <ChevronRightIcon />
          </ListItemButton>
        </ListItem>*/}
      </List>
    </Collapse>
  </>);
};

export default PeerDevice;