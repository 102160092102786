import React, { useState } from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import HelpIcon from '@mui/icons-material/Help';
import TourIcon from '@mui/icons-material/Tour';
import ConstructionIcon from '@mui/icons-material/Construction';

import { useTranslation } from 'react-i18next';
import { useAppController } from '../../controllers/AppController';

interface HelpTrayIconProps {
  onTrayItemClick?: React.MouseEventHandler<HTMLElement> | undefined;
}

const HelpTrayIcon = (props: HelpTrayIconProps) => {
  const { onTrayItemClick } = props;

  const { t } = useTranslation('prompter');

  const appController = useAppController();

  // useState will hold reference to Bluetooth Menu anchor element.
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<HTMLElement | null>(null);
  const onHelpIconClick = (e: React.MouseEvent<HTMLElement>) => {
    if(onTrayItemClick) { onTrayItemClick(e); }
    setHelpMenuAnchorEl(e.currentTarget);
  };
  const onHelpMenuClose = () => {
    setHelpMenuAnchorEl(null);
  };

  return (<>
    <Tooltip title={t('helpmenu.help')} arrow>
      <IconButton
        className="TourTargetHelpIcon"
        size="large"
        edge="start"
        color="inherit"
        aria-label={t('helpmenu.help')}
        onFocus={(e) => { e.target.blur(); }}
        onClick={onHelpIconClick}
      >
        <HelpIcon />
      </IconButton>
    </Tooltip>
    <Menu
      id="help-menu"
      anchorEl={helpMenuAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(helpMenuAnchorEl)}
      onClose={onHelpMenuClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        sx: {
          width: 240,
          maxWidth: '100%'
        }
      }}
    >
      <MenuItem
        onClick={() => {
          onHelpMenuClose();
          appController.dispatchMessage('prompter.local.tour');
        }}
      >
        <ListItemIcon>
          <TourIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('helpmenu.welcometour')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          onHelpMenuClose();
          appController.dispatchMessage('prompter.local.showhelp');
        }}
      >
        <ListItemIcon>
          <HelpIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('helpmenu.showhelp')}</ListItemText>
        <Typography variant="body2" color="text.secondary">
          Alt+H
        </Typography>{/* Win: ⎇   Mac: ⌥ */}
      </MenuItem>
      {process.env.REACT_APP_ENV === 'development' && <Divider />}
      {process.env.REACT_APP_ENV === 'development' &&
        <MenuItem
          onClick={() => {
            onHelpMenuClose();
            appController.dispatchMessage('prompter.local.showdebugtools');
          }}
        >
          <ListItemIcon>
            <ConstructionIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('helpmenu.debugtools')}</ListItemText>
          {/* <Typography variant="body2" color="text.secondary">
            Alt+H
          </Typography> */}
        </MenuItem>
      }
    </Menu>
  </>);
};

export default HelpTrayIcon;