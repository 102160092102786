import { BaseControlMessage } from './BaseControlMessage';
export const PEERPINGMESSAGE_KEY = 'peer.ping';
/**
 * Represents the PeerPingMessage type.
 */
export class PeerPingMessage extends BaseControlMessage {
    constructor(targetId) {
        super();
        this.type = PEERPINGMESSAGE_KEY; // 'prompter.state.edit';
        this.targetId = targetId;
    }
    static fromJson(data) {
        if (data.type !== PEERPINGMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for PeerPingMessage.');
        }
        const input = data;
        const instance = new PeerPingMessage(input.targetId);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            targetId: this.targetId,
        };
    }
}
PeerPingMessage.MESSAGE_NAME = PEERPINGMESSAGE_KEY;
