import { useCallback, Dispatch } from 'react';
import { ReducerActionTypes, IReducerAction } from './hookTypes';
import fetchUserProfileGlobal from './fetchUserProfileGlobal';
import { IUserProfile } from '@fluidprompter/core';

export const useFetchUserProfile = (getApiToken: () => Promise<string>, dispatch: Dispatch<IReducerAction>) => {
  const fetchUserProfile = useCallback(async (): Promise<IUserProfile | undefined> => {
    // 
    //
    const apiToken = await getApiToken();
  
    let fetchedUserProfile: IUserProfile | undefined = undefined;
    try {
      fetchedUserProfile = await fetchUserProfileGlobal(apiToken);
  
      dispatch({
        type: ReducerActionTypes.setUserProfile,
        payload: fetchedUserProfile,
      });
    } catch (err: unknown) {
      const error = err as Error;
      if(error.name === 'ERR_INTERNET_DISCONNECTED') {
        // We are currently offline.
        dispatch({
          type: ReducerActionTypes.setInternetOnline,
          payload: false,
        });
      }
    }
  
    return fetchedUserProfile;
  }, [getApiToken, dispatch]);

  return fetchUserProfile;
};


