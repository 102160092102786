import { BaseControlMessage } from './BaseControlMessage';
export const APPLYSYNCADJUTTMENT_KEY = 'prompter.applysyncadjustment';
/**
 * Represents the ApplySyncAdjustmentMessage type.
 */
export class ApplySyncAdjustmentMessage extends BaseControlMessage {
    constructor(syncAdjustmentDistance, syncAdjustmentTime) {
        super();
        this.type = APPLYSYNCADJUTTMENT_KEY;
        this.syncAdjustmentDistance = syncAdjustmentDistance;
        this.syncAdjustmentTime = syncAdjustmentTime;
    }
    static fromJson(data) {
        const instance = new ApplySyncAdjustmentMessage(data.syncAdjustmentDistance, data.syncAdjustmentTime);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            syncAdjustmentDistance: this.syncAdjustmentDistance,
            syncAdjustmentTime: this.syncAdjustmentTime,
        };
    }
}
ApplySyncAdjustmentMessage.MESSAGE_NAME = APPLYSYNCADJUTTMENT_KEY;
