import { useEffect, Dispatch } from 'react';
import { IApplicationContextState, IReducerAction, ReducerActionTypes } from './hookTypes';
import isValidIpAddress from '../../utils/isValidIpAddress';

const TIMEOUT_TIME_MS = 3000;
const POLLING_INTERVAL = 10000;

const timeout = (time: number, promise: Promise<unknown>) => {
  return new Promise(function(resolve, reject) {
    setTimeout(() => {
      reject(new Error('Request timed out.'));
    }, time);
    promise.then(resolve, reject);
  });
};

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;

  // If the browser has no network connection return offline
  if (!navigator.onLine) return navigator.onLine;

  //
  // Build our API request path, this will work for both cloudflare environments, and localhost or
  // local IP address during development.
  //
  const currentUrl = window.location.protocol + '//' + window.location.host + '/';  // window.location.pathname
  const statusUrl = new URL(process.env.REACT_APP_API_URL || currentUrl);
  if(isValidIpAddress(window.location.hostname)) {
    statusUrl.hostname = window.location.hostname;
  }
  statusUrl.pathname = '/api/status';

  //
  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch(statusUrl.href, {
        method: 'GET',
        signal
      })
    );
    return true;
  } catch (error) {
    // Error Log
    console.error(error);

    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort();
  }
  return false;
};

export const useOnlineStatus = (
  isRootHook: boolean | undefined,
  appContext: IApplicationContextState,
  dispatch: Dispatch<IReducerAction>
) => {
  const {
    networkOnline,
    internetOnline,
  } = appContext;

  useEffect(() => {
    // Only the root hook needs to monitor network changes.
    if(!isRootHook) { return; }

    //
    // The offline event of the Window interface is fired when the browser has lost access to the
    // network and the value of Navigator.onLine switches to false.
    //
    // See: https://developer.mozilla.org/en-US/docs/Web/API/Window/offline_event
    //
    const offlineEventHandler = () => {
      dispatch({
        type: ReducerActionTypes.setNetworkOnline,
        payload: false,
      });
    };

    //
    // The online event of the Window interface is fired when the browser has gained access to the
    // network and the value of Navigator.onLine switches to true.
    //
    // See: https://developer.mozilla.org/en-US/docs/Web/API/Window/online_event
    //
    const onlineEventHandler = () => {
      dispatch({
        type: ReducerActionTypes.setNetworkOnline,
        payload: true,
      });
    };

    window.addEventListener('offline', offlineEventHandler);
    window.addEventListener('online', onlineEventHandler);

    return () => {
      window.removeEventListener('offline', offlineEventHandler);
      window.removeEventListener('online', onlineEventHandler);
    };
  }, [isRootHook, dispatch]);


  useEffect(() => {
    // If we are not the first instance of the useOnlineStatus hook, then we aren't responsible
    // for checking for changes in connectivity. The root hook will inform us of changes.
    if(!isRootHook) {
      return;
    }

    //
    // If our network interface is not online, then there is no way we can test for internet
    // connectivity.
    //
    if(!networkOnline) {
      return;
    }

    //
    // If we are already online and also already believe internet is online, then we don't need to
    // re-check connectivity right now.
    //
    if(networkOnline && internetOnline) {
      return;
    }

    // Add polling incase of slow connection
    let interval_id = 0;
    const checkNow = async () => {
      const isOnline = await checkOnlineStatus();
      if(isOnline) {
        clearInterval(interval_id);
        dispatch({
          type: ReducerActionTypes.setInternetOnline,
          payload: isOnline,
        });
      }
    };
    interval_id = window.setInterval(checkNow, POLLING_INTERVAL);

    return () => {
      clearInterval(interval_id);
    };
  }, [isRootHook, networkOnline, internetOnline, dispatch]);
};