import { useCallback } from 'react';

import { Editor, Node, NodeEntry, Path } from 'slate';
import { ReactEditor } from 'slate-react';
import { ElementTypes, PrompterEditor, PrompterElement, PrompterText } from '../../models/EditorTypes';

import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession, { PrompterSessionState } from '../../state/PrompterSessionState';
import FocusGuide from '../../models/FocusGuide';

import { IViewportFuncs } from '../PrompterViewport/usePrompterViewportFuncs';
import TextAlign from '../../models/TextAlign';
import { useMessageHandler } from '../../controllers/AppController';

//
// Given a cue position on screen, find the nearest script segment that is either under the cue
// position, the next script segment after the cue position, or the last script segment in the
// prompter.
//
const findNearestScriptSegmentForCuePosition = (prompterState: PrompterSessionState, targetScrollY: number): PrompterElement | undefined => {
  let targetNodeIndex = -1;

  const scriptNodes = prompterState.scriptNodes;
  const nodesMeta = prompterState.scriptNodesMeta?.nodesMeta;
  if(nodesMeta && nodesMeta.length === scriptNodes.length) {
    let lastPrompterSegmentIndex = -1;
    targetNodeIndex = nodesMeta.findIndex((nodeMeta, nodeIndex) => {
      const currentNode = scriptNodes[nodeIndex] as PrompterElement;

      //
      // We will only consider script segments and not the StartElement, EndElement, or
      // PauseElements.
      //
      if(currentNode.type !== ElementTypes.SCRIPT_SEGMENT) {
        return false;
      }
      lastPrompterSegmentIndex = nodeIndex;

      //
      // If the cue position lies between the top and bottom of this script segment.
      //
      if(nodeMeta.top <= targetScrollY && nodeMeta.bottom >= targetScrollY) {
        // console.log(`Node ${nodeIndex} top ${nodeMeta.top} is less than targetY ${targetScrollY} and bottom ${nodeMeta.bottom} is more than targetY`);
        return true;
      }

      //
      // We will only get here, if all previous script segments did not contain the cue position
      // but the next script segment is actually after the cue position (meaning there is space
      // between the previous script segment and this one that contains the cue position.
      //
      if(nodeMeta.top > targetScrollY) {
        // console.log(`Node ${nodeIndex} top ${nodeMeta.top} is after targetY ${targetScrollY}`);
        return true;
      }

      return false;
    });

    //
    // If we have simply ran out of nodes... then pick the last Script Segment!
    // This happens if the cue position is after the last script segment node.
    //
    // If we never found a suitable prompter segment the cue position is after the last prompter
    // segment in the script. So let's just go back to the end of the last prompter segment.
    if(targetNodeIndex < 0) { targetNodeIndex = lastPrompterSegmentIndex; }

    if(targetNodeIndex >= 0) {
      return scriptNodes[targetNodeIndex] as PrompterElement;
    }
  }
};

const findFirstParagraph = (targetHTMLElement: HTMLElement): HTMLElement | null => {
  const segmentBodyEl = targetHTMLElement.closest('.SegmentElement');
  if(!segmentBodyEl) {
    return null;
  }

  const firstParagraph = segmentBodyEl.querySelector('p');
  if(!firstParagraph) {
    return null;
  }

  return firstParagraph as HTMLElement;
};


const activeContentEditable = (): Promise<void> => {
  const initialActiveElement = document.activeElement as HTMLElement;

  if(initialActiveElement.isContentEditable) {
    console.log('activeElement was ALREADY a ContentEditable');
    return Promise.resolve();
  }

  const promise = new Promise<void>((resolve, reject) => {

    //
    // Safety, just in case...
    //
    const promiseTimeout = setTimeout(() => {
      cleanupListeners();
      reject(new Error('promise activeContentEditable() Timeout'));
    }, 3000);

    //
    // This is called whenever document.activeElement changes due to a focus event or blur event.
    //
    const focusHandler = (ev: FocusEvent) => {
      const currentActiveElement = document.activeElement as HTMLElement;
      if(initialActiveElement !== currentActiveElement
        && currentActiveElement.isContentEditable)
      {
        console.log('activeElement CHANGED to a ContentEditable');
        clearTimeout(promiseTimeout);
        cleanupListeners();
        resolve();
      }
    };

    window.addEventListener('focus', focusHandler);
    window.addEventListener('blur', focusHandler);

    const cleanupListeners = () => {
      window.removeEventListener('focus', focusHandler);
      window.removeEventListener('blur', focusHandler);
    };
  });

  return promise;
};

const newDocumentSelection = (previousRange?: Range): Promise<Range> => {

  const previousRange2 = previousRange || document.getSelection()?.getRangeAt(0);

  const promise = new Promise<Range>((resolve, reject) => {

    //
    // Safety, just in case...
    //
    const promiseTimeout = setTimeout(() => {
      cleanupListeners();
      reject(new Error('promise newDocumentSelection() Timeout'));
    }, 3000);

    const selectionchangeHandler = (e: Event) => {
      const newSelection = document.getSelection();
      if(newSelection && newSelection.rangeCount) {
        const newRange = newSelection.getRangeAt(0);
        // TODO compare newRange again a passed in argument range
        if(newRange.startContainer !== previousRange2?.startContainer
          || newRange.startOffset !== previousRange2?.startOffset
          || newRange.endContainer !== previousRange2?.endContainer
          || newRange.endOffset !== previousRange2?.endOffset)
        {
          // We have a new selection range!
          console.log('We have a new selection range!');
          clearTimeout(promiseTimeout);
          cleanupListeners();
          resolve(newRange);
        }
      }
    };

    document.addEventListener('selectionchange', selectionchangeHandler);

    const cleanupListeners = () => {
      document.removeEventListener('selectionchange', selectionchangeHandler);
    };
  });

  return promise;
};

const unwrapSlateNode = (slateDOMNode: HTMLElement): HTMLElement => {
  if(slateDOMNode.hasChildNodes()) {
    return unwrapSlateNode(slateDOMNode.firstChild as HTMLElement);
  }
  return slateDOMNode;
};

//
// If our target element is a descendant of a .SegmentElementBody then we want to focus the
// beginning of the line at the prompter cue position.
//
const focusElementInsideBody = (editor: PrompterEditor, targetHTMLElement: HTMLElement, targetY: number) => {
  const elementRect = targetHTMLElement.getBoundingClientRect();
  let percentageOffset = (targetY - elementRect.top) / elementRect.height;

  console.log('original targetHTMLElement', targetHTMLElement);
  const originalNode = ReactEditor.toSlateNode(editor, targetHTMLElement);
  const originalPath = ReactEditor.findPath(editor, originalNode);
  let slateLeafNode = originalNode as PrompterText;

  let focusPath: Path = originalPath;

  //
  // Check if this node is not a leaf node. If not, then we need to find the nearest leaf node.
  //
  const slatePrompterElement = originalNode as PrompterElement;
  if(slatePrompterElement.children && slatePrompterElement.children.length) {
    // This node is not a leaf node, lets iterate over its leaf nodes and check if they are
    // under the cue position.
    const nextLeafNodeIterator = Node.texts(originalNode);

    let nextLeafNode: IteratorResult<NodeEntry<PrompterText>, void>;
    do {
      nextLeafNode = nextLeafNodeIterator.next();
      if (!nextLeafNode.done) { // Using Type discrimination to make sure we have a value: https://github.com/microsoft/TypeScript/issues/33353
        const [leafNode, leafPath] = nextLeafNode.value;
        slateLeafNode = leafNode;
        focusPath = [...originalPath, ...leafPath];

        console.log('check Node.texts node ', leafNode);

        try {
          const leafDOMNode: HTMLElement = ReactEditor.toDOMNode(editor, leafNode);
          const leafRect = leafDOMNode.getBoundingClientRect();

          // Check if this leaf node appears to be under the prompter cue position.
          if(Math.floor(leafRect.top) <= targetY && Math.ceil(leafRect.bottom) >= targetY) {
            // Recalculate the percentage offset based on the cue position within the top & bottom of this leaf node.
            percentageOffset = (targetY - leafRect.top) / leafRect.height;

            // Re-assign the target slate node/path for selection.
            slateLeafNode = leafNode;
            focusPath = [...originalPath, ...leafPath];
            console.log('Chose Node.texts node ', leafNode);
            break;
          }
        } catch(err) {
          // We don't care if there was an error. Move on with life.
          //
          // Occassionally ReactEditor.toDOMNode can't resolve a node to a DOMNode, but out
          // above code will just take the last checked PrompterText element in those cases.
        }
      }
    } while (!nextLeafNode.done);
  }

  // Let's figure out our target character offset as a percentage of the way through
  // this leaf node's content.
  const focusOffset = Math.floor(percentageOffset * slateLeafNode.text.length);

  // Update the Slate editors position.
  //const promiseSelectionRange = newDocumentSelection();

  // Transforms.select(editor, { path: focusPath, offset: focusOffset });

  //
  // First find where the cursor would be at the current scroll position.
  //
  const [focusSlateNode] = Editor.node(editor, focusPath, {
    edge: 'end'
  });
  const slateDOMNode = ReactEditor.toDOMNode(editor, focusSlateNode);
  const focusDOMNode = unwrapSlateNode(slateDOMNode);

  const docSelection = document.getSelection();
  const firstRange = document.createRange();
  firstRange.setStart(focusDOMNode, focusOffset);
  firstRange.collapse(true);
  if(docSelection) {
    docSelection.removeAllRanges();
    docSelection.addRange(firstRange);
  }
  // focusDOMNode.focus();

  //let previousSelectionRange: Range = await promiseSelectionRange;
  let previousSelectionRange: Range = firstRange;
  // await activeContentEditable();

  let previousSelectionLeft: number | undefined = previousSelectionRange.getClientRects()[0].left;
  for(let i = focusOffset; i >= 0; i--) {

    // Transforms.select(editor, { path: focusPath, offset: i });
    // ReactEditor.focus(editor);

    const newRange = previousSelectionRange.cloneRange();
    newRange.setStart(newRange.startContainer, i);
    newRange.collapse(true);
    const newRangeRect = newRange.getClientRects()[0];
    // console.log(`offset(${i}).left = ${newRangeRect.left}`);  //, newRangeRect

    //
    // Measure our current selection position in the viewport.
    //
    if(newRangeRect.left <= previousSelectionLeft) {
      previousSelectionRange = newRange;
      previousSelectionLeft = newRangeRect.left;
      continue;
    }

    // If we get here the last change in selection resulted in the left position becoming greater, meaning we went to the end of the previous line.
    // Transforms.select(editor, { path: focusPath, offset: i+1 });
    previousSelectionRange = newRange;
    previousSelectionLeft = newRangeRect.left;
    break;
  }

  const currentSelection = document.getSelection();
  if(currentSelection) {
    // console.log(`Final Selection left ${previousSelectionLeft}`, previousSelectionRange);
    currentSelection.removeAllRanges();
    currentSelection.addRange(previousSelectionRange);
  }
};

const useEditorFocusCommandHandler = (editor: PrompterEditor, viewportFuncs: IViewportFuncs) => {

  const doEditorFocus = useCallback(async () => {
    //console.log('doEditorFocus');
    //
    // We can't focus the editor if we aren't in edit mode.
    //
    const prompterState = usePrompterSession.getState();
    if(!prompterState.isEditing) {
      // console.log('doEditorFocus not editing');
      return;
    }

    const prompterMeta = prompterState.scriptNodesMeta;
    if(!prompterMeta) {
      throw new Error('prompterMeta is null');
    }

    const visualViewport = window.visualViewport;
    if(!visualViewport) {
      // console.log('doEditorFocus no visualViewport');
      return;
    }

    const configState = useConfigurationStore.getState();

    const prompterPosition = viewportFuncs.getPrompterPosition();
    if(!prompterPosition) {
      // getPrompterPosition() will return undefined if we don't have refs to DOM elements.
      // This can happen during mounting/unmounting/rerendering of components.
      return;
    }

    //
    // Determine our targetX offset based on the text justification.
    //
    const leftGutterPx = visualViewport.width * configState.leftGutter / 100;
    let contentOffsetFromLeft = 0;
    switch(configState.contentJustification) {
      case TextAlign.Left:
      case TextAlign.Justify:
        // 1EM padding outside prompter segment, 1EM padding inside prompter segment, 4px border
        // + 2px extra to make sure we are into an element.
        contentOffsetFromLeft = prompterMeta.contentPaddingLeft + prompterMeta.contentPaddingLeft + 6;
        break;
      case TextAlign.Center:
        contentOffsetFromLeft = (prompterMeta.contentWidth / 2);
        break;
      case TextAlign.Right:
        // contentWidth includes both left and right padding of 1EM as well as 4px border
        // subtract another 2px extra to make sure we are into an element.
        contentOffsetFromLeft = prompterMeta.contentWidth - prompterMeta.contentPaddingLeft - prompterMeta.contentPaddingLeft - 6;
        break;
    }
    const targetX = leftGutterPx + contentOffsetFromLeft;

    //
    // Determine our targetY position based on the current focusGuide position.
    //
    let focusGuideY = 0.5;
    switch(configState.focusGuide) {
      case FocusGuide.Top:
        focusGuideY = 0.2;
        break;
      case FocusGuide.Bottom:
        focusGuideY = 0.8;
        break;
      default:
        break;
    }
    const targetY = Math.floor(visualViewport.height * focusGuideY);

    //
    // Use the targetX and targetY co-ordinates to figure out what element is under the prompter
    // cue position.
    //
    let targetHTMLElement: HTMLElement = document.elementFromPoint(targetX, targetY) as HTMLElement;
    if(!targetHTMLElement) {
      return;
    }
    let focusEndOfTarget = false;

    //
    // If the element under the cue position is inside a prompter segment element...
    // Then let's focus the cursor in this element!
    //
    const segmentBodyEl = targetHTMLElement.closest('.SegmentElementBody') as HTMLElement;
    if(segmentBodyEl) {
      focusElementInsideBody(editor, targetHTMLElement, targetY);
      return;
    }

    //
    // If we don't yet have a paragraph target element, the cue position is not over segment
    // element content. But let's see if the cue position is over a script segment at least.
    //
    const targetNodeY = targetY + prompterPosition.scrollPosition;
    let slateNode: PrompterElement | null = null;
    let segmentWrapperEl: HTMLElement | null = targetHTMLElement.closest<HTMLElement>('.SegmentElement');
    if(segmentWrapperEl) {
      slateNode = ReactEditor.toSlateNode(editor, segmentWrapperEl) as PrompterElement;
    }
    if(!segmentWrapperEl) {
      const nearestSegmentNode = findNearestScriptSegmentForCuePosition(prompterState, targetNodeY);
      if(nearestSegmentNode) {
        slateNode = nearestSegmentNode;
        segmentWrapperEl = ReactEditor.toDOMNode(editor, nearestSegmentNode);
      }
    }

    if(segmentWrapperEl && slateNode) {
      //slateNode = ReactEditor.toSlateNode(editor, segmentWrapperEl);
      // slatePath = ReactEditor.findPath(editor, slateNode);
      // console.log(`slatePath=${slatePath}`);

      const segmentRect = segmentWrapperEl.getBoundingClientRect();
      const distanceFromTop = targetY - segmentRect.top;
      const distanceFromBottom = segmentRect.bottom - targetY;
      console.log(`segmentRect targetY=${targetNodeY}, distanceFromTop=${distanceFromTop}, distanceFromBottom=${distanceFromBottom}`, segmentRect);

      const firstParagraph = (distanceFromTop < distanceFromBottom)
        ? segmentWrapperEl.querySelector<HTMLParagraphElement>('p')
        : segmentWrapperEl.querySelector<HTMLParagraphElement>('p:last-child');

      // The cue position is at least over a SegmentElement.
      //const firstParagraph = segmentWrapperEl.querySelector('p:last-child');
      if(firstParagraph) {
        // TODO?: await viewportFuncs.scrollToSegment(slateNode);
        const paragraphtRect = firstParagraph.getBoundingClientRect();

        // const targetPosition = "cue";
        // let scrollPosition = ('top' === targetPosition)
        //   ? paragraphtRect.top + (prompterPosition?.scrollPosition || 0) - 66  // top bar is 64px, then focus outline is 2px
        //   : paragraphtRect.top + (prompterPosition?.scrollPosition || 0) - (prompterPosition.viewportCueTop - prompterPosition.viewportTop + (prompterPosition.lineHeight / 6)); // Assumes lineheight = 1.5

        let scrollPosition = ((distanceFromBottom < distanceFromTop) ? paragraphtRect.bottom : paragraphtRect.top)  // Are we target the top or bottom of this paragraph?
          + prompterPosition.scrollPosition
          - (prompterPosition.viewportCueTop - prompterPosition.viewportTop + (prompterPosition.lineHeight / 6)); // Assumes lineheight = 1.5

        if(scrollPosition < 0) { scrollPosition = 0; }

        viewportFuncs.queueSequentialTask(async () => {
          return viewportFuncs.scrollToPosition({
            scrollPosition: scrollPosition,
            scrollBehavior: 'smooth',
          });
        });

        targetHTMLElement = firstParagraph as HTMLElement;
        focusEndOfTarget = (distanceFromBottom < distanceFromTop);
      }
    } // END if(segmentWrapperEl)

    // console.log(`final targetHTMLElement tagName=${targetHTMLElement.tagName}`);

    if(!targetHTMLElement) {
      // We have no target element to focus in the editor!
      return;
    }


    const range = document.createRange(),
      newSelection = window.getSelection();

    if(!newSelection) {
      return;
    }
    if(focusEndOfTarget) {
      range.setStartAfter(targetHTMLElement);
    } else {
      range.setStart(targetHTMLElement, 0);
    }

    range.collapse(true);
    newSelection.removeAllRanges();
    newSelection.addRange(range);
    targetHTMLElement.focus();


    return;

    /*
    // Get the DOM Element for the current script segment and call .focus()?
    const currentScriptNode = usePrompterSession.getState().currentScriptNode;
    if(!currentScriptNode) {
      return;
    }

    // alert('begin script edit');
    const scriptNodeEl = ReactEditor.toDOMNode(editor, currentScriptNode);
    if(!scriptNodeEl) {
      return;
    }

    // Find child .SegmentElementBody within the .SegmentElement
    const segmentBodyEl = scriptNodeEl.querySelector('.SegmentElementBody');
    if(!segmentBodyEl) {
      // No body element? That would be a bug.
      throw new Error('ScriptSegment has no SegmentElementBody element.');
    }

    const segmentBodyHTMLElement = segmentBodyEl as HTMLElement;
    // console.log(segmentBodyHTMLElement);

    let range = document.createRange(),
        newSelection = window.getSelection();

    if(!newSelection) {
      return;
    }
    range.setStart(segmentBodyHTMLElement, 1);
    range.collapse(true);
    newSelection.removeAllRanges();
    newSelection.addRange(range);
    segmentBodyHTMLElement.focus();
    */
  }, [editor, viewportFuncs]);

  useMessageHandler('prompter.editor.focusatcue', () => {
    viewportFuncs.queueSequentialTask(async () => {
      await doEditorFocus();
    });
  });

  useMessageHandler('prompter.editor.focus', () => {
    ReactEditor.focus(editor);
  });

};

export default useEditorFocusCommandHandler;