import React from 'react';
import AccountHeader from '../AccountHeader/AccountHeader';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';

import useApplicationContext from '../../../hooks/useApplicationContext';
import useFeatureFlagsStore from '../../../state/FeatureFlagsStore';

const PasswordAndAuthView = () => {

  const { t } = useTranslation('account');

  const {
    getApiToken,
  } = useApplicationContext(false);

  const redirectToBillingPortal = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      const token = await getApiToken();

      const returnUrl = new URL(window.location.href);

      // Make sure we don't lose any temporarily enabled feature flags during the login redirects.
      useFeatureFlagsStore.getState().encodeFeatureFlagParams(returnUrl.searchParams);
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/account/changepassword?` + new URLSearchParams({
        return_url: returnUrl.toString(),
      }), {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status !== 200) {
        // We received an error response.
        alert('Sorry, we encountered an unexpected error. Please contact fluidprompter support.');
        return;
      }

      console.log('Got app API account response from API serverless function', response);

      const changePasswordResponse = await response.json();
      const changePasswordUrl = changePasswordResponse.url as string;
      if(changePasswordUrl) {
        window.location.href = changePasswordUrl;
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Stack spacing={2}>
      <Box>
        <AccountHeader title={t('authenticationsection.changepassword.title')}></AccountHeader>
        <Stack
          direction="row"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            onClick={redirectToBillingPortal}
          >{t('authenticationsection.changepassword.button')}</Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default PasswordAndAuthView;