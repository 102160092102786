import { BaseControlMessage } from './BaseControlMessage';
export const LIFECYCLEMESSAGE_KEY = 'lifecycle';
/**
 * Represents the LifecycleMessage type.
 */
export class LifecycleMessage extends BaseControlMessage {
    constructor(endpointId, lifecycleState) {
        super();
        this.type = LIFECYCLEMESSAGE_KEY;
        this.endpointId = endpointId;
        this.lifecycleState = lifecycleState;
    }
    static fromJson(data) {
        if (data.type !== LIFECYCLEMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for LifecycleMessage.');
        }
        const input = data;
        const instance = new LifecycleMessage(input.endpointId, input.lifecycleState);
        instance.deserializeSenderInfo(data.sender);
        instance.endpointId = input.endpointId;
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            endpointId: this.endpointId,
            lifecycleState: this.lifecycleState,
        };
    }
}
LifecycleMessage.MESSAGE_NAME = LIFECYCLEMESSAGE_KEY;
