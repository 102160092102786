import ZustandStoreSlice from '../utils/ZustandStoreSlice';

import TextAlign from '../../models/TextAlign';
import FocusGuide from '../../models/FocusGuide';

export const CONTENT_WIDTH_MINIMUM = 30;
const SHIFT_LEFT_RIGHT_INCREMENT = 0.5;

export interface IPrompterAppearanceData {
  textSize: number;
  lineHeight: number;
  leftGutter: number;
  rightGutter: number;
  contentWidth: number;
  //
  colorScheme: string;
  textColor: string;
  backgroundColor: string;
  //
  contentJustification: TextAlign;
  flipHorizontal: boolean;
  flipVertical: boolean;
  showStats: boolean;
  focusGuide: FocusGuide;
}
export interface IPrompterAppearanceSlice extends IPrompterAppearanceData {
  // textSize: number;
  setTextSize: (fps: number) => void;
  increaseTextSize: (by?: number) => void;
  decreaseTextSize: (by?: number) => void;

  // BackgroundColor (RGB)
  // BackgroundOpacity (A)
  // TextColor
  // TextOpacity?
  // TextAlignment: Left|Center|Right
  // CharacterSpacing?
  // LineSpacing?

  // lineHeight: number;
  setLineHeight: (lineHeight: number) => void;

  // leftGutter: number;
  setLeftGutter: (leftGutter: number) => void;
  shiftLeft: () => void;
  // rightGutter: number;
  setRightGutter: (rightGutter: number) => void;
  shiftRight: () => void;
  // contentWidth: number;
  setContentWidth: (contentWidth: number) => void;
  increaseContentWidth: (by?: number) => void;
  decreaseContentWidth: (by?: number) => void;

  setColorScheme: (colorScheme: string) => void;

  // contentJustification: TextAlign;
  setContentJustification: (justification: TextAlign) => void;

  // flipHorizontal: boolean;
  setFlipHorizontal: (flip: boolean) => void;
  toggleFlipHorizontal: () => void;
  // flipVertical: boolean;
  setFlipVertical: (flip: boolean) => void;
  toggleFlipVertical: () => void;

  // showStats: boolean;
  setShowStats: (flip: boolean) => void;
  toggleShowStats: (flip: boolean) => void;

  // focusGuide: FocusGuide;
  setFocusGuide: (position: FocusGuide) => void;
}

const DEFAULT_VALUES: IPrompterAppearanceData = {
  textSize: 54,
  lineHeight: 1.5,
  contentWidth: 90,
  leftGutter: 5,
  rightGutter: 5,
  //
  colorScheme: 'yellow-black',
  textColor: '#ffff00',       // Default = Yellow
  backgroundColor: '#000000', // Default = Black
  //
  contentJustification: TextAlign.Left,
  flipHorizontal: false,
  flipVertical: false,
  showStats: false,
  focusGuide: FocusGuide.None,
};
const initialValuesByViewportSize = (): Partial<IPrompterAppearanceData> | undefined => {
  const viewportWidth = document.documentElement.clientWidth;
  if(!viewportWidth) {
    // This is likely a unit test environment or something if document does not have a client width.
    return undefined;
  }

  //
  // Extra-Large Viewport - 4k UHD
  //
  if(viewportWidth > 1920) {
    return {
      textSize: 64,
      contentWidth: 60,
      leftGutter: 20,
      rightGutter: 20,
      showStats: true,
    };
  }

  //
  // Large Viewport - 1080p FHD
  //
  if(viewportWidth >= 1536) {
    return {
      textSize: 64,
      contentWidth: 66,
      leftGutter: 17,
      rightGutter: 17,
      showStats: true,
    };
  }

  //
  // Medium Viewport
  // ----------
  // MacBookPro, Safari, reports 1512px clientWidth
  // MacBookPro, Google Chrome, reports 1512px clientWidth
  //
  if(viewportWidth >= 1200) {
    return {
      textSize: 54,
      contentWidth: 66,
      leftGutter: 17,
      rightGutter: 17,
      showStats: true,
    };
  }

  //
  // Small Viewport (portrait iPad?)
  // ----------
  //
  if(viewportWidth >= 600) {
    return {
      textSize: 36,
      contentWidth: 70,
      leftGutter: 15,
      rightGutter: 15,
    };
  }

  //
  // Extra-Small Viewport (portrait mobile phone)
  // ----------
  //
  return {
    textSize: 24,
    contentWidth: 100,
    leftGutter: 0,
    rightGutter: 0,
  };
};

const createPrompterAppearanceSlice: ZustandStoreSlice<IPrompterAppearanceSlice> = (set, get) => ({
  ...DEFAULT_VALUES,
  ...initialValuesByViewportSize(),

  setTextSize: (size: number) => set(() => ({ textSize: size })),
  increaseTextSize: (by = 1) => set((state) => {
    let proposedTextSize = state.textSize + by;
    if(proposedTextSize > 100) { proposedTextSize = 100; }
    return { textSize: proposedTextSize };
  }),
  decreaseTextSize: (by = 1) => set((state) => {
    let proposedTextSize = state.textSize - by;
    if(proposedTextSize < 10) { proposedTextSize = 10; }
    return { textSize: proposedTextSize };
  }),

  setLineHeight: (lineHeight: number) => set(() => ({ lineHeight })),

  setLeftGutter: (leftGutter: number) => set((state) => {
    let contentWidth = 100 - state.rightGutter - leftGutter;

    //
    // If the contentWidth is getting too small, take some away from the other gutter width.
    //
    let rightGutter = state.rightGutter;
    if(contentWidth < CONTENT_WIDTH_MINIMUM) {
      contentWidth = CONTENT_WIDTH_MINIMUM;
      rightGutter = 100 - contentWidth - leftGutter;
    }

    return { leftGutter, rightGutter, contentWidth };
  }),
  shiftLeft: () => set((state) => {
    let leftGutter = state.leftGutter;
    let rightGutter = state.rightGutter;
    if(leftGutter >= SHIFT_LEFT_RIGHT_INCREMENT) {
      leftGutter -= SHIFT_LEFT_RIGHT_INCREMENT;
      rightGutter += SHIFT_LEFT_RIGHT_INCREMENT;
    }

    return { leftGutter, rightGutter };
  }),
  setRightGutter: (rightGutter: number) => set((state) => {
    let contentWidth = 100 - state.leftGutter - rightGutter;

    //
    // If the contentWidth is getting too small, take some away from the other gutter width.
    //
    let leftGutter = state.leftGutter;
    if(contentWidth < CONTENT_WIDTH_MINIMUM) {
      contentWidth = CONTENT_WIDTH_MINIMUM;
      leftGutter = 100 - contentWidth - rightGutter;
    }

    return { leftGutter, rightGutter, contentWidth };
  }),
  shiftRight: () => set((state) => {
    let leftGutter = state.leftGutter;
    let rightGutter = state.rightGutter;
    if(rightGutter >= SHIFT_LEFT_RIGHT_INCREMENT) {
      rightGutter -= SHIFT_LEFT_RIGHT_INCREMENT;
      leftGutter += SHIFT_LEFT_RIGHT_INCREMENT;
    }

    return { leftGutter, rightGutter };
  }),
  setContentWidth: (proposedContentWidth: number) => set((state) => {
    let contentWidth = proposedContentWidth;
    if(contentWidth < CONTENT_WIDTH_MINIMUM) { contentWidth = CONTENT_WIDTH_MINIMUM; }
    if(contentWidth > 100) { contentWidth = 100; }

    const prevTotalGutter = state.leftGutter + state.rightGutter;
    const newTotalGutter = 100 - contentWidth;

    let leftGutter = Math.round(newTotalGutter * 2 * (prevTotalGutter ? (state.leftGutter / prevTotalGutter) : 0.5)) / 2;
    if(leftGutter < 0) { leftGutter = 0; }
    if(leftGutter > 50) { leftGutter = 50; }

    let rightGutter = Math.round(newTotalGutter * 2 * (prevTotalGutter ? (state.rightGutter / prevTotalGutter) : 0.5)) / 2;
    if(rightGutter < 0) { rightGutter = 0; }
    if(rightGutter > 50) { rightGutter = 50; }

    return { leftGutter, rightGutter, contentWidth };
  }),
  increaseContentWidth: (by = 1) => {
    const state = get();
    state.setContentWidth(state.contentWidth + by);
  },
  decreaseContentWidth: (by = 1) => {
    const state = get();
    state.setContentWidth(state.contentWidth - by);
  },

  setColorScheme: (newColorScheme: string) => set((state) => {
    let colorScheme = newColorScheme;
    let textColor = '';
    let backgroundColor = '';
    switch(colorScheme) {
      default:
      case 'yellow-black':
        textColor = '#ffff00';
        backgroundColor = '#000000';
        colorScheme = 'yellow-black';
        break;
      case 'white-black':
        textColor = '#ffffff';
        backgroundColor = '#000000';
        break;
      case 'black-white':
        textColor = '#000000';
        backgroundColor = '#ffffff';
        break;
    }

    return { colorScheme, textColor, backgroundColor };
  }),

  // contentJustification: TextAlign.Left,
  setContentJustification: (justification: TextAlign) => set(() => ({ contentJustification: justification })),

  // flipHorizontal: false,
  setFlipHorizontal: (flip: boolean) => set(() => ({ flipHorizontal: flip })),
  toggleFlipHorizontal: () => set((state) => ({ flipHorizontal: !state.flipHorizontal })),
  // flipVertical: false,
  setFlipVertical: (flip: boolean) =>  set(() => ({ flipVertical: flip })),
  toggleFlipVertical: () => set((state) => ({ flipVertical: !state.flipVertical })),

  // showStats: false,
  setShowStats: (show: boolean) =>  set(() => ({ showStats: show })),
  toggleShowStats: () =>  set(() => ({ showStats: get().showStats })),

  // focusGuide: FocusGuide.None,
  setFocusGuide: (position: FocusGuide) => set(() => ({ focusGuide: position })),
});

export default createPrompterAppearanceSlice;