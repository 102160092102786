const CONF_DOUBLE_CLICK_MS = 180; // 180 ms
const CONF_LONG_PRESS_MS = 500; // 500 ms

const ButtonEvent = {
  UP: 'up',
  DOWN: 'down',
  PRESSED: 'press',
  DOUBLE_PRESSED: 'doublePress',
  LONG_PRESSED: 'longPress',
  LONG_PRESSED_REPEAT: 'longPressRepeat',
  DOUBLE_LONG_PRESSED: 'doubleLongPress',
};

export interface DigitalButtonConfig {
  /**
   * The name of the input on the control device. 
   * For example this may be 'A', 'B', 'X', 'Y', 'LR', etc
   */
  name: string,

  /**
   * The bitmask is used to filter our a relevant bit representing this button's state from a 
   * larger value. Most devices return an 8, 16 or 32 bit field where each bit represents one 
   * of the buttons on the device.
   */
  bitmask: number,
}

type DigitalInputCallback = (buttonName: string, eventType: string) => void;

class DigitalInputStateMachine {

  _config: DigitalButtonConfig;
  _callback: DigitalInputCallback;

  _previousState: number;
  _lastTimeStamp: number;
  _multipressInProgress: boolean;
  _repeatCount: number;
  _pressEventTimer: number | null;
  _longpressTimer: number | null;

  constructor(config: DigitalButtonConfig, callback: DigitalInputCallback) {
    // Apply button config
    this._config = config;
    this._callback = callback;

    this._previousState = 0;
    this._lastTimeStamp = 0;
    this._multipressInProgress = false;
    this._repeatCount = 0;
    this._pressEventTimer = null;
    this._longpressTimer = null;
  }

  fireEvent(eventType: string) {
    const buttonName = this._config.name;
    this._callback(buttonName, eventType);
  }

  processState(nextInputState: number) {
    /* eslint no-bitwise: ["error", { "allow": ["^", "&"] }] */

    // The button transitioned states from 1->0 or 0->1
    const deltaState = (nextInputState ^ this._previousState);
    if (deltaState & this._config.bitmask) {
      const elapsed = Date.now() - this._lastTimeStamp;
      // console.log('OK toggled.');
      if (nextInputState & this._config.bitmask) {
        // console.log('OK down');
        this.fireEvent(ButtonEvent.DOWN);

        // 1.) if we are pressing this button sooner than
        // a queued "press event", then cancel the press
        // event as we are going to fire a "double click"
        // event instead
        if (elapsed < CONF_DOUBLE_CLICK_MS && this._pressEventTimer) {
          clearTimeout(this._pressEventTimer);
          this._pressEventTimer = null;

          this._multipressInProgress = true;
          // console.log('OK double-pressed');
        }

        // 2.) Start long-press timer
        // this._longpressTimer = setTimeout(() => {
        this._longpressTimer = window.setInterval(() => {
          if (this._multipressInProgress) {
            // OK double-long-pressed
            // console.log(this._config.name + ' double-long-pressed');
            this.fireEvent(ButtonEvent.DOUBLE_LONG_PRESSED);
            this._multipressInProgress = false;
          } else {
            this._repeatCount += 1;
            if(this._repeatCount === 1) {
              // First long press
              console.log(`Long Press ${this._repeatCount}`);
              this.fireEvent(ButtonEvent.LONG_PRESSED);
            } else {
              // 2nd or more long press
              console.log(`Long Press ${this._repeatCount}`);
              this.fireEvent(ButtonEvent.LONG_PRESSED_REPEAT);
            }

            if(this._pressEventTimer) {
              clearTimeout(this._pressEventTimer);
              this._pressEventTimer = null;
            }
          }
        }, CONF_LONG_PRESS_MS);
      } else {
        // console.log(`OK up (was down ${elapsed}ms)`);
        this.fireEvent(ButtonEvent.UP);

        this._repeatCount = 0;  // Clear the counter for any longpress repeats.        
        if (this._longpressTimer) {
          // clearTimeout(this._longpressTimer);
          clearInterval(this._longpressTimer);
          this._longpressTimer = null;
        }

        // If pressed time was < long press interval, then
        // queue pressed event for just long than the double
        // click interval
        if (elapsed < CONF_LONG_PRESS_MS && !this._multipressInProgress) {
          // We pressed the button for less time than a long a press.
          this._pressEventTimer = window.setTimeout(() => {
            // console.log(this._config.name + ' pressed');
            this.fireEvent(ButtonEvent.PRESSED);
          }, 200);
        } else if (this._multipressInProgress) {
          //
          this._pressEventTimer = window.setTimeout(() => {
            // console.log(this._config.name + ' double-pressed');
            this.fireEvent(ButtonEvent.DOUBLE_PRESSED);
          }, 200);
        }

        // Clear state of any detected double-press
        this._multipressInProgress = false;
      }
      this._lastTimeStamp = Date.now();
    }

    // this._stateTimers
    this._previousState = nextInputState;
  }
}

export default DigitalInputStateMachine;
