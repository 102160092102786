import { create } from 'zustand';

import BaseRemote from '../devices/BaseRemote';

interface BluetoothState {
  menuOpen: boolean
  setMenuOpen: (open: boolean) => void
  toggleMenuOpen: () => void

  toastId: number | string
  setToastId: (id: number | string) => void

  isBluetoothAvailable: boolean;   // Is WebBluetooth available on this browser/device?
  setBluetoothAvailable: (available: boolean) => void
  isBluetoothConnecting: boolean;  // Are we currently connecting a device?
  setBluetoothConnecting: (connecting: boolean) => void
  isBluetoothConnected: boolean;   // Do we have at least 1 connected ble device?
  // setBluetoothConnected: (connected: boolean) => void
  
  bleDevices: BaseRemote[];
  addDevice: (instance: BaseRemote) => void;
  getDevice: (id: string) => BaseRemote | undefined;
  removeDevice: (id: string) => void;
  checkBluetoothConnected: () => void;
  setConnectedSate: (id: string, isConnected: boolean) => void
}

const useBluetoothState = create<BluetoothState>()(
  (set, get) => ({
    menuOpen: false,
    setMenuOpen: (open: boolean) => set(() => ({ menuOpen: open })),
    toggleMenuOpen: () => set(() => ({ menuOpen: !get().menuOpen })),

    toastId: 0,
    setToastId: (id: number | string) => set(() => ({ toastId: id })),

    isBluetoothAvailable: false,   // Is WebBluetooth available on this browser/device?
    setBluetoothAvailable: (available: boolean) => set(() => ({ isBluetoothAvailable: available })),

    isBluetoothConnecting: false,  // Are we currently connecting a device?
    setBluetoothConnecting: (connecting: boolean) => set(() => ({ isBluetoothConnecting: connecting })),

    isBluetoothConnected: false,   // Do we have at least 1 connected ble device?
    // setBluetoothConnected: (connected: boolean) => set((state) => ({ isBluetoothConnected: connected })),

    bleDevices: [],
    // methods for manipulating state
    addDevice: (instance: BaseRemote) => {
      set((state) => {
        if(!instance) {
          throw new Error('BluetoothDevice is required.');
        }

        const otherDevices = state.bleDevices.filter((bleDevice) => bleDevice.id !== instance.id);
        const bleDevices = [ instance, ...otherDevices ];

        // Automatically update our state to reflect if we have any connected device(s).
        const isBluetoothConnected = bleDevices.some((bleDevice) => {
          return bleDevice.isConnected();
        });

        return {
          bleDevices,
          isBluetoothConnected,
        };
      });
    },
    getDevice: (id: string) => {
      return get().bleDevices.find((bleDevice) => bleDevice.id === id);
    },
    removeDevice: (id) => {
      set((state) => {
        const bleDevices = state.bleDevices.filter((bleDevice) => bleDevice.id !== id);

        // Automatically update our state to reflect if we have any connected device(s).
        const isBluetoothConnected = bleDevices.some((bleDevice) => {
          return bleDevice.isConnected();
        });

        return { bleDevices, isBluetoothConnected };
      });
    },
    checkBluetoothConnected: () => {
      set((state) => {
        // Automatically update our state to reflect if we have any connected device(s).
        const isBluetoothConnected = state.bleDevices.some((bleDevice) => {
          return bleDevice.isConnected();
        });

        return { isBluetoothConnected };
      });
    },
    setConnectedSate: (/*deviceId, isConnected*/) => {
      set((state) => {
        /*
        const bleDevices = state.bleDevices.map((bleDevice) => {
          return (bleDevice.id === deviceId)
            ? ({ ...bleDevice, connected: isConnected } as BleDevice)
            : bleDevice;
        });
        */
        const bleDevices = state.bleDevices;

        // Automatically update our state to reflect if we have any connected device(s).
        const isBluetoothConnected = bleDevices.some((bleDevice) => {
          return bleDevice.isConnected();
        });

        // Return our state updates to Zustand
        return { bleDevices, isBluetoothConnected };
      });
    },  
  })
);

export default useBluetoothState;