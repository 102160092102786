import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageHandlerEvent, useMessageHandler } from '../../controllers/AppController';
import { GenericMessage } from '@fluidprompter/core';

import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterPresets from '../../state/PrompterPresetsState';

import { toast, ToastOptions } from 'react-toastify';

interface SavePresetParams {
  presetNumber: number;
}

function usePrompterPresetHandlers() {
  const { t } = useTranslation('prompter');

  const doSavePreset = useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    const params = e.message.payload as SavePresetParams;
    const presetNumber = params?.presetNumber;
    if(!presetNumber) {
      return;
    }

    const configState = useConfigurationStore.getState();

    usePrompterPresets.getState().setPreset(presetNumber, {
      scrollSpeed: configState.scrollSpeed,
    });

    const toastOptions: ToastOptions = {
      type: 'success',
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false
    };
    toast(t('speedmenu.presetsaved', { presetNumber }), toastOptions);

    // appController.dispatchMessage('prompter.local.presetmenu.close');
  }, [t]);
  useMessageHandler('prompter.local.savepreset', doSavePreset);

  const doRecallPreset = useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    const params = e.message.payload as SavePresetParams;
    const presetNumber = params?.presetNumber;
    if(presetNumber === undefined) {
      return;
    }

    const proposedPreset = usePrompterPresets.getState().getPreset(presetNumber);
    if(!proposedPreset) {
      const toastOptions: ToastOptions = {
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false
      };
      toast(t('speedmenu.presetnotfound', { presetNumber }), toastOptions);

      return;
    }

    useConfigurationStore.getState().setUserScrollSpeed(proposedPreset.scrollSpeed);

    // const toastOptions: ToastOptions = {
    //   type: 'success',
    //   isLoading: false,
    //   autoClose: 5000,
    //   hideProgressBar: false
    // };
    // toast(`Preset ${presetNumber} Recalled!`, toastOptions);
  }, [t]);
  useMessageHandler('prompter.local.recallpreset', doRecallPreset);

  useMessageHandler('prompter.local.speed.togglelock', () => {
    useConfigurationStore.getState().toggleScrollSpeedLocked();
  });

  useMessageHandler('prompter.local.speed.togglehide', () => {
    useConfigurationStore.getState().toggleHideScrollSpeed();
  });
}

export default usePrompterPresetHandlers;