import React from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

interface SpeedEditorDialogProps {
  onClose: (closeAll?: boolean) => void;
}

const SpeedEditorDialog = (props: SpeedEditorDialogProps) => {
  const { onClose } = props;

  const { t } = useTranslation('prompter');

  const handleClose = () => {
    onClose(true);
  };

  return (
    <Dialog onClose={handleClose} open={true}>
      <DialogTitle>Configure Speed Editor{/*t('connectdevicedialog.title')*/}</DialogTitle>
      <DialogContent>
      Speed Editor Dialog
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>{t('connectdevicedialog.done')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SpeedEditorDialog;