import * as React from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
    <>
    Page not found! <Link to="/">Go to FluidPrompter home.</Link>
    </>
  );
}

export default NotFoundPage;