import { useCallback } from 'react';
import { useMessageHandler } from '../controllers/AppController';
import { useTranslation } from 'react-i18next';
import { toast, Id, ToastOptions, UpdateOptions } from 'react-toastify';
import throttle from 'lodash/throttle';

import useConfigurationStore from '../state/ConfigurationStore';
import usePrompterSession from '../state/PrompterSessionState';
import { EndpointRole, SetScrollSpeedMessage } from '@fluidprompter/core';

let globalToastId: Id = 0;

function useConfigMessageHandlers() {
  const { t } = useTranslation('prompter');

  const showNotification = useCallback(throttle(() => {
    const toastOptions: UpdateOptions = {
      type: 'error',
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      onClose: () => {
        globalToastId = 0;
      }
    };

    if(globalToastId) {
      toast.update(globalToastId, toastOptions);
      return;
    }

    globalToastId = toast(t('speedmenu.scrollspeedislocked'), toastOptions as ToastOptions);

  }, 6000), [t]);

  const doSetUserScrollSpeed = useCallback(async function (msg?: SetScrollSpeedMessage) {
    const state = useConfigurationStore.getState();
    if(state.scrollSpeedLocked) {
      showNotification();
      return;
    }

    if(msg
      && msg.reverse !== undefined
      && state.scrollReversed !== msg.reverse
    ) {
      state.setScrollReversed(msg.reverse);
    }
    if(msg && state.scrollSpeed !== msg.speed) {
      state.setUserScrollSpeed(msg.speed);
    }
  }, [showNotification]);

  useMessageHandler('setscrollspeed', (e) => {
    const { message, originatedRemotely } = e;
    const { speed: proposedSpeed, reverse: proposedReverse, sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    // performance.mark('setscrollspeed-start');

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);


    e.sendToPeers = !originatedRemotely || (senderIsRemote && isLeader);
    if(sender && e.sendToPeers) {
      // If we are sending to peers, we are currently the leader acting on the requested operation.
      if(proposedReverse !== undefined) {
        sender.scrollReversed = proposedReverse;
      }
      sender.scrollSpeed = proposedSpeed;
    }

    //
    // If the sender is a remote and I'm not the leader, ignore this message.
    // The Leader who receives this message will propagate the message.
    //
    if(originatedRemotely && senderIsRemote && !isLeader) {
      // The current leader will process the remote command within the context of the current
      // scroll position and transmit a more accurate message to all peers.
      return;
    }

    if(originatedRemotely && !isLeader) {
      //
      // Scale scroll speed based on sender metrics.
      e.syncScrollSpeed();

      // performance.mark('setscrollspeed-syncScrollSpeed()');
    } else {
      const state = useConfigurationStore.getState();
      if(state.scrollSpeedLocked) {
        e.sendToPeers = false;
      }

      //
      // Does not scale scroll speed.
      doSetUserScrollSpeed(message);
      // performance.mark('setscrollspeed-doSetUserScrollSpeed()');
    }
    // const measureInfo = performance.measure('setscrollspeed-end', 'setscrollspeed-start');
    // console.log(`setscrollspeed duration = ${measureInfo.duration}`);
  });

  return {
    setScrollSpeed: doSetUserScrollSpeed,
  };
}

export default useConfigMessageHandlers;