import { IAccountSetupRequired } from './hookTypes';
import { IUserProfile } from '@fluidprompter/core';

export const setupRequiredForUserProfile = (userProfile: IUserProfile): IAccountSetupRequired | undefined => {

  const accountSetupRequiredData: IAccountSetupRequired = {};
  if(!userProfile.fluidprompter_aloneorteam || !userProfile.fluidprompter_usecase) {
    // 
    accountSetupRequiredData.missingUserUseCase = true;
  }
  if(!userProfile.fluidprompter_plan) {
    // This user has no Stripe Customer Id or Subscription - we need them to perform a "checkout" to create their subscription.
    accountSetupRequiredData.missingStripeCustomer = true;
  }
  if(!userProfile.fluidprompter_referralsource) {
    // 
    accountSetupRequiredData.missingUserReferralSource = true;
  }

  // If there is no setup required, return undefined.
  return (accountSetupRequiredData.missingUserUseCase 
    || accountSetupRequiredData.missingStripeCustomer 
    || accountSetupRequiredData.missingUserReferralSource) 
    ? accountSetupRequiredData : undefined;
};