import { useEffect, useRef } from 'react';
import { useAuth0, OAuthError } from '@auth0/auth0-react';
import { IApplicationContextState, IappContextHookExports, GlobalUserStateHook, ReducerActionTypes, ListenerType } from './hookTypes';

import { useApplicationContextReducer } from './useApplicationContextReducer';
import { useGetApiToken } from './useGetApiToken';
import { useFetchUserProfile } from './useFetchUserProfile';
import { usePatchUserProfile } from './usePatchUserProfile';
import { useOnlineStatus } from './useOnlineStatus';
import { useSubscriptionUtils } from './useSubscriptionUtils';

//
// The authentication context follows a flow of potentially asyncronous actions to load the
// authentication context. We will use a reducer to update the state after each asyncronous request.
// 1.) We begin in an isLoading=true state.
// 2.) Auth0 will determine if we are authenticated and "may" make an http request to load
//     authentication state. If we are not authenticated, we are done and isLoading is set to false.
//     (asyncronous http request)
// 3.) If we are authenticated, then we will retreive an apiToken to call fluidprompter APIs.
//     (asyncronous http request)
// 4.) If we are authenticated, and have an apiToken, we will load the current user profile from our
//     application API.
//     (asyncronous http request)
//
//     * Upon receipt of a userprofile, we will evaluate if any user account setup steps are
//       currently required.
//

const createContainer = (): GlobalUserStateHook => {
  let globalUserState: IApplicationContextState = {
    networkOnline: navigator.onLine,
    internetOnline: true,
    isLoading: true,
    isAuthenticated: false,
  };
  const setGlobalUserState = (newState: IApplicationContextState) => {
    globalUserState = newState;
  };
  const listeners = new Set<ListenerType>();

  const useApplicationContextHook: GlobalUserStateHook = (isRootHook?: boolean): IappContextHookExports => {
    //
    // Prepare our Auth0 library state.
    //
    const auth0Context = useAuth0();
    const {
      isAuthenticated: auth0IsAuthenticated,
      isLoading: auth0IsLoading,
      error: auth0Error,
      loginWithRedirect,
      loginWithPopup,
      logout
    } = auth0Context;

    //
    // Prepare our applicationContext state and a dispatch method for modifying that state.
    //
    const [appContext, dispatch] = useApplicationContextReducer(
      globalUserState,
      setGlobalUserState,
      listeners
    );
    const {
      isLoading,
      userProfile,
    } = appContext;

    //
    // function get an API token from Auth0
    const getApiToken = useGetApiToken(auth0Context);

    //
    // Get/Patch User Profile functions via FluidPrompter APIs
    const fetchUserProfile = useFetchUserProfile(getApiToken, dispatch);
    const patchUserProfile = usePatchUserProfile(getApiToken, dispatch);

    //
    // Track if the application is currently online/offline.
    useOnlineStatus(isRootHook, appContext, dispatch);

    //
    // Contains utilities for working with the customer subscription.
    const subscription = useSubscriptionUtils(appContext, dispatch, getApiToken);

    //
    // If we are online and logged in, then load the user profile.
    const oauthErrorRef = useRef<OAuthError>();
    useEffect(() => {
      // If we think we are loading, but Auth0 is finished loading and we are not currently
      // authenticated, then we are done loading. (Can't fetch a userprofile if no one is
      // authenticated!)
      if(isRootHook && isLoading && !auth0IsAuthenticated && (!auth0IsLoading || !window.isSecureContext)) {
        if(auth0Error && oauthErrorRef.current === undefined) {
          const oauthError = auth0Error as OAuthError;
          oauthErrorRef.current = oauthError;

          if(oauthError.error === 'timeout') {
            //
            // A Timeout error likely means we are currently offline (or Auth0 is down).
            //
            dispatch({
              type: ReducerActionTypes.setInternetOnline,
              payload: false,
            });
          }
        }
        dispatch({
          type: ReducerActionTypes.setIsLoading,
          payload: false,
        });
      }

      //
      // If we think we are loading, Auth0 is finished loading and is authenticated, but
      // we don't yet have a user profile, then kick-off a request to load user profile.
      //
      // Due to React StrictMode running hooks twice, we need to handle the abort properly.
      //
      if(isRootHook /*&& isLoading*/ && !auth0IsLoading && auth0IsAuthenticated && !userProfile) {
        (async () => {
          // Fetch the authenticated user's current profile.
          await fetchUserProfile();

          // We should clear the loading indicator regardless of success or failure retrieving the
          // user profile.
          dispatch({
            type: ReducerActionTypes.setIsLoading,
            payload: false,
          });
        })();
      }
    }, [isRootHook, isLoading, auth0IsAuthenticated, auth0IsLoading, auth0Error, fetchUserProfile, userProfile, dispatch]);

    // Return our hook instance exports (appContextHookExports).
    return {
      appContext,
      getApiToken,
      fetchUserProfile,
      patchUserProfile,
      loginWithRedirect,
      loginWithPopup,
      logout,
      subscription,
    };
  };  // END useApplicationContextLoader()

  // Return our hook instance (GlobalUserStateHook).
  return useApplicationContextHook;
};

const singletonInstance: GlobalUserStateHook = createContainer();
export default singletonInstance;