import React from 'react';
import { ThemeProvider, useTheme, styled } from '@mui/material/styles';
import lightTheme from '../../themes/LightTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation, Trans } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from '@mui/material';
import QRCode from 'react-qr-code';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';

import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
// import VisibilityIcon from '@mui/icons-material/Visibility';

import useInputMiddleware, { InputMiddlewareProps } from '../../hooks/useInputMiddleware';

import useApplicationState from '../../state/ApplicationState';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';
import DialogTitleWithClose from '../DialogTitleWithClose';

import logger, { mapLevelNumberToName } from '../../utils/Logger';

// Left align the label relative to the enclosed form input element.
// https://stackoverflow.com/a/56828472
const StyledFormControlLabel = styled(FormControlLabel)({
  width: '100%',
  alignItems: 'start',
  justifyContent: 'space-between',
  marginLeft: 0,
});

function RemoteDialog() {

  const { t } = useTranslation('prompter');

  const theme = useTheme();
  const viewportLessThanLg = useMediaQuery(theme.breakpoints.down('lg'));

  const appState = useApplicationState(state => ({
    remoteDialogOpen: state.remoteDialogOpen,
    setRemoteDialogOpen: state.setRemoteDialogOpen,
  }), shallow);
  const prompterSession = usePrompterSession(state => ({
    prompterId: state.prompterId,
  }), shallow);

  const handleClose = () => {
    appState.setRemoteDialogOpen(false);
  };

  //
  // For link-type state.
  //
  const [linkType, setLinkType] = React.useState<string | null>('remote');
  const handleLinkType = (
    event: React.MouseEvent<HTMLElement>,
    newLinkType: string | null,
  ) => {
    if(newLinkType) {
      setLinkType(newLinkType);
    }
  };

  //
  // Compose the link.
  //
  const currentUrl = window.location.protocol + '//' + window.location.host + '/';  // window.location.pathname
  const remoteUrl = new URL(currentUrl);
  switch(linkType) {
    case 'remote':
      remoteUrl.pathname = `/remote/${prompterSession.prompterId}`;
      break;

    case 'prompter':
      remoteUrl.pathname = '/';
      remoteUrl.searchParams.set('prompterId', prompterSession.prompterId);
      break;

    default:
      break;
  }

  // Persist the log level across user logins
  const logLevelNumber = logger.level();
  if(logLevelNumber > 0 && logLevelNumber < 40) {
    const logLevelName = mapLevelNumberToName(logLevelNumber);
    remoteUrl.searchParams.set('log', logLevelName);
  }

  //
  // For copy to clipboard button
  //
  const [clipboardCopied, setClipboardCopied] = React.useState<boolean>(false);
  const handleOnTooltipClose = () => {
    setClipboardCopied(false);
  };

  //
  // Take over processing of human inputs while this modal dialog is open.
  //
  const inputMiddlewareProps = React.useMemo<InputMiddlewareProps>(() => {
    const results = {
      exclusive: appState.remoteDialogOpen,  // Prevent all user input hooks above this one from receiving input while this model is open.
    };

    return results;
  }, [appState.remoteDialogOpen]);
  useInputMiddleware(inputMiddlewareProps);

  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog onClose={handleClose} open={appState.remoteDialogOpen}>
        <DialogTitleWithClose onClose={handleClose}>{t('joindevicedialog.title')}</DialogTitleWithClose>
        <DialogContent>
          <Trans>{t('joindevicedialog.text')}</Trans>

          <StyledFormControlLabel
            disableTypography
            labelPlacement="top"
            label={<Box display="flex" justifyContent="space-between" width="100%" marginBottom="0.35em">
              <Typography component="span">{t('joindevicedialog.linktype')}:</Typography>
            </Box>}
            control={<ToggleButtonGroup
              size="large"
              aria-label="Large sizes"
              value={linkType}
              onChange={handleLinkType}
              orientation={viewportLessThanLg ? 'vertical' : 'horizontal'}
              exclusive
              fullWidth
              color="primary"
            >
              <ToggleButton
                value="remote"
                aria-label="left aligned"
              >
                <SettingsRemoteIcon sx={{ verticalAlign: 'text-bottom', marginRight: 1 }} /> {t('joindevicedialog.remote')}
              </ToggleButton>
              <ToggleButton value="prompter" aria-label="centered">
                <OndemandVideoIcon sx={{ verticalAlign: 'text-bottom', marginRight: 1 }} /> {t('joindevicedialog.viewer')}
              </ToggleButton>
            </ToggleButtonGroup>}
            sx={{
              marginTop: 2,
            }}
          />

          <Typography
            component={'div'}
            sx={{
              marginTop: 2,
            }}
          >{t('joindevicedialog.scanqrcode')}:</Typography>
          <Box sx={{ padding: '10px', textAlign: 'center' }}>
            <QRCode
              level="M"
              size={256}
              value={remoteUrl.href} />
          </Box>

          <StyledFormControlLabel
            disableTypography
            labelPlacement="top"
            label={<Box display="flex" justifyContent="space-between" width="100%">
              <Typography component="span">{t('joindevicedialog.sharelink')}:</Typography>
            </Box>}
            control={<Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              maxWidth="100%"
            >
              <Link
                href={remoteUrl.href}
                target="_blank"
                sx={{
                  whiteSpace: 'nowrap',
                  overflowY: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >{remoteUrl.href}</Link>
              <Tooltip
                open={clipboardCopied}
                title={t('joindevicedialog.copied')}
                placement="top"
                leaveDelay={1500}
                onClose={handleOnTooltipClose}
                arrow
              >
                <Button variant="outlined" size="small" onClick={async () => {
                  await navigator.clipboard.writeText(remoteUrl.href);
                  setClipboardCopied(true);
                }}>{t('joindevicedialog.copy')}</Button>
              </Tooltip>
            </Stack>}
          />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

export default RemoteDialog;