import { BaseControlMessage } from './BaseControlMessage';
export const EDITMESSAGE_KEY = 'prompter.state.edit';
/**
 * Represents the EditMessage type.
 */
export class EditMessage extends BaseControlMessage {
    constructor() {
        super(...arguments);
        this.type = EDITMESSAGE_KEY; // 'prompter.state.edit';
    }
    static fromJson(data) {
        const instance = new EditMessage();
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
        };
    }
}
EditMessage.MESSAGE_NAME = EDITMESSAGE_KEY;
