import { create } from 'zustand';

interface SpeechRecognitionState {
  active: boolean;
  setActive: (active: boolean) => void;

  audioActive: boolean;
  setAudioActive: (audioActive: boolean) => void;

  speechActive: boolean;
  setSpeechActive: (audioActive: boolean) => void;
}

const useSpeechRecognitionState = create<SpeechRecognitionState>()(
  (set) => ({
    active: false,
    setActive: (active: boolean) => set(() => ({ active })),

    audioActive: false,
    setAudioActive: (audioActive: boolean) => set(() => ({ audioActive })),

    speechActive: false,
    setSpeechActive: (speechActive: boolean) => set(() => ({ speechActive })),
  })
);

export default useSpeechRecognitionState;