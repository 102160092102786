import EasingFunctions, { EasingFunctionKeys } from '../utils/EasingFunctions';

enum AHDSRStates {
  Idle = 0,
  Attack,
  Hold,
  Decay,
  Sustain,
  Release
}

interface AHDSRConfig {
  AttackGain: number;
  AttackMs: number;
  AttackEasingFunction: EasingFunctionKeys;

  HoldMs: number;

  DecayMs: number;
  DecayEasingFunction: EasingFunctionKeys;

  ReleaseMs: number;
  ReleaseEasingFunction: EasingFunctionKeys;
}

interface ICurrentEnvelopeValue {
  ahdsrValue: number | undefined;
  ahdsrReversed: boolean;
}

const defaultConfg: AHDSRConfig = {
  AttackGain: 2.0,
  AttackMs: 1000,
  AttackEasingFunction: 'Sinusoidal',

  HoldMs: 500,

  DecayMs: 750,
  DecayEasingFunction: 'Sinusoidal',

  ReleaseMs: 1000,
  ReleaseEasingFunction: 'Sinusoidal',  // 'Quintic',
};

class AHDSRStateMachine {
  private _state: AHDSRStates = AHDSRStates.Idle;
  private _reverse = false;
  private _lastTransitionTimestamp = 0;
  private _lastHighValue = 0;
  private _lastLowValue = 0;

  constructor() {
    this.applyConfig(defaultConfg);
  }

  // private _attackMs: number = 500;
  // private _holdMs: number = 250;
  // private _decayMs: number = 500;
  // private _releaseMs: number = 500;

  private _attackGain = 0;
  private _attackMs = 0;
  private _attackEasingFunction: EasingFunctionKeys = 'Linear';

  private _holdMs = 0;

  private _decayMs = 0;
  private _decayEasingFunction: EasingFunctionKeys = 'Linear';

  private _releaseMs = 0;
  private _releaseEasingFunction: EasingFunctionKeys = 'Linear';

  applyConfig(confg: AHDSRConfig) {
    this._attackGain = confg.AttackGain;
    this._attackMs = confg.AttackMs;
    this._attackEasingFunction = confg.AttackEasingFunction;
  
    this._holdMs = confg.HoldMs;
  
    this._decayMs = confg.DecayMs;
    this._decayEasingFunction = confg.DecayEasingFunction;
  
    this._releaseMs = confg.ReleaseMs;
    this._releaseEasingFunction = confg.ReleaseEasingFunction;
  }

  /**
   * Method called when the AHDSR machine should enter the attack phase (accelerate).
   * @param reverse If true we are triggering a signal for momentary reverse mode.
   */
  trigger(reverse?: boolean) {
    // If reverse is `undefined`, we will take that as `false` by default.
    this.setState(AHDSRStates.Attack, (reverse === true));
    // console.log(`AHDSR ATTACK! ${reverse}`);
  }

  /**
   * Method called when the AHDSR machine should transition to the release phase (decelerate).
   * @param reverse If true we are releasing a signal for momentary reverse mode.
   */
  release(reverse?: boolean) {
    // If reverse is `undefined`, we will take that as `false` by default.
    this.setState(AHDSRStates.Release, (reverse === true));
    // console.log(`AHDSR RELEASE! ${reverse}`);
  }

  /**
   * Reset the statemachine to Idle, not reversed, at result 0.
   */
  reset() {
    this.setState(AHDSRStates.Idle, false);
    this.saveLastHighResult(0);
    this.saveLastLowResult(0);
  }

  private setState(state: AHDSRStates, reverse?: boolean) {
    this._state = state;

    // If `reverse` is undefined, we will take that to mean "no change" from current value.
    if(reverse !== undefined) {
      this._reverse = reverse;
    }
    this._lastTransitionTimestamp = performance.now();
    // console.log(`AHDSRStateMachine.setState(${state}) at ${this._lastTransitionTimestamp} (reverse = ${this._reverse})`);
    return this._lastTransitionTimestamp;
  }
  private saveLastHighResult(value: number) {
    this._lastHighValue = value;

    // let stateName = '';
    // switch(this._state) {
    //   case 0:
    //     stateName = 'Idle';
    //     break;
    //   case 1:
    //     stateName = 'Attack';
    //     break;
    //   case 2:
    //     stateName = 'Hold';
    //     break;
    //   case 3:
    //     stateName = 'Decay';
    //     break;
    //   case 4:
    //     stateName = 'Sustain';
    //     break;
    //   case 5:
    //     stateName = 'Release';
    //     break;
    // }
    // console.log(`ahdsrValue _lastHighValue ${this._lastHighValue} for ${stateName}`);
    return this._lastHighValue;
  }
  private saveLastLowResult(value: number) {
    this._lastLowValue = value;

    // let stateName = '';
    // switch(this._state) {
    //   case 0:
    //     stateName = 'Idle';
    //     break;
    //   case 1:
    //     stateName = 'Attack';
    //     break;
    //   case 2:
    //     stateName = 'Hold';
    //     break;
    //   case 3:
    //     stateName = 'Decay';
    //     break;
    //   case 4:
    //     stateName = 'Sustain';
    //     break;
    //   case 5:
    //     stateName = 'Release';
    //     break;
    // }
    // console.log(`ahdsrValue _lastLowValue ${this._lastLowValue} for ${stateName}`);
    return this._lastLowValue;
  }
  private buildCurrentValue(ahdsrValue: number): ICurrentEnvelopeValue {
    return {
      ahdsrValue,
      ahdsrReversed: this._reverse,
    };
  }

  getCurrentEnvelopeValue(sustainValue: number): ICurrentEnvelopeValue {
    // If we are idle, value is 0.
    if(this._state === AHDSRStates.Idle || !this._lastTransitionTimestamp) {
      // this.saveLastHighResult(0);
      return {
        ahdsrValue: undefined,
        ahdsrReversed: this._reverse,
      };
    }

    //
    // 1.) Handle Attack Phase
    //
    let deltaTime = performance.now() - this._lastTransitionTimestamp;
    if(this._state === AHDSRStates.Attack && deltaTime >= this._attackMs) {
      this.setState(AHDSRStates.Hold);
      deltaTime = 0;
    }
    if(this._state === AHDSRStates.Attack) {
      // Calculate the amplitude for the current position within the attack phase.
      // return this.saveLastHighResult((sustainValue * this._attackGain) * easeInOutCubic(deltaTime / this._attackMs));
      const easingFn = EasingFunctions[this._attackEasingFunction].Out;

      return this.buildCurrentValue(this.saveLastHighResult(
        this._lastLowValue
          + (((sustainValue * this._attackGain) - this._lastLowValue)
            * easingFn(deltaTime / this._attackMs))
      ));
    }

    //
    // 2.) Handle Hold Phase
    //
    if(this._state === AHDSRStates.Hold && deltaTime >= this._holdMs) {
      this.setState(AHDSRStates.Decay);
      deltaTime = 0;
    }
    if(this._state === AHDSRStates.Hold) {
      // Calculate the amplitude for the current position within the hold phase.
      return this.buildCurrentValue(this.saveLastHighResult(sustainValue * this._attackGain));
    }

    //
    // 3.) Handle Decay Phase
    //
    if(this._state === AHDSRStates.Decay && deltaTime >= (this._decayMs)) {
      this.setState(AHDSRStates.Sustain);
      deltaTime = 0;
    }
    if(this._state === AHDSRStates.Decay) {
      // Calculate the amplitude for the current position within the decay phase.
      const easingFn = EasingFunctions[this._decayEasingFunction].InOut;

      return this.buildCurrentValue(
        sustainValue
          + ((sustainValue * (this._attackGain - 1))
            * (1 - easingFn(deltaTime / this._decayMs)))
      );
    }

    //
    // 4.) Handle Sustain Phase
    //
    if(this._state === AHDSRStates.Sustain) {
      // Calculate the amplitude for the current position within the sustain phase.
      return this.buildCurrentValue(this.saveLastHighResult(sustainValue));
    }

    //
    // 5.) Handle Release Phase
    //
    if(this._state === AHDSRStates.Release && deltaTime >= (this._releaseMs)) {
      this.setState(AHDSRStates.Idle);
      deltaTime = 0;

      // Make sure the last result we return that is not undefined is exactly 0. This will trigger 
      // the prompter to pause.
      return this.buildCurrentValue(this.saveLastLowResult(0));
    }
    if(this._state === AHDSRStates.Release) {
      // Calculate the amplitude for the current position within the decay phase.
      const easingFn = EasingFunctions[this._releaseEasingFunction].In;

      return this.buildCurrentValue(this.saveLastLowResult(this._lastHighValue * (1 - easingFn(deltaTime / this._releaseMs))));
    }

    // Should be impossible to get here... but this is a failsafe fall through.
    return {
      ahdsrValue: undefined,
      ahdsrReversed: this._reverse,
    };
  }
}

export default AHDSRStateMachine;