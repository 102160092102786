import React from 'react';
import EventEmitter from 'eventemitter3';
import { v4 as uuidv4 } from 'uuid';

import DeviceHost from './DeviceHost';
import PrompterPeerInstance from './prompterpeer/PrompterPeerInstance';
import { ConnectionState, EndpointRole } from '@fluidprompter/core';

import { DeviceConnectingEvent } from './events/DeviceConnectingEvent';
import { DeviceConnectedEvent } from './events/DeviceConnectedEvent';
import { DeviceDisconnectedEvent } from './events/DeviceDisconnectedEvent';
import { DeviceRemovedEvent } from './events/DeviceRemovedEvent';
import { DeviceReportEvent } from './events/DeviceReportEvent';
import { DeviceButtonEvent } from './events/DeviceButtonEvent';
import { DeviceWheelEvent } from './events/DeviceWheelEvent';
import { DeviceAxisEvent } from './events/DeviceAxisEvent';
import { TFunction } from 'i18next';
import _ from 'lodash';

export interface DeviceComponentProps {
  device?: BaseDevice;
  deviceMenuAnchor: HTMLDivElement | undefined;
  children?: React.ReactNode;
  onClose: (closeAll?: boolean) => void;
}
export type DeviceComponent = React.FC<DeviceComponentProps>; //(props: DeviceComponentProps) => React.ReactNode;

/**
 * Config class represents the set of options that can be used to tune an axis input from
 * various input devices. This includes enforcing minimums, maximums, and scaling values.
 */
export class DeviceAxisConfig {

  get valueMinimum(): number {
    return this._valueMinimum;
  }
  set valueMinimum(value: number) {
    this._valueMinimum = value;
  }
  private _valueMinimum = -1;

  get valueMinimumTolerance(): number {
    return this._valueMinimumTolerance;
  }
  set valueMinimumTolerance(value: number) {
    this._valueMinimumTolerance = value;
  }
  private _valueMinimumTolerance = 0.01;

  get valueNeutral(): number {
    return this._valueNeutral;
  }
  set valueNeutral(value: number) {
    this._valueNeutral = value;
  }
  private _valueNeutral = 0;

  get valueNeutralTolerance(): number {
    return this._valueNeutralTolerance;
  }
  set valueNeutralTolerance(value: number) {
    this._valueNeutralTolerance = value;
  }
  private _valueNeutralTolerance = 0.01;

  get valueMaximum(): number {
    return this._valueMaximum;
  }
  set valueMaximum(value: number) {
    this._valueMaximum = value;
  }
  private _valueMaximum = 1;

  get valueMaximumTolerance(): number {
    return this._valueMaximumTolerance;
  }
  set valueMaximumTolerance(value: number) {
    this._valueMaximumTolerance = value;
  }
  private _valueMaximumTolerance = 0.01;
}

// const syntheticEvent = new WheelEvent("syntheticWheel", {
//   deltaX: 4,
//   deltaMode: 0,
// });
//
// const kbdEvent = new KeyboardEvent("syntheticKey", false);

interface BaseDeviceEvents {
  connecting: (e: DeviceConnectingEvent) => void;
  connected: (e: DeviceConnectedEvent) => void;
  disconnected: (e: DeviceDisconnectedEvent) => void;
  removed: (e: DeviceRemovedEvent) => void;
  devicereport: (e: DeviceReportEvent) => void;
  // Input events! Should I have keydown, keyup, keypress?
  buttonreport: (e: DeviceButtonEvent) => void;
  wheelevent: (e: DeviceWheelEvent) => void;
  axisevent: (e: DeviceAxisEvent) => void;
}

export enum DeviceConnectionType {
  Unknown = 0,
  Keyboard,
  Mouse,
  Touch,
  Gamepad,
  Hid,
  Bluetooth,
  Network,
}
export interface IDeviceDescriptor {
  connectionType: DeviceConnectionType,
  deviceKey: string,
  deviceName: string,
  deviceIcon: string,
  requiresBluetooth?: boolean,
  requiresHid?: boolean,
  requiresPlanLevel: number,
  priority?: number,
}
export interface IDeviceState {
  id: string,
  parentId?: string,
  sortPriority: number,
  name?: string,
  icon?: string,
  iconOverlay?: string,
  connectionType: DeviceConnectionType,
  timestamp: number,
  batteryPercentage?: number,
  batteryCharging?: boolean,
  //
  endpointRole?: EndpointRole,
}

export interface GenericDeviceConstructor {
  new (deviceHost: DeviceHost): BaseDevice;
}

// interface IConnectableDevice {
//   // connect: () => Promise<void>;
// }

abstract class BaseDevice
  extends EventEmitter<BaseDeviceEvents>
  // implements IConnectableDevice // EventTarget,
{
  /**
   * This is the type discriminator string - generally the device type identifier for device the
   * inherit from BaseDevice.
   */
  abstract readonly type: string;

  private _timestamp: number;
  protected _sortPriority: number;

  constructor(deviceHost: DeviceHost, suppressConnectionAlerts?: boolean, deviceId?: string) {
    super();
    this._suppressConnectionAlerts = suppressConnectionAlerts;
    this._deviceHost = deviceHost;

    this._deviceId = deviceId || uuidv4();

    const localDevice = deviceHost.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .find((device) => device.representsLocal);
    if(localDevice) {
      this._parentId = localDevice._deviceId;
    }

    this._timestamp = new Date().getTime();
    this._sortPriority = this._timestamp;

    this._connectionType = DeviceConnectionType.Unknown;
    this._connectionState = ConnectionState.Disconnected;
    this._disconnectRequested = false;
    this._removeAfterDisconnect = false;

    this.requestDeviceReport = _.debounce(this.emitDeviceReport, 100, {
      leading: false,
      trailing: true,
    });

    deviceHost.registerDevice(this);
  }

  /**
   * When the current application language changes, all devices will be informed via this function.
   * @param t Translation function
   */
  public applyTranslation(t: TFunction) {
    //
  }

  /**
   * Let's create a debounced method for requesting this peer instance emit a device report.
   * Debounced method is initialized in constructor.
   */
  private emitDeviceReport() {
    this.emit('devicereport', new DeviceReportEvent(this));
  }
  protected requestDeviceReport: _.DebouncedFunc<() => void>;

  protected get deviceHost() {
    return this._deviceHost;
  }
  private _deviceHost: DeviceHost;

  public get id() {
    return this._deviceId;
  }
  protected set id(deviceId: string) {
    if (!deviceId) {
      throw new Error('deviceId cannot be empty.');
    }
    this._deviceId = deviceId;
  }
  private _deviceId: string;

  public get parentId() {
    return this._parentId;
  }
  protected set parentId(parentId: string | undefined) {
    // if (!parentId) {
    //   throw new Error('parentId cannot be empty.');
    // }
    this._parentId = parentId;
  }
  private _parentId?: string;

  public get name() {
    return this._deviceName || '-';
  }
  protected set name(deviceName: string) {
    if (!deviceName) {
      throw new Error('deviceName cannot be empty.');
    }
    this._deviceName = deviceName;
  }
  private _deviceName: string | undefined;

  public get icon() {
    return this._icon;
  }
  protected set icon(iconName: string | undefined) {
    if (!iconName) {
      throw new Error('iconName cannot be empty.');
    }
    this._icon = iconName;
  }
  private _icon: string | undefined;

  public get connectionType() {
    return this._connectionType;
  }
  protected set connectionType(connectionType: DeviceConnectionType) {
    this._connectionType = connectionType;
  }
  private _connectionType: DeviceConnectionType;

  public get batteryPercentage() {
    return this._batteryPercentage;
  }
  protected set batteryPercentage(batteryPercentage: number | undefined) {
    if (batteryPercentage === undefined) {
      throw new Error('batteryPercentage must be between 0 and 100.');
    }
    if (batteryPercentage < 0) {
      this._batteryPercentage = 0;
      return;
    }
    if (batteryPercentage > 100) {
      this._batteryPercentage = 100;
      return;
    }

    this._batteryPercentage = batteryPercentage;
  }
  private _batteryPercentage: number | undefined = undefined;

  public get batteryCharging() {
    return this._batteryCharging;
  }
  protected set batteryCharging(batteryCharging: boolean | undefined) {
    if (batteryCharging === undefined) {
      throw new Error('batteryCharging must be provided.');
    }

    this._batteryCharging = batteryCharging;
  }
  private _batteryCharging: boolean | undefined = undefined;

  public get suppressConnectionAlerts() {
    return this._suppressConnectionAlerts === true;
  }
  protected set suppressConnectionAlerts(value: boolean) {
    this._suppressConnectionAlerts = value;
  }
  private _suppressConnectionAlerts: boolean | undefined = undefined;

  public get connectionState() {
    return this._connectionState;
  }
  protected _connectionState: ConnectionState;

  public get disconnectRequested() {
    return this._disconnectRequested;
  }
  protected _disconnectRequested: boolean;

  public get removeAfterDisconnect() {
    return this._removeAfterDisconnect;
  }
  protected _removeAfterDisconnect: boolean;

  abstract connect(): void;
  abstract disconnect(): void;

  /**
   * Returns a POJO describing the current state of this device.
   */
  getDeviceState(): IDeviceState {
    return {
      id: this._deviceId,
      parentId: this._parentId,
      sortPriority: this._sortPriority,
      name: this._deviceName,
      icon: this._icon,
      connectionType: this._connectionType,
      timestamp: this._timestamp,
      batteryPercentage: this._batteryPercentage,
      batteryCharging: this._batteryCharging,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDeviceDescriptors(t: TFunction): IDeviceDescriptor[] {
    throw new Error('Error getDeviceDescriptor() not implemented for device.');
  }

  abstract getDeviceUIComponent(): DeviceComponent;
}

export default BaseDevice;