import { StateCreator } from 'zustand';
import { IDeviceState } from '../../devices/BaseDevice';

import { v4 as uuidv4 } from 'uuid';

import { Descendant } from 'slate';
import { ElementTypes, PrompterElement } from '../../models/EditorTypes';
import IPrompterPosition from '../../models/IPrompterPosition';

import { ConnectionState, PrompterMode, IPrompterMeta, ISlateNodeMeta, IScriptNodeState, IScriptPosition } from '@fluidprompter/core';
import getTreeNodeByPath from '../../utils/getTreeNodeByPath';

import ISegmentMeasurements from '../../models/segments/ISegmentMeasurements';

import InitialScript from '../InitialScript';

interface IScrollPosition {
  scrollPosition: number;
  heightRemaining: number;
}

export interface IPrompterScriptSlice {
  isSynchronizing: boolean;
  setIsSynchronizing: (hasUnsavedChanges?: boolean) => void;

  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (hasUnsavedChanges?: boolean) => void;
  unsetHasUnsavedChanges: () => void;

  editorFocused: boolean;
  setEditorFocused: (isFocused: boolean) => void;

  lastScriptChangeTimestamp: number | undefined;
  setLastScriptChangeTimestamp: (lastScriptChangeTimestamp: number) => void;

  scriptNodes: Descendant[];
  setScriptNodes: (script: Descendant[], lastChangedTimestamp?: number) => void;
  getScriptNode: (nodePath: number[]) => Descendant | undefined;
  getScriptNodePath: (node?: Descendant) => number[] | undefined;
  currentScriptNode: Descendant | undefined;
  currentScriptNodeIndex: number;
  setCurrentScriptNode: (node?: Descendant) => void;

  // Meta information = dimensions for each prompter segment as well as the overall document height.
  // These are recalculated everytime the prompter content is resized. This includes changing the font size, the content width or gutter width, or editing the content to add more content or remove content.
  scriptNodesMeta: IPrompterMeta | undefined;
  setScriptNodesMeta: (script: IPrompterMeta) => void;
  getNodeMeta: (node: Descendant) => ISlateNodeMeta | undefined;
  getNodeMetaByPath: (nodePath: number[]) => ISlateNodeMeta | undefined;
  getScrollPositionMax: () => number;
  getScrollPositionByScriptPosition: (scriptPosition: IScriptPosition) => IScrollPosition | undefined;
  getScriptPositionByScrollPosition: (scrollPosition: number) => IScriptPosition | undefined;
  getScriptPositionByOffset: (offset: number) => IScriptPosition | undefined;

  // State information = whether each node is outside viewport, inside viewport, or on the cue position.
  // These are re-evaluated whenever the prompter is scrolled using the ScriptNodesMeta information above to avoid frequent large DOM calculations.
  scrollPosition: number;
  scriptNodesState: IPrompterPosition | undefined;
  isAtStartOfScript: boolean;
  isAtEndOfScript: boolean;
  setScriptNodesState: (scrollPosition: number, prompterPosition: IPrompterPosition) => void;
  getNodeState: (node: Descendant) => IScriptNodeState | undefined;

  textMetrics: ISegmentMeasurements;
  setTextMetrics: (metrics: ISegmentMeasurements) => void;
  getNodeTextMetrics: (node: Descendant) => ISegmentMeasurements | undefined;
  getNodeTextMetricsByPath: (nodePath: number[]) => ISegmentMeasurements | undefined;
}

// 1000 ms x 60 secs x 60 mins x 24 hrs x 365 days
const ONE_YEAR_MS = 1000 * 60 * 60 * 24 * 365;

const createPrompterScriptSlice: StateCreator<
  IPrompterScriptSlice,
  [],
  [],
  IPrompterScriptSlice
> = (set, get) => ({

  isSynchronizing: true,
  setIsSynchronizing: (isSynchronizing?: boolean) => set(() => { return { isSynchronizing: isSynchronizing === true }; }),

  hasUnsavedChanges: false,
  setHasUnsavedChanges: (hasUnsavedChanges?: boolean) => set(() => { return { hasUnsavedChanges: !(hasUnsavedChanges === false) }; }),
  unsetHasUnsavedChanges: () => set(() => { return { hasUnsavedChanges: false }; }),

  editorFocused: false,
  setEditorFocused: (editorFocused: boolean) => set(() => { return { editorFocused }; }),

  lastScriptChangeTimestamp: undefined,
  setLastScriptChangeTimestamp: (lastScriptChangeTimestamp: number) => set(() => ({ lastScriptChangeTimestamp })),
  scriptNodes: InitialScript,
  setScriptNodes: (scriptNodes: Descendant[], lastChangedTimestamp?: number) => {
    set(() => {
      if(!scriptNodes) {
        throw new Error('Descendant[] is required.');
      }

      return { scriptNodes, lastScriptChangeTimestamp: lastChangedTimestamp || Date.now() };
    });
  },
  getScriptNode: (nodePath?: number[]): Descendant | undefined => {
    const state = get();
    if(!state.scriptNodes || !nodePath) {
      return undefined;
    }

    let scriptNodesCollection: Descendant[] = state.scriptNodes;
    for(let i = 0; i < nodePath.length; i++) {
      const currentIndex = nodePath[i];

      const nextNode = scriptNodesCollection[currentIndex];
      if(i === nodePath.length - 1) {
        // This is the last index in the path.
        return nextNode;
      }

      const prompterElement = nextNode as PrompterElement;
      if(prompterElement && prompterElement.children) {
        scriptNodesCollection = prompterElement.children;
        continue;
      }

      // If we get here and didn't return or Continue above, then this is an error case.
      break;
    }

    // If we get here and didn't return or Continue above, then this is an error case.
    throw new Error('Unresolveable ScriptNode Path');
  },
  getScriptNodePath: (node?: Descendant): number[] | undefined => {
    const state = get();
    if(!state.scriptNodes) {
      return undefined;
    }

    const recursiveFilter = (currentPath: number[], scriptNodes: Descendant[], targetNode?: Descendant) => {
      //
      // Note, we are optimizing for matching shallowest depth first rather than searching full
      // children tree as we go.
      //

      // Check if the target node is contained in the current collection.
      const nodeIndex = state.scriptNodes.findIndex((n) => n === targetNode);
      if(nodeIndex >= 0) {
        // We found it
        const thisPath = [...currentPath, nodeIndex];
        return thisPath;
      }

      // Check if any of the nodes in the collection have children
      state.scriptNodes.forEach((n, childIndex) => {
        const prompterElement = n as PrompterElement;
        if(prompterElement.children) {
          // This node has children.
          const childrenResults = recursiveFilter([...currentPath, childIndex], prompterElement.children, targetNode);
          if(childrenResults) { return childrenResults; }
        }
      });

      return; // Return undefined, we didn't find anything.
    };

    return recursiveFilter([], state.scriptNodes, node);
  },
  currentScriptNode: undefined,
  currentScriptNodeIndex: -1,
  setCurrentScriptNode: (node?: Descendant) => {
    set((state) => {
      const currentScriptNodeIndex = state.scriptNodes.findIndex((scriptNode) => (scriptNode === node));
      return { currentScriptNode: node, currentScriptNodeIndex };
    });
  },
  scriptNodesMeta: undefined,
  setScriptNodesMeta: (prompterMeta: IPrompterMeta) => {
    set(() => {
      if(!prompterMeta) {
        throw new Error('IPrompterMeta[] is required.');
      }

      return { scriptNodesMeta: prompterMeta };
    });
  },
  getNodeMeta: (targetNode: Descendant): ISlateNodeMeta | undefined => {
    const state = get();
    if(!state.scriptNodes || !state.scriptNodesMeta) {
      return undefined;
    }

    const nodeIndex = state.scriptNodes.findIndex((node) => node === targetNode);
    if(nodeIndex < 0) {
      // Total edge case, race condition... could a node be deleted at the same time some component wants to get information about it?
      return undefined;
    }

    return state.scriptNodesMeta.nodesMeta[nodeIndex];
  },
  getNodeMetaByPath: (nodePath: number[]) => {
    const state = get();
    if(!state.scriptNodesMeta) {
      return undefined;
    }

    try {
      return getTreeNodeByPath(nodePath, state.scriptNodesMeta.nodesMeta);
    }
    catch(err) {
      // We aren't interested in handling errors related to bad data
      //
      // If we encounter a problem, it's probably because the nodePath doesn't exist in the
      // current tree data.
      //
      // We will just return undefined.
      return undefined;
    }
  },
  getScrollPositionMax: (): number => {
    const state = get();
    if(!state.scriptNodesMeta) {
      return 0;
    }

    return (state.scriptNodesMeta.contentHeight - state.scriptNodesMeta.viewportHeight);
  },
  getScrollPositionByScriptPosition: (scriptPosition: IScriptPosition) => {
    const {
      nodePath,
      position: senderPosition,
      nodeChildrenTop: senderChildrenTop,
      nodeChildrenHeight: senderChildrenHeight,
      nodeHeight: senderHeight,
    } = scriptPosition;
    const state = get();

    let nodeMeta: ISlateNodeMeta | undefined;
    try {
      nodeMeta = state.getNodeMetaByPath(nodePath);
    }
    catch(err) {
      // We aren't interested in handling errors related to bad data
      //
      // If we encounter a problem, it's probably because the nodePath doesn't exist in the
      // current tree data.
      //
      // We will just return undefined.
      return undefined;
    }
    if(!nodeMeta) {
      return undefined;
    }
    const {
      top: localTop,
      height: localHeight,
      childrenTop: localChildrenTop,
      childrenHeight: localChildrenHeight
    } = nodeMeta;

    // Default to scaling based on the overall block height position as a percentage.
    const positionPercentage = senderPosition / senderHeight;
    const heightRemaining = (senderPosition >= senderHeight)
      ? 0 : senderHeight - senderPosition;

    // Calculate the local script position as a scroll position given a script node path and
    // percentage of that node's height.
    let scrollPosition = localTop
      + (localHeight * positionPercentage);

    const childrenBottom = senderChildrenTop + senderChildrenHeight;
    /* if(senderPosition > senderHeight) {
      //
      // We are past the height of this node, but not yet into the next node in the script.
      // This means we are in the margin space between script nodes such as where the insert
      // toolbar floats below script segments. This will be express as a position greater than
      // 100% of the node height relative to the node top.
      //
      const currentSegmentIndex = nodePath.shift();
      if(currentSegmentIndex === undefined) {
        throw new Error('nodePath was invalid.');
      }
      const nextSegmentIndex = currentSegmentIndex + 1;
      const nextSiblingMeta = state.getNodeMetaByPath([nextSegmentIndex]);
      if(nextSiblingMeta === undefined) {
        throw new Error('nextSiblingMeta was invalid.');
      }

      const currentNodeMeta = nodeMeta;
      const { top: currentTop, bottom: currentBottom, marginBottom } = currentNodeMeta;
      const { top: nextTop, bottom: nextBottom } = nextSiblingMeta;

      const spaceBetween = nextTop - currentBottom;
      console.log(`spaceBetween=${spaceBetween}, marginBottom=${marginBottom}`);

    } else */
    if(nodePath.length === 1 && nodePath[0] === 0) {
      //
      // Special case to handle the Start Script element which doesn't get measured well.
      //
      scrollPosition = localTop
        + (senderPosition * localHeight / senderHeight);

    } else if (senderPosition > childrenBottom) {
      //
      // We are in the block footer
      //
      const fixedSenderPosition = senderChildrenTop + senderChildrenHeight;
      const variableSenderPosition = senderPosition - fixedSenderPosition;

      const senderBottomHeight = senderHeight - senderChildrenTop - senderChildrenHeight;
      const localBottomHeight = localHeight - (localChildrenTop - localTop) - localChildrenHeight;

      scrollPosition = localChildrenTop
        + localChildrenHeight
        + (variableSenderPosition * localBottomHeight / senderBottomHeight);

    } else if(senderPosition >= senderChildrenTop) {
      //
      // We are in the block body (children area)
      //
      scrollPosition = localTop
        + (senderPosition * localChildrenHeight / senderChildrenHeight);

    } else {
      //
      // We are in the block header.
      //
      const senderHeaderHeight = senderChildrenTop;
      const localHeaderHeight = localChildrenTop - localTop;

      scrollPosition = localTop
        + (senderPosition * localHeaderHeight / senderHeaderHeight);

    }

    return {
      scrollPosition,
      heightRemaining,
    };
  },
  getScriptPositionByScrollPosition: (scrollPosition: number) => {
    const state = get();
    const { scriptNodesMeta } = state;
    if(!scriptNodesMeta) {
      return undefined;
    }

    const { nodesMeta: rootNodesMeta } = scriptNodesMeta;
    if(!rootNodesMeta) {
      return undefined;
    }

    // TODO: Find the last node at the deepest level
    const recursiveFilter = (currentPath: number[], nodesMeta: ISlateNodeMeta[], targetPosition: number): IScriptPosition | undefined => {

      if(!nodesMeta.length) {
        return;
      }

      //
      // We are searching "depth first" - we are looking for the deepest matching block node.
      // Therefor search children first before searching this node.
      //
      let childResult: IScriptPosition | undefined;
      // TODO: Can we make this a .reduce()?
      nodesMeta.find((nodeMeta, childIndex) => {

        //
        // Only evaluate children nodes if our target position is within the range of the children
        // top and bottom (deepest node containing the target position). Otherwise we want our
        // IScriptPosition to be relative to the last shallowest node (probably root script
        // segment).
        //
        if(
          nodeMeta.children
          && (targetPosition >= nodeMeta.childrenTop)
          && (targetPosition <= nodeMeta.childrenBottom)
        ) {
          // This node has children and the targetPosition is somewhere within those children.
          childResult = recursiveFilter([...currentPath, childIndex], nodeMeta.children, targetPosition);
        }

        return (childResult !== undefined);
      });
      if(childResult) {
        // console.log(`FOUND childResult recursiveFilter([${childResult.nodePath}]), isBlock=${resultMeta?.isBlock}`);
        return childResult;
      }

      //
      // Find the last node whose top has passed the target position.
      //
      let lastIndexToQualify = -1;
      for(let i = 0; i < nodesMeta.length; i++) {
        const nodeMeta = nodesMeta[i];
        if(nodeMeta.isBlock && (nodeMeta.top <= targetPosition)) {
          lastIndexToQualify = i;
        }
      }

      //
      // Prepare our final results relative to the last node to have passed the target position.
      //
      if(lastIndexToQualify >= 0) {
        // We found it
        const nodeMeta = nodesMeta[lastIndexToQualify];
        const thisPath = [...currentPath, lastIndexToQualify];

        const finalResult: IScriptPosition = {
          nodePath: thisPath,
          position: targetPosition - nodeMeta.top,
          nodeHeight: nodeMeta.height,
          nodeChildrenTop: nodeMeta.childrenTop - nodeMeta.top,
          nodeChildrenHeight: nodeMeta.childrenHeight,
        };
        return finalResult;
      }

      return; // Return undefined, we didn't find anything.
    };

    return recursiveFilter([], rootNodesMeta, scrollPosition);
  },
  getScriptPositionByOffset: (offset: number) => {
    const state = get();

    const { scrollPosition, scriptNodesState, scriptNodesMeta } = state;
    if(!scriptNodesState || !scriptNodesMeta) {
      return undefined;
    }

    let targetScrollPosition = scrollPosition + offset;
    if(targetScrollPosition < 0) {
      targetScrollPosition = 0;
    }
    const scrollPositionMax = scriptNodesMeta.contentHeight - scriptNodesMeta.viewportHeight;
    if(targetScrollPosition > scrollPositionMax) {
      targetScrollPosition = scrollPositionMax;
    }

    return state.getScriptPositionByScrollPosition(targetScrollPosition);
  },

  scrollPosition: 0,
  scriptNodesState: undefined,
  isAtStartOfScript: true,
  isAtEndOfScript: false,
  setScriptNodesState: (scrollPosition: number, scriptNodesState: IPrompterPosition) => {
    set(() => {
      if(!scriptNodesState) {
        throw new Error('IPrompterMeta[] is required.');
      }

      const startNodeState = scriptNodesState.nodesState[0];
      const isAtStartOfScript = startNodeState.onCue;

      const endNodeState = scriptNodesState.nodesState[scriptNodesState.nodesState.length - 1];
      const isAtEndOfScript = endNodeState.onCue || endNodeState.aboveCue;

      return {
        scrollPosition,
        scriptNodesState,
        isAtStartOfScript,
        isAtEndOfScript,
      };
    });
  },
  getNodeState: (targetNode: Descendant): IScriptNodeState | undefined => {
    const state = get();
    if(!state.scriptNodes || !state.scriptNodesState) {
      return undefined;
    }

    const nodeIndex = state.scriptNodes.findIndex((node) => node === targetNode);
    if(nodeIndex < 0) {
      // Total edge case, race condition... could a node be deleted at the same time some component wants to get information about it?
      return undefined;
    }

    return state.scriptNodesState.nodesState[nodeIndex];
  },

  textMetrics: {
    nodePath: [],
    lines: 0,
    //
    document_characters: 0,
    document_words: 0,
    //
    characters: 0,
    words: 0,
    //
    estimatedLength: 0,
    children: [],
  },
  setTextMetrics: (metrics: ISegmentMeasurements) => set(() => ({ textMetrics: metrics })),
  getNodeTextMetrics: (targetNode: Descendant): ISegmentMeasurements | undefined => {
    const state = get();
    if(!state.scriptNodes || !state.textMetrics) {
      return undefined;
    }

    // const scriptSegments = state.scriptNodes.filter((value) => (value as PrompterElement).type === ElementTypes.SCRIPT_SEGMENT);
    // const nodeIndex = scriptSegments.findIndex((node) => node === targetNode);
    const nodeIndex = state.scriptNodes.findIndex((node) => node === targetNode);
    if(nodeIndex < 0) {
      // Total edge case, race condition... could a node be deleted at the same time some component wants to get information about it?
      return undefined;
    }

    return state.textMetrics.children[nodeIndex];
  },
  getNodeTextMetricsByPath: (nodePath: number[]): ISegmentMeasurements | undefined => {
    const state = get();
    if(!state.textMetrics.children) {
      return undefined;
    }

    try {
      return getTreeNodeByPath(nodePath, state.textMetrics.children);
    } catch (err) {
      // We aren't interested in handling errors related to bad data
      //
      // If we encounter a problem, it's probably because the nodePath doesn't exist in the
      // current tree data.
      //
      // We will just return undefined.
      return undefined;
    }
  }
});

export default createPrompterScriptSlice;