import BinaryChunkMessage from "./BinaryChunkMessage";
const chunk_size = 16000; // Chunk size for transmitting on the wire.
class BinaryAssembler {
    constructor(bundleId, expectedBytes) {
        this._bundleId = bundleId;
        this._startedAt = new Date().getDate();
        this._receivedBytes = new Uint8Array(expectedBytes);
    }
    get bundleId() {
        return this._bundleId;
    }
    hasExpired() {
        const now = new Date().getDate();
        if (now - this._startedAt > 1000) {
            return true;
        }
        return false;
    }
    /**
     *
     * @param binaryChunk An incoming binary chunk message.
     * @returns true is bundle is complete, false if not yet complete.
     */
    receiveChunk(binaryChunk) {
        const { senderId, targetId, chunkNumber, data, bundleTotalBytes } = binaryChunk;
        //
        // Copy the received bytes into our receive buffer in the correct location.
        //
        const chunkOffset = (chunkNumber - 1) * chunk_size;
        // Error: Uncaught (in promise) RangeError: offset is out of bounds
        this._receivedBytes.set(new Uint8Array(data), chunkOffset);
        //
        // Check if we are done receiving the bundle!
        //
        if (chunkOffset + data.byteLength === bundleTotalBytes) {
            return {
                senderId,
                targetId,
                complete: true,
                result: this.toString(),
                totalChunks: chunkNumber,
            };
        }
        return {
            senderId,
            targetId,
            complete: false,
        };
    }
    toString() {
        const textDecoder = new TextDecoder('utf-8');
        return textDecoder.decode(this._receivedBytes);
    }
}
class BinaryReceiver {
    constructor() {
        this._batches = new Map();
    }
    receiveBinaryChunk(chunkBytes) {
        //
        // Deserialize our binary chunk
        //
        const binaryChunk = BinaryChunkMessage.fromBinary(chunkBytes);
        //
        // Based on the bundleId in the BinaryChunkMessage, get the correct assembler.
        //
        let assembler = this._batches.get(binaryChunk.bundleId);
        if (!assembler) {
            // Check for old stale, incomplete bundles. This is an error case where we never received
            // all the expected bytes in a bundle.
            for (const openBatch of this._batches.values()) {
                if (openBatch.hasExpired()) {
                    this._batches.delete(openBatch.bundleId);
                }
            }
            // Create our new Assembler for the current bundleId.
            assembler = new BinaryAssembler(binaryChunk.bundleId, binaryChunk.bundleTotalBytes);
            this._batches.set(binaryChunk.bundleId, assembler);
        }
        const binaryAssemblerResult = assembler.receiveChunk(binaryChunk);
        if (binaryAssemblerResult.complete) {
            // We are done receiving all chunks for this bundle!
            this._batches.delete(binaryChunk.bundleId);
        }
        return binaryAssemblerResult;
    }
}
export default BinaryReceiver;
