import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

interface ComparePlanSectionHeaderRowProps {
  icon: React.ReactNode,
  title: string,
  mt?: number,
}

function ComparePlanSectionHeaderRow(props: ComparePlanSectionHeaderRowProps) {
  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell component="th" scope="row" colSpan={2} sx={{ borderBottom: '0 none', userSelect: 'none', cursor: 'pointer' }}>
        <Typography variant="h2" sx={{ mt: props.mt || 0, fontSize: '1.5em', fontWeight: 'bold' }}>{props.icon}{props.title}</Typography>
      </TableCell>
      <TableCell align="center" sx={{ borderBottom: '0 none', verticalAlign: 'top', background: '#f6f8fa' }} />
    </TableRow>
  );
}

export default ComparePlanSectionHeaderRow;