import { ShepherdOptionsWithType } from 'react-shepherd';
import { TourStepFactoryMethod, TourStepFactoryParams } from './TourStepTypes';

const WelcomeTourStepFactory: TourStepFactoryMethod = (params: TourStepFactoryParams) => {
  const { t } = params;

  const WelcomeTourStep: ShepherdOptionsWithType = {
    id: 'TourWelcome',
    text: [t('welcometour.welcomestep.body')],
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: t('welcometour.welcomestep.cancel')
      },
      {
        type: 'next',
        text: t('next')
      }
    ]
  };

  return WelcomeTourStep;
};

export default WelcomeTourStepFactory;