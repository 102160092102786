class Stack<T>{
  private _stack: T[];

  constructor(stack?: T[]) {
    this._stack = stack || [];
  }

  peek(): T | undefined {
    // OLD JavaScript: return this._stack[this._stack.length - 1];
    return this._stack.at(-1);
  }

  push(item: T) {
    this._stack.push(item);
  }

  pop(): T | undefined {
    return this._stack.pop();
  }

  isEmpty() {
    return this._stack.length === 0;
  }

  clear() {
    this._stack = [];
  }

  getItems(){
    return this._stack;
  }    

  get count(): number {
    return this._stack.length;
  }

  toString() {
    return `Stack (${this._stack.length})`;
  }

  indexOf(searchElement: T, fromIndex?: number): number {
    return this._stack.indexOf(searchElement, fromIndex);
  }

  /*
  [Symbol.iterator]() {
    let index = this.start;
    return {
      next: () =>
        index < this.end
          ? {
              value: this.queue[index++]
            }
          : { done: true }
    };
  }
  */
}

export default Stack;