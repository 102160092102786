import React from 'react';
import Stack from '@mui/material/Stack';

import './AccountHeader.scss';

interface AccountHeaderProps {
  title: string,
  children?: React.ReactNode
}

const AccountHeader = function(props: AccountHeaderProps) {
  return (
    <Stack
      className='AccountHeader'
      direction='row'
      alignItems='flex-start'
      spacing={2}
      marginTop={2}
      marginBottom={2}
    >
      <h2>{props.title}</h2>
      {props.children}
    </Stack>
  );
};

export default AccountHeader;