import { StateCreator } from 'zustand';
import { PrompterMode } from '@fluidprompter/core';
import { IPrompterAttributesSlice } from './PrompterAttributesSlice';
import { IPrompterModeSlice } from './PrompterModeSlice';
import { IPrompterScriptSlice } from './PrompterScriptSlice';
import { ShotLogEntry, ShotLogEntryType } from '@fluidprompter/core';

export interface IShotLoggingSlice {
  shotloggingEnabled: boolean;
  setShotloggingEnabled: (enabled: boolean) => void;
  shotlogEntries: ShotLogEntry[];
  nextShotlogEntry: ShotLogEntry | undefined;
  startShotLogEntry: (newEntryType: ShotLogEntryType) => void;
  updateShotLogEntry: (nodePath: number[], title?: string) => void;
  finalizeShotLogEntry: (nodePath: number[], newEntryType: ShotLogEntryType, implied?: boolean) => void;
}

const createShotLoggingSlice: StateCreator<
  IPrompterAttributesSlice & IPrompterModeSlice & IPrompterScriptSlice & IShotLoggingSlice,
  [],
  [],
  IShotLoggingSlice
> = (set, get) => ({
  shotloggingEnabled: false,
  setShotloggingEnabled: (enabled: boolean) => {
    set(() => {
      return { shotloggingEnabled: enabled };
    });
  },
  shotlogEntries: [],
  nextShotlogEntry: undefined,
  startShotLogEntry: (newEntryType) => {
    set((state) => {
      const scrollPositionMax = state.getScrollPositionMax();

      const scriptPosition = state.scriptNodesState?.scriptPosition;
      if(!scriptPosition) {
        // ScriptPosition will be undefined in unit tests, unless we mock it.
        console.error('scriptPosition is undefined in startShotLogEntry()');
        return state;
      }

      // const currentScrollPosition = state.scrollPosition || 0;
      const viewportPosition = state.getScrollPositionByScriptPosition(scriptPosition);
      if(!viewportPosition) {
        console.error('viewportPosition is undefined in startShotLogEntry()');
        return state;
      }

      const proposedPosition = scrollPositionMax ? ((viewportPosition.scrollPosition + ((state.scriptNodesMeta?.viewportHeight || 0) / 2)) / scrollPositionMax) : 0;

      const nowTimestamp = new Date().getTime();
      const newShotlogEntry: ShotLogEntry = {
        nodePath: [0],    // TODO
        nodePathKey: '0', // TODO
        take: 1,          // TODO
        type: newEntryType, // ShotLogEntryType.ThumbsUp,
        // timestamp: new Date().getTime() - state.sessionStartTimestamp,
        startTimestamp: state.sessionStartTimestamp,
        finishTimestamp: nowTimestamp,
        startScriptPosition: proposedPosition,
        endScriptPosition: proposedPosition,
      };

      const newShotlogEntries = [...state.shotlogEntries, newShotlogEntry];

      return { shotlogEntries: newShotlogEntries };
    });
  },
  updateShotLogEntry: (nodePath: number[], title?: string) => {
    set((state) => {
      const nodePathKey = nodePath.join('-');

      // Find the last ShotLogEntry recorded for the same segment nodePath
      let lastTakeForNodePath: ShotLogEntry | undefined = undefined;
      for(let i = state.shotlogEntries.length - 1; i >= 0; i--) {
        const evalEntry = state.shotlogEntries[i];

        if(evalEntry.nodePathKey === nodePathKey) {
          lastTakeForNodePath = evalEntry;
          break;
        }
      }

      const prevTake = lastTakeForNodePath?.take ?? 0;

      const shotLogEntry = { ...state?.nextShotlogEntry,
        nodePath,
        nodePathKey,
        title,
        take: prevTake + 1,
      } as ShotLogEntry;

      return { nextShotlogEntry: shotLogEntry };
    });
  },
  finalizeShotLogEntry: (nodePath: number[], entryType: ShotLogEntryType, implied?: boolean) => {
    set((state) => {
      const nowTimestamp = new Date().getTime();
      const nodePathKey = nodePath.join('-');
      // console.log('state.nextShotlogEntry', state.nextShotlogEntry);
      const scrollPositionMax = state.getScrollPositionMax();
      const currentScrollPosition = state.scrollPosition;
      const proposedPosition = scrollPositionMax ? ((currentScrollPosition + ((state.scriptNodesMeta?.viewportHeight || 0) / 2)) / scrollPositionMax) : 0;

      //
      // If the nodePath didn't match the above `state.nextShotlogEntry`, then check if we recently
      // finalized an implicit entry for this nodePath that we changed our mind about.
      //
      let shotlogEntries: ShotLogEntry[] = state.shotlogEntries;

      //
      // Lookup prior shotlog entry, if any.
      //
      const lastEntryIndex = (state.shotlogEntries && state.shotlogEntries.length) ? state.shotlogEntries.length - 1 : -1;
      let lastEntry: ShotLogEntry | undefined = undefined;
      if(lastEntryIndex >= 0) {
        lastEntry = state.shotlogEntries[lastEntryIndex];
      }

      //
      // Find the last ShotLogEntry recorded for the same segment nodePath
      //
      let lastTakeForNodePath: ShotLogEntry | undefined = undefined;
      for(let i = state.shotlogEntries.length - 1; i >= 0; i--) {
        const evalEntry = state.shotlogEntries[i];

        if(evalEntry.nodePathKey === nodePathKey) {
          lastTakeForNodePath = evalEntry;
          break;
        }
      }
      const prevTake = lastTakeForNodePath?.take ?? 0;

      //
      // Is this new shotlog entry for the same script segment as our previous shotlog entry?
      // If yes, we may possibly update the previous shotlog entry if it was implied.
      //
      if(lastEntry && lastEntry.nodePathKey === nodePathKey && lastEntry.implied)
      {
        // If the last entry was implied, we can update its type.
        const lastEntryReplacement: ShotLogEntry = {
          ...lastEntry,
          type: entryType,
          implied,
          // timestamp: new Date().getTime() - state.sessionStartTimestamp,
          finishTimestamp: nowTimestamp,
        };

        // Remove the last item from `state.shotlogEntries`.
        // Then append the new (updated) last entry.
        const previousShotlogEntries = state.shotlogEntries.slice(0, -1);
        shotlogEntries = [...previousShotlogEntries, lastEntryReplacement];
        return { shotlogEntries };
      }

      //
      // If the last entry was explicitly set from user, but then we triggered an implicit entry
      // soon after (by scrolling past the end of a script segment) then let's just update the
      // finishTimestamp and endScriptPosition.
      //
      if(lastEntry
        && lastEntry.nodePathKey === nodePathKey
        && !lastEntry.implied // Last entry was explicitly entered by user (not implied)
        && implied            // This entry was implied
        && lastEntry.type === entryType
      ) {
        // If the last entry was explicit, and this entry is implied, and they are the same entry
        // type, then just update the last entry timestamp? ex: If user clicked "Keep & Continue"
        // and then scrolled past end of prompter segment where implied goodtake is triggered.
        const lastEntryReplacement: ShotLogEntry = {
          ...lastEntry,
          endScriptPosition: proposedPosition,
          finishTimestamp: nowTimestamp,
        };

        const previousShotlogEntries = state.shotlogEntries.slice(0, -1);
        shotlogEntries = [...previousShotlogEntries, lastEntryReplacement];
        return { shotlogEntries };
      }

      //
      // If we get here, we didn't update the last shotlog entry and instead will now need to
      // insert a new entry.
      //
      // We will inherit property values from `nextShotlogEntry` if there are any (such as title).
      //
      const newEntry: ShotLogEntry = { ...state.nextShotlogEntry,
        nodePath,
        nodePathKey,
        take: prevTake + 1,
        type: entryType,
        implied,
        startTimestamp: lastEntry?.finishTimestamp || state.sessionStartTimestamp,
        finishTimestamp: nowTimestamp,
        startScriptPosition: lastEntry?.endScriptPosition || proposedPosition,
        endScriptPosition: proposedPosition,
      };
      shotlogEntries = [...state.shotlogEntries, newEntry];

      return { shotlogEntries };
    });
  },
});

export default createShotLoggingSlice;