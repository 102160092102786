import React, { useEffect, useState, forwardRef } from 'react';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  // padding: '4px 8px',
  // background: '#FFAB00', // theme.palette.warning.main,
  // borderColor: theme.palette.warning.dark,
  // borderRadius: theme.shape.borderRadius,
  // borderWidth: '2px',
  // color: theme.palette.warning.dark,
  /*
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
  */
}));

interface DeviceListItemButtonProps extends ListItemButtonProps {
  depth: number;
  isLast?: boolean;
  expanded: boolean;
  showBorder?: boolean;
  children?: React.ReactNode;
}

const DeviceListItemButton = forwardRef<HTMLDivElement, DeviceListItemButtonProps>(function BlinkingIconButton(props: DeviceListItemButtonProps, ref) {

  // Destructure our custom props from the `rest` which
  // are `IconButtonProps` for our internal IconButton
  const {
    depth,
    isLast,
    expanded,
    children,
    showBorder,
    ...rest
  } = props;

  return (
    <ListItemButton
      alignItems="flex-start"
      ref={ref}
      {...rest}
      sx={{
        paddingTop: (depth > 0 ? 0.5 : 1),
        paddingBottom: (depth > 0 ? 0.5 : 1),
        paddingLeft: (depth * 2) + (depth > 0 ? 2 : 0) + 2,
        borderTop: showBorder ? (expanded ? '1px solid #BDBDBD' : '1px solid transparent') : undefined,
        borderBottom: showBorder ? (expanded ? '1px solid #BDBDBD' : '1px solid transparent') : undefined,
        background: expanded ? 'rgba(25, 118, 210, 0.08)' : 'none',
      }}
    >
      {(depth > 0) && <div style={{
        position: 'absolute',
        width: '12px',
        height: '50%',
        top: 0, left: (depth * 16) + (depth > 0 ? 16 : 0),
        borderLeft: '1px solid #BDBDBD',
        borderBottom: '1px solid #BDBDBD',
      }}></div>}
      {(depth > 0) && !isLast && <div style={{
        position: 'absolute',
        width: '12px',
        height: '50%',
        top: '50%', left:  (depth * 16) + (depth > 0 ? 16 : 0),
        borderLeft: '1px solid #BDBDBD',
      }}></div>}
      {children}
    </ListItemButton>
  );
});

export default DeviceListItemButton;