import BaseDevice from '../BaseDevice';
import { BaseDeviceEvent } from './BaseDeviceEvent';

export class DeviceRemovedEvent extends BaseDeviceEvent<Event> {
  readonly type = 'DeviceRemovedEvent';

  constructor(
    device: BaseDevice,
  ) {
    super(device, new Event('SyntheticDeviceRemovedEvent'));
  }
}