import React, { useEffect, useState } from 'react';
import { PrompterSegment } from '../../../models/EditorTypes';
import { useTranslation, Trans } from 'react-i18next';

import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ShotLogButton from '../ShotLogModal/ShotLogButton';

import classNames from 'classnames';
import { useAppController } from '../../../controllers/AppController';

interface SegmentShotLogActionsProps {
  node: PrompterSegment;
  isPlaying: boolean;
  isPaused: boolean;
  shotlogActive: boolean;
}

const SegmentShotLogActions = (props: SegmentShotLogActionsProps) => {
  const { t } = useTranslation(['prompter', 'common']);
  const appController = useAppController();

  const [autoContinueMilliseconds, setAutoContinueMilliseconds] = useState<number | undefined>();

  useEffect(() => {
    if(props.isPlaying && autoContinueMilliseconds) {
      setAutoContinueMilliseconds(undefined);
    }
  }, [props.isPlaying, autoContinueMilliseconds]);

  return (<>
    <div className={classNames('ShotLogActionsContainer', { isPaused: props.shotlogActive })}>
      <div className={classNames('ShotLogActionsHeader')}>{t('segmentelement.shotlog.caption')}</div>
      <div
        className={classNames('ShotLogActionsInline')}
        contentEditable={false}
      >
        <ShotLogButton
          className="ShotLogButton"
          startIcon={<ThumbDownAltIcon sx={{ color: props.shotlogActive ? 'inherit' : 'error' }} />}
          disabled={props.isPlaying}
          aria-label="Log last take in shot log as bad and rewind for a retake"
          aria-haspopup="menu"
          onFocus={(e) => { e.target.blur(); }}
          onClick={() => {
            // Stop the auto click timer.
            setAutoContinueMilliseconds(undefined);

            appController.dispatchMessage('shotlog.badtake', {
              node: props.node
            });
          }}
        ><Trans>{t('segmentelement.shotlog.badtake')}</Trans></ShotLogButton>

        <ShotLogButton
          className="ShotLogButton FlexReverse"
          // ref={menuAnchorRef}
          endIcon={<ThumbsUpDownIcon sx={{ color: props.shotlogActive ? 'inherit' : 'warning' }} />}
          disabled={props.isPlaying}
          // aria-expanded={menuOpen ? 'true' : undefined}
          aria-label="Additional shot logging options"
          aria-haspopup="menu"
          onFocus={(e) => { e.target.blur(); }}
          onClick={() => {
            /*if(autoContinueMilliseconds) {
              setAutoContinueMilliseconds(undefined);
            } else {
              setAutoContinueMilliseconds(5000);
            }*/
            setAutoContinueMilliseconds(undefined);

            appController.dispatchMessage('shotlog.oktake', {
              node: props.node
            });
          }}
        ><Trans>{t('segmentelement.shotlog.mediocretake')}</Trans></ShotLogButton>

        <ShotLogButton
          className="ShotLogButton FlexReverse"
          endIcon={<ThumbUpAltIcon sx={{ color: props.shotlogActive ? 'inherit' : 'success' }} />}
          disabled={props.isPlaying}
          aria-label="Log last take in shot log as good"
          aria-haspopup="menu"
          // onClick={handleToggle}
          autoClickMilliseconds={autoContinueMilliseconds}
          onFocus={(e) => { e.target.blur(); }}
          onClick={() => {
            setAutoContinueMilliseconds(undefined);

            appController.dispatchMessage('shotlog.goodtake', {
              node: props.node
            });
          }}
        ><Trans>{t('segmentelement.shotlog.goodtake')}</Trans></ShotLogButton>
      </div>
    </div>
  </>);
};

export default SegmentShotLogActions;