import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Chip from '@mui/material/Chip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';


import Box from '@mui/material/Box';
import { useTheme, styled } from '@mui/material/styles';

import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

import ComparePlanSectionHeaderRow from './ComparePlanSectionHeaderRow';

import useInputMiddleware, { InputMiddlewareProps } from '../../hooks/useInputMiddleware';

import useApplicationState from '../../state/ApplicationState';
import useFeatureFlagsStore from '../../state/FeatureFlagsStore';
import { shallow } from 'zustand/shallow';

import useApplicationContext from '../../hooks/useApplicationContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import './ComparePlansDialog.scss';

const HeaderButton = styled(Button)({
  minWidth: 'fit-content',
  whiteSpace: 'nowrap',
});

interface RowData {
  name: string,
  info: string,
  freeIncludes: boolean | React.ReactNode,
  proIncludes: boolean | React.ReactNode,
  studioIncludes: boolean | React.ReactNode,
}
function createData(
  name: string,
  info: string,
  freeIncludes: boolean | React.ReactNode,
  proIncludes: boolean | React.ReactNode,
  studioIncludes: boolean | React.ReactNode,
): RowData {
  return {
    name,
    info,
    freeIncludes,
    proIncludes,
    studioIncludes,
  };
}

function Row(props: { row: ReturnType<typeof createData>, currentPlan?: string }) {
  const { row, currentPlan } = props;
  const [open, setOpen] = React.useState(false);

  //
  const freeIncludesType = typeof row.freeIncludes;
  const freeIncludesNodes = (<>
    {freeIncludesType === 'boolean' && (row.freeIncludes ? <CheckIcon fontSize="medium" color="success" sx={{ ml: 1, mr: 1, verticalAlign: 'middle' }} /> : <ClearIcon fontSize="medium" color="error" sx={{ ml: 1, mr: 1, verticalAlign: 'middle' }} />)}
    {freeIncludesType === 'string' && row.freeIncludes}
  </>);

  const proIncludesType = typeof row.proIncludes;
  const proIncludesNodes = (<>
    {proIncludesType === 'boolean' && (row.proIncludes ? <CheckIcon fontSize="medium" color="success" sx={{ ml: 1, mr: 1, verticalAlign: 'middle' }} /> : <ClearIcon fontSize="medium" color="error" sx={{ ml: 1, mr: 1, verticalAlign: 'middle' }} />)}
    {proIncludesType === 'string' && row.proIncludes}
  </>);

  const studioIncludesType = typeof row.studioIncludes;
  const studioIncludesNodes = (<>
    {studioIncludesType === 'boolean' && (row.studioIncludes ? <CheckIcon fontSize="medium" color="success" sx={{ ml: 1, mr: 1, verticalAlign: 'middle' }} /> : <ClearIcon fontSize="medium" color="error" sx={{ ml: 1, mr: 1, verticalAlign: 'middle' }} />)}
    {(studioIncludesType === 'string' || studioIncludesType === 'object') && row.studioIncludes}
  </>);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row" sx={{ borderBottom: '0 none', userSelect: 'none', cursor: 'pointer' }}>
          {open ? <KeyboardArrowUpIcon sx={{ mr: 1, verticalAlign: 'middle' }} /> : <KeyboardArrowDownIcon sx={{ mr: 1, verticalAlign: 'middle' }} />}<b>{row.name}</b>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginTop: 1, marginLeft: 4 }}>
              <Typography gutterBottom component="div">
                {row.info}                
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell align="center" sx={{ borderBottom: '0 none', verticalAlign: 'top' }}>{currentPlan === 'free' ? freeIncludesNodes : proIncludesNodes}</TableCell>
        <TableCell align="center" sx={{ borderBottom: '0 none', verticalAlign: 'top', background: '#f6f8fa' }}>{currentPlan === 'free' ? proIncludesNodes : studioIncludesNodes}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const rowsTeleprompterEssentials = [
  createData('Smooth scrolling text up the screen!', 'Sure, all teleprompter apps scroll text on the screen. But FluidPrompter is extra smooth on 60 fps displays and still maintains consistent scrolling on 30 fps displays and even variable framerate displays!', true, true, true),
  createData('Continuously adjustable speed', 'FluidPrompter can continuously adjust the scrolling speed, even while actively prompting! Many competitor teleprompters can only scroll at one fixed speed.', true, true, true),
  createData('Adjustable font size', 'You can adjust the size of the text on screen to fit your specific prompter screen size and distance from the talent.', true, true, true),
  createData('Adjustable margins/gutters', 'You can adjust the margins or gutters on screen to reduce left-right eye movement if you are particularly close to the teleprompter and camera.', true, true, true),
  createData('Multiple Prompter Screens (coming soon!)', 'The FluidPrompter Free plan allows you to have a single concurrent prompter. Soon paid plans will be able to syncornize multiple prompters over the network.', false, '2 Screens', <>2 Screens per user<br/>+ 2 bonus screens!</>),
  // createData('Title', 'Info', false, true),
  // 1 Cloud Remote
  // Basic API automation
  // Community support
  // Analog foot-pedal control 
  // 2 Concurrent Prompters
  // Syncronized Prompters for multiple camera angles or presenters
  // Full API automation
];

const rowsDifferentiators = [
  createData('Screens', 'FluidPrompter will soon be able to syncronize your prompter session across multiple screens/devices.', false, '2 Screens', <>2 Screens per user<br/>+ 2 bonus screens!</>),
  // createData('Multiple Concurrent Prompters', 'The FluidPrompter Free plan allows you to have a single concurrent prompter.', false, true),
];

const rowsRemoteControlOptions = [
  createData('Keyboard', 'FluidPrompter has extensive support for keyboard shortcuts.', true, true, true),
  createData('Mouse', 'FluidPrompter allows the use of a wireless mouse as an impromptu wireless teleprompter remote.', true, true, true),
  createData('AirTurn Bluetooth Remotes', 'AirTurn Bluetooth Remotes can be paired with FluidPrompter convenient wireless control.', true, true, true),
  createData('Ikan Wireless Remote', 'The Ikan Wireless Teleprompter Remote is well suited for iPad use. FluidPrompter has unparalleled support for the Ikan remote.', true, true, true),
  createData('Cloud Remote', 'You can control the teleprompter via the cloud (over the internet). You can open the remote on your mobile device by scanning a QR code on the prompter.', true, true, true),
  // createData('Multiple Concurrent Prompters', 'The FluidPrompter Free plan allows you to have a single concurrent prompter.', false, true, true),
];

function ComparePlansDialog() {

  const appState = useApplicationState(state => ({
    isAccountSettingsOpen: state.isAccountSettingsOpen,
    setAccountSettingsOpen: state.setAccountSettingsOpen,
    isComparePlansOpen: state.isComparePlansOpen,
    setComparePlansOpen: state.setComparePlansOpen,
  }), shallow);

  const theme = useTheme();
  const showDialogFullscreen = useMediaQuery(theme.breakpoints.down('lg'));

  const {
    appContext,
    getApiToken,
    subscription,
  } = useApplicationContext(false);
  const fluidprompter_plan = appContext.userProfile?.fluidprompter_plan;

  const handleNewCustomerCheckout = async () => {
    try {
      const token = await getApiToken();

      const returnUrl = new URL(window.location.href);

      // Make sure we don't lose any temporarily enabled feature flags during the login redirects.
      useFeatureFlagsStore.getState().encodeFeatureFlagParams(returnUrl.searchParams);

      /*
      const response = await fetch(`${window.location.protocol}//${apiHostname}/api/stripe/getbillingportal?` + new URLSearchParams({
        return_url: returnUrl.toString(),
      }), {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });
      */
      const response = await fetch(`${process.env.REACT_APP_API_URL}/stripe/getcheckouturl?` + new URLSearchParams({
        return_url: returnUrl.toString(),
      }), {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });

      if(response.status !== 200) {
        // We received an error response.
        alert('Sorry, we encountered an unexpected error. Please contact fluidprompter support.');
        return;
      }

      console.log('Got app API account response from API serverless function', response);

      const billingPortalSession = await response.json();
      console.log(billingPortalSession);
      
      const billingUrl = billingPortalSession.url as string;
      if(billingUrl) {
        // Unnecessary? appState.setComparePlansOpen(false);
        window.location.href = billingUrl;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleClose = () => {
    appState.setComparePlansOpen(false);
  };

  //
  // Take over processing of human inputs while this modal dialog is open.
  //
  const inputMiddlewareProps = React.useMemo<InputMiddlewareProps>(() => {
    const results: InputMiddlewareProps = {
      exclusive: appState.isComparePlansOpen,  // Prevent all user input hooks above this one from receiving input while this model is open.
    };

    return results;
  }, [appState.isComparePlansOpen]);
  useInputMiddleware(inputMiddlewareProps);

  return (
    <Dialog
      maxWidth='lg'
      fullWidth={true}
      fullScreen={showDialogFullscreen}
      /*scroll='paper'*/
      PaperProps={{
        sx: {
          minHeight: 'calc(100% - 64px)'
        }
      }}
      onClose={handleClose} 
      open={appState.isAccountSettingsOpen}
    >
      {/*<DialogTitleWithClose onClose={handleClose}>Compare FluidPrompter Plans</DialogTitleWithClose>*/}
      <DialogContent dividers={true} sx={{ padding: 0, display: 'flex' }}>
        <TableContainer sx={{ flex: 1 }}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell width='40%' sx={{ verticalAlign: 'top', borderBottom: '0 none' }}>
                  <h1 style={{
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    paddingBottom: '16px'
                  }}>Compare FluidPrompter Plans</h1>
                </TableCell>
                {fluidprompter_plan === 'free' &&
                <TableCell width='20%' align="center" sx={{ verticalAlign: 'top', borderBottom: '0 none' }}>
                  <Typography variant="h2" sx={{ mt: 1, mb: 1, fontSize: '1.5em', fontWeight: 400 }}>Free</Typography>
                  <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>All the basics</Typography>
                  <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><b>$0</b> per user/month</Typography>
                  <Chip label="Current Plan" variant="outlined" color="secondary" />
                </TableCell>}

                <TableCell width='20%' align="center" sx={{ verticalAlign: 'top', borderBottom: '0 none', background: (fluidprompter_plan === 'free') ? '#f6f8fa' : '' }}>
                  <Typography variant="h2" sx={{ mt: 1, mb: 1, fontSize: '1.5em', fontWeight: 400 }}>Pro</Typography>
                  <Typography variant="caption" color="rgba(0, 0, 0, 0.6)" sx={{ mt: 1, mb: 1 }}>Pro plan for video creators mostly working alone.</Typography>
                  <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                    {/* <div style={{
                      color: '#ff0000'
                    }}><del>$19/mo regular rate</del></div> */}
                    <b>$9/mo</b>
                    <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">&nbsp;single user</Typography>
                  </Typography>
                  {fluidprompter_plan === 'free' &&
                  <HeaderButton
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={async (e) => {
                      e.preventDefault();
                      if(fluidprompter_plan) {
                        subscription.startChangePlans();
                      } else {
                        await handleNewCustomerCheckout();
                      }
                    }}
                  >Upgrade to Pro</HeaderButton>}
                  {fluidprompter_plan === 'pro' && <Chip label="Current Plan" variant="outlined" color="secondary" />}
                </TableCell>

                {fluidprompter_plan !== 'free' &&
                <TableCell width='20%' align="center" sx={{ verticalAlign: 'top', borderBottom: '0 none', background: '#f6f8fa' }}>
                  <Typography variant="h2" sx={{ mt: 1, mb: 1, fontSize: '1.5em', fontWeight: 400 }}>Studio</Typography>
                  <Typography variant="caption" color="rgba(0, 0, 0, 0.6)" sx={{ mt: 1, mb: 1 }}>Studio plan for businesses or production teams.</Typography>
                  <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                    {/* <div style={{
                      color: '#ff0000'
                    }}><del>$49/mo regular rate</del></div> */}
                    <b>$29/mo</b>
                    <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">&nbsp;per user</Typography>
                  </Typography>
                  {fluidprompter_plan !== 'studio' &&<HeaderButton
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={async (e) => {
                      e.preventDefault();
                      if(fluidprompter_plan) {
                        subscription.startChangePlans();
                      } else {
                        await handleNewCustomerCheckout();
                      }
                    }}
                  >Upgrade to Studio</HeaderButton>}
                  {fluidprompter_plan === 'studio' && <Chip label="Current Plan" variant="outlined" color="secondary" />}
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <ComparePlanSectionHeaderRow icon={<OndemandVideoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />} title="Teleprompter Essentials" />
              {rowsTeleprompterEssentials.map((row) => (
                <Row key={row.name} row={row} currentPlan={fluidprompter_plan} />
              ))}
              {/* <ComparePlanSectionHeaderRow icon={<SettingsRemoteIcon sx={{ mr: 1, verticalAlign: 'middle' }} />} title="Multi-Prompter" mt={3} />
              {rowsDifferentiators.map((row) => (
                <Row key={row.name} row={row} currentPlan={fluidprompter_plan} />
              ))} */}
              <ComparePlanSectionHeaderRow icon={<SettingsRemoteIcon sx={{ mr: 1, verticalAlign: 'middle' }} />} title="Remote Control Features" mt={3} />
              {rowsRemoteControlOptions.map((row) => (
                <Row key={row.name} row={row} currentPlan={fluidprompter_plan} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>          
        {/*<Grid container spacing={{ sm: 0, md: 2 }}>
          <Grid item sm={12} md={4}>
            Info
          </Grid>
          <Grid item sm={12} md={3}>
            FluidPrompter Free
          </Grid>
          <Grid item sm={12} md={3}>
            FluidPrompter Pro
          </Grid>
        </Grid>*/}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={async (e) => {
          e.preventDefault();
          if(fluidprompter_plan) {
            subscription.startChangePlans();
          } else {
            await handleNewCustomerCheckout();
          }
        }}>
          {(fluidprompter_plan === 'free') && 'Upgrade to Pro'}
          {(fluidprompter_plan === 'pro') && 'Upgrade to Studio'}
          {(fluidprompter_plan === 'studio') && 'Change Plan'}
        </Button>
        <Button onClick={handleClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ComparePlansDialog;