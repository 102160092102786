import * as React from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import WidgetContainer from './WidgetContainer';
import PrompterHudStats from '../PrompterHudStats';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import './PrompterHudRight.scss';

const PrompterHudRight = React.memo(function PrompterHudRight() {

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  /*
  const configStore = useConfigurationStore(state => ({
    textSize: state.textSize,
    contentWidth: state.contentWidth,
    flipHorizontal: state.flipHorizontal,
    flipVertical: state.flipVertical,
    showStats: state.showStats,
    focusGuide: state.focusGuide,
  }), shallow);
  */

  const prompterState = usePrompterSession(state => ({
    isEditing: state.isEditing,
    cursorHidden: state.cursorHidden,
  }), shallow);

  return (
    <Stack
      className={classNames('PrompterHudRight', { hideMode: (!prompterState.isEditing && prompterState.cursorHidden) })}
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      sx={{
        /*transform: (!prompterSession.isEditing && prompterSession.cursorHidden) ? 'translateY(-100%)': 'none',*/
        transition: 'transform 500ms ease-in-out',   /* This needs to be in sx styles */
      }}
    >
      {isLargeScreen && <PrompterHudStats />}
      <Box sx={{ flexGrow: 1 }} />
      <WidgetContainer />
    </Stack>
  );
});

export default PrompterHudRight;