import { ConsolePlainStream, ConsoleRawStream, createLogger, FATAL, ERROR, WARN, INFO, DEBUG, TRACE, Logger, LogStream } from 'browser-bunyan';
import { ServerStream } from '@browser-bunyan/server-stream';

export type LogLevelNames = 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace';

export function mapLevelNameToNumber(levelName: LogLevelNames) {
  switch(levelName) {
    case 'fatal':
      return FATAL;
    case 'error':
      return ERROR;
    case 'warn':
      return WARN;
    case 'info':
      return INFO;
    case 'debug':
      return DEBUG;
    case 'trace':
      return TRACE;
  }
}

export function mapLevelNumberToName(levelName: number): LogLevelNames {
  switch(levelName) {
    case FATAL:
      return 'fatal';
    default:
    case ERROR:
      return 'error';
    case WARN:
      return 'warn';
    case INFO:
      return 'info';
    case DEBUG:
      return 'debug';
    case TRACE:
      return 'trace';
  }
}

/**
 * Build our websocket connection URL given a prompterId, taking
 * into account the current browser window's location.
 * @param prompterId
 * @returns
 */
const buildLogUrl = () => {
  const currentUrl = window.location.protocol + '//' + window.location.host + '/';
  const logUrl = new URL(process.env.REACT_APP_API_URL || currentUrl);
  logUrl.protocol = 'https:';
  logUrl.port = '443';

  if(
    'localhost' === window.location.hostname
    || '127.0.0.1' === window.location.hostname
    || window.location.hostname.startsWith('192.168')
  ) {
    logUrl.hostname = window.location.hostname;
    logUrl.protocol = 'http:';
    logUrl.port = '8787';
  }
  logUrl.pathname = '/api/log';

  return logUrl.href;
};

interface LogRecord {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [member: string]: any;
}

/**
 * This is a Bunyan style stream processor that will decorate the log record before sending it on
 * to the inner stream.
 */
export class LogDecorator implements LogStream {
  private _innerStream: LogStream;
  private _data: LogRecord = {};

  constructor(innerStream: LogStream) {
    this._innerStream = innerStream;
  }

  write(record: object) {
    const logRecord = record as LogRecord;
    for (const [key, value] of Object.entries(this._data)) {
      logRecord[key] = value;
    }

    this._innerStream.write(logRecord);
  }

  set(key: string, value: unknown) {
    this._data[key] = value;
  }

  delete(key: string) {
    delete this._data[key];
  }
}

export const logDecorator = new LogDecorator(new ServerStream({
  url: buildLogUrl(),
  method: 'PUT',
}));

const logger = createLogger({
  name: 'fp-app-web',
  level: ERROR,
  streams: [{
    stream: new ConsolePlainStream(),
  // }, {
  //  stream: new ConsoleRawStream(),
  }, {
    stream: logDecorator,
  }],
});

export default logger;
