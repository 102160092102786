import React, { useCallback, useEffect, useState } from 'react';
import { zaraz } from 'zaraz-ts';

import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import * as z from 'zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import useApplicationContext from '../../hooks/useApplicationContext';
import { IUserProfile } from '@fluidprompter/core';

import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledFormLabel = styled(FormLabel)({
  color: '#000',
  fontSize: '1.25em',
});

const GatherUserReferralSource = () => {

  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up('md'));

  const {
    appContext,
    patchUserProfile,
  } = useApplicationContext();

  useEffect(() => {
    if(!appContext.userProfile) {
      return;
    }

    const { fluidprompter_plan, fluidprompter_referralsource, created_at } = appContext.userProfile;
    if(!fluidprompter_plan || !created_at) {
      return;
    }

    if(fluidprompter_referralsource) {
      // If we have a referral source set on the user profile, they completed this onboarding step
      // already and we already sent the analytics `onboarding_checkout_complete` event.
      return;
    }

    const createdAt = new Date(created_at);
    const accountAgeSeconds = Math.ceil(
      (Date.now() - createdAt.getTime()) / 1000
    );
    if (accountAgeSeconds < (5 * 60)) {
      // Track successful checkout.
      zaraz.track('onboarding_checkout_complete', {
        fluidprompter_plan,
      });
    }
  }, [appContext.userProfile]);

  const registerSchema = z.object({
    fluidprompter_referralsource: z.string({
      invalid_type_error: 'Please tell us where you found us!',
    }).refine((val) => ['google', 'youtube', 'facebook', 'friend-recommendation', 'other'].includes(val)),
    fluidprompter_referralsourcecustom: z.string(),
  }).refine((val) => (val.fluidprompter_referralsource !== 'other') || (val.fluidprompter_referralsourcecustom.length > 3), {
    path: ['fluidprompter_referralsource'],
    message: 'Please tell us where you found us!',
  });

  type RegisterInput = z.TypeOf<typeof registerSchema>;

  const {
    register,
    watch,
    setValue,
    formState: { errors },
    setFocus,
    handleSubmit
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });

  const useCase = watch('fluidprompter_referralsource') || null;
  const handleChangeUseCase = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('fluidprompter_referralsource', (event.target as HTMLInputElement).value);
  };

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const onSubmitValidHandler: SubmitHandler<RegisterInput> = useCallback(async (values) => {
    if(!appContext.userProfile) {
      return;
    }

    setIsSaving(true);
    const userProfile: IUserProfile = { ...appContext.userProfile };

    userProfile.fluidprompter_referralsource = values.fluidprompter_referralsource;
    userProfile.fluidprompter_referralsourcecustom = values.fluidprompter_referralsourcecustom;

    await patchUserProfile(userProfile);

    await zaraz.track('onboarding_referralsource', {
      fluidprompter_referralsource: values.fluidprompter_referralsource,
    });
  }, [appContext.userProfile, patchUserProfile]);

  //
  // Set focus to the first field with an error.
  //
  React.useEffect(() => {
    // https://stackoverflow.com/questions/69719627/how-to-focus-when-an-error-occurs-in-react-hook-form-controller

    // Find the first field with an error.
    const firstError = (
      Object.keys(errors) as Array<keyof typeof errors>
    ).reduce<keyof typeof errors | null>((field, a) => {
      const fieldKey = field as keyof typeof errors;
      return errors[fieldKey] ? fieldKey : a;
    }, null);

    // Handle special case when usecase==='other'
    if(firstError === 'fluidprompter_referralsource' && useCase === 'other') {
      setFocus('fluidprompter_referralsourcecustom');
      return;
    }

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, useCase, setFocus]);

  return (
    <>
      <DialogContent dividers={true}>
        <Box
          maxWidth={'md'}
          sx={{
            m: '0 auto',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          <h3 style={{
            textAlign: 'center',
            fontSize: viewportIsLarge ? '1.2rem' : '1rem',
            fontWeight: viewportIsLarge ? 'bold' : '400'
          }}>Let&apos;s finish your FluidPrompter account setup.</h3>
          <Stepper activeStep={2} alternativeLabel>
            <Step>
              <StepLabel>Use Case</StepLabel>
            </Step>
            <Step>
              <StepLabel>Choose Plan</StepLabel>
            </Step>
            <Step>
              <StepLabel>Finish</StepLabel>
            </Step>
          </Stepper>
        </Box>

        <Container
          component={'form'}
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit(onSubmitValidHandler)}
          maxWidth="sm"
          sx={{
            m: '0 auto'
          }}
        >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isSaving}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Stack spacing={3} alignItems={'center'}>

            <FormControl fullWidth error={!!errors['fluidprompter_referralsource']}>
              <StyledFormLabel id="usecase-group">How did you find FluidPrompter?</StyledFormLabel>
              <RadioGroup
                aria-labelledby="usecase-group"
                value={useCase}
                onChange={handleChangeUseCase}
              >
                <FormControlLabel value="google" {...register('fluidprompter_referralsource')} control={<Radio />} label="Google" />
                <FormControlLabel value="youtube" {...register('fluidprompter_referralsource')} control={<Radio />} label="YouTube" />
                <FormControlLabel value="facebook" {...register('fluidprompter_referralsource')} control={<Radio />} label="Facebook" />
                <FormControlLabel value="friend-recommendation" {...register('fluidprompter_referralsource')} control={<Radio />} label="Friends/Recommendation" />
                <FormControlLabel
                  value="other"
                  {...register('fluidprompter_referralsource')}
                  disableTypography
                  control={<Radio />}
                  label={(<FormControlLabel
                    labelPlacement="start"
                    control={
                      <TextField
                        fullWidth
                        {...register('fluidprompter_referralsourcecustom')}
                        error={(useCase === 'other') && !!errors['fluidprompter_referralsource']}
                        onFocus={() => { setValue('fluidprompter_referralsource', 'other'); }}
                        size="small"
                        sx={{ ml: 2 }}
                      />
                    }
                    label={'Other: '}
                    sx={{ ml: 0, width: '100%' }} />)
                  }
                  sx={{
                    width: '100%',
                  }}
                />
              </RadioGroup>
              <FormHelperText error={!!errors['fluidprompter_referralsource']}>
                {errors['fluidprompter_referralsource'] ? errors['fluidprompter_referralsource'].message : ''}
              </FormHelperText>
            </FormControl>
            <Container
              maxWidth="xs"
            >
              <Button
                fullWidth
                type='submit'
                variant='contained'
              >Save</Button>
            </Container>
          </Stack>
        </Container>
      </DialogContent>
    </>
  );
};

export default GatherUserReferralSource;