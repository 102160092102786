import React from 'react';
import Collapse from '@mui/material/Collapse';
// import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';

import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
// import KeyboardIcon from '@mui/icons-material/Keyboard';
// import MouseIcon from '@mui/icons-material/Mouse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { useTranslation } from 'react-i18next';

import useConfigurationStore from '../../../state/ConfigurationStore';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ControlMethods from './ControlMethods';
import MomentarySettings from './MomentarySettings';

// Left align the label relative to the enclosed form input element.
// https://stackoverflow.com/a/56828472
const StyledFormControlLabel = styled(FormControlLabel)({
  width: '100%',
  alignItems: 'center', // 'start',
  justifyContent: 'space-between',
  marginLeft: 0,
});

const StyledFormHelperText = styled(FormHelperText)({
  marginTop: 0,
  marginBottom: 12,
});

interface ControlsMenuProps {
  expanded: boolean;
  onExpand: () => void;
}
const ControlsMenu = (props: ControlsMenuProps) => {
  const { t } = useTranslation('appmenu');

  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const requestMenuIndex = (
    index: number,
  ) => {
    if(selectedIndex === index) {
      // We are clicking an item that is already selected.
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };

  return (
    <>
      <ListItemButton
        selected={props.expanded}
        onClick={() => {
          props.onExpand();
        }}
      >
        <ListItemIcon>
          <SportsEsportsIcon />
        </ListItemIcon>
        <ListItemText primary={t('controlsmenu.caption')} />
        {props.expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{
          pt: 0,
          pr: 0,
          pb: 2,
          pl: 4,
          background: 'rgba(25, 118, 210, 0.08)',
        }}
        in={!!props.expanded} timeout="auto"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <ControlMethods expanded={selectedIndex === 0} onExpand={() => { requestMenuIndex(0); }} />
        <MomentarySettings expanded={selectedIndex === 1} onExpand={() => { requestMenuIndex(1); }} />
      </Collapse>
    </>
  );
};

export default ControlsMenu;