import React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import AccountPanelOutlined from '../../AccountPanelOutlined/AccountPanelOutlined';

import useApplicationState from '../../../../state/ApplicationState';
import { shallow } from 'zustand/shallow';

const StudioPlanPanel = () => {
  const appState = useApplicationState(state => ({
    setComparePlansOpen: state.setComparePlansOpen,  
  }), shallow);

  const handleComparePlans = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    appState.setComparePlansOpen(true);
  };

  return (<AccountPanelOutlined
    title={<List disablePadding>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="FluidPrompter Studio" src={process.env.PUBLIC_URL + '/android-chrome-192x192.png'} />
        </ListItemAvatar>
        <ListItemText
          primary={'FluidPrompter Studio'}
          secondary={'Studio version with extra features for businesses or production teams.'}
        />
      </ListItem>
    </List>}
    className="PlanFeatures"
  >
    <Grid container spacing={{ sm: 0, md: 3 }}>
      <Grid item sm={12} md={6}>
        <ul className="ListIncluded">
          <li className="awesome"><b><em>Everything in the Free Plan and Pro Plan, plus:</em></b></li>
          <li>Priority Email Support</li>
        </ul>
      </Grid>
      <Grid item sm={12} md={6}>
        Not Included:
        <ul className="ListNotIncluded">
          <li>Nothing! You&apos;ve got every feature we offer. 😎</li>
        </ul>
        <a href="#plans" onClick={handleComparePlans}>See all features and compare plans</a>
      </Grid>
    </Grid>
  </AccountPanelOutlined>);
};

export default StudioPlanPanel;