import * as React from 'react';
import useApplicationContext from '../hooks/useApplicationContext';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function FeedbackSSOPage() {

  const {
    appContext,
    loginWithRedirect,
    getApiToken,
  } = useApplicationContext(false);

  React.useEffect(() => {
    //
    // Wait for our appContext to finish loading (checking if we are logged in, etc)
    //
    if(appContext.isLoading) {
      return;
    }

    //
    // If we are not yet logged in, then redirect user to login now.
    //
    if(!appContext.isAuthenticated) {
      const finalUrl = window.location.href;
      const returnUrl = new URL(window.location.href);
      returnUrl.pathname = '/auth/';
      returnUrl.search = '';
      returnUrl.hash = '';

      // User is not currently authenticated/
      loginWithRedirect({
        appState: {
          returnTo: finalUrl,
        },
        authorizationParams: {
          screen_hint: 'login',

          /**
           * The URL where Auth0 will redirect your browser to with
           * the authentication result. It must be whitelisted in
           * the "Allowed Callback URLs" field in your Auth0 Application's
           * settings.
           */
          redirect_uri: returnUrl.toString(),
        },
      });
    }

    //
    // If we are logged in, then redirect the user to our feedback portal.
    //
    if(appContext.isAuthenticated) {
      (async () => {
        try {
          const token = await getApiToken();

          const response = await fetch(`${process.env.REACT_APP_API_URL}/feedback/getportalurl`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${token}`,
            },
          });
          if(response.status !== 200) {
            // We received an error response.
            alert('Sorry, we encountered an unexpected error. Please contact fluidprompter support.');
            return;
          }

          const feedbackPortalInfo = await response.json();

          //
          // If we got a JWT, let Nolt know.
          //
          const feedbackPortalJwt = feedbackPortalInfo.jwt as string;
          if(feedbackPortalJwt) {
            // https://YOUR_BOARD.nolt.io/sso/JWT_FOR_THIS_USER?returnUrl=RETURN_URL
            const returnUrl = new URL(window.location.href);
            returnUrl.pathname = '';  // Remove any path so that Nolt redirect back to the app itself.

            const feedbackUrl = new URL('https://feedback.fluidprompter.com/');
            feedbackUrl.pathname = `/sso/${feedbackPortalJwt}`;  // Remove any path so that Nolt redirect back to the app itself.
            feedbackUrl.searchParams.set('returnUrl', returnUrl.toString());

            window.location.href = feedbackUrl.toString();
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [appContext, loginWithRedirect, getApiToken]);

  return (
    <Backdrop open={true}>
      <CircularProgress color="inherit" />
      <span style={{
        marginLeft: '16px'
      }}>Loading...</span>
    </Backdrop>
  );
}

export default FeedbackSSOPage;