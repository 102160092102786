import { useCallback } from 'react';
import { useMessageHandler, MessageHandlerEvent } from '../../controllers/AppController';
import { useConfirm } from 'material-ui-confirm';
import usePrompterSession from '../../state/PrompterSessionState';

import { IViewportRefs } from './usePrompterViewportRefs';
import { IViewportFuncs } from './usePrompterViewportFuncs';
import { EndpointRole, ResetMessage } from '@fluidprompter/core';

function usePrompterResetHandler(viewportrefs: IViewportRefs, viewportFuncs: IViewportFuncs) {

  const confirm = useConfirm();

  const performReset = useCallback(async function (e: MessageHandlerEvent<ResetMessage>) {
    // console.log('BEGIN prompter.reset');

    // Cancel any animation frame that is in progress
    // ** Probably not needed as when we change prompterSession.isPlaying the PrompterViewport has a hook that will cancelAnimationFrame.
    cancelAnimationFrame(viewportrefs.requestRef.current);

    //
    // Update our application state and wait for the state to have been processed
    //
    usePrompterSession.getState().reset();

    //
    // Clear out our previously elapsed time.
    //
    const currentLedger = viewportrefs.previousLedgerRef.current;
    if(currentLedger) {
      currentLedger.elapsed = 0;
    }

    //
    // Scroll the prompter back to the start!
    //
    // console.log('prompter.reset - request scroll to 0');
    const scrollPrompise = viewportFuncs.scrollToPosition({
      scrollPosition: 0,
      scrollBehavior: 'auto'
    });

    // console.log('END prompter.reset');
    return scrollPrompise;
  }, [viewportrefs.previousLedgerRef, viewportrefs.requestRef, viewportFuncs]);

  useMessageHandler('prompter.state.reset', async (e: MessageHandlerEvent<ResetMessage>) => {
    const { message, originatedRemotely } = e;
    const { skipConfirm, sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);

    e.sendToPeers = !originatedRemotely || (senderIsRemote && isLeader);

    if(skipConfirm) {
      viewportFuncs.queueSequentialTask(async () => {
        return performReset(e);
      });
      return;
    }

    if(e.originatedRemotely) {
      // We won't show the user a confirmation dialog if the reset request didn't originate from this instance.
      return;
    }

    await confirm({
      title: 'Are you sure you want to reset the prompter?',
      description: 'This will rewind to the start of your script, clear any shot log entries and reset the elapsed time.',
    });

    e.dispatchMessage(new ResetMessage(true));
  });
}

export default usePrompterResetHandler;