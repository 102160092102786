import { RedirectLoginOptions, LogoutOptions, PopupLoginOptions, PopupConfigOptions } from '@auth0/auth0-react';
import { IUserProfile } from '@fluidprompter/core';

import { ISubscriptionUtils } from './useSubscriptionUtils';

//
// Hook Defintion
//
export interface IAccountSetupRequired {
  missingUserUseCase?: boolean;  // True if this user does not have a recorded use case for what they want to do with FluidPrompter.
  missingStripeCustomer?: boolean;      // True if this user does not have a linked Stripe customer.
  missingUserReferralSource?: boolean;  // True if this user does not have a recorded source for how they found FluidPrompter (Google, YouTube, Twitter, Friend/Colleague, Other).
}
export interface IApplicationContextState {
  isLoading?: boolean;
  // isOffline?: boolean;
  networkOnline: boolean;
  internetOnline: boolean;
  isAuthenticated?: boolean;
  userProfile?: IUserProfile;
  accountSetupRequired?: IAccountSetupRequired;
}
export interface IappContextHookExports {
  appContext: IApplicationContextState, 
  getApiToken: () => Promise<string>,
  fetchUserProfile: () => Promise<IUserProfile | undefined>,
  patchUserProfile: (userProfile: IUserProfile) => Promise<IUserProfile>;
  loginWithRedirect: (options?: RedirectLoginOptions | undefined) => Promise<void>;
  loginWithPopup: (options?: PopupLoginOptions, config?: PopupConfigOptions) => Promise<void>;
  logout: (options?: LogoutOptions | undefined) => void;
  subscription: ISubscriptionUtils;
}
export type GlobalUserStateHook = (isRootHook?: boolean) => IappContextHookExports;

//
// Reducer State Definitions
//
export enum ReducerActionTypes {
  setNetworkOnline = 'setNetworkOnline',
  setInternetOnline = 'setInternetOnline',
  // setIsOffline = 'setIsOffline',
  setIsLoading = 'setIsLoading',
  setIsAuthenticated = 'setIsAuthenticated',
  setUserProfile = 'setUserProfile',
  setAccountSetupRequired = 'setAccountSetupRequired',
  replaceState = 'replaceState',  // When state is syncronized between hook intances.
}
export interface IReducerAction {
  type: ReducerActionTypes;
  payload?: unknown;
}

//
// Types for syncronizing state
//
export type ListenerType = (state: IApplicationContextState) => void;
// export type GlobalUserStateSetter = (nextGlobalState: IApplicationContextState) => void;
