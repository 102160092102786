import React, { useState } from 'react';

import { DeviceComponent, DeviceComponentProps } from '../../BaseDevice';
// import IkanEliteRemoteMenu from './IkanEliteRemoteMenu';
import MomanFS1PedalMenu from '../../common/UI/DeviceMenu';
// import GenericGamepadDialog from './IkanEliteRemoteDialog';

const MomanFS1PedalUI = (props: DeviceComponentProps) => {
  const { onClose } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (<>
    <MomanFS1PedalMenu {...props} setDialogOpen={setDialogOpen} />
    {/* {dialogOpen && <GenericGamepadDialog onClose={() => {
      // Close dialog - should we close menu too?
      setDialogOpen(false);
      onClose();
    }} />} */}
  </>);
};

export default MomanFS1PedalUI;