var ConnectionState;
(function (ConnectionState) {
    ConnectionState["Unknown"] = "unknown";
    ConnectionState["Disconnected"] = "disconnected";
    ConnectionState["Connecting"] = "connecting";
    ConnectionState["Connected"] = "connected";
    ConnectionState["Disconnecting"] = "disconnecting";
    ConnectionState["Reconnecting"] = "reconnecting";
})(ConnectionState || (ConnectionState = {}));
export default ConnectionState;
