import BaseControlMessage from './BaseControlMessage';
export const CONNECTRESPONSEMESSAGE_KEY = 'connect.response';
/**
 * Represents the ConnectResponse type.
 */
export class ConnectResponse extends BaseControlMessage {
    constructor(rtcConfiguration, endpoints) {
        super();
        this.type = CONNECTRESPONSEMESSAGE_KEY;
        /**
         * Contains the list of existing prompter peers connected to the current prompterId.
         */
        this.endpoints = [];
        this.rtcConfiguration = rtcConfiguration;
        this.endpoints = endpoints;
    }
    static fromJson(data) {
        if (data.type !== CONNECTRESPONSEMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for ConnectResponse.');
        }
        const input = data;
        const instance = new ConnectResponse(input.rtcConfiguration, input.endpoints);
        instance.deserializeSenderInfo(input.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            rtcConfiguration: this.rtcConfiguration,
            endpoints: this.endpoints,
        };
    }
}
ConnectResponse.MESSAGE_NAME = CONNECTRESPONSEMESSAGE_KEY;
