/*
 * This was done to support unit testing in an environment where window.crypto.subtle will not exist.
 * See also: https://stackoverflow.com/questions/60370138/mock-secure-origin-for-auth0-spa-js-in-jest-tests
 */
import * as React from 'react';
import { AppState, Auth0Provider, Auth0ProviderOptions, User } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { zaraz } from 'zaraz-ts';

interface ConditionalAuth0ProviderProps {
  children?: React.ReactNode;
}
const ConditionalAuth0Provider = (props: ConditionalAuth0ProviderProps) => {
  if(!process.env.REACT_APP_AUTH0_DOMAIN) {
    /**
     * You must add the key=value for REACT_APP_AUTH0_DOMAIN to an application .env file.
     */
    throw new Error('Auth0 Domain is not configured.');
  }
  if(!process.env.REACT_APP_AUTH0_CLIENTID) {
    /**
     * You must add the key=value for REACT_APP_AUTH0_CLIENTID to an application .env file.
     */
    throw new Error('Auth0 ClientId is not configured.');
  }

  const navigate = useNavigate();

  const auth0Options: Auth0ProviderOptions = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID,
    authorizeTimeoutInSeconds: 10,
    authorizationParams: {
      redirect_uri: window.location.href,
      audience: process.env.REACT_APP_API_URL,
    },
    cacheLocation:
      process.env.REACT_APP_ENV === 'production' ? 'memory' : 'localstorage',
  };
  if (process.env.REACT_APP_AUTH0_COOKIE_DOMAIN) {
    // I don't want to allow an empty string or undefined. If the cookie domain is invalid, auth will break.
    auth0Options.cookieDomain = process.env.REACT_APP_AUTH0_COOKIE_DOMAIN;
  }

  //
  // Capture the AppState from the redirect.
  // Note this is only fired when the user logins either as a new or returning user.
  // This callback does NOT fire for the user logout redirect.
  //
  auth0Options.onRedirectCallback = async (appState?: AppState, user?: User) => {
    //
    // We just logged in - but was it a new user signup or an existing user login?
    // NOTE: This callback is NOT fired for the `logout` action, only login, including signup.
    //
    // {
    //   "created_at": "2023-05-20T20:33:39.350Z",
    //   "logins_count": 105,
    //   "nickname": "ben+test6c",
    //   "name": "ben+test6@benswayne.com",
    //   "picture": "https://s.gravatar.com/avatar/0e6de652a65574386ca40c615eef4b8c?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fbe.png",
    //   "updated_at": "2024-01-27T16:36:23.873Z",
    //   "email": "ben+test6@benswayne.com",
    //   "email_verified": false,
    //   "sub": "auth0|64692ea3c2b8380d9bdd24d2"
    // }
    //
    // We will use the user profile `created_at` field to calculate how old the authenticated
    // user's account is. If their account is less than 10 seconds old, they are a new account
    // signup.
    //
    if (user?.created_at) {
      const createdAt = new Date(user.created_at);
      const accountAgeSeconds = Math.ceil(
        (Date.now() - createdAt.getTime()) / 1000
      );

      // console.log(
      //   `Account is ${accountAgeSeconds} seconds old, and has signed in ${auth0User.logins_count} times`
      // );

      if (accountAgeSeconds < 10) {
        // If this account was created less than 10 seconds ago, the user logged in via the
        // "signup" or register action.
        await zaraz.track('user_signup_complete');
      }
    }

    if (user) {
      await zaraz.track('user_login_complete');
    }

    if (appState?.returnTo) {
      const returnToUrl = new URL(appState.returnTo);
      navigate(returnToUrl, {
        replace: true,
      });
      // window.history.replaceState(null, document.title, appState.returnTo);
      // window.location.href = appState.returnTo;
    }
  };

  return !navigator.userAgent.includes('jsdom') && window.isSecureContext ? (
    <Auth0Provider {...auth0Options}>
      {props.children}
    </Auth0Provider>
  ) : (
    <>
      {props.children}
    </>
  );
};

export default ConditionalAuth0Provider;
