import EndpointRole from "./EndpointRole";
/**
 * See: https://developer.chrome.com/static/docs/web-platform/page-lifecycle-api/image/page-lifecycle-api-state.svg
 * Active = app is visible and has focus
 * Passive = app is visible, but does not have focus
 * Hidden = app is not visible, was probably "minimized" or user switched apps on their device
 */
export var AppLifecycleState;
(function (AppLifecycleState) {
    AppLifecycleState["Active"] = "active";
    AppLifecycleState["Passive"] = "passive";
    AppLifecycleState["Hidden"] = "hidden";
})(AppLifecycleState || (AppLifecycleState = {}));
/**
 * Represents the PrompterSessionEndpoint information.
 */
export class PrompterSessionEndpoint {
    constructor() {
        this.role = EndpointRole.Unknown;
        this.disconnected = false;
        this.connectedTimestamp = Date.now();
        this.appLifecycleState = AppLifecycleState.Active;
    }
    static fromJson(jsonData) {
        if (!jsonData) {
            throw new SyntaxError('Invalid JSON for PrompterSessionEndpoint.');
        }
        const instance = new PrompterSessionEndpoint();
        instance.endpointId = jsonData.endpointId;
        instance.role = jsonData.role;
        instance.peerNumber = jsonData.peerNumber;
        instance.appLifecycleState = jsonData.appLifecycleState;
        instance.cloudLatency = jsonData.cloudLatency;
        instance.endpointName = jsonData.endpointName;
        instance.connectedTimestamp = jsonData.connectedTimestamp;
        instance.logLevel = jsonData.logLevel;
        instance.disconnected = jsonData.disconnected;
        //
        // The following are gathered client side from FluidPrompter app ConnectMessage.
        //
        instance.osName = jsonData.osName;
        instance.osVersion = jsonData.osVersion;
        instance.browserName = jsonData.browserName;
        instance.browserVersion = jsonData.browserVersion;
        instance.deviceType = jsonData.deviceType;
        instance.viewportWidth = jsonData.viewportWidth;
        instance.viewportHeight = jsonData.viewportHeight;
        instance.contentWidth = jsonData.contentWidth;
        instance.contentHeight = jsonData.contentHeight;
        instance.lineHeight = jsonData.lineHeight;
        //
        // The following are gathered server side from ClloudFlare geo-ip
        //
        instance.clientIp = jsonData.clientIp;
        instance.city = jsonData.city;
        instance.regionCode = jsonData.regionCode;
        instance.region = jsonData.region;
        instance.country = jsonData.country;
        instance.continent = jsonData.continent;
        instance.postalCode = jsonData.postalCode;
        instance.latitude = jsonData.latitude;
        instance.longitude = jsonData.longitude;
        instance.timezone = jsonData.timezone;
        return instance;
    }
    /**
     * Customize the JSON.stringify() serialization of the entire object.
     * @returns PrompterSessionEndpointForApiJson
     */
    toJSON() {
        return {
            endpointId: this.endpointId,
            endpointName: this.endpointName,
            role: this.role,
            peerNumber: this.peerNumber,
            appLifecycleState: this.appLifecycleState,
            connectedTimestamp: this.connectedTimestamp,
            cloudLatency: this.cloudLatency,
            logLevel: this.logLevel,
            disconnected: this.disconnected,
            //
            // The following are gathered client side from FluidPrompter app ConnectMessage.
            //
            osName: this.osName,
            osVersion: this.osVersion,
            browserName: this.browserName,
            browserVersion: this.browserVersion,
            deviceType: this.deviceType,
            viewportWidth: this.viewportWidth,
            viewportHeight: this.viewportHeight,
            contentWidth: this.contentWidth,
            contentHeight: this.contentHeight,
            lineHeight: this.lineHeight,
            //
            // The following are gathered server side from ClloudFlare geo-ip
            //
            clientIp: this.clientIp,
            city: this.city,
            regionCode: this.regionCode,
            region: this.region,
            country: this.country,
            continent: this.continent,
            postalCode: this.postalCode,
            latitude: this.latitude,
            longitude: this.longitude,
            timezone: this.timezone,
        };
    }
}
