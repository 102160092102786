import React from 'react';

import useDeviceHost from './useDeviceHost';
import useGenericDevices from './useGenericDevices';
import useBleDevices from './useBleDevices';
import useHidDevices from './useHidDevices';
import useGamepadDevices from './useGamepadDevices';

import { ThemeProvider } from '@mui/material/styles';
import lightTheme from '../themes/LightTheme';

import ConnectDeviceDialog from '../components/ConnectDeviceDialog';
import usePeerDevices from './usePeerDevices';

const DeviceManagerComponent = () => {

  //
  // DeviceHost is the container that holds the collection of currently connected devices
  // independently from any UI rendering lifecycle.
  //
  const {
    deviceHost,
    deviceComponent: DeviceComponent,
    deviceComponentProps,
  } = useDeviceHost();

  //
  // This hook handles WebRTC DataChannel connected peers including network prompters and remotes.
  //
  usePeerDevices(deviceHost);

  //
  // Generic Device implementation details are encapsulated in the useGenericDevices() hook.
  //
  useGenericDevices(deviceHost);

  //
  // Ble Device specific implementation details are encapsulated in the useBleDevices() hook.
  //
  useBleDevices(deviceHost);

  //
  // HID Device specific implementation details are encapsulated in the useHidDevices() hook.
  //
  useHidDevices(deviceHost);

  //
  // Gamepad Device specific implementation details are encapsulated in the useGamepadDevices() hook.
  //
  useGamepadDevices(deviceHost);

  //
  // We will render any current device management UI including
  // - ConnectDeviceDialog
  // - Device Menu's, Device Dialogs
  //
  return (
    <ThemeProvider theme={lightTheme}>
      <ConnectDeviceDialog />
      {deviceComponentProps && <DeviceComponent {...deviceComponentProps} />}
    </ThemeProvider>
  );
};

export default DeviceManagerComponent;