import React from 'react';
import { useAppController, useMessageHandler } from '../../../controllers/AppController';

import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';

import SpeedPopover from './SpeedPopover';

import useConfigurationStore from '../../../state/ConfigurationStore';
import { shallow } from 'zustand/shallow';

import { SetScrollSpeedMessage } from '@fluidprompter/core';
import throttle from 'lodash/throttle';

import SpeedIcon from '@mui/icons-material/Speed';
import LockIcon from '@mui/icons-material/Lock';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const SliderNoTransition = styled(Slider)({
  width: 'auto',
  height: 8,
  color: '#fff',
  '& .MuiSlider-track': {
    border: 'none',
    transition: 'none', // Disable CSS transitions for high frequency updates.
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    transition: 'none', // Disable CSS transitions for high frequency updates.
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    color: '#333',
    backgroundColor: '#fff',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

interface SpeedSliderProps {
  viewportIsLargeOrLarger?: boolean;
}

const SpeedSlider = (props: SpeedSliderProps) => {
  const configStore = useConfigurationStore(state => ({
    scrollReversed: state.scrollReversed,
    scrollSpeedLocked: state.scrollSpeedLocked,
    userScrollSpeed: state.userScrollSpeed,
    hideScrollSpeed: state.hideScrollSpeed,
  }), shallow);

  const appController = useAppController();

  const setScrollSpeedThrottled = React.useCallback(throttle((proposedSpeed: number) => {
    appController.dispatchMessage(new SetScrollSpeedMessage(proposedSpeed));
  }, 1000/60, {
    leading: true,
    trailing: true,
  }), []);
  
  const popoverButtonRef = React.createRef<HTMLButtonElement>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const openPresetMenu = React.useCallback(async function () {
    setAnchorEl(popoverButtonRef.current);
  }, [popoverButtonRef, setAnchorEl]);
  useMessageHandler('prompter.local.presetmenu.open', openPresetMenu);

  const closePresetMenu = React.useCallback(async function () {
    setAnchorEl(null);
  }, [setAnchorEl]);
  useMessageHandler('prompter.local.presetmenu.close', closePresetMenu);

  const togglePresetMenu = React.useCallback(async function () {
    setAnchorEl(anchorEl ? null : popoverButtonRef.current);
  }, [popoverButtonRef, anchorEl, setAnchorEl]);
  useMessageHandler('prompter.local.presetmenu.toggle', togglePresetMenu);

  const presetMenuAvailable = true;
  return (
    <>
      {/* <Typography component={'span'} fontSize='1.5rem' color='error'>R</Typography> */}
      {!configStore.hideScrollSpeed && <IconButton
        disabled
        size="large"
        edge="start"
        sx={{
          padding: 1,
        }}
      >
        {configStore.scrollReversed
          ? <SettingsBackupRestoreIcon color="error" />
          : (configStore.scrollSpeedLocked
            ? <LockIcon color="error" />
            : <SpeedIcon color="primary" />)}
      </IconButton>}
      {!configStore.hideScrollSpeed && <SliderNoTransition
        className="TourTargetSpeedControl"
        color="secondary"
        valueLabelDisplay="auto"
        aria-label="Prompter Speed"
        sx={{
          maxWidth: props.viewportIsLargeOrLarger ? 200 : 150, 
          flexGrow: 1
        }}
        min={20} 
        max={500} 
        value={configStore.userScrollSpeed} 
        onChange={(e, newValue) => {
          const newSpeed = newValue as number;
          setScrollSpeedThrottled(newSpeed);
        }}
      />}

      {presetMenuAvailable && <>
        <IconButton
          ref={popoverButtonRef}
          size="large"
          edge="end"
          color="inherit"
          aria-label="Speeds Menu"
          sx={{
            padding: 1,
          }}
          onFocus={(e) => { e.target.blur(); }}
          onClick={() => {
            appController.dispatchMessage('prompter.local.presetmenu.open');
          }}
        >
          { (anchorEl !== null) ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
        </IconButton>
        {(anchorEl !== null) && <SpeedPopover anchorEl={anchorEl} />}
      </>}
    </>
  );
};

export default SpeedSlider;