import React, { useCallback, useState } from 'react';
import { Editor, Node, Transforms } from 'slate';
import { ReactEditor, useSlateStatic, RenderElementProps, useFocused, useSelected } from 'slate-react';
import { ElementTypes, Image, PrompterElement } from '../../../models/EditorTypes';

import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import isHotkey from 'is-hotkey';

import './ImageElement.scss';

const ImageElement = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  const imageNode = element as Image;

  const [isEditingCaption, setEditingCaption] = useState(false);
  const [caption, setCaption] = useState(imageNode.caption);

  const editor = useSlateStatic();
  const editorIsReadOnly = ReactEditor.isReadOnly(editor);

  const selected = useSelected();  // Get the current selected state of an element.
  const focused = useFocused();    // Get the current focused state of the editor.


  const applyCaptionChange = useCallback((captionInput: string) => {
    const imageNodeEntry = Editor.above(editor, {
      match: (n: Node) => {
        const node = n as PrompterElement;
        return node.type === ElementTypes.IMAGE;
      },
    });
    if (imageNodeEntry == null) {
      return;
    }

    if (captionInput != null) {
      setCaption(captionInput);
    }

    Transforms.setNodes<Image>(
      editor,
      { caption: captionInput },
      { at: imageNodeEntry[1] }
    );
  }, [editor, setCaption]);

  const onCaptionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCaption(event.target.value);
    },
    [setCaption]
  );

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isHotkey('enter', e)) {
      return;
    }
    e.preventDefault();

    // User pressed enter! 
    // Now let's stop stop editing the image caption.
    const inputEl = e.target as HTMLInputElement;
    applyCaptionChange(inputEl.value);
    setEditingCaption(false);
  }, [applyCaptionChange, setEditingCaption]);


  const startCaptionEdit = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const wasEditing = isEditingCaption;
      setEditingCaption(!isEditingCaption);
      wasEditing && applyCaptionChange(caption);
    },
    [isEditingCaption, applyCaptionChange, caption]
  );

  const finishCaptionEdit = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      const wasEditing = isEditingCaption;
      setEditingCaption(!isEditingCaption);
      wasEditing && applyCaptionChange(caption);
    },
    [isEditingCaption, applyCaptionChange, caption]
  );

  return (    
    <div className={classNames('ImageElement', { isSelected: (selected && focused) })} contentEditable={false} {...attributes}>
      <div
        className={classNames({
          'image-container': true,
          'is-selected': selected && focused,
        })}
      >
        {!imageNode.isUploading && imageNode.url != null ? (
          <img src={String(imageNode.url)} alt={caption} className="image" />
        ) : (
          <div className={'image-upload-placeholder'}>
            {/*<Spinner animation="border" variant="dark" />*/}
          </div>
        )}
        {children}        
        {isEditingCaption ? (
          <TextField
            variant="outlined"
            autoFocus={true}
            className={'image-caption-input'}
            defaultValue={caption}
            onChange={onCaptionChange}
            onBlur={finishCaptionEdit}
            InputProps={{
              onKeyDown: onKeyDown,
            }}
          />
        ) : (
          <div
            className={'image-caption-read-mode'}
            onClick={startCaptionEdit}
          >
            {imageNode.caption}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageElement;