import React, { useLayoutEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';

import CircularProgress from '@mui/material/CircularProgress';

import { ITrayItem } from './TrayContainer';
import TrayItemIconMenuItem from './TrayItemIconMenuItem';
import TrayItemIconButton from './TrayItemIconButton';

import { useAppController } from '../../controllers/AppController';
import { useTranslation } from 'react-i18next';

import useBluetoothState from '../../state/BluetoothState';
import { shallow } from 'zustand/shallow';

import BluetoothIcon from '@mui/icons-material/Bluetooth';
import BluetoothConnectedIcon from '@mui/icons-material/BluetoothConnected';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';

import Battery20Icon from '@mui/icons-material/Battery20';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryFull from '@mui/icons-material/BatteryFull';

import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import BatteryCharging30Icon from '@mui/icons-material/BatteryCharging30';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import BatteryChargingFull from '@mui/icons-material/BatteryChargingFull';

import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';

// import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
// import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
// import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalCellularConnectedNoInternet4BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet4Bar';

interface BluetoothTrayItemProps {
  renderAsMenuItem?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  requestCloseTray: () => void;
}

const BluetoothTrayItem = (props: BluetoothTrayItemProps) => {

  const { renderAsMenuItem } = props;

  const { t } = useTranslation('prompter');

  const appController = useAppController();

  const bluetoothState = useBluetoothState(state => ({
    bleDevices: state.bleDevices,
    isBluetoothAvailable: state.isBluetoothAvailable,
    isBluetoothConnecting: state.isBluetoothConnecting,
    isBluetoothConnected: state.isBluetoothConnected,
  }), shallow);

  // useState will hold reference to Bluetooth Menu anchor element.
  const [btMenuAnchorEl, setBtMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const onBluetoothClick = (e: React.MouseEvent<HTMLElement>) => {
    if(props.onClick) { props.onClick(e); }
    setBtMenuAnchorEl(e.currentTarget);
  };
  const onBluetoothClose = () => {
    setBtMenuAnchorEl(null);
  };

  useLayoutEffect(() => {
    // When renderAsMenuItem changes, we need to close any open menus.
    onBluetoothClose();
  }, [renderAsMenuItem]);

  const disconnectBluetoothDevice = (e: React.MouseEvent<HTMLLIElement>) => {
    setBtMenuAnchorEl(null);
    props.requestCloseTray();

    appController.dispatchMessage('devices.bluetooth.disconnect', {
      deviceId: e.currentTarget.id
    });
  };

  const getConnectedBluetoothDevicesMenu = () => {
    const menuItems: React.ReactNode[] = [];

    const bleDevices = bluetoothState.bleDevices || [];
    if(bleDevices.length) {
      menuItems.push( <Divider key="div1" /> );
      bleDevices.map((bluetoothDevice) => {

        const isCharging = bluetoothDevice.batteryCharging;
        const knownBatteryPercentage = (bluetoothDevice.batteryPercentage || 0);

        let batteryIcon: React.ReactNode;
        if(bluetoothDevice.batteryPercentage === undefined) {
          // This means the device does not report battery status.
          batteryIcon = undefined;
        } else if (!isCharging && (knownBatteryPercentage <= 10)) {
          batteryIcon = <BatteryAlertIcon sx={{ color: '#ff0000' }} fontSize="small" />;
        } else if (knownBatteryPercentage <= 20) {
          batteryIcon = isCharging ? <BatteryCharging20Icon sx={{ color: '#ff0000' }} fontSize="small" /> : <Battery20Icon sx={{ color: '#ff0000' }} fontSize="small" />;
        } else if (knownBatteryPercentage <= 30) {
          batteryIcon = isCharging ? <BatteryCharging30Icon sx={{ color: '#ffeb3b' }} fontSize="small" /> : <Battery30Icon sx={{ color: '#ffeb3b' }} fontSize="small" />;
        } else if (knownBatteryPercentage <= 50) {
          batteryIcon = isCharging ? <BatteryCharging50Icon sx={{ color: '#ffeb3b' }} fontSize="small" /> : <Battery50Icon sx={{ color: '#ffeb3b' }} fontSize="small" />;
        } else if (knownBatteryPercentage <= 60) {
          batteryIcon = isCharging ? <BatteryCharging60Icon sx={{ color: '#4caf50' }} fontSize="small" /> : <Battery60Icon sx={{ color: '#4caf50' }} fontSize="small" />;
        } else if (knownBatteryPercentage <= 80) {
          batteryIcon = isCharging ? <BatteryCharging80Icon sx={{ color: '#4caf50' }} fontSize="small" /> : <Battery80Icon sx={{ color: '#4caf50' }} fontSize="small" />;
        } else if (knownBatteryPercentage <= 98) {
          batteryIcon = isCharging ? <BatteryCharging90Icon sx={{ color: '#4caf50' }} fontSize="small" /> : <Battery90Icon sx={{ color: '#4caf50' }} fontSize="small" />;
        } else {
          batteryIcon = isCharging ? <BatteryChargingFull sx={{ color: '#4caf50' }} fontSize="small" /> : <BatteryFull sx={{ color: '#4caf50' }} fontSize="small" />;
        }

        const connectionIcon: React.ReactNode = bluetoothDevice.isConnected() ? <SignalCellular4BarIcon fontSize="small" /> : <SignalCellularConnectedNoInternet4BarIcon fontSize="small" />;
                
        menuItems.push(
          <MenuItem key={bluetoothDevice.id} id={bluetoothDevice.id} onClick={disconnectBluetoothDevice}>
            <ListItemText>Disconnect {bluetoothDevice.name}</ListItemText>
            {batteryIcon && <ListItemIcon style={{
              paddingLeft: '16px'
            }}>
              <Tooltip title={isCharging ? 'Plugged-In Charging' : `${bluetoothDevice.batteryPercentage}%`} arrow>{batteryIcon}</Tooltip>
            </ListItemIcon>}
            <ListItemIcon style={{
              paddingLeft: '16px'
            }}>
              <Tooltip title={bluetoothDevice.isConnected() ? 'connected' : 'disconnected'} arrow>{connectionIcon}</Tooltip>
            </ListItemIcon>
          </MenuItem>
        );
      });
    }

    return menuItems;
  };

  const bluetoothButtonIcon = function() {
    let bluetoothIcon = <BluetoothDisabledIcon />;
    // Device what bluetooth icon to render!
    // { state.isBluetoothAvailable ? (state.isBluetoothConnected ? <BluetoothConnectedIcon /> : <BluetoothIcon />) : <BluetoothDisabledIcon /> }
    if(bluetoothState.isBluetoothAvailable) {
      bluetoothIcon = <BluetoothIcon />;

      if(bluetoothState.isBluetoothConnecting) {
        bluetoothIcon = <CircularProgress size={24} color="inherit" />;
      } else if(bluetoothState.isBluetoothConnected) {
        bluetoothIcon = <BluetoothConnectedIcon />;
      }
    }

    return bluetoothIcon;
  };

  const trayItemProps: ITrayItem = {
    title: t('trayicons.bluetooth'),
    icon: bluetoothButtonIcon(),
    onClick: onBluetoothClick,
  };

  return (<>
    {renderAsMenuItem ? (<TrayItemIconMenuItem {...trayItemProps} />) : (<TrayItemIconButton {...trayItemProps} />)}
    <Menu
      id="bluetooth-menu"
      anchorEl={btMenuAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}        
      open={Boolean(btMenuAnchorEl)}
      onClose={onBluetoothClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={
        () => {
          setBtMenuAnchorEl(null);
          props.requestCloseTray();

          // Pop up the new generic "Connect a Device" menu
          appController.dispatchMessage('app.connect.device');
        }
      }>{t('trayicons.connectbluetooth')}</MenuItem>
      {getConnectedBluetoothDevicesMenu()}
    </Menu>
  </>);
};

export default BluetoothTrayItem;