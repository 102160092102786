import React, { useEffect, useCallback, useRef } from 'react';
import { Editor, Descendant, Text, Location, Path, BasePoint, BaseRange, NodeEntry, Node } from 'slate';
import { ReactEditor } from 'slate-react';

import AppController from '../../controllers/AppController/AppController';
import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';

import { useWhatChanged } from '@simbathesailor/use-what-changed';
import {
  IViewportInfo,
  IPrompterMeta,
  ISlateNodeMeta,
  IScriptNodeState,
  ScriptNodeStateChangedEvent,
  BaseControlMessage,
  IScriptPosition,
  PrompterMode,
  ScriptNodeStateChangeTypes,
  SegmentChangedMessage,
  NodeChangedMessage,
  SetScrollSpeedMessage,
} from '@fluidprompter/core';

import IPrompterPosition from '../../models/IPrompterPosition';

import debounce from 'lodash/debounce';
import useResizeObserver from '@react-hook/resize-observer';
import { PrompterEditor, PrompterElement } from '../../models/EditorTypes';
import FocusGuide from '../../models/FocusGuide';

import calculateScrollPositionAdjustment from '../../utils/calculateScrollPositionAdjustment';
import calculateScrollSpeed from '../../utils/calculateScrollSpeed';

export interface PrompterResizedEventArgs {
  contentHeight: number,
  viewportHeight: number,
  lineHeight: number,
}

const measureScriptNode = (
  editor: PrompterEditor,
  nodeLocation: Location,
  prompterContentRect: DOMRect,
  flipVertical: boolean
): ISlateNodeMeta => {
  let currentNode: Node | undefined;
  try {
    const [editorNode] = Editor.node(editor, nodeLocation);
    currentNode = editorNode;
  } catch(err) {
    console.error(err);
    console.log(`Could not find node ${nodeLocation}`);
  }
  if(!currentNode) {
    throw new Error('Slate could not locate node in DOM.');
  }
  const segmentEl = ReactEditor.toDOMNode(editor, currentNode);
  // console.log(`Found Slate Node ${index}!`, currentNode, segmentEl);

  const segmentRect = segmentEl.getBoundingClientRect();

  // const segmentTop = segmentEl.offsetTop + editorOffsetTop;
  // const segmentHeight = segmentEl.clientHeight;
  const segmentTop = flipVertical
    ? prompterContentRect.top + prompterContentRect.height - segmentRect.bottom
    : segmentRect.top - prompterContentRect.top;
  const segmentHeight = segmentRect.height;
  const segmentBottom = segmentTop + segmentHeight;

  // console.log(`segmentRect ${index}, offsetTop=${segmentEl.offsetTop}, rectTop=${segmentTop}, delta=${segmentTop - segmentEl.offsetTop}`, segmentRect);
  // console.log(`editorRect ${index}, offsetTop=${editorOffsetTop}`, editorRect);

  //const domRect = linkDOMNode.getBoundingClientRect();

  const styles = window.getComputedStyle(segmentEl);
  const marginTop = parseFloat(styles['marginTop']);
  const marginBottom = parseFloat(styles['marginBottom']);
  const segmentLineHeight = parseInt(styles['lineHeight']);

  // TODO: Would this be more accurate using the body height for a prompter segment? Currently this is more a calculation of 'space/dimensions' than 'content length'.
  const segmentLines = Math.round(segmentHeight / segmentLineHeight);

  const isBlock = !(Text.isText(currentNode) || Editor.isInline(editor, currentNode));
  const isVoid = Editor.isVoid(editor, currentNode);

  return {
    top: segmentTop,
    height: segmentHeight,
    bottom: segmentBottom,
    prompterLines: segmentLines,
    marginTop,
    marginBottom,
    // offsetTop: segmentEl.offsetTop,       // includes: the top position, and margin of the element. the top padding, scrollbar and border of the parent
    // offsetHeight: segmentEl.offsetHeight  // The offsetHeight property returns the viewable height of an element (in pixels), including padding, border and scrollbar, but not the margin.
    children: [], // TODO get position of children elements
    childrenTop: 0,     // Default value - replaced when recursively measuring nodes
    childrenBottom: 0,  // Default value - replaced when recursively measuring nodes
    childrenHeight: 0,  // Default value - replaced when recursively measuring nodes
    isBlock,
    isVoid,
  };
};

const measureScriptNodes = (parentNodePath: Path, scriptNodes: Descendant[], editor: PrompterEditor, prompterContentRect: DOMRect, flipVertical: boolean): ISlateNodeMeta[] => {
  const segmentPoint: BasePoint = {
    path: parentNodePath,
    offset: 0
  };
  const segmentRange: BaseRange = {
    anchor: segmentPoint,
    focus: segmentPoint,
  };

  const nodesMeta = scriptNodes.map((scriptNode, index, array): ISlateNodeMeta => {
    const nodePath = [...parentNodePath, index];
    segmentRange.anchor.path = nodePath;
    segmentRange.focus.path = nodePath;

    //
    // Measure the current node.
    //
    const currentNodeMeta: ISlateNodeMeta = measureScriptNode(editor, segmentRange, prompterContentRect, flipVertical);

    //
    // Measure any children nodes, if appropriate.
    //
    const prompterElement = scriptNode as PrompterElement;
    if(prompterElement && prompterElement.children) {
      const childrenMeta = measureScriptNodes(nodePath, prompterElement.children, editor, prompterContentRect, flipVertical);

      currentNodeMeta.children = childrenMeta;
      currentNodeMeta.childrenTop = childrenMeta[0].top;
      currentNodeMeta.childrenBottom = childrenMeta[childrenMeta.length - 1].bottom;
      currentNodeMeta.childrenHeight = currentNodeMeta.childrenBottom - currentNodeMeta.childrenTop;
    }

    return currentNodeMeta;
  });

  // console.log(`Calculated ISlateNodeMeta for children at path [${parentNodePath}]`, nodesMeta);
  return nodesMeta;
};

const getViewportInfo = (flipVertical: boolean, focusGuide: FocusGuide) => {
  const viewportEl = document.scrollingElement;
  if(!viewportEl) {
    throw new Error('document.scrollingElement undefined.');
  }

  const viewportTop = flipVertical ? viewportEl.scrollHeight - viewportEl.clientHeight - viewportEl.scrollTop : viewportEl.scrollTop;
  const viewportHeight = viewportEl.clientHeight;
  const viewportBottom = viewportTop + viewportHeight;

  let viewportCuePosition = 0;
  switch(focusGuide) {
    case FocusGuide.Top:
      viewportCuePosition = viewportTop + (viewportHeight * 0.2);
      break;
    case FocusGuide.Bottom:
      viewportCuePosition = viewportTop + (viewportHeight * 0.8);
      break;
    default:
    case FocusGuide.Middle:
    case FocusGuide.None:
      viewportCuePosition = viewportTop + (viewportHeight * 0.5);
      break;
  }
  // UNEEDED? DELETE: const contentHeight = scriptNodesMeta.contentHeight;
  // UNEEDED? DELETE: const maxScollPosition = contentHeight - viewportHeight;

  const viewportInfo: IViewportInfo = {
    viewportTop,
    viewportHeight,
    viewportBottom,
    viewportCuePosition,
  };

  return viewportInfo;
};

interface IEvaluateNodesStateResults {
  /**
   * This is a collection of states for each node
   */
  nodeStates: IScriptNodeState[];

  /**
   * A collection of events to emit as a result of nodes' changing state in the prompter viewport.
   * ex: EnteringViewport, LeavingViewport, EnteringCue, LeavingCue
   */
  nodeEvents: BaseControlMessage[];
}

class EvaluateNodesStateResultsClass implements IEvaluateNodesStateResults {
  nodeStates: IScriptNodeState[];
  // nodeEvents: IControlMessage[];
  nodeEvents: BaseControlMessage[];

  scriptPosition: IScriptPosition | undefined;

  constructor() {
    this.nodeStates = [];
    this.nodeEvents = [];
  }

  addEvent(event: BaseControlMessage) {
    this.nodeEvents.push(event);
  }

  addEvents(events: BaseControlMessage[]) {
    if(!events) {
      return;
    }
    events.forEach((newEvent) => {
      this.nodeEvents.push(newEvent);
    });
  }

  setCurrentNode(
    nodePath: number[],
    nodeHeight: number,
    nodeChildrenTop: number,
    nodeChildrenHeight: number,
    position: number
  ) {
    if(nodePath.length === 0) {
      throw new Error('Invalid nodePath provided.');
    }

    this.scriptPosition = {
      nodePath,
      //
      nodeHeight,
      nodeChildrenTop,
      nodeChildrenHeight,
      //
      position,
    };
  }
}

// RECURSIVE METHOD
const evaluateNodesState = (editor: PrompterEditor, viewportInfo: IViewportInfo, parentNodePath: Path, scriptNodes: Descendant[], nodesMeta: ISlateNodeMeta[], nodesState: IScriptNodeState[] | undefined, prompterMode: PrompterMode, resultsCollectorInstance?: EvaluateNodesStateResultsClass): EvaluateNodesStateResultsClass => {
  const resultsCollector = resultsCollectorInstance || new EvaluateNodesStateResultsClass();

  const newScriptNodesState = nodesMeta.map((currentNodeMeta, index, array): IScriptNodeState => {
    const currentNodePath = [...parentNodePath, index];
    const prompterDescendant = scriptNodes[index];

    const prompterElement = prompterDescendant as PrompterElement;
    const isInline = Text.isText(prompterDescendant) || Editor.isInline(editor, prompterDescendant);

    // Copy some meta info
    const currentNodeHeight = currentNodeMeta.height;

    // Recalculate Node position
    const previousNodeState = nodesState && (array.length === nodesState.length) ? nodesState[index] : undefined;

    const segmentTopIntersection = Math.max(viewportInfo.viewportTop, currentNodeMeta.top);
    const segmentBottomIntersection = Math.min(viewportInfo.viewportBottom, currentNodeMeta.bottom);

    // True if any portion of this segment is visible in the viewport.
    const elementVisible = (segmentTopIntersection <= segmentBottomIntersection);

    // True if the entire node is above the viewport top.
    const elementAboveViewportTop = !elementVisible && (currentNodeMeta.bottom < viewportInfo.viewportTop);

    // True if any portion of this segment is above the top edge of the viewport.
    const elementCrossingViewportTop = elementVisible && (currentNodeMeta.top < viewportInfo.viewportTop);

    // True if any portion of this segment is at the cue position.
    const onCue = (segmentTopIntersection <= viewportInfo.viewportCuePosition && segmentBottomIntersection >= viewportInfo.viewportCuePosition);

    // Calculate the percentage through this element's height the cue position is.
    const anyPortionIsPassedCue = (viewportInfo.viewportCuePosition >= currentNodeMeta.top);
    const completelyPassedCue = (currentNodeMeta.bottom < viewportInfo.viewportCuePosition);

    // The number of pixels of the current node's height remaining past the cue position.
    const heightRemaining = completelyPassedCue ? 0 : currentNodeMeta.bottom - viewportInfo.viewportCuePosition;

    // We will hold a reference to the last block element in the script that has any portion
    // passed the cue position. This will be called our "current node" and the current script
    // position can be expressed as the percentage of this node's height that is passed the
    // cue position.
    if(
      anyPortionIsPassedCue
      && !(currentNodePath.length > 1 && completelyPassedCue) // Don't use nested blocked level elements that are fully passed the cue, instead fallback the last prompter segment root node.
      && !isInline
    ) {
      // if(prompterElement.type === ElementTypes.SCRIPT_SEGMENT && !completelyPassedCue) {
      //   const scalingHeight = currentNodeMeta.height - currentNodeMeta.childrenHeight;
      //   console.log(`PrompterSegment${currentNodePath} ScalingHeight: ${scalingHeight} = OuterHeight:${currentNodeHeight} - InnerHeight: ${currentNodeMeta.childrenHeight}`);
      // }

      // New way of collecting current node path and percentage passed cue.
      // console.log(`setCurrentNode<${prompterDescendant.type}>(currentNodePath:${currentNodePath}, nodeHeight:${currentNodeHeight}`);

      const position = viewportInfo.viewportCuePosition - currentNodeMeta.top;
      resultsCollector.setCurrentNode(
        currentNodePath,
        currentNodeHeight,
        currentNodeMeta.childrenTop - currentNodeMeta.top,
        currentNodeMeta.childrenHeight,
        position
      );
    }

    // True if the entire node is above the cue position.
    const aboveCue = !onCue && (currentNodeMeta.bottom < viewportInfo.viewportCuePosition);

    // True if the entire node is below the cue position.
    const belowCue = !onCue && (currentNodeMeta.top > viewportInfo.viewportCuePosition);

    // True if any portion of this segment is below the bottom edge of the viewport.
    const elementCrossingViewportBottom = elementVisible && (currentNodeMeta.bottom > viewportInfo.viewportBottom);

    // True if the entire node is below the viewport bottom
    const elementBelowViewportBottom = !elementVisible && (currentNodeMeta.top > viewportInfo.viewportBottom);

    //
    // Assemble the NodeState for the current node.
    //
    const currentNodeState: IScriptNodeState = {
      visible: elementVisible,
      aboveViewport: elementAboveViewportTop,
      topClipped: elementCrossingViewportTop,
      aboveCue,
      onCue,
      heightRemaining,
      belowCue,
      bottomClipped: elementCrossingViewportBottom,
      belowViewport: elementBelowViewportBottom,
      children: [], //TODO: recursively check children positions within prompter segments
    };

    //
    // Recursively update child nodes' state if this parent node state changed (to avoid excessive
    // calculations during animation - we really only care about the nodes in the viewport).
    //
    if(prompterElement?.children && (!previousNodeState // If there was no previous node state this is the first run after loading script - calculate everything!
      || elementVisible || elementVisible !== previousNodeState.visible))
    {
      // Calculate current state for child nodes.
      const childrenNodesStateResults = evaluateNodesState(editor, viewportInfo, currentNodePath, prompterElement.children, currentNodeMeta.children, previousNodeState?.children, prompterMode, resultsCollector);
      // console.log('Children Node State', childrenNodesState);
      // const childrenHeight = childrenNodesStateResults.nodeStates[0].height
      currentNodeState.children = childrenNodesStateResults.nodeStates;
    }

    //
    // 1. Check if this node is entering the viewport.
    // Event: prompter.node.{nodeType}.enteringviewport
    //
    const enteringviewport = (elementVisible && (previousNodeState && !previousNodeState.visible));
    if(enteringviewport) {
      // const reverse = previousNodeState.aboveViewport || previousNodeState.topClipped;

      const nodeEvent = ScriptNodeStateChangedEvent.build(
        ScriptNodeStateChangeTypes.ENTERING_VIEWPORT,
        prompterElement,
        currentNodePath,
        currentNodeMeta,
        previousNodeState,
        currentNodeState,
        viewportInfo,
        prompterMode
      );
      // console.log(`${prompterElementType} enteringviewport [${currentNodePath}] from ${reverse ? 'top of viewport' : 'bottom of viewport'}`);
      resultsCollector.addEvent(nodeEvent);
    }

    //
    // 1.b. Check if this node has entered the viewport.
    // Event: prompter.node.{nodeType}.enteredviewport
    //
    const enteredviewport = (elementVisible && (previousNodeState && previousNodeState.bottomClipped) && !elementCrossingViewportBottom);
    if(enteredviewport) {
      // const reverse = previousNodeState.aboveViewport || previousNodeState.topClipped;

      const nodeEvent = ScriptNodeStateChangedEvent.build(
        ScriptNodeStateChangeTypes.ENTERED_VIEWPORT,
        prompterElement, currentNodePath,
        currentNodeMeta, previousNodeState,
        currentNodeState,
        viewportInfo,
        prompterMode
      );
      // console.log(`${prompterElementType} enteredviewport [${currentNodePath}] from ${reverse ? 'top of viewport' : 'bottom of viewport'}`);
      resultsCollector.addEvent(nodeEvent);
    }

    //
    // 2. Check if this node is entering the cue position.
    // Event: prompter.node.{nodeType}.enteringcue
    //
    const enteringcue = (onCue && (previousNodeState && !previousNodeState.onCue));
    if(enteringcue) {
      // const reverse = previousNodeState.aboveCue;

      const nodeEvent = ScriptNodeStateChangedEvent.build(
        ScriptNodeStateChangeTypes.ENTERING_CUE,
        prompterElement,
        currentNodePath,
        currentNodeMeta,
        previousNodeState,
        currentNodeState,
        viewportInfo,
        prompterMode
      );
      // console.log(`${prompterElementType} enteringcue [${currentNodePath}] from ${reverse ? 'above cue' : 'below cue'}`);
      resultsCollector.addEvent(nodeEvent);
    }

    //
    // 3. Check if this node is leaving the cue position.
    // Event: prompter.node.{nodeType}.leavingcue
    //
    const leavingcue = (!onCue && (previousNodeState && previousNodeState.onCue));
    if(leavingcue) {
      // const reverse = belowCue;

      const nodeEvent = ScriptNodeStateChangedEvent.build(
        ScriptNodeStateChangeTypes.LEAVING_CUE,
        prompterElement,
        currentNodePath,
        currentNodeMeta,
        previousNodeState,
        currentNodeState,
        viewportInfo,
        prompterMode
      );
      // console.log(`${prompterElementType} leavingcue [${currentNodePath}] towards ${reverse ? 'below cue' : 'above cue'}`);
      resultsCollector.addEvent(nodeEvent);
    }

    //
    // 4. Check if this node is "leaving" the viewport. Leaving means any portion of this node
    // Event: prompter.node.{nodeType}.leavingviewport
    //
    const leavingviewport = (elementCrossingViewportTop && (previousNodeState && !previousNodeState.topClipped));
    if(leavingviewport) {
      // const reverse = previousNodeState.bottomClipped;

      const nodeEvent = ScriptNodeStateChangedEvent.build(
        ScriptNodeStateChangeTypes.LEAVING_VIEWPORT,
        prompterElement,
        currentNodePath,
        currentNodeMeta,
        previousNodeState,
        currentNodeState,
        viewportInfo,
        prompterMode
      );
      // console.log(`${prompterElementType} leavingviewport [${currentNodePath}] through ${reverse ? 'bottom of viewport' : 'top of viewport'}`);
      resultsCollector.addEvent(nodeEvent);
    }

    // const leftviewport = (!elementVisible && (previousNodeState && previousNodeState.visible));

    //
    // Handle PrompterSegments
    //
    /*
    const prompterElementType = prompterElement?.type || 'leaf';
    if(previousNodeState && prompterElementType === ElementTypes.SCRIPT_SEGMENT && prompterMode === PrompterMode.Playing) {
      //
      // If this segment was onCue and just left the cue position - consider pausing for ShotLogging.
      //
      if(!onCue && previousNodeState.onCue !== onCue) {
        resultsCollector.bus.emit('prompter.state.pause');
        resultsCollector.bus.emit('prompter.delay.actions');
        resultsCollector.bus.emit('prompter.prompt.shotlog');
      }
    } // END handle PrompterSegment node position changes
    */

    return currentNodeState;
  }); // END map()

  resultsCollector.nodeStates = newScriptNodesState;

  return resultsCollector;
};  // END evaluateNodesState() function

/**
 * Hook monitors changes in content size and subsequently measures the script content for use in
 * detecting script element positions (when script element enter the viewport, pass the cue
 * position or leave the viewport) and translating scrolling speed from a connected peer
 * prompter.
 *
 * The prompter content can change size for many reasons including loading a new script, resizing
 * the browser window, or changing the prompter appearance parameters such as text size or
 * margins.
 *
 * @param appController
 * @param editor
 * @param prompterContentRef
 * @returns
 */
function usePrompterContentMeasurements(appController: AppController, editor: PrompterEditor, prompterContentRef: React.RefObject<HTMLDivElement>) {

  // useWhatChanged([viewportRefs.previousLedgerRef, prompterSession.prompterMode, prompterSession.segments, configStore.focusGuide, configStore.flipVertical]);

  const doMeasureScriptNodes = useCallback(() => {
    //
    // Whenever the prompter is resized in anyway, the max scroll position or number of lines of
    // text may change as a result of the resizing.
    //

    // const viewportTop = prompterScroller.scrollTop;
    const viewportWidth = document.scrollingElement?.clientWidth || 0;
    const viewportHeight = document.scrollingElement?.clientHeight || 0;
    // const viewportBottom = viewportTop + viewportHeight;

    //
    // prompterContentRef wraps the slate Editor instance.
    //
    const prompterContentEl = prompterContentRef.current;
    if(!prompterContentEl) {
      return;
    }
    const prompterContentRect = prompterContentEl.getBoundingClientRect();
    const contentHeight = prompterContentEl.offsetHeight;
    const contentWidth = prompterContentEl.offsetWidth;
    // console.log(`prompterContentRect.top: ${prompterContentRect.top}`, prompterContentRect);

    const computedStyle = window.getComputedStyle(prompterContentEl);
    const lineHeight = parseInt(computedStyle.lineHeight);
    const contentPaddingLeft = parseInt(computedStyle.paddingLeft);
    const contentPaddingRight = parseInt(computedStyle.paddingRight);

    //
    // Get the root editable DIV element so that we know what margin it has.
    //
    // The root element created by slate to contain the contenteditable may be repositioned due to margin on the PrompterSegmentStartOfScript and PrompterSegmentEndOfScript elements.
    // I could move this margin to the PrompterContentContainer? Particularly if it cleaned up the position detection algorithm.
    //
    const editorRef = ReactEditor.toDOMNode(editor, editor);
    const editorRect = editorRef.getBoundingClientRect();
    const editorOffsetTop = editorRef.offsetTop;
    //console.log(`Slate editor offsetTop: ${editorRef.offsetTop}`);

    //
    // The following iterates over all nodes to collect size and position information.
    //
    const {
      flipVertical,
      focusGuide,
    } = useConfigurationStore.getState();

    let cuePositionOffset: number;
    switch(focusGuide) {
      case FocusGuide.Top:
        cuePositionOffset = viewportHeight * 0.2;
        break;
      default:
      case FocusGuide.Middle:
        cuePositionOffset = viewportHeight * 0.5;
        break;
      case FocusGuide.Bottom:
        cuePositionOffset = viewportHeight * 0.8;
        break;
    }

    const {
      scriptNodes,
      setScriptNodesMeta,
    } = usePrompterSession.getState();

    //
    // Recursively measure all scriptNodes in the prompter. This will later be used for detecting
    // the position of elements and when they enter the viewport, enter or leave the cue position,
    // and leave the viewport.
    //
    // These events may be used to trigger automations or special actions when certain nodes reach
    // a trigger position.
    //
    const nodesMeta: ISlateNodeMeta[] = measureScriptNodes([], scriptNodes, editor, prompterContentRect, flipVertical);

    //
    // Store our current script meta date (measurements) in react state for future reference.
    //
    const prompterMeta: IPrompterMeta = {
      contentWidth,
      contentHeight,
      contentPaddingLeft,
      contentPaddingRight,
      viewportWidth,
      viewportHeight,
      lineHeight,
      cuePositionOffset,
      nodesMeta
    };
    //console.log(`Prompter Meta:`, prompterMeta);
    setScriptNodesMeta(prompterMeta);

    //
    // Whenever the content size is changed we either need to either:
    // - inform other peers to recalculate their scroll position & speed (if we are leader)
    // - or recalculate our own scroll position & speed (if we are not leader)
    //
    const localPrompter = appController.deviceHost.getPrompterPeerLocalInstance();

    //
    // If the local prompter is the current leader, then changes in our content size should trigger
    // all followers to recalculate their scroll position/speed.
    //
    if(localPrompter?.isLeader) {
      const { scrollSpeed } = useConfigurationStore.getState();
      appController.dispatchMessage(new SetScrollSpeedMessage(scrollSpeed));
    }

    //
    // If the local prompter is not the current leader, then we want to sync our scroll position
    // and speed based on the current leader's meta info.
    //
    if(!localPrompter?.isLeader) {
      const leaderPrompter = appController.deviceHost.getPrompterPeerLeader();
      if(localPrompter === leaderPrompter) {
        // console.log('localPrompter === leaderPrompter');
        return;
      }

      //
      // Recalculate our current scoll position within current script.
      //
      const syncAdjustmentMessage = calculateScrollPositionAdjustment(leaderPrompter?.lastSenderInfo);
      if(syncAdjustmentMessage) {
        appController.handleLocalMessage(syncAdjustmentMessage);
      }

      //
      // Recalculate our current scroll speed
      //
      calculateScrollSpeed(leaderPrompter?.lastSenderInfo);
    }
  }, []); // END doMeasureScriptNodes()

  //
  // The index of the current script position under the cue position. If no script segment is under
  // the cue position now, then this will be the index of the last segment to have been under the
  // cue position.
  //
  // This is used to highlight in a table of contents where you are within a script or agenda.
  //
  const currentSegmentIndexRef = useRef<number>(0);

  //
  // The script node path for the current Script Node (at any depth in the node tree).
  //
  const currentNodePathRef = useRef<string>('');

  //
  // This function should calculate where each segment is given the current dimensions and
  //
  const doRecalculateSegmentPositions = useCallback((): IPrompterPosition | undefined => {
    // console.log('BEGIN doRecalculateSegmentPositions!');
    // Recalculate segment positions relative to prompter scroll position and dimensions.
    const {
      focusGuide,
      flipVertical,
    } = useConfigurationStore.getState();

    const {
      scriptNodes,
      scriptNodesMeta,
      scriptNodesState,
      setScriptNodesState,
      prompterMode,
      isLeader,
    } = usePrompterSession.getState();

    //
    // If we don't have all the information we need to calculate viewport and node positions, return.
    // This is only in the very first frame or two of rendering the DOM.
    //
    if(!scriptNodesMeta || !scriptNodesMeta.contentHeight || !scriptNodesMeta.nodesMeta) {
      return;
    }

    //
    // Retrieve measurements of current viewport.
    //
    const viewportInfo = getViewportInfo(flipVertical, focusGuide);

    //
    // Recursively calculate the state of each script node.
    //
    // console.log('BEGIN evaluateNodesState');
    const scriptNodesStates = evaluateNodesState(
      editor,
      viewportInfo,
      [],
      scriptNodes,
      scriptNodesMeta.nodesMeta,
      scriptNodesState?.nodesState,
      prompterMode
    );
    // console.log('END evaluateNodesState', scriptNodesStates.scriptPosition);

    /*
     * This works but can be cleaned up into a reusable utility function and maybe a method on the
     * state store.
     *
    //
    // Get relative position of the prompter viewport as measured from the top of the current node
    // as a percentage of the current node's content height.
    //
    */
    const { scriptPosition } = scriptNodesStates;
    if(!scriptPosition) {
      throw new Error('No ScriptPosition returned while calculating.');
    }

    //
    // If the current script segment (root node) has changed, dispatch an app message to let other
    // components know.
    //
    const currentSegmentIndex = scriptPosition.nodePath[0];
    if(currentSegmentIndexRef.current !== currentSegmentIndex) {
      //
      // Current Script Segment has changed (root node). This event triggers updates to things like
      // the table of contents, highlighting the segment currently under the cue position.
      //
      scriptNodesStates.addEvent(new SegmentChangedMessage(currentSegmentIndex));

      currentSegmentIndexRef.current = currentSegmentIndex;
    }

    //
    // If the current script node (deepest block node) has changed, dispatch an app message to let
    // other components know.
    //
    const currentNodePath = scriptPosition.nodePath.join('-');
    if(
      currentNodePathRef.current !== currentNodePath
      && isLeader
    ) {
      //
      // Current Script Node (at any depth in the node tree) has changed.
      //
      // But, we only want to fire the NodeChangedMessage after initial render (before
      // initial render currentNodePathRef.current will be an empty string).
      //
      if(currentNodePathRef.current) {
        scriptNodesStates.addEvent(
          new NodeChangedMessage(
            scriptPosition.nodePath,
            scriptPosition.nodeHeight,
            scriptPosition.nodeChildrenTop,
            scriptPosition.nodeChildrenHeight,
            scriptPosition.position
          )
        );
      }

      currentNodePathRef.current = currentNodePath;
    }

    //
    // Save our current node state! We want to do this before we call
    // appController.dispatchMessage() below so that the node state is available for decorating
    // app messages with current state information.
    //
    // We only want to setScriptNodesState() when some nodeEvents were emitted!
    // If we did not have any nodeEvents emitted, then no state changed and we want to avoid unnecessary changes.
    //
    const results: IPrompterPosition = {
      cuePosition: viewportInfo.viewportCuePosition,
      scriptPosition,
      // nodesState: (nodeEvents?.length) ? scriptNodesStates.nodeStates : scriptNodesState?.nodesState || scriptNodesStates.nodeStates,
      nodesState: scriptNodesStates.nodeStates,
    };
    setScriptNodesState(viewportInfo.viewportTop, results);

    //
    // Dispatch any app messages that were created while evaluating any change in node states.
    //
    appController.dispatchMessages(scriptNodesStates.nodeEvents);

    // console.log('END doRecalculateSegmentPositions!', scriptNodesState);
    return;
  }, []); // END doRecalculateSegmentPositions()

  //
  // Anytime our prompter content or window is resized, we will want to measure our script nodes
  // and then update the script nodes state reflecting there they are (before, on, after cue
  // position, etc)
  //
  // Note: we want to be sure we finished measuring the script nodes, before we begin checking
  // their positions (using their measurements).
  //
  const onPrompterContentResized = useCallback(debounce((/*e?: ResizeObserverEntry*/) => {
    // Measure the size of each script node.
    doMeasureScriptNodes();

    // Recalculate segment positions relative to prompter scroll position and dimensions.
    doRecalculateSegmentPositions();
  }, 100, { leading: false, trailing: true}), [prompterContentRef, editor]);  // END onPrompterContentResized()

  //
  // When our prompter content <div> element is resized (ie: changed the font size, margins, or
  // prompter content).
  //
  useResizeObserver(prompterContentRef, onPrompterContentResized);

  //
  // When the browser window is resized.
  //
  useEffect(() => {
    window.addEventListener('resize', onPrompterContentResized);

    return () => {
      window.removeEventListener('resize', onPrompterContentResized);
    };
  }, [onPrompterContentResized]);

  //
  // If the user changes the position of the focus guide, we need to recalculate our script nodes
  // positions.
  //
  useEffect(() => {
    const unsubscribe = useConfigurationStore.subscribe(
      (config, previousConfig) => {
        // console.log('PrompterConfig changed.')
        if(config.focusGuide !== previousConfig.focusGuide) {
          // console.log('PrompterConfig.focusGuide changed.')
          //
          // The focus guide CSS animates changes in position over 500ms. We don't want to
          // recalculate our segment positions until the animation is done.
          //
          // This delay may not actually be necessary if we already know where the focus guide will
          // be.
          //
          window.setTimeout(doRecalculateSegmentPositions, 550);
        }
      }
    );

    return unsubscribe;
  }, [doRecalculateSegmentPositions]);

  //
  // Export any functions from this hook that may be used elsewhere in the editor logic such as
  // when opening or editing a script.
  //
  return {
    doMeasureScriptNodes,
    doRecalculateSegmentPositions,
  };
}

export default usePrompterContentMeasurements;