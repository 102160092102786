import React, { useCallback, useEffect } from 'react';

import { Descendant, Path } from 'slate';
import { PrompterElement, PrompterText } from '../../models/EditorTypes';
import ISegmentMeasurements from '../../models/segments/ISegmentMeasurements';

import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import throttle from 'lodash/throttle';

interface useEditorTextMetricsExports {
  calculateTextMetrics: (doc: Descendant[], wordLimit: number | undefined, parentNodePath?: Path, parentAccumulator?: ISegmentMeasurements) => ISegmentMeasurements;
  requestUpdateTextMetrics: (doc: Descendant[], wordLimit: number | undefined) => ISegmentMeasurements | undefined;
}

const useEditorTextMetrics = (): useEditorTextMetricsExports => {

  const calculateTextMetrics = (doc: Descendant[], wordLimit: number | undefined, parentNodePath: Path = [], parentAccumulator?: ISegmentMeasurements): ISegmentMeasurements => {
    const depth = parentNodePath.length;
    const spacerText = '    '.repeat(depth);

    const accumulator = {
      nodePath: parentNodePath,
      document_characters: parentAccumulator?.document_characters || 0,
      document_words: parentAccumulator?.document_words || 0,
      characters: 0,
      words: 0,
      children: [] as ISegmentMeasurements[],
    } as ISegmentMeasurements;

    //accumulator.children = doc.map((node, ndex, array) => {
    for(let i = 0; i < doc.length; i++) {
      const node = doc[i];
      const currentNodePath = [...parentNodePath, i];
      //
      const prompterText = node as PrompterText;
      const prompterElement = node as PrompterElement;

      //
      // If this node has children, it is a container element such as a paragraph or script
      // segment.
      //
      const children = prompterElement.children as Descendant[];
      if(children) {
        // console.log(`${spacerText}BEGIN Ancestor Node (${prompterElement.type}), Children: ${children.length}`);

        const childStats = calculateTextMetrics(children, wordLimit, currentNodePath, accumulator);

        const childStatsTotal = {
          ...childStats,
          nodePath: currentNodePath,
          // document_characters: accumulator.document_characters + childStats.characters,
          // document_words: accumulator.document_words + childStats.words,
          characters: childStats.document_characters - accumulator.document_characters,
          words: childStats.document_words - accumulator.document_words,
        };

        accumulator.document_characters = accumulator.document_characters + childStatsTotal.characters;
        const document_words_before = accumulator.document_words;
        accumulator.document_words = accumulator.document_words + childStatsTotal.words;
        if(
          wordLimit
            && !accumulator.wordLimitNodePath
            && accumulator.document_words >= wordLimit
            && document_words_before < wordLimit
        ) {
          // This is the first node to exceed the word limit.
          // Let's save the node path to help us position the WordLimitNotice component.
          childStatsTotal.wordLimitNodePath = currentNodePath;
          accumulator.wordLimitNodePath = currentNodePath;
          if(parentAccumulator) {
            parentAccumulator.wordLimitNodePath = currentNodePath;
          }
        }

        accumulator.characters += childStatsTotal.characters;
        accumulator.words += childStatsTotal.words;

        // console.log(`${spacerText}END Ancestor Node (${prompterElement.type}), document_words: ${accumulator.document_words}, Words: ${childStatsTotal.words}, Characters: ${childStatsTotal.characters}`, childStatsTotal);

        // return childStatsTotal;
        accumulator.children.push(childStatsTotal);

        continue;
      }

      //
      // If this node didn't have any children above, then it is a leaf node.
      //
      const segmentText = prompterText.text as string || '';
      if(segmentText) {
        const characters = segmentText.length;
        const words = segmentText.trim().split(/\S+/).length - 1;

        accumulator.document_characters = accumulator.document_characters + characters;
        accumulator.document_words = accumulator.document_words + words;

        const leafStats = {
          document_characters: accumulator.document_characters,
          document_words: accumulator.document_words,
          characters,
          words,
        } as ISegmentMeasurements;
        // console.log(`${spacerText}Leaf Node, Chars: ${characters}, Words: ${words}`, leafStats);
        accumulator.children.push(leafStats);
      }


      // return leafStats;
    // });
    } // END for() loop

    //
    // After looping over all children, update any calculated stats derived from word count.
    //
    const speakingWordsPerMinute = 150; // TODO: This can come from user profile and be customized by user.
    accumulator.estimatedLength = Math.ceil(accumulator.words * 60 / speakingWordsPerMinute);

    //
    // If we are finishing the root function call in the tree, then let's save our data
    //
    if(depth === 0) {
      // console.log('Final accumulator:', accumulator);

      // Minor optimization to not change reference equality when the text metrics didn't really
      // change. Reduces unnecessary state updates for components dependent on this state.
      const currentState = usePrompterSession.getState();
      if(!shallow(currentState.textMetrics, accumulator)) {
        currentState.setTextMetrics(accumulator);
      }
    }

    return accumulator;
  };

  // const calculateTextMetricsDebounced = useCallback(debounce((doc) => calculateTextMetrics(doc), 500, { leading: false, trailing: true }), [calculateTextMetrics]);

  const requestUpdateTextMetrics = useCallback(throttle((doc: Descendant[], wordLimit: number | undefined) => calculateTextMetrics(doc, wordLimit), 500, { leading: false, trailing: true }), [calculateTextMetrics]);

  //
  // On initial window load, calculate the text metrics for the existing script.
  // Also when our `wordLimit` changes, recalculate text metrics. The user probably signed in or out.
  //
  const wordLimit = usePrompterSession(state => state.wordLimit);
  useEffect(() => {
    const { scriptNodes } = usePrompterSession.getState();
    requestUpdateTextMetrics(scriptNodes, wordLimit);
  }, [wordLimit]);

  return {
    calculateTextMetrics,
    requestUpdateTextMetrics,
  };
};

export default useEditorTextMetrics;