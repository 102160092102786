import { useRef, useLayoutEffect, useReducer, Dispatch } from 'react';
import { IApplicationContextState, IReducerAction, ListenerType, ReducerActionTypes } from './hookTypes';
import { reducerFunction } from './reducerFunction';
import { shallow } from 'zustand/shallow';

export const useApplicationContextReducer = (
  globalUserState: IApplicationContextState, 
  setGlobalUserState: (globalUserState: IApplicationContextState) => void,
  listeners: Set<ListenerType>,
): [IApplicationContextState, Dispatch<IReducerAction>] => {
  const [appContext, dispatch] = useReducer(reducerFunction, globalUserState);

  //
  // If this instance of the hook has changed our state, propogate to global state.
  // This happens when `dispatch()` is called within this reducer instance.
  //
  const lastappContext = useRef(appContext);    
  useLayoutEffect(() => {
    // Only use setApplicationContextState for real changes made by reducer action on this 
    // hook instance, not initial loading of this hook.
    if(appContext === lastappContext.current) {
      return;
    }

    // Let's make a custom equality checker that compares the userprofile more deeply. 
    // This prevents unnecessary state updates when set to the same values.
    //
    // Some reasons why a state update may result in the same next state is for example when React 
    // fires useEffect twice in strict mode development builds resulting in fetching the same 
    // userProfile with two network requests.
    if(globalUserState.networkOnline === appContext.networkOnline
      && globalUserState.internetOnline === appContext.internetOnline
      && globalUserState.isLoading === appContext.isLoading
      && globalUserState.isAuthenticated === appContext.isAuthenticated
      && shallow(globalUserState.userProfile, appContext.userProfile)
      && shallow(globalUserState.accountSetupRequired, appContext.accountSetupRequired))
    {
      return;
    }

    // Update our global state variable.
    setGlobalUserState(appContext);

    // Notify all other instances of the hook about this change.
    listeners.forEach(listener => listener(appContext));

    // Save a ref to our last saved state.
    lastappContext.current = appContext;
  }, [globalUserState, setGlobalUserState, listeners, appContext]);

  //
  // We register a listener for state changes that may occur in other instances of this hook.
  //
  useLayoutEffect(() => {
    const listener = (state: IApplicationContextState) => {

      dispatch({
        type: ReducerActionTypes.replaceState,
        payload: state,
      });
    };
    listeners.add(listener);
    listener(globalUserState); // in case it's already changed

    // cleanup function to remove listener
    return () => {
      listeners.delete(listener);
    };
  }, [globalUserState, listeners]);

  return [appContext, dispatch];
};

export default useApplicationContextReducer;