export interface AnalogInputStateMachineConfigInput {
  name: string;

  sourceMinimum?: number;
  sourceMaximum?: number;

  neutralMinimum?: number;
  neutralMaximum?: number;

  outputMinimum?: number;
  outputMaximum?: number;
  roundOutput?: boolean;
}

export interface AnalogInputStateMachineConfig {
  name: string;

  sourceMinimum: number;
  sourceMaximum: number;

  neutralMinimum: number;
  neutralMaximum: number;

  outputMinimum: number;
  outputMaximum: number;
  roundOutput?: boolean;
}

const defaultAnalogInputStateMachineConfig: AnalogInputStateMachineConfig = {
  name: 'default',
  sourceMinimum: 0,
  sourceMaximum: 1,
  neutralMinimum: 0,
  neutralMaximum: 0.01,
  outputMinimum: 0,
  outputMaximum: 32767,
};

type AnalogInputCallback = (valueChanged: AnalogInputChangedEventArgs) => void;

export class AnalogInputChangedEventArgs extends Event {
  name: string;
  min: number;
  max: number;
  value: number;
  previousValue: number;

  constructor(config: AnalogInputStateMachineConfig, analogValue: number, previousValue: number) {
    super('AnalogInputChangedEventArgs', {
      bubbles: true,
      cancelable: true,
      composed: false,
    });
  
    this.name = config.name;
    this.min = config.outputMinimum;
    this.max = config.outputMaximum;
    this.value = analogValue;
    this.previousValue = previousValue;
  }
}

class AnalogInputStateMachine {
  private _config: AnalogInputStateMachineConfig;
  private _callback: AnalogInputCallback;
  private _lastScaledValue: number;

  constructor(config: AnalogInputStateMachineConfigInput, callback: AnalogInputCallback) {
    const thisConfig = Object.assign({}, defaultAnalogInputStateMachineConfig);
    this._config = Object.assign(thisConfig, config);
    this._callback = callback;

    this._lastScaledValue = 0;
  }

  processState(nextInputValue: number) {
    //
    // Anything between this._config.neutralMinimum and this._config.neutralMaximum will be 
    // considered a zero value. This allows us to tune out small jitters at the end position.
    //
    let normalizedValue = nextInputValue;
    if(nextInputValue >= this._config.neutralMinimum && nextInputValue <= this._config.neutralMaximum) {
      normalizedValue = 0;
    }

    //
    // We are going to scale our real analog input range to our desired output range.
    //
    // this._config.sourceMinimum < nextInputState < this._config.sourceMaximum
    // this._config.outputMinimum < scaledValue < this._config.outputMaximum
    // scaled output = nextInputState * this._config.outputMaximum / this._config.sourceMaximum;
    //
    // const scaledValue = Math.abs((normalizedValue - this._config.sourceMinimum) * this._config.outputMaximum / this._config.sourceMaximum) + this._config.outputMinimum;
    const scaledValue = ((normalizedValue - this._config.sourceMinimum) * this._config.outputMaximum / this._config.sourceMaximum) + this._config.outputMinimum;

    const roundedValue = this._config.roundOutput ? Math.round(scaledValue) : scaledValue;

    //
    // Avoid firing input event's if the analog input didn't materially change. This check is done 
    // after scaling/rounding to allow for micro-changes that are not intentional human input.
    //
    if(roundedValue === this._lastScaledValue) {
      return;
    }

    // if(this._config.name === 'axes4') { console.log(`Axes source value ${normalizedValue}`); }

    const eventArgs = new AnalogInputChangedEventArgs(this._config, roundedValue, this._lastScaledValue);
    this._lastScaledValue = roundedValue;
    this._callback(eventArgs);
  }
}

export default AnalogInputStateMachine;