import React from 'react';
import Collapse from '@mui/material/Collapse';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import PreviewIcon from '@mui/icons-material/Preview';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

import { useNavigate } from 'react-router-dom';
import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

interface ScriptMenuProps {
  expanded: boolean;
  onExpand: () => void;
}
const ViewMenu = (props: ScriptMenuProps) => {
  const navigate = useNavigate();

  const prompterSession = usePrompterSession(state => ({
    prompterId: state.prompterId,
  }), shallow);

  //
  // Compose the link.
  //
  const buildUrl = (linkType: string) => {
    const currentUrl = window.location.protocol + '//' + window.location.host + '/';  // window.location.pathname
    const remoteUrl = new URL(currentUrl);
    switch(linkType) {
      case 'prompter':
        remoteUrl.pathname = '/';
        remoteUrl.searchParams.set('prompterId', prompterSession.prompterId);
        break;
      case 'viewer':
        remoteUrl.pathname = `/viewer/${prompterSession.prompterId}`;
        break;
      case 'remote':
        remoteUrl.pathname = `/remote/${prompterSession.prompterId}`;
        break;
    }

    // return remoteUrl.href;
    return {
      pathname: remoteUrl.pathname,
      search: remoteUrl.search,
      hash: remoteUrl.hash,
    };
  };

  return (
    <>
      <ListItemButton
        selected={props.expanded}
        onClick={() => {
          props.onExpand();
        }}
      >
        <ListItemIcon><PreviewIcon /></ListItemIcon>
        <ListItemText
          primary="Change View"
        />
        {props.expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{
          pb: 2,
          background: 'rgba(25, 118, 210, 0.08)',
        }}
        in={!!props.expanded} timeout="auto"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              // bus.emit('prompter.local.prompterview');
              // window.location.href = buildUrl('prompter');
              navigate(buildUrl('prompter'));
            }}
          >
            <ListItemIcon><OndemandVideoIcon /></ListItemIcon>
            <ListItemText
              primary="Prompter"
              secondary="Full Prompter app including edit and control features."
            />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              // bus.emit('prompter.local.remoteview');
              // window.location.href = buildUrl('viewer');
              navigate(buildUrl('viewer'));
            }}
          >
            <ListItemIcon><PreviewIcon /></ListItemIcon>
            <ListItemText
              primary="Read-Only Viewer"
              secondary="Reduced user interface. Runs well on lower power devices."
            />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              // bus.emit('prompter.local.remoteview');
              // window.location.href = buildUrl('remote');
              navigate(buildUrl('remote'));
            }}
          >
            <ListItemIcon><SettingsRemoteIcon /></ListItemIcon>
            <ListItemText
              primary="Remote"
              secondary="Control prompter without showing prompter content. Good for small screens or automation."
            />
          </ListItemButton>
          {/*<ListItemButton
            sx={{ pl: 4 }}
            onClick={() => { }}
          >
            <ListItemIcon><FileDownloadIcon /></ListItemIcon>
            <ListItemText primary="" />
          </ListItemButton>*/}
        </List>
      </Collapse>
    </>
  );
};

export default ViewMenu;