import { useCallback } from 'react';
import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { useMessageHandler, MessageHandlerEvent } from '../../controllers/AppController';
import { IViewportFuncs } from './usePrompterViewportFuncs';
import { EndpointRole, SetReverseMessage } from '@fluidprompter/core';

function usePrompterSetReverseHandler(viewportFuncs: IViewportFuncs) {

  const doSetReverse = useCallback(async function (e: MessageHandlerEvent<SetReverseMessage>) {
    const { message, originatedRemotely } = e;
    const { operation, sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);

    e.sendToPeers = !originatedRemotely || (senderIsRemote && isLeader);
    if(e.sendToPeers && sender) {
      // If we are sending to peers, we are currently the leader acting on the requested operation.
      switch(operation) {
        case SetReverseMessage.Operation.Toggle:
          sender.scrollReversed = !useConfigurationStore.getState().scrollReversed;
          break;
        case SetReverseMessage.Operation.Enable:
          sender.scrollReversed = true;
          break;
        case SetReverseMessage.Operation.Disable:
          sender.scrollReversed = false;
          break;
      }
    }

    viewportFuncs.queueSequentialTask(async () => {
      if(
        originatedRemotely
        && !isLeader
        && !senderIsRemote
      ) {
        //
        // Apply the remote prompter script position to the local prompter.
        //
        e.syncScrollPosition();

        //
        // Apply the remote prompter scroll speed to the local prompter.
        //
        e.syncScrollSpeed();
      }

      const configState = useConfigurationStore.getState();
      const requestReversed = (sender?.scrollReversed === true);
      if(configState.scrollReversed !== requestReversed) {
        configState.setScrollReversed(requestReversed);
      }
    });

  }, []);
  useMessageHandler('setreverse', doSetReverse);
}

export default usePrompterSetReverseHandler;