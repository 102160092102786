import * as React from 'react';
import useApplicationContext from '../hooks/useApplicationContext';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function LoginPage() {

  const {
    appContext,
    loginWithRedirect,
    getApiToken,
  } = useApplicationContext(false);

  React.useEffect(() => {
    //
    // Wait for our appContext to finish loading (checking if we are logged in, etc)
    //
    if(appContext.isLoading) {
      return;
    }

    const returnUrl = new URL(window.location.href);
    returnUrl.pathname = '';
    returnUrl.search = '';
    returnUrl.hash = '';
    const finalUrl = returnUrl.toString();
    returnUrl.pathname = '/auth/';

    //
    // If we are logged in, then redirect the user to our feedback portal.
    //
    if(appContext.isAuthenticated) {
      window.location.href = finalUrl;
      return;
    }

    //
    // If we are not yet logged in, then redirect user to login now.
    //
    try {
      loginWithRedirect({
        appState: {
          returnTo: finalUrl,
        },
        authorizationParams: {
          screen_hint: 'login',

          /**
           * The URL where Auth0 will redirect your browser to with
           * the authentication result. It must be whitelisted in
           * the "Allowed Callback URLs" field in your Auth0 Application's
           * settings.
           */
          redirect_uri: returnUrl.toString(),
        },
      });
    } catch(err) {
      //
      // This occurs when the Auth0Provider is not present or misconfigured.
      // The Auth0Provider will not be loaded in a non-secure context outside of localhost.
      //
      alert('Unable to login. Please contact support.');
      window.location.href = finalUrl;
    }
  }, [appContext, loginWithRedirect, getApiToken]);

  return (
    <Backdrop open={true}>
      <CircularProgress color="inherit" />
      <span style={{
        marginLeft: '16px'
      }}>Loading...</span>
    </Backdrop>
  );
}

export default LoginPage;