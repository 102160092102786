import { BaseControlMessage } from './BaseControlMessage';
export const SETLEADERMESSAGE_KEY = 'setleader';
/**
 * Represents the SetLeaderMessage type.
 */
export class SetLeaderMessage extends BaseControlMessage {
    constructor(endpointId) {
        super();
        this.type = SETLEADERMESSAGE_KEY;
        this.endpointId = endpointId;
    }
    static fromJson(data) {
        if (data.type !== SETLEADERMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for SetLeaderMessage.');
        }
        const input = data;
        const instance = new SetLeaderMessage(input.endpointId);
        instance.deserializeSenderInfo(data.sender);
        instance.endpointId = input.endpointId;
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            endpointId: this.endpointId,
        };
    }
}
SetLeaderMessage.MESSAGE_NAME = SETLEADERMESSAGE_KEY;
