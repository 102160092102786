import BaseDevice from '../BaseDevice';
import { BaseDeviceEvent } from './BaseDeviceEvent';

export class DeviceConnectingEvent
  extends BaseDeviceEvent<Event>
{
  readonly type = 'DeviceConnectingEvent';

  constructor(
    device: BaseDevice, 
  ) {
    super(device, new Event('SyntheticDeviceConnectingEvent'));
  }
}