import { BaseControlMessage } from './BaseControlMessage';
export const RESETMESSAGE_KEY = 'prompter.state.reset';
/**
 * Represents the ResetMessage type.
 */
export class ResetMessage extends BaseControlMessage {
    constructor(skipConfirm) {
        super();
        this.type = RESETMESSAGE_KEY;
        this.skipConfirm = (skipConfirm === true);
    }
    static fromJson(data) {
        const instance = new ResetMessage(data.skipConfirm);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            skipConfirm: this.skipConfirm,
        };
    }
}
ResetMessage.MESSAGE_NAME = RESETMESSAGE_KEY;
