/**
 * Represents the PageDownMessage type.
 */
export class PageDownMessage {
    constructor() {
        this.type = PageDownMessage.MESSAGE_NAME;
    }
    static fromJson() {
        return new PageDownMessage();
    }
    toJSON() {
        return { type: this.type };
    }
}
PageDownMessage.MESSAGE_NAME = 'prompter.navigate.pagedown';
