import { useRef } from 'react';
import { useMessageHandler } from '../../controllers/AppController';
import {
  AppLifecycleState,
  ConnectionState,
  EndpointRole,
  PeerPongMessage,
  SetLeaderMessage,
} from '@fluidprompter/core';

import DeviceHost from '../DeviceHost';
import { DeviceConnectionType } from '../BaseDevice';

import PrompterPeerInstance from '../prompterpeer/PrompterPeerInstance';

import logger from '../../utils/Logger';

/**
 * Implement handlers for messages intended to affect a single connected peer's configuration or
 * state (vs session control messages that cause all peers to change state for Play/Pause/Stop).
 * @param deviceHost
 */
function usePeerControlMessages(deviceHost: DeviceHost) {

  const log = logger.child({
    childName: 'usePeerControlMessages'
  });
  log.trace('usePeerControlMessages() constructor');

  const appLifecycleStateRef = useRef<AppLifecycleState>(deviceHost.appController.appLifecycleState);
  useMessageHandler('lifecycle', (e) => {
    const { message } = e;
    const { sender, lifecycleState } = message;

    const previousLifecycleState = appLifecycleStateRef.current;
    appLifecycleStateRef.current = lifecycleState;

    //
    // If this lifecycle message originated locally, we want to send it to peers.
    // Also if we have transitioned from hidden to visible, let's check on our peer connections.
    //
    if(!e.originatedRemotely) {
      e.sendToPeers = true;

      /*
      //
      // If the websocket is not currently connected, the peer connections will be reviewed after
      // the websocket reconencts and receives a `connect.response` message from the backend.
      //
      // Note when you close the lid on a macbook it often disconnects the RTCPeerConnections but
      // might leave the WebSocket connected depending on power state (plugged in vs battery, etc).
      //
      if(
        deviceHost.appController.websocketConnectionState === ConnectionState.Connected
          && previousLifecycleState === AppLifecycleState.Hidden
          && lifecycleState !== AppLifecycleState.Hidden
      ) {
        // We are transitioning from hidden to a visible state.
        console.log(`${new Date().toLocaleString()} Lifecycle transitioning from ${previousLifecycleState} to ${lifecycleState}. Check peer connections.`);

        // Let's check on our peer connections.
        deviceHost.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
          .forEach(prompterPeer => prompterPeer.connect());
      }
      */
      return;
    }

    // console.log(`Received LifecycleMessage sender.id: ${e.message.sender?.id} state ${e.message.lifecycleState}`);

    //
    // If this lifecycle message originated remotely, then we should update our understanding of
    // the remote peer's current state.
    //
    const lifecyclePeer = deviceHost.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .find((device) => device.endpointId === sender?.id);
    if(!lifecyclePeer) {
      // Received a lifecycle event for a peer we have no record of.
      return;
    }

    lifecyclePeer.appLifecycleState = message.lifecycleState;

    //
    // We don't want a hidden prompter to remain the leader as it might be about to disconnect.
    //
    /*
    if(lifecyclePeer.isLeader && lifecyclePeer.appLifecycleState === 'hidden') {
      const newLeader = deviceHost.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
        .filter((peer) => peer.endpointRole === EndpointRole.Prompter
          && peer.appLifecycleState !== AppLifecycleState.Hidden
          && (peer.representsLocal || peer.connectionState === ConnectionState.Connected))
        .sort((a: PrompterPeerInstance, b: PrompterPeerInstance) => {
          return (a.lastLeaderTimestamp === b.lastLeaderTimestamp) ? 0
            : (a.lastLeaderTimestamp < b.lastLeaderTimestamp) ? -1 : 1;
        })
        .find((device) => device.endpointId !== lifecyclePeer.endpointId);

      if(newLeader) {
        // console.log(`Successor leader '${newLeader?.endpointId}' should take over as leader.`);
        e.dispatchMessage(new SetLeaderMessage(newLeader.id));
      }
    } // End nominate new leader
    */
  });

  /**
   * When we recieve a ping from another peer, respond with a 'peer.pong'
   */
  useMessageHandler('peer.ping', (e) => {
    const { targetId, sender } = e.message;
    if(!e.originatedRemotely) {
      log.trace('usePeerControlMessages() forwarding \'peer.ping\' message to peers');
      e.sendToPeers = true;
      return;
    }
    if(
      !sender
      || targetId !== deviceHost.appController.localEndpointId
    ) {
      log.trace(`usePeerControlMessages() ignoring 'peer.ping' message (sender undefined = ${sender === undefined} || ${targetId} !== ${deviceHost.appController.localEndpointId})`);
      return;
    }

    log.trace(`usePeerControlMessages() sending 'peer.ping' message to ${sender.id}`);

    e.dispatchMessage(new PeerPongMessage(targetId));
  });

  /**
   * When we receive a pong from another peer, find the PrompterPeerInstance that sent the ping and inform it of our response.
   */
  useMessageHandler('peer.pong', (e) => {
    const { targetId } = e.message;
    if(!e.originatedRemotely) {
      log.trace('usePeerControlMessages() forwarding \'peer.pong\' message to peers');
      e.sendToPeers = true;
      return;
    }

    log.trace({
      msg: e.message,
    }, 'usePeerControlMessages() received \'peer.pong\' message');

    deviceHost.appController.resolveEndpointIdIsConnectedToCloud(targetId, true);
  });

  /**
   * When we receive a peer.missing from the server, it means the PrompterPeerInstance that we tried to ping does not currently have a websocket connection with the server.
   */
  useMessageHandler('peer.missing', (e) => {
    const { targetId } = e.message;

    log.trace({
      msg: e.message,
    }, 'usePeerControlMessages() received \'peer.missing\' message');

    deviceHost.appController.resolveEndpointIdIsConnectedToCloud(targetId, false);
  });

}

export default usePeerControlMessages;