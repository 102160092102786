import { PrompterText } from '../../models/EditorTypes';
import Stack from '../Stack';

/**
 * Slate represents text with nested marks as a flat collection of text nodes where multiple 
 * sequential nodes may share in common one or more marks (but not all).
 * 
 * THIS DATA:
 * [
 *   { text: 'The', bold: true },
 *   { text: ' ' },
 *   { text: 'quick', italic: true },
 *   { text: ' ' },
 *   { text: 'brown', underline: true },
 *   { text: ' fox ' },
 *   { text: 'jumped ', underline: true },
 *   { text: 'over ', underline: true, bold: true },
 *   { text: 'the', underline: true, bold: true, italic: true },
 *   { text: ' lazy', underline: true, bold: true },
 *   { text: ' dog.', underline: true }
 * ] 
 * 
 * SHOULD BECOME: (note the italics, nested inside a bold, nested inside an underline)
 *   **The** *quick* [U]brown[/U] fox [U]jumped **over *the* lazy** dog.[/U]
 * 
 * AND SHOULD *NOT* BE:
 *   **The** *quick* [U]brown[/U] fox [U]jumped [/U][U]**over **[/U][U]***the***[/U][U]** lazy**[/U][U] dog.[/U]
 * 
 */
class MarkedTextSerializer {
  private _activeMarks = new Stack();

  private processStartMark(node: PrompterText, markName: string, markSymbol: string): string {
    if(node[markName] && this._activeMarks.indexOf(markName) < 0) {
      // We are transitioning to begin a bold mark.
      this._activeMarks.push(markName);
      return markSymbol;
    }

    return '';
  }

  private processStartMarks(node: PrompterText): string {
    let startMarks = '';

    startMarks += this.processStartMark(node, 'bold', '**');
    startMarks += this.processStartMark(node, 'italic', '*'); // Could be 1 asterisk or 1 underscore
    startMarks += this.processStartMark(node, 'underline', '[U]'); // Discord uses two-underscores __ for underline!
    startMarks += this.processStartMark(node, 'strike', '~~');
    startMarks += this.processStartMark(node, 'highlight', '==');

    return startMarks;
  }

  private processEndMark(node: PrompterText, markName: string, markSymbol: string): string {
    if(!node[markName] && this._activeMarks.peek() === markName) {
      // We are transitioning to begin a bold mark.
      this._activeMarks.pop();
      return markSymbol;
    }

    return '';
  }
  private processEndMarks(node: PrompterText): string {
    let endMarks = '';

    endMarks += this.processEndMark(node, 'underline', '[/U]'); // Discord uses two-underscores __ for underline!
    endMarks += this.processEndMark(node, 'italic', '*'); // Could be 1 asterisk or 1 underscore
    endMarks += this.processEndMark(node, 'bold', '**');
    endMarks += this.processEndMark(node, 'strike', '~~');
    endMarks += this.processEndMark(node, 'highlight', '==');

    return endMarks;
  }

  processNode(node: PrompterText) {
    const endMarks = this.processEndMarks(node);
    const startMarks = this.processStartMarks(node);
    return `${endMarks}${startMarks}${node.text}`;
  }

  /**
   * Called at the end of a collection of children nodes that were processed to close off any open marks.
   * @returns 
   */
  closeOpenMarks(input: string) {
    const endMarks = this.processEndMarks({ text: '' });
    return `${input}${endMarks}`;
  }
}

export default MarkedTextSerializer;