import React from 'react';
import { DefaultElement, RenderElementProps } from 'slate-react';

import StartElement from '../StartElement';
import SegmentElement from '../SegmentElement';
import ParagraphElement from '../ParagraphElement';
import EndElement from '../EndElement';
import PauseElement from '../PauseElement';
import ImageElement from '../ImageElement';
import HyperlinkElement from '../HyperlinkElement';
import CodeBlockElement from '../CodeBlockElement';
import BRollElement from '../BRollElement';

import { ElementTypes } from '../../../models/EditorTypes';

function renderElement(props: RenderElementProps) {
  const { element, children, attributes } = props;

  switch (element.type) {
    case ElementTypes.STARTSCRIPT:
      return (<StartElement {...props} />);
    case ElementTypes.SCRIPT_SEGMENT:
      return (<SegmentElement {...props} />);
    case ElementTypes.ENDSCRIPT:
      return (<EndElement {...props} />);
    case ElementTypes.PAUSE:
      return (<PauseElement {...props} />);
    case ElementTypes.PARAGRAPH:
      return (<ParagraphElement {...props} />);
    case ElementTypes.HYPERLINK:
      return (<HyperlinkElement {...props} />);
    case ElementTypes.CODE_BLOCK:
      return (<CodeBlockElement {...props} />);
    case ElementTypes.BROLL:
      return (<BRollElement {...props} />);
    /*
    case 'h1':
      return (
        <h1 {...attributes}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 {...attributes} content-editable={'true'}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 {...attributes} content-editable={'true'}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 {...attributes} content-editable={'true'}>
          {children}
        </h4>
      );
    */
    case ElementTypes.IMAGE:
      return <ImageElement {...props} />;
    default:
      return <DefaultElement {...props} />;
  }
}

export default renderElement;