import { BaseControlMessage } from '@fluidprompter/core';

interface IMessageTime {
  time: number,
  msgId: string,
}

export class MessageDeduplicator {
  private _cacheTime: number;
  private _recentMessages: IMessageTime[];

  constructor() {
    this._cacheTime = 1000;
    this._recentMessages = [];
  }

  isDuplicateMessage<T extends BaseControlMessage>(message: T, messageSource?: string): boolean {
    const time = performance.now();
    const cutoff = time - this._cacheTime;

    //
    // TODO: Deduplicate Messages
    //
    let msgId: string | undefined;
    const { sender } = message;
    if(sender) {
      const { id, sequence } = sender;
      msgId = `${id}${sequence}`;
    }
    if(!msgId) {
      // No msgId - maybe because we are missing senderState?
      // console.error('No senderState in msg?', message);
      return false;
    }

    const existingMessage = this._recentMessages.find((msg) => msg.msgId === msgId);
    if(existingMessage) {
      const delta = Math.round(time - existingMessage.time);
      // We found a duplicate!
      // console.log(`Duplicate message received via ${messageSource} ${delta}ms later`, message);
      return true;
    }

    // Filter out old messages in the buffer.
    const messagesStillFresh = this._recentMessages.filter((entry) => { return entry.time > cutoff; });

    // Add the current message to the list of recent messages.
    this._recentMessages = [
      ...messagesStillFresh,
      {
        time,
        msgId: msgId,
      }
    ];

    return false;
  }
}

export default MessageDeduplicator;