import React from 'react';
import { RenderElementProps, RenderLeafProps, useFocused, useSelected } from 'slate-react';

import './KbdElement.scss';

function KbdElement(props: RenderLeafProps) {
  const { attributes, children, leaf } = props;

  const selected = useSelected();  // Get the current selected state of an element.
  const focused = useFocused();    // Get the current focused state of the editor.

  return (
    <span
      className="kbdshortcut"
      contentEditable={false} 
      {...attributes}
      style={{
        outline: (selected && focused) ? '0 0 0 3px #B4D5FF' : 'none'
      }}
    ><kbd>{children}</kbd></span>
  );
}

export default KbdElement;