import { IConnectedDevicesSlice } from '../../state/PrompterSessionState/ConnectedDevicesSlice';
import AppController from './AppController';
import { MessageContext } from './MessageContext';
import { BaseControlMessage, EndpointRole, GenericMessage, SetLeaderMessage } from '@fluidprompter/core';

export class MessageHandlerEvent<T extends BaseControlMessage> {
  constructor(message: T, context: MessageContext, appController: AppController) {
    this.message = message;
    this.context = context;
    this.appController = appController;
  }

  message: T;
  context: MessageContext;
  private appController: AppController;

  public get originatedRemotely() {
    return this.context.originatedRemotely;
  }

  public get sendToPeers() {
    return this.message.sendToPeers === true;
  }
  public set sendToPeers(sendToPeers: boolean) {
    this.message.sendToPeers = sendToPeers;
  }

  // Overload signatures
  dispatchMessage<T extends BaseControlMessage>(message: T): void;
  dispatchMessage(eventType: string, payload?: unknown): void;

  // Implementation signature
  dispatchMessage(input: BaseControlMessage | string, payload?: unknown) {
    const message = (typeof input === 'string') ? new GenericMessage(input, payload) : input;
    // console.log(`dispatchMessage(${message.type})`);

    this.dispatchMessages([message]);
  }

  dispatchMessages(messageBatch: BaseControlMessage[]) {
    this.context.dispatchMessages(messageBatch);
  }

  /**
   * Given the current message we are handling, determine if this prompter instance is currently
   * the leader and if there is any change in leadership occuring as a result of this message.
   *
   * When a message originates remotely from another prompter, that prompter will be designated
   * the current leader.
   *
   * @param prompterSession A fresh copy of our prompterSession state.
   * @returns true if this prompter instance is the current leader, otherwise false.
   */
  checkIAmLeader(prompterSession: IConnectedDevicesSlice): boolean {
    const { sender } = this.message;

    let isLeader = prompterSession.isLeader;
    if(sender?.role === EndpointRole.Prompter) {
      isLeader = !this.originatedRemotely;

      // If the current leader is changing, we need to update our prompterPeerInstances.
      if(prompterSession.currentLeaderId !== sender.id) {
        // console.log(`dispatchMessage(new SetLeaderMessage(${sender.id})) after receiving message type '${this.message.type}', originatedRemotely=${this.originatedRemotely}`, this.message);
        this.dispatchMessage(new SetLeaderMessage(sender.id));
      }
    }

    return isLeader;
  }

  syncScrollPosition() {
    // TODO: Defer and de-duplicate syncScrollPosition within a message batch.
    this.context.syncScrollPosition();
  }

  syncScrollSpeed() {
    // TODO: Defer and de-duplicate syncScrollSpeed within a message batch.
    this.context.syncScrollSpeed();
  }
}

export default MessageHandlerEvent;