import React, { useEffect, useRef, useState } from 'react';
import { ServiceWorkerController } from './ServiceWorkerController';

type SimpleFunction = () => void;
type ApplyUpdateCallback = (applyUpdate: SimpleFunction) => void;

const serviceWorkerSupported = 'serviceWorker' in navigator;

export interface IServiceWorkerHookState {
  updateFound: boolean;
  updateWaiting: boolean;
}
export type ListenerType = (state: IServiceWorkerHookState) => void;
export interface IServiceWorkerHookExports {
  serviceWorkerSupported: boolean;
  updateFound: boolean;
  updateWaiting: boolean;
  checkUpdate: () => Promise<boolean>;
  applyUpdate: SimpleFunction;
  serviceWorkerController: ServiceWorkerController;
}
export type GlobalServiceWorkerHook = (applyUpdateCallback?: ApplyUpdateCallback) => IServiceWorkerHookExports;

const createContainer = (): GlobalServiceWorkerHook => {
  let globalServiceWorkerState: IServiceWorkerHookState = {
    updateFound: false,
    updateWaiting: false,
  };
  const setServiceWorkerState = (newState: IServiceWorkerHookState) => {
    if(globalServiceWorkerState === newState) {
      // Don't do anything if we are being requested for the existing state.
      return;
    }

    globalServiceWorkerState = newState;

    listeners.forEach(listener => listener(globalServiceWorkerState));
  };

  const listeners = new Set<ListenerType>();

  //
  // Our main hook function
  //
  const useServiceWorker: GlobalServiceWorkerHook = (applyUpdateCallback?: ApplyUpdateCallback): IServiceWorkerHookExports => {

    const serviceWorkerController = ServiceWorkerController.current;

    // The first hook to be used in the app will be the root hook.
    // The root hook will be the only instance that calls register.
    const isRootHookRef = useRef<boolean>(listeners.size === 0);

    //
    // Prepare this hook instance's state, then sync with global state via useEffect.
    //
    const [state, setState] = useState(globalServiceWorkerState);
    useEffect(() => {
      const listener = () => {
        setState(globalServiceWorkerState);
      };
      listeners.add(listener);
      listener(); // in case it's already changed

      // cleanup
      return () => {
        listeners.delete(listener);
      };
    }, []);

    //
    // When a service worker update is found, update our state.
    // Note: when an update is found it will first go through states:
    //   installing -> waiting -> activated
    //
    const handleUpdateFound = React.useCallback(async function () {
      setServiceWorkerState({
        ...state,
        updateFound: true,
      });
    }, [setServiceWorkerState]);

    //
    // When a service worker update is found, we want to update this hook.
    //
    const handleUpdateWaiting = React.useCallback(async function () {
      setServiceWorkerState({
        ...state,
        updateWaiting: true,
      });

      if(applyUpdateCallback) {
        applyUpdateCallback(serviceWorkerController.applyUpdate);
      }
    }, [setServiceWorkerState, applyUpdateCallback, serviceWorkerController]);

    //
    // Attach event listeners to our singleton ServiceWorkerController so that we can update this
    // hook's return with current state information.
    //
    useEffect(() => {
      // If we are not the root hook, or service workers are not supported, then we don't need to
      // call register.
      if(!isRootHookRef.current || !serviceWorkerSupported) {
        return;
      }

      serviceWorkerController.addListener('updatefound', handleUpdateFound);
      serviceWorkerController.addListener('updatewaiting', handleUpdateWaiting);

      const updateInterval = window.setInterval(
        serviceWorkerController.checkUpdate,
        1000 * 60 * 10  // Check for updates every 10 minutes
      );

      // Hook clean-up function
      return () => {
        window.clearInterval(updateInterval);

        serviceWorkerController.removeListener('updatefound', handleUpdateFound);
        serviceWorkerController.removeListener('updatewaiting', handleUpdateWaiting);
      };
    }, [isRootHookRef, serviceWorkerController, handleUpdateFound, handleUpdateWaiting]);

    //
    // Kick-off the initial service worker registration.
    //
    const registerWasCalledRef = useRef<boolean>(false);
    useEffect(() => {
      // If we are not the root hook, we don't need to call register.
      if(!isRootHookRef.current || registerWasCalledRef.current) {
        return;
      }

      serviceWorkerController.register();
      registerWasCalledRef.current = true;
    }, [isRootHookRef]);

    return {
      serviceWorkerSupported,
      updateFound: state.updateFound,
      updateWaiting: state.updateWaiting,
      checkUpdate: serviceWorkerController.checkUpdate,
      applyUpdate: serviceWorkerController.applyUpdate,
      serviceWorkerController,
    };
  };  // END useServiceWorker()

  // Return our hook instance (GlobalUserStateHook).
  return useServiceWorker;
};

const singletonInstance: GlobalServiceWorkerHook = createContainer();
export default singletonInstance;