import { useState, useRef, useEffect, useCallback } from 'react';
import IVisualViewport from '../models/IVisualViewport';

interface useVisualViewportExports {
  visualViewport: IVisualViewport;
  visualViewportReduced: boolean;
}

const useVisualViewport = (): useVisualViewportExports => {

  const previousViewport = useRef<IVisualViewport | null>(null);
  const pendingViewport = useRef<IVisualViewport | null>(null);
  const pendingUpdate = useRef<boolean>(false);

  // If the visual viewport shrinks after component is initially rendered, we will set visualViewportReduced to true.
  const [visualViewportReduced, setVisualViewportReduced] = useState<boolean>(false);

  const [visualViewport, setVisualViewport] = useState<IVisualViewport>((): IVisualViewport => {
    const visualViewport2: VisualViewport | null = window.visualViewport;

    const visualViewportInitial: IVisualViewport = {
      height: visualViewport2?.height || 0,
      offsetLeft: visualViewport2?.offsetLeft || 0,
      offsetTop: visualViewport2?.offsetTop || 0,
      pageLeft: visualViewport2?.pageLeft || 0,
      pageTop: visualViewport2?.pageTop || 0,
      scale: visualViewport2?.scale || 0,
      width: visualViewport2?.width || 0,
    };

    if(!previousViewport.current) {
      previousViewport.current = visualViewportInitial;
    }

    return visualViewportInitial as IVisualViewport;
  });

  //
  // Throttle the setter using requestAnimationFrame.
  //
  const setVisualViewportThrottled = useCallback((data: IVisualViewport) => {
    //
    // We are using a "last in wins" update strategy despite throttling.
    //
    // If multiple resize/scroll events fire during the same animation frame, we want to keep the 
    // last visualViewport from the last event despite scheduling the requestAnimationFrame during 
    // the first event.
    //
    if(data) {
      pendingViewport.current = data;
    }

    //
    // If we're already going to handle an update, return
    //
    if (pendingUpdate.current) return;

    pendingUpdate.current = true;

    //
    // Use requestAnimationFrame so the update happens before next render
    //
    requestAnimationFrame(() => {
      pendingUpdate.current = false;

      //
      // Only update our useState hook if the visualViewport actually changed 
      // (another kind of debouncing if more than one event was fired for the same change in 
      // visualViewport values).
      //
      const proposedVisualViewport = pendingViewport.current;
      if(proposedVisualViewport
        && (proposedVisualViewport.offsetTop !== previousViewport.current?.offsetTop
          || proposedVisualViewport.height !== previousViewport.current?.height
        || proposedVisualViewport.scale !== previousViewport.current?.scale)
      ) {
        previousViewport.current = proposedVisualViewport;        
        setVisualViewport(proposedVisualViewport);
      }
    });
  }, [setVisualViewport]);

  useEffect(() => {
    const listener = (e: Event) => {
      const originalViewport = previousViewport.current;

      const visualViewport = e.target as VisualViewport;
      const visualViewportCopy = {
        height: visualViewport.height,
        offsetLeft: visualViewport.offsetLeft,
        offsetTop: visualViewport.offsetTop,
        pageLeft: visualViewport.pageLeft,
        pageTop: visualViewport.pageTop,
        scale: visualViewport.scale,
        width: visualViewport.width,
      };

      if(originalViewport 
          && visualViewportCopy.height < originalViewport.height
      ) {
        setVisualViewportReduced(true);
      }

      setVisualViewportThrottled(visualViewportCopy);
    };

    if('visualViewport' in window && window.visualViewport) {  // Not supported in Unit Testing Environment with no real viewport.
      window.visualViewport.addEventListener('resize', listener);
      window.visualViewport.addEventListener('scroll', listener);
    }

    return () => {
      if('visualViewport' in window && window.visualViewport) {  // Not supported in Unit Testing Environment with no real viewport.
        window.visualViewport.removeEventListener('resize', listener);
        window.visualViewport.removeEventListener('scroll', listener);
      }
    };
  }, [setVisualViewportThrottled, setVisualViewportReduced]);

  return {
    visualViewport,
    visualViewportReduced,
  };
};

export default useVisualViewport;