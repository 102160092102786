import React, { useLayoutEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ITrayItem } from './TrayContainer';
import TrayItemIconMenuItem from './TrayItemIconMenuItem';
import TrayItemIconButton from './TrayItemIconButton';

import { useTranslation } from 'react-i18next';

import NoSleepIcon from '../CustomIcons/NoSleepIcon';
import noSleep from '../../hooks/useNoSleep';

interface NoSleepTrayItemProps {
  renderAsMenuItem?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  requestCloseTray: () => void;
}

const NoSleepTrayItem = (props: NoSleepTrayItemProps) => {

  const { renderAsMenuItem } = props;

  const { t } = useTranslation('prompter');

  const [noSleepEnabled, setNoSleepEnabled] = noSleep.useNoSleepState();

  // useState will hold reference to Websocket Menu anchor element.
  const [trayMenuAnchorEl, setTrayMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const onTrayMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    // setNoSleepEnabled(true);
    setTrayMenuAnchorEl(e.currentTarget);
  };
  const onTrayMenuClose = () => {
    setTrayMenuAnchorEl(null);
  };

  useLayoutEffect(() => {
    // When renderAsMenuItem changes, we need to close any open menus.
    onTrayMenuClose();
  }, [renderAsMenuItem]);

  const trayItemProps: ITrayItem = {
    title: t('trayicons.sleep'),
    icon: (<NoSleepIcon />),
    onClick: onTrayMenuClick,
  };

  return (<>
    {noSleepEnabled && (renderAsMenuItem ? (<TrayItemIconMenuItem {...trayItemProps} />) : (<TrayItemIconButton {...trayItemProps} />))}
    <Menu
      id="nosleep-menu"
      anchorEl={trayMenuAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}        
      open={Boolean(trayMenuAnchorEl)}
      onClose={onTrayMenuClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={
        () => {
          setNoSleepEnabled(false);
          onTrayMenuClose();
          props.requestCloseTray();
        }
      }>{t('trayicons.allowsleep')}</MenuItem>
    </Menu>
  </>);
};

export default NoSleepTrayItem;