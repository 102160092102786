import React, { useState } from 'react';

import { DeviceComponent, DeviceComponentProps } from '../../BaseDevice';
import PrompterPeerUIMenu from '../../common/UI/DeviceMenu';

const PrompterPeerUI = (props: DeviceComponentProps) => {
  const { onClose } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (<>
    <PrompterPeerUIMenu {...props} setDialogOpen={setDialogOpen} />
  </>);
};

export default PrompterPeerUI;