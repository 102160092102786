import BaseDevice from '../BaseDevice';

const inputElementTags = ['input', 'select', 'button', 'textarea'];

export abstract class BaseDeviceEvent<TNativeEvent extends Event> {
  private _device: BaseDevice | undefined;
  private _isContentEditableFocused: boolean | undefined;
  private _isInputElementFocused: boolean | undefined;
  private _isEditableFocused: boolean | undefined;

  abstract readonly type: string;

  constructor(device: BaseDevice | undefined, e: TNativeEvent) {
    this._device = device;

    this._nativeEvent = e;
    this.nativeEvent = e;
  }

  public get device() {
    return this._device;
  }

  // Can our InputMiddlewareHandler decorate synthetic events with last known modifers?
  // ctrlKey
  // altKey
  // shiftKey
  // metaKey

  private _nativeEvent: TNativeEvent;
  get nativeEvent(): TNativeEvent {
    return this._nativeEvent;
  }
  protected set nativeEvent(value: TNativeEvent) {
    if(!value) {
      return;
    }

    this._defaultPrevented = value.defaultPrevented;
    this._nativeEvent = value;

    const targetHTMLElement = this._nativeEvent.target as HTMLElement;
    this._isContentEditableFocused = targetHTMLElement && targetHTMLElement.isContentEditable;
    this._isInputElementFocused = targetHTMLElement && !!targetHTMLElement.tagName && (inputElementTags.indexOf(targetHTMLElement.tagName.toLowerCase()) >= 0);
    this._isEditableFocused = this._isContentEditableFocused || this._isInputElementFocused;
  }

  get isContentEditableFocused(): boolean {
    return !!this._isContentEditableFocused;
  }
  get isEditableFocused(): boolean {
    return !!this._isEditableFocused;
  }

  private _defaultPrevented?: boolean;
  get defaultPrevented(): boolean {
    return this._defaultPrevented === true;
  }  
  preventDefault() {
    this._defaultPrevented = true;
    if(this.nativeEvent) {
      this.nativeEvent.preventDefault();
    }
  }

  private _propagationStopped?: boolean;
  get propagationStopped(): boolean {
    return this._propagationStopped === true;
  }  
  stopPropagation() {
    this._propagationStopped = true;

    if(this.nativeEvent) {
      this.nativeEvent.stopPropagation();
    }
  }
}