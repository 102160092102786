
import React, { useState } from 'react';

import { DeviceComponent, DeviceComponentProps } from '../../../BaseDevice';
import SpeedEditorMenu from './SpeedEditorMenu';
import SpeedEditorDialog from './SpeedEditorDialog';

const SpeedEditorUI: DeviceComponent = (props: DeviceComponentProps) => {
  const { onClose } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (<>
    <SpeedEditorMenu {...props} setDialogOpen={setDialogOpen} />
    {dialogOpen && <SpeedEditorDialog onClose={() => {
      // Close dialog - should we close menu too?
      setDialogOpen(false);
      onClose();
    }} />}
  </>);
};

export default SpeedEditorUI;