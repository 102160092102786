import { useEffect, useCallback } from 'react';
import { useMessageHandler } from '../../controllers/AppController';
import { IViewportRefs } from './usePrompterViewportRefs';
import { IViewportFuncs } from './usePrompterViewportFuncs';

import AHDSRStateMachine from '../../controllers/AHDSRStateMachine';

import useConfigurationStore from '../../state/ConfigurationStore';
import { shallow } from 'zustand/shallow';
import usePrompterSession from '../../state/PrompterSessionState';

const usePrompterAHDSRScrolling = (viewportRefs: IViewportRefs, viewportFuncs: IViewportFuncs) => {

  const momentaryConfig = useConfigurationStore(state => ({
    AttackGain: state.momentaryAttackGain,
    AttackMs: state.momentaryAttackTime,
    AttackEasingFunction: state.momentaryAttackFunction,
    
    HoldMs: state.momentaryHoldTime,
    
    DecayMs: state.momentaryDecayTime,
    DecayEasingFunction: state.momentaryDecayFunction,
    
    ReleaseMs: state.momentaryReleaseTime,
    ReleaseEasingFunction: state.momentaryReleaseFunction,
  }), shallow);

  useEffect(() => {
    if(!viewportRefs.ahdsrRef.current) {
      viewportRefs.ahdsrRef.current = new AHDSRStateMachine();
    }

    // Either we just created the AHDSR state machine, or the config just changed.
    // In both cases, we want to apply our current config parameters.
    viewportRefs.ahdsrRef.current.applyConfig(momentaryConfig);
  }, [viewportRefs.ahdsrRef, momentaryConfig]);

  const startADHSRScrolling = useCallback(async function (reverse?: boolean) {
    const ahdsr = viewportRefs.ahdsrRef.current;
    if(!ahdsr) {
      throw new Error('AHDSR not defined.');
    }

    const configState = useConfigurationStore.getState();
    if(reverse && !configState.scrollReversed) {
      configState.setScrollReversed(true);
    }
    if(!reverse && configState.scrollReversed) {
      configState.setScrollReversed(false);
    }

    ahdsr.trigger(reverse);
    const localPrompterSession = usePrompterSession.getState();
    if(!localPrompterSession.isPlaying) {
      localPrompterSession.play();
    }
  }, [viewportRefs.ahdsrRef]);
  useMessageHandler('prompter.state.momentaryplay.start', () => {
    viewportFuncs.queueSequentialTask(async () => {
      startADHSRScrolling();
    });
  });
  useMessageHandler('prompter.state.momentaryreverse.start', () => {
    viewportFuncs.queueSequentialTask(async () => {
      startADHSRScrolling(true);
    });
  });

  const stopADHSRScrolling = useCallback(async function (reverse?: boolean) {
    const ahdsr = viewportRefs.ahdsrRef.current;
    if(!ahdsr) {
      throw new Error('AHDSR not defined.');
    }

    ahdsr.release(reverse);
    // The prompter will transition to paused within the main animate() loop if the AHDSR value hits 0.
    // if(prompterSession.isPlaying) {
    //   prompterSession.pause();
    // }
  }, [viewportRefs.ahdsrRef]);
  useMessageHandler('prompter.state.momentaryplay.stop', () => {
    viewportFuncs.queueSequentialTask(async () => {
      stopADHSRScrolling();
    });
  });
  useMessageHandler('prompter.state.momentaryreverse.stop', () => {
    viewportFuncs.queueSequentialTask(async () => {
      stopADHSRScrolling(true);
    });
  });


  // Occurs when a momentaryreverse becomes idle (after the `release` time has elapsed)
  const idleADHSRScrolling = useCallback(async function (reverse?: boolean) {
    const ahdsr = viewportRefs.ahdsrRef.current;
    if(!ahdsr) {
      throw new Error('AHDSR not defined.');
    }

    //
    // Reset the state machine, just in case we were interupted by hitting a trigger in teh script 
    // such as reach the beginning or end of the script or reach a pause element in the script.
    //
    ahdsr.reset();

    // If we were in the middle of momentaryreverse scrolling... we are down now, turn on reverse 
    // scrolling mode/
    const configState = useConfigurationStore.getState();
    if(reverse && configState.scrollReversed) {
      configState.setScrollReversed(false);
    }
  }, [viewportRefs.ahdsrRef]);
  useMessageHandler('prompter.state.momentaryplay.idle', () => {
    viewportFuncs.queueSequentialTask(async () => {
      idleADHSRScrolling();
    });
  });
  useMessageHandler('prompter.state.momentaryreverse.idle', () => {
    viewportFuncs.queueSequentialTask(async () => {
      idleADHSRScrolling(true);
    });
  });

};

export default usePrompterAHDSRScrolling;