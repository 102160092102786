/**
 * © Copyright 2024 fluidprompter.com
 */

import { v4 as uuidv4 } from 'uuid';
import {
  Characteristic,
  Device,
  Service,
  BleError,
  State,
} from 'react-native-ble-plx';

import AppController from '../controllers/AppController/AppController';
import {
  IAppMessage,
  Status,
  Module,
  BleCommand,
} from './BluetoothNativeInterface';
import {
  IBluetoothProvider,
  IPCNotifyCallback,
  AppBluetoothProvider,
  AppBluetoothStatus,
  AppBluetoothDeviceState,
  AppBluetoothDevice,
  AppBluetoothService,
  AppBluetoothCharacteristic,
  AppBluetoothEventType,
  DeviceId,
} from './BluetoothProviders';

const eventListenerMap: { [key in AppBluetoothEventType]: string } = {
  [AppBluetoothEventType.ServerDisconnectedEvent]: 'onDeviceDisconnect',
  [AppBluetoothEventType.AvailabilityChangedEvent]: 'onStateChange',
};

/**
 * Implement the BluetoothProvider interface using IPC communication with a host app that has
 * FluidPrompter loaded in a webview like component.
 */
export class BluetoothProviderIPC implements IBluetoothProvider {
  private _appController: AppController;

  constructor(appController: AppController) {
    this._appController = appController;
  }

  private _base64ToDataView(base64: string): DataView {
    console.log(`Remote button value in base64: ${base64}`);

    // Decode the base64 string to a binary string.
    const binaryString = window.atob(base64);

    // Convert the binary string to a Uint8Array.
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create an ArrayBuffer from the Uint8Array.
    const arrayBuffer = bytes.buffer;

    // Create a DataView with the ArrayBuffer.
    const dataView = new DataView(arrayBuffer);

    return dataView;
  }

  getProvider(): AppBluetoothProvider {
    return AppBluetoothProvider.IPC;
  }

  async connect(deviceId: string): Promise<AppBluetoothDevice | undefined> {
    const msg: IAppMessage = {
      requestId: uuidv4(),
      source: Module.WEB,
      destination: Module.BLE,
      data: {
        command: BleCommand.Connect,
        arguments: { deviceId: deviceId },
      },
    };

    let response: IAppMessage;
    try {
      response = await this._appController.sendAndReceiveIPCMessage(msg);
    } catch (error) {
      console.log('Bluetooth connect ipc method failed');
      return undefined;
    }

    if (response.status === Status.Failed) {
      console.log('Bluetooth connect failed');
      return undefined;
    }

    const device: Device = response.data as Device;

    return {
      name: device.id,
      id: device.id,
      rssi: device.rssi,
      serviceUUIDs: device.serviceUUIDs,
      localName: device.localName,
    };
  }

  async disconnect(deviceId: string): Promise<AppBluetoothDevice | undefined> {
    const msg: IAppMessage = {
      requestId: uuidv4(),
      source: Module.WEB,
      destination: Module.BLE,
      data: {
        command: BleCommand.Disconnect,
        arguments: { deviceId: deviceId },
      },
    };

    let response: IAppMessage;
    try {
      response = await this._appController.sendAndReceiveIPCMessage(msg);
    } catch (error) {
      console.log('Bluetooth disconnect ipc method failed');
      return undefined;
    }

    if (response.status === Status.Failed) {
      console.log('Bluetooth disconnect failed');
      return undefined;
    }

    const device: Device = response.data as Device;

    return {
      name: device.id,
      id: device.id,
      rssi: device.rssi,
      serviceUUIDs: device.serviceUUIDs,
      localName: device.localName,
    };
  }

  async getState(): Promise<AppBluetoothDeviceState> {
    const msg: IAppMessage = {
      requestId: uuidv4(),
      source: Module.WEB,
      destination: Module.BLE,
      data: {
        command: BleCommand.GetState,
      },
    };

    let response: IAppMessage;
    try {
      response = await this._appController.sendAndReceiveIPCMessage(msg);
    } catch (error) {
      console.log('Bluetooth get state ipc method failed');
      return AppBluetoothDeviceState.Unknown;
    }

    if (response.status === Status.Failed) {
      console.log('Bluetooth get state failed');
      return AppBluetoothDeviceState.Unknown;
    }

    const StateResp = response.data as { state: AppBluetoothDeviceState };
    return StateResp.state;
  }

  async startScanning(requestOptions: RequestDeviceOptions): Promise<AppBluetoothDevice[]> {

    const devices: AppBluetoothDevice[] = [];

    const msg: IAppMessage = {
      requestId: uuidv4(),
      source: Module.WEB,
      destination: Module.BLE,
      data: {
        command: BleCommand.StartScanning,
        arguments: requestOptions,
      },
    };

    let response: IAppMessage;
    try {
      response = await this._appController.sendAndReceiveIPCMessage(msg);
    } catch (error) {
      console.log(`Bluetooth start scanning ipc method failed: ${error}`);
      return devices;
    }

    if (response.status === Status.Failed) {
      console.log('Bluetooth start scanning failed');
      return devices;
    }

    const devicesResp = response.data as { devices: Device[] };
    devicesResp.devices.forEach((device) => {
      devices.push({
        name: device.id,
        id: device.id,
        rssi: device.rssi,
        serviceUUIDs: device.serviceUUIDs,
        localName: device.localName,
      });
    });

    return devices;
  }

  async stopScanning(): Promise<AppBluetoothStatus> {
    const devices: BluetoothDevice[] = [];

    const msg: IAppMessage = {
      requestId: uuidv4(),
      source: Module.WEB,
      destination: Module.BLE,
      data: {
        command: BleCommand.StopScanning,
      },
    };

    let response: IAppMessage;
    try {
      response = await this._appController.sendAndReceiveIPCMessage(msg);
      if (response.status) {
        return response.status === Status.Success ? AppBluetoothStatus.Success : AppBluetoothStatus.Failed;
      }
    } catch (error) {
      console.log('Bluetooth stop scanning ipc method failed');
    }
    return AppBluetoothStatus.Failed;
  }

  async discoverServices(deviceId: DeviceId): Promise<AppBluetoothService[]> {
    const services: AppBluetoothService[] = [];

    const msg: IAppMessage = {
      requestId: uuidv4(),
      source: Module.WEB,
      destination: Module.BLE,
      data: {
        command: BleCommand.DiscoverServices,
        arguments: { deviceId: deviceId },
      },
    };

    let response: IAppMessage;
    try {
      response = await this._appController.sendAndReceiveIPCMessage(msg);
    } catch (error) {
      console.log('Bluetooth discover services ipc method failed');
      return services;
    }

    if (response.status === Status.Failed) {
      console.log('Bluetooth discover services failed');
      return services;
    }

    const devicesResp = response.data as { services: Service[] };
    devicesResp.services.forEach((service) => {
      const chars: Characteristic[] = [];
      services.push({
        id: service.id.toString(),
        uuid: service.uuid,
        deviceID: service.deviceID,
        isPrimary: service.isPrimary,
      });
    });

    return services;
  }

  async read(deviceId: DeviceId,
    serviceUUID: string,
    characteristicUUID: string
  ) : Promise<DataView | null> {

    const msg: IAppMessage = {
      requestId: uuidv4(),
      source: Module.WEB,
      destination: Module.BLE,
      data: {
        command: BleCommand.Read,
        arguments: {
          deviceId,
          characteristicUUID,
          serviceUUID
        },
      },
    };

    let response: IAppMessage;
    try {
      response = await this._appController.sendAndReceiveIPCMessage(msg);
    } catch (error) {
      console.log('Bluetooth read characteristic ipc method failed');
      return null;
    }

    if (response.status === Status.Failed) {
      console.log(`Bluetooth read characteristic ipc returned error code: ${response.code}`);
      return null;
    }

    const char = response.data as {characteristic: Characteristic};
    if (!char.characteristic.value) {
      return null;
    }

    return this._base64ToDataView(char.characteristic.value);
  }

  async notify(
    deviceId: DeviceId,
    serviceUUID: string,
    characteristicUUID: string,
    handler: IPCNotifyCallback,
    topic: string | undefined
  ) : Promise<void> {
    const notifyCommand: IAppMessage = {
      requestId: topic ? topic : uuidv4(),
      source: Module.WEB,
      destination: Module.BLE,
      data: {
        command: BleCommand.Notify,
        arguments: {
          deviceId: deviceId,
          serviceUUID: serviceUUID,
          characteristicUUID: characteristicUUID,
        },
      },
    };

    let char: AppBluetoothCharacteristic;

    this._appController.subscribeIPCMessage(
      notifyCommand.requestId,
      (msg: string) => {
        const response: IAppMessage = JSON.parse(msg);
        const charInResponse = response.data as Characteristic;
        const responseStatus = response.status === Status.Success ? AppBluetoothStatus.Success : AppBluetoothStatus.Failed;

        if (responseStatus === AppBluetoothStatus.Success && charInResponse.uuid === characteristicUUID) {
          char = {
            uuid: charInResponse.uuid,
            serviceUUID: charInResponse.serviceUUID,
            deviceID: charInResponse.deviceID,
            isReadable: charInResponse.isReadable,
            isNotifiable: charInResponse.isNotifiable,
            value: charInResponse.value ? this._base64ToDataView(charInResponse.value) : null,
          };
        }

        handler(char, responseStatus, response.code ?? null);
      }
    );

    this._appController.sendIPCMessage(JSON.stringify(notifyCommand));
  }

  /**
   * Register a callback to be called when a specific event occurs
   * for a device.
   *
   * @param {DeviceId} deviceId - The id of the device to listen to.
   * @param {AppBluetoothEventType} eventType - The type of event to listen to.
   * @param {() => void} listener - The callback to be called when the event
   * occurs.
   * @return {void}
   */
  addEventListener(deviceId: DeviceId,
    eventType: AppBluetoothEventType,
    listener: () => void
  ) : void {

    const msg: IAppMessage = {
      requestId: uuidv4(),
      source: Module.WEB,
      destination: Module.BLE,
      data: {
        command: BleCommand.AddListener,
        arguments: {
          deviceId: deviceId,
          event: eventListenerMap[eventType]
        },
      },
    };

    this._appController.subscribeIPCMessage(
      msg.requestId,
      (msg: string) => {
        const response: IAppMessage = JSON.parse(msg);
        console.log(`Calling a listener for ${eventType} event`);
        listener();
      }
    );

    this._appController.sendIPCMessage(JSON.stringify(msg));
  }

  removeEventListener(deviceId: DeviceId,
    eventType: AppBluetoothEventType,
    listener: () => void
  ) : void {
    const msg: IAppMessage = {
      requestId: uuidv4(),
      source: Module.WEB,
      destination: Module.BLE,
      data: {
        command: BleCommand.RemoveListener,
        arguments: {
          deviceId: deviceId,
          event: eventListenerMap[eventType]
        },
      },
    };
    this._appController.sendIPCMessage(JSON.stringify(msg));
  }
}