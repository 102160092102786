/**
 * Represents one logic endpoint involved in a prompter session. This may be a prompter, a web
 * remote, or a companion module for control via streamdeck.
 */
export var EndpointRole;
(function (EndpointRole) {
    EndpointRole["Unknown"] = "unknown";
    EndpointRole["Prompter"] = "prompter";
    EndpointRole["Viewer"] = "viewer";
    EndpointRole["Remote"] = "remote";
    // API = 'API',
})(EndpointRole || (EndpointRole = {}));
export default EndpointRole;
