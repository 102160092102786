/**
 * useInputMiddleware() hook
 * 
 * This hook is added to the global chain of input middleware for the application. Use input events
 * will be processed through the middleware chain from the deepest instance towards the root.
 */
import { useEffect, useCallback, useRef } from 'react';
import { isMacOs, isIOS /*, isWindows*/ } from 'react-device-detect';
import isHotkey from 'is-hotkey';

import useMemoCompare from '../../hooks/useMemoCompare';

import { DeviceKeyboardEvent } from '../../devices/events/DeviceKeyboardEvent';
import { DeviceButtonEvent } from '../../devices/events/DeviceButtonEvent';
import { DeviceWheelEvent } from '../../devices/events/DeviceWheelEvent';
import { DeviceAxisEvent } from '../../devices/events/DeviceAxisEvent';

export interface InputMiddlewareResult {
  handled?: boolean;
}
export type InputMiddlewareCallback = (event: DeviceInputEvent) => InputMiddlewareResult;

export type DeviceInputEvent = DeviceKeyboardEvent | DeviceButtonEvent | DeviceWheelEvent | DeviceAxisEvent;

interface InputMiddlewareHookExports {
  dispatchKeyboardEvent: (e: KeyboardEvent) => void;
  dispatchButtonEvent: (e: DeviceButtonEvent) => void;
  dispatchRemoteEvent: (e: KeyboardEvent) => void;
  dispatchWheelEvent: (e: DeviceWheelEvent) => void;
  dispatchAxisEvent: (e: DeviceAxisEvent) => void;
}

type KeyboardEventTypes = 'keydown' | 'keyup';
type KeyboardEventHandler = (e: DeviceKeyboardEvent) => void;
export type KeyCombinationConfig = {
  [eventType in KeyboardEventTypes]?: KeyboardEventHandler;
}

type KeyCombinationTypes = string | string[];
export interface KeyboardHandlers {
  hotkeys: KeyCombinationTypes;
  byKey?: boolean;
  skipWhenEditableFocused?: boolean;
  macOnly?: boolean;
  keydown?: KeyboardEventHandler;
  keyup?: KeyboardEventHandler;
}
export type KeyboardHandlersCollection = KeyboardHandlers[];

type ButtonEventHandler = (e: DeviceButtonEvent) => void;
export interface ButtonHandler {
  button: string;
  skipWhenEditableFocused?: boolean;
  buttondown?: ButtonEventHandler;
  buttonup?: ButtonEventHandler;
}
export type ButtonHandlersCollection = ButtonHandler[];

type WheelEventHandler = (e: DeviceWheelEvent) => void;
export interface WheelHandler {
  scroll?: WheelEventHandler;
}
export type WheelHandlersCollection = WheelHandler[];

type AxisEventHandler = (e: DeviceAxisEvent) => void;
export interface AxisHandler {
  change?: AxisEventHandler;
}
export type AxisHandlersCollection = AxisHandler[];

// interface GamepadHandlers {
//   [keyCombination: string]: KeyCombinationConfig;
// }
export interface InputMiddlewareProps {
  exclusive?: boolean;
  keyboard?: KeyboardHandlersCollection;
  buttons?: ButtonHandlersCollection;
  // gamepad?: GamepadHandlers;
  wheels?: WheelHandlersCollection,
  axes?: AxisHandlersCollection,
}
type InputMiddlewareHook = (props?: InputMiddlewareProps) => InputMiddlewareHookExports;


// This is the true hook definition that will be returned
const createSingletonContainer = (): InputMiddlewareHook => {
  const middlewareSet = new Map<InputMiddlewareCallback, number>();

  const useInputMiddlewareInternal: InputMiddlewareHook = (props?: InputMiddlewareProps): InputMiddlewareHookExports => {



    // console.log(`useInputMiddleware executed`);
    
    const {
      exclusive, 
      keyboard: keyboardMappings, 
      buttons: buttonMappings,
      wheels: wheelHandlers,
      axes: axisHandlers,
    } = props || {};

    // Keep track of the middleware depth when this hook is first loaded. We will maintain the same
    // depth accross component re-renders.
    const middlewareDepth = useRef<number | null>(null);

    const handleDeviceKeyboardEvent = useCallback((e: DeviceKeyboardEvent): InputMiddlewareResult => {
      const result: InputMiddlewareResult = {};

      const eventType = e.nativeEvent.type as KeyboardEventTypes;
      if(keyboardMappings) {
        for (const keyCombinationConfig of keyboardMappings) {

          //
          // Some hotkeys are only applicable when not focused on an input element or ContentEditable.
          //
          if(keyCombinationConfig.skipWhenEditableFocused && e.isEditableFocused) {
            continue;
          }

          // Is this handler only for MacOS only? Make sure we are on Mac then. Otherwise skip.
          if(keyCombinationConfig.macOnly && !(isMacOs || isIOS)) {
            continue;
          }

          //
          // Check if this entry has declared a handler for this event type.
          // ex: 'keydown' | 'keyup'
          //
          const eventHandler = keyCombinationConfig[eventType];
          if(!eventHandler) {
            continue;
          }

          // console.log(`${keyCombination}: ${keyCombinationConfig}`);

          //
          // Check if this entries key combination matches the current event.
          //        
          if(isHotkey(keyCombinationConfig.hotkeys, { byKey: (keyCombinationConfig.byKey === true) }, e.nativeEvent)) {
            eventHandler(e);
            // console.log(`isHotkey(${keyCombinationConfig.hotkeys}) === true, cancelable=${e.cancelable}, defaultPrevented=${e.defaultPrevented}`);
            result.handled = e.defaultPrevented;
            break;
          }
        }
      }

      return result;
    }, [keyboardMappings]);

    const handleDeviceButtonEvent = useCallback((e: DeviceButtonEvent): InputMiddlewareResult => {
      const result: InputMiddlewareResult = {};

      if(buttonMappings) {
        for (const buttonMapping of buttonMappings) {
          //
          // Some hotkeys are only applicable when not focused on an input element or ContentEditable.
          //
          // if(keyCombinationConfig.skipWhenEditableFocused && e.isEditableFocused) {
          //   continue;
          // }
          if(buttonMapping.button !== e.buttonName) {
            continue;
          }

          //
          // Check if this entry has declared a handler for this event type.
          // ex: 'keydown' | 'keyup'
          //
          let eventHandler: ButtonEventHandler | undefined;
          switch(e.eventType) {
            case 'down':
              eventHandler = buttonMapping.buttondown;
              break;
            case 'up':
              eventHandler = buttonMapping.buttonup;
              break;
          }
          if(!eventHandler) {
            continue;
          }

          //
          // Execute the current middleware handler
          eventHandler(e);

          //
          // Should we stop walking down the middleware list now?
          result.handled = e.defaultPrevented;
        }
      }

      return result;
    }, [buttonMappings]);

    const handleDeviceWheelEvent = useCallback((e: DeviceWheelEvent): InputMiddlewareResult => {
      const result: InputMiddlewareResult = {};

      if(wheelHandlers) {
        for (const wheelHandler of wheelHandlers) {
          //
          // Some hotkeys are only applicable when not focused on an input element or ContentEditable.
          //
          // if(keyCombinationConfig.skipWhenEditableFocused && e.isEditableFocused) {
          //   continue;
          // }

          //
          // Check if this entry has declared a handler for this event type.
          // ex: 'keydown' | 'keyup'
          //
          const eventHandler = wheelHandler.scroll;
          if(!eventHandler) {
            continue;
          }

          //
          // Execute the current middleware handler
          eventHandler(e);

          //
          // Should we stop walking down the middleware list now?
          result.handled = e.defaultPrevented;
        }
      }

      return result;
    }, [wheelHandlers]);

    const handleDeviceAxisEvent = useCallback((e: DeviceAxisEvent): InputMiddlewareResult => {
      const result: InputMiddlewareResult = {};

      if(axisHandlers) {
        for (const axisHandler of axisHandlers) {
          //
          // Some hotkeys are only applicable when not focused on an input element or ContentEditable.
          //
          // if(keyCombinationConfig.skipWhenEditableFocused && e.isEditableFocused) {
          //   continue;
          // }

          //
          // Check if this entry has declared a handler for this event type.
          // ex: 'keydown' | 'keyup'
          //
          const eventHandler = axisHandler.change;
          if(!eventHandler) {
            continue;
          }

          //
          // Execute the current middleware handler
          eventHandler(e);

          //
          // Should we stop walking down the middleware list now?
          result.handled = e.defaultPrevented;
        }
      }

      return result;
    }, [axisHandlers]);

    const middlewareEventHandler: InputMiddlewareCallback = useCallback((e: DeviceInputEvent): InputMiddlewareResult => {
      const result: InputMiddlewareResult = {};

      //
      // Exclusive middleware will never bubble events up through previous middleware.
      //
      if(exclusive && !e.isEditableFocused) {
        // Only do this if we don't have focus on an editable component such as an input element.
        e.preventDefault();
      }

      //
      // Use type discrimination to route events to the correct handlers:
      // Keyboard, Wheel, Axis
      //
      switch(e.type) {
        case 'DeviceKeyboardEvent':
          handleDeviceKeyboardEvent(e);
          break;
        case 'DeviceButtonEvent':
          handleDeviceButtonEvent(e);
          break;
        case 'DeviceWheelEvent':
          handleDeviceWheelEvent(e);
          break;
        case 'DeviceAxisEvent':
          handleDeviceAxisEvent(e);
          break;
      }

      result.handled = e.defaultPrevented;
      return result;
    }, [exclusive, handleDeviceKeyboardEvent, handleDeviceButtonEvent, handleDeviceWheelEvent, handleDeviceAxisEvent]);

    //
    // We register a listener for state changes that may occur in other instances of this hook.
    //
    useEffect(() => {
      if(!middlewareDepth.current) {
        // Initialize middlewareDepth
        middlewareDepth.current = middlewareSet.size + 1;
      }
      const middlewareDepthValue = middlewareDepth.current;

      middlewareSet.set(middlewareEventHandler, middlewareDepthValue);
      // console.log(`Added Input Middleware at depth ${middlewareDepthValue}, ${middlewareSet.size} total middleware`);

      // cleanup
      return () => {
        middlewareSet.delete(middlewareEventHandler);
        // console.log(`Removed Input Middleware at depth ${middlewareDepthValue}, ${middlewareSet.size} remaining middleware`);
      };
    }, [middlewareEventHandler, middlewareDepth]);

    const dispatchDeviceInputEvent = useCallback((inputEvent: DeviceInputEvent) => {
  
      // Iterate over all middleware instances in reverse order (depth first, towards root)
      // Each middleware has a chance to process input events and optionally stop propogation.
      const sortedMiddleware = Array.from(middlewareSet.entries()).sort((a, b) => {
        // Sort by value DESCENDING.
        return b[1] - a[1];
      }).map((entry) => entry[0]);

      // console.log(`${sortedMiddleware.length} middleware instances`);
      if(sortedMiddleware.length < 1) {
        return;
      }

      //
      // Iterate over the available middleware instances and try each middleware in order until the
      // event has been handled.
      //
      for(let i = 0; i < sortedMiddleware.length; i++) {
        const middlewareInstance = sortedMiddleware[i];
        if(typeof(middlewareInstance) !== 'function') {
          // If we somehow got an undefined entry added to our set/array, skip it.
          continue;
        }

        const middlewareResult = middlewareInstance(inputEvent);
        // console.log(`Run middleware #${i}, result handled=${middlewareResult.handled}`);

        if(middlewareResult.handled) {
          break;
        }
      } // END for()
    }, []);

    const dispatchKeyboardEvent = useCallback((e: KeyboardEvent) => {
      //
      // Wrap our KeyboardEvent. This will also record whether we were focused on a ContentEditable
      // or Input element when the key was pressed.
      //
      const syntheticEvent = new DeviceKeyboardEvent(undefined, e);
      dispatchDeviceInputEvent(syntheticEvent);
    }, [dispatchDeviceInputEvent]);

    const dispatchButtonEvent = useCallback((e: DeviceButtonEvent) => {
      dispatchDeviceInputEvent(e);
    }, [dispatchDeviceInputEvent]);

    const dispatchRemoteEvent = useCallback((e: KeyboardEvent) => {
      const syntheticEvent = new DeviceKeyboardEvent(undefined, e);
      dispatchDeviceInputEvent(syntheticEvent);
    }, [dispatchDeviceInputEvent]);

    // const wheelDeltaY = useRef<number>(0);
    // const dispatchWheelEventThrottled = useCallback((e: DeviceWheelEvent) => {
    //   // Need to throttle this event using requestAnimationFrame and aggregate the delta values 
    //   // across multiple events.
    //   const currentDeltaY = wheelDeltaY.current;
    //   wheelDeltaY.current = 0;

    //   dispatchDeviceInputEvent(e);
    // }, [dispatchDeviceInputEvent]);
    const dispatchWheelEvent = useCallback((e: DeviceWheelEvent) => {
      // Need to throttle this event using requestAnimationFrame and aggregate the delta values 
      // across multiple events.
      dispatchDeviceInputEvent(e);
    }, [dispatchDeviceInputEvent]);

    const dispatchAxisEvent = useCallback((e: DeviceAxisEvent) => {
      dispatchDeviceInputEvent(e);
    }, [dispatchDeviceInputEvent]);

    return {
      dispatchKeyboardEvent,
      dispatchButtonEvent,
      dispatchRemoteEvent,
      dispatchWheelEvent,
      dispatchAxisEvent,
    };
  };  // END useInputMiddleware()

  const useInputMiddleware: InputMiddlewareHook = (props?: InputMiddlewareProps): InputMiddlewareHookExports => {
    const memoProps = useMemoCompare(props, (prev, next) => {
      if(prev?.exclusive !== next?.exclusive) {
        return false;
      }
      if(prev?.keyboard !== next?.keyboard) {
        return false;
      }
      // if(prev?.gamepad !== next?.gamepad) {
      //   return false;
      // }
      if(prev?.wheels !== next?.wheels) {
        return false;
      }
      if(prev?.axes !== next?.axes) {
        return false;
      }

      return true;
    });

    return useInputMiddlewareInternal(memoProps);
  };

  return useInputMiddleware;
};

const singletonInstance: InputMiddlewareHook = createSingletonContainer();
export default singletonInstance;
