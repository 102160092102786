import MessageUtils from "./MessageUtils";
const header_size = 2 + 4 + 2 + 16 + 16; // bundleId, bundleTotalBytes, chunkNumber, senderId, targetId
const chunk_size = 16000; // Chunk size for transmitting on the wire.
/**
 * Represents a binary chunk transmitted over the network on either websocket or datachannel.
 */
class BinaryChunkMessage {
    constructor(bundleId, bundleTotalBytes, chunkNumber, senderId, targetId, data) {
        this._bundleId = bundleId;
        this._bundleTotalBytes = bundleTotalBytes;
        this._chunkNumber = chunkNumber;
        this._senderId = new Uint8Array(senderId);
        if (this._senderId.byteLength !== 16) {
            throw new Error(`Error: senderId must be 16 bytes.`);
        }
        this._targetId = new Uint8Array(targetId);
        if (this._targetId.byteLength !== 16) {
            throw new Error(`Error: targetId must be 16 bytes.`);
        }
        this._data = data;
    }
    get bundleId() {
        return this._bundleId;
    }
    get bundleTotalBytes() {
        return this._bundleTotalBytes;
    }
    get chunkNumber() {
        return this._chunkNumber;
    }
    get senderId() {
        return this._senderId;
    }
    get targetId() {
        return this._targetId;
    }
    get data() {
        return this._data;
    }
    static fromBinary(data) {
        const dataView = new DataView(data);
        const senderId = data.slice(8, 24);
        const targetId = data.slice(24, 40);
        const rawChunkData = data.slice(header_size);
        // We obfuscate the payload with xor encoding just to make FluidPrompter harder to reverse
        // engineer. Decode the payload bytes using senderId.
        const decodedData = MessageUtils.xorDecodeBytes(new Uint8Array(senderId), rawChunkData);
        const instance = new BinaryChunkMessage(dataView.getUint16(0), // bundleId
        dataView.getUint32(2), // bundleTotalBytes
        dataView.getUint16(6), // chunkNumber
        senderId, // senderId 16 bytes UUID
        targetId, // targetId 16 bytes UUID
        decodedData);
        return instance;
    }
    toBinary() {
        //
        // Write header
        //
        const chunkHeader = new ArrayBuffer(header_size);
        const dataView = new DataView(chunkHeader);
        dataView.setUint16(0, this._bundleId); // 2 bytes: bundleId
        dataView.setUint32(2, this._bundleTotalBytes); // 4 bytes; totalBytes
        dataView.setUint16(6, this._chunkNumber); // 2 bytes; chunkNumber
        const headerBytes = new Uint8Array(chunkHeader);
        // Combine our chunkHeader+chunkContent
        const binaryChunk = new Uint8Array(header_size + this._data.byteLength);
        binaryChunk.set(headerBytes, 0);
        binaryChunk.set(this._senderId, 8); // write our senderId 16 bytes (128 bits) UUID.
        binaryChunk.set(this._targetId, 24); // write our senderId 16 bytes (128 bits) UUID.
        // We obfuscate the payload with xor encoding just to make FluidPrompter harder to reverse
        // engineer. Encode the payload bytes using senderId.
        const encodedBytes = MessageUtils.xorEncodeBytes(this._senderId, this._data);
        // binaryChunk.set(new Uint8Array(this._data), chunkHeader.byteLength);
        binaryChunk.set(encodedBytes, header_size);
        return binaryChunk;
    }
}
export default BinaryChunkMessage;
