import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useAppController, useMessageHandler } from '../../controllers/AppController';
import useConfigurationStore from '../../state/ConfigurationStore';
import useFeatureFlagsStore from '../../state/FeatureFlagsStore';
import useBackgroundState, { BackgroundLayout, BackgroundTypes } from '../../state/BackgroundState';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import useResizeObserver from '@react-hook/resize-observer';
import { Id, toast, ToastOptions, TypeOptions, UpdateOptions } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import classNames from 'classnames';
import './PrompterBackground.scss';

/** [MDN Reference](https://developer.mozilla.org/docs/Web/API/UserActivation) */
interface UserActivation {
  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/UserActivation/hasBeenActive) */
  readonly hasBeenActive: boolean;
  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/UserActivation/hasBeenActive) */
  readonly isActive: boolean;
}

interface Dimensions {
  width: number;
  height: number;
}

const toastIds: Record<string, Id> = {};
const createOrUpdateToast = (deviceId: string, content: string, options?: UpdateOptions | undefined) => {
  const toastId = toastIds[deviceId];
  if(toastId && toast.isActive(toastId)) {
    // Update
    if(content && options) {
      options['render'] = content;
    }
    toast.update(toastId, options);
    return;
  }

  const toastOptions = options as ToastOptions;
  toastIds[deviceId] = toast(content, toastOptions);
};

const PrompterBackground = () => {
  const videoRef = React.createRef<HTMLVideoElement>();
  const canvasRef = React.createRef<HTMLCanvasElement>();
  const [fitWidth, setFitWidth] = useState<boolean>(true);
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [mediaTrackSettings, setMediaTrackSettings] = useState<MediaTrackSettings>();
  const [backgroundDimensions, setBackgroundDimensions] = useState<Dimensions>();

  const configStore = useConfigurationStore(state => ({
    flipHorizontal: state.flipHorizontal,
    flipVertical: state.flipVertical,
  }), shallow);

  const backgroundState = useBackgroundState(state => ({
    backgroundType: state.backgroundType,
    setBackgroundType: state.setBackgroundType,
    backgroundLayout: state.backgroundLayout,
    // setBackgroundLayout: state.setBackgroundLayout,
  }), shallow);

  const appController = useAppController();
  const confirm = useConfirm();

  const prompterSession = usePrompterSession(state => ({
    isBlanking: state.isBlanking,
  }), shallow);

  const backgroundContainerRef = React.createRef<HTMLDivElement>();

  const handleBackgroundResized = useCallback(async (e?: ResizeObserverEntry) => {
    // console.log('handleBackgroundResized', e);
    const backgroundEl = backgroundContainerRef.current;
    if(!backgroundEl || !backgroundDimensions) {
      // console.log('ERROR handleBackgroundResized bailed...', mediaTrackSettings);
      return;
    }

    // const backgroundWidth = e?.contentRect.width || backgroundEl.clientWidth;
    // const backgroundHeight = e?.contentRect.height || backgroundEl.clientHeight;
    // const contentSmallerThanContainer = mediaTrackSettings.width < backgroundWidth && mediaTrackSettings.height < backgroundHeight;

    //
    // Determine how to fit the video in our background.
    // We can letter box on top and bottom or left and right.
    //
    const backgroundAspectRatio = (e !== undefined) ? e.contentRect.width / e.contentRect.height : backgroundEl.clientWidth / backgroundEl.clientHeight;
    const videoAspectRatio = backgroundDimensions.width / backgroundDimensions.height;
    // console.log(`handleBackgroundResized, backgroundAspectRatio=${backgroundAspectRatio}, videoAspectRatio=${videoAspectRatio}`);

    const localBackgroundState = useBackgroundState.getState();
    switch(localBackgroundState.backgroundLayout) {
      case BackgroundLayout.Contain:
        setFitWidth(videoAspectRatio >= backgroundAspectRatio);
        break;
      case BackgroundLayout.Cover:
        setFitWidth(videoAspectRatio < backgroundAspectRatio);
        break;
    }
  }, [backgroundContainerRef, backgroundDimensions]);
  useResizeObserver(backgroundContainerRef, handleBackgroundResized);

  const setVideoTrack = useCallback((mediaStream: MediaStream | undefined) => {
    const videoEl = videoRef.current;
    if(!videoEl) {
      console.warn('setVideoTrack() videoEl was undefined');
      return;
    }

    if(mediaStream === undefined) {
      videoEl.srcObject = null;
      console.warn('setVideoTrack() mediaStream was undefined');
      return;
    }

    //
    // Shen you share a desktop window, and stop sharing via a native browser control, the
    // video track is "ended".
    //
    const videoTracks = mediaStream.getVideoTracks();
    if(videoTracks && videoTracks.length) {
      const videoTrack = videoTracks[0];

      //
      // Discover native media dimensions (is this a 720p, 1080p or 4k webcam?)
      //
      // const capabilities = videoTrack.getCapabilities();
      const videoTrackSettings = videoTrack.getSettings();
      setMediaTrackSettings(videoTrackSettings);
      //videoTrackSettings.deviceId

      // This is only available when the video track is originating locally.
      if(videoTrackSettings.width && videoTrackSettings.height) {
        console.log(`VideoTrack Capabilities - Width: ${videoTrackSettings.width}, Height: ${videoTrackSettings.height}`, videoTrackSettings);

        setBackgroundDimensions({
          width: videoTrackSettings.width,
          height: videoTrackSettings.height,
        });
      }

      videoTrack.addEventListener('ended', () => {
        console.log('videoTrack ended');
        appController.dispatchMessage('prompter.background.clear');
      });

      //
      // Video track 'mute' event will be fired when the video source fails to produce frames at
      // the expected framerate. This happens when the media capture source isn't performing
      // optimally such as being throttled by the host OS. This doesn't necessarily mean we want to
      // show a black background in fluidprompter. We'd rather have a dropped video frame than a
      // black flicker.
      //
      // TL;DR; Don't hide the video background dom element.
      //
      videoTrack.addEventListener('mute', () => {
        console.log('videoTrack mute');
        // videoEl.style.visibility = 'hidden';
      });
      videoTrack.addEventListener('unmute', () => {
        console.log('videoTrack unmute');
        // videoEl.style.visibility = 'visible';
      });
    }

    // Changing the source of video to current stream.
    videoEl.srcObject = mediaStream;
    videoEl.addEventListener('loadedmetadata', async () => {
      // console.log(`MediaStream loadedmetadata w: ${videoEl.videoWidth}, h: ${videoEl.videoHeight}`);

      // The videoWidth/Height is not known until after we begin receiving the remote stream.
      if(videoEl.videoWidth && videoEl.videoHeight) {
        setBackgroundDimensions({
          width: videoEl.videoWidth,
          height: videoEl.videoHeight,
        });
      }

      //
      // We will retry the play method twice. If the first attempt to play fails, we will try to
      // get user consent to play with a click.
      //
      for(let i = 0; i < 2; i++) {
        if(i > 0) {
          // This is a retry. Do we need the user to interact with the page?
          // If the user has not yet interacted with the document, let's get them to now.
          await confirm({
            // title: <><WarningIcon sx={{ mr: 1, verticalAlign: 'middle', color: '#eed202' }} />Another Prompter is sharing a background.</>,
            title: 'Another prompter is sharing a background.',
            titleProps: {
              //
            },
            description: 'Do you want to accept the incoming background?',
            confirmationText: 'Yes',
            confirmationButtonProps: {
              variant: 'contained',
              size: 'small',
              color: 'success',
            },
            cancellationText: 'No',
            cancellationButtonProps: {
              variant: 'contained',
              size: 'small',
              color: 'error',
            }
          });
        }

        try {
          await videoEl.play();

          break;
        } catch (err) {
          // This may fail because the user has never interacted with the page.
          //
          // DOMException: play() failed because the user didn't interact with the document first.
          // https://goo.gl/xX8pDD
          //
          // Let's ask the user to interact now!
          console.log('video play error', err);
        }
      }
    });
    videoEl.addEventListener('abort', () => {
      // The UserAgent terminated our access to the media.
      // The user may have stopped video via a browser native control.
      //
      // This event appears to be sent from Firefox for Webcam sharing.
      // Chrome gets this when we clear background on Chrome previously sending webcam from Chrome.
      console.log('MediaStream abort');
    });
    videoEl.addEventListener('ended', () => {
      // The UserAgent terminated our access to the media.
      // The user may have stopped video via a browser native control.
      //
      // This event appears to be sent from Firefox for desktop/screen sharing.
      console.log('MediaStream ended');
      // appController.dispatchMessage('prompter.background.clear');
    });
  }, [videoRef]);

  useMessageHandler('background', async (e) => {
    // Save a ref to our incoming remote stream.
    // mediaStreamRef.current = e.message.mediaStream;
    /*
    const backgroundStream = e.message.mediaStream;
    backgroundStream.addEventListener('removetrack', (e: MediaStreamTrackEvent) => {
      setMediaStream(undefined);

      const localBackgroundState = useBackgroundState.getState();
      if(localBackgroundState.backgroundType !== BackgroundTypes.None) {
        localBackgroundState.setBackgroundType(BackgroundTypes.None);
      }
    });
    */

    // Enable the hidden backgrounds feature if this prompter peer receives a background track from
    // another connected peer.
    useFeatureFlagsStore.getState().setVideoBackgrounds(true);

    //
    // If we are receiving a remote background track via webrtc, this will adjust our user
    // interface to disable options we can't use with a remote source.
    //
    if(!e.message.isLocalSource) {
      backgroundState.setBackgroundType(BackgroundTypes.Remote);
    }

    console.log(`PrompterBackground.setMediaStream(active = ${e.message.mediaStream.active})`, e.message.mediaStream);
    // setVideoTrack(e.message.mediaStream);
    setMediaStream(e.message.mediaStream);
  });

  useEffect(() => {
    setVideoTrack(mediaStream);
  }, [mediaStream]);

  useMessageHandler('prompter.background.clear', (e) => {
    e.sendToPeers = true;

    const videoEl = videoRef.current;
    if(!videoEl) {
      return;
    }

    const videoSrc = videoEl.srcObject as MediaStream;
    if(!videoSrc) {
      return;
    }

    // This will release media source such as a webcam and turn off the webcam-in-use indicator.
    videoSrc.getVideoTracks().forEach((track) => {
      // track.enabled = false;
      track.stop();
    });
    videoEl.srcObject = null;
  });

  //
  // Re-compute the background layout:
  //   1. When the component initially renders
  //   2. When backgroundState.backgroundLayout changes (contain | cover)
  //   3. When mediaTrackSettings change (because the source media changed)
  //
  useEffect(() => {
    handleBackgroundResized();
  }, [handleBackgroundResized, backgroundState.backgroundLayout, backgroundDimensions]);

  //
  // Capture a snapshot of the current video background.
  //
  const hasAvailableVideoSource = () => {
    const videoEl = videoRef.current;
    const canvasEl = canvasRef.current;
    if(!videoEl || videoEl.srcObject === null || !canvasEl) {
      createOrUpdateToast('capture-still-unavailable', 'No video source for capture...', {
        type: 'error',
        isLoading: false,
        autoClose: 3000,
        hideProgressBar: false,
      });
      return false;
    }

    return true;
  };

  const [countdownNumber, setCountdownNumber] = useState<number>();
  useMessageHandler('prompter.background.snapshot.countdown', () => {
    if(!hasAvailableVideoSource()) {
      return;
    }

    setCountdownNumber(3);
  });

  useMessageHandler('prompter.background.snapshot.immediate', () => {
    if(!hasAvailableVideoSource()) {
      return;
    }

    setCountdownNumber(0);
  });

  const countdownRef = useRef<HTMLSpanElement>(null);
  const countdownTimerRef = useRef<number>();
  useEffect(() => {
    if(countdownNumber === undefined) {
      return;
    }

    if(countdownNumber < 0) {
      setCountdownNumber(undefined);

      const videoEl = videoRef.current;
      const canvasEl = canvasRef.current;
      if(!videoEl || videoEl.srcObject === null || !canvasEl) {
        console.error('no videoEl, videoEl.srcObject or canvasEl');
        return;
      }

      // CAPTURE SNAPSHOT NOW!!
      const canvasContext = canvasEl.getContext('2d');
      if(!canvasContext) {
        console.error('no canvasContext');
        return;
      }

      const videoWidth = videoEl.videoWidth;  // 1920;
      const videoHeight = videoEl.videoHeight;  // 1080;

      canvasEl.width = videoWidth;
      canvasEl.height = videoHeight;

      // console.log(`videoWidth: ${videoWidth}, videoHeight: ${videoHeight}`)
      // canvasContext.drawImage(videoEl, 0, 0, canvasEl.width, canvasEl.height);
      canvasContext.drawImage(videoEl, 0, 0, videoWidth, videoHeight);
      const image_data_url = canvasEl.toDataURL('image/jpeg');

      const now = new Date();
      const nowISO = now.toISOString();

      const element = document.createElement('a');
      element.setAttribute('href', image_data_url);
      element.setAttribute('download', `FluidPrompter Photo - ${nowISO}.jpg`);
      element.style.display = 'none';

      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      // window.URL.revokeObjectURL(objectURL);

      return;
    }

    const countdownEl = countdownRef.current;
    if(countdownEl) {
      // https://css-tricks.com/restart-css-animation/
      countdownEl.classList.remove('pulse-animation');
      void countdownEl.offsetWidth;
      countdownEl.classList.add('pulse-animation');
    }

    if(countdownNumber >= 0) {
      const nextNumber = countdownNumber - 1;
      countdownTimerRef.current = window.setTimeout(() => {
        setCountdownNumber(nextNumber);
      }, 1000);
    }

    return () => {
      window.clearTimeout(countdownTimerRef.current);
    };
  }, [countdownNumber, videoRef, canvasRef]);

  //
  // Figure out our list of CSS Transforms that apply any configured flipping
  // horizontally or vertically as well as scroll position.
  //
  const transformDirectives: string[] = ['translate(-50%,-50%)'];
  if(configStore.flipHorizontal) { transformDirectives.push('rotateY(180deg)'); }
  if(configStore.flipVertical) { transformDirectives.push('rotateX(180deg)'); }
  const contentTransform = transformDirectives.join(' ');

  return (<div ref={backgroundContainerRef}
    className={classNames('BackgroundContainer', {
      PrompterBlanking: prompterSession.isBlanking,
      // Contain: (backgroundState.backgroundLayout === BackgroundLayout.Contain),
      // Cover: (backgroundState.backgroundLayout === BackgroundLayout.Cover),
    })}
  >
    {mediaStream && <video
      id="background-video"
      disablePictureInPicture={true}
      playsInline={true}
      muted={true}
      ref={videoRef}
      style={{
        width: fitWidth ? '100%' : 'auto',
        height: fitWidth ? 'auto' : '100%',
        transform: contentTransform,
      }}
    ></video>}
    <canvas
      id="background-canvas"
      ref={canvasRef}
    > </canvas>
    {countdownNumber !== undefined && <div
      className={classNames('CountdownContainer', { CountdownFlash: (countdownNumber === 0) })}
    >
      {countdownNumber > 0 && <span
        ref={countdownRef}
        className="CountdownNumber"
      >{countdownNumber}</span>}
    </div>}
  </div>);
};

export default PrompterBackground;