import BaseControlMessage from './BaseControlMessage';
import { PrompterSessionEndpoint } from '../models/PrompterSessionEndpoint';
export const CONNECTMESSAGE_KEY = 'connect';
/**
 * Represents the ConnectMessage type.
 */
export class ConnectMessage extends BaseControlMessage {
    constructor(endpoint) {
        super();
        this.type = CONNECTMESSAGE_KEY;
        this.endpoint = endpoint;
    }
    static fromJson(data) {
        if (data.type !== CONNECTMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for ConnectMessage.');
        }
        const input = data;
        const endpoint = PrompterSessionEndpoint.fromJson(input.endpoint);
        const instance = new ConnectMessage(endpoint);
        instance.deserializeSenderInfo(data.sender);
        instance.rebuildConnection = input.rebuildConnection;
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            endpoint: this.endpoint,
            rebuildConnection: (this.rebuildConnection === true),
        };
    }
}
ConnectMessage.MESSAGE_NAME = CONNECTMESSAGE_KEY;
