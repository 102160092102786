import { useState, useEffect, useCallback } from 'react';

declare global {
  interface Document {
		webkitFullscreenEnabled?: boolean;
		mozFullScreenEnabled?: boolean;
		msFullscreenEnabled?: boolean;
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
  }
}

const fullscreenEnabled = document.fullscreenEnabled ||
	document.webkitFullscreenEnabled ||
	document.mozFullScreenEnabled ||
	document.msFullscreenEnabled;

const fullscreenEventName = document.fullscreenEnabled ? 'fullscreenchange' :
  document.webkitFullscreenEnabled ? 'webkitfullscreenchange' :
    document.mozFullScreenEnabled ? 'mozfullscreenchange' :
      document.msFullscreenEnabled ? 'MSFullscreenChange' : 'fullscreenchange';

// 
// Safari is the biggest offender still not supporting a non-vendor-prefixed fullscreen api. 
// Safari still requires webkit prefix.
// See: https://caniuse.com/fullscreen
// 
function useFullscreen() {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isNativeFullscreen, setIsNativeFullscreen] = useState(false);

  /**
	 * Handler fired from the browser when full screen changes as a result 
	 * of JavaScript code (not fired for browser native fullscreen such as
	 * F11, for F11 detection we must monitor the browser resize event).
	 */
  /**
	 * Method to check if we think the browser is full screen by comparing
	 * the viewport dimensions against the screen dimensions.
	 */
  const checkFullscreenMode = useCallback(() => {
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
    if(fullscreenElement) {
      // console.log('Page has entered application fullscreen mode', fullscreenElement);
      setIsNativeFullscreen(false);
      setIsFullscreen(true);
      return;
    }

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    if (windowWidth === screenWidth && windowHeight === screenHeight) {
      // console.log('Page has entered native fullscreen mode');
      setIsNativeFullscreen(true);
      setIsFullscreen(true);
    } else if (windowWidth < screenWidth || windowHeight < screenHeight) {
      setIsNativeFullscreen(false);
      setIsFullscreen(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', checkFullscreenMode, true);
    document.addEventListener(fullscreenEventName, checkFullscreenMode);

    // Return a clean-up function that is used if the component is re-rendered.
    return () => {
      window.removeEventListener('resize', checkFullscreenMode, true);
      document.removeEventListener(fullscreenEventName, checkFullscreenMode);
    };		
  }, [checkFullscreenMode]);

  const requestFullscreenMode = useCallback(async () => {
    const target = document.documentElement;

    const requestFullscreenMethod = target.requestFullscreen || 
			target.webkitRequestFullscreen ||
			target.mozRequestFullScreen ||
			target.msRequestFullscreen;

    try {
      // await requestFullscreen(window.document.documentElement);
      return requestFullscreenMethod.call(document.documentElement);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const exitFullscreenMode = useCallback(async () => {
    const method = document.exitFullscreen || 
			document.webkitExitFullscreen ||
			document.mozCancelFullScreen ||
			document.msExitFullscreen;

    try {
      // await exitFullscreen();
      method.call(document);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const toggleFullscreenMode = useCallback(async () => {
    if (!fullscreenEnabled) {
      throw new Error('fullscreen is not available');
    }

    if(isFullscreen) {
      exitFullscreenMode();
    } else {
      requestFullscreenMode();
    }
  }, [isFullscreen, exitFullscreenMode, requestFullscreenMode]);

  return { isFullscreenAvailable: !!fullscreenEnabled, isFullscreen, isNativeFullscreen, toggleFullscreenMode, setFullscreenMode: setIsFullscreen };
}

export default useFullscreen;