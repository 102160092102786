import { BaseControlMessage } from './BaseControlMessage';
export const PEERPONGMESSAGE_KEY = 'peer.pong';
/**
 * Represents the PeerPongMessage type.
 */
export class PeerPongMessage extends BaseControlMessage {
    constructor(targetId) {
        super();
        this.type = PEERPONGMESSAGE_KEY;
        this.targetId = targetId;
    }
    static fromJson(data) {
        if (data.type !== PEERPONGMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for PeerPongMessage.');
        }
        const input = data;
        const instance = new PeerPongMessage(input.targetId);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            targetId: this.targetId,
        };
    }
}
PeerPongMessage.MESSAGE_NAME = PEERPONGMESSAGE_KEY;
