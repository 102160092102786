import React, { useState, useEffect } from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ITrayItem } from './TrayContainer';

interface BlinkingMenuItemProps extends MenuItemProps {
  onIcon?: React.ReactNode;
  blinkIcon?: React.ReactNode;
  offIcon?: React.ReactNode;
  on?: boolean;
  blink?: boolean;
}

const TrayItemIconMenuItem = (props: ITrayItem) => {

  const { title, icon, selected, onClick } = props;

  /*
  // Destructure our custom props from the `rest` which 
  // are `IconButtonProps` for our internal IconButton
  const {
    onIcon,
    blinkIcon,
    offIcon,
    on,
    blink,
    ...rest
  } = props;

  const [cloudIsSolid, setcloudIsSolid] = useState(false);
  useEffect(() => {
    let cloudBlinkInterval: number = 0;

    if(blink) {
      cloudBlinkInterval = window.setInterval(() => {
        setcloudIsSolid(c => !c);
      }, 1000);
    }

    return () => { clearInterval(cloudBlinkInterval); }
  }, [blink]);

  const getIcon = function(): React.ReactNode {
    if(props.disabled) {
      return offIcon;
    }

    if(blink) {
      // We want a blinking icon
      return cloudIsSolid ? onIcon: blinkIcon;
    } else {
      // We want a steady on or off icon.
      return on ? onIcon : offIcon;
    }
  }
  */

  return (
    <MenuItem
      selected={selected}
      onClick={onClick}
    >
      {(icon !== undefined) && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>{title}</ListItemText>
    </MenuItem>
  );
};

export default TrayItemIconMenuItem;