import React from 'react';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Divider from '@mui/material/Divider';

import useConfigurationStore from '../../../../state/ConfigurationStore';
import useInputMiddleware, { InputMiddlewareProps } from '../../../../hooks/useInputMiddleware';
import isHotkey from 'is-hotkey';
import { SetScrollSpeedMessage } from '@fluidprompter/core';

import { useTranslation } from 'react-i18next';
import useVisualViewport from '../../../../hooks/useVisualViewport';
import { useAppController } from '../../../../controllers/AppController';

enum SpeedPopoverState {
  RootMenu = 0,
  SavePreset = 1,
  RecallPreset = 2,
}
const Numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

interface SpeedPopoverProps {
  anchorEl: HTMLElement;
}

const SpeedPopover = (props: SpeedPopoverProps) => {
  const { anchorEl } = props;
  const appController = useAppController();

  const { t } = useTranslation('prompter');

  const { visualViewport, visualViewportReduced } = useVisualViewport();

  const [speedPopoverState, setSpeedPopoverState] = React.useState<SpeedPopoverState>(SpeedPopoverState.RootMenu);

  const scrollSpeedLocked = useConfigurationStore(state => state.scrollSpeedLocked);
  const hideScrollSpeed = useConfigurationStore(state => state.hideScrollSpeed);

  //
  // Take over processing of human inputs while this modal dialog is open.
  //
  const inputMiddlewareProps = React.useMemo<InputMiddlewareProps>(() => {
    const results: InputMiddlewareProps = {
      exclusive: true,  // Prevent all user input hooks above this one from receiving input while this model is open.
      keyboard: [{
        hotkeys: 'enter',
        keydown: (e) => {
          e.preventDefault();
          appController.dispatchMessage('prompter.local.presetmenu.close');
        }
      }, {
        hotkeys: 'L',
        keydown: (e) => {
          e.preventDefault();

          appController.dispatchMessage('prompter.local.speed.togglelock');
          appController.dispatchMessage('prompter.local.presetmenu.close');
        }
      }, {
        hotkeys: 'S',
        keydown: (e) => {
          e.preventDefault();
          if(speedPopoverState === SpeedPopoverState.RootMenu) {
            setSpeedPopoverState(SpeedPopoverState.SavePreset);
          }
        }
      }, {
        hotkeys: 'R', // Recall Preset
        keydown: (e) => {
          e.preventDefault();
          if(speedPopoverState === SpeedPopoverState.RootMenu) {
            setSpeedPopoverState(SpeedPopoverState.RecallPreset);
          }
        }
      }, {
        hotkeys: 'H',
        keydown: (e) => {
          e.preventDefault();

          appController.dispatchMessage('prompter.local.speed.togglehide');
          appController.dispatchMessage('prompter.local.presetmenu.close');
        }
      }, {
        hotkeys: Numbers,
        keydown: (e) => {
          if(e.isEditableFocused) {
            return;
          }

          const presetNumber = parseInt(e.key, 10);
          e.preventDefault();
          if(speedPopoverState === SpeedPopoverState.SavePreset) {
            appController.dispatchMessage('prompter.local.savepreset', {
              presetNumber,
            });
            appController.dispatchMessage('prompter.local.presetmenu.close');
          }
          if(speedPopoverState === SpeedPopoverState.RecallPreset) {
            appController.dispatchMessage('prompter.local.recallpreset', {
              presetNumber,
            });
            appController.dispatchMessage('prompter.local.presetmenu.close');
          }
        }
      }]
    };

    return results;
  }, [speedPopoverState, appController]);
  useInputMiddleware(inputMiddlewareProps);

  /*
  useEffect(() => {
    if(visualViewportReduced) {
      alert('visualViewportReduced');
    }
  }, [visualViewportReduced]);
  */
  //
  // On Ben's iPad Pro PWA, the window.innerHeight is reduced by the amount of the visualViewport.offsetTop when the soft keyboard is opened.
  //
  // TODO: const iOSKeyboardHeight = (window.innerHeight + visualViewport.offsetTop) - visualViewport.height;
  //const toolbarBottom = ((window.innerHeight - visualViewport.height) < 1) ? 76 : (window.innerHeight - visualViewport.height + 24);

  const popoverPosition: Partial<PopoverProps> = visualViewportReduced ? {
    anchorReference: 'anchorPosition',
    anchorPosition: { top: (visualViewport.height / 2) + visualViewport.offsetTop, left: visualViewport.width / 2 },
    anchorOrigin: {
      vertical: 'center',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'center',
      horizontal: 'center',
    }
  } : {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    }
  };

  return (
    <Popover 
      open={(anchorEl !== null)}
      anchorEl={anchorEl}
      onClose={() => {
        appController.dispatchMessage('prompter.local.presetmenu.close');
      }}
      {...popoverPosition}
    >
      {speedPopoverState === SpeedPopoverState.RootMenu && <>
        <Typography sx={{ p: 2 }}>{t('speedmenu.title')}</Typography>
        <TextField
          autoFocus={true}
          fullWidth 
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          onKeyDown={(e) => {
            if(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].indexOf(e.key) < 0) {
              // Not a number digit key.
              e.preventDefault();
            }
            
            if (isHotkey('enter', e)) {
              e.preventDefault();

              const inputEl = e.target as HTMLInputElement;
              if(inputEl) {
                let proposedSpeed = parseInt(inputEl.value, 10);
                if (isNaN(proposedSpeed)) { return; }
                
                if(proposedSpeed < 0) { proposedSpeed = 0; }
                if(proposedSpeed > 500) { proposedSpeed = 500; }

                // setScrollSpeedThrottled(proposedSpeed);
                appController.dispatchMessage(new SetScrollSpeedMessage(proposedSpeed));
              }
              // setAnchorEl(null);
              appController.dispatchMessage('prompter.local.presetmenu.close');
              return;
            }
          }}
          onBlur={(e) => {
            const inputEl = e.target as HTMLInputElement;
            if(inputEl) {
              let proposedSpeed = parseInt(inputEl.value, 10);
              if (isNaN(proposedSpeed)) { return; }
              
              if(proposedSpeed < 0) { proposedSpeed = 0; }
              if(proposedSpeed > 500) { proposedSpeed = 500; }

              // setScrollSpeedThrottled(proposedSpeed);
              appController.dispatchMessage(new SetScrollSpeedMessage(proposedSpeed));
            }
            // setAnchorEl(null);
            appController.dispatchMessage('prompter.local.presetmenu.close');
            return;
          }}
        />
        <List disablePadding>
          {/* Manage Presets */}
          <ListItemButton
            sx={{
              minWidth: 300,
            }}
            onClick={() => {
              appController.dispatchMessage('prompter.local.speed.togglelock');
              appController.dispatchMessage('prompter.local.presetmenu.close');
            }}
          >
            <ListItemText
              primary={scrollSpeedLocked ? t('speedmenu.unlockspeed') : t('speedmenu.lockspeed')}
            />
            <ListItemSecondaryAction style={{ height: '24px' }}>L</ListItemSecondaryAction>
          </ListItemButton>
          <Divider />
          <ListItemButton
            onClick={() => {
              setSpeedPopoverState(SpeedPopoverState.SavePreset);
            }}
          >
            <ListItemText
              primary={t('speedmenu.savepreset')}
            />
            <ListItemSecondaryAction style={{ height: '24px' }}>S</ListItemSecondaryAction>
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setSpeedPopoverState(SpeedPopoverState.RecallPreset);
            }}
          >
            <ListItemText
              primary={t('speedmenu.recallpreset')}
            />
            <ListItemSecondaryAction style={{ height: '24px' }}>R</ListItemSecondaryAction>
          </ListItemButton>

          <Divider />
          <ListItemButton
            sx={{
              minWidth: 300,
            }}
            onClick={() => {
              appController.dispatchMessage('prompter.local.speed.togglehide');
              appController.dispatchMessage('prompter.local.presetmenu.close');
            }}
          >
            <ListItemText
              primary={hideScrollSpeed ? t('speedmenu.unhidespeed') : t('speedmenu.hidespeed')}
            />
            <ListItemSecondaryAction style={{ height: '24px' }}>H</ListItemSecondaryAction>
          </ListItemButton>
        </List>
      </>}
      {speedPopoverState === SpeedPopoverState.SavePreset && <List disablePadding>
        <ListItem
          sx={{
            minWidth: 240,
            textTransform: 'uppercase',
          }}
        >{t('speedmenu.savepreset')}</ListItem>
        {Numbers.map((number) => (
          <ListItemButton
            key={`SavePreset${number}`}
            onClick={() => {
              appController.dispatchMessage('prompter.local.savepreset', {
                presetNumber: parseInt(number, 10),
              });  
              appController.dispatchMessage('prompter.local.presetmenu.close');
            }}
          >
            <ListItemText
              primary={`${t('speedmenu.preset')} ${number}`}
            />
            <ListItemSecondaryAction style={{ height: '24px' }}>{number}</ListItemSecondaryAction>
          </ListItemButton>
        ))}
      </List>}
      {speedPopoverState === SpeedPopoverState.RecallPreset && <List disablePadding>
        <ListItem
          sx={{
            minWidth: 240,
            textTransform: 'uppercase',
          }}
        >{t('speedmenu.recallpreset')}</ListItem>
        {Numbers.map((number) => (
          <ListItemButton
            key={`RecallPreset${number}`}
            onClick={() => {
              appController.dispatchMessage('prompter.local.recallpreset', {
                presetNumber: parseInt(number, 10),
              });  
              appController.dispatchMessage('prompter.local.presetmenu.close');
            }}
          >
            <ListItemText
              primary={`${t('speedmenu.preset')} ${number}`}
            />
            <ListItemSecondaryAction style={{ height: '24px' }}>{number}</ListItemSecondaryAction>
          </ListItemButton>
        ))}
      </List>}
    </Popover>
  );
};

export default SpeedPopover;