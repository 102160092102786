import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

const StyledButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  bottom: 0,  
  width: '100%',
  color: '#fff',
  /*border: "4px solid red",*/
  borderRadius: 1,
});

interface AspectRatioButtonProps extends IconButtonProps {
  heightPercentage?: number,
}

function AspectRatioIconButton({ heightPercentage, children, ...rest }: AspectRatioButtonProps) {
  let hp = heightPercentage || 100;
  if(hp < 1) { hp = 1; }
  if(hp > 100) { hp = 100; }

  return (
    <Box sx={{
      position: 'relative',
      height: 0,
      paddingTop: `${hp}%`
    }}>
      <StyledButton {...rest}>
        {children}
      </StyledButton>
    </Box>
  );
}

export default AspectRatioIconButton;