import { createTheme, ThemeOptions } from '@mui/material/styles';

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: '#000',
    },
    primary: {
      main: '#fff',
    },
    /*secondary: {
      main: '#f50057',
    },*/
    success: {
      contrastText: '#fff',
      main: '#008000', // '#81d51b',
    },
    warning: {
      main: '#eed202',  // WAS #ed6c02
    },
    error: {
      main: '#cd071e',
    },
    info: {
      main: '#0288d1',
    },
  },
  typography: {
    fontSize: 14, // 14px is the default Mui fontSize, making this bigger makes everything bigger!
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overscrollBehavior: 'none',
        },
        body: {
          overscrollBehavior: 'none',
        }
      }
    },
    /*MuiButtonBase: {
      styleOverrides: {
        root: {
        }
      }
    },*/
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'small' && {
            fontSize: '1.0rem'
          }),
          ...(ownerState.size === 'medium' && {
            fontSize: '1.3rem'
          }),
          ...(ownerState.size === 'large' && {
            fontSize: '1.5rem'
          })
          /*...(ownerState.size === 'small' && {
            '& > *:nth-of-type(1)': {
              fontSize: 18.1,
            },
          }),
          ...(ownerState.size === 'medium' && {
            '& > *:nth-of-type(1)': {
              fontSize: '2.25em',
            },
          }),
          ...(ownerState.size === 'large' && {
            '& > *:nth-of-type(1)': {
              fontSize: '2.25em',
            },
          }),*/
          /*
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: '#202020',
              color: '#fff',
            }),
            */
        }),
        startIcon: ({ ownerState }) => ({
          ...(ownerState.size === 'small' && {
            '& > *:nth-of-type(1)': {
              fontSize: '1.25em',
            },
          }),
          ...(ownerState.size === 'medium' && {
            '& > *:nth-of-type(1)': {
              fontSize: '1.25em',
            },
          }),
          ...(ownerState.size === 'large' && {
            '& > *:nth-of-type(1)': {
              fontSize: '1.5rem',
            },
          }),
        }),
      }
    },
    MuiAppBar: {
      defaultProps: {
        position: 'fixed',
        elevation: 0,
        color: 'transparent',
      },
      styleOverrides: {
        colorTransparent: {
          color: '#fff'
        }
      }
    }
  }
};

const themeInstance = createTheme(darkThemeOptions);

export default themeInstance;