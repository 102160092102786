import { StateCreator } from 'zustand';
import { IPrompterAttributesSlice } from './PrompterAttributesSlice';
import { IDeviceState } from '../../devices/BaseDevice';

export interface IConnectedDevicesSlice
//  extends IPrompterAttributesSlice
{
  connectedDevices: IDeviceState[];
  connectedDevicesAddOrUpdate: (device: IDeviceState) => void;
  connectedDevicesRemove: (device: IDeviceState) => void;
  isLeader: boolean;
  currentLeaderId: string;
  setCurrentLeaderId: (currentLeaderId: string) => void;
}

const createConnectedDevicesSlice: StateCreator<
  IPrompterAttributesSlice & IConnectedDevicesSlice,
  [],
  [],
  IConnectedDevicesSlice
> = (set, get) => ({
  connectedDevices: [],
  connectedDevicesAddOrUpdate: (device: IDeviceState) => set((state) => {
    if(!device) {
      throw new Error('BaseDevice is required.');
    }

    const otherDevices = state.connectedDevices.filter((existingDevice) => existingDevice.id !== device.id);
    const connectedDevices = [ ...otherDevices, device ];

    return { connectedDevices };
  }),
  connectedDevicesRemove: (device: IDeviceState) => set((state) => {
    if(!device) {
      throw new Error('BaseDevice is required.');
    }

    const connectedDevices = state.connectedDevices.filter((existingDevice) => existingDevice.id !== device.id);
    return { connectedDevices };
  }),
  isLeader: false,
  currentLeaderId: '',
  setCurrentLeaderId: (currentLeaderId: string) => set((state) => {
    return {
      currentLeaderId,
      isLeader: currentLeaderId === state.instanceId,
    };
  }),

  /*
  remoteEndpointJoin: (endpoint: PrompterSessionEndpointApp) => {
    set((state) => {
      if(!endpoint) {
        throw new Error('PrompterSessionEndpointApp is required.');
      }

      const existingEntry = state.remoteEndpoints.find((value) => value.endpointId === endpoint.endpointId);
      if(!existingEntry) {
        const remoteEndpoints = [ ...state.remoteEndpoints, endpoint ];

        // Sort all endpoints joined to this prompter session.
        // Top item should be "This Endpoint", the rest sorted by timestamp they joined, first join
        // to most recent join in that order.
        remoteEndpoints.sort((a, b) => {
          const scoreA = a.isThisInstance ? 0 : a.connectedTimestamp;
          const scoreB = b.isThisInstance ? 0 : b.connectedTimestamp;
          return scoreA - scoreB;
        });

        return { remoteEndpoints };
      }

      existingEntry.endpointName = endpoint.endpointName;
      existingEntry.role = endpoint.role;
      existingEntry.connectedTimestamp = endpoint.connectedTimestamp;
      existingEntry.disconnected = endpoint.disconnected;

      existingEntry.isThisInstance = endpoint.isThisInstance;

      // De-duplicate if multiple join messages are received. We will drop any existing record
      // first before adding the PrompterSessionEndpointApp passed into this method.
      const otherEndpoints = state.remoteEndpoints.filter((item) => item.endpointId !== endpoint.endpointId);
      const remoteEndpoints = [ ...otherEndpoints, endpoint ];

      // Sort all endpoints joined to this prompter session.
      // Top item should be "This Endpoint", the rest sorted by timestamp they joined, first join
      // to most recent join in that order.
      remoteEndpoints.sort((a, b) => {
        const scoreA = a.isThisInstance ? 0 : a.connectedTimestamp;
        const scoreB = b.isThisInstance ? 0 : b.connectedTimestamp;
        return scoreA - scoreB;
      });

      return { remoteEndpoints };
    });
  },
  */
});

export default createConnectedDevicesSlice;