import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import { useTranslation } from 'react-i18next';

enum CallbackStatus {
  None,
  Success,
  Failure,
}

export type SaveCallback = (err: Error | undefined, data: unknown) => void;
export type SaveHandler = (callback: SaveCallback) => void;

interface SectionSaveButtonProps {
  buttonText: string;
  disabled?: boolean;
  onSave?: SaveHandler;
}

const SectionSaveButton = function (props: SectionSaveButtonProps) {
  const { t } = useTranslation('account');

  const [isSaving, setIsSaving] = useState(false);
  const [callbackStatus, setCallbackStatus] = useState<CallbackStatus>(CallbackStatus.None);

  const saveCallback = (err: Error | undefined) => {
    setIsSaving(false);
    setTimeout(() => {
      setCallbackStatus(CallbackStatus.None);
    }, 3500);

    if(err) {
      // Something didn't go right...
      setCallbackStatus(CallbackStatus.Failure);
      return;
    }

    // If we get here, save was successful!
    setCallbackStatus(CallbackStatus.Success);
  };

  const handleSave = () => {
    if(props.onSave) {
      setIsSaving(true);
      props.onSave(saveCallback);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
    >
      {isSaving && (
        <CircularProgress size={30}/>
      )}
      {(callbackStatus === CallbackStatus.Success) && <CheckIcon fontSize="large" color="success" />}
      {(callbackStatus === CallbackStatus.Failure) && <ErrorIcon fontSize="large" color="error" />}
      <Button
        variant="contained"
        disabled={props.disabled || isSaving}
        onClick={handleSave}
        color={(callbackStatus === CallbackStatus.Success) ? 'success' : (callbackStatus === CallbackStatus.Failure) ? 'error' : 'primary'}
      >{isSaving ? t('savebutton.saving') : (callbackStatus === CallbackStatus.Success) ? t('savebutton.saved') : (callbackStatus === CallbackStatus.Failure) ? t('savebutton.errortryagain') : props.buttonText}</Button>
    </Stack>
  );
};

export default SectionSaveButton;