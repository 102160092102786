import ZustandStoreSlice from '../utils/ZustandStoreSlice';

export interface IPrompterControlsSlice {
  mouseControlsEnabled: boolean;
  setMouseControlsEnabled: (mouseControlsEnabled: boolean) => void;

  alwaysShowNavigation: boolean;
  setAlwaysShowNavigation: (alwaysShowNavigation: boolean) => void;

  enableIkanRemoteNumberKeys: boolean;
  setEnableIkanRemoteNumberKeys: (enableIkanRemoteNumberKeys: boolean) => void;
}

const createPrompterControlsSlice: ZustandStoreSlice<IPrompterControlsSlice> = (set) => ({
  mouseControlsEnabled: true,
  setMouseControlsEnabled: (mouseControlsEnabled: boolean) => set(() => ({ mouseControlsEnabled })),

  alwaysShowNavigation: false,
  setAlwaysShowNavigation: (alwaysShowNavigation: boolean) => set(() => ({ alwaysShowNavigation })),

  enableIkanRemoteNumberKeys: false,
  setEnableIkanRemoteNumberKeys: (enableIkanRemoteNumberKeys: boolean) => set(() => ({ enableIkanRemoteNumberKeys })),
});

export default createPrompterControlsSlice;