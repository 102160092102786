import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import BlinkingIconButton from '../BlinkingIconButton';
import { ITrayItem } from './TrayContainer';

interface TooltipWrapperProps {
  tooltip?: string;
  children: React.ReactElement;
}
const TooltipWrapper = (props: TooltipWrapperProps) => {
  const { tooltip, children } = props;

  if(!tooltip) { return children; }

  return (<Tooltip title={tooltip} arrow>{children}</Tooltip>);
};

const TrayItemIconButton = (props: ITrayItem) => {
  return (
    <TooltipWrapper tooltip={props.title}>
      <BlinkingIconButton
        offIcon={props.icon}
        onFocus={(e) => { e.target.blur(); }}
        onClick={props.onClick}
      />
    </TooltipWrapper>
  );
};

export default TrayItemIconButton;