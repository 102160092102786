import React, { useState } from 'react';

import { DeviceComponent, DeviceComponentProps } from '../../BaseDevice';
import YCOnionRemoteMenu from './YCOnionRemoteMenu';
// import YCOnionRemoteMenuDialog from './YCOnionRemoteMenuDialog';

const YCOnionRemoteUI = (props: DeviceComponentProps) => {
  const { onClose } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (<>
    <YCOnionRemoteMenu {...props} setDialogOpen={setDialogOpen} />
    {/* {dialogOpen && <YCOnionRemoteMenuDialog onClose={() => {
      // Close dialog - should we close menu too?
      setDialogOpen(false);
      onClose();
    }} />} */}
  </>);
};

export default YCOnionRemoteUI;