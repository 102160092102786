import { ShepherdOptionsWithType } from 'react-shepherd';
import { offset} from '@floating-ui/dom';

import { TourStepFactoryMethod, TourStepFactoryParams } from './TourStepTypes';

const CenterControlsTourStepFactory: TourStepFactoryMethod = (params: TourStepFactoryParams) => {
  const { t } = params;

  const CenterControlsTourStep: ShepherdOptionsWithType = {
    id: 'CenterControls',
    text: [t('welcometour.controlsstep.body')],
    // text: [
    //   `<p>Prompter controls, such as play/pause/edit, can be found at the bottom of the screen.</p>
    //
    //   <p>
    //   You can also use the <kbd>space</kbd> or <kbd>enter</kbd> keys to toggle play/pause and <kbd>ctrl</kbd> + <kbd>space</kbd> or <kbd>ctrl</kbd> + <kbd>enter</kbd> keys to toggle edit mode.
    //   </p>
    //
    //   <p><b><em>That's it! Let's go!</em></b></p>`
    // ],
    attachTo: { element: '.TourTargetCenterControls', on: 'top' },
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: t('back')
      },
      {
        type: 'next',
        text: t('welcometour.controlsstep.next')
      }
    ],
    when: {
      show: () => {
        // const currentStep = Shepherd.activeTour?.getCurrentStep();
        // const currentStepElement = currentStep?.getElement();
        const shepherdElements = document.querySelectorAll('[data-shepherd-step-id="CenterControls"]');
        if(shepherdElements.length === 0) {
          return;
        }

        const currentStepElement = shepherdElements.item(0);
        if(!currentStepElement) {
          return;
        }

        const footer = currentStepElement.querySelector('.shepherd-footer');
        const progress = document.createElement('span');
        progress.className = 'shepherd-progress';
        // progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1} of ${Shepherd.activeTour?.steps.length}`;
        // progress.innerText = `Tip 3 of 3`;
        progress.innerHTML = t('tip', { number: '3', total: '3' });

        footer?.insertBefore(progress, currentStepElement.querySelector('.shepherd-button:first-child'));
      },
      hide: () => {
        console.log('hide step');
      }
    },
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 12, crossAxis: 0 })]
    }
  };

  return CenterControlsTourStep;
};

export default CenterControlsTourStepFactory;