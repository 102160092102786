import { useCallback } from 'react';
import { IViewportRefs } from './usePrompterViewportRefs';

import FocusGuide from '../../models/FocusGuide';
import IPrompterPosition from '../../models/segments/IPrompterPosition';
import { PrompterMode, ScrollDirection } from '@fluidprompter/core';
import usePrompterSession from '../../state/PrompterSessionState';

interface GetPrompterPositionConfigStoreSlice {
    focusGuide: FocusGuide,
    flipVertical: boolean,
}

const useGetPrompterPositionFunction = function(viewportRefs: IViewportRefs, configStore: GetPrompterPositionConfigStoreSlice) {
  const getPrompterPosition = useCallback(function() {
    const scrollingEl = document.scrollingElement;
    const prompterContentEl = viewportRefs.segmentsContainerRef.current?.prompterContentRef.current;
    if(!scrollingEl || !prompterContentEl) {
      return;
    }

    // Grab the relevent position metrics from the scroller element.
    const scrollPosition = scrollingEl.scrollTop;
    const viewportHeight = scrollingEl.clientHeight;
    const contentHeight = scrollingEl.scrollHeight;

    // Get line height in px, we will use this later when  computing segment positions.
    // When computing segment positions, we really want to know whether the first line 
    // of any segment is at the top of the screen, bottom of the screen or on the cue point.
    const computedStyle = window.getComputedStyle(prompterContentEl);
    const lineHeight = parseInt(computedStyle.lineHeight);

    // ****************************************
    // Prepare our IPrompterPosition object for calculating segment positions.
    // ****************************************
    const viewportPosition: IPrompterPosition = {
      prompterMode: usePrompterSession.getState().prompterMode,
      viewportTop: scrollPosition,
      viewportHeight,
      viewportBottom: scrollPosition + viewportHeight,
      contentHeight,
      lineHeight,
      //-- prompterMode: PrompterState,
      // scrollDirection: ScrollDirection,
      // scrollPosition: number,
      //-- contentHeight: number,
      //-- lineHeight: number,
      //-- viewportTop: number,
      //-- viewportHeight: number,
      //-- viewportBottom: number,
      //-- viewportCueTop: number,
      //-- viewportCueBottom: number,
      scrollDirection: ScrollDirection.None, 
      scrollPosition, 
      viewportCueTop: -1, 
      viewportCueCenter: -1, 
      viewportCueBottom: -1
    };

    // ****************************************
    // Calculate the top and bottom position for the Cue point.
    // ****************************************
    let focusPosition = configStore.focusGuide;
    if(configStore.flipVertical && configStore.focusGuide === FocusGuide.Top) {
      focusPosition = FocusGuide.Bottom;
    }
    if(configStore.flipVertical && configStore.focusGuide === FocusGuide.Bottom) {
      focusPosition = FocusGuide.Top;
    }
    switch(focusPosition) {
      case FocusGuide.Top:
        viewportPosition.viewportCueCenter = scrollPosition + (viewportHeight * 20 / 100);
        break;
      case FocusGuide.Middle:
      case FocusGuide.None:
      default:
        viewportPosition.viewportCueCenter = scrollPosition + (viewportHeight / 2);
        break;
      case FocusGuide.Bottom:
        viewportPosition.viewportCueCenter = scrollPosition + (viewportHeight * 80 / 100);
        break;
    }
    viewportPosition.viewportCueTop = viewportPosition.viewportCueCenter - (lineHeight / 2);
    viewportPosition.viewportCueBottom = viewportPosition.viewportCueTop + lineHeight;

    return viewportPosition;
  }, [viewportRefs.segmentsContainerRef, configStore.focusGuide, configStore.flipVertical]);


  return getPrompterPosition;
};

export default useGetPrompterPositionFunction;