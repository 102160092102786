import React from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import AccountHeader from '../AccountHeader/AccountHeader';
import BillingSummaryPanel from './BillingSummaryPanel';
import FreePlanPanel from './FreePlanPanel';
import ProPlanPanel from './ProPlanPanel';
import StudioPlanPanel from './StudioPlanPanel';

import { useTranslation } from 'react-i18next';
import useApplicationContext from '../../../hooks/useApplicationContext';
import useApplicationState from '../../../state/ApplicationState';
import { shallow } from 'zustand/shallow';

import { styled } from '@mui/material';
import './BillingAndPlansView.scss';

const HeaderButton = styled(Button)({
  minWidth: 'fit-content',
  whiteSpace: 'nowrap',
});

const BillingAndPlansView = function () {
  const { t } = useTranslation('account');

  const {
    appContext,
    subscription,
  } = useApplicationContext(false);
  const fluidprompter_plan = appContext.userProfile?.fluidprompter_plan;

  const appState = useApplicationState(state => ({
    setComparePlansOpen: state.setComparePlansOpen,
  }), shallow);

  const handleComparePlans = (
    /*e: React.MouseEvent<HTMLButtonElement>,*/
  ) => {
    appState.setComparePlansOpen(true);
  };

  const handleUpgradePlan = () => {
    subscription.startChangePlans();
  };

  const handleGoToBillingPortal = () => {
    subscription.redirectToBillingPortal();
  };

  return (
    <Stack spacing={2}>
      <Box>
        <AccountHeader title={t('billingplanssection.billingsummary.title')}></AccountHeader>
        <BillingSummaryPanel />
      </Box>
      <Box>
        <AccountHeader title={t('billingplanssection.currentplan.title')}>
          <HeaderButton
            variant="outlined"
            size="small"
            onClick={handleComparePlans}
          >{t('billingplanssection.currentplan.compareplans')}</HeaderButton>
          {(appContext.userProfile?.fluidprompter_plan !== 'studio') && <HeaderButton
            variant="contained"
            color="success"
            size="small"
            onClick={handleUpgradePlan}
          >{t('billingplanssection.currentplan.upgrade')}</HeaderButton>}
        </AccountHeader>
        {fluidprompter_plan === 'free' && <FreePlanPanel />}
        {fluidprompter_plan === 'pro' && <ProPlanPanel />}
        {fluidprompter_plan === 'studio' && <StudioPlanPanel />}
      </Box>
      <Box>
        <AccountHeader title={t('billingplanssection.billinginfo.title')}></AccountHeader>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              onClick={handleGoToBillingPortal}
            >{t('billingplanssection.billinginfo.button')}</Button>
          </Stack>
          {fluidprompter_plan !== 'free' && <Stack
            direction="row"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              onClick={() => {
                subscription.downgradeToFreePlan();
              }}
            >Downgrade to Free Plan</Button>
          </Stack>}
          {fluidprompter_plan === 'free' && <Stack
            direction="row"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              onClick={() => {
                subscription.switchToSoloCreatorPlan();
              }}
            >Activate Solo Creator Plan</Button>
          </Stack>}
        </Stack>
      </Box>
    </Stack>
  );
};

export default BillingAndPlansView;