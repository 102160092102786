import * as React from 'react';

import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { ClickAwayListener } from '@mui/base';

import AltRouteIcon from '@mui/icons-material/AltRoute';
import ReplayIcon from '@mui/icons-material/Replay';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SkipNextIcon from '@mui/icons-material/SkipNext';

import { useAppController } from '../../../controllers/AppController';
import { useTranslation } from 'react-i18next';

import { NavigateMessage, ResetMessage } from '@fluidprompter/core';

const navigateActions = [
  { icon: <ReplayIcon />, name: 'navigationtools.restart', command: new ResetMessage() },                     // 'prompter.state.reset'
  { icon: <SkipPreviousIcon />, name: 'navigationtools.previous', command: new NavigateMessage(NavigateMessage.Target.PrevSegment) }, // 'prompter.navigate.prevsegment'
  { icon: <FastRewindIcon />, name: 'navigationtools.skipback', command: new NavigateMessage(NavigateMessage.Target.PageUp) },        // 'prompter.navigate.pageup'
  { icon: <FastForwardIcon />, name: 'navigationtools.skipforward', command: new NavigateMessage(NavigateMessage.Target.PageDown) },  // 'prompter.navigate.pagedown'
  { icon: <SkipNextIcon />, name: 'navigationtools.next', command: new NavigateMessage(NavigateMessage.Target.NextSegment) },         // 'prompter.navigate.nextsegment'
];

interface NavigateSpeedDialProps {
  isLargeScreen?: boolean;
  isOpen: boolean;
  toggleOpen: () => void;
}

const NavigateSpeedDial = React.memo(function NavigateSpeedDial(props: NavigateSpeedDialProps) {

  const { t } = useTranslation('prompter');

  const appController = useAppController();

  const speedialRef = React.createRef<HTMLButtonElement>();

  return (
    <Box alignContent={'left'} width={'100%'}>
      <ClickAwayListener onClickAway={() => {
        if(props.isOpen) {
          props.toggleOpen();
        }
      }}>
        <SpeedDial
          ariaLabel={t('navigationtools.caption')}
          direction="right"
          /*sx={{ position: 'absolute', bottom: 16, right: 24 }}*/
          /*icon={<SpeedDialIcon icon={<SpeakerNotesIcon />} />}*/
          icon={props.isLargeScreen ? (<><AltRouteIcon />&nbsp;{t('navigationtools.caption')}</>) : <AltRouteIcon />}
          /*onClose={handleClose}*/
          /*onOpen={handleOpen}*/
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            const fabEl = speedialRef.current;
            if(fabEl && fabEl.contains(e.target as Node)) {
              props.toggleOpen();
            }
          }}
          open={props.isOpen}
          /*hidden={!prompterState.cursorHidden}*/
          sx={{
            width: '100%',
            whiteSpace: 'nowrap',
          }}
          FabProps={{
            ref: speedialRef,
            variant: 'extended',
            color: 'error',
            style: {
              backgroundColor: 'rgb(255,255,255,0.08)',
              minWidth: '100%',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: 'rgb(255,255,255,0.09)',
            }
          }}
        >
          {navigateActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={t(`${action.name}.tooltip`)}
              // tooltipOpen={true}
              // tooltipPlacement={'top'}
              onMouseEnter={() => {
                appController.dispatchMessage('prompter.local.HideCursorTimer.Restart');
              }}
              onFocus={(e) => { e.target.blur(); }}
              onClick={() => {
                appController.dispatchMessage('prompter.local.HideCursorTimer.Restart');

                if(!action.command) {
                  return;
                }

                appController.dispatchMessage(action.command);
              }}
              sx={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'rgb(78, 78, 78, 0.9)',
                backgroundColor: 'rgb(32, 32, 32, 0.8)',
              }}
            />
          ))}
        </SpeedDial>
      </ClickAwayListener>
    </Box>
  );
});

export default NavigateSpeedDial;