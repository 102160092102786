import { Editor, Transforms, NodeEntry, Range, Path, Node, Ancestor } from 'slate';
import { PrompterEditor, ElementTypes, PrompterElement, PrompterSegment } from '../../models/EditorTypes';

const withSlateCustomizations = <T extends PrompterEditor>(editor: T): T => {
  const { isVoid, isInline, insertBreak, deleteBackward, deleteForward, insertData, normalizeNode  } = editor;

  editor.isVoid = (element) => {
    return [ElementTypes.STARTSCRIPT, ElementTypes.PAUSE, ElementTypes.ENDSCRIPT, 'kbd', ElementTypes.BROLL, ElementTypes.IMAGE].includes(element.type) || isVoid(element);
  };

  editor.isInline = (element) => {
    // Our custom FluidPrompter nodes that are inline elements.
    if(
      //
      // Some of FluidPrompter's code appears to sometimes call isInline() with an element that
      // has no type or text. I wonder if this is a bug in `Text.isText()` not returning true for
      // empty leaf nodes?
      //
      // For now, let's just use `element?.type` to protect ourselves!
      //
      // usePrompterContentResizedHandler:
      // isBlock = !(Text.isText(currentNode) || Editor.isInline(editor, currentNode));
      //
      // useRecalculateSegmentPositionsHandler:
      // isInline = Text.isText(prompterDescendant) || Editor.isInline(editor, prompterDescendant);
      //
      [
        'kbd',
        ElementTypes.HYPERLINK,
        'link'
      ].includes(element.type)
    ) {
      return true;
    }

    // Fall back to the built-in method for testing isInline.
    return isInline(element);
  };

  editor.insertBreak = () => {

    // Find the native DOM element from a Slate `node`.
    /*
     * NOT NECESSARY - we are enforcing the start of script and end of script nodes in normalizeNode function now.
     *
    if(isStartNodeOrEndNode(editor)) {
      //ReactEditor.toDOMNode(editor, node);
      alert('You can\'t press enter on a void node!');
      return;
    }*/
    //console.log(`editor.insertBreak current selection:`, editor.selection);

    if (!editor.selection || !Range.isCollapsed(editor.selection)) {
      return insertBreak();
    }

    const selectedNodePath = Path.parent(editor.selection.anchor.path);
    const selectedNode = Node.get(editor, selectedNodePath);
    //console.log(`Selected node when enter pressed:`, selectedNode);
    if (Editor.isVoid(editor, selectedNode)) {
      Editor.insertNode(editor, {
        type: ElementTypes.PARAGRAPH,
        children: [{ text: '' }],
      });
      return;
    }

    // If the current selection is a void type, suppress the default behavior and insert a break after the currently selected void type.
    insertBreak();
  };

  // deleteBackward: (unit: 'character' | 'word' | 'line' | 'block') => void
  editor.deleteBackward = (unit) => {

    /*
    if (editor.selection && Range.isCollapsed(editor.selection) && (editor.selection.anchor.offset === 0)) {
      // We are at the very start of a node, therefor to check if we can backspace, we need to check the previous node for eligbility.
      // Editor.previous: <T extends Node>(editor: Editor, options?: EditorPreviousOptions<T>) => NodeEntry<T> | undefined;
      const nodeEntry = Editor.previous(editor, {
        at: editor.selection,
        match: (node, path): boolean => {
          return true;
        },
        mode: 'all',
        voids: true,
      });

      if(nodeEntry) {
        const [previousNode, previousPath] = nodeEntry;
        console.log('Previous Node', previousNode, previousPath);

        if(previousNode) {
          const previousNodeAsAny = previousNode as any;
          if(previousNodeAsAny.type === ElementTypes.STARTSCRIPT) {
            alert('you aren\'t allowed to perform a backspace right now');
            return;
          }
        }
      }
    } */

    // Editor.next: <T extends Descendant>(editor: Editor, options?: EditorNextOptions<T>) => NodeEntry<T> | undefined;
    // Editor.previous: <T extends Node>(editor: Editor, options?: EditorPreviousOptions<T>) => NodeEntry<T> | undefined;
    // Editor.previous

    //console.log('deleteBackward');
    deleteBackward(unit);
  };

  // deleteForward: (unit: 'character' | 'word' | 'line' | 'block') => void
  editor.deleteForward = (unit) => {
    //console.log('deleteForward');
    deleteForward(unit);
  };

  editor.insertData = (data) => {
    // const html = data.getData('text/html');

    console.log('insertData', data);

    /*
    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html')
      const fragment = deserialize(parsed.body)
      Transforms.insertFragment(editor, fragment)
      return
    }
    */

    insertData(data);
  };

  /* normalizeNode is called from the deepest node changed up the tree to the root. It's job is to
   * fix any errors resulting from browser contentEditable. For example two consecutive text nodes
   * with the exact same marks can be merged into 1 node.
   */
  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    const [ node, nodePath ] = entry;

    //
    // Make sure our script always starts with a `ElementTypes.STARTSCRIPT` node!
    //
    if(Editor.isEditor(node)) {
      const nodeData = node as Ancestor;
      const firstNode = nodeData.children[0] as PrompterElement;
      if(firstNode.type !== ElementTypes.STARTSCRIPT) {
        // The first node in our editor is NOT a start of script element.
        // Let's insert a startscript node now!
        Transforms.insertNodes<PrompterElement>(editor, {
          type: ElementTypes.STARTSCRIPT,
          children: [{ text: '' }]
        } as Node, {
          at: [0]
        });
      }

      const childrenLength = nodeData.children.length;
      const lastNode = nodeData.children[childrenLength - 1] as PrompterElement;
      if(lastNode.type !== ElementTypes.ENDSCRIPT) {
        // The first node in our editor is NOT a start of script element.
        // Let's insert a startscript node now!
        Transforms.insertNodes(editor, {
          type: ElementTypes.ENDSCRIPT,
          children: [{ text: '' }]
        } as Node, {
          at: [childrenLength]
        });
      }
    }

    //
    // Re-number our first 1-9 'ElementTypes.SCRIPT_SEGMENT' nodes as the user can jump to those segments with shortcut-keys.
    //
    if(Editor.isEditor(node)) {
      // All root nodes must by definition be block elements with children. This is basically just typescript stuff to maintain typings.
      const nodeData = node as Ancestor;
      const rootChildren = nodeData.children.map((value) => (value as PrompterElement));

      // Loop over all root children and number them, skipping any root children that are not of type 'paragraph'
      let segmentCounter = 0;
      rootChildren.forEach((rootChild, index) => {
        const rootChildPath = [...nodePath, index];

        if(rootChild.type !== ElementTypes.SCRIPT_SEGMENT) {
          return;
        }

        segmentCounter++;

        const segmentNumber = (segmentCounter < 10 ? segmentCounter : undefined);

        if(rootChild.number !== segmentNumber) {
          // Assign the paragraphNumbder to the first 9 paragraphs found in the editor root - otherwise assign -1 to our node.
          Transforms.setNodes<PrompterSegment>(editor, { number: segmentNumber }, { at: rootChildPath });
        }
      });
    }

    //
    // Make sure a SegmentElement always contains at least 1 paragraph node child.
    //
    const nodePrompterElement = node as PrompterElement;
    if(nodePrompterElement.type === ElementTypes.SCRIPT_SEGMENT) {

      const rootChildren = nodePrompterElement.children.map((value) => (value as PrompterElement));

      rootChildren.forEach((rootChild, index) => {
        const rootChildPath = [...nodePath, index];

        if(rootChild.type !== ElementTypes.PARAGRAPH) {
          /*
          console.log('Found child of SCRIPT_SEGMENT that is not a paragraph. Wrap it!');
          setTimeout(() => {
            alert('Found child of SCRIPT_SEGMENT that is not a paragraph. Wrap it!');
          }, 1000);
          */

          // A child of SCRIPT_SEGMENT is not a paragraph. Wrap this node with a paragraph.
          Transforms.wrapNodes(editor, {
            type: ElementTypes.PARAGRAPH,
            children: []
          }, {
            at: rootChildPath
          });
        }
      });
    }
    normalizeNode(entry);
  };

  return editor;
};

export default withSlateCustomizations;