import React from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import usePrompterSession from '../../state/PrompterSessionState';
import { useTranslation } from 'react-i18next';

interface AppLoadingProps {
  isLoading: boolean | undefined;
  loginInitiated: boolean;
}

/**
 * Handles core app loading of the user context and showing a loading indicator while asyncronous
 * loading if happening.
 * @returns
 */
const AppLoadingIndicator = (props: AppLoadingProps) => {
  const { isLoading, loginInitiated } = props;

  const { t } = useTranslation('common');

  const isSynchronizing = usePrompterSession(state => state.isSynchronizing);

  return (<>
    {(isLoading || loginInitiated || isSynchronizing) && <Backdrop open={true}>
      <CircularProgress color="inherit" />
      <span style={{
        marginLeft: '16px'
      }}>{t('notifications.loading')}</span>
    </Backdrop>}
  </>);
};

export default AppLoadingIndicator;