import * as React from 'react';

import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { ClickAwayListener } from '@mui/base';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

import useSpeechRecognitionState from '../../../state/SpeechRecognitionState';
import { shallow } from 'zustand/shallow';

import { useAppController } from '../../../controllers/AppController';

interface VoiceSpeedDialProps {
  isLargeScreen?: boolean;
  /*isOpen: boolean;*/
  toggleOpen: () => void;
}

const VoiceSpeedDial = (props: VoiceSpeedDialProps) => {

  const { isLargeScreen, toggleOpen } = props;

  const appController = useAppController();

  const speedialRef = React.createRef<HTMLButtonElement>();

  const speechRecognitionState = useSpeechRecognitionState(state => ({
    active: state.active,
    audioActive: state.audioActive,
    speechActive: state.speechActive,
  }), shallow);

  const iconJsx = (<>
    <MicIcon color={speechRecognitionState.active ? 'error' : 'primary'} />
    {isLargeScreen && <>&nbsp;Voice Typing</>}
  </>);

  return (<Box alignContent={'left'} width={'100%'}>
    <ClickAwayListener onClickAway={() => {
      // if(props.isOpen) {
      //   props.toggleOpen();
      // }
    }}>
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        direction="right"
        /*sx={{ position: 'absolute', bottom: 16, right: 24 }}*/
        /*icon={<SpeedDialIcon icon={<SpeakerNotesIcon />} />}*/
        icon={iconJsx}
        /*onClose={handleClose}*/
        /*onOpen={handleOpen}*/
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          const fabEl = speedialRef.current;
          if(fabEl && fabEl.contains(e.target as Node)) {
            toggleOpen();
          }

          // Toggle Voice Typing feature
          appController.dispatchMessage('prompter.editor.voicetyping.toggle');
        }}
        open={false}
        /*hidden={!prompterState.cursorHidden}*/
        sx={{
          width: '100%',
          whiteSpace: 'nowrap',
        }}
        FabProps={{
          ref: speedialRef,
          variant: 'extended',
          color: 'error',
          style: {
            backgroundColor: 'rgb(255,255,255,0.08)',
            minWidth: '100%',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'rgb(255,255,255,0.09)',
            animation: speechRecognitionState.speechActive ? 'ripple 2s linear infinite' : 'none',
          }
        }}
      >
      </SpeedDial>
    </ClickAwayListener>
  </Box>);
};

export default VoiceSpeedDial;