import * as React from 'react';
import { Descendant } from 'slate';

import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { ClickAwayListener } from '@mui/base';

import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import RateReviewIcon from '@mui/icons-material/RateReview';
// import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
// import BookmarkIcon from '@mui/icons-material/Bookmark';
// import LabelIcon from '@mui/icons-material/Label';

import { useAppController, useMessageHandler } from '../../../controllers/AppController';
import { useTranslation } from 'react-i18next';
import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import { NavigateMessage, PauseMessage, PlayMessage, ShotLogEntryType } from '@fluidprompter/core';

interface ShotLogUpdateMetaArgs {
  nodePath: number[],
  title?: string,
}

interface ShotLogFinalizeCheckpointArgs {
  node?: Descendant,
  nodePath?: number[],
  implied?: boolean,
}

const shotlogActions = [
  { icon: <ThumbDownAltIcon sx={{ color: '#d40201' }} />, name: 'shotlogspeeddial.badtake', command: 'shotlog.badtake' },
  { icon: <ThumbsUpDownIcon sx={{ color: '#fac603' }} />, name: 'shotlogspeeddial.mediocretake', command: 'shotlog.oktake' },
  { icon: <ThumbUpAltIcon sx={{ color: '#00b316' }} />, name: 'shotlogspeeddial.goodtake', command: 'shotlog.goodtake' },
  { icon: <RateReviewIcon />, name: 'shotlogspeeddial.review', command: 'prompter.shotlog.show' },
  // { icon: <ShareIcon />, name: 'Share' },
];

interface ShotLogSpeedDialProps {
  isLargeScreen?: boolean;
  isOpen: boolean;
  toggleOpen: () => void;
}

const ShotLogSpeedDial = React.memo(function ShotLogSpeedDial(props: ShotLogSpeedDialProps) {

  const { isLargeScreen, isOpen, toggleOpen } = props;
  const appController = useAppController();

  const { t } = useTranslation('prompter');

  const speedialRef = React.createRef<HTMLButtonElement>();

  const prompterState = usePrompterSession(state => ({
    startShotLogEntry: state.startShotLogEntry,
    updateShotLogEntry: state.updateShotLogEntry,
    finalizeShotLogEntry: state.finalizeShotLogEntry,
  }), shallow);

  useMessageHandler('shotlog.updatemeta', (e) => {
    const args = e.message.payload as ShotLogUpdateMetaArgs;
    if(!args) {
      throw new Error('ShotLogUpdateMetaArgs is required for command shotlog.updatemeta');
    }

    //
    // This is called when a script node enters the cue position to update the current shot log
    // entry's meta data such as segment title.
    //
    prompterState.updateShotLogEntry(args.nodePath, args.title);
  });

  useMessageHandler('shotlog.badtake', (e) => {
    const args = e.message.payload as ShotLogFinalizeCheckpointArgs;
    const state = usePrompterSession.getState();
    let nodePath: number[] | undefined = args?.nodePath;
    const targetNode = args?.node || state.currentScriptNode;
    if(!nodePath && targetNode) {
      nodePath = state.getScriptNodePath(targetNode);
    }

    if(!nodePath) {
      throw new Error('shotlog.goodtake could not resolve target script node.');
    }

    prompterState.finalizeShotLogEntry(nodePath, ShotLogEntryType.BadTake, (args?.implied === true));

    appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Segment, {
      segmentInstance: targetNode,
    }));

    appController.dispatchMessage(new PauseMessage());
  });

  useMessageHandler('shotlog.oktake', (e) => {
    const args = e.message.payload as ShotLogFinalizeCheckpointArgs;
    const state = usePrompterSession.getState();
    let nodePath: number[] | undefined = args?.nodePath;
    const targetNode = args?.node || state.currentScriptNode;
    if(!nodePath && targetNode) {
      nodePath = state.getScriptNodePath(targetNode);
    }

    if(!nodePath) {
      throw new Error('shotlog.goodtake could not resolve target script node.');
    }

    prompterState.finalizeShotLogEntry(nodePath, ShotLogEntryType.OkTake, (args?.implied === true));

    appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Segment, {
      segmentInstance: targetNode,
    }));

    appController.dispatchMessage(new PauseMessage());
  });

  useMessageHandler('shotlog.goodtake', (e) => {
    const args = e.message.payload as ShotLogFinalizeCheckpointArgs;
    const state = usePrompterSession.getState();
    let nodePath: number[] | undefined = args?.nodePath;
    const targetNode = args?.node || state.currentScriptNode;
    if(!nodePath && targetNode) {
      nodePath = state.getScriptNodePath(targetNode);
    }

    if(!nodePath) {
      throw new Error('shotlog.goodtake could not resolve target script node.');
    }

    prompterState.finalizeShotLogEntry(nodePath, ShotLogEntryType.GoodTake, (args?.implied === true));

    appController.dispatchMessage(new PlayMessage());
  });

  return (
    <Box alignContent={'left'} width={'100%'}>
      <ClickAwayListener onClickAway={() => {
        if(isOpen) {
          toggleOpen();
        }
      }}>
        <SpeedDial
          ariaLabel={t('shotlogspeeddial.caption')}
          direction="right"
          /*sx={{ position: 'absolute', bottom: 16, right: 24 }}*/
          /*icon={<SpeedDialIcon icon={<SpeakerNotesIcon />} />}*/
          icon={isLargeScreen ? (<><SpeakerNotesIcon />&nbsp;{t('shotlogspeeddial.caption')}</>) : <SpeakerNotesIcon />}
          /*onClose={handleClose}*/
          /*onOpen={handleOpen}*/
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            const fabEl = speedialRef.current;
            if(fabEl && fabEl.contains(e.target as Node)) {
              toggleOpen();
            }
          }}
          open={isOpen}
          /*hidden={!prompterState.cursorHidden}*/
          sx={{
            width: '100%',
            whiteSpace: 'nowrap',
          }}
          FabProps={{
            ref: speedialRef,
            variant: 'extended',
            color: 'error',
            style: {
              backgroundColor: 'rgb(255,255,255,0.08)',
              minWidth: '100%',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: 'rgb(255,255,255,0.09)',
            }
          }}
        >
          {shotlogActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={t(`${action.name}.tooltip`)}
              // tooltipOpen={true}
              // tooltipPlacement={'top'}
              onMouseEnter={() => {
                appController.dispatchMessage('prompter.local.HideCursorTimer.Restart');
              }}
              onFocus={(e) => { e.target.blur(); }}
              onClick={() => {
                appController.dispatchMessage('prompter.local.HideCursorTimer.Expire');

                // emit event
                if(action.command) {
                  // TODO, only if a string
                  appController.dispatchMessage(action.command);
                }
              }}
              sx={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'rgb(78, 78, 78, 0.9)',
                backgroundColor: 'rgb(32, 32, 32, 0.8)',
              }}
            />
          ))}
        </SpeedDial>
      </ClickAwayListener>
    </Box>
  );
});

export default ShotLogSpeedDial;