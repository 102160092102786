import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IPrompterPreset {
  scrollSpeed: number;
}

interface IPrompterPresetCollection {
  Preset1?: IPrompterPreset;
  Preset2?: IPrompterPreset;
  Preset3?: IPrompterPreset;
  Preset4?: IPrompterPreset;
  Preset5?: IPrompterPreset;
  Preset6?: IPrompterPreset;
  Preset7?: IPrompterPreset;
  Preset8?: IPrompterPreset;
  Preset9?: IPrompterPreset;
  Preset0?: IPrompterPreset;
}

export interface PrompterPresetsState {
  presets: IPrompterPresetCollection;
  setPreset: (presetNumber: number, preset: IPrompterPreset) => void;
  getPreset: (presetNumber: number) => IPrompterPreset | undefined;
}

const usePrompterPresetsState = create<PrompterPresetsState>()(persist<PrompterPresetsState>(
  (set, get) => ({
    presets: {},
    setPreset: (presetNumber: number, preset: IPrompterPreset) => set((state) => {
      const { presets } = state;

      switch(presetNumber) {
        case 1:
          return { presets: { ...presets, Preset1: preset } };
        case 2:
          return { presets: { ...presets, Preset2: preset } };
        case 3:
          return { presets: { ...presets, Preset3: preset } };
        case 4:
          return { presets: { ...presets, Preset4: preset } };
        case 5:
          return { presets: { ...presets, Preset5: preset } };
        case 6:
          return { presets: { ...presets, Preset6: preset } };
        case 7:
          return { presets: { ...presets, Preset7: preset } };
        case 8:
          return { presets: { ...presets, Preset8: preset } };
        case 9:
          return { presets: { ...presets, Preset9: preset } };
        case 0:
          return { presets: { ...presets, Preset0: preset } };
      }      

      return { presets };
    }),
    getPreset: (presetNumber: number) => {
      const state = get();
      console.log(`getPreset(${presetNumber})`, state.presets);

      switch(presetNumber) {
        case 1:
          return state.presets.Preset1;
        case 2:
          return state.presets.Preset2;
        case 3:
          return state.presets.Preset3;
        case 4:
          return state.presets.Preset4;
        case 5:
          return state.presets.Preset5;
        case 6:
          return state.presets.Preset6;
        case 7:
          return state.presets.Preset7;
        case 8:
          return state.presets.Preset8;
        case 9:
          return state.presets.Preset9;
        case 0:
          return state.presets.Preset0;
      }

      return;
    },
  }),
  {
    name: 'fp-presets', // name of item in the storage (must be unique)
  }
));

export default usePrompterPresetsState;