import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import useConfigurationStore from '../../../state/ConfigurationStore';
import useApplicationState from '../../../state/ApplicationState';
import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';
import useFullscreen from '../../../hooks/useFullscreen';
import noSleep from '../../../hooks/useNoSleep';
import { useAppController, useMessageHandler } from '../../../controllers/AppController';

import WindowMode from '../../../models/WindowMode';
import { PlayMessage, PauseMessage, EditMessage, ResetMessage, NavigateMessage } from '@fluidprompter/core';

import EditIcon from '@mui/icons-material/Edit';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import ReplayIcon from '@mui/icons-material/Replay';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SkipNextIcon from '@mui/icons-material/SkipNext';

import { useTranslation } from 'react-i18next';

interface CenterControlsProps {
  viewportIsMediumOrLarger?: boolean;
  viewportIsLargeOrLarger?: boolean;
}
const CenterControls = (props: CenterControlsProps) => {

  const { viewportIsMediumOrLarger, viewportIsLargeOrLarger } = props;
  const hideWhenCursorHidden = viewportIsMediumOrLarger;

  const { t } = useTranslation('prompter');

  const appController = useAppController();

  const [, setNoSleepEnabled] = noSleep.useNoSleepState();

  const alwaysShowNavigation = useConfigurationStore(state => state.alwaysShowNavigation);
  const windowMode = useApplicationState(state => state.windowMode);
  const pwaWindow = (windowMode === WindowMode.PWA);

  const prompterState = usePrompterSession(state => ({
    prompterMode: state.prompterMode,
    isPlaying: state.isPlaying,
    isEditing: state.isEditing,
    edit: state.edit,
    // elapsedPlayTime: state.elapsedPlayTime,
    // framesPerSecond: state.framesPerSecond,
    cursorHidden: state.cursorHidden,
  }), shallow);

  const [runningAsPWA, setRunningAsPWA] = useState<boolean>(() => {
    // See: https://web.dev/learn/pwa/detection/
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    }
    return false;
  });

  const { isFullscreenAvailable, isFullscreen, toggleFullscreenMode } = useFullscreen();

  // Add message handle for fullscreen.
  useMessageHandler('fullscreen.toggle', async (e) => {
    toggleFullscreenMode();
  });

  return (<Stack
    className="TourTargetCenterControls"
    direction={'row'}
    spacing={1}
    sx={{
      transform: (!alwaysShowNavigation && hideWhenCursorHidden && !prompterState.isEditing && prompterState.cursorHidden) ? 'translateY(125%)': 'none',
      transition: 'transform 500ms ease-in-out',
    }}
  >
    {alwaysShowNavigation && <Tooltip title={t('navigationtools.restart.tooltip')} placement="top" arrow>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label={t('navigationtools.restart.tooltip')}
        onFocus={(e) => { e.target.blur(); }}
        onClick={() => {
          setNoSleepEnabled(true);
          appController.dispatchMessage(new ResetMessage());
        }}
      ><ReplayIcon /></IconButton>
    </Tooltip>}
    {alwaysShowNavigation && <Tooltip title={t('navigationtools.previous.tooltip')} placement="top" arrow>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label={t('navigationtools.previous.tooltip')}
        onFocus={(e) => { e.target.blur(); }}
        onClick={() => {
          setNoSleepEnabled(true);
          appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PrevSegment));
        }}
      ><SkipPreviousIcon /></IconButton>
    </Tooltip>}
    {alwaysShowNavigation && <Tooltip title={t('navigationtools.skipback.tooltip')} placement="top" arrow>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label={t('navigationtools.skipback.tooltip')}
        onFocus={(e) => { e.target.blur(); }}
        onClick={() => {
          setNoSleepEnabled(true);
          appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageUp));
        }}
      ><FastRewindIcon /></IconButton>
    </Tooltip>}

    {!prompterState.isEditing && <Tooltip title={t('editbutton.tooltip')} placement="top" arrow>
      {(viewportIsLargeOrLarger || (viewportIsMediumOrLarger && !alwaysShowNavigation)) ? (
        <Button
          startIcon={ <EditIcon /> }
          onFocus={(e) => { e.target.blur(); }}
          onClick={() => {
            setNoSleepEnabled(true);
            appController.dispatchMessage(new EditMessage());
          }}
        >
          {t('editbutton.caption')}
        </Button>
      ) : (
        <IconButton
          onFocus={(e) => { e.target.blur(); }}
          onClick={() => {
            setNoSleepEnabled(true);
            appController.dispatchMessage(new EditMessage());
          }}
        ><EditIcon /></IconButton>
      )}
    </Tooltip>}

    <Tooltip title={prompterState.isPlaying ? t('pausebutton.tooltip') : t('playbutton.tooltip')} placement="top" arrow>
      {(viewportIsLargeOrLarger || (viewportIsMediumOrLarger && !alwaysShowNavigation)) ? (
        <Button
          startIcon={ prompterState.isPlaying ? <PauseIcon /> : <PlayArrowIcon /> }
          onFocus={(e) => { e.target.blur(); }}
          onClick={() => {
            setNoSleepEnabled(true);
            appController.dispatchMessage(prompterState.isPlaying ? new PauseMessage() : new PlayMessage());
          }}
        >
          {prompterState.isPlaying ? t('pausebutton.caption') : t('playbutton.caption')}
        </Button>
      ) : (
        <IconButton
          onFocus={(e) => { e.target.blur(); }}
          onClick={() => {
            setNoSleepEnabled(true);
            appController.dispatchMessage(prompterState.isPlaying ? new PauseMessage() : new PlayMessage());
          }}
        >{ prompterState.isPlaying ? <PauseIcon /> : <PlayArrowIcon /> }</IconButton>
      )}
    </Tooltip>

    {alwaysShowNavigation && <Tooltip title={t('navigationtools.skipforward.tooltip')} placement="top" arrow>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label={t('navigationtools.skipforward.tooltip')}
        onFocus={(e) => { e.target.blur(); }}
        onClick={() => {
          setNoSleepEnabled(true);
          appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageDown));
        }}
      ><FastForwardIcon /></IconButton>
    </Tooltip>}
    {alwaysShowNavigation && <Tooltip title={t('navigationtools.next.tooltip')} placement="top" arrow>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label={t('navigationtools.next.tooltip')}
        onFocus={(e) => { e.target.blur(); }}
        onClick={() => {
          setNoSleepEnabled(true);
          appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.NextSegment));
        }}
      ><SkipNextIcon /></IconButton>
    </Tooltip>}

    {!runningAsPWA && !pwaWindow && isFullscreenAvailable &&
    <Tooltip title={t('fullscreen.tooltip')} placement="top" arrow>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label={t('fullscreen.tooltip')}
        onFocus={(e) => { e.target.blur(); }}
        onClick={() => {
          setNoSleepEnabled(true);
          toggleFullscreenMode();
        }}
      >
        { isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon /> }
      </IconButton>
    </Tooltip>}
  </Stack>);
};

export default CenterControls;