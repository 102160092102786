import BaseControlMessage from './BaseControlMessage';
export const DISCONNECTMESSAGE_KEY = 'disconnect';
/**
 * Represents the DisconnectMessage type.
 */
export class DisconnectMessage extends BaseControlMessage {
    constructor(endpointId, wasIntentional) {
        super();
        this.type = DISCONNECTMESSAGE_KEY;
        this.endpointId = endpointId;
        this.wasIntentional = (wasIntentional === true);
    }
    static fromJson(data) {
        if (data.type !== DISCONNECTMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for DisconnectMessage.');
        }
        const input = data;
        const instance = new DisconnectMessage(input.endpointId, input.wasIntentional);
        instance.reason = input.reason;
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            endpointId: this.endpointId,
            wasIntentional: this.wasIntentional,
            reason: this.reason,
        };
    }
}
DisconnectMessage.MESSAGE_NAME = DISCONNECTMESSAGE_KEY;
