import { useCallback, Dispatch } from 'react';
import { ReducerActionTypes, IReducerAction } from './hookTypes';
import patchUserProfileGlobal from './patchUserProfileGlobal';
import { IUserProfile } from '@fluidprompter/core';

export const usePatchUserProfile = (getApiToken: () => Promise<string>, dispatch: Dispatch<IReducerAction>) => {
  const patchUserProfile = useCallback(async (userProfile: IUserProfile): Promise<IUserProfile> => {
    // const apiToken = await getAccessTokenSilently({
    //   audience: 'https://app.fluidprompter.com/api',
    //   /*audience: 'https://dev-zek2ko1p.us.auth0.com/api/v2/',*/
    //   /*scope: 'read:posts',*/
    // });
    const apiToken = await getApiToken();

    const resultProfile = await patchUserProfileGlobal(apiToken, userProfile);

    // The API for patchUserProfile may not return all user profile fields. 
    // So we will copy any provided fields on top of current user profile object.
    const patchedUserProfile = Object.assign(userProfile, resultProfile);

    dispatch({
      type: ReducerActionTypes.setUserProfile,
      payload: patchedUserProfile,
    });

    return resultProfile;
  }, [getApiToken, dispatch]);

  return patchUserProfile;
};