import BaseControlMessage from './BaseControlMessage';
export const PEERSTATEMESSAGE_KEY = 'peer.state';
/**
 * Represents the PeerStateResponse type.
 */
export class PeerStateResponse extends BaseControlMessage {
    constructor(init) {
        super();
        this.type = PEERSTATEMESSAGE_KEY;
        const { isLeader, lastScriptChangeTimestamp, lastLeaderTimestamp, lifecycleState, } = init;
        this.isLeader = isLeader;
        this.lastScriptChangeTimestamp = lastScriptChangeTimestamp;
        this.lastLeaderTimestamp = lastLeaderTimestamp;
        this.lifecycleState = lifecycleState;
    }
    static fromJson(data) {
        if (data.type !== PEERSTATEMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for PeerStateResponse.');
        }
        const input = data;
        const instance = new PeerStateResponse({
            isLeader: input.isLeader,
            lastScriptChangeTimestamp: input.lastScriptChangeTimestamp,
            lastLeaderTimestamp: input.lastLeaderTimestamp,
            lifecycleState: input.lifecycleState,
        });
        instance.deserializeSenderInfo(input.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            isLeader: this.isLeader,
            lastScriptChangeTimestamp: this.lastScriptChangeTimestamp,
            lastLeaderTimestamp: this.lastLeaderTimestamp,
            lifecycleState: this.lifecycleState,
        };
    }
}
PeerStateResponse.MESSAGE_NAME = PEERSTATEMESSAGE_KEY;
