import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CreditCardIcon from '@mui/icons-material/CreditCard';

import { useTranslation } from 'react-i18next';
import useApplicationContext from '../../../../hooks/useApplicationContext';

import { IUserProfile } from '@fluidprompter/core';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const composeAmountTitle = (userProfile: IUserProfile | undefined): string => {
  if(!userProfile) {
    return '';
  }

  if(userProfile.subscription_interval === 'month') {
    // return 'Current monthly bill';
    return 'billingplanssection.billingsummary.currentmonthlybill';
  }

  if(userProfile.subscription_interval === 'year') {
    // return 'Current yearly bill';
    return 'billingplanssection.billingsummary.currentyearlybill';
  }

  // return 'Last one-time charge';
  return 'billingplanssection.billingsummary.lastonetimecharge';
};

const composePlanAmount = (userProfile: IUserProfile | undefined): string => {
  if(!userProfile) {
    return '';
  }

  if(userProfile.subscription_next_amount === undefined || !userProfile.subscription_currency || !userProfile.subscription_interval) {
    // Error, missing information
    return '';
  }

  // The next amount is provided in cents - no floating point.
  const nextCharge = Math.ceil(userProfile.subscription_next_amount / 100);

  // return `$${userProfile.subscription_next_amount} ${userProfile.subscription_currency.toUpperCase()}/${userProfile.subscription_interval}`;
  return `$${nextCharge}`;
};

const shouldShowYearlyCTA = (userProfile: IUserProfile | undefined): boolean => {
  if(!userProfile || !userProfile.subscription_interval || !userProfile.subscription_next_amount) {
    return false;
  }

  return userProfile?.subscription_interval === 'month';
};

const composePeriodEndTitle = (userProfile: IUserProfile | undefined): string => {
  if(!userProfile || !userProfile.subscription_interval) {
    // return 'Current plan expires';
    return 'billingplanssection.billingsummary.currentplanexpires';
  }

  // return 'Next payment due';
  return 'billingplanssection.billingsummary.nextpaymentdue';
};

const composePeriodEndDate = (userProfile: IUserProfile | undefined): string => {
  if(!userProfile || !userProfile.subscription_period_end) {
    return '—';
  }

  if(userProfile.subscription_next_amount === 0) {
    // This is a free plan, there is no next payment date.
    return '—';
  }

  const now = new Date();
  const periodEnd = new Date(userProfile.subscription_period_end * 1000);

  const monthNumber = periodEnd.getMonth();
  const monthName = months[monthNumber];

  const dayNumber = periodEnd.getDate();
  const year = periodEnd.getFullYear();

  const nextPaymentDate = `${monthName} ${dayNumber}` + ((now.getFullYear() === year) ? '' : `, ${year}`);
  return nextPaymentDate;
};

const composePaymentMethod = (userProfile: IUserProfile | undefined): React.ReactNode => {
  if(!userProfile || userProfile.billing_type !== 'card') {
    return '';
  }

  if(userProfile.subscription_next_amount === 0) {
    // This is a free plan, there is no payment.
    return '';
  }

  const paymentMethod = `${userProfile.billing_card_brand?.toUpperCase()} •••• ${userProfile.billing_card_last4}`;
  return (<Typography variant="body1" paddingBottom={1}><CreditCardIcon sx={{ mr: 1, verticalAlign: 'middle' }} />{paymentMethod}</Typography>);
};

const BillingSummaryPanel = () => {
  const { t } = useTranslation('account');

  const {
    appContext,
    subscription,
  } = useApplicationContext(false);
  
  return (<Grid container spacing={{ sm: 0, md: 3 }}>
    <Grid item xs={12} sm={4} display="flex">
      <Box flex={1} sx={{
        padding: 2,
        background: '#f6f8fa',
        borderRadius: '6px',
        // border: '1px solid rgba(0, 0, 0, 0.12)',
      }}>
        <Typography variant="body1" paddingBottom={1}>{t(composeAmountTitle(appContext.userProfile))}</Typography>
        <Typography variant="h5" fontWeight="bold" paddingBottom={2}>{composePlanAmount(appContext.userProfile)}</Typography>
        {/* <Button variant="text" onClick={redirectToBillingPortal}>Switch to yearly billing</Button> */}
        {shouldShowYearlyCTA(appContext.userProfile) && <a href="#switch_to_yearly" onClick={(e) => {
          e.preventDefault();
          subscription.redirectToBillingPortal();
        }}>{t('billingplanssection.billingsummary.switchtoyearlybilling')}</a>}
      </Box>        
    </Grid>
    <Grid item xs={12} sm={4} display="flex">
      <Box flex={1} sx={{
        padding: 2,
        background: '#f6f8fa',
        borderRadius: '6px',
        // border: '1px solid rgba(0, 0, 0, 0.12)',
      }}>
        <Typography variant="body1" paddingBottom={1}>{t(composePeriodEndTitle(appContext.userProfile))}</Typography>
        <Typography variant="h5" fontWeight="bold" paddingBottom={2}>{composePeriodEndDate(appContext.userProfile)}</Typography>
      </Box>
    </Grid>
    <Grid item xs={12} sm={4} display="flex">
      <Box flex={1} sx={{
        padding: 2,
        background: '#f6f8fa',
        borderRadius: '6px',
        // border: '1px solid rgba(0, 0, 0, 0.12)',
      }}>
        <Typography variant="body1" paddingBottom={1}>{t('billingplanssection.billingsummary.paymentinformation')}</Typography>
        {composePaymentMethod(appContext.userProfile)}
        <a href="#switch_to_yearly" onClick={(e) => {
          e.preventDefault();
          subscription.startUpdatePaymentMethod();
        }}>{t('billingplanssection.billingsummary.updatepaymentmethod')}</a>
        {/* <a href="#switch_to_yearly" onClick={() => {
          alert('');
        }}>Manage spending limit</a>
        <a href="#switch_to_yearly" onClick={() => {
          alert('');
        }}>Redeem coupon</a> */}
      </Box>
    </Grid>
  </Grid>);
};

export default BillingSummaryPanel;