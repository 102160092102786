import React, { useCallback, useState } from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import darkTheme from '../../../themes/DarkTheme';
import lightTheme from '../../../themes/LightTheme';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitleWithClose from '../../DialogTitleWithClose';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import useApplicationContext from '../../../hooks/useApplicationContext';
import useApplicationState from '../../../state/ApplicationState';
import { Avatar, Typography } from '@mui/material';

import * as z from 'zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const DeleteAccountDialog = () => {

  const {
    appContext,
    getApiToken,
    logout,
  } = useApplicationContext();

  const theme = useTheme();
  const showDialogFullscreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    useApplicationState.getState().setAccountDeleteOpen(false);
  };

  const registerSchema = z.object({
    confirm: z.string({
      invalid_type_error: 'You must type \'yes\' to confirm.',
    }).refine((val) => ['yes'].includes(val)),
    formstatus: z.string().default(''),
  });

  type RegisterInput = z.TypeOf<typeof registerSchema>;

  const {
    register,
    setValue,
    setError,
    formState: { errors },
    handleSubmit
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const onSubmitValidHandler: SubmitHandler<RegisterInput> = useCallback(async (values) => {
    if(!appContext.isAuthenticated) {
      return;
    }

    try {
      setIsProcessing(true);

      const apiToken = await getApiToken();

      setIsProcessing(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/account/?` + new URLSearchParams({
        confirm: values.confirm,
      }), {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${apiToken}`,
        },
      });
      if(response.status !== 200) {
        // We received an error response.
        throw new Error('Sorry, we encountered an unexpected error. Please contact fluidprompter support.');
      }

      // If we were succesful, we need to logout now.
      const returnUrl = new URL(window.location.href);
      returnUrl.pathname = '/';
      returnUrl.search = '';
      returnUrl.searchParams.set('return_from', 'delete_account');
      returnUrl.hash = '';
      const finalUrl = returnUrl.toString();

      returnUrl.pathname = '/auth/';
      returnUrl.search = '';    // Make sure we are not forwarding the /logout portion of the current page URL, but do preserve any querystrings!
      returnUrl.searchParams.set('action', 'logout');
      returnUrl.searchParams.set('redirectUrl', finalUrl);

      logout({
        // openUrl: (url: string) => {
        //   router.replace(url);
        // },
        logoutParams: {
          returnTo: returnUrl.toString(),
        },
      });
    } catch (err) {
      setIsProcessing(false);
      setError('formstatus', { type: 'custom', message: 'Error: could not save changes. Try again or contact support.' });
    }
  }, [appContext.isAuthenticated, getApiToken, logout, setError]);

  return (<>
    <Dialog
      maxWidth='lg'
      fullWidth={showDialogFullscreen}
      // fullScreen={showDialogFullscreen}
      scroll='paper'
      onClose={handleClose}
      open={true}
    >
      <ThemeProvider theme={lightTheme}>
        <Container
          component={'form'}
          disableGutters
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit(onSubmitValidHandler)}
        >
          <DialogTitleWithClose onClose={handleClose}></DialogTitleWithClose>
          <DialogContent>
            <Stack direction={'column'} alignItems={'center'}>
              <Avatar sx={{
                background: '#d32f2f',
                width: 64,
                height: 64
              }}><DeleteForeverIcon color="inherit" sx={{ width: 48, height: 48 }} /></Avatar>
              <Typography variant="h4" color={'#d32f2f'} sx={{ mt: 2, mb: 1, ml: 2, mr: 2 }}>Permanently Delete Account?</Typography>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 1, mb: 1, ml: 2, mr: 2 }}>Warning: This cannot be undone.</Typography>
              <ul>
                <li>You will immediately lose access to FluidPrompter.</li>
                <li>Your preferences and configurations will be deleted.</li>
                <li>Your purchase history and invoices will be unrecoverable.</li>
              </ul>
              <TextField
                variant="outlined"
                color="error"
                fullWidth
                label="Type 'yes' to confirm"
                helperText="Asking users to type 'yes' to confirm helps prevent accidental account deletion."
                {...register('confirm')}
                error={!!errors['confirm']}
                onFocus={() => { setValue('confirm', ''); }}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{
            alignItems: 'stretch',
            background: '#eee',
          }}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleClose}
              sx={{
                flex: 1,
                flexDirection: showDialogFullscreen ? 'column' : 'row'
              }}
            >CANCEL</Button>
            <Button
              type='submit'
              variant="contained"
              color="error"
              startIcon={<DeleteForeverIcon />}
              sx={{
                flex: 1,
                flexDirection: showDialogFullscreen ? 'column' : 'row'
              }}
            >DELETE ACCOUNT</Button>
          </DialogActions>
        </Container>
      </ThemeProvider>

      {isProcessing && <ThemeProvider theme={darkTheme}><Backdrop open={true}>
        <CircularProgress color="primary" />
        <span style={{
          color: '#fff',
          marginLeft: '16px'
        }}>Processing...</span>
      </Backdrop></ThemeProvider>}
    </Dialog>
  </>);
};

export default DeleteAccountDialog;