import { ConnectMessage } from "./ConnectMessage";
import { ConnectResponse } from "./ConnectResponse";
import { ConnectRequestMessage } from "./ConnectRequestMessage";
import { EditMessage } from "./EditMessage";
import { GenericMessage } from "./GenericMessage";
import { HeartbeatMessage } from "./HeartbeatMessage";
import { HeartbeatResponse } from "./HeartbeatResponse";
import { IceCandidateMessage } from "./IceCandidateMessage";
import { DisconnectMessage } from "./DisconnectMessage";
import { GetSessionMessage } from "./GetSessionMessage";
import { GetSessionResponse } from "./GetSessionResponse";
import { NavigateMessage } from "./NavigateMessage";
import { PauseMessage } from "./PauseMessage";
import { PlayMessage } from "./PlayMessage";
import { ResetMessage } from "./ResetMessage";
import { NodeChangedMessage } from "./NodeChangedMessage";
import { SegmentChangedMessage } from "./SegmentChangedMessage";
import { SdpMessage } from "./SdpMessage";
import { SetReverseMessage } from "./SetReverseMessage";
import { SetScrollSpeedMessage } from "./SetScrollSpeedMessage";
import { TestMessage } from "./TestMessage";
import { PeerStateRequest } from "./PeerStateRequest";
import { PeerStateResponse } from "./PeerStateResponse";
import { ScriptRequestMessage } from "./ScriptRequestMessage";
import { ScriptStateMessage } from "./ScriptStateMessage";
import { EditorActiveMessage } from "./EditorActiveMessage";
import { EditorOperationsMessage } from "./EditorOperationsMessage";
import { LifecycleMessage } from "./LifecycleMessage";
import { PeerPingMessage } from "./PeerPingMessage";
import { PeerPongMessage } from "./PeerPongMessage";
import { PeerMissingMessage } from "./PeerMissingMessage";
/**
 * Deserialize an application message from Json to a proper class instance.
 * @param msgJson
 * @returns
 */
function deserializeJsonMessage(msgJson) {
    let currentMsg = undefined;
    switch (msgJson.type) {
        case PlayMessage.MESSAGE_NAME:
            currentMsg = PlayMessage.fromJson(msgJson);
            break;
        case SetReverseMessage.MESSAGE_NAME:
            currentMsg = SetReverseMessage.fromJson(msgJson);
            break;
        case PauseMessage.MESSAGE_NAME:
            currentMsg = PauseMessage.fromJson(msgJson);
            break;
        case EditMessage.MESSAGE_NAME:
            currentMsg = EditMessage.fromJson(msgJson);
            break;
        case ResetMessage.MESSAGE_NAME:
            currentMsg = ResetMessage.fromJson(msgJson);
            break;
        case SetScrollSpeedMessage.MESSAGE_NAME:
            currentMsg = SetScrollSpeedMessage.fromJson(msgJson);
            break;
        case NavigateMessage.MESSAGE_NAME: // 'navigate'
            currentMsg = NavigateMessage.fromJson(msgJson);
            break;
        case SegmentChangedMessage.MESSAGE_NAME:
            // Do we want to handle this when it originated remotely?
            // currentMsg = SegmentChangedMessage.fromJson(msgJson);
            break;
        case NodeChangedMessage.MESSAGE_NAME:
            // Do we want to handle this when it originated remotely?
            // Yes, when script node changes, we should sync scroll speed again.
            currentMsg = NodeChangedMessage.fromJson(msgJson);
            break;
        case TestMessage.MESSAGE_NAME:
            currentMsg = TestMessage.fromJson(msgJson);
            break;
        //
        // Script editing messages
        case PeerStateRequest.MESSAGE_NAME:
            currentMsg = PeerStateRequest.fromJson(msgJson);
            break;
        case PeerStateResponse.MESSAGE_NAME:
            currentMsg = PeerStateResponse.fromJson(msgJson);
            break;
        case ScriptRequestMessage.MESSAGE_NAME:
            currentMsg = ScriptRequestMessage.fromJson(msgJson);
            break;
        case ScriptStateMessage.MESSAGE_NAME:
            currentMsg = ScriptStateMessage.fromJson(msgJson);
            break;
        case EditorActiveMessage.MESSAGE_NAME:
            currentMsg = EditorActiveMessage.fromJson(msgJson);
            break;
        case EditorOperationsMessage.MESSAGE_NAME:
            currentMsg = EditorOperationsMessage.fromJson(msgJson);
            break;
        //
        // Heartbeat and Sync message
        case HeartbeatMessage.MESSAGE_NAME:
            // 'heartbeat'
            currentMsg = HeartbeatMessage.fromJson(msgJson);
            break;
        case HeartbeatResponse.MESSAGE_NAME:
            // 'heartbeat.response'
            currentMsg = HeartbeatResponse.fromJson(msgJson);
            break;
        case 'sync':
            currentMsg = GenericMessage.fromJson(msgJson);
            break;
        //
        //
        case LifecycleMessage.MESSAGE_NAME:
            currentMsg = LifecycleMessage.fromJson(msgJson);
            break;
        //
        // WebRTC Messages
        case ConnectMessage.MESSAGE_NAME:
            currentMsg = ConnectMessage.fromJson(msgJson);
            break;
        case ConnectResponse.MESSAGE_NAME:
            currentMsg = ConnectResponse.fromJson(msgJson);
            break;
        case ConnectRequestMessage.MESSAGE_NAME:
            currentMsg = ConnectRequestMessage.fromJson(msgJson);
            break;
        case DisconnectMessage.MESSAGE_NAME:
            currentMsg = DisconnectMessage.fromJson(msgJson);
            break;
        case GetSessionMessage.MESSAGE_NAME:
            currentMsg = GetSessionMessage.fromJson(msgJson);
            break;
        case GetSessionResponse.MESSAGE_NAME:
            currentMsg = GetSessionResponse.fromJson(msgJson);
            break;
        case PeerPingMessage.MESSAGE_NAME:
            currentMsg = PeerPingMessage.fromJson(msgJson);
            break;
        case PeerPongMessage.MESSAGE_NAME:
            currentMsg = PeerPongMessage.fromJson(msgJson);
            break;
        case PeerMissingMessage.MESSAGE_NAME:
            currentMsg = PeerMissingMessage.fromJson(msgJson);
            break;
        case SdpMessage.MESSAGE_NAME:
            currentMsg = SdpMessage.fromJson(msgJson);
            break;
        case IceCandidateMessage.MESSAGE_NAME:
            currentMsg = IceCandidateMessage.fromJson(msgJson);
            break;
        case 'prompter.content.show': // Prompter Blanking
        case 'prompter.content.hide': // Prompter Blanking
            // case 'prompter.content.togglevisbility':  // Prompter Blanking - this one is not allowed to be serialized/deserialized
            currentMsg = GenericMessage.fromJson(msgJson);
            break;
        case 'prompter.background.clear':
            currentMsg = GenericMessage.fromJson(msgJson);
            break;
        default:
            console.warn(`deserializeJsonMessage() did not handle message type '${msgJson.type}'`);
            break;
    }
    return currentMsg;
}
const xorEncodeBytes = (secretKey, inputBytes) => {
    const inputBytesArray = new Uint8Array(inputBytes);
    let keyIndex = 0;
    const outputEncryptedBytes = new Uint8Array(inputBytesArray.length);
    for (let i = 0; i < inputBytesArray.byteLength; ++i) {
        outputEncryptedBytes[i] = inputBytesArray[i] ^ secretKey[keyIndex];
        keyIndex++;
        if (keyIndex === secretKey.length) {
            keyIndex = 0;
        }
    }
    return outputEncryptedBytes;
};
const xorDecodeBytes = (secretKey, encryptedBytes) => {
    const encryptedBytesArray = new Uint8Array(encryptedBytes);
    let keyIndex = 0;
    const outputDecryptedBytes = new Uint8Array(encryptedBytesArray.length);
    for (let i = 0; i < encryptedBytesArray.length; ++i) {
        outputDecryptedBytes[i] = encryptedBytesArray[i] ^ secretKey[keyIndex];
        keyIndex++;
        if (keyIndex === secretKey.length) {
            keyIndex = 0;
        }
    }
    return outputDecryptedBytes;
};
export default {
    deserializeJsonMessage,
    xorEncodeBytes,
    xorDecodeBytes,
};
