import { create } from 'zustand';

export enum BackgroundTypes {
  None = 'none',
  Webcam = 'webcam',
  Desktop = 'desktop',
  Remote = 'remote',
}

export enum BackgroundLayout {
  // None = 'none',
  Contain = 'contain',
  Cover = 'cover',
}

interface BackgroundState {
  backgroundType: BackgroundTypes;
  setBackgroundType: (backgroundType: BackgroundTypes) => void;

  backgroundLayout: BackgroundLayout;
  setBackgroundLayout: (backgroundLayout: BackgroundLayout) => void;
}

const useBackgroundState = create<BackgroundState>()(
  (set) => ({
    backgroundType: BackgroundTypes.None,
    setBackgroundType: (backgroundType: BackgroundTypes) => set(() => ({ backgroundType })),

    backgroundLayout: BackgroundLayout.Contain,
    setBackgroundLayout: (backgroundLayout: BackgroundLayout) => set(() => ({ backgroundLayout })),
  })
);

export default useBackgroundState;