import React from 'react';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Avatar from '@mui/material/Avatar';
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const StyledListItem = styled(ListItemButton)({
  background: 'rgb(253, 237, 237)',
  borderRadius: 0,
  borderBottom: '1px solid #d32f2f',
  '&:hover': {
    background: 'rgb(253, 237, 237)',
  },
});

const OfflineUserMenu = () => {
  const { t } = useTranslation('appmenu');

  return (
    <StyledListItem>
      <ListItemAvatar>
        <Avatar sx={{
          background: '#d32f2f',
        }}><WifiOffOutlinedIcon color="inherit" /></Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={t('usermenu.offline.caption')}
        primaryTypographyProps={{
          color: 'rgb(95, 33, 32)'
        }}
        secondary={t('usermenu.offline.helpertext')}
        secondaryTypographyProps={{
          color: 'rgb(95, 33, 32)'
        }}
      />
      {/* <ListItemSecondaryAction>
        <Button color="error" variant="text" sx={{
          whiteSpace: 'nowrap'
        }}>
          RETRY
        </Button>
      </ListItemSecondaryAction> */}
    </StyledListItem>
  );
};

export default OfflineUserMenu;