import React, { useCallback, useEffect, useRef, useContext } from 'react';
import { ShepherdTourContext, Tour } from 'react-shepherd';
import { useAppController, useMessageHandler, MessageHandlerEvent } from '../../controllers/AppController';


import useConfigurationStore from '../../state/ConfigurationStore';
import { shallow } from 'zustand/shallow';
import { PlayMessage, EditMessage, GenericMessage } from '@fluidprompter/core';
import { zaraz } from 'zaraz-ts';
import usePrompterSession from '../../state/PrompterSessionState';

interface ITourEventArgs {
  index: number;
  tour: unknown;
}

const TourController = React.memo(function TourController() {

  const appController = useAppController();

  const configStore = useConfigurationStore(state => ({
    tourComplete: state.tourComplete,
    setTourComplete: state.setTourComplete,
  }), shallow);

  const tour = useContext(ShepherdTourContext);

  const tourRef = useRef<Tour | null>(tour);
  useEffect(() => {
    // console.log('tour instance changed', tour);
    tourRef.current = tour;
  }, [tour]);

  const requestTour = useCallback(function (e?: MessageHandlerEvent<GenericMessage>) {
    const { current: currentTour } = tourRef;
    if(e) {
      e.sendToPeers = false;
    }

    if(
      !currentTour
        || !currentTour.steps.length
        || currentTour.isActive()
    ) {
      return;
    }

    currentTour.start();
  }, [tourRef]);
  useMessageHandler('prompter.local.tour', requestTour);

  // Start the tour on load.
  const initialRender = useRef(true);
  useEffect(() => {
    if(!tour || !tour.steps.length) {
      return;
    }

    //
    // *complete
    // *cancel
    // hide
    // show
    // *start
    // active
    // inactive
    //

    const onTourStart = () => {
      // console.log('start tour', tour.steps);
      zaraz.track('app_event', {
        event_name: 'app_tour_start'
      });
      // This is often dispatched early in the page life cycle before we are connected to
      // FluidPrompter cloud.
      if(appController.localEndpointId) {
        //
        // If we have a localEndpointId, we are ok to dispatch a message.
        // localEndpointId is sometimes not available during the first 1 second of loading the page
        // while we are checking on a cached windowId collision.
        //
        // Generally speaking this shouldn't happen, as if you are a first time user, you shouldn't
        // have a cached windowId. But just in case, we will provide a local only option below.
        //
        appController.dispatchMessage(new EditMessage());
      } else {
        // We are showing the tour very early in the page lifecycle during initial page load.
        usePrompterSession.getState().edit();
      }
    };
    tour.on('start', onTourStart);

    const onTourCancel = (/*params: ITourEventArgs*/) => {
      // console.log('cancel tour', arguments);
      zaraz.track('app_event', {
        event_name: 'app_tour_cancel'
      });

      configStore.setTourComplete(true);
    };
    tour.on('cancel', onTourCancel);

    const onTourComplete = (params: ITourEventArgs) => {
      // console.log('tour complete!', params);
      if(params.index === 3) {
        zaraz.track('app_event', {
          event_name: 'app_tour_complete'
        });

        configStore.setTourComplete(true);
        appController.dispatchMessage(new PlayMessage());
      }
    };
    tour.on('complete', onTourComplete);

    if(initialRender && !configStore.tourComplete) {
      initialRender.current = false;

      requestTour();
    }

    // cleanup!
    return () => {
      tour.off('start', onTourStart);
      tour.off('cancel', onTourCancel);
      tour.off('complete', onTourComplete);

      if(tour.isActive()) {
        tour.cancel();
      }
    };
  }, [tour, configStore, appController, requestTour]);

  return (<></>);
});

export default TourController;