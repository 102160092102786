import React from 'react';
import classNames from 'classnames';
import './HorizontalLine.scss';

interface HorizontalLineProps {
  caption?: string;
  position?: number;
}

const HorizontalLine = (props: HorizontalLineProps) => {

  const { caption, position } = props;
  // console.log(`HorizontalLine.position=${position}`);

  const positionStyles: React.CSSProperties | undefined = (position !== undefined) ? {
    position: 'absolute',
    width: '100%',
    top: position,
  } : undefined;

  return (
    <div
      className="HorizontalLine"
      contentEditable={false}
      style={positionStyles}
    >
      <div className="LeftMarker"><span></span></div>
      <div 
        className={classNames('DividerLine', { HasCaption: (caption !== undefined) })}      
      >
        {caption && <span>{caption}</span>}
      </div>
      <div className="RightMarker"><span></span></div>
    </div>
  );
};

export default HorizontalLine;