import React, { useState } from 'react';

import { DeviceComponent, DeviceComponentProps } from '../../BaseDevice';
// import IkanEliteRemoteMenu from './IkanEliteRemoteMenu';
import IkanEliteRemoteMenu from '../../common/UI/DeviceMenu';
// import GenericGamepadDialog from './IkanEliteRemoteDialog';

const IkanEliteRemoteUI = (props: DeviceComponentProps) => {
  const { onClose } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (<>
    <IkanEliteRemoteMenu {...props} setDialogOpen={setDialogOpen} />
    {/* {dialogOpen && <GenericGamepadDialog onClose={() => {
      // Close dialog - should we close menu too?
      setDialogOpen(false);
      onClose();
    }} />} */}
  </>);
};

export default IkanEliteRemoteUI;