import React, { useCallback, useState } from 'react';
import { Transforms, Descendant } from 'slate';
import { ReactEditor, useSlateStatic, RenderElementProps, useFocused, useSelected } from 'slate-react';

import { useTranslation } from 'react-i18next';
import useResizeObserver from '@react-hook/resize-observer';
import debounce from 'lodash/debounce';
import useConfigurationStore from '../../../state/ConfigurationStore';
import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';
import FocusGuide from '../../../models/FocusGuide';

import PrompterIconButton from '../../PrompterIconButton';

import { Typography } from '@mui/material';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import RepeatIcon from '@mui/icons-material/Repeat';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import ShotLogButton from '../ShotLogModal/ShotLogButton';
import SegmentInsertToolbar from '../SegmentInsertToolbar';
import { Id, toast, ToastOptions, UpdateOptions } from 'react-toastify';

import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Button, { buttonClasses } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';

import SettingsIcon from '@mui/icons-material/Settings';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import TuneIcon from '@mui/icons-material/Tune';

import { useAppController } from '../../../controllers/AppController';

import isHotkey from 'is-hotkey';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import './StartElement.scss';

const HeaderTextField = styled(TextField)({
  flexGrow: 1,
  '& .MuiOutlinedInput-input': {
    margin: '0 0.5em',
    fontSize: '2rem',
    fontStyle: 'italic',
    fontWeight: 'bold',
    // textTransform: 'uppercase',
    padding: 0,
  }
});

const ShotLogButtonBase = styled(Button)({
  fontSize: 'inherit',
  // fontWeight: 'bold',
  lineHeight: '1.25',
  paddingLeft: 12,
  color: 'inherit',
  whiteSpace: 'nowrap',
  '&:hover': {
    color: '#fff',
  },
  [`&.${buttonClasses.disabled}`]: {
    color: 'inherit', // The pause type button is disabled when prompting.
  },
  '.MuiButton-startIcon': {
    '& > *:nth-of-type(1)': {
      fontSize: '1em',
    }
  },
  '.MuiButton-endIcon': {
    '& > *:nth-of-type(1)': {
      fontSize: '1em',
    }
  },
});

interface ButtonOrIconButtonProps {
  startIcon?: React.ReactNode;
  caption: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}
const ButtonOrIconButton = (props: ButtonOrIconButtonProps) => {
  const { startIcon, caption, onClick } = props;

  const viewportIsLargeOrLarger = true;

  return (<>
    <Tooltip title={caption} placement="top" arrow>
      {(viewportIsLargeOrLarger) ? (
        <ShotLogButtonBase
          startIcon={ startIcon }
          // onFocus={(e) => { e.target.blur(); }}
          onClick={onClick}
        >{caption}</ShotLogButtonBase>
      ) : (
        <IconButton
          // onFocus={(e) => { e.target.blur(); }}
          onClick={onClick}
        >{ startIcon }</IconButton>
      )}
    </Tooltip>
  </>);
};

function StartElement(props: RenderElementProps) {
  const { attributes, children, element } = props;

  const { t } = useTranslation('prompter');

  const appController = useAppController();

  const focusGuide = useConfigurationStore(state => state.focusGuide);
  const flipVertical = useConfigurationStore(state => state.flipVertical);

  let marginTop = flipVertical ? 80 : 20;
  switch(focusGuide) {
    case FocusGuide.Middle:
    case FocusGuide.None:
      marginTop = 50;
      break;
    case FocusGuide.Bottom:
      marginTop = flipVertical ? 20 : 80;
      break;
  }

  const editor = useSlateStatic();
  const editorIsReadOnly = ReactEditor.isReadOnly(editor);

  const selected = useSelected();  // Get the current selected state of an element.
  const focused = useFocused();    // Get the current focused state of the editor.

  const prompterSession = usePrompterSession(state => ({
    isEditing: state.isEditing,
    shotloggingEnabled: state.shotloggingEnabled,
    setShotloggingEnabled: state.setShotloggingEnabled,
  }), shallow);


  const [isEditingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState('Untitled Script');

  const onTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTitle(event.target.value);
    },
    [setTitle]
  );

  const applyTitleChange = useCallback((captionInput: string) => {
    setTitle(captionInput);
    // if(onTitleChanged) {
    //   onTitleChanged(captionInput);
    // }
  }, [/*onTitleChanged*/]);

  const startTitleEdit = useCallback((event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(isEditingTitle) {
      return;
    }

    setEditingTitle(true);
  }, [isEditingTitle]);

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isHotkey('enter', e)) {
      return;
    }
    e.preventDefault();

    // User pressed enter!
    // Now let's stop stop editing the promptersegment title.
    const inputEl = e.target as HTMLInputElement;
    applyTitleChange(inputEl.value);
    setEditingTitle(false);
  },
  [applyTitleChange, setEditingTitle]
  );

  const finishTitleEdit = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      if(!isEditingTitle) {
        return;
      }

      applyTitleChange(title);
      setEditingTitle(false);
    }, [isEditingTitle, applyTitleChange, title]
  );


  const onChangeShotLoggingEnabled = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const proposedShotloggingEnabled = e.target.checked;
    prompterSession.setShotloggingEnabled(proposedShotloggingEnabled);

    const now = new Date();

    if(proposedShotloggingEnabled) {
      toast(`Shot Logging Enabled at ${now.toLocaleTimeString()}!`, {
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false
      });
    }
  }, [prompterSession]);

  const onInsertNodeHandler = useCallback((node: Descendant) => {
    const freshpath = ReactEditor.findPath(editor, element);

    // Insert the proposedNode after the current node.
    const currentNodeIndex = freshpath.shift();
    if(currentNodeIndex !== undefined) {
      freshpath.unshift(currentNodeIndex + 1);
    }

    // If the local prompter believes it is the leader, then the following node transform
    // will be sent to all other connected prompters.
    //
    // After a script modification has been sent to all other connected prompters, those
    // other prompters will also recognize this prompter as the current leader.
    appController.setLeaderIsSelf();
    Transforms.insertNodes<Descendant>(editor, node, {
      at: freshpath,
    });
    Transforms.select(editor, freshpath);
  }, [editor, element]);


  const [layoutSize, setLayoutSize] = useState<number>(5);
  const handleResizeEvent = React.useCallback(debounce((e: ResizeObserverEntry) => {
    // console.log(`Start Element resized ${e.contentRect.width}`);

    // TODO: We will have multiple breakpoints:
    const contentWidth = e.contentRect.width;
    if(contentWidth <= 500) {
      // Width 1: Portrait Mobile - most narrow view
      // XS
      setLayoutSize(1);
    } else if (contentWidth <= 720) {
      // Width 2: Mobile Landscape/Tablet Portrait
      // SM
      setLayoutSize(2);
    } else if (contentWidth <= 1024) {
      // Width 3: Tablet Landscape
      // MD
      setLayoutSize(3);
    } else if (contentWidth <= 1920) {
      // Width 4: Desktop HD
      // LG (HD)
      setLayoutSize(4);
    } else {
      // Width 5: Desktop 4k
      // XL (4k)
      setLayoutSize(5);
    }
  }, 50), []);
  useResizeObserver(attributes.ref, handleResizeEvent);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const scriptTitleEditorEnabled = false;

  const translationRootKey = `startelement.${layoutSize <= 1 ? 'quickactions-compact' : 'quickactions-full'}`;
  return (<>
    <div
      className="StartElement"
      contentEditable={false}
      {...attributes}
      // style={{
      //   marginTop: `${marginTop}vh`
      // }}
    >
      <div className="StartElementSpacer"
        style={{
          height: `${marginTop}vh`,
        }}
      ></div>
      <div
        className={classNames('StartElementContent', { isSelected: (selected && focused) })}
      >
        <Stack
          className="StartElementQuickActions"
          direction={layoutSize <= 2 ? 'column' : 'row'}
          paddingLeft={2}
          paddingRight={2}
          spacing={layoutSize <= 2 ? 0 : 1}
          minWidth={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {(layoutSize > 2) && <Typography padding={'6px 8px'} fontSize={'inherit'} textTransform='uppercase'>{t('startelement.quickactions')}:</Typography>}
          <Stack
            direction={'row'}
            spacing={1}
            minWidth={layoutSize <= 2 ? '100%' : undefined}
            justifyContent={layoutSize <= 2 ? 'space-around' : 'center'}
            alignItems={'center'}
          >
            <ShotLogButtonBase
              startIcon={ <NoteAddIcon /> }
              // onFocus={(e) => { e.target.blur(); }}
              onClick={() => { appController.dispatchMessage('newscript'); }}
            >{t(`${translationRootKey}.new.caption`)}</ShotLogButtonBase>

            <ShotLogButtonBase
              startIcon={ <FileOpenIcon /> }
              // onFocus={(e) => { e.target.blur(); }}
              onClick={() => { appController.dispatchMessage('openfile'); }}
            >{t(`${translationRootKey}.open.caption`)}</ShotLogButtonBase>

            <ShotLogButtonBase
              startIcon={ <AddIcon /> }
              // onFocus={(e) => { e.target.blur(); }}
              onClick={() => { appController.dispatchMessage('app.connect.device'); }}
            >{t(`${translationRootKey}.connectdevice.caption`)}</ShotLogButtonBase>
          </Stack>
        </Stack>
        <div className="StartElementHeader">
          <h1>{t('startelement.defaultcaption')}{children}</h1>
          <div style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            top: 0, bottom: 0, right: '12px',
            paddingTop: '16px',
          }}>
            <PrompterIconButton
              size="large"
              edge="start"
              aria-label="Script Settings"
              sx={{
              // mr: 1,
                position: 'absolute',
                right: 1,
              // float: 'right',
              }}
              disabled={editorIsReadOnly}
              onFocus={(e) => { e.target.blur(); }}
              onClick={() => { setIsOpen(!isOpen); }}
            >
              <SettingsIcon />
            </PrompterIconButton>
          </div>
        </div>
        <div className={classNames('StartElementActionsInline')}>
          {scriptTitleEditorEnabled && (isEditingTitle ? (
            <HeaderTextField
              className="StartElementTitle"
              variant="outlined"
              autoFocus={true}
              defaultValue={title}
              onChange={onTitleChange}
              onBlur={finishTitleEdit}
              InputProps={{
                onKeyDown: onKeyDown,
              }}
            />
          ) : (
            <h1 className="StartElementTitle" onClick={startTitleEdit}>{title}</h1>
          ))}

          <Collapse
            className="StartElementActionsCollapse"
            in={isOpen}
            timeout="auto"
          >
            <FormControl component="fieldset" fullWidth>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="start"
                  disabled={!prompterSession.isEditing}
                  control={<Switch disabled={!prompterSession.isEditing} color="primary" checked={prompterSession.shotloggingEnabled} onChange={onChangeShotLoggingEnabled} />}
                  label={t('startelement.options.enableshotlogging')}
                  labelPlacement="start"
                  onFocus={(e) => { e.target.blur(); }}
                />
              </FormGroup>
            </FormControl>
          </Collapse>
        </div>
      </div>
      <SegmentInsertToolbar onInsertNode={onInsertNodeHandler} />
    </div>
  </>
  );
}

export default StartElement;