import DeviceHost from '../DeviceHost';
import {
  IDeviceDescriptor,
  DeviceComponent,
  DeviceConnectionType
} from '../BaseDevice';
import BaseRemote from '../BaseRemote';
import { DeviceButtonEvent } from '../events/DeviceButtonEvent';

import DigitalButtonStateMachine from '../common/DigitalButtonStateMachine';

import { TFunction } from 'i18next';
import MomanFS1PedalIcon from './images/moman-fs1-pedal-icon.png';
import MomanFS1PedalUI from './UI';

import {
  AppBluetoothDevice,
  AppBluetoothCharacteristic,
  IBluetoothProvider,
  AppBluetoothStatus
} from '../BluetoothProviders';

const MOMANFS1_TYPE = 'momanfs1';

class MomanFS1Pedal extends BaseRemote {
  readonly type = MOMANFS1_TYPE;

  public static readonly DEVICE_TYPE: string = MOMANFS1_TYPE;

  static PRIMARY_SERVICE_UUID = '00001000-e619-419b-bc43-821e711009b7';
  static PRIMARY_CHARACTERISTIC_UUID = '00001002-e619-419b-bc43-821e711009b7';

  _pedal: DigitalButtonStateMachine;

  constructor(deviceHost: DeviceHost) {
    super(deviceHost);
    this.icon = MomanFS1PedalIcon;
    this.name = 'Moman FS1 Pedal';
    this.connectionType = DeviceConnectionType.Bluetooth;

    this._pedal = new DigitalButtonStateMachine({
      bitmask: 0x40,
      name: 'ok',
    }, this.onButtonEvent.bind(this));
  }

  getRequestDeviceOptions(): RequestDeviceOptions {
    //
    // This should catch all MOMAN pedals with factory device name.
    // ie: MOMAN-397E
    //
    const neewerBleDeviceFilterByName: BluetoothLEScanFilter = {
      namePrefix: 'MOMAN'
    };
    const requestDeviceOptions: RequestDeviceOptions = {
      filters: [neewerBleDeviceFilterByName],
      optionalServices: [MomanFS1Pedal.PRIMARY_SERVICE_UUID, 'battery_service'],
    };

    return requestDeviceOptions;
  }


  async attachServicesAndCharacteristics(
    device: AppBluetoothDevice,
    provider: IBluetoothProvider,
    subscriptionTopic: string) {

    super.attachServicesAndCharacteristics(device, provider, subscriptionTopic);

    await provider.notify(device.id,
      MomanFS1Pedal.PRIMARY_SERVICE_UUID,
      MomanFS1Pedal.PRIMARY_CHARACTERISTIC_UUID,
      (char: AppBluetoothCharacteristic, status: AppBluetoothStatus, error: number | null) => {
        if (status === AppBluetoothStatus.Success && char.value) {
          this.onNotifyDigital(char.value);

        } else {
          console.log(`Received error on button notification. Error code is ${error}`);
        }
      },
      subscriptionTopic
    );
  }

  /**
   * Fired when our state machines have detected a user input event.
   * @param {*} buttonName
   * @param {*} eventType
   */
  onButtonEvent(buttonName: string, eventType: string) {
    const eventName = `${buttonName}.${eventType}`;
    // console.log(`MomanFS1Pedal.onButtonEvent ${eventName}`);
    this.emit('buttonreport', new DeviceButtonEvent(this, buttonName, eventType));
  }

  /**
   * Fired when we receive a BLE characteristic notification for a digital button state change (button being pressed or released).
   * @param {*} notifyData
   */
  onNotifyDigital(notifyData: DataView) {
    // Moman FS1 pedal will notify with 20 bytes.
    //
    // A1 06 80 80 80 80 40 00 00 00 ...
    //  0  1  2  3  4  5  6
    //
    // Bytes 0-5 appear to be a fixed prefix.
    //
    // Byte 0 = 0xA1
    // Byte 1 = 0x06
    // Byte 2 = 0x80
    // Byte 3 = 0x80
    // Byte 4 = 0x80
    // Byte 5 = 0x80
    // Byte 6 = 0x40 Button State
    // Byte 7-19 = 0x00
    //

    if(notifyData.byteLength < 20) {
      throw new Error('Unexpected data length from Moman FS1 Remote.');
    }

    const buttonState = notifyData.getUint8(6);  // Need to get the byte as _unsigned_ value

    // console.log(`Moman FS1 button state ${buttonState} with ${notifyData.byteLength} bytes`);

    this._pedal.processState(buttonState);
  }

  //
  // Fired when a device transition from connected to disconnected.
  // This may be due to user interaction to disconnect on purpose or it may be due to an unexpected
  // disconnect (device turned off, moved too far away, etc).
  //
  async onDisconnected() {
    // When the device is disconnected, it is possible it was disconnected while
    // a button was actively being pressed. The button will then be stuck in the
    // pressed state and the repeat press timer may continue to fire indefinitely.
    const buffer = new ArrayBuffer(20);
    const view = new DataView(buffer);
    this.onNotifyDigital(view);

    await super.onDisconnected();
  }

  static readonly DeviceKey: string = 'moman_fs1';
  static getDeviceDescriptors(t: TFunction): IDeviceDescriptor[] {
    return [{
      connectionType: DeviceConnectionType.Bluetooth,
      deviceKey: MomanFS1Pedal.DeviceKey,
      deviceName: `Moman FS1 ${t('connectdevicedialog.remote')}`,
      deviceIcon: MomanFS1PedalIcon,
      requiresPlanLevel: 1,
      requiresBluetooth: true,
    }];
  }

  getDeviceUIComponent(): DeviceComponent {
    return MomanFS1PedalUI;
  }
}

export default MomanFS1Pedal;