import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const HoveringToolbarButton = styled(Button)({
  color: '#858585',
  borderRadius: 4,
  minWidth: 'unset',
  padding: '12px',
  '&:hover': {
    color: '#fff',
  },
});

export default HoveringToolbarButton;