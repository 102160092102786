import { Descendant } from 'slate';

import { IViewportRefs } from './usePrompterViewportRefs';
import IPrompterPosition from '../../models/segments/IPrompterPosition';

import FocusGuide from '../../models/FocusGuide';

import useGetPrompterPositionFunction from './useGetPrompterPositionFunction';
import usePrompterScrollToPositionFunction, { ScrollToArgs } from './usePrompterScrollToPositionFunction';
import usePrompterScrollByDeltaFunction, { ScrollByArgs } from './usePrompterScrollByDeltaFunction';
import usePrompterScrollToSegmentFunction from './usePrompterScrollToSegmentFunction';
import useSegmentNavigationFunctions, { PushLastScrollTargetArgs, ScrollPositionQueueEntry } from './useSegmentNavigationFunctions';
import useSequentialTaskQueue from './useSequentialTaskQueue';

export interface IViewportFuncs {
    getPrompterPosition: () => IPrompterPosition | undefined,
    scrollToPosition: (args?: ScrollToArgs | undefined) => Promise<void>,
    scrollByDelta: (args?: ScrollByArgs | undefined) => Promise<void> | undefined,
    scrollToSegment: (targetSegment: Descendant, targetPosition?: string | undefined) => Promise<void>,
    getSegmentIndex: (targetSegment: Descendant) => number,
    getSegmentIndexRelativeToActive: (offsetFromCurrent: number) => number,
    pushLastScrollTarget: (args: PushLastScrollTargetArgs) => ScrollPositionQueueEntry,
    shiftLastScrollTarget: () => PushLastScrollTargetArgs | undefined,
    clearLastTargetSegmentIndex: () => void,
    queueSequentialTask: (promiseInstance: () => Promise<void>) => Promise<void>,
}

interface PrompterViewportFuncsConfigStoreSlice {
    focusGuide: FocusGuide,
    flipVertical: boolean,
}

function usePrompterViewportFuncs(
  viewportRefs: IViewportRefs, 
  configStore: PrompterViewportFuncsConfigStoreSlice
): IViewportFuncs
{
  const getPrompterPosition = useGetPrompterPositionFunction(viewportRefs, configStore);
  const scrollToPosition = usePrompterScrollToPositionFunction(viewportRefs);

  const {
    getSegmentIndex,
    getSegmentIndexRelativeToActive, 
    pushLastScrollTarget,
    shiftLastScrollTarget,
    clearLastTargetSegmentIndex
  } = useSegmentNavigationFunctions();

  const scrollByDelta = usePrompterScrollByDeltaFunction(viewportRefs, scrollToPosition, pushLastScrollTarget, shiftLastScrollTarget);
  const scrollToSegment = usePrompterScrollToSegmentFunction(getPrompterPosition, scrollToPosition, pushLastScrollTarget, shiftLastScrollTarget);

  const queueSequentialTask = useSequentialTaskQueue();

  return {
    getPrompterPosition,
    scrollToPosition,
    scrollByDelta,
    scrollToSegment,
    getSegmentIndex,
    getSegmentIndexRelativeToActive, 
    pushLastScrollTarget,
    shiftLastScrollTarget,
    clearLastTargetSegmentIndex,
    queueSequentialTask,
  };
}

export default usePrompterViewportFuncs;