import React from 'react';
import { useMessageHandler } from '../../controllers/AppController';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import NotificationBar from './NotificationBar';
import SessionTimerDisplay from './SessionTimerDisplay';
import TrayContainer from './TrayContainer';
import HelpTrayIcon from './HelpTrayIcon';

import Button from '@mui/material/Button';

import Close from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

import useApplicationState from '../../state/ApplicationState';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import noSleep from '../../hooks/useNoSleep';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import './Navbar.scss';

function Navbar() {

  const [/*noSleepEnabled*/, setNoSleepEnabled] = noSleep.useNoSleepState();

  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up('sm'));

  const appState = useApplicationState();

  const prompterSession = usePrompterSession(state => ({
    hasUnsavedChanges: state.hasUnsavedChanges,
    isEditing: state.isEditing,
    cursorHidden: state.cursorHidden,
  }), shallow);

  useMessageHandler('app.qrcode-to-mobile', () => {
    appState.setRemoteDialogOpen(true);
  });

  return (
    <AppBar
      className={classNames('Navbar', { hideMode: (!prompterSession.isEditing && prompterSession.cursorHidden) })}
      sx={{
        /*transform: (!prompterSession.isEditing && prompterSession.cursorHidden) ? 'translateY(-100%)': 'none',*/
        transition: 'transform 500ms ease-in-out',   /* This needs to be in sx styles */
      }}
    >
      {/* <NotificationBar /> */}
      <Toolbar disableGutters>
        <Grid container columns={12} marginLeft={viewportIsLarge ? 2 : 1} marginRight={1}>
          <Grid item xs={6} sm={4} order={{ xs: 1, sm: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" height="100%">
              <Button 
                className="AppMenuButton"
                size={viewportIsLarge ? 'medium' : 'small'}
                startIcon={ appState.menuOpen ? <Close /> : <MenuIcon /> }
                sx={{
                  paddingLeft: '5px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  justifyContent: 'left',
                  textOverflow: 'ellipsis',
                }}
                onFocus={(e) => { e.target.blur(); }}
                onClick={() => {
                  setNoSleepEnabled(true);
                  appState.toggleMenuOpen();
                }}
              >
              Fluid Prompter{prompterSession.hasUnsavedChanges && '*'}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} order={{ xs: 3, sm: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="center" height="100%">
              <SessionTimerDisplay initialType="Empty" />
            </Stack>
          </Grid>
          <Grid item xs={6} sm={4} order={{ xs: 2, sm: 3 }}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" height="100%" spacing={1}>
              <TrayContainer viewportIsLarge={viewportIsLarge} onTrayItemClick={() => { setNoSleepEnabled(true); }} />
              <HelpTrayIcon onTrayItemClick={() => { setNoSleepEnabled(true); }} />
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;