import { useCallback } from 'react';
import Queue from 'queue-promise';

const queue = new Queue({
  concurrent: 1,
  interval: 0
});

function useSequentialTaskQueue() {
  const queueSequentialTask = useCallback(async function (taskFactory: () => Promise<void>) {
    // console.log('queueSequentialCommand() - enqueue(TaskFactory)');
    queue.enqueue(taskFactory);
  }, []);

  return queueSequentialTask;
}

export default useSequentialTaskQueue;