import { createTheme, ThemeOptions } from '@mui/material/styles';

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
  },
  typography: {
    fontSize: 14, // 14px is the default Mui fontSize, making this bigger makes everything bigger!
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overscrollBehavior: 'none',
        },
        body: {
          overscrollBehavior: 'none',
        }
      }
    },
  }
};

const themeInstance = createTheme(lightThemeOptions);

export default themeInstance;