import { useCallback } from 'react';
import { useMessageHandler } from '../../controllers/AppController';

// Slate Imports
import { Editor, BaseEditor, BaseSelection, createEditor, Range, Ancestor, Descendant, Text, BasePoint, BaseRange, Node, NodeEntry, Transforms, Path, Point } from 'slate';
import { ReactEditor, withReact, useFocused } from 'slate-react';
import { ElementTypes, PrompterEditor, PrompterElement, PrompterText } from '../../models/EditorTypes';

import { IViewportFuncs } from '../PrompterViewport/usePrompterViewportFuncs';

/**
 * Returns true if any node within the provided selection range of the provided Slate editor contains the mark.
 * @param editor Slate editor instance
 * @param selection The selection range we want to toggle a mark on
 * @param mark The mark name such as 'bold' or 'italic'
 * @returns 
 */
const isFormatActive = (editor: PrompterEditor, selection: BaseRange, mark: string) => {
  const nodesMatchedWithFormat = Editor.nodes(editor, {
    at: selection,
    match: (n: Node) => {
      const node = n as PrompterText;
      return node[mark] === true;
    },
    mode: 'all',
  });

  const firstMatchedNode = nodesMatchedWithFormat.next();
  return firstMatchedNode && firstMatchedNode.value;
};

export function getActiveStyles(editor: PrompterEditor) {
  return new Set(Object.keys(Editor.marks(editor) ?? {}));
}

export function toggleStyle(editor: PrompterEditor, style: string) {
  const activeStyles = getActiveStyles(editor);
  if (activeStyles.has(style)) {
    Editor.removeMark(editor, style);
  } else {
    Editor.addMark(editor, style, true);
  }
}

const clearMarks = (editor: PrompterEditor, selection: BaseRange) => {
  Transforms.setNodes<PrompterText>(
    editor,
    { bold: undefined, italic: undefined, underline: undefined, strike: undefined, highlight: undefined, code: undefined },
    {
      at: selection,
      match: Text.isText, 
      split: true
    }
  );
};

export interface FormatCommands {
  toggleBold: () => void;
  toggleItalic: () => void;
  toggleUnderline: () => void;
  toggleStrikethrough: () => void;
  toggleHighlight: () => void;
  clearFormatting: () => void;
}

const useEditorFormatCommandHandlers = (editor: PrompterEditor, viewportFuncs: IViewportFuncs): FormatCommands => {

  const toggleBold = useCallback(() => {
    if(editor.selection) {
      toggleStyle(editor, 'bold');
    }
  }, [editor]);
  useMessageHandler('prompter.editor.format.togglebold', toggleBold);

  const toggleItalic = useCallback(() => {
    if(editor.selection) {
      toggleStyle(editor, 'italic');
    }
  }, [editor]);
  useMessageHandler('prompter.editor.format.toggleitalic', toggleItalic);

  const toggleUnderline = useCallback(() => {
    if(editor.selection) {
      toggleStyle(editor, 'underline');
    }
  }, [editor]);
  useMessageHandler('prompter.editor.format.toggleunderline', toggleUnderline);

  const toggleStrikethrough = useCallback(() => {
    if(editor.selection) {
      toggleStyle(editor, 'strike');
    }
  }, [editor]);
  useMessageHandler('prompter.editor.format.togglestrike', toggleStrikethrough);

  const toggleHighlight = useCallback(() => {
    if(editor.selection) {
      toggleStyle(editor, 'highlight');
    }
  }, [editor]);
  useMessageHandler('prompter.editor.format.togglehighlight', toggleHighlight);

  const clearFormatting = useCallback(() => {
    if(editor.selection) {
      clearMarks(editor, editor.selection);
    }
  }, [editor]);
  useMessageHandler('prompter.editor.format.clear', clearFormatting);

  return {
    toggleBold,
    toggleItalic,
    toggleUnderline,
    toggleStrikethrough,
    toggleHighlight,
    clearFormatting,
  };
};

export default useEditorFormatCommandHandlers;