import { useCallback } from 'react';
import { useMessageHandler } from '../../controllers/AppController';

import DeviceHost from '../DeviceHost';
import BaseDevice, { DeviceConnectionType } from '../BaseDevice';

import PrompterPeerInstance from '../prompterpeer/PrompterPeerInstance';
import useBackgroundState, { BackgroundLayout, BackgroundTypes } from '../../state/BackgroundState';
import { BackgroundMessage, ConnectionState, EndpointRole } from '@fluidprompter/core';

function useRTCBackgroundClearHandler(deviceHost: DeviceHost) {

  const setBackgroundMute = useCallback(async (muted?: boolean) => {
    const localDeviceInstance = deviceHost
      .allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .find((device) => device.representsLocal);

    if(!localDeviceInstance) {
      throw new Error('Missing local peer instance.');
    }

    return localDeviceInstance.muteBackgroundTrack(muted);
  }, []);

  useMessageHandler('prompter.background.togglemute', async (e) => {
    const newMuteState = await setBackgroundMute();

    // Transmit 'prompter.background.setmute' to peers based on local mute state after toggle.
    if(newMuteState) {
      e.dispatchMessage('prompter.background.mute');
    } else {
      e.dispatchMessage('prompter.background.unmute');
    }
  });

  useMessageHandler('prompter.background.mute', async (e) => {
    e.sendToPeers = !e.originatedRemotely;

    await setBackgroundMute(true);
  });

  useMessageHandler('prompter.background.unmute', async (e) => {
    e.sendToPeers = !e.originatedRemotely;

    await setBackgroundMute(false);
  });

  useMessageHandler('prompter.background.contain', async (e) => {
    const localBackgroundState = useBackgroundState.getState();
    if(localBackgroundState.backgroundLayout !== BackgroundLayout.Contain) {
      localBackgroundState.setBackgroundLayout(BackgroundLayout.Contain);
    }
  });

  useMessageHandler('prompter.background.cover', async (e) => {
    const localBackgroundState = useBackgroundState.getState();
    if(localBackgroundState.backgroundLayout !== BackgroundLayout.Cover) {
      localBackgroundState.setBackgroundLayout(BackgroundLayout.Cover);
    }
  });

  useMessageHandler('prompter.background.clear', async (e) => {
    const allPeerDevices = deviceHost
      .allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .filter((device) => !device.representsLocal);

    //
    // When you share a desktop window, and stop sharing via a native browser control, the
    // video track is "ended".
    //
    allPeerDevices.forEach((device) => {
      device.clearBackgroundTrack();
    });

    //
    // Update our app state to be reflected in the UI.
    //
    const localBackgroundState = useBackgroundState.getState();
    if(localBackgroundState.backgroundType !== BackgroundTypes.None) {
      localBackgroundState.setBackgroundType(BackgroundTypes.None);
    }
  });
}

export default useRTCBackgroundClearHandler;