import { ConnectionState, PeerStateResponse, PrompterSessionEndpoint, ScriptRequestMessage } from '@fluidprompter/core';
import DeviceHost from './DeviceHost';
import PrompterPeerInstance from './prompterpeer/PrompterPeerInstance';
import usePrompterSession from '../state/PrompterSessionState';

import { Logger, TRACE } from 'browser-bunyan';
import logger from '../utils/Logger';

/**
 * Gathers connected PrompterPeerInstance's and gather's the current prompter script with a
 * priority order.
 *
 * If we get a connection to a prompter who is the current leader, then request the script
 * from the leader prompter. If we do not have any leader prompter (because all peers are
 * minimized) then we will request the script in priority order based on lastLeaderTimestamp.
 */
export class PeerScriptCollector {

  private logger: Logger;

  private _deviceHost: DeviceHost;
  private _peerCandidates: string[];

  private _startTime = 0;
  private _waitingForScript: boolean;

  constructor(deviceHost: DeviceHost) {
    this.logger = logger.child({
      childName: 'PeerScriptCollector',
    });
    this.logger.trace('PeerScriptCollector.constructor()');

    this._deviceHost = deviceHost;
    this._peerCandidates = [];
    this._waitingForScript = false;
  }

  get logLevel() {
    return this.logger.level();
  }
  set logLevel(levelNumber: number) {
    this.logger.level(levelNumber);
  }

  /**
   * Called when we receive a 'connect.response' websocket message to reset the pool of potential
   * leader prompters from which we will request the current script.
   */
  public setExpectedPeers(expectedPeerIds: string[]) {
    this.logger.trace('ScriptCollector.setExpectedPeers()');

    //
    // Gather our list of potential peer endpointIds from which to get our script.
    // Filter out our local endpointId or any bad data (missing endpointIds)
    //
    this._peerCandidates = expectedPeerIds;

    //
    // Check if we are already connected to all peer candidates. This will happen when the
    // websocket reconnects but we never lost our peer connections. In this case we don't need
    // to reload the script state.
    //
    let remotePeersAlreadyConnected = true;
    this._peerCandidates
      .forEach(expectedEndpointId => {
        if(expectedEndpointId) {
          // Find our existing PrompterPeerInstance if it exists.
          const existingPeerInstance = this._deviceHost.getDevice(expectedEndpointId) as PrompterPeerInstance | undefined;

          // If we are not already connected, then set our flag so we know we need to fetch the
          // current script.
          if(
            !existingPeerInstance?.representsLocal
              && existingPeerInstance?.connectionState !== ConnectionState.Connected
          ) {
            remotePeersAlreadyConnected = false;
          }
        }
      });

    this.logger.debug(`ScriptCollector.setExpectedPeers(): Already connected to all ${expectedPeerIds.length} peers = ${remotePeersAlreadyConnected}`);

    // We are only waitingForScript if we don't already have a connection to all expected peers.
    // In the event that we momentarily lost our websocket connection, but peer connections
    // remained active, we will re-establish our websocket connection but still have an up to date
    // script and don't want to reload the script.
    this._waitingForScript = !remotePeersAlreadyConnected;

    // No timeouts/retries are needed if we were already connected to all peers.
    if(remotePeersAlreadyConnected) {
      usePrompterSession.getState().setIsSynchronizing(false);
      return;
    }

    // We need to receive the script from another prompter peer! Show the visual indicator to the
    // end user.
    usePrompterSession.getState().setIsSynchronizing(true);
  }

  public resetExpectedPeers() {
    this.logger.trace('ScriptCollector.resetExpectedPeers()');

    this._peerCandidates = [];

    this._waitingForScript = false;

    usePrompterSession.getState().setIsSynchronizing(false);
  }

  /**
   * When a device is removed (unregistered) from the DeviceHost, we are no longer interested in
   * connecting with that peer.
   * @param targetPeerId
   */
  public removeExpectedPeer(targetPeerId: string) {
    const previousCandidateCount = this._peerCandidates.length;
    this._peerCandidates = this._peerCandidates.filter(expectedPeerId => expectedPeerId !== targetPeerId);

    //
    // If we have no more expected peers, then we don't need to be waiting to synchronize the
    // script.
    //
    if(previousCandidateCount && this._peerCandidates.length === 0) {
      this.logger.trace('All expected peers have been removed.');

      this._waitingForScript = false;

      usePrompterSession.getState().setIsSynchronizing(false);
    }
  }

  /**
   * Called when a PrompterPeerInstance becomes connected. If the new connection is contained in
   * our current candidate pool, we will evaluate whether we should request the script from this
   * peer. If this peer isLeader then we may request the script right away. If it is not the
   * leader, then we will wait until all peer candidates are connected and request the script in
   * priority order based on lastLeaderTimestamp.
   */
  public receivedPeerCandidateState(peerState: PeerStateResponse): boolean | undefined {
    const { sender, isLeader, lastScriptChangeTimestamp, lastLeaderTimestamp, lifecycleState } = peerState;
    if(!sender?.id) {
      this.logger.error('Received \'peer.state\' with no sender.id provided.');
      return;
    }

    const peerCandidate = this._deviceHost.getDevice(sender.id) as PrompterPeerInstance;
    if(!peerCandidate) {
      this.logger.error(`Received 'peer.state' message from ${sender.id}. Could not find PrompterPeerInstance.`);
      return;
    }

    this.logger.trace(`Received 'peer.state' message from ${sender.id}: isLeader=${isLeader}, lastLeaderTimestamp=${lastLeaderTimestamp}, lastScriptChangeTimestamp=${lastScriptChangeTimestamp}`);
    this.logger.trace(`ScriptCollector.receivedPeerCandidateState(${peerCandidate.endpointId}) - isLeader=${peerCandidate.isLeader}, lastLeaderTimestamp=${peerCandidate.lastLeaderTimestamp}, waitingForScript=${this._waitingForScript}`);

    peerCandidate.isLeader = isLeader;
    peerCandidate.lastScriptChangeTimestamp = lastScriptChangeTimestamp;
    peerCandidate.lastLeaderTimestamp = lastLeaderTimestamp;
    peerCandidate.appLifecycleState = lifecycleState;

    //
    // Check which peer has the most recent lastLeaderTimestamp.
    //
    const localPeer = this._deviceHost.getPrompterPeerLocalInstance();
    const peerCandidateShouldBeLeader = (localPeer && lastLeaderTimestamp > localPeer.lastLeaderTimestamp);
    if(peerCandidateShouldBeLeader) {
      // The newly connected peer has a more recent lastLeaderTimestamp than the local prompter.
      this.logger.trace(`Newly connected peer should be the leader. New peer has more recent lastLeaderTimestamp (${lastLeaderTimestamp} > ${localPeer.lastLeaderTimestamp})`);
      this._deviceHost.appController.setLeaderEndpointId(sender.id);
    } else {
      this.logger.trace(`Newly connected peer should NOT be the leader. New peer has less recent lastLeaderTimestamp (${lastLeaderTimestamp} <= ${localPeer?.lastLeaderTimestamp})`);
    }

    //
    // We will get here when we receive the peer state from another peer, regardless of whether
    // that was during an initial connection or reconnection and whether we initiated the
    // connection or the other peer did (This event will fire on both sides of the same peer
    // connection).
    //
    // We need to decide which peer has the most recent script and either request the other peer's
    // script or push our script to the other peer.
    //
    if(localPeer && (lastScriptChangeTimestamp && (!localPeer.lastScriptChangeTimestamp || lastScriptChangeTimestamp > localPeer.lastScriptChangeTimestamp))) {
      // The newly connected peer has a more recent lastScriptChangeTimestamp than the local peer.
      this.logger.trace(`Request script from new peer. New peer has more recent lastScriptChangeTimestamp (${lastScriptChangeTimestamp} > ${localPeer.lastScriptChangeTimestamp})`);
      this.requestScriptFromPeer(peerCandidate);
      return;
    }

    //
    // If we get here, our copy of the script appears to be the most recent.
    //
    this.logger.trace(`Keep local script. New peer has less recent lastScriptChangeTimestamp (${lastScriptChangeTimestamp} <= ${localPeer?.lastScriptChangeTimestamp})`);

    const shouldSyncPosition = this._waitingForScript;

    this._waitingForScript = false;

    usePrompterSession.getState().setIsSynchronizing(false);

    return shouldSyncPosition;
  }

  private requestScriptFromPeer(device: PrompterPeerInstance) {
    this.logger.trace(`ScriptCollector.requestScriptFromPeer(${device.endpointId})`);

    this._startTime = Date.now(); //Used to calculate how long it took to synchronize the script from other peers.

    device.sendMessage(new ScriptRequestMessage());
  }

  /**
   * Called when we have successfully received the current script state from a peer.
   * This will stop any further requests from other peers as we are all done for now.
   */
  public receivedScriptFromPeer(endpointId: string) {
    const scriptSender = this._deviceHost.getDevice(endpointId) as PrompterPeerInstance;

    const scriptSyncTime = Date.now() - this._startTime;
    this.logger.trace(`receivedScriptFromPeer(waitingForScript = ${this._waitingForScript}): from Peer #${scriptSender.peerNumber} in ${scriptSyncTime}ms`);

    this._waitingForScript = false;

    usePrompterSession.getState().setIsSynchronizing(false);
  }
}

export default PeerScriptCollector;