import React, { Suspense } from 'react';
import { useCallback } from 'react';
import { MessageHandlerEvent, useMessageHandler } from '../../controllers/AppController';
import { GenericMessage } from '@fluidprompter/core';

import useApplicationState from '../../state/ApplicationState';
import { shallow } from 'zustand/shallow';
import { zaraz } from 'zaraz-ts';

const HelpDialogRenderer = React.lazy(() => import('./HelpDialogRenderer'));

function HelpDialog() {

  const appState = useApplicationState(state => ({
    isHelpOpen: state.isHelpOpen,
    setHelpOpen: state.setHelpOpen,
  }), shallow);

  const handleClose = () => {
    appState.setHelpOpen(false);
  };

  const showHelp = useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    e.sendToPeers = false;

    // Fire analytics event for help.
    zaraz.track('app_event', {
      event_name: 'app_showhelp'
    });

    useApplicationState.getState().setHelpOpen(true);
  }, []);
  useMessageHandler('prompter.local.showhelp', showHelp);

  return (
    <Suspense>
      {appState.isHelpOpen && <HelpDialogRenderer handleClose={handleClose} />}
    </Suspense>
  );
}

export default HelpDialog;