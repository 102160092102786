import { useCallback } from 'react';
import { useMessageHandler } from '../../controllers/AppController';
import { IViewportFuncs } from './usePrompterViewportFuncs';

// interface ScreenOrientation {
//   angle: 0, 
//   type: "landscape-primary", 
//   onchange: ((this: ScreenOrientation, ev: Event) => any) | null;
// }

interface ScreenDetailedEventMap {
  'change': Event;
}

/** A screen, usually the one on which the current window is being rendered, and is obtained using window.screen. */
interface ScreenExtended extends Screen {
  readonly isExtended?: boolean;
}

interface ScreenDetailed extends ScreenExtended {
  // availHeight: number;
  // availWidth: number;
  // colorDepth: number;
  // height: number;
  // orientation: ScreenOrientation;
  // pixelDepth: number;
  // width: number;

  availLeft: number;
  availTop: number;
  devicePixelRatio: number;
  isExtended: boolean;
  isInternal: boolean;
  isPrimary: boolean;
  label: string;
  left?: number;
  top?: number;

  onchange: ((this: ScreenDetailed, ev: Event) => unknown) | null;
  addEventListener<K extends keyof ScreenDetailedEventMap>(type: K, listener: (this: ScreenDetailed, ev: ScreenDetailedEventMap[K]) => unknown, options?: boolean | AddEventListenerOptions): void;
  addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
  removeEventListener<K extends keyof ScreenDetailedEventMap>(type: K, listener: (this: ScreenDetailed, ev: ScreenDetailedEventMap[K]) => unknown, options?: boolean | EventListenerOptions): void;
  removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
}

interface ScreenDetailsEventMap {
  'currentscreenchange': Event;
  'screenschange': Event;
}

interface ScreenDetails {
  currentScreen: ScreenDetailed;
  screens: ScreenDetailed[];

  oncurrentscreenchange: ((this: ScreenDetailed, ev: Event) => unknown) | null;
  onscreenschange: ((this: ScreenDetailed, ev: Event) => unknown) | null;
  addEventListener<K extends keyof ScreenDetailsEventMap>(type: K, listener: (this: ScreenDetailed, ev: ScreenDetailsEventMap[K]) => unknown, options?: boolean | AddEventListenerOptions): void;
  addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
  removeEventListener<K extends keyof ScreenDetailsEventMap>(type: K, listener: (this: ScreenDetailed, ev: ScreenDetailsEventMap[K]) => unknown, options?: boolean | EventListenerOptions): void;
  removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
}

type PermissionName = 'window-management' | 'window-placement' | 'geolocation' | 'notifications' | 'persistent-storage' | 'push' | 'screen-wake-lock' | 'xr-spatial-tracking';

interface PermissionDescriptor {
  name: PermissionName;
}

declare global {
  //
  // Define the experimental window-management api methods.
  //
  interface Window {
    // https://caniuse.com/mdn-api_window_getscreendetails
    getScreenDetails(): Promise<ScreenDetails>;
  }

  //
  // Redefine the browser permissions query api to support the "window-management" | 
  // "window-placement" permissions types only supported by Chromium based browsers.
  //
  interface Permissions {
    query(permissionDesc: PermissionDescriptor): Promise<PermissionStatus>;
  }  
}

//
// Returns a promise that resolves with a string.
//
const getWindowManagementPermissionState = async (): Promise<string> => {
  let state;
  // The new permission name.
  try {
    ({ state } = await navigator.permissions.query({
      name: 'window-management',
    }));
  } catch (err: unknown) {
    const error = err as Error;

    if (error.name !== 'TypeError') {
      return `${error.name}: ${error.message}`;
    }
    // The old permission name.
    try {
      ({ state } = await navigator.permissions.query({
        name: 'window-placement',
      }));
    } catch (err: unknown) {
      const error = err as Error;
      if (error.name === 'TypeError') {
        return 'Window management not supported.';
      }
      return `${error.name}: ${error.message}`;
    }
  }

  // type PermissionState = "denied" | "granted" | "prompt";
  return state;
};

const windowManagementMethod = async (): Promise<ScreenDetailed> => {
  // let targetScreen = window.screen as ScreenDetailed;
  let targetScreen = Object.assign({
    label: 'Default Monitor',
    top: 0,
    availTop: 0,
    left: 0,
    availLeft: 0,
    width: 0,
    availWidth: 0,
    height: 0,
    availHeight: 0,
    isPrimary: true,    // Not really known, just a failback
    isExtended: false,  // Not really known, just a failback
    isInternal: true,   // Not really known, just a failback
  }, window.screen) as ScreenDetailed;

  if (targetScreen.isExtended) {
    console.log('Multiple screens detected');
  }
  
  //
  // Detect if the current browser support the window-management API for querying monitor configuration.
  //
  if ('getScreenDetails' in window) {
    //
    // Request browser window-management permissions
    //
    const permissionState = await getWindowManagementPermissionState();
    if(permissionState !== 'granted') {
      alert('You must grant permission for FluidPrompter to manage window placement to open a projector on a 2nd monitor or display.');
    }
    console.log(`Browser responded with permission state ${permissionState}`);

    // The Multi-Screen Window Placement API is supported.
    const { screens, currentScreen } = await window.getScreenDetails();
    console.log(screens);
    /* Output from my MacBook Pro 13″ with the iPad attached:
    {
      currentScreen: ScreenDetailed {left: 0, top: 0, isPrimary: true, isInternal: true, devicePixelRatio: 2, …}
      oncurrentscreenchange: null
      onscreenschange: null
      screens: [{
        // The MacBook Pro
        availHeight: 969
        availLeft: 0
        availTop: 25
        availWidth: 1680
        colorDepth: 30
        devicePixelRatio: 2
        height: 1050
        isExtended: true
        isInternal: true
        isPrimary: true
        label: "Built-in Retina Display"
        left: 0
        onchange: null
        orientation: ScreenOrientation {angle: 0, type: "landscape-primary", onchange: null}
        pixelDepth: 30
        top: 0
        width: 1680
      },
      {
        // The iPad
        availHeight: 999
        availLeft: 1680
        availTop: 25
        availWidth: 1366
        colorDepth: 24
        devicePixelRatio: 2
        height: 1024
        isExtended: true
        isInternal: false
        isPrimary: false
        label: "Sidecar Display (AirPlay)"
        left: 1680
        onchange: null
        orientation: ScreenOrientation {angle: 0, type: "landscape-primary", onchange: null}
        pixelDepth: 24
        top: 0
        width: 1366
      }]
    }
    */

    let primary: ScreenDetailed = window.screen as ScreenDetailed;
    let second: ScreenDetailed | null = null;

    for (const element of screens) {
      if (element.isPrimary) {
        primary = element;
      } else {
        second = element;
      }
    }

    targetScreen = second || primary;
  }

  return targetScreen;
};

function usePrompterWindowManagement(viewportFuncs: IViewportFuncs) {

  const doOpenProjectorWindow = useCallback(async function () {
    // Launch a 2nd browser window!

    const screenDetails = await windowManagementMethod();
    if(screenDetails.isPrimary) {
      // We don't have a 2nd display.
    }

    const projectorUrl = new URL(window.location.href);
    projectorUrl.pathname = '/viewer';

    const popup = window.open(
      projectorUrl.toString(),
      'FluidPrompter Viewer',
      `left=${screenDetails.isPrimary ? (screenDetails.width * 0.6) : (screenDetails.left || 0)},top=${screenDetails.top || 0},width=${screenDetails.isPrimary ? (screenDetails.width * 0.4) : screenDetails.width},height=${screenDetails.height},popup=1`,
    );

    if(!popup) {
      // popup was blocked, perhaps user denied permission...
      alert('popup was blocked');
      return;
    }

    // Move pop-up to the 2nd monitor.
    // popup.moveTo(2500, 50);
    // popup.resizeTo(0, 0);
    // window.setTimeout(() => {
    //   popup.postMessage('popup');
    // }, 1000);

  }, []);

  useMessageHandler('prompter.windows.projector.start', () => {
    viewportFuncs.queueSequentialTask(async () => {
      doOpenProjectorWindow();
    });
  });
}

export default usePrompterWindowManagement;