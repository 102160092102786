import React from 'react';
import { RenderElementProps } from 'slate-react';

const CodeBlockElement = (props: RenderElementProps) => {
  return (
    <pre {...props.attributes}>
      <code>{props.children}</code>
    </pre>
  );
};

export default CodeBlockElement;