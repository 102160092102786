import BaseDevice from '../BaseDevice';
import { BaseDeviceEvent } from './BaseDeviceEvent';

/**
 * Represent a state change in a button or digital input device.
 * Most commonly a "keyboard" or other control surface with a collection of buttons.
 * 
 * See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent
 */
export class DeviceButtonEvent extends BaseDeviceEvent<Event> {
  readonly type = 'DeviceButtonEvent';

  constructor(
    device: BaseDevice, 
    buttonName: string, // 
    eventType: string,  // down, up, press
    proposedAction?: string,
  ) {
    super(device, new Event('SyntheticDeviceButtonEvent'));

    this._buttonName = buttonName;
    this._eventType = eventType;
    this._proposedAction = proposedAction;
  }

  get buttonName(): string {
    return this._buttonName;
  }
  private _buttonName: string;

  get eventType(): string {
    return this._eventType;
  }
  private _eventType: string;

  get proposedAction(): string | undefined {
    return this._proposedAction;
  }
  private _proposedAction: string | undefined;
}