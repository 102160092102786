import React, { useEffect, useState, forwardRef } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

interface BlinkingIconButtonProps extends IconButtonProps {
  onIcon?: React.ReactNode;
  blinkIcon?: React.ReactNode;
  offIcon?: React.ReactNode;
  on?: boolean;
  blink?: boolean;
  disabled?: boolean;
}

const BlinkingIconButton = forwardRef<HTMLButtonElement, BlinkingIconButtonProps>(function BlinkingIconButton(props: BlinkingIconButtonProps, ref) {

  // Destructure our custom props from the `rest` which 
  // are `IconButtonProps` for our internal IconButton
  const {
    onIcon,
    blinkIcon,
    offIcon,
    on,
    blink,
    ...rest
  } = props;

  const [cloudIsSolid, setcloudIsSolid] = useState(false);
  useEffect(() => {
    let cloudBlinkInterval = 0;

    if(blink) {
      cloudBlinkInterval = window.setInterval(() => {
        setcloudIsSolid(c => !c);
      }, 1000);
    }

    return () => { clearInterval(cloudBlinkInterval); };
  }, [blink]);

  const getIcon = function(): React.ReactNode {
    if(props.disabled) {
      return offIcon;
    }

    if(blink) {
      // We want a blinking icon
      return cloudIsSolid ? onIcon: blinkIcon;
    } else {
      // We want a steady on or off icon.
      return on ? onIcon : offIcon;
    }
  };

  return (
    <IconButton ref={ref} {...rest}>
      {getIcon()}
    </IconButton>
  );
});

export default BlinkingIconButton;