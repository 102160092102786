interface IButtonCodes {
  [index: number]: string;
}

const buttonCodes: IButtonCodes = {
  0x01: 'Smart Insert',
  0x02: 'Append',
  0x03: 'Ripple',
  0x04: 'Close Up',
  0x05: 'Place On Top',
  0x06: 'Source',
  //
  0x07: 'In',           // PAGE UP
  0x08: 'Out',          // PAGE DOWN
  0x09: 'Trim In',
  0x0A: 'up',           // UP
  0x0B: 'Roll',
  0x0C: 'left',         // LEFT
  0x0D: 'down',         // DOWN
  0x0E: 'right',        // RIGHT
  0x0F: 'Cut',
  0x10: 'Dissolve',
  0x11: 'Smooth Cut',
  0x12: 'Smooth Cut',
  //
  0x31: 'esc',         // 49
  0x1F: 'Sync Bin',       // 31
  0x2C: 'Audio Level',    // 44
  0x2D: 'Full View',      // 45
  //
  0x22: 'Transistion',    // 34
  0x2F: 'Split',          // 47
  0x2E: 'Snap',           // 46
  0x2B: 'Ripple Delete',  // 43
  //
  0x39: 'Camera 7',       // 57
  0x3A: 'Camera 8',       // 58
  0x3B: 'Camera 9',       // 59
  0x30: 'Live',           // 48
  //
  0x36: 'Camera 4',       // 54
  0x37: 'Camera 5',       // 55
  0x38: 'Camera 6',       // 56
  0x25: 'Video Only',     // 37
  //
  0x33: 'Camera 1',       // 51
  0x34: 'Camera 2',       // 52
  0x35: 'Camera 3',       // 53
  0x26: 'Audio Only',     // 38
  //
  0x3C: 'ok',      // 60 - The big "space bar" key.
  //
  0x1A: 'Source',         // 26
  0x1B: 'Timeline',       // 27
  //
  0x1C: 'SHTL',           // 28 - Shuttle = Scroll at 2x, 4x, 8x play speed (very fast seeking)
  0x1D: 'JOG',            // 29 - Jog = Scroll by frames
  0x1E: 'SCRL'            // 30 - Scroll = Scroll by seconds
};

export default buttonCodes;