import React from 'react';
import Box from '@mui/material/Box';

interface AccountPanelOutlinedProps {
  className?: string;
  title?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
}

const AccountPanelOutlined = function(props: AccountPanelOutlinedProps) {
  return (
    <Box
      className={props.className}
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        marginBottom: 2,
      }}>
      <Box sx={{
        background: '#f6f8fa',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}>
        {props.title}
      </Box>
      <Box sx={{
        padding: 2,
      }}>
        {props.children}
      </Box>
    </Box>
  );
};

export default AccountPanelOutlined;