import BinaryChunkMessage from "./BinaryChunkMessage";
import { parse as parseUUID } from 'uuid';
const chunk_size = 16000; // Chunk size for transmitting on the wire.
const broadcastId = new Uint8Array(16);
/**
 * This class will chunk up a large binary payload into chunks of a set size for transmission
 * across the newtwork.
 */
class BinarySender {
    constructor(senderId, messages, targetEndpointId) {
        this._senderId = new Uint8Array(parseUUID(senderId));
        // console.log(`parsed UUID bytes: `, this._senderId);
        if (this._senderId.byteLength !== 16) {
            throw new Error(`Error: senderId must be 16 bytes.`);
        }
        if (targetEndpointId) {
            this._targetId = new Uint8Array(parseUUID(targetEndpointId));
            if (this._targetId.byteLength !== 16) {
                throw new Error(`Error: targetId must be 16 bytes.`);
            }
        }
        this._messages = messages;
    }
    toBinaryChunks() {
        const messagesJson = JSON.stringify(this._messages);
        const textEncoder = new TextEncoder(); // always utf-8
        const messagesBytes = textEncoder.encode(messagesJson);
        const numberOfChunks = Math.ceil(messagesBytes.length / chunk_size);
        // console.log(`BinarySender(${this._messages?.length}).toBinaryChunks() messagesJson.length=${messagesJson.length}`);
        // console.log(`BinarySender(${this._messages?.length}).toBinaryChunks() messagesBytes.length=${messagesBytes.length}`);
        //
        // Break the message bytes into 16kb chunks that we will transmit over the wire.
        //
        const binaryChunks = [];
        for (let chunkIndex = 0; chunkIndex < messagesBytes.length; chunkIndex += chunk_size) {
            // Slice off max 16kb of bytes to be sent.
            const chunkContent = messagesBytes.slice(chunkIndex, chunkIndex + chunk_size);
            const binaryChunkMessage = new BinaryChunkMessage(1, messagesBytes.length, chunkIndex + 1, this._senderId, // 16 bytes (128 bits) UUID
            this._targetId || broadcastId, chunkContent);
            const binaryChunk = binaryChunkMessage.toBinary();
            // console.log(`BinarySender.toBinaryChunks() binaryChunk[${chunkIndex}].length=${binaryChunk.length}, contentLength=${chunkContent.length}`);
            binaryChunks.push(binaryChunk);
        }
        // console.log(`BinarySender(${this._messages?.length}).toBinaryChunks() binaryChunks.length=${binaryChunks.length}`);
        return binaryChunks;
    }
}
export default BinarySender;
