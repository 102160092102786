import * as React from 'react';
import useApplicationContext from '../hooks/useApplicationContext';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function LogoutPage() {

  const {
    appContext,
    logout,
  } = useApplicationContext(false);
  const {
    isLoading,
    isAuthenticated,
    userProfile,
  } = appContext;

  React.useEffect(() => {
    if(isLoading) {
      return;
    }

    // Initiate a Logout Flow now.
    const returnUrl = new URL(window.location.href);
    returnUrl.pathname = '/';
    returnUrl.search = '';
    returnUrl.hash = '';
    const finalUrl = returnUrl.toString();

    returnUrl.pathname = '/auth/';
    returnUrl.search = '';    // Make sure we are not forwarding the /logout portion of the current page URL, but do preserve any querystrings!
    returnUrl.searchParams.set('action', 'logout');
    returnUrl.searchParams.set('redirectUrl', finalUrl);

    // If we are already not authenticated...
    if(!isAuthenticated) {
      window.location.replace(finalUrl);
      return;
    }

    // If we need to become unauthenticated...
    try {
      logout({
        openUrl: (url: string) => {
          window.location.replace(url);
        },
        logoutParams: {
          returnTo: returnUrl.toString(),
        },
      });
    } catch(err) {
      //
      // This occurs when the Auth0Provider is not present or misconfigured.
      // The Auth0Provider will not be loaded in a non-secure context outside of localhost.
      //
      window.location.replace(finalUrl);
    }
  }, [isLoading, isAuthenticated, logout]);

  return (
    <Backdrop open={true}>
      <CircularProgress color="inherit" />
      <span style={{
        marginLeft: '16px'
      }}>Loading...</span>
    </Backdrop>
  );
}

export default LogoutPage;