import React from 'react';

interface ISampleData {
  time: number,
  value: number,
}

interface IJitterAccumulator {
  previousSample: number;
  totalVariance: number;
  sampleCount: number;
}

interface CalculatedStatistics {
  count: number;
  sum: number;
  avg: number;
  jitter: number;
}

function useStatsCollector(collectionPeriod?: number) {
  const configMs: number = collectionPeriod || 1000;

  const samplesRef = React.useRef<ISampleData[]>([]);

  return React.useCallback((value: number): CalculatedStatistics => {
    const time = performance.now();
    const cutoff = time - configMs;

    // Filter out old stat samples.
    const newSamples = samplesRef.current.filter((entry) => { return entry.time > cutoff; });
    // Push new stat sample.
    newSamples.push({ time, value });
    samplesRef.current = newSamples;

    // Sum all values collected in the last second.
    const count = newSamples.length;
    const sum = newSamples.reduce((partialSum, a) => partialSum + a.value, 0);
    const avg = (sum / newSamples.length) || 0;


    const jitterAccumulator = newSamples.reduce<IJitterAccumulator>((prev, sample) => {
      const {totalVariance, sampleCount} = prev;
      const sampleDelta = Math.abs(sample.value - prev.previousSample);
      return {
        previousSample: sample.value,
        totalVariance: totalVariance + sampleDelta,
        sampleCount: sampleCount + 1,
      };      
    }, {
      previousSample: newSamples[0].value,
      totalVariance: 0,
      sampleCount: 0,
    });

    const { totalVariance, sampleCount } = jitterAccumulator;
    const jitter = totalVariance / sampleCount;

    return { count, sum, avg, jitter };
  }, [configMs]);
}

export default useStatsCollector;