import React, { useCallback } from 'react';
import Collapse from '@mui/material/Collapse';

import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';

import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { useTranslation } from 'react-i18next';
import useApplicationState from '../../../state/ApplicationState';

import { FluidPrompterPlans, IUserProfile } from '@fluidprompter/core';
import { useAppController } from '../../../controllers/AppController';

const getAccountPlanLevelDescription = (plan?: FluidPrompterPlans) => {
  switch(plan) {
    default:
    case 'free':
      return 'Free plan';
    case 'pro':
      return 'Pro plan';
    case 'studio':
      return 'Studio plan';
  }
};

interface OnlineUserMenuProps {
  expanded: boolean;
  onExpand: () => void;
  isLoading?: boolean;
  isAuthenticated?: boolean;
  userProfile?: IUserProfile;
}
const OnlineUserMenu = (props: OnlineUserMenuProps) => {
  const { expanded, onExpand, isLoading, isAuthenticated, userProfile } = props;

  const appState = useApplicationState();

  const { t } = useTranslation('appmenu');

  const appController = useAppController();

  const handleAuthMenuClick = useCallback((/* event: React.MouseEvent<HTMLDivElement, MouseEvent> */) => {
    if(isAuthenticated) {
      // Toggle the account collapse region.
      onExpand();
    } else {
      appController.dispatchMessage('prompter.local.login');
    }
  }, [isAuthenticated, appController, onExpand]);

  const handleLogoutMenuClick = useCallback((/* event: React.MouseEvent<HTMLDivElement, MouseEvent> */) => {
    appController.dispatchMessage('prompter.local.logout');
  }, [appController]);

  return (<>
    <ListItemButton
      className='UserMenuButton'
      alignItems="flex-start"
      selected={expanded}
      onClick={handleAuthMenuClick}
    >
      <ListItemAvatar>
        {(isAuthenticated && !isLoading) ? (<Avatar alt={(userProfile?.user_nickname || userProfile?.email)} src={userProfile?.avatar_url} />) : (<Avatar><PersonIcon/></Avatar>)}
      </ListItemAvatar>
      <ListItemText
        primary={(isAuthenticated && !isLoading) ? (userProfile?.user_nickname || userProfile?.email) : t('usermenu.signinbutton.caption')}
        secondary={(isAuthenticated && !isLoading) ? getAccountPlanLevelDescription(userProfile?.fluidprompter_plan) : t('usermenu.signinbutton.helpertext')}
      />
      <ListItemSecondaryAction style={{ height: '24px' }}>
        {expanded ? <ExpandLess /> : <ChevronRightIcon />}
      </ListItemSecondaryAction>
    </ListItemButton>
    <Collapse
      in={expanded} timeout="auto" unmountOnExit
    >
      <List component="div" disablePadding>
        <ListItemButton
          sx={{ pl: 4 }}
          onClick={() => {
            appState.setAccountSettingsOpen(true);
          }}
        >
          <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
          <ListItemText primary={t('usermenu.accountsettingsbutton.caption')} />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4 }} onClick={handleLogoutMenuClick}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary={t('usermenu.signoutbutton.caption')} />
        </ListItemButton>
      </List>
    </Collapse>
    <Divider/>
  </>);
};

export default OnlineUserMenu;