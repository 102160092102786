import { BaseControlMessage } from './BaseControlMessage';
export const SETREVERSEMESSAGE_KEY = 'setreverse';
/**
 * Represents the SetReverseMessage type.
 */
export class SetReverseMessage extends BaseControlMessage {
    constructor(operation) {
        super();
        this.type = SETREVERSEMESSAGE_KEY;
        this.operation = operation;
    }
    static fromJson(data) {
        if (data.type !== SETREVERSEMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for SetReverseMessage.');
        }
        const input = data;
        const instance = new SetReverseMessage(input.operation);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            operation: this.operation,
        };
    }
}
SetReverseMessage.MESSAGE_NAME = SETREVERSEMESSAGE_KEY;
(function (SetReverseMessage) {
    let Operation;
    (function (Operation) {
        Operation["Toggle"] = "toggle";
        Operation["Enable"] = "enable";
        Operation["Disable"] = "disable";
    })(Operation = SetReverseMessage.Operation || (SetReverseMessage.Operation = {}));
})(SetReverseMessage || (SetReverseMessage = {}));
