import React from 'react';
import { RenderLeafProps, useFocused } from 'slate-react';

import KbdElement from '../KbdElement';
import TextElement from '../TextElement';

export function renderLeaf(props: RenderLeafProps) {
  const { leaf } = props;

  if(leaf.kbd) {
    return <KbdElement {...props} />;
  }

  const editorIsFocused = useFocused();    // Get the current focused state of the editor.

  return (
    <>
      {leaf.placeholder && editorIsFocused && <span className="EmptyLinePlaceholder" contentEditable={false}>
        Type / for menu
      </span>}
      <TextElement {...props} />
    </>
  );
}