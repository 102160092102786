import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import ShotLogButton from './ShotLogButton';

import RepeatIcon from '@mui/icons-material/Repeat';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import AddLocationIcon from '@mui/icons-material/AddLocation';

import './ShotLogModal.scss';
import { useAppController, useMessageHandler } from '../../../controllers/AppController';
import { PlayMessage } from '@fluidprompter/core';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  // borderRadius: '8px',
  // boxShadow: 24,
  p: 1,
};

const ShotLogModal = () => {

  const appController = useAppController();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [autoContinueMilliseconds, setAutoContinueMilliseconds] = useState<number | undefined>();
  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false);

  //
  // Shotlog popup.
  //
  useMessageHandler('prompter.prompt.shotlog', () => {
    // TODO: Make this queue a promise? So that it can be chained after a delay or similar.
    // Prompt user for shotlog input
    setShowMoreOptions(false);
    setOpen(true);
    setAutoContinueMilliseconds(5000);
  });

  return (<>
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        invisible: true,
      }}
    >
      <Fade timeout={350} in={open}>
        <Box className="ShotLogModal" sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
          How was that last take?
          </Typography>
          <div className="ShotLogActions" contentEditable={false}>
            <ShotLogButton
              className="ShotLogButton"
              startIcon={<RepeatIcon />}
              // disabled={editorIsReadOnly}
              aria-label="Log last take in shot log as bad and rewind for a retake"
              aria-haspopup="menu"
              onClick={(e) => {
              // Stop the auto click timer.
                setAutoContinueMilliseconds(undefined);
                setOpen(false);

                // RETAKE!
                appController.dispatchMessage('prompter.navigate.restartsegment');
                appController.dispatchMessage('prompter.delay.actions');
                appController.dispatchMessage(new PlayMessage());

                const btn = e.target as HTMLButtonElement;
                if(btn) { btn.blur(); }  
              }}
            >Retake</ShotLogButton>

            <ShotLogButton
              className="ShotLogButton FlexReverse"
              // ref={menuAnchorRef}
              endIcon={<MoreHorizIcon />}
              // disabled={editorIsReadOnly}
              // aria-expanded={menuOpen ? 'true' : undefined}
              aria-label="Additional shot logging options"
              aria-haspopup="menu"
              onClick={() => {
                if(autoContinueMilliseconds) {
                  setAutoContinueMilliseconds(undefined);
                  setShowMoreOptions(true);
                } else {
                  setAutoContinueMilliseconds(5000);
                }
              }}
            >More</ShotLogButton>

            <ShotLogButton
              className="ShotLogButton FlexReverse"
              endIcon={<PlayArrowIcon />}
              // disabled={editorIsReadOnly}
              aria-label="Log last take in shot log as good"
              aria-haspopup="menu"
              // onClick={handleToggle}
              autoClickMilliseconds={autoContinueMilliseconds}
              onClick={() => {
                setOpen(false);
                setAutoContinueMilliseconds(undefined);
                appController.dispatchMessage('prompter.delay.actions');
                appController.dispatchMessage(new PlayMessage());
              }}
            >Continue</ShotLogButton>
          </div>
          <Collapse in={showMoreOptions} timeout="auto">
          Change Clip Color
          Edit Meta Data
          This is more options!
          </Collapse>
        </Box>
      </Fade>
    </Modal>  
  </>);
};

export default ShotLogModal;