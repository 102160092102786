import { useState, useCallback, useEffect, useRef } from 'react';
import { useMessageHandler } from '../../controllers/AppController';

import DeviceHost from '../DeviceHost';
import BaseDevice, { DeviceConnectionType } from '../BaseDevice';

import PrompterPeerInstance from '../prompterpeer/PrompterPeerInstance';
import useBackgroundState, { BackgroundTypes } from '../../state/BackgroundState';
import { BackgroundMessage, ConnectionState, EndpointRole } from '@fluidprompter/core';

function useRTCBackgroundSetHandler(deviceHost: DeviceHost) {
  const [backgroundMediaStream, setBackgroundMediaStream] = useState<MediaStream>();

  useMessageHandler('background', async (e) => {
    if(!e.message.isLocalSource) {
      // We only want to transmit background streams to peers if they are originating from this
      // local instance.
      return;
    }

    const allPeerDevices = deviceHost
      .allDevices<PrompterPeerInstance>(DeviceConnectionType.Network);

    //
    // Shen you share a desktop window, and stop sharing via a native browser control, the
    // video track is "ended".
    //
    allPeerDevices.forEach((device) => {
      device.sendBackgroundMediaStream(e.message.mediaStream);
    });
    setBackgroundMediaStream(e.message.mediaStream);

    /*
    let videoTrack: MediaStreamTrack | undefined;
    const videoTracks = e.message.mediaStream.getVideoTracks();
    if(videoTracks && videoTracks.length) {
      videoTrack = videoTracks[0];

      //
      // Discover native media dimensions (is this a 720p, 1080p or 4k webcam?)
      //
      // const capabilities = videoTrack.getCapabilities();
      // const videoTrackSettings = videoTrack.getSettings();
      // setMediaTrackSettings(videoTrackSettings);

      //videoTrackSettings.deviceId
      // console.log(`VideoTrack Capabilities - Width: ${videoTrackSettings.width}, Height: ${videoTrackSettings.height}`);
      // capabilities.height
      // capabilities.width

      // videoTrack.addEventListener('ended', () => {
      //   console.log('videoTrack ended');
      //   appController.dispatchMessage('prompter.background.clear');
      // });
    }
    */
  });

  return backgroundMediaStream;
}

export default useRTCBackgroundSetHandler;