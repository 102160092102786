import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function SignupPage() {

  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  if(!isAuthenticated) {
    const returnUrl = new URL(window.location.href);
    returnUrl.pathname = '';  // Make sure we are not forwarding the /login portion of the current page URL, but do preserve any querystrings!
    const finalUrl = returnUrl.toString();
    returnUrl.pathname = '/auth/';
    returnUrl.search = '';
    returnUrl.hash = '';

    // User is not currently authenticated/
    loginWithRedirect({
      appState: {
        returnTo: finalUrl,
      },
      authorizationParams: {
        screen_hint: 'signup',

        /**
         * The URL where Auth0 will redirect your browser to with
         * the authentication result. It must be whitelisted in
         * the "Allowed Callback URLs" field in your Auth0 Application's
         * settings.
         */
        redirect_uri: returnUrl.toString(),
      },
    });
  }

  return (
    <>
    Please wait
    </>
  );
}

export default SignupPage;