import React, { useCallback } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitleWithClose from '../DialogTitleWithClose';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import ShotLogTable from './ShotLogTable';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

import useInputMiddleware, { InputMiddlewareProps } from '../../hooks/useInputMiddleware';

import useApplicationState from '../../state/ApplicationState';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import { ShotLogEntryType } from '@fluidprompter/core';

import { ThemeProvider } from '@mui/material/styles';
import lightTheme from '../../themes/LightTheme';
import './ShotLogReviewDialog.scss';
import { useMessageHandler } from '../../controllers/AppController';

const ShotLogReviewDialog = () => {

  const appState = useApplicationState(state => ({
    isShotLogOpen: state.isShotLogOpen,
    setShotLogOpen: state.setShotLogOpen,
  }), shallow);

  const prompterState = usePrompterSession(state => ({
    sessionStartTimestamp: state.sessionStartTimestamp,
    shotlogEntries: state.shotlogEntries,
    // startShotLogEntry: state.startShotLogEntry,
    // updateShotLogEntry: state.updateShotLogEntry,
  }), shallow);

  const { shotlogEntries } = prompterState;

  const handleClose = () => {
    appState.setShotLogOpen(false);
  };

  const showHelp = useCallback(async function () {
    useApplicationState.getState().setShotLogOpen(true);
  }, []);
  useMessageHandler('prompter.shotlog.show', showHelp);

  const handleDownloadShotLog = useCallback(() => {
    //
    // {
    // "ver":1,
    // "name":"SessionName",
    // "timems":1670969788000,
    // "time":1670969787,
    // "cuts":[
    //    {"e":"*","t":5039.8999999999},
    //    {"e":"x","t":10250.3},
    //    {"e":"*","t":15171.9},
    //    {"e":"x","t":20217.5},
    //    {"e":"x","t":25653.6},
    //    {"e":"*","t":29520.2,"take":"*","color":"Orange"},
    //    {"e":"*","t":32526},
    //    {"e":"x","t":32526.3}],
    // "sync":1,
    // "start":0,
    // "idx":2
    // }
    //

    const now = new Date();
    const nowISO = now.toISOString();
    const shotLogFileName = `FluidPrompter Shot Log - ${nowISO}`;

    // Find the origin timestamp that will be subtract from other timestamps to get relative 
    // distance from start of video shoot.
    let sessionStartTimestamp = shotlogEntries[0].startTimestamp;
    for (let i = 1; i < shotlogEntries.length; i++) {
      const thisEntry = shotlogEntries[i];
      if (thisEntry.startTimestamp < sessionStartTimestamp) {
        sessionStartTimestamp = thisEntry.startTimestamp;
      }
    }
    const sessionStartSeconds = Math.floor(sessionStartTimestamp / 1000);

    if(sessionStartTimestamp <= 0) {
      throw new Error('Could not find proper sessionStartTimestamp');
    }

    const shotLogCuts = shotlogEntries.map((shotLogEntry) => {
      return {
        // "*" - keep everything since the last checkpoint
        // "x" - cut everything since the last checkpoint
        'e': (shotLogEntry.type === ShotLogEntryType.BadTake) ? 'x' : '*',
        't': shotLogEntry.finishTimestamp - sessionStartTimestamp,
        'color': (shotLogEntry.type === ShotLogEntryType.OkTake) ? 'Orange' : undefined,
        'title': shotLogEntry.title,
      };
    });

    const shotLogJson = {
      'ver': 1,
      'name': shotLogFileName,
      'timems': sessionStartTimestamp,  // 1670969788000,
      'time': sessionStartSeconds,    // 1670969787,
      //
      'cuts': shotLogCuts,
      //
      'sync':1,
      'start':0,
      'idx':2
    };

    const shotLogJsonString = JSON.stringify(shotLogJson, null, 2);

    const blob = new Blob([shotLogJsonString], { type: 'application/json' });
    const objectURL = window.URL.createObjectURL(blob);

    const element = document.createElement('a');
    element.setAttribute('href', objectURL);
    element.setAttribute('download', `${shotLogFileName}.json`);
    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    window.URL.revokeObjectURL(objectURL);
  }, [shotlogEntries]);

  //
  // Take over processing of human inputs while this modal dialog is open.
  //
  const inputMiddlewareProps = React.useMemo<InputMiddlewareProps>(() => {
    const results: InputMiddlewareProps = {
      exclusive: appState.isShotLogOpen,  // Prevent all user input hooks above this one from receiving input while this model is open.
    };

    return results;
  }, [appState.isShotLogOpen]);  
  useInputMiddleware(inputMiddlewareProps);

  return (<>
    <ThemeProvider theme={lightTheme}>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll='paper'
        onClose={handleClose}
        open={appState.isShotLogOpen}
      >
        <DialogTitleWithClose onClose={handleClose}>{/*<HelpIcon />*/}Your Shot Log</DialogTitleWithClose>
        <DialogContent dividers={true}>
          <b>Shot Log Review!</b>
          <ShotLogTable shotlogEntries={shotlogEntries} />
        </DialogContent>
        <DialogActions>
          <Button startIcon={<FileDownloadIcon color="primary" />} onClick={handleDownloadShotLog}>Download Shot Log</Button>
          <Button onClick={handleClose} autoFocus>Done</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  </>);
};

export default ShotLogReviewDialog;