import DeviceHost from './DeviceHost';
import BaseDevice from './BaseDevice';
import { ConnectionState } from '@fluidprompter/core';

export interface HidDeviceConstructor {
  new (deviceHost: DeviceHost): BaseHidDevice;
}

abstract class BaseHidDevice extends BaseDevice {
  _connectionState: ConnectionState;

  constructor(deviceHost: DeviceHost) {
    super(deviceHost);

    this._connectionState = ConnectionState.Disconnected;
  }

  abstract getRequestDeviceOptions(): HIDDeviceRequestOptions;

  get hidDevice(): HIDDevice | undefined {
    return this._hidDevice;
  }
  protected set hidDevice(hidDevice: HIDDevice | undefined) {
    if(!hidDevice) {
      throw new Error('HidDevice instance is required.');
    }

    this._hidDevice = hidDevice;
  }
  private _hidDevice: HIDDevice | undefined;

  async requestHidDevice() {
    const hidDevices = await navigator.hid.requestDevice(this.getRequestDeviceOptions());

    if(!hidDevices.length) {
      alert('No HID device found.');
      return;
    }
  }
}

export default BaseHidDevice;