import { useCallback } from 'react';
import { IViewportRefs } from './usePrompterViewportRefs';
import { ScrollToArgs } from './usePrompterScrollToPositionFunction';
import { PushLastScrollTargetArgs, ScrollPositionQueueEntry } from './useSegmentNavigationFunctions';

export interface ScrollByArgs {
    deltaY: number,
    abortController?: AbortController,
}

const usePrompterScrollByDeltaFunction = function(
  viewportRefs: IViewportRefs, 
  scrollToPosition: (args?: ScrollToArgs | undefined) => Promise<void> | undefined,
  pushLastScrollTarget: (args: PushLastScrollTargetArgs) => ScrollPositionQueueEntry,
  shiftLastScrollTarget: () => ScrollPositionQueueEntry | undefined,
) {
  //const scrollToPosition
  const scrollByDelta = useCallback(function (args?: ScrollByArgs): Promise<void> | undefined {
    const currentLedger = viewportRefs.previousLedgerRef.current;
    if(!args || !currentLedger || args.deltaY === undefined) {
      return;
    }

    let scrollPosition = currentLedger.scrollPosition + args.deltaY;
    if(scrollPosition < 0) { scrollPosition = 0; }
    if(currentLedger.scrollPositionMax && scrollPosition > currentLedger.scrollPositionMax) { scrollPosition = currentLedger.scrollPositionMax; }

    //
    // Push an entry onto our queue of scroll to segment requests.
    //
    pushLastScrollTarget({ scrollPosition });

    const scrollPromise = scrollToPosition({ scrollPosition, scrollBehavior: 'auto', abortController: args.abortController });
    if(!scrollPromise) {
      // Must be missing ref to scroller element.
      return;
    }

    //
    // When the promise is settled (whether reject or resolved) we need to shift out our queue entry.
    //
    scrollPromise
      .catch(() => {
        /*
        We don't care if we succeeded or failed to scroll to this position. 
        This promise may have been aborted by another request to scroll to another position.
        */
      })
      .finally(() => { shiftLastScrollTarget(); });

    return scrollPromise;
  }, [viewportRefs.previousLedgerRef, pushLastScrollTarget, scrollToPosition, shiftLastScrollTarget]);

  return scrollByDelta;
};

export default usePrompterScrollByDeltaFunction;