import { useCallback, useRef, useState } from 'react';
import { BaseSelection, Editor, Range, Path, Node, NodeEntry } from 'slate';
import { PrompterEditor, PrompterText } from '../../models/EditorTypes';

import areEqual from 'deep-equal';

interface useSelectionArtifacts {
  previousSelection: BaseSelection;
  selection: BaseSelection;
  setSelection: (newSelection: BaseSelection) => void;
}

function useSelection(editor: PrompterEditor): useSelectionArtifacts {
  const [selection, setSelection] = useState<BaseSelection>(editor.selection);
  const previousSelection = useRef<BaseSelection>(null);

  const setSelectionOptimized = useCallback((newSelection: BaseSelection) => {
    if (areEqual(selection, newSelection)) {
      return;
    }

    let normalizedSelection = newSelection;
    if(newSelection && Range.isExpanded(newSelection)) {

      const startLocation = Range.isForward(newSelection) ? newSelection.anchor : newSelection.focus;
      const endLocation = Range.isForward(newSelection) ? newSelection.focus : newSelection.anchor;

      const [ startNode, /*startPath*/ ] = Editor.node(editor, startLocation);
      const startNodeAsPrompterText = startNode as PrompterText;
      const startNodeLength = startNodeAsPrompterText.text ? startNodeAsPrompterText.text.length : 0;

      //
      // Normalize the start of our selection range.
      //
      let normalizedSelectionStart = startLocation;
      if(startLocation.offset === startNodeLength) {
        //
        // If we are at the very end of the node representing the start of this selection Range,
        // then drop this node from the start of the selection (no 'printable characters' from this
        // node are part of the selection range).
        //
        const nextLeafNodeIterator = Editor.nodes(editor, {
          at: [startLocation.path, endLocation.path],  // Location = BaseRange | BasePoint | Path | Span = [Path, Path]
          match: (n: Node, p: Path) => {
            // We want to find the next leaf node after our current startLocation.
            // This is a node with a 'text' value, whose path is after the current startLocation.path.
            const node = n as PrompterText;
            return (node.text !== undefined) && Path.isAfter(p, startLocation.path);
          },
          mode: 'all',
        });

        const nextLeafNode = nextLeafNodeIterator.next();
        let nextStartNodePath: Path = Path.next(startLocation.path);  // This appears to be "dumb" and just increment the last number in the path array regardless of whether a node exists there or not. Can I verify in slate source?
        if(!nextLeafNode.done) { // Using Type discrimination to make sure we have a value: https://github.com/microsoft/TypeScript/issues/33353
          const [, path] = nextLeafNode.value;
          nextStartNodePath = path;
        }

        normalizedSelectionStart = {
          path: nextStartNodePath,
          offset: 0
        };

        const pointName = Range.isForward(newSelection) ? 'anchor' : 'focus';
        console.log(`Selection start (${pointName}) is at the end of a node. Change ${startLocation.path} to ${nextStartNodePath}!`);
      }

      //
      // Normalize the end of our selection range.
      //
      let normalizedSelectionEnd = endLocation;
      if(endLocation.offset === 0) {
        //
        // If we are at the very start of the node representing the end of this selection Range,
        // then drop this node from the end of the selection (no 'printable characters' from this
        // node are part of the selection range).
        //
        // Path.previous() Can throw: Uncaught (in promise) Error: Cannot get the previous path of a first child path [2,1,0] because it would result in a negative index.
        let nextEndNodePath: Path = Path.hasPrevious(endLocation.path) ? Path.previous(endLocation.path) : Path.parent(endLocation.path);
        const nextLeafNodeIterator = Editor.nodes(editor, {
          at: [startLocation.path, nextEndNodePath],  // Location = BaseRange | BasePoint | Path | Span = [Path, Path]
          match: (n: Node, p: Path) => {
            // We want to find the previous leaf node before our current endLocation.
            // This is a node with a 'text' value, whose path is before the current endLocation.path.
            const node = n as PrompterText;
            return (node.text !== undefined) && Path.isBefore(p, endLocation.path);
          },
          mode: 'all',
        });

        //
        // Because Slate can't search nodes recursively in reverse... (See: https://github.com/ianstormtaylor/slate/issues/5080)
        // We will search forwards, and keep a reference to the last match found.
        //
        // Perhaps not supremely efficient if the selection is very large - but I'm not prepared to
        // write a reverse recursive search function for Slate at this time. If it becomes a
        // performance issue, we can revisit.
        //
        let nextLeafNode: IteratorResult<NodeEntry<Node>, void>;
        let nextEndNodeLength = 0;
        do {
          nextLeafNode = nextLeafNodeIterator.next();
          if (!nextLeafNode.done) { // Using Type discrimination to make sure we have a value: https://github.com/microsoft/TypeScript/issues/33353
            const [node, path] = nextLeafNode.value;
            nextEndNodePath = path;
            nextEndNodeLength = (node as PrompterText).text.length;
          }
        } while (!nextLeafNode.done);

        normalizedSelectionEnd = {
          path: nextEndNodePath,
          offset: nextEndNodeLength
        };

        const pointName = Range.isForward(newSelection) ? 'focus' : 'anchor';
        console.log(`Selection end (${pointName}) is at the start of a node. Change ${endLocation.path} to ${nextEndNodePath}!`);
      }

      normalizedSelection = {
        anchor: normalizedSelectionStart,
        focus: normalizedSelectionEnd
      };
    }

    previousSelection.current = selection;
    // console.log('New editor selection', selection);

    setSelection(normalizedSelection);
  }, [editor, selection, setSelection]);

  return {
    previousSelection: previousSelection.current,
    selection,
    setSelection: setSelectionOptimized,
  };
}

export default useSelection;