import React, { useCallback } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import lightTheme from '../../themes/LightTheme';

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { styled } from '@mui/material/styles';

import useApplicationContext from '../../hooks/useApplicationContext';
import useApplicationState from '../../state/ApplicationState';
import useFeatureFlagsStore from '../../state/FeatureFlagsStore';

import { useAppController, useMessageHandler } from '../../controllers/AppController';
import useServiceWorker from '../../hooks/useServiceWorker';
import { useTranslation } from 'react-i18next';

import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpIcon from '@mui/icons-material/Help';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';

import UserMenu from './UserMenu';
import ScriptMenu from './ScriptMenu';
import PrompterAppearance from './AppearanceMenu';
import ControlsMenu from './ControlsMenu';
import DevicesMenu from './DevicesMenu';
import ViewMenu from './ViewMenu';

const drawerWidth = 320;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  background: '#333',
  justifyContent: 'flex-end',
}));

const StyledButton = styled(Button)({
  color: '#fff',
});

declare global {
  const nolt: (arg1: string, arg2: unknown) => void;
}

function PrompterDrawer() {
  const appState = useApplicationState();

  const { t } = useTranslation('appmenu');

  const {
    updateWaiting,
  } = useServiceWorker();

  const appController = useAppController();

  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const openMenu = useCallback(function() {
    appState.setMenuOpen(true);
  }, [appState]);
  useMessageHandler('prompter.local.appmenu.open', openMenu);

  const closeMenu = useCallback(function() {
    setSelectedIndex(-1); // Collapse all menus, any that unmount will save rendering performance while prompting.
    appState.setMenuOpen(false);
  }, [appState]);
  useMessageHandler('prompter.local.appmenu.close', closeMenu);

  const toggleMenu = useCallback(function() {
    if(appState.menuOpen) {
      setSelectedIndex(-1); // Collapse all menus, any that unmount will save rendering performance while prompting.
      appState.setMenuOpen(false);
      return;
    }

    appState.setMenuOpen(true);
  }, [appState]);
  useMessageHandler('prompter.local.appmenu.toggle', toggleMenu);

  const requestMenuIndex = (
    index: number,
  ) => {
    if(selectedIndex === index) {
      // We are clicking an item that is already selected.
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };

  const {
    appContext,
    getApiToken,
    subscription,
  } = useApplicationContext(false);

  const currentplan = appContext.userProfile?.fluidprompter_plan || 'free';

  const redirectToFeedbackPortal = useCallback(async () => {
    //
    // If we are not authenticated, then don't bother with SSO.
    //
    if(!appContext.isAuthenticated) {
      window.open('https://feedback.fluidprompter.com/', '_blank');
      return;
    }

    //
    // If we are authenticated, then let's SSO into the feedback portal.
    //
    try {
      const token = await getApiToken();

      const response = await fetch(`${process.env.REACT_APP_API_URL}/feedback/getportalurl`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status !== 200) {
        // We received an error response.
        alert('Sorry, we encountered an unexpected error. Please contact fluidprompter support.');
        return;
      }

      console.log('Got app API account response from API serverless function', response);

      const feedbackPortalInfo = await response.json();

      //
      // If we got a JWT, let Nolt know.
      //
      const feedbackPortalJwt = feedbackPortalInfo.jwt as string;
      // if(feedbackPortalJwt) {
      //   nolt('identify', {
      //     jwt: feedbackPortalJwt
      //   });
      // }

      const feedbackPortalUrl = feedbackPortalInfo.url as string;
      if(feedbackPortalUrl) {
        // https://YOUR_BOARD.nolt.io/sso/JWT_FOR_THIS_USER?returnUrl=RETURN_URL
        // `https://fluidprompter.nolt.io/sso/${feedbackPortalJwt}?returnUrl=RETURN_URL`
        // window.location.href = feedbackPortalUrl;
        const feedbackPortalUrlAuth = `https://feedback.fluidprompter.com/sso/${feedbackPortalJwt}`;
        window.open(feedbackPortalUrlAuth, '_blank');
      }
    } catch (e) {
      console.error(e);
    }
  }, [appContext.isAuthenticated, getApiToken]);

  const handleUpgradeToPaid = () => {
    if(appContext.isAuthenticated) {
      // Upgrade intent
      subscription.startChangePlans();
    } else {
      // Sign-up intent
      appController.dispatchMessage('prompter.local.signup');
    }
  };

  const featureFlags = useFeatureFlagsStore.getState();
  const ShouldShowLogonMenu = featureFlags.prompterDrawerLogonMenu;
  const ShouldShowViewMenu = featureFlags.prompterDrawerViewMenu;

  return (
    <ThemeProvider theme={lightTheme}>
      <Drawer
        anchor="left"
        open={appState.menuOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          ['& .MuiDrawer-paper']: { width: drawerWidth, boxSizing: 'border-box' },
        }}
        onClose={closeMenu}
      >
        <DrawerHeader>
          <StyledButton
            endIcon={<ChevronLeftIcon />}
            onClick={() => { appState.toggleMenuOpen(); }}
          >{t('closemenu')}</StyledButton>
        </DrawerHeader>
        <Divider />
        <List disablePadding>
          {ShouldShowLogonMenu && <UserMenu expanded={selectedIndex === 0} onExpand={() => { requestMenuIndex(0); }} />}
          <ScriptMenu expanded={selectedIndex === 1} onExpand={() => { requestMenuIndex(1); }} />
          {ShouldShowViewMenu && <ViewMenu expanded={selectedIndex === 2} onExpand={() => { requestMenuIndex(2); }} />}
          <PrompterAppearance expanded={selectedIndex === 3} onExpand={() => { requestMenuIndex(3); }} />
          <ControlsMenu expanded={selectedIndex === 4} onExpand={() => { requestMenuIndex(4); }} />
          <DevicesMenu expanded={selectedIndex === 5} onExpand={() => { requestMenuIndex(5); }} />

          <ListItemButton
            onClick={() => {
              appController.dispatchMessage('pwa.checkforupdates');
            }}
          >
            <ListItemIcon>
              <Badge overlap="circular" color="warning" variant="dot" invisible={!updateWaiting}>
                <BrowserUpdatedIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={updateWaiting ? t('reloadtoupdate') :  t('checkupdates')}
            />
          </ListItemButton>


          <Divider />
          <ListItemButton
            onClick={redirectToFeedbackPortal}
          >
            <ListItemIcon><FeedbackIcon /></ListItemIcon>
            <ListItemText
              primary={t('submitfeedback')}
            />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              appController.dispatchMessage('prompter.local.showhelp');
            }}
          >
            <ListItemIcon><HelpIcon /></ListItemIcon>
            <ListItemText
              primary={t('helpsupport')}
            />
          </ListItemButton>
        </List>
        {(currentplan === 'free') &&
        <Box display={'flex'} flex={1} alignItems={'end'} margin={2}>
          <Button
            variant="outlined"
            fullWidth
            onClick={handleUpgradeToPaid}
          >{t('upgradetopro')}</Button>
        </Box>}
      </Drawer>
    </ThemeProvider>
  );
}

export default PrompterDrawer;