import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppController, useMessageHandler, MessageHandlerEvent } from '../controllers/AppController';
import usePrompterSession from '../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import DeviceHost from './DeviceHost';
import useInputMiddleware from '../hooks/useInputMiddleware';
import BaseDevice, {
  DeviceComponent,
  DeviceComponentProps,
} from './BaseDevice';
import { DeviceConnectingEvent } from './events/DeviceConnectingEvent';
import { DeviceConnectedEvent } from './events/DeviceConnectedEvent';
import { DeviceDisconnectedEvent } from './events/DeviceDisconnectedEvent';
import { DeviceRemovedEvent } from './events/DeviceRemovedEvent';
import { DeviceButtonEvent } from './events/DeviceButtonEvent';
import { DeviceReportEvent } from './events/DeviceReportEvent';

import EmptyComponent from './EmptyComponent';

import { Id, toast, ToastOptions, TypeOptions, UpdateOptions } from 'react-toastify';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import { ConnectionState, PlayMessage, PauseMessage, GenericMessage, NavigateMessage  } from '@fluidprompter/core';

interface ToastDeviceIconProps {
  deviceIcon?: string,
  notificationType?: TypeOptions,
}

const ToastDeviceIcon = (props: ToastDeviceIconProps) => {

  let badgeContent: React.ReactNode;
  switch(props.notificationType) {
    default:
    case 'default':
      break;
    case 'info':
      badgeContent = (<InfoIcon fontSize="small" color="info" />);
      break;
    case 'success':
      badgeContent = (<CheckCircleIcon fontSize="small" color="success" />);
      break;
    case 'warning':
      badgeContent = (<WarningIcon fontSize="small" color="warning" />);
      break;
    case 'error':
      badgeContent = (<ArrowCircleRightIcon fontSize="small" color="error" />);
      break;
  }
  return (
    <Stack direction="row" spacing={0}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={badgeContent}
      >
        <Avatar src={props.deviceIcon} variant="square" />
      </Badge>
    </Stack>
  );
};


const toastIds: Record<string, Id> = {};
const createOrUpdateToast = (deviceId: string, content: string, options?: UpdateOptions | undefined) => {
  const toastId = toastIds[deviceId];
  if(toastId && toast.isActive(toastId)) {
    // Update
    if(content && options) {
      options['render'] = content;
    }
    toast.update(toastId, options);
    return;
  }

  const toastOptions = options as ToastOptions;
  toastIds[deviceId] = toast(content, toastOptions);
};

interface IDeviceMenuEvent {
  anchorEl: HTMLDivElement;
  deviceId: string;
}

interface useDeviceHostReturns {
  deviceHost: DeviceHost,
  deviceMenuAnchor: HTMLDivElement | undefined,
  deviceComponent: DeviceComponent,
  deviceComponentProps: DeviceComponentProps | undefined,
}

const useDeviceHost = (): useDeviceHostReturns => {

  const { t } = useTranslation('devices');

  const appController = useAppController();
  const { deviceHost } = appController;

  const prompterSession = usePrompterSession(state => ({
    connectedDevicesAddOrUpdate: state.connectedDevicesAddOrUpdate,
    connectedDevicesRemove: state.connectedDevicesRemove,
  }), shallow);

  //
  // Construct our inputMiddleware args to remap some inputs based on connected devices.
  //
  const {
    dispatchKeyboardEvent,
    dispatchButtonEvent,
    // dispatchRemoteEvent,
    dispatchWheelEvent,
    dispatchAxisEvent,
  } = useInputMiddleware();

  const onDeviceConnecting = useCallback(async (e: DeviceConnectingEvent) => {
    const { device } = e;
    if(!device) {
      return;
    }

    const deviceReport = device.getDeviceState();

    // toast(`${deviceReport.name} Connecting!`, {
    //   type: 'success',
    //   isLoading: false,
    //   autoClose: 5000,
    //   hideProgressBar: false
    // });

    createOrUpdateToast(device.id, 'Connecting Bluetooth Device...', {
      type: 'default',
      isLoading: true,
      hideProgressBar: true,
    });

    prompterSession.connectedDevicesAddOrUpdate(deviceReport);

  }, [prompterSession]);

  const onDeviceConnected = useCallback(async (e: DeviceConnectedEvent) => {
    const { device } = e;
    if(!device) {
      return;
    }

    const deviceReport = device.getDeviceState();

    if(!device.suppressConnectionAlerts) {
      createOrUpdateToast(device.id, `${deviceReport.name} Connected!`, {
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        icon: ({type}) =>  (
          <ToastDeviceIcon
            deviceIcon={device.icon}
            notificationType={type}
          />
        ),
      });
    }

    prompterSession.connectedDevicesAddOrUpdate(deviceReport);

  }, [prompterSession]);

  const onDeviceDisconnected = useCallback(async (e: DeviceDisconnectedEvent) => {
    const { device } = e;
    if(!device) {
      return;
    }

    const deviceReport = device.getDeviceState();

    //
    // If this device was disconnected unexpectedly, we will present a reconnecting popup.
    //
    if(e.device && e.device.connectionState === ConnectionState.Reconnecting) {
      createOrUpdateToast(device.id, `Connecting ${deviceReport.name} Bluetooth Device...`, {
        type: 'default',
        isLoading: true,
        hideProgressBar: true,
        // icon: ({type}) =>  ( // TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'default';
        //   <ToastDeviceIcon
        //     deviceIcon={device.icon}
        //     notificationType={type}
        //   />
        // ),
      });
      return;
    }

    // toast(`${deviceReport.name} Disconnected!`, {
    //   type: 'error',
    //   isLoading: false,
    //   autoClose: 5000,
    //   hideProgressBar: false
    // });
    if(!device.suppressConnectionAlerts) {
      createOrUpdateToast(device.id, `${deviceReport.name} Disconnected!`, {
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        icon: ({type}) =>  ( // TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'default';
          <ToastDeviceIcon
            deviceIcon={device.icon}
            notificationType={type}
          />
        ),
      });
    }

    // If the device is not trying to reconnect, we can remove it from our list of connected devices.
    // if(e.device && e.device.connectionState !== ConnectionState.Reconnecting) {
    //   prompterSession.connectedDevicesRemove(deviceReport);
    // }
  }, [prompterSession]);

  const onDeviceRemoved = useCallback(async (e: DeviceRemovedEvent) => {
    const { device } = e;
    if(!device) {
      return;
    }

    const deviceReport = device.getDeviceState();

    prompterSession.connectedDevicesRemove(deviceReport);
  }, [prompterSession]);

  const onDeviceUpdate = useCallback(async (e: DeviceReportEvent) => {
    if(!e.device) {
      return;
    }

    const deviceReport = e.device.getDeviceState();
    prompterSession.connectedDevicesAddOrUpdate(deviceReport);
  }, [prompterSession]);

  //
  // DeviceHost event handlers
  //
  const onButtonEvent = useCallback(async (e: DeviceButtonEvent) => {
    // Handle events emitted by connected Devices!

    if(e.proposedAction) {
      dispatchButtonEvent(e);
      return;
    }

    if(e.eventType !== 'down') {
      return;
    }

    switch(e.buttonName) {
      case 'ok':
        dispatchKeyboardEvent(new KeyboardEvent('keydown', {
          cancelable: true,
          key: 'Enter',
          keyCode: 13,
          which: 13,
          code: 'Enter',
        }));
        break;
      case 'left':
        dispatchKeyboardEvent(new KeyboardEvent('keydown', {
          cancelable: true,
          key: 'ArrowLeft',
          keyCode: 37,
          which: 37,
          code: 'ArrowLeft',
        }));
        break;
      case 'right':
        dispatchKeyboardEvent(new KeyboardEvent('keydown', {
          cancelable: true,
          key: 'ArrowRight',
          keyCode: 39,
          which: 39,
          code: 'ArrowRight',
        }));
        break;
      case 'up':
        dispatchKeyboardEvent(new KeyboardEvent('keydown', {
          cancelable: true,
          key: 'ArrowUp',
          keyCode: 38,
          which: 38,
          code: 'ArrowUp',
        }));
        break;
      case 'down':
        dispatchKeyboardEvent(new KeyboardEvent('keydown', {
          cancelable: true,
          key: 'ArrowDown',
          keyCode: 40,
          which: 40,
          code: 'ArrowDown',
        }));
        break;
      case 'stop':
        appController.dispatchMessage(new PauseMessage());
        break;
      case 'play':
        appController.dispatchMessage(new PlayMessage());
        break;
      case 'pageup':
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PrevSegment));
        // appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Start));
        break;
      case 'pagedown':
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.NextSegment));
        // appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.End));
        break;
      case 'fullscreen':
        appController.dispatchMessage('fullscreen.toggle');
        break;
    }

    // dispatchRemoteEvent()
    // dispatchWheelEvent,
    // dispatchAxisEvent,

  }, [dispatchButtonEvent, dispatchKeyboardEvent, appController]);

  //
  // When our translation function changes, the current language has changed.
  // We want to inform our connected devices of the current language.
  //
  useEffect(() => {
    deviceHost.allDevices().forEach(device => device.applyTranslation(t));
  }, [deviceHost, t]);

  //
  // Attached DeviceHost event listeners
  //
  useEffect(() => {
    // Register event listeners
    deviceHost.addListener('deviceConnecting', onDeviceConnecting);
    deviceHost.addListener('deviceConnected', onDeviceConnected);
    // deviceHost.addListener('deviceReconnecting', onDeviceConnected);
    deviceHost.addListener('deviceDisconnected', onDeviceDisconnected);
    deviceHost.addListener('deviceRemoved', onDeviceRemoved);
    deviceHost.addListener('deviceReport', onDeviceUpdate);
    deviceHost.addListener('buttonEvent', onButtonEvent);
    deviceHost.addListener('wheelEvent', dispatchWheelEvent);
    deviceHost.addListener('axisEvent', dispatchAxisEvent);

    return () => {
      // Cleanup function
      deviceHost.removeListener('deviceConnecting', onDeviceConnecting);
      deviceHost.removeListener('deviceConnected', onDeviceConnected);
      deviceHost.removeListener('deviceDisconnected', onDeviceDisconnected);
      deviceHost.removeListener('deviceRemoved', onDeviceRemoved);
      deviceHost.removeListener('deviceReport', onDeviceUpdate);
      deviceHost.removeListener('buttonEvent', onButtonEvent);
      deviceHost.removeListener('wheelEvent', dispatchWheelEvent);
      deviceHost.removeListener('axisEvent', dispatchAxisEvent);
    };
  }, [
    deviceHost,
    onDeviceConnecting,
    onDeviceConnected,
    onDeviceDisconnected,
    onDeviceUpdate,
    onButtonEvent,
    dispatchWheelEvent,
    dispatchAxisEvent,
  ]);

  //
  // Handle device menus
  //
  const [deviceMenuAnchor, setDeviceMenuAnchor] = useState<HTMLDivElement | undefined>();
  const [deviceComponent, setDeviceComponent] = useState<DeviceComponent>(() => EmptyComponent);
  const lastDeviceRef = useRef<BaseDevice>();
  const deviceMenuOpen = React.useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    const args = e.message.payload as IDeviceMenuEvent;

    const device = deviceHost.getDevice(args.deviceId);
    if(device) {
      lastDeviceRef.current = device;
      setDeviceMenuAnchor(args.anchorEl);
      setDeviceComponent(() => device.getDeviceUIComponent());
      return;
    }

    setDeviceMenuAnchor(undefined);
    setDeviceComponent(() => EmptyComponent);
  }, [deviceHost]);
  useMessageHandler('devices.devicemenu.open', deviceMenuOpen);

  const deviceComponentProps: DeviceComponentProps = {
    device: lastDeviceRef.current,
    deviceMenuAnchor,
    onClose: (closeAll?: boolean) => {
      setDeviceMenuAnchor(undefined);
      setDeviceComponent(() => EmptyComponent);
      if(closeAll) {
        appController.dispatchMessage('prompter.local.appmenu.close');
      }
    }
  };

  return {
    deviceHost,
    deviceMenuAnchor,
    deviceComponent,
    deviceComponentProps,
  };
};

export default useDeviceHost;