export default class AppConfig {

  /**
   * Static singleton instance of AppConfig
   */
  public static current: AppConfig = new AppConfig();

  private constructor() {
    try {
      this.WORDLIMIT_SIGNEDOUT_USER = Number.parseInt(process.env.REACT_APP_WORDLIMIT_SIGNEDOUT_USER || '90', 10);
    } catch(err) {
      // If the config value is not a string or an unparseable string...
    }
    try {
      this.WORDLIMIT_FREE_USER = Number.parseInt(process.env.REACT_APP_WORDLIMIT_FREE_USER || '500', 10);
    } catch(err) {
      // If the config value is not a string or an unparseable string...
    }

    this.PRICE_ID_FREE_PLAN = process.env.REACT_APP_ENV === 'production'
      ? 'price_1MdGj2E4AiWOHBTNtAdcFWAu'  // Live Mode Price Id
      : 'price_1LlyvsE4AiWOHBTNLBTGU2kv'; // Test Mode Price Id

    this.PRICE_ID_SOLO_PLAN = process.env.REACT_APP_ENV === 'production'
      ? 'price_1MdGgTE4AiWOHBTNMiPua4MB'  // Live Mode Price Id
      : 'price_1Lr2hqE4AiWOHBTNFpGpaDZb'; // Test Mode Price Id
  }

  WORDLIMIT_SIGNEDOUT_USER: number | undefined;
  WORDLIMIT_FREE_USER: number | undefined;

  PRICE_ID_FREE_PLAN: string;
  PRICE_ID_SOLO_PLAN: string;
}