import React from 'react';
import Stack from '@mui/material/Stack';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import StarIcon from '@mui/icons-material/Star';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ShieldIcon from '@mui/icons-material/Shield';
import VerifiedIcon from '@mui/icons-material/Verified';
import StarsIcon from '@mui/icons-material/Stars';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import { styled } from '@mui/material/styles';

const DeviceAvatar = styled(Avatar)<AvatarProps>(({ theme }) => ({
  background: 'none',
  fontSize: '1.05em',
  '& .DeviceAvatarImage': {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    objectFit: 'cover',
    color: 'transparent',
    textIndent: '10000px',
  },
  '& .DeviceAvatarOverlay': {
    position: 'absolute',
    top: '9px',
    left: 0,
    color: '#ffff00',
    right: 0,
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

export interface DecoratedDeviceAvatarProps extends AvatarProps {
  badgeContent?: React.ReactNode;
  badgeVisible?: boolean;
}

const DecoratedDeviceAvatar = ({
  badgeContent,
  badgeVisible,
  ...rest
}: DecoratedDeviceAvatarProps) => {
  let finalContent: React.ReactNode = (<DeviceAvatar {...rest} />);

  //
  // Right hand badge
  //
  if(badgeContent) {
    finalContent = (<Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={badgeContent}
      invisible={badgeVisible === false}
      // badgeContent={<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+klEQVR4nOWRTWoCQRCFv2UCRvAOLryAYBbuEvHnHopCPEC8SZbmOoNLUdz5s3HhInGjAzMyoQaKsacZmWlMyIMHTXX1+6gu+EXqAVtgA3RcADZAKF67AOwUIII5+aIvcdcF4EnCj0DZxfKG6ouGt+RlXd5M9UXnNF3lZQHUVU/selZARxU9oGJ49GEARLWkKpIRh7f15UQuVkBN1Uuy3CTgO7HsKjCXu3fTaC31+AC8SH1gCI/dl54msFf1V1LGC1STD4zUyCZ70uOrWpDyzT9aWsLCjF5g0WcBgKkNMC4A8GYDPBcAaNgAD8ApR/gZeOTeCnP6HwD+li4lx+T/m9YGcgAAAABJRU5ErkJggg=="/>}
    >
      {finalContent}
    </Badge>);
  }

  return finalContent;
};

export default DecoratedDeviceAvatar;