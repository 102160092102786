import ZustandStoreSlice from '../utils/ZustandStoreSlice';
import { EasingFunctionKeys } from '../../utils/EasingFunctions';

export interface IPrompterMomentarySlice {
  momentaryAttackGain: number;
  momentaryAttackTime: number;
  momentaryAttackFunction: EasingFunctionKeys;
  setMomentaryAttackGain: (momentaryAttackGain: number) => void;
  
  momentaryHoldTime: number;
  
  momentaryDecayTime: number;
  momentaryDecayFunction: EasingFunctionKeys;
  
  momentaryReleaseTime: number;
  momentaryReleaseFunction: EasingFunctionKeys;
}

const createPrompterMomentarySlice: ZustandStoreSlice<IPrompterMomentarySlice> = (set) => ({
  momentaryAttackGain: 2.0,
  momentaryAttackTime: 1000,
  momentaryAttackFunction: 'Sinusoidal',
  setMomentaryAttackGain: (momentaryAttackGain: number) => set(() => ({ momentaryAttackGain })),
  
  momentaryHoldTime: 500,
  
  momentaryDecayTime: 750,
  momentaryDecayFunction: 'Sinusoidal',
  
  momentaryReleaseTime: 1000,
  momentaryReleaseFunction: 'Sinusoidal',
});

export default createPrompterMomentarySlice;