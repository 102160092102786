import { BaseControlMessage } from './BaseControlMessage';
export const NAVIGATEMESSAGE_KEY = 'navigate';
/**
 * Represents the NavigateMessage type.
 */
export class NavigateMessage extends BaseControlMessage {
    constructor(target, params) {
        super();
        this.type = NAVIGATEMESSAGE_KEY;
        this.target = target;
        if (params) {
            this.targetPosition = params.targetPosition;
            this.segmentInstance = params.segmentInstance;
            this.segmentIndex = params.segmentIndex;
            this.contentNumber = params.contentNumber;
            this.scrollPosition = params.scrollPosition;
            this.scrollOffset = params.scrollOffset;
            this.scrollBehavior = params.scrollBehavior;
            this.queueSequentialTask = params.queueSequentialTask;
            this.scriptPosition = params.scriptPosition;
        }
    }
    static fromJson(data) {
        if (data.type !== NAVIGATEMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for NavigateMessage.');
        }
        const input = data;
        const instance = new NavigateMessage(input.t);
        instance.deserializeSenderInfo(data.sender);
        instance.target = input.t;
        instance.targetPosition = input.tp;
        instance.segmentIndex = input.i;
        instance.contentNumber = input.no;
        instance.scrollPosition = input.p;
        instance.scrollOffset = input.o;
        instance.scrollBehavior = input.b;
        instance.queueSequentialTask = input.q;
        // this.scriptPosition = params.scriptPosition;
        if (input.n !== undefined
            && input.nh !== undefined
            && input.nct !== undefined
            && input.nch !== undefined
            && input.np !== undefined) {
            instance.scriptPosition = {
                nodePath: input.n,
                nodeHeight: input.nh,
                nodeChildrenTop: input.nct,
                nodeChildrenHeight: input.nch,
                position: input.np,
            };
        }
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            t: this.target,
            tp: this.targetPosition,
            i: this.segmentIndex,
            no: this.contentNumber,
            p: this.scrollPosition,
            o: this.scrollOffset,
            b: this.scrollBehavior,
            q: this.queueSequentialTask,
            //
            // Flattened IScriptPosition
            n: this.scriptPosition?.nodePath,
            nh: this.scriptPosition?.nodeHeight,
            nct: this.scriptPosition?.nodeChildrenTop,
            nch: this.scriptPosition?.nodeChildrenHeight,
            np: this.scriptPosition?.position,
        };
    }
}
NavigateMessage.MESSAGE_NAME = NAVIGATEMESSAGE_KEY;
(function (NavigateMessage) {
    let Target;
    (function (Target) {
        Target["Start"] = "start";
        Target["End"] = "end";
        Target["PageUp"] = "pageup";
        Target["PageDown"] = "pagedown";
        Target["PrevSegment"] = "prevsegment";
        Target["NextSegment"] = "nextsegment";
        Target["Segment"] = "segment";
        Target["Position"] = "position";
    })(Target = NavigateMessage.Target || (NavigateMessage.Target = {}));
})(NavigateMessage || (NavigateMessage = {}));
