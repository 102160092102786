import DeviceHost from '../DeviceHost';
import {
  IDeviceDescriptor,
  DeviceComponent,
  DeviceConnectionType
} from '../BaseDevice';
import BaseRemote from '../BaseRemote';
import { DeviceButtonEvent } from '../events/DeviceButtonEvent';

import DigitalButtonStateMachine from '../common/DigitalButtonStateMachine';

import { TFunction } from 'i18next';
import RemoteYCOnionIcon from './images/remote-yconion-icon.png';
import YCOnionRemoteUI from './UI';
import {
  AppBluetoothDevice,
  AppBluetoothCharacteristic,
  IBluetoothProvider,
  AppBluetoothStatus
} from '../BluetoothProviders';

// import { IAppMessage, Status } from '../../native/INativeModulesIpc';

const YCONION_TYPE = 'yconion';

class YCOnionRemote extends BaseRemote {
  readonly type = YCONION_TYPE;

  public static readonly DEVICE_TYPE: string = YCONION_TYPE;

  static DISCOVERY_SERVICE_UUID = '0000ffe0-0000-1000-8000-00805f9b34fb'; // 0000fff0-0000-1000-8000-00805f9b34fb | 0xFFF0

  static BASE_UUID = '00000000-0000-1000-8000-00805F9B34FB';
  static PRIMARY_SERVICE_UUID = '0000fff0-0000-1000-8000-00805f9b34fb'; // 0000fff0-0000-1000-8000-00805f9b34fb | 0xFFF0

  // 0000fff2-0000-1000-8000-00805f9b34fb
  static PRIMARY_CHARACTERISTIC_UUID = '0000fff2-0000-1000-8000-00805f9b34fb'; //'0xFFF2';  // <- This characteristic can be subscribed to for button presses.

  _buttons: DigitalButtonStateMachine[];

  // This is an undocumented service/characteristic on the YC Onion Remote:
  // static PRIMARY_SERVICE_UUID: string = '5833ff01-9b8b-5191-6142-22a4536ef123';
  // static PRIMARY_CHARACTERISTIC_UUID: string = '5833ff03-9b8b-5191-6142-22a4536ef123';

  // Name: TL2.0
  // MAC: C0:00:00:00:00:24
  // Manufacturer data: 0x0504
  //

  constructor(deviceHost: DeviceHost) {
    super(deviceHost);
    this.icon = RemoteYCOnionIcon;
    this.name = 'YC Onion Remote';
    this.connectionType = DeviceConnectionType.Bluetooth;

    const buttons = [{
      bitmask: 0x01,
      name: 'left',
    }, {
      bitmask: 0x02,
      name: 'right',
    }, {
      bitmask: 0x04,
      name: 'up',
    }, {
      bitmask: 0x08,
      name: 'down',
    }, {
      bitmask: 0x10,
      name: 'ok',
    }, {
      bitmask: 0x20,
      name: 'minus',
    }, {
      bitmask: 0x40,
      name: 'plus',
    }];

    this._buttons = [];
    for (let i = 0; i < buttons.length; i++) {
      const btnInstance = new DigitalButtonStateMachine(buttons[i], this.onButtonEvent.bind(this));
      this._buttons.push(btnInstance);
    }
  }

  getRequestDeviceOptions(): RequestDeviceOptions {
    const requestDeviceOptions: RequestDeviceOptions = {
      filters: [{services: [YCOnionRemote.DISCOVERY_SERVICE_UUID]}],
      optionalServices: [YCOnionRemote.PRIMARY_SERVICE_UUID, 'battery_service'] // Battery Level
    };
    return requestDeviceOptions;
  }

  async attachServicesAndCharacteristics(
    device: AppBluetoothDevice,
    provider: IBluetoothProvider,
    subscriptionTopic: string) {


    super.attachServicesAndCharacteristics(device, provider, subscriptionTopic);

    await provider.notify(device.id,
      YCOnionRemote.PRIMARY_SERVICE_UUID,
      YCOnionRemote.PRIMARY_CHARACTERISTIC_UUID,
      (char: AppBluetoothCharacteristic, status: AppBluetoothStatus, error: number | null) => {
        if (status === AppBluetoothStatus.Success && char.value) {
          this.onNotifyDigital(char.value);

        } else {
          console.log(`Received error on button notification. Error code is ${error}`);
        }
      },
      subscriptionTopic
    );

    //
    // Let's subscribe to battery level updates.
    //
    // YC ONION has a "fake" battery service that always reports 90% battery charge. Not helpful
    // to end user, let's ignore it.
    //
    // await this.subscribeToBatteryStatus(device, server);
  }

  /**
   * Fired when our state machines have detected a user input event.
   * @param {*} buttonName
   * @param {*} eventType
   */
  onButtonEvent(buttonName: string, eventType: string) {
    const eventName = `${buttonName}.${eventType}`;
    console.log(`YCOnionRemote.onButtonEvent ${eventName}`);

    // Resolve what action this remote button is mapped to based on remote configuration.
    /*
    let proposedAction: string | undefined = undefined;
    switch(eventName) {
      case 'ok.down':
        proposedAction = 'prompter.state.momentaryplay.start';
        break;
      case 'ok.up':
        proposedAction = 'prompter.state.momentaryplay.stop';
        break;
      default:
        break;
    }

    this.emit('buttonreport', new DeviceButtonEvent(this, buttonName, eventType, proposedAction));
    */
    const proposedAction = ((buttonName === 'plus') || (buttonName === 'minus')) ? 'yconion' : undefined;
    this.emit('buttonreport', new DeviceButtonEvent(this, buttonName, eventType, proposedAction));
  }

  /**
   * Fired when we receive a BLE characteristic notification for a digital button state change (button being pressed or released).
   * @param {*} notifyData
   */
  onNotifyDigital(notifyData: DataView) {
    // YCOnion remote will notify with 5 bytes.
    // Bytes 1 and 2 appear to be a fixed prefix.
    //
    // Byte 1 = 0x05 (5)
    // Byte 2 = 0xA5 (165)
    // Byte 3 = Button State
    // Byte 4 = Checksum??
    // Byte 5 = Sequence Number
    //
    // Button State Bitmasks:
    // 0x01 = Left
    // 0x02 = Right
    // 0x04 = Up
    // 0x08 = Down
    // 0x10 = Center (Play/Pause)
    // 0x20 = Minus
    // 0x40 = Plus

    if(notifyData.byteLength < 5) {
      throw new Error('Unexpected data length from YCOnion Remote.');
    }
    // const checksumByte = notifyData.getUint8(3);  // Need to get the byte as _unsigned_ value
    // const sequenceByte = notifyData.getUint8(4);  // Need to get the byte as _unsigned_ value

    const buttonState = notifyData.getUint8(2);  // Need to get the byte as _unsigned_ value
    for (let i = 0; i < this._buttons.length; i++) {
      this._buttons[i].processState(buttonState);
    }
  }

  static readonly DeviceKey: string = 'yconion';
  static getDeviceDescriptors(t: TFunction): IDeviceDescriptor[] {
    return [{
      connectionType: DeviceConnectionType.Bluetooth,
      deviceKey: YCOnionRemote.DeviceKey,
      deviceName: `YC Onion ${t('connectdevicedialog.remote')}`,
      deviceIcon: RemoteYCOnionIcon,
      requiresPlanLevel: 1,
      requiresBluetooth: true,
    }];
  }

  getDeviceUIComponent(): DeviceComponent {
    return YCOnionRemoteUI;
  }
}

export default YCOnionRemote;