import React, { useLayoutEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ITrayItem } from './TrayContainer';
import TrayItemIconMenuItem from './TrayItemIconMenuItem';
import TrayItemIconButton from './TrayItemIconButton';

import useServiceWorker from '../../hooks/useServiceWorker';
import { useTranslation } from 'react-i18next';

import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';

interface AppUpdateTrayItemProps {
  renderAsMenuItem?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  requestCloseTray: () => void;
}

const AppUpdateTrayItem = (props: AppUpdateTrayItemProps) => {

  const { renderAsMenuItem } = props;

  const { t } = useTranslation('prompter');

  const {
    updateWaiting,
    applyUpdate,
  } = useServiceWorker();

  // useState will hold reference to Websocket Menu anchor element.
  const [trayMenuAnchorEl, setTrayMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const onTrayMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    setTrayMenuAnchorEl(e.currentTarget);
  };
  const onTrayMenuClose = () => {
    setTrayMenuAnchorEl(null);
  };

  useLayoutEffect(() => {
    // When renderAsMenuItem changes, we need to close any open menus.
    onTrayMenuClose();
  }, [renderAsMenuItem]);

  const trayItemProps: ITrayItem = {
    title: t('trayicons.updatewaiting'),
    icon: (<BrowserUpdatedIcon />),
    onClick: onTrayMenuClick,
  };

  if(!updateWaiting) {
    return (<></>);
  }

  return (<>
    {renderAsMenuItem ? (<TrayItemIconMenuItem {...trayItemProps} />) : (<TrayItemIconButton {...trayItemProps} />)}
    <Menu
      id="appupdate-menu"
      anchorEl={trayMenuAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(trayMenuAnchorEl)}
      onClose={onTrayMenuClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={
        () => {
          applyUpdate();
          onTrayMenuClose();
          props.requestCloseTray();
        }
      }>{t('trayicons.updateapply')}</MenuItem>
    </Menu>
  </>);
};

export default AppUpdateTrayItem;