import BaseControlMessage from './BaseControlMessage';
export const GETSESSIONRESPONSEMESSAGE_KEY = 'getsession.response';
/**
 * Represents the GetSessionResponse type.
 */
export class GetSessionResponse extends BaseControlMessage {
    constructor(endpoints) {
        super();
        this.type = GETSESSIONRESPONSEMESSAGE_KEY;
        /**
         * Contains the list of existing prompter peers connected to the current prompterId.
         */
        this.endpoints = [];
        this.endpoints = endpoints;
    }
    static fromJson(data) {
        if (data.type !== GETSESSIONRESPONSEMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for GetSessionResponse.');
        }
        const input = data;
        const instance = new GetSessionResponse(input.endpoints);
        instance.deserializeSenderInfo(input.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            endpoints: this.endpoints,
        };
    }
}
GetSessionResponse.MESSAGE_NAME = GETSESSIONRESPONSEMESSAGE_KEY;
