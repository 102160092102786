import { IUserProfile } from '@fluidprompter/core';

const fetchUserProfileGlobal = async (apiToken: string): Promise<IUserProfile> => {
  if(!apiToken) {
    throw new Error('apiToken is required to fetchUserProfile');
  }

  let response: Response | undefined = undefined;
  try{
    response = await fetch(`${process.env.REACT_APP_API_URL}/account/profile`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }
  catch(err) {
    // ERR_INTERNET_DISCONNECTED
    // console.log(`${typeof err}`, err);
    // if(err.foo === 'ERR_INTERNET_DISCONNECTED') {
    //   // We are currently offline.
    // }
  }
  if(!response) {
    throw new Error('Error: no response available on fetchUserProfile.');
  }
  // console.log(`${typeof response}`, response);

  if(response && response.status !== 200) {
    // We received an error response.
    throw new Error('Error: could not fetch user profile.');
  }

  return await response.json() as IUserProfile;
};

export default fetchUserProfileGlobal;