import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import AccountHeader from '../AccountHeader/AccountHeader';
import SectionSaveButton, { SaveHandler, SaveCallback } from '../SectionSaveButton';

import Alert, { alertClasses } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import Input from '@mui/material/Input';

import { useTranslation } from 'react-i18next';

import { IUserProfile } from '@fluidprompter/core';
import useApplicationContext from '../../../hooks/useApplicationContext';
import useApplicationState from '../../../state/ApplicationState';
import { styled } from '@mui/material';

const formatDateString = (date: Date) => {
  return date.toLocaleString();
};

const StyledAlert = styled(Alert)({
  marginTop: 2,
  [`& .${alertClasses.action}`]: {
    paddingBottom: '4px',
    alignItems: 'center',
  }
});

const ProfileView = function () {

  const { t } = useTranslation('account');

  const {
    appContext,
    patchUserProfile,
  } = useApplicationContext();

  const [userProfile, setUserProfile] = useState<IUserProfile>(appContext.userProfile || { email: 'unknown' });

  //
  // Actions to save user profile when user clicks save.
  //
  const onChange = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    /*console.log(value);*/
    setUserProfile({
      ...userProfile,
      [name]: value,
    });
  };
  const handleSave: SaveHandler = async (callback: SaveCallback) => {
    const callbackInstance = callback;

    if(!appContext.isAuthenticated) {
      return;
    }

    try {
      const updatedUserProfile = await patchUserProfile(userProfile);

      // Update our shadow copy of the user profile in this component.
      setUserProfile(updatedUserProfile);

      // Informs our animated in-progress save button that we are done now.
      callbackInstance(undefined, updatedUserProfile);
    } catch (e) {
      console.error(e);
      callbackInstance(new Error('Failed to save!'), null);
    }
  };

  const createdOnString = formatDateString(new Date(userProfile.created_at as string));
  const updatedAtString = formatDateString(new Date(userProfile.updated_at as string));
  return (
    <Stack>
      <Box>
        <AccountHeader title={t('profilesection.title')}></AccountHeader>
        <Stack spacing={3}>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-helper">{t('profilesection.profile.nickname')}</InputLabel>
            <Input
              id="component-helper"
              name="user_nickname"
              value={userProfile.user_nickname}
              disabled={appContext.isLoading}
              onChange={onChange}
              aria-describedby="component-helper-text"
            />
            {/*<FormHelperText id="component-helper-text">
              The name you'd prefer appear online
            </FormHelperText>*/}
          </FormControl>

          <TextField 
            margin="normal"
            disabled
            id="created_on" 
            label={t('profilesection.profile.createdon')}
            variant="standard"
            value={createdOnString}
          />
          <TextField
            margin="normal"
            disabled
            id="updated_on"
            label={t('profilesection.profile.updatedon')}
            variant="standard"
            value={updatedAtString}
          />
        </Stack>

        <Box marginTop={2}>
          <SectionSaveButton
            buttonText={t('profilesection.profile.button')}
            disabled={appContext.isLoading}
            onSave={handleSave}
          />
        </Box>
      </Box>
      <Box paddingTop={4}>
        <AccountHeader title={t('profilesection.dangerzone.title')}></AccountHeader>
        <StyledAlert
          icon={false}
          severity="error"
          action={
            <Button
              color="error" variant="contained"
              // startIcon={ <DeleteForeverIcon /> }
              sx={{
                whiteSpace: 'nowrap'
              }}
              onClick={() => {
                useApplicationState.getState().setAccountDeleteOpen(true);
              }}
            >{t('profilesection.dangerzone.deleteaccount.button')}</Button>
          }
        >
          <AlertTitle>{t('profilesection.dangerzone.deleteaccount.title')}</AlertTitle>
          {t('profilesection.dangerzone.deleteaccount.helpertext')}
        </StyledAlert>
      </Box>
    </Stack>
  );
};

export default ProfileView;