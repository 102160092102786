import BaseControlMessage from './BaseControlMessage';
import { PrompterSessionEndpoint } from '../models/PrompterSessionEndpoint';
export const CONNECTREQUESTMESSAGE_KEY = 'connect.request';
/**
 * Represents the ConnectRequestMessage type.
 */
export class ConnectRequestMessage extends BaseControlMessage {
    constructor(args) {
        super();
        this.type = CONNECTREQUESTMESSAGE_KEY;
        const { endpoint, rebuildConnection, } = args;
        this.endpoint = endpoint;
        this.rebuildConnection = rebuildConnection;
    }
    static fromJson(data) {
        if (data.type !== CONNECTREQUESTMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for ConnectRequestMessage.');
        }
        const input = data;
        const endpoint = PrompterSessionEndpoint.fromJson(input.endpoint);
        const instance = new ConnectRequestMessage({
            endpoint,
            rebuildConnection: input.rebuildConnection === true,
        });
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            endpoint: this.endpoint,
            rebuildConnection: this.rebuildConnection,
        };
    }
}
ConnectRequestMessage.MESSAGE_NAME = CONNECTREQUESTMESSAGE_KEY;
