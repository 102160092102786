import React from 'react';

import { ITrayItem } from './TrayContainer';
import TrayItemIconMenuItem from './TrayItemIconMenuItem';
import TrayItemIconButton from './TrayItemIconButton';

import QrCode2 from '@mui/icons-material/QrCode2';
import ShareIcon from '@mui/icons-material/Share';

import { useTranslation } from 'react-i18next';
import useApplicationState from '../../state/ApplicationState';

interface SharePrompterTrayItemProps {
  renderAsMenuItem?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  requestCloseTray: () => void;
}

const SharePrompterTrayItem = (props: SharePrompterTrayItemProps) => {

  const { renderAsMenuItem } = props;

  const { t } = useTranslation('prompter');

  const appState = useApplicationState();

  const onTrayMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    if(props.onClick) { props.onClick(e); }
    props.requestCloseTray();
    appState.setRemoteDialogOpen(true);
  };

  const trayItemProps: ITrayItem = {
    title: t('trayicons.shareprompter'),
    icon: (<ShareIcon />),
    onClick: onTrayMenuClick,
  };

  return (<>
    {renderAsMenuItem ? (<TrayItemIconMenuItem {...trayItemProps} />) : (<TrayItemIconButton {...trayItemProps} />)}
  </>);
};

export default SharePrompterTrayItem;