import EventEmitter from 'eventemitter3';

const serviceWorkerSupported = 'serviceWorker' in navigator;
const CLOUDFLARE_DEV_URL_SUFFIX = '.pages.dev';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

interface ServiceWorkerControllerEvents {
  updatefound: (e: Event) => void;
  updatewaiting: (e: Event) => void;
}

export class ServiceWorkerController
  extends EventEmitter<ServiceWorkerControllerEvents>
{

  /**
   * Static singleton instance of AppController
   */
  public static current: ServiceWorkerController = new ServiceWorkerController();

  private constructor() {
    super();

    this.handleSWUpdateWaiting = this._handleSWUpdateWaiting.bind(this);
    this.handleSWInstalling = this._handleSWInstalling.bind(this);

    this.checkUpdate = this._checkUpdate.bind(this);
    this.applyUpdate = this._applyUpdate.bind(this);
  }

  private lastController?: ServiceWorker;

  async register(): Promise<ServiceWorkerRegistration | undefined> {
    if(this._serviceWorkerRegistration) {
      console.log('ServiceWorker already registered.');
      return;
    }

    if (!serviceWorkerSupported) {
      console.log('This browser does not support serviceWorkers.');
      return;
    }

    if(isLocalhost) {
      console.log('Service Worker not registered for localhost.');
      return;
    }

    if(!process.env.REACT_APP_URL) {
      console.log('Missing REACT_APP_URL environment variable. Will not register PWA service worker.');
      return;
    }

    const publicUrl = new URL(process.env.REACT_APP_URL, window.location.href);
    const currentOrigin = window.location.origin;
    if (publicUrl.origin !== currentOrigin && !currentOrigin.endsWith(CLOUDFLARE_DEV_URL_SUFFIX)) {
      // Our service worker won't work if REACT_APP_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      console.log(`Origin '${currentOrigin}' does not match '${publicUrl.origin}'`);
      return;
    }

    const newController = navigator.serviceWorker.controller;
    if(newController) {
      this.lastController = newController;
    }

    const swUrl = `${currentOrigin}/service-worker.js`;

    this._serviceWorkerRegistration = await navigator.serviceWorker.register(swUrl);

    this._serviceWorkerRegistration.addEventListener('updatefound', async () => {

      const currentRegistration = await navigator.serviceWorker.getRegistration();
      if(!currentRegistration) {
        return;
      }

      // Before we handle the new service worker about to be installed, let's save a record of
      // whether we had an active service worker running before this next one is installed.
      //
      // If we did not, its a new user with empty cache,
      const isUpgradeNotInitial = !!currentRegistration.installing
        && !!currentRegistration.active;

      if(currentRegistration.installing) {
        this.handleSWInstalling(currentRegistration.installing, isUpgradeNotInitial);
      }
    });

    let reloadInProcess = false;
    navigator.serviceWorker.addEventListener('controllerchange', async () => {
      if(reloadInProcess) {
        return;
      }

      //
      // We don't want to reload the page for first time visitors who had nothing cached on initial
      // page load, but then the service-worker gets registered once page load is complete.
      //
      // We do want to reload the page if their was already an active server-worker prior to the
      // `controllerchange` event - indicating we are updating the user's cached app version.
      //
      const lastController = this.lastController;
      const newController = navigator.serviceWorker.controller;
      if(!newController) {
        return;
      }
      if(lastController && newController) {
        reloadInProcess = true;
        window.location.reload();
      }
      this.lastController = newController;
    });

    //
    // If we have an installing service worker AND have an active service worker, it means we are
    // performing and upgrade and is not the user's first visit to the app with initial install.
    //
    const isUpgradeNotInitial = this._serviceWorkerRegistration.installing !== null
      && this._serviceWorkerRegistration.active !== null;

    if (this._serviceWorkerRegistration.waiting) {
      this.handleSWUpdateWaiting(this._serviceWorkerRegistration.waiting, isUpgradeNotInitial);
      return this._serviceWorkerRegistration;
    }

    //
    // This is the case for a new user, empty cache, for initial installation of service worker.
    //
    if (this._serviceWorkerRegistration.installing) {
      this.handleSWInstalling(this._serviceWorkerRegistration.installing, isUpgradeNotInitial);
      return this._serviceWorkerRegistration;
    }

    return this._serviceWorkerRegistration;
  }
  private _serviceWorkerRegistration: ServiceWorkerRegistration | undefined;

  /**
   * Unregister any active service worker - should not be required routinely if our service worker
   * update code is working well.
   */
  unregister() {
    if (serviceWorkerSupported) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister();
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }

  /**
   *
   * @param worker The new ServiceWorker in the process of installing
   * @returns
   */
  private _handleSWInstalling(worker: ServiceWorker, isUpgradeNotInitial: boolean) {
    if(!worker) {
      return;
    }

    worker.addEventListener('statechange', () => {
      if (worker.state === 'installed') {
        this.handleSWUpdateWaiting(worker, isUpgradeNotInitial);
      }
    });
  }
  private handleSWInstalling: (worker: ServiceWorker, isUpgradeNotInitial: boolean) => void;

  /**
   * This method is called when the ServiceWorkerRegistration updatefound event is fired.
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/updatefound_event
   *
   * The updatefound event of the ServiceWorkerRegistration interface is fired any time the
   * ServiceWorkerRegistration.installing property acquires a new service worker.
   */
  private _handleSWUpdateWaiting(worker: ServiceWorker, isUpgradeNotInitial: boolean) {
    this.nextWorker = worker;

    // If we did not have a previous service worker, then we are simply installing the service
    // worker for the first time and don't need to alert the end user.
    if(isUpgradeNotInitial) {
      this.updateWaiting = true;
      this.emit('updatewaiting', new Event('updatewaiting'));
    }
  }
  private handleSWUpdateWaiting: (worker: ServiceWorker, isUpgradeNotInitial: boolean) => void;
  updateWaiting = false;
  private nextWorker?: ServiceWorker;

  /**
   * The update() method of the ServiceWorkerRegistration interface attempts to update the service
   * worker. It fetches the worker's script URL, and if the new worker is not byte-by-byte
   * identical to the current worker, it installs the new worker. The fetch of the worker bypasses
   * any browser caches if the previous fetch occurred over 24 hours ago.
   * @returns
   */
  private async _checkUpdate(): Promise<boolean> {
    if(!serviceWorkerSupported || !this._serviceWorkerRegistration) {
      return false;
    }

    try {
      await this._serviceWorkerRegistration.update();
      return true;
    } catch(err) {
      return false;
    }
  }
  checkUpdate: () => Promise<boolean>;

  /**
   * This method will send a `SKIP_WAITING` message to the service workering instance sitting in
   * the waiting state. The service worker will then come out of waiting and become activated.
   *
   * Our handler registered for the `controllerchange` event during registration will detect this
   * newly activated service worker and reload the page.
   */
  private _applyUpdate() {
    if(!this.nextWorker) {
      return;
    }

    this.nextWorker.postMessage({ type: 'SKIP_WAITING' });
  }
  applyUpdate: () => void;

  private async _clearAppCache() {
    if (!('caches' in window)) {
      return;
    }

    for (const key of await caches.keys()) {
      if(key === 'static-assets') {
        await caches.delete(key);
      }
    }
  }
}