import { useCallback } from 'react';
import { useAppController, useMessageHandler } from '../../../controllers/AppController';

import { ScriptNodeTypes, ScriptNodeStateChangeTypes, ScriptNodeStateChangedEvent, PrompterMode } from '@fluidprompter/core';
import { PrompterSegment } from '../../../models/EditorTypes';

function useSegmentElementLogic() {

  const appController = useAppController();

  //
  // prompter.node.promptersegment.enteringviewport
  //
  const handleEnteringViewport = useCallback((e?: ScriptNodeStateChangedEvent) => {
    if(!e || !e.nodeMeta || !e.previousState || !e.viewportInfo) {
      throw new Error('`prompter.node.promptersegment.enteringviewport` event requires ScriptNodeStateChangedEvent args.');
    }

    // const segmentNode = e.node as PrompterSegment;
  }, []);

  //
  // prompter.node.promptersegment.enteringviewport
  //
  const handleEnteredViewport = useCallback((e?: ScriptNodeStateChangedEvent) => {
    if(!e || !e.nodeMeta || !e.previousState || !e.viewportInfo) {
      throw new Error('`prompter.node.promptersegment.enteredviewport` event requires ScriptNodeStateChangedEvent args.');
    }

    // const segmentNode = e.node as PrompterSegment;
    // console.log(`prompter.node.${ScriptNodeTypes.SCRIPT_SEGMENT}.${ScriptNodeStateChangeTypes.ENTERED_VIEWPORT}`);

    /*
     * Pause on shotlog buttons?
     *
    bus.emit('prompter.pauseAtPosition', {
      scrollPosition: e.nodeMeta.bottom - 50 - (e.viewportInfo.viewportCuePosition - e.viewportInfo.viewportTop)
    });
    */
  }, []);

  //
  // prompter.node.promptersegment.enteringcue
  //
  const handleEnteringCue = useCallback((e?: ScriptNodeStateChangedEvent) => {
    if(!e || !e.nodePath || !e.nodeMeta || !e.viewportInfo) {
      throw new Error('`prompter.node.promptersegment.enteringcue` event requires ScriptNodeStateChangedEvent args.');
    }

    const segmentNode = e.node as PrompterSegment;

    // UPDATE META DATA ON PENDING SHOT LOG CHECKPOINT
    // segmentNode.number
    // segmentNode.title
    appController.dispatchMessage('shotlog.updatemeta', {
      nodePath: e.nodePath,
      title: segmentNode.title,
    });
  }, [appController]);

  //
  // prompter.node.promptersegment.leavingcue
  //
  const handleLeavingCue = useCallback((e?: ScriptNodeStateChangedEvent) => {
    if(!e || !e.nodePath || !e.nodeMeta || !e.viewportInfo) {
      throw new Error('`prompter.node.promptersegment.leavingcue` event requires ScriptNodeStateChangedEvent args.');
    }

    // const segmentNode = e.node as PrompterSegment;

    //
    // Implicitly finalize this prompter segment as a goodtake if we scroll past it.
    //
    if(e.prompterMode === PrompterMode.Playing) {
      appController.dispatchMessage('shotlog.goodtake', {
        nodePath: e.nodePath,
        implied: true,
      });
    }
  }, [appController]);

  //
  // prompter.node.promptersegment.leavingviewport
  //
  const handleLeavingViewport = useCallback((e?: ScriptNodeStateChangedEvent) => {
    if(!e || !e.nodeMeta || !e.previousState) {
      throw new Error('`prompter.node.promptersegment.leavingviewport` event requires ScriptNodeStateChangedEvent args.');
    }

    // const segmentNode = e.node as PrompterSegment;
  }, []);

  useMessageHandler('prompter.node.statechanged', (e) => {
    const { message } = e;
    if(message.node.type !== ScriptNodeTypes.SCRIPT_SEGMENT) {
      return;
    }

    switch(message.changeType) {
      case ScriptNodeStateChangeTypes.ENTERING_VIEWPORT:
        handleEnteringViewport(message);
        break;
      case ScriptNodeStateChangeTypes.ENTERED_VIEWPORT:
        handleEnteredViewport(message);
        break;
      case ScriptNodeStateChangeTypes.ENTERING_CUE:
        handleEnteringCue(message);
        break;
      case ScriptNodeStateChangeTypes.LEAVING_CUE:
        handleLeavingCue(message);
        break;
      case ScriptNodeStateChangeTypes.LEAVING_VIEWPORT:
        handleLeavingViewport(message);
        break;
    }
  });
}

export default useSegmentElementLogic;