/**
 * Returns true if a string is either a syntactically valid IPv4 address or IPv6 address.
 * This does not validate at a network layer, just if the string represents a valid address.
 * This is used for detecting if a URL hostname is an IP Address vs a DNS name so that
 * FluidPrompter works while being served from an IP Address - primarily for local development
 * purposes.
 * @param ip string
 * @returns boolean
 */
export function isValidIpAddress(ip: string) {
  const parts = ip.split(/[.:]/);

  if (parts.length === 4) {
    // Check IPv4 parts
    for (const part of parts) {
      const num = parseInt(part);
      if (isNaN(num) || num < 0 || num > 255) {
        return false;
      }
    }
    return true;
  } else if (parts.length === 8) {
    // Check IPv6 parts
    for (const part of parts) {
      if (!/^[0-9a-fA-F]{1,4}$/.test(part)) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export default isValidIpAddress;
