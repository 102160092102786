import { ScriptNodeStateChangeTypes } from '../models/ScriptNodeStateChangeTypes';
import BaseControlMessage from '../messages/BaseControlMessage';
/**
 * Represents the ScriptNodeStateChangedEvent type.
 *
 * Event is created when a script node's state changes by entering the viewport, leaving the
 * viewport, entering the cue, or leaving the cue.
 */
export class ScriptNodeStateChangedEvent extends BaseControlMessage {
    constructor(nodePath, node) {
        super();
        this.type = ScriptNodeStateChangedEvent.MESSAGE_NAME;
        this.nodeType = 'leaf';
        this.changeType = ScriptNodeStateChangeTypes.UNKNOWN;
        // Private constructor - forces use of factory methods.
        this.nodePath = nodePath;
        this.node = node || {};
        this.nodeType = this.node.type || 'leaf';
    }
    static build(changeType, node, nodePath, scriptNodeMeta, previousState, newState, viewportInfo, prompterMode) {
        const instance = new ScriptNodeStateChangedEvent(nodePath, node);
        instance.changeType = changeType;
        // instance.type = `prompter.node.${nodeType}.${changeType}`;
        instance.nodePath = nodePath;
        instance.nodeMeta = scriptNodeMeta;
        instance.previousState = previousState;
        instance.newState = newState;
        instance.viewportInfo = viewportInfo;
        instance.prompterMode = prompterMode;
        //
        // Determine how this node's position has changed relative to the prompter viewport.
        //
        /*
        if(newState.bottomClipped && previousState.bottomClipped !== newState.bottomClipped) {
          instance.changeType = ScriptNodeStateChangeTypes.ENTERING_VIEWPORT;
        } else if (newState.onCue && previousState.onCue !== newState.onCue) {
          instance.changeType = ScriptNodeStateChangeTypes.ENTERING_CUE;
        } else if (!newState.onCue && previousState.onCue !== newState.onCue) {
          instance.changeType = ScriptNodeStateChangeTypes.LEAVING_CUE;
        } else if (!newState.topClipped && previousState.topClipped !== newState.topClipped) {
          instance.changeType = ScriptNodeStateChangeTypes.LEAVING_VIEWPORT;
        }
        */
        return instance;
    }
    static fromJson(data) {
        if (!data.nodePath) {
            throw new Error('nodePath is required');
        }
        const instance = new ScriptNodeStateChangedEvent(data.nodePath);
        instance.deserializeSenderInfo(data.sender);
        // instance.changeType = data.changeType;
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            nodePath: this.nodePath,
            changeType: this.changeType,
        };
    }
}
ScriptNodeStateChangedEvent.MESSAGE_NAME = 'prompter.node.statechanged';
