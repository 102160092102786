import BaseControlMessage from './BaseControlMessage';
/**
 * Represents the GenericMessage type.
 */
export class GenericMessage extends BaseControlMessage {
    constructor(eventKey, payload) {
        super();
        this.type = GenericMessage.MESSAGE_NAME;
        this.type = eventKey;
        this.payload = payload;
    }
    static fromJson(data) {
        const instance = new GenericMessage(data.type, data.payload);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            payload: this.payload,
            sender: this.sender?.toJSON(),
        };
    }
}
GenericMessage.MESSAGE_NAME = 'genericmessage';
