import { useCallback } from 'react';
import { MessageHandlerEvent, useMessageHandler } from '../../controllers/AppController';
import usePrompterSession from '../../state/PrompterSessionState';
import { GenericMessage } from '@fluidprompter/core';

function usePrompterBlankingHandler() {
  const doToggleVisibility = useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    e.dispatchMessage(
      usePrompterSession.getState().isBlanking
        ? 'prompter.content.show'
        : 'prompter.content.hide'
    );
  }, []);
  useMessageHandler('prompter.content.togglevisbility', doToggleVisibility);

  const doContentShow = useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    e.sendToPeers = true;

    usePrompterSession.getState().setBlanking(false);
  }, []);
  useMessageHandler('prompter.content.show', doContentShow);

  const doContentHide = useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    e.sendToPeers = true;

    usePrompterSession.getState().setBlanking(true);
  }, []);
  useMessageHandler('prompter.content.hide', doContentHide);

  return doToggleVisibility;
}

export default usePrompterBlankingHandler;