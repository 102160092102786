import React, { useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth0, OAuthError } from '@auth0/auth0-react';
import useApplicationContext from '../hooks/useApplicationContext';
import { zaraz } from 'zaraz-ts';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function AuthCallbackPage() {
  const navigate = useNavigate();
  // const [ searchParams ] = useSearchParams();

  const {
    appContext,
  } = useApplicationContext(false);
  const {
    isLoading: auth0IsLoading,
    isAuthenticated: auth0IsAuthenticated,
    userProfile: auth0User,
  } = appContext;

  const authCallbackProcessedRef = useRef<boolean>(false);
  useEffect(() => {
    if(authCallbackProcessedRef.current) {
      // Prevent double execution in react strict mode.
      return;
    }
    if (auth0IsLoading) {
      return;
    }
    authCallbackProcessedRef.current = true;

    async function processAuthCallback() {
      //
      // We only want to handle the 'logout' state change here, login/signup will be handled in the
      // Auth0Provider.onRedirectCallback function where we have access to the OAuth AppState.
      //
      if (!auth0IsAuthenticated) {
        // We just logged out. Logging out does NOT call the Auth0Provider.onRedirectCallback function.
        await zaraz.track('user_logout_complete');

        const currentUrl = new URL(window.location.href);
        const { searchParams } = currentUrl;

        const redirectUrlString = searchParams.get('redirectUrl');
        let redirectUrl: URL;
        if (redirectUrlString) {
          redirectUrl = new URL(redirectUrlString);
        } else {
          redirectUrl = new URL(window.location.href);
          redirectUrl.pathname = '/';
          redirectUrl.search = '';
          redirectUrl.hash = '';
        }

        navigate(redirectUrl, {
          replace: true,
        });
      }
    }
    processAuthCallback();
  }, [auth0IsAuthenticated, auth0IsLoading, auth0User, navigate]);

  return (
    <Backdrop open={true}>
      <CircularProgress color="inherit" />
      <span style={{
        marginLeft: '16px'
      }}>Loading...</span>
    </Backdrop>
  );
}

export default AuthCallbackPage;