import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';

const HoveringToolbarToggleButton = styled(ToggleButton)({
  color: '#858585',
  borderRadius: 4,
  minWidth: 'unset',
  padding: '12px',
  '&:hover': {
    color: '#fff',
  },
  [`&.${toggleButtonClasses.selected}`]: {
    color: '#fff',
    backgroundColor: '#1E1E1E',
    //borderBottom: '2px solid #fff',
  },
  [`&.${toggleButtonClasses.disabled}`]: {
    pointerEvents: 'unset', // allow :hover styles to be triggered
    cursor: 'not-allowed', // and custom cursor can be defined without :hover state
    color: 'rgba(222,222,222,0.8)',
  },
});

export default HoveringToolbarToggleButton;