import DeviceHost from '../../DeviceHost';
import {
  IDeviceDescriptor,
  DeviceComponent,
  DeviceConnectionType,
  DeviceAxisConfig,
} from '../../BaseDevice';
import BaseHidDevice from '../../BaseHidDevice';
import { DeviceConnectedEvent } from '../../events/DeviceConnectedEvent';
import { DeviceDisconnectedEvent } from '../../events/DeviceDisconnectedEvent';
import { DeviceRemovedEvent } from '../../events/DeviceRemovedEvent';
import { DeviceReportEvent } from '../../events/DeviceReportEvent';
import { DeviceAxisEvent } from '../../events/DeviceAxisEvent';
import { DeviceButtonEvent } from '../../events/DeviceButtonEvent';
import { DeviceWheelEvent } from '../../events/DeviceWheelEvent';
import DigitalButtonStateMachine, { DigitalButtonConfig } from '../../common/DigitalButtonStateMachine';

import { TFunction } from 'i18next';
import RemoteContourShuttleXpressIcon from './images/remote-contour-shuttlexpress-icon.png';
import RemoteContourShuttleProV2Icon from './images/remote-contour-shuttleprov2-icon.png';
import ShuttleProUI from './UI';

type DeviceAxisEventOrUndefined = DeviceAxisEvent | undefined;

const CONTOURSHUTTLE_TYPE = 'contourshuttle';

/**
 * Implements connectivity with a Contour ShuttlePro control surface.
 */
class ShuttlePro extends BaseHidDevice {
  readonly type = CONTOURSHUTTLE_TYPE;

  public static readonly DEVICE_TYPE: string = CONTOURSHUTTLE_TYPE;

  static HID_VIDS = Object.freeze({
    CONTOUR: 0x0B33
  });

  static HID_PIDS = Object.freeze({
    SHUTTLEPRO_V1: 0x0010,
    SHUTTLEXPRESS: 0x0020,
    SHUTTLEPRO_V2: 0x0030
  });

  private _buttonConfigs: DigitalButtonConfig[] | undefined;
  private _buttons: DigitalButtonStateMachine[] = [];

  _handleInputReport: (ev: HIDInputReportEvent) => Promise<void>;

  constructor(deviceHost: DeviceHost) {
    super(deviceHost);
    this.icon = RemoteContourShuttleXpressIcon;
    this.name = 'Contour Device';
    this.connectionType = DeviceConnectionType.Hid;

    // We need to hold a reference to our bound handler so we can properly remove the handler when
    // the device is disconnected.
    this._handleInputReport = this.handleInputReport.bind(this);
  }


  getRequestDeviceOptions(): HIDDeviceRequestOptions {
    const deviceFilters: HIDDeviceRequestOptions = {
      filters: [{
        vendorId: ShuttlePro.HID_VIDS.CONTOUR,
        productId: ShuttlePro.HID_PIDS.SHUTTLEXPRESS,
      }, {
        vendorId: ShuttlePro.HID_VIDS.CONTOUR,
        productId: ShuttlePro.HID_PIDS.SHUTTLEPRO_V1,
      }, {
        vendorId: ShuttlePro.HID_VIDS.CONTOUR,
        productId: ShuttlePro.HID_PIDS.SHUTTLEPRO_V2,
      }]
    };
    return deviceFilters;
  }

  async connect() {
    try {
      const hidDevices = await navigator.hid.requestDevice(this.getRequestDeviceOptions());

      if(!hidDevices.length) {
        throw new Error('HidDevice required.');
      }

      this.hidDevice = hidDevices[0];

      if(!this.hidDevice.opened) {
        await this.hidDevice.open();
      }

      if(this.hidDevice.vendorId !== ShuttlePro.HID_VIDS.CONTOUR
        || (
          this.hidDevice.productId !== ShuttlePro.HID_PIDS.SHUTTLEXPRESS
          && this.hidDevice.productId !== ShuttlePro.HID_PIDS.SHUTTLEPRO_V1
          && this.hidDevice.productId !== ShuttlePro.HID_PIDS.SHUTTLEPRO_V2
        )
      ) {
        throw new Error('HID Device has incorrect VID or PID.');
      }

      switch(this.hidDevice.productId) {
        case ShuttlePro.HID_PIDS.SHUTTLEXPRESS:
          this.icon = RemoteContourShuttleXpressIcon;
          this.name = 'Contour ShuttleXpress';
          this._buttonConfigs = [{
            name: 'pageup', // button1
            bitmask: 0x0010,
          }, {
            name: 'up', // button2
            bitmask: 0x0020,
          }, {
            name: 'ok', // button3
            bitmask: 0x0040,
          }, {
            name: 'down', // button4
            bitmask: 0x0080,
          }, {
            name: 'pagedown', // button5
            bitmask: 0x0100,
          }];
          break;
        case ShuttlePro.HID_PIDS.SHUTTLEPRO_V1:
        case ShuttlePro.HID_PIDS.SHUTTLEPRO_V2:
          this.icon = RemoteContourShuttleProV2Icon;
          this.name = 'Contour ShuttlePro';
          this._buttonConfigs = [{
            name: 'button1',
            bitmask: 0x0001,
          }, {
            name: 'button2',
            bitmask: 0x0002,
          }, {
            name: 'button3',
            bitmask: 0x0004,
          }, {
            name: 'button4',
            bitmask: 0x0008,
          }, {
            name: 'button5',
            bitmask: 0x0010,
          }, {
            name: 'button6',
            bitmask: 0x0020,
          }, {
            name: 'stop',     // button7
            bitmask: 0x0040,
          }, {
            name: 'play',     // button8
            bitmask: 0x0080,
          }, {
            name: 'button9',
            bitmask: 0x0100,
          }, {
            name: 'button10',
            bitmask: 0x0200,
          }, {
            name: 'button11',
            bitmask: 0x0400,
          }, {
            name: 'button12',
            bitmask: 0x0800,
          }, {
            name: 'button13',
            bitmask: 0x1000,
          }, {
            name: 'up',     // button14
            bitmask: 0x2000,
          }, {
            name: 'down',   // button15
            bitmask: 0x4000,
          }];
          break;
      }

      this.emit('connected', new DeviceConnectedEvent(this));

      // this.emit('devicereport', new DeviceReportEvent(this));

      //
      // Initialize button state machines.
      //
      this._buttons = [];
      for (let i = 0; i < this._buttonConfigs.length; i++) {
        const btnInstance = new DigitalButtonStateMachine(this._buttonConfigs[i], (buttonName: string, eventType: string) => {
          // const eventName = `${buttonName}.${eventType}`;
          // console.log(`ShuttlePro.onButtonEvent ${eventName}`);
          // this.emit("inputevent", eventName);
          this.emit('buttonreport', new DeviceButtonEvent(this, buttonName, eventType));
        });
        this._buttons.push(btnInstance);
      }

      this.hidDevice.addEventListener('inputreport', this._handleInputReport);
    } catch(err) {
      alert('Error connecting ShuttlePro.');
    }
  }

  async disconnect() {
    if(this.hidDevice) {
      this.hidDevice.removeEventListener('inputreport', this._handleInputReport);

      await this.hidDevice.close();
    }

    this.emit('disconnected', new DeviceDisconnectedEvent(this, true));
    this.emit('removed', new DeviceRemovedEvent(this));
  }

  private async handleInputReport(ev: HIDInputReportEvent) {
    //
    // HANDLE Axis
    // Range from -7 to +7
    //
    this.processAxisChanges(ev);

    //
    // HANDLE WHEEL
    // Circular rotation 0-255
    //
    this.processWheelChanges(ev);

    //
    // HANDLE Buttons
    //
    this.processButtonChanges(ev);
  }

  private _previousAxisEvent: DeviceAxisEventOrUndefined = undefined;
  private async processAxisChanges(ev: HIDInputReportEvent) {
    const { data, device, reportId } = ev;

    const axisPosition = data.getInt8(0);
    if(axisPosition !== this._previousAxisEvent?.rawValue) {
      // Key up on previous Axis Position key (this._previousAxisPosition)

      // Key down on current Axis Position if not 0 (axisPosition)
      const scaledAxisPosition = axisPosition * 32767 / 7;
      // console.log(`Axis Position: ${axisPosition}, Scaled: ${scaledAxisPosition}`);

      const axisConfig = new DeviceAxisConfig();
      // axisConfig.valueMinimum = -7;
      axisConfig.valueMinimum = -32767;

      axisConfig.valueNeutral = 0;

      // axisConfig.valueMaximum = 7;  // signed int32 maximum value
      axisConfig.valueMaximum = 32767;  // signed int32 maximum value

      const axisEvent = new DeviceAxisEvent(this, 0, axisConfig, ev, scaledAxisPosition, this._previousAxisEvent);

      // Save our new AnalogPosition/Value for use in the next event to see what changed.
      this._previousAxisEvent = axisEvent;
      // this._previousAxisPosition = axisPosition;  // TODO: Delete this, it is recorded in the _previousAxisEvent

      this.emit('axisevent', axisEvent);
    }
  }

  private _raf = 0;
  private _deltaY = 0;
  private _previousWheelPosition = 0;
  private async processWheelChanges(ev: HIDInputReportEvent) {
    const { data, device, reportId } = ev;

    const wheelPosition = data.getUint8(1);
    if(wheelPosition !== this._previousWheelPosition) {
      //
      // Wheel position moved, let's figure out if it moved forwards or backwards.
      //
      // Return 1 for clockwise rotation, -1 for counter-clockwise rotation.
      //
      const direction = (
        ((this._previousWheelPosition === 0xFF && wheelPosition === 0x00)  // Clockwise boundary rollover
        || (!(this._previousWheelPosition === 0x00 && wheelPosition === 0xFF)  // Counter-clockwise boundary rollover
          && wheelPosition > this._previousWheelPosition))
      ) ? 1 : -1;
      // console.log(`Wheel turned: previous:${this._previousWheelPosition}, current:${wheelPosition}, direction ${direction}`);

      //
      // Accumulate wheel movements and throttle our wheel events based on prompter framerate.
      //
      this._deltaY += direction;
      cancelAnimationFrame(this._raf);
      this._raf = requestAnimationFrame(this.emitWheelEvent.bind(this));

      this._previousWheelPosition = wheelPosition;
    }
  }
  private emitWheelEvent() {
    // Small jitters on the jog wheel may accumulate some very small +/- events that aggregate to 0.
    // Don't fire useless events for 0 effective movement of the jogwheel.
    if(this._deltaY !== 0) {
      this.emit('wheelevent', new DeviceWheelEvent(this, DeviceWheelEvent.DELTA_LINE, 0, this._deltaY, 0));
      this._deltaY = 0;
    }
  }

  private _previousButtonStates = 0;
  private async processButtonChanges(ev: HIDInputReportEvent) {
    const { data, device, reportId } = ev;

    // TODO: Process button changes
    const buttonStates = data.getUint16(3, true);
    if(buttonStates !== this._previousButtonStates) {
      // The button state changed.
      for (let i = 0; i < this._buttons.length; i++) {
        this._buttons[i].processState(buttonStates);
      }

      this._previousButtonStates = buttonStates;
    }
  }

  static readonly DeviceKey: string = 'contourshuttle';
  static getDeviceDescriptors(t: TFunction): IDeviceDescriptor[] {
    return [{
      connectionType: DeviceConnectionType.Hid,
      deviceKey: ShuttlePro.DeviceKey,
      deviceName: 'Contour ShuttleXpress',
      deviceIcon: RemoteContourShuttleXpressIcon,
      requiresPlanLevel: 1,
      requiresHid: true,
    }, {
      connectionType: DeviceConnectionType.Hid,
      deviceKey: ShuttlePro.DeviceKey,
      deviceName: 'Contour ShuttlePro',
      deviceIcon: RemoteContourShuttleProV2Icon,
      requiresPlanLevel: 1,
      requiresHid: true,
    }];
  }

  getDeviceUIComponent(): DeviceComponent {
    return ShuttleProUI;
  }
}

export default ShuttlePro;