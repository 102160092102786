import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import ScheduleIcon from '@mui/icons-material/Schedule';

interface RenderTimeOfDayProps {
  openMenuFn: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const getTimeOfDayString = () => {
  const now = new Date();

  const timeHours = now.getHours();
  const timeAmPm = timeHours >= 12 ? 'pm' : 'am';

  // const timeHoursHalf = timeHours % 12;
  // const timeHoursHalf = (timeHours % 12 === 0) ? (timeHours % 12) : 12; // the hour '0' should be '12'
  const timeHoursHalf = (timeHours > 12) ? (timeHours - 12) : timeHours;  // Convert 24 hour time instead 12 hour time

  // const timeHoursString = ("0" + timeHoursHalf).slice(-2);

  const timeMinutes = ('0' + now.getMinutes()).slice(-2);
  // const timeSeconds = ("0" + now.getSeconds()).slice(-2);

  const isEvenSeconds = (now.getSeconds() % 2) === 0;
  // const timeSeparater = isEvenSeconds ? ':' : ' ';

  //`YYYY-MM-DDTHH:mm:ss.sssZ`
  //return `${timeHoursHalf}${timeSeparater}${timeMinutes} ${timeAmPm}`;
  return {
    Part1: `${timeHoursHalf}`,
    SeparatorVisible: isEvenSeconds,
    Part2: `${timeMinutes} ${timeAmPm}`,
  };
};

const StyledButton = styled(Button)({
  fontSize: '1.4em',
  color: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 1.0)',
  },
});

function RenderTimeOfDay(props: RenderTimeOfDayProps) {

  const timePart1Ref = React.useRef<HTMLDivElement>(null);
  const timeSeparatorRef = React.useRef<HTMLDivElement>(null);
  const timePart2Ref = React.useRef<HTMLDivElement>(null);

  const {
    Part1,
    Part2,
  } = getTimeOfDayString();

  useEffect(() => {
    const interval = window.setInterval(() => {
      const timePart1El = timePart1Ref.current;
      const timeSeparatorEl = timeSeparatorRef.current;
      const timePart2El = timePart2Ref.current;
      if(!timePart1El || !timeSeparatorEl || !timePart2El) {
        return;
      }

      requestAnimationFrame(() => {
        const {
          Part1,
          SeparatorVisible,
          Part2,
        } = getTimeOfDayString();

        timePart1El.textContent = Part1;
        timeSeparatorEl.style.color = SeparatorVisible ? 'inherit' : 'transparent';
        timePart2El.textContent = Part2;
      });
    }, 250);

    return () => { window.clearInterval(interval); };
  }, []);

  return (
    <StyledButton 
      size="large"
      startIcon={ <ScheduleIcon /> }
      onFocus={(e) => { e.target.blur(); }}
      onClick={props.openMenuFn}
    >
      <Typography component={'span'} fontSize='inherit' ref={timePart1Ref}>{Part1}</Typography>
      <Typography component={'span'} fontSize='inherit' ref={timeSeparatorRef}>:</Typography>
      <Typography component={'span'} fontSize='inherit' ref={timePart2Ref}>{Part2}</Typography>
    </StyledButton>
  );
}

export default RenderTimeOfDay;