import BaseDevice from '../BaseDevice';
import { BaseDeviceEvent } from './BaseDeviceEvent';

/**
 * An event for expressing continuously rotatable input devices such as mouse wheels and
 * shuttle/jog wheels.
 * 
 * See: https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent
 */
export class DeviceWheelEvent extends BaseDeviceEvent<Event> {
  readonly type = 'DeviceWheelEvent';

  static readonly DELTA_PIXEL: number = 0x00;
  static readonly DELTA_LINE: number = 0x01;
  static readonly DELTA_PAGE: number = 0x02;

  constructor(
    device: BaseDevice, 
    deltaMode: number, 
    deltaX: number,
    deltaY: number,
    deltaZ: number,
  ) {
    super(device, new Event('SyntheticDeviceWheelEvent'));

    this._deltaMode = deltaMode;
    this._deltaX = deltaX;
    this._deltaY = deltaY;
    this._deltaZ = deltaZ;
  }

  get deltaMode(): number {
    return this._deltaMode;
  }
  private _deltaMode = 0; // DOM_DELTA_PIXEL (0x00) | DOM_DELTA_LINE (0x01) | DOM_DELTA_PAGE (0x02)

  get deltaX(): number {
    return this._deltaX;
  }
  private _deltaX = 0;

  get deltaY(): number {
    return this._deltaY;
  }
  private _deltaY = 0;

  get deltaZ(): number {
    return this._deltaZ;
  }
  private _deltaZ = 0;
}