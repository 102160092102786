import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

const HoveringToolbarComponent = styled(Toolbar)({
  color: '#fff',
  background: '#2D2D2D',
  borderRadius: 4,
  width: 'fit-content',
  /*'@media (min-width: 600px)': {
    minHeight: 'unset'
  },*/
});

export default HoveringToolbarComponent;