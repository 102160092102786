import DeviceHost from '../DeviceHost';
import BaseDevice, {
  IDeviceDescriptor,
  DeviceComponent,
  DeviceConnectionType,
} from '../BaseDevice';
import { DeviceConnectedEvent } from '../events/DeviceConnectedEvent';
import { DeviceDisconnectedEvent } from '../events/DeviceDisconnectedEvent';
import { DeviceRemovedEvent } from '../events/DeviceRemovedEvent';

import { TFunction } from 'i18next';
import RemoteIkanEliteIcon from './images/remote-ikan-elite-icon.png';
import IkanEliteRemoteUI from './UI';

const IKANELITE_TYPE = 'ikanelite';

class IkanEliteRemote extends BaseDevice {
  readonly type = IKANELITE_TYPE;

  public static readonly DEVICE_TYPE: string = IKANELITE_TYPE;

  constructor(deviceHost: DeviceHost) {
    super(deviceHost);
    this.icon = RemoteIkanEliteIcon;
    this.name = 'Ikan Elite Remote';
    this.connectionType = DeviceConnectionType.Keyboard;
  }

  async connect() {
    this.emit('connected', new DeviceConnectedEvent(this));
  }

  async disconnect() {
    this.emit('disconnected', new DeviceDisconnectedEvent(this, true));
    this.emit('removed', new DeviceRemovedEvent(this));
  }

  static readonly DeviceKey: string = 'ikanelite';
  static getDeviceDescriptors(t: TFunction): IDeviceDescriptor[] {
    return [{
      connectionType: DeviceConnectionType.Keyboard,
      deviceKey: IkanEliteRemote.DeviceKey,
      deviceName: `Ikan Elite ${t('connectdevicedialog.remote')}`,
      deviceIcon: RemoteIkanEliteIcon,
      requiresPlanLevel: 0,
      priority: 2,
    }];
  }

  getDeviceUIComponent(): DeviceComponent {
    return IkanEliteRemoteUI;
  }
}

export default IkanEliteRemote;