import BaseControlMessage from './BaseControlMessage';
import { PrompterSessionEndpoint } from '../models/PrompterSessionEndpoint';
export const SDPMESSAGE_KEY = 'sdp';
/**
 * Represents the SdpMessage type.
 */
export class SdpMessage extends BaseControlMessage {
    constructor(endpoint, targetId, sdp) {
        super();
        this.type = SDPMESSAGE_KEY;
        this.endpoint = endpoint;
        this.targetId = targetId;
        this.sdp = sdp;
    }
    static fromJson(data) {
        const jsonData = data;
        if (!jsonData
            || jsonData.type !== SDPMESSAGE_KEY
            || !jsonData.endpoint
            || !jsonData.targetId
            || !jsonData.sdp) {
            throw new SyntaxError('Invalid JSON for SdpMessage.');
        }
        const endpoint = PrompterSessionEndpoint.fromJson(jsonData.endpoint);
        const instance = new SdpMessage(endpoint, jsonData.targetId, jsonData.sdp);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            endpoint: this.endpoint,
            targetId: this.targetId,
            sdp: this.sdp,
        };
    }
}
SdpMessage.MESSAGE_NAME = SDPMESSAGE_KEY;
