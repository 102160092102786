import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import RenderEmptyTimer from './RenderEmptyTimer';
import RenderSecondsElapsed from './RenderSecondsElapsed';
import RenderTimeOfDay from './RenderTimeOfDay';

enum StatusTypes {
  EmptyTimer = 'Empty',
  TimeOfDay = 'TimeOfDay',
  ElapsedSeconds = 'ElapsedSeconds'
}

interface SessionTimerDisplayProps {
  initialType: string
}

const SessionTimerDisplay = React.memo(function SessionTimerDisplay(props: SessionTimerDisplayProps) {

  let defaultType = StatusTypes.EmptyTimer;
  switch(props.initialType) {
    case 'TimeOfDay':
      defaultType = StatusTypes.TimeOfDay;
      break;
    case 'ElapsedSeconds':
      defaultType = StatusTypes.ElapsedSeconds;
      break;
    default:
    case 'Empty':
      defaultType = StatusTypes.EmptyTimer;
      break;
  }

  const [statusType, setStatusType] = React.useState<StatusTypes>(defaultType);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeStatusType = (type: StatusTypes) => {
    setAnchorEl(null);
    setStatusType(type);
  };

  return (
    <>
      {statusType === StatusTypes.EmptyTimer && <RenderEmptyTimer openMenuFn={handleMenu} />}
      {statusType === StatusTypes.ElapsedSeconds && <RenderSecondsElapsed openMenuFn={handleMenu} />}
      {statusType === StatusTypes.TimeOfDay && <RenderTimeOfDay openMenuFn={handleMenu} />}
      <Menu
        id="bluetooth-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => { changeStatusType(StatusTypes.EmptyTimer); }}>(Empty)</MenuItem>
        <MenuItem onClick={() => { changeStatusType(StatusTypes.TimeOfDay); }}>Time of Day</MenuItem>
        <MenuItem onClick={() => { changeStatusType(StatusTypes.ElapsedSeconds); }}>Elapsed Play Time</MenuItem>
      </Menu>
    </>
  );
});

export default SessionTimerDisplay;