/**
 * © Copyright 2023 fluidprompter.com
 */

export const enum AppBluetoothProvider {
  WEB = 'WEB',
  IPC = 'IPC',
}

export const enum AppBluetoothDeviceState {
  Unknown = 'Unknown',
  Resetting = 'Resetting',
  Unsupported = 'Unsupported',
  Unauthorized = 'Unauthorized',
  PoweredOff = 'PoweredOff',
  PoweredOn = 'PoweredOn',
}

export const enum AppBluetoothEventType {
  ServerDisconnectedEvent = 'serverdisconnected',
  AvailabilityChangedEvent = 'availabilitychanged',
}

export type UUID = string
export type Identifier = string
export type DeviceId = string
export type Base64 = string

export const enum AppBluetoothStatus {
  Success = 'success',
  Failed ='failed',
}

export type AppBluetoothDevice = {
  id: DeviceId;
  name: string | null;
  serviceUUIDs: string[] | null;
  rssi?: number | null;
  localName?: string | null;
}

export type AppBluetoothService = {
  id: Identifier;
  uuid: UUID;
  deviceID: DeviceId;
  isPrimary: boolean;
  characteristics?: AppBluetoothCharacteristic[];
}

export type AppBluetoothCharacteristic = {
  uuid: UUID
  // Service's UUID to which characteristic belongs
  serviceUUID: UUID
  // Device's ID to which characteristic belongs
  deviceID: DeviceId
  isReadable?: boolean
  // True if characteristic can monitor value changes.
  isNotifiable?: boolean
  value?: DataView | null
}

export type IPCNotifyCallback = (
  char: AppBluetoothCharacteristic,
  status: AppBluetoothStatus,
  error: number | null
) => void;

export type IPCListenerCallback = (...args: Event[]) => void;

export interface IBluetoothProvider {
  getProvider(): AppBluetoothProvider;
  getState(): Promise<AppBluetoothDeviceState>;
  startScanning(requestOptions: RequestDeviceOptions): Promise<AppBluetoothDevice[]>;
  stopScanning(): Promise<AppBluetoothStatus>;
  connect(deviceId: DeviceId): Promise<AppBluetoothDevice | undefined>;
  disconnect(deviceId: DeviceId): Promise<AppBluetoothDevice | undefined>;
  discoverServices(deviceId: DeviceId): Promise<AppBluetoothService[]>;
  read(deviceId: DeviceId,
    serviceUUID: string,
    characteristicUUID: string
  ) : Promise<DataView | null>;
  notify(deviceId: DeviceId,
    serviceUUID: string,
    characteristicUUID: string,
    handler: IPCNotifyCallback,
    topic: string | undefined
  ) : Promise<void>;
  addEventListener(
    deviceId: DeviceId,
    eventType: AppBluetoothEventType,
    listener: IPCListenerCallback
  ) : void;
  removeEventListener(
    deviceID: DeviceId,
    eventType: AppBluetoothEventType,
    listener: IPCListenerCallback
  ) : void;
}