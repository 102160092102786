import { useCallback } from 'react';
import { Editor, BaseEditor, createEditor, Range, Descendant, Text, BasePoint, BaseRange } from 'slate';
import { Slate, Editable, ReactEditor, withReact, DefaultElement, RenderElementProps, RenderLeafProps } from 'slate-react';

import { IViewportRefs } from './usePrompterViewportRefs';
import { IViewportFuncs } from './usePrompterViewportFuncs';
import { PrompterMode, ScrollDirection } from '@fluidprompter/core';
import FocusGuide from '../../models/FocusGuide';

import ISegmentMeasurements from '../../models/segments/ISegmentMeasurements';
import IPrompterPosition from '../../models/segments/IPrompterPosition';
import SegmentPosition from '../../models/segments/SegmentPosition';

import { useWhatChanged } from '@simbathesailor/use-what-changed';

export interface PrompterResizedEventArgs {
  contentHeight: number,
  viewportHeight: number,
  lineHeight: number,
}

interface PrompterScrolledHandlerConfigStoreSlice {
    focusGuide: FocusGuide;
    flipVertical: boolean;
}

function usePrompterResizedHandler(viewportRefs: IViewportRefs, viewportFuncs: IViewportFuncs, configStore: PrompterScrolledHandlerConfigStoreSlice) {

  // useWhatChanged([viewportRefs.previousLedgerRef, prompterSession.prompterMode, prompterSession.segments, configStore.focusGuide, configStore.flipVertical]);

  const onPrompterResized = useCallback(async (e: PrompterResizedEventArgs) => {
    const scrollingEl = document.scrollingElement;
    const currentLedger = viewportRefs.previousLedgerRef.current;
    if(!scrollingEl || !currentLedger) {
      return;
    }

    // Get our maximum scroll position.
    // const viewportHeight = scrollingEl.clientHeight;
    // const contentHeight = scrollingEl.scrollHeight;
    // const scrollPositionMax = contentHeight - viewportHeight;
    currentLedger.scrollPositionMax = e.contentHeight - e.viewportHeight;

    // Let's make sure all segments have been rendered/mounted before trying to measure their DOM elements.
    /*
    const currentSegments = prompterSession.segments;
    const segmentRenderPromises = currentSegments.map((segment) => segment.isRendered());
    await Promise.allSettled(segmentRenderPromises);
    */

    //
    // Whenever the prompter is resized in anyway, the max scroll position or number of lines of 
    // text may change as a result of the resizing.
    //
    // let prompterLines = 0;
    // let prompterWords = 0;
    // let prompterCharacters = 0;
    /*
    currentSegments.forEach(async (segment, index, segments) => {
      const segmentPosition = segment.measureDimensions.call(segment);

      prompterLines += segmentPosition.lines;
      prompterWords += segmentPosition.words;
      prompterCharacters += segmentPosition.characters;
    });
    */
    // const { scriptNodes } = prompterSession;
    // scriptNodes.forEach((slateNode, index, slateNodes) => {
    //   // Look-up the meta data for the given slate node and calculate whether it is in the viewport or now.
    // });

    /*
    prompterSession.setTextMetrics({
      lines: prompterLines,
      words: prompterWords,
      characters: prompterCharacters,
    });
    */

  }, [viewportRefs.previousLedgerRef]);

  return onPrompterResized;
}

export default usePrompterResizedHandler;