import BaseDevice from '../BaseDevice';
import { BaseDeviceEvent } from './BaseDeviceEvent';

export class DeviceDisconnectedEvent extends BaseDeviceEvent<Event> {
  readonly type = 'DeviceDisconnectedEvent';

  constructor(
    device: BaseDevice, 
    userRequested: boolean,
  ) {
    super(device, new Event('SyntheticDeviceDisconnectedEvent'));

    this._userRequested = (userRequested === true);
  }

  public get userRequested() {
    return this._userRequested;
  }
  private _userRequested: boolean;
}