import { useCallback, Dispatch } from 'react';
import { IApplicationContextState, IReducerAction, ReducerActionTypes } from './hookTypes';
import useFeatureFlagsStore from '../../state/FeatureFlagsStore';
import AppConfig from '../../utils/AppConfig';

interface IRedirectToBillingPortalArgs {
  return_url?: string,
  flow?: string,

  price_id?: string,  // If specified, the price_id to update the current subscription to.
}

export interface ISubscriptionUtils {
  redirectToBillingPortal: (args?: IRedirectToBillingPortalArgs) => void;
  startUpdatePaymentMethod: () => void;
  startChangePlans: () => void;
  downgradeToFreePlan: () => void;
  switchToSoloCreatorPlan: () => void;
}

export const useSubscriptionUtils = (
  appContext: IApplicationContextState,
  dispatch: Dispatch<IReducerAction>,
  getApiToken: () => Promise<string>,
): ISubscriptionUtils => {

  const redirectToBillingPortal = useCallback(async (args?: IRedirectToBillingPortalArgs) => {
    dispatch({
      type: ReducerActionTypes.setIsLoading,
      payload: true,
    });

    const token = await getApiToken();

    const returnUrl = new URL(window.location.href);

    // Make sure we don't lose any temporarily enabled feature flags during the login redirects.
    useFeatureFlagsStore.getState().encodeFeatureFlagParams(returnUrl.searchParams);

    const requestParams = new URLSearchParams({
      return_url: returnUrl.toString(),
    });
    if(args?.flow) {
      requestParams.set('flow', args.flow);
    }
    if(args?.price_id) {
      requestParams.set('price_id', args.price_id);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/stripe/getbillingportal?` + requestParams, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status !== 200) {
        // We received an error response.
        alert('Sorry, we encountered an unexpected error. Please contact fluidprompter support.');
        return;
      }

      // console.log(`Got app API account response from API serverless function`, response);

      const billingPortalSession = await response.json();
      const billingUrl = billingPortalSession.url as string;
      if(billingUrl) {
        // If the user hits the back button and back/forward cache is used, we want to clear the loading indicator.
        window.addEventListener('pageshow', () => {
          dispatch({
            type: ReducerActionTypes.setIsLoading,
            payload: false,
          });
        }, { once: true });

        window.location.href = billingUrl;
      }
      return;
    } catch (err) {
      // Probably ran into a problem preparing a billing portal session via API request.
      alert('Unexpected error. Please contact customer support.');
    }

    //
    // If we get here, we never managed to create a billing portal and redirect the user.
    // Must have been some problem above..
    //
    dispatch({
      type: ReducerActionTypes.setIsLoading,
      payload: false,
    });
  }, [getApiToken, dispatch]);

  const startUpdatePaymentMethod = useCallback(() => {
    redirectToBillingPortal({
      flow: 'payment_method_update'
    });
  }, [redirectToBillingPortal]);

  const startChangePlans = useCallback(() => {
    redirectToBillingPortal({
      flow: 'subscription_update'
    });
  }, [redirectToBillingPortal]);

  const downgradeToFreePlan = useCallback(() => {
    redirectToBillingPortal({
      flow: 'subscription_update_confirm',
      price_id: AppConfig.current.PRICE_ID_FREE_PLAN,
    });
  }, [redirectToBillingPortal]);

  const switchToSoloCreatorPlan = useCallback(() => {
    redirectToBillingPortal({
      flow: 'subscription_update_confirm',
      price_id: AppConfig.current.PRICE_ID_SOLO_PLAN,
    });
  }, [redirectToBillingPortal]);

  return {
    redirectToBillingPortal,
    startUpdatePaymentMethod,
    startChangePlans,
    downgradeToFreePlan,
    switchToSoloCreatorPlan,
  };
};