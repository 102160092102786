import { useCallback, useEffect, useRef } from 'react';
import { MessageHandlerEvent, useMessageHandler } from '../controllers/AppController';

import DeviceHost from './DeviceHost';
import PrompterPeerInstance from './prompterpeer/PrompterPeerInstance';

import BaseDevice, { DeviceConnectionType } from './BaseDevice';
import usePrompterSession from '../state/PrompterSessionState';
import useRTCBackgroundHandlers from './rtcbackground';
import useRTCNegotiation from './prompterpeer/useRTCNegotiation';
import usePeerControlMessages from './prompterpeer/usePeerControlMessages';
import { AppLifecycleState, ConnectionState, EndpointRole, GenericMessage, PeerStateResponse, PrompterSessionEndpoint, ScriptRequestMessage, SetLeaderMessage } from '@fluidprompter/core';

import { Id, toast } from 'react-toastify';

import logger from '../utils/Logger';
import { logDecorator } from '../utils/Logger';

interface IManagePeerDeviceEvent {
  anchorEl: HTMLDivElement;
  deviceId: string;
}

const usePeerDevices = (deviceHost: DeviceHost) => {

  const log = logger.child({
    childName: 'usePeerDevices'
  });
  log.trace('usePeerDevices() constructor');

  const websocketState = usePrompterSession(state => state.websocketState);

  const websocketStateRef = useRef<ConnectionState>(websocketState);
  const connectingToastTimeoutRef = useRef<number>();
  const connectingToastRef = useRef<Id>();
  useEffect(() => {
    const lastWebsocketState = websocketStateRef.current;
    if(websocketState === lastWebsocketState) {
      return;
    }

    //
    // Did we just start connecting?
    //
    if(
      lastWebsocketState !== ConnectionState.Connecting
      && websocketState === ConnectionState.Connecting
    ) {
      //
      // We just transitioned to connecting state from something else.
      //
      // Don't show the `connecting...` toast unless we are taking longer than 1000ms to connect.
      // Most happy path websocket connections will be established pretty quick.
      //
      connectingToastTimeoutRef.current = window.setTimeout(() => {
        connectingToastRef.current = toast('Connecting...', {
          isLoading: true,
          closeButton: false,
          closeOnClick: false,
          draggable: false,
          pauseOnFocusLoss: false,
          position: 'bottom-center',
          theme: 'dark',
          className: 'connecting',
        });
      }, 1000);
    }

    //
    // Did we just finishing connecting?
    //
    if(
      lastWebsocketState === ConnectionState.Connecting
      && websocketState !== ConnectionState.Connecting
    ) {
      //
      // We just transitioned away from connecting (whether success or failure)
      //
      clearTimeout(connectingToastTimeoutRef.current);
      toast.dismiss(connectingToastRef.current);
    }

    websocketStateRef.current = websocketState;
  }, [websocketState]);

  //
  // Handles SdpMessage and IceCandidateMessage
  //
  useRTCNegotiation(deviceHost);

  //
  // Implement handlers for messages intended to affect a single connected peer's configuration or
  // state (vs session control messages that cause all peers to change state for Play/Pause/Stop).
  //
  usePeerControlMessages(deviceHost);

  //
  // Handle all the background related app messages.
  //
  const backgroundMediaStream = useRTCBackgroundHandlers(deviceHost);

  /**
   * Ensure a peer is connected to this prompter instance by either making a new RTCPeerConnection
   * or verifying the existing peer connection is still working.
   *
   * @param remoteEndpoint data model containing endpoint information for the remote peer
   * @param representsLocal true if this peer instance represents this local device
   * @param polite true if this side of the peer connection is the polite side which will yield to the other peer when handle 'glare'
   */
  const connectPeerDevice = async (
    remoteEndpoint: PrompterSessionEndpoint,
    representsLocal: boolean,
    polite: boolean
  ) => {
    const { endpointId } = remoteEndpoint;
    if(!endpointId) {
      throw new Error('Invalid endpointId');
    }

    //
    // Find our device implementation and instantiate an instance.
    //
    let remoteDevice: (PrompterPeerInstance | undefined) = undefined;
    try {
      //
      // First check if this device already has an instance.
      //
      const allPeers = deviceHost.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network);
      const localDevice = allPeers.find((peerInstance) => peerInstance.representsLocal);
      remoteDevice = allPeers.find((peerInstance) => peerInstance.endpointId === endpointId);

      if(remoteDevice) {
        // console.log('connectPeerDevice() found existing PrompterPeerInstance - probably a reconnect');
        remoteDevice.updateFromEndpoint(remoteEndpoint);
        remoteDevice.setPolite(polite);
      }

      //
      // If we don't already have a peer instance for the provided endpointId,
      // then let's create one.
      //
      if(!remoteDevice) {
        log.info('connectPeerDevice() requires new PrompterPeerInstance() - not a reconnect');
        remoteDevice = new PrompterPeerInstance(
          deviceHost,
          undefined,  // currentRTCConfiguration,
          remoteEndpoint,
          representsLocal,
          polite
        );

        // If we already have a background track originated from this prompter instance, then let's
        // get it sent to the newly connecting prompter!
        if(backgroundMediaStream) {
          remoteDevice.sendBackgroundMediaStream(backgroundMediaStream);
        }
      }

      //
      // Is this server record representing this local instance?
      // Special case, we want to keep track of our currently assigned peerNumber in appController.
      //
      if(representsLocal && remoteEndpoint.peerNumber) {
        deviceHost.appController.peerNumber = remoteEndpoint.peerNumber;
      }

      //
      // If we know the latitude and longitude for both this local prompter instance and the remote
      // peer instance, we can calculate the estimated distance between the peers.
      //
      if(localDevice && remoteDevice) {
        const { latitude, longitude } = localDevice;
        // console.log(`got localDevice and remoteDevice (${latitude}, ${longitude})`);
        if(latitude && longitude) {
          // console.log('got latitude and longitude');
          remoteDevice.setLocalLatitudeLongitude(latitude, longitude);
        }
      }

      //
      // `connect()` won't do anything if the peer instance was already connected.
      //
      await remoteDevice.connect();
    } catch(error) {
      // Error
      alert('Error trying to connect prompter peer.\rPlease try again or contact FluidPrompter support.');
      log.error(error as Error, 'Error trying to connect prompter peer. connectPeerDevice()');

      if(remoteDevice) {
        remoteDevice.disconnect();
        deviceHost.unregisterDevice(remoteDevice);
      }
    }
  };

  //
  // Register this local prompter in our devices list.
  //
  const thisEndpointId = usePrompterSession(state => state.instanceId);
  useEffect(() => {
    if(!thisEndpointId) {
      return;
    }

    //
    // Check if this endpoint was already registered... maybe it disconnected/reconnected.
    //
    const existingDevice = deviceHost.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .find((device) => device.representsLocal);
    if(existingDevice) {
      // We changed PrompterSession.instanceId
      existingDevice.endpointId = thisEndpointId;
      return;
    }

    const endpointInfo = new PrompterSessionEndpoint();
    endpointInfo.endpointId = thisEndpointId;
    endpointInfo.role = EndpointRole.Prompter;
    endpointInfo.cloudLatency = deviceHost.appController.localCloudLatency || 0;

    log.info('usePeerDevices -> deviceHost.getOrCreatePeerInstance(endpointInfo); for local peer');
    // connectPeerDevice(endpointInfo, true, false);
    deviceHost.getOrCreatePeerInstance(endpointInfo, true);
  }, [thisEndpointId]);

  //
  // 'connect': ConnectMessage,
  // 'connect.response': ConnectResponse,
  // 'disconnect': DisconnectMessage,
  // 'sdp': SdpMessage,
  // 'icecandidate': IceCandidateMessage,
  //
  useMessageHandler('connect', async (e) => {
    // A remote device just joined this session.
    const { endpoint, rebuildConnection } = e.message;
    const { endpointId, connectedTimestamp } = endpoint;
    log.info({
      message: e.message
    }, `Received 'connect' message (rebuildConnection = ${rebuildConnection === true}).`);

    if(!endpointId) {
      throw new Error('endpointId was not provided in connect message.');
    }

    // ConnectPeer should be idempotent. It should ensure peer connection whether peer is currently
    // connected or disconnected.
    // connectPeerDevice(endpoint, false, isPolitePeer);
    const peerInstance = deviceHost.getOrCreatePeerInstance(endpoint);
    peerInstance.connect(rebuildConnection);
  });

  /**
   * ConnectRequestMessage is sent by a polite peer to request that the impolite peer initiation an
   * RTCPeerConnection by sending an Sdp Offer.
   *
   * The handling will be similar to when we receive a regular 'connect' message when a peer first
   * connects to the prompter session.
   */
  useMessageHandler('connect.request', async (e) => {
    const { endpoint, rebuildConnection } = e.message;
    const { endpointId, connectedTimestamp } = endpoint;

    if(!e.originatedRemotely) {
      // If this originated locally, its not intended for ourself. :-)
      e.sendToPeers = true;
      return;
    }

    log.info({
      message: e.message
    }, `Received 'connect.request' message (rebuildConnection = ${rebuildConnection}).`);

    if(!endpointId) {
      throw new Error('endpointId was not provided in connect message.');
    }

    //
    // Find our peer instance representing the sender endpoint.
    //
    const peerInstance = deviceHost.getOrCreatePeerInstance(endpoint);

    //
    // The rebuild connection flag will be set true when the peer who sent the connect request
    // message is the polite peer and failed to open the datachannel after iceconnectionstate
    // became 'connected'.
    //
    peerInstance.connect(rebuildConnection);
  });


  /**
   * Given a list of currently connected endpoints our cloud API knows about, disconnect any local
   * orphaned peers that are no longer connect to both the cloud and its corresponding peer, and
   * connect any peers we don't yet have a local instance for.
   */
  const reconcilePeerInstances = useCallback((allRemotePeersServer: PrompterSessionEndpoint[]) => {
    const allPeersLocal = deviceHost.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network);

    //
    // Grab a reference to our local prompter instance.
    //
    const localDevice = allPeersLocal.find((device) => device.representsLocal);
    if(!localDevice) {
      log.error('Local device not yet registered in usePeerDevices() handling prompter.state.current message.');
      return; // Should this ever happen? The local device is added in a hook above.
    }

    //
    // As a connecting prompter, we will only be the leader if we are the first prompter to join
    // this session (not including remotes/viewers).
    //
    // If we are not the first prompter joining this session, the leader will be designated when
    // we receive a `loadscript` message from the current leader.
    //
    let thisIsFirstConnectedPrompter = true;
    if(allRemotePeersServer && allRemotePeersServer.length) {
      const firstRemotePrompter = allRemotePeersServer.find(
        (peer) => peer.role === EndpointRole.Prompter
          && peer.endpointId !== thisEndpointId
      );

      thisIsFirstConnectedPrompter = (firstRemotePrompter === undefined);
    }
    if(thisIsFirstConnectedPrompter) {
      localDevice.handleLeaderDesignation(localDevice.id);
    }

    //
    // Loop over all endpoint instances that exist locally, and remove orphaned endpoints not
    // currently connected to the server.
    //
    allPeersLocal.forEach((endpointLocalRecord) => {
      if(endpointLocalRecord.representsLocal) {
        return;
      }

      const endpointServerRecord = allRemotePeersServer.find((device) => device.endpointId === endpointLocalRecord.endpointId);
      if(!endpointServerRecord) {
        //
        // Remove the local orphaned endpoint.
        //
        // We don't want to forcefully disconnect the peer if we still have a peer connection.
        // The peer may have lost its connection to the cloud (lost public internet) without losing
        // its connections to peer (local network).
        //
        log.info({
          endpointServerRecord,
        }, `reconcilePeerInstances() orphaned peer removeIfDisconnected(${endpointLocalRecord.endpointId})`);
        endpointLocalRecord.removeIfDisconnected();
      }
    });

    //
    // Loop over all endpoints reported by the cloud/server and update or add our local endpoint
    // instance.
    //
    allRemotePeersServer.forEach((endpointServerRecord) => {
      const { endpointId, peerNumber } = endpointServerRecord;
      if(!endpointId) {
        log.error({
          endpointServerRecord
        }, `invalid endpointId '${endpointId}'`);
        throw new Error(`invalid endpointId '${endpointId}'`);
      }

      if(peerNumber && endpointId === deviceHost.appController.localEndpointId) {
        //
        // Update our appController's knowledge of our assigned PeerNumber
        // This will be used in the event of a WebSocket reconnect to request the same peerNumber
        // be reassigned.
        //
        deviceHost.appController.peerNumber = peerNumber;

        //
        // Updates our local endpoint with meta data from the server including PeerNumber as well
        // as things like GeoIP detected location or what data center we are connected to.
        //
        localDevice.updateFromEndpoint(endpointServerRecord);
      }

      if(endpointId === localDevice.endpointId) {
        // We don't want to connect to our local device.
        return;
      }

      const peerInstance = deviceHost.getOrCreatePeerInstance(endpointServerRecord);
      peerInstance.connect();
    });
  }, []);

  useMessageHandler('connect.response', async (e) => {
    const { rtcConfiguration, endpoints: allRemotePeersServer } = e.message;
    log.info({
      message: e.message
    }, 'Received \'connect.response\' message.');

    const { localEndpointId } = deviceHost.appController;
    if(!localEndpointId) {
      // This should be impossible to reach as we don't establish our websocket until after the
      // WindowTracker has issued us a windowId/endpointId.
      throw new Error('AppController.localEndpointId is not defined');
    }

    //
    // Add some meta data to our log decorator which will be included in all future log records.
    //
    const localEndpointRecord = allRemotePeersServer.find(endpointMeta => endpointMeta.endpointId === localEndpointId);
    if(localEndpointRecord && localEndpointRecord.peerNumber) {
      logDecorator.set('senderName', `PEER #${localEndpointRecord.peerNumber}`);
    }

    //
    // Save the server provided RTCConfiuration. This may contain authenticated TURN servers.
    //
    if(rtcConfiguration) {
      deviceHost.appController.rtcConfig = rtcConfiguration;
    }

    //
    // Gather the list of remote endpointIds (not including this local prompter).
    //
    const remotePeerEndpointIdsNotIncludingLocal = allRemotePeersServer
      .filter(expectedPeer => expectedPeer.endpointId && expectedPeer.endpointId !== localEndpointId)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map(expectedPeer => expectedPeer!.endpointId) as string[];

    if(remotePeerEndpointIdsNotIncludingLocal) {
      // We have other remote peers in this session before we joined!

      //
      // After we reconnect the websocket, we will retrieve the current script from one of our peers.
      //
      deviceHost.scriptCollector.setExpectedPeers(remotePeerEndpointIdsNotIncludingLocal);

      //
      // Update our peers list, creating peers we don't yet have a connection with, and removing
      // peers that are disconnected both to cloud and this peer.
      //
      reconcilePeerInstances(allRemotePeersServer);

      return;
    }

    //
    // If we get here, then there were no other remote peers in this session when we joined.
    // We are the leader and we are the most recent script.
    //
    const { setCurrentLeaderId, setLastScriptChangeTimestamp } = usePrompterSession.getState();
    setCurrentLeaderId(localEndpointId);

    // The lastScriptChangeTimestamp defaults to 1yr ago until we either connect to the expected
    // peers, or if there are no other peers in this session, then let's set our
    // lastScriptChangeTimestamp to now so that if other peers join sometime after now, they
    // consider this peer to have the most recent script.
    setLastScriptChangeTimestamp(Date.now());
  });

  useMessageHandler('disconnect', (e) => {
    // A remote device just disconnected from the cloud (its websocket connection terminated,
    // whether intentionally or unintentionally).
    const { message: disconnectMessage } = e;

    //
    // 1.) If peer disconnecting was the current leader, we need to find a successor for leader.
    // The last peer to have been leader prior to this one will be selected.
    //
    if(disconnectMessage.endpointId === usePrompterSession.getState().currentLeaderId) {
      const newLeader = deviceHost.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
        .filter((peer) => peer.endpointRole === EndpointRole.Prompter
          && peer.appLifecycleState !== AppLifecycleState.Hidden
          && (peer.representsLocal || peer.connectionState === ConnectionState.Connected))
        .sort((a: PrompterPeerInstance, b: PrompterPeerInstance) => {
          return (a.lastLeaderTimestamp === b.lastLeaderTimestamp) ? 0
            : (a.lastLeaderTimestamp < b.lastLeaderTimestamp) ? -1 : 1;
        })
        .find((device) => device.endpointId !== disconnectMessage.endpointId);

      // console.log(`Previous leader '${disconnectMessage.endpointId}' disconnected.`);
      if(newLeader) {
        // console.log(`Successor leader '${newLeader?.endpointId}' should take over as leader.`);
        e.dispatchMessage(new SetLeaderMessage(newLeader.id));
      }
    }

    //
    // 1.) Find the device disconnected from the cloud based on the endpointId.
    //
    const targetDevice = deviceHost.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .find((device) => device.endpointId === disconnectMessage.endpointId);

    if(!targetDevice) {
      // We have no record of this device.
      return;
    }

    //
    // 2.) Mark the local device record as disconnected from the cloud. This will not force
    // disconnect any webrtc peer connection. If the peer connection still exists, it should
    // continue to function.
    //
    targetDevice.setCloudConnectionState(ConnectionState.Disconnected);

    //
    // 3.) If this was an intentional disconnect (and not a "lost connect"), then we will fully
    // disconnect this peer device and remove it from the user interface.
    //
    if(disconnectMessage.wasIntentional) {
      targetDevice?.disconnect();
    }
  });

  // useMessageHandler('getsession', async (e) => {
  //   // console.log('Received GetSession message:', e.message);
  //   e.sendToPeers = !e.originatedRemotely;
  // });
  //
  //
  // We will request current prompter session info whenever we think we're out of date
  // including when a peer disconencts ungracefully. We will check the list of currently
  // connected peers in the GetSessionResponse to see if the peer is gone from the server.
  //
  useMessageHandler('getsession.response', async (e) => {
    const { endpoints: allRemotePeersServer } = e.message;
    log.info({
      message: e.message
    }, `Received GetSessionResponse message (${allRemotePeersServer.length} peer devices total)`);

    //
    // Update our peers list, creating peers we don't yet have a connection with, and removing
    // peers that are disconnected both to cloud and this peer.
    //
    reconcilePeerInstances(allRemotePeersServer);
  });

  useMessageHandler('heartbeat.response', (e) => {
    const { roundtripLatency } = e.message;
    if(!roundtripLatency) {
      // This is a super edge case - should never happen.
      return;
    }

    deviceHost.appController.localCloudLatency = roundtripLatency;

    deviceHost
      .allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .forEach((peerDevice) => {
        peerDevice.setLocalCloudLatency(roundtripLatency);
      });
  });

  /**
   * When this prompter instance receives a control message it will re-evaluate which endpointId is
   * the current leader. Using the current leader's endpointId, we will update our list of
   * PrompterPeerInstances to set or clear their isLeader property.
   *
   * Each PrompterPeerInstance will also request a UI update if their isLeader property changed.
   */
  useMessageHandler('setleader', async (e) => {
    const { originatedRemotely, message } = e;
    const { endpointId: proposedLeaderEndpointId } = message;
    e.sendToPeers = false;

    log.trace(`received 'setleader' message (originatedRemotely = ${originatedRemotely})`);

    deviceHost.appController.setLeaderEndpointId(proposedLeaderEndpointId);
  });

  useMessageHandler('peer.state.request', async (e) => {
    const { message } = e;
    const { sender } = message;

    if(!e.originatedRemotely) {
      e.sendToPeers = true;
      return;
    }

    if(!sender?.id) {
      // Cannot reply to a request without a senderId.
      return;
    }

    const localPeerInstance = deviceHost.getPrompterPeerLocalInstance();
    if(!localPeerInstance) {
      // Cannot provide information about this local peer, if we have no local peer.
      // This should be impossible!
      return;
    }
    const { lastScriptChangeTimestamp } = usePrompterSession.getState();

    const peerStateResponse = new PeerStateResponse({
      isLeader: localPeerInstance.isLeader,
      lastScriptChangeTimestamp,
      lastLeaderTimestamp: localPeerInstance.lastLeaderTimestamp,
      lifecycleState: localPeerInstance.appLifecycleState,
    });

    //
    // It appears negotiated RTCDataChannels do not necessarily become 'open' simultaneously on
    // both ends of the peer connection? Rather each end will transition to open on its own time
    // which can be milliseconds apart. If we send a message too quickly there is a race
    // condition where a message can be lost in one direction.
    //
    // I have not verified this 100% but changed our collection of peer state to be a pull approach
    // with a request/response and retry interval.
    //
    log.trace('Reply with PeerStateResponse');

    //
    // We want to reply directly to the sending peer, and not broadcast our peer state to all
    // connected peers.
    //
    const remotePeerInstance = deviceHost.getDevice(sender.id) as PrompterPeerInstance;
    if(!remotePeerInstance) {
      throw new Error('Could not find remotePeerInstance');
    }
    remotePeerInstance.sendMessage(peerStateResponse);
  });

  /**
   * When a new peer connection is made, we should receive a PeerStateResponse from the connected
   * peer containing its lastLeaderTimestamp and lifecycleState. Update our local representation
   * for this peer.
   */
  useMessageHandler('peer.state', async (e) => {
    const { message } = e;
    const { sender } = message;

    if(!e.originatedRemotely) {
      e.sendToPeers = true;
      return;
    }

    const targetDevice = await deviceHost.promisePeerInstance(sender?.id);
    if(!targetDevice) {
      log.error(`Received PeerStateResponse for unknown device: senderId = '${sender?.id}'`);
      return;
    }

    const shouldSync = targetDevice.handlePeerStateResponse(message);

    if(shouldSync) {
      e.syncScrollPosition();

      e.syncScrollSpeed();

      //
      // Make sure we are in the same play/pause/blanking state as the script leader we got the
      // script from.
      //
      const localPrompterSession = usePrompterSession.getState();
      if(sender?.mode === 'playing' && !localPrompterSession.isPlaying) {
        if(localPrompterSession.isBlanking) {
          e.dispatchMessage('prompter.content.show');
        }
        localPrompterSession.play();
      }
      if(sender?.mode === 'paused' && !localPrompterSession.isPaused) {
        localPrompterSession.pause();
      }
    }
  });

  const managePeerDevice = useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    const args = e.message.payload as IManagePeerDeviceEvent;

    const device = deviceHost.getDevice(args.deviceId) as PrompterPeerInstance;
    if(device) {
      alert('usePeerDevices() -> Manage Device');

      // lastDeviceRef.current = device;
      // setDeviceMenuAnchor(args.anchorEl);
      // setDeviceComponent(() => device.getDeviceUIComponent());
      return;
    }

    // setDeviceMenuAnchor(undefined);
    // setDeviceComponent(() => EmptyComponent);
  }, [deviceHost]);
  useMessageHandler('devices.managedevice', managePeerDevice);

  /**
   * Register a handler for ALL app messages, we will send to peer if appropriate.
   */
  useMessageHandler('*', (e) => {
    if(!e.sendToPeers) {
      return;
    }

    // Transmit to all peer connections (except myself of course).
    const allDevicesExceptMyself = deviceHost
      .allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .filter((device) => !device.representsLocal);

    // let allPeersAreConnected = true;
    allDevicesExceptMyself.forEach((device) => {
      device.sendMessage(e.message);

      // device.peerConnectionState;
      // device.cloudConnectionState;
    });
  });
};

export default usePeerDevices;