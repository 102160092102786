import { useCallback } from 'react';
import { Descendant } from 'slate';

import { IViewportRefs } from './usePrompterViewportRefs';
import { IViewportFuncs } from './usePrompterViewportFuncs';
import { PrompterMode, ScrollDirection } from '@fluidprompter/core';
import FocusGuide from '../../models/FocusGuide';

import IPrompterPosition from '../../models/segments/IPrompterPosition';
import SegmentPosition from '../../models/segments/SegmentPosition';

import usePrompterSession from '../../state/PrompterSessionState';
import { useWhatChanged } from '@simbathesailor/use-what-changed';

export interface PrompterScrolledEventArgs {
  scrollDirection: ScrollDirection,
  scrollPosition: number,
  contentHeight: number,
  viewportHeight: number,
  lineHeight: number,
}

interface PrompterScrolledHandlerConfigStoreSlice {
    focusGuide: FocusGuide,
    flipVertical: boolean,
}

function usePrompterScrolledHandler(viewportRefs: IViewportRefs, viewportFuncs: IViewportFuncs, configStore: PrompterScrolledHandlerConfigStoreSlice) {

  // useWhatChanged([viewportRefs.previousLedgerRef, prompterSession.prompterMode, prompterSession.segments, configStore.focusGuide, configStore.flipVertical]);

  const onPrompterScrolled = useCallback((e: PrompterScrolledEventArgs) => {

    const prompterSession = usePrompterSession.getState();

    //
    // This callback is fired whether or not the scroll action was initiated by the teleprompter animation 
    // algorithm or a human input device such as a mouse wheel. If it was initiated by the teleprompter 
    // animation, we don't want to update our current ledger as it results in rounding the floating point 
    // scrollPosition to an integer scrollPosition. This rounding leads to some "jank" in the animation 
    // making it appear less smooth particularly on lower resolution displays or displays with scaling.
    //
    // However, if the end user repositioned the script using the mouse while the prompter was not playing
    // we need to make sure the animation starts from the new position next time we do start playing.
    //
    if(prompterSession.prompterMode !== PrompterMode.Playing) {
      if(viewportRefs.previousLedgerRef.current) {
        viewportRefs.previousLedgerRef.current.scrollPosition = e.scrollPosition;
        // console.log(`4.) PrompterContainer.onPrompterScrolled() documentScrollPosition = ${e.scrollPosition}`);
      }
    }

    const contentHeight = e.contentHeight;
    const viewportHeight = e.viewportHeight;

    // ****************************************
    // Prepare our IPrompterPosition object for calculating segment positions.
    // ****************************************
    const viewportPosition: IPrompterPosition = {
      ...e,
      prompterMode: prompterSession.prompterMode,
      viewportTop: e.scrollPosition,
      viewportHeight: e.viewportHeight,
      viewportBottom: e.scrollPosition + e.viewportHeight,
      //
      scrollDirection: ScrollDirection.None,
      viewportCueTop: -1,
      viewportCueCenter: -1,
      viewportCueBottom: -1,
    };

    // ****************************************
    // Calculate the top and bottom position for the Cue point.
    // ****************************************
    let focusPosition = configStore.focusGuide;
    if(configStore.flipVertical && configStore.focusGuide === FocusGuide.Top) {
      focusPosition = FocusGuide.Bottom;
    }
    if(configStore.flipVertical && configStore.focusGuide === FocusGuide.Bottom) {
      focusPosition = FocusGuide.Top;
    }
    switch(focusPosition) {
      case FocusGuide.Top:
        viewportPosition.viewportCueCenter = e.scrollPosition + (e.viewportHeight * 20 / 100);
        break;
      case FocusGuide.Middle:
      case FocusGuide.None:
      default:
        viewportPosition.viewportCueCenter = e.scrollPosition + (e.viewportHeight / 2);
        break;
      case FocusGuide.Bottom:
        viewportPosition.viewportCueCenter = e.scrollPosition + (e.viewportHeight * 80 / 100);
        break;
    }
    viewportPosition.viewportCueTop = viewportPosition.viewportCueCenter - (e.lineHeight / 2);
    viewportPosition.viewportCueBottom = viewportPosition.viewportCueTop + e.lineHeight;

    // ****************************************
    // TODO: Figure out where we are in the content and fire events for each IPrompterSegment for onEnter, onCue, onLeave
    // ****************************************
    /*
     * OLD WAY with previous prompter segments classes. New method will be different.
     *
    const currentSegmentPositions = [];
    const currentSegments = prompterSession.segments;
    currentSegments.forEach((segment, index, segments) => {
      const segmentPosition = segment.measurePosition.call(segment, viewportPosition);

      //
      // Check if this segment just became the active segment at the prompter cue position.
      //
      if(segmentPosition
        && segmentPosition.segmentPosition !== segmentPosition.segmentPositionPrev 
        && segmentPosition.segmentPosition === SegmentPosition.Cue)
      {
        // This segment just entered the cue region, let's update our prompterSession to reflect the currently active segment.
        prompterSession.setCurrentSegment(segment.segmentId);
      }

      currentSegmentPositions.push(segmentPosition);
    });
    */
    // console.log(currentSegmentPositions);

    // sample: 39.917865908715896
    // let scrollPercentage = (e.scrollPosition * 100) / (contentHeight - viewportHeight);
    // console.log(`Scroll Percentage: ${scrollPercentage}`);


  }, [viewportRefs.previousLedgerRef, configStore.focusGuide, configStore.flipVertical]);

  return onPrompterScrolled;
}

export default usePrompterScrolledHandler;