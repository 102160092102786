interface ITreeNode<T extends ITreeNode<T>> {
  children: T[]
}

function getTreeNodeByPath<T extends ITreeNode<T>>(currentPath: number[], availableMeta: T[]): T {
  if(currentPath.length === 0) {
    throw new Error(`Invalid node path: ${currentPath}`);
  }

  const workingPath = [...currentPath]; // Avoid modifying the supplied path parameter, create a copy.
  const targetIndex = workingPath.shift();
  if(targetIndex === undefined || availableMeta.length < targetIndex) {
    throw new Error('Unavailable targetIndex.');
  }

  const currentMeta = availableMeta[targetIndex];

  if(workingPath.length === 0) { return currentMeta; }

  return getTreeNodeByPath(workingPath, currentMeta.children);
}

export default getTreeNodeByPath;