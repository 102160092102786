import React, { useState, useCallback } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import isHotkey from 'is-hotkey';

function RemoteJoinPage() {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [roomId, setRoomId] = useState<string>('');

  const handleJoinRoom = useCallback(() => {
    setIsLoading(true);
    const currentUrl = window.location.protocol + '//' + window.location.host + '/';
    const remoteUrl = new URL(currentUrl);
    remoteUrl.pathname = `/remote/${roomId}`;
    window.location.href = remoteUrl.toString();
  }, [roomId]);

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isHotkey('enter', e)) {
      return;
    }
    e.preventDefault();

    // User pressed enter! 
    handleJoinRoom();
  }, [handleJoinRoom]);

  return (
    <>
      {isLoading && <Backdrop
        open={true}
        sx={{
          backgroundColor: '#000',
          zIndex: 2,
        }}
      >
        <CircularProgress color="inherit" />
        <span style={{
          marginLeft: '16px'
        }}>Loading...</span>
      </Backdrop>}
      <Stack
        spacing={0}
        sx={{ maxWidth: '480px', width: '100%', height: '100vh', margin: '0 auto' }}
      >
        <Box sx={{ flexGrow: 0.5 }} />

        <Box>
          <Typography
            m='5px auto'
            sx={{
              fontSize: '0.8rem',
              color: 'rgba(238,238,238,0.6)'
            }}
          >Enter the prompter room id you want to join.</Typography>
          <TextField
            label="Prompter Room Id" 
            variant="outlined"
            value={roomId}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setRoomId(e.target.value);
            }}
            onKeyDown={onKeyDown}
          />
          <Button onClick={handleJoinRoom}>JOIN</Button>
        </Box>

        <Box sx={{ flexGrow: 0.5 }} />
      </Stack>
    </>
  );
}

export default RemoteJoinPage;