import React, { useEffect, useRef, useState } from 'react';
import SelectDeviceDialogRenderer, { IAvailableDevice } from './SelectDeviceDialogRenderer';
import { MessageHandlerEvent, useMessageHandler } from '../../controllers/AppController';
import { GenericMessage } from '@fluidprompter/core';
import useApplicationContext from '../../hooks/useApplicationContext';

const SelectDeviceDialog = () => {

  const {
    appContext,
  } = useApplicationContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [devices, setDevices] = useState<IAvailableDevice[]>([]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelectDevice = React.useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    setIsOpen(true);
  }, [appContext]);
  // useMessageHandler('prompter.local.accountrequired2', handleSelectDevice);

  const intervalId = useRef<number>();
  useEffect(() => {
    if(!isOpen) {
      return;
    }

    const intervalHandler = () => {
      if(devices.length > 5) {
        clearInterval(intervalId.current);
        return;
      }

      setDevices([
        ...devices,
        { name: `Device ${devices.length + 1}` }
      ]);
    };

    intervalId.current = window.setInterval(intervalHandler, 1000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, [isOpen, devices]);

  return (<>
    {isOpen && <SelectDeviceDialogRenderer handleClose={handleClose} devices={devices} />}
  </>);
};

export default SelectDeviceDialog;