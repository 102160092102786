import React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import AccountPanelOutlined from '../../AccountPanelOutlined/AccountPanelOutlined';

import InfoIcon from '@mui/icons-material/Info';

import useApplicationState from '../../../../state/ApplicationState';
import { shallow } from 'zustand/shallow';

const FreePlanPanel = () => {
  const appState = useApplicationState(state => ({
    setComparePlansOpen: state.setComparePlansOpen,  
  }), shallow);

  const handleComparePlans = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    appState.setComparePlansOpen(true);
  };

  return (<AccountPanelOutlined
    title={<List disablePadding>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="FluidPrompter Free" src={process.env.PUBLIC_URL + '/android-chrome-192x192.png'} />
        </ListItemAvatar>
        <ListItemText
          primary={'FluidPrompter Free'}
          secondary={'The free version includes the basics found in most free teleprompter apps, but better!'}
        />
      </ListItem>
    </List>}
    className="PlanFeatures"
  >
    <Grid container spacing={{ sm: 0, md: 3 }}>
      <Grid item sm={12} md={6}>
        <ul className="ListIncluded">
          <li className="awesome"><b><em>Smooth scrolling text up the screen!</em></b></li>
          <li>Continuously adjustable speed</li>
          <li>Adjustable font size</li>
          <li>Adjustable margins/gutters</li>
          <li>Community support</li>
        </ul>
      </Grid>
      <Grid item sm={12} md={6}>
        Not Included:
        <ul className="ListNotIncluded">
          <li>Analog foot-pedal control <InfoIcon fontSize="small" sx={{ color: 'rgba(0, 102, 204, 0.4)', verticalAlign: 'text-bottom' }}/></li>
          <li>API automation</li>
          <li>Email Support</li>
        </ul>
        <a href="#plans" onClick={handleComparePlans}>See all features and compare plans</a>
      </Grid>
    </Grid>
  </AccountPanelOutlined>);
};

export default FreePlanPanel;