import React from 'react';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import TextAlign from '../../../models/TextAlign';
import FocusGuide from '../../../models/FocusGuide';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import useConfigurationStore, { CONTENT_WIDTH_MINIMUM } from '../../../state/ConfigurationStore';
import { useAppController } from '../../../controllers/AppController';
import { useTranslation } from 'react-i18next';
import { SetScrollSpeedMessage } from '@fluidprompter/core';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Left align the label relative to the enclosed form input element.
// https://stackoverflow.com/a/56828472
const StyledFormControlLabel = styled(FormControlLabel)({
  width: '100%',
  alignItems: 'start',
  justifyContent: 'space-between',
  marginLeft: 0,
});

interface PrompterAppearanceProps {
  expanded: boolean;
  onExpand: () => void;
}
const PrompterAppearance = (props: PrompterAppearanceProps) => {
  const configStore = useConfigurationStore();

  const appController = useAppController();

  const { t } = useTranslation('appmenu');

  const selectedColorStyles: React.CSSProperties = {
    borderRadius: '4px',
    outline: 'solid #1976d2',
    outlineOffset: 2,
    transform: 'scale(1.0)',
    zIndex: 1,
  };

  return (
    <>
      <ListItemButton
        selected={props.expanded}
        onClick={() => {
          props.onExpand();
        }}
      >
        <ListItemIcon>
          <DisplaySettingsIcon />
        </ListItemIcon>
        <ListItemText primary={t('appearancemenu.caption')} />
        {props.expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{
          pt: 2,
          pr: 2,
          pb: 2,
          pl: 4,
          background: 'rgba(25, 118, 210, 0.08)',
        }}
        in={!!props.expanded} timeout="auto"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <StyledFormControlLabel
          disableTypography
          labelPlacement="top"
          label={<Box display="flex" justifyContent="space-between" width="100%">
            <Typography component="span">{t('appearancemenu.textsize')}:</Typography>
            <Typography component="span">{configStore.textSize}pt</Typography>
          </Box>}
          control={<Slider aria-label={t('appearancemenu.textsize')}
            valueLabelDisplay="off"
            min={10}
            max={100}
            value={configStore.textSize}
            onChange={(e, newValue) => configStore.setTextSize(newValue as number)}
          />}
        />

        <StyledFormControlLabel
          disableTypography
          labelPlacement="top"
          label={<Box display="flex" justifyContent="space-between" width="100%">
            <Typography component="span">{t('appearancemenu.lineheight')}:</Typography>
            <Typography component="span">{configStore.lineHeight}</Typography>
          </Box>}
          control={<Slider aria-label={t('appearancemenu.lineheight')}
            valueLabelDisplay="off"
            min={1}
            max={3}
            step={0.25}
            value={configStore.lineHeight}
            onChange={(e, newValue) => configStore.setLineHeight(newValue as number)}
          />}
        />

        <StyledFormControlLabel
          disableTypography
          labelPlacement="top"
          label={<Box display="flex" justifyContent="space-between" width="100%">
            <Typography component="span">{t('appearancemenu.leftgutter')}:</Typography>
            <Typography component="span">{configStore.leftGutter}%</Typography>
          </Box>}
          control={<Slider aria-label={t('appearancemenu.leftgutter')}
            valueLabelDisplay="off"
            min={0}
            max={50}
            value={configStore.leftGutter}
            onChange={(e, newValue) => configStore.setLeftGutter(newValue as number)}
          />}
          sx={{
            marginTop: 2,
          }}
        />
        <StyledFormControlLabel
          disableTypography
          labelPlacement="top"
          label={<Box display="flex" justifyContent="space-between" width="100%">
            <Typography component="span">{t('appearancemenu.rightgutter')}:</Typography>
            <Typography component="span">{configStore.rightGutter}%</Typography>
          </Box>}
          control={<Slider aria-label={t('appearancemenu.rightgutter')}
            valueLabelDisplay="off"
            min={0}
            max={50}
            value={configStore.rightGutter}
            onChange={(e, newValue) => configStore.setRightGutter(newValue as number)}
          />}
        />
        <StyledFormControlLabel
          disableTypography
          labelPlacement="top"
          label={<Box display="flex" justifyContent="space-between" width="100%">
            <Typography component="span">{t('appearancemenu.contentwidth')}:</Typography>
            <Typography component="span">{configStore.contentWidth}%</Typography>
          </Box>}
          control={<Slider aria-label={t('appearancemenu.contentwidth')}
            valueLabelDisplay="off"
            min={CONTENT_WIDTH_MINIMUM}
            max={100}
            value={configStore.contentWidth}
            onChange={(e, newValue) => configStore.setContentWidth(newValue as number)}
          />}
        />

        <StyledFormControlLabel
          disableTypography
          labelPlacement="top"
          label={<Box display="flex" justifyContent="space-between" width="100%" marginBottom="0.35em">
            <Typography component="span">{t('appearancemenu.colorscheme.caption')}:</Typography>
          </Box>}
          control={<Grid container spacing={1}>
            <Grid item xs={6}>
              <Tooltip title={t('appearancemenu.colorscheme.yellow-black')} placement="top" arrow>
                <Button
                  fullWidth
                  onClick={() => {
                    configStore.setColorScheme('yellow-black');
                  }}
                  style={{
                    transform: 'scale(0.9)',
                    ...((
                      '#000000' === configStore.backgroundColor
                        && '#ffff00' === configStore.textColor
                    ) && selectedColorStyles),
                    fontWeight: 'bold',
                    background: '#000000',
                    color: '#ffff00'
                  }}
                >{t('appearancemenu.colorscheme.yellow')}</Button>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title={t('appearancemenu.colorscheme.white-black')} placement="top" arrow>
                <Button
                  fullWidth
                  onClick={() => {
                    configStore.setColorScheme('white-black');
                  }}
                  style={{
                    transform: 'scale(0.9)',
                    ...((
                      '#000000' === configStore.backgroundColor
                        && '#ffffff' === configStore.textColor
                    ) && selectedColorStyles),
                    fontWeight: 'bold',
                    background: '#000000',
                    color: '#ffffff'
                  }}
                >{t('appearancemenu.colorscheme.white')}</Button>
              </Tooltip>
            </Grid>
            {/* <Grid item xs={4}>
              <Tooltip title={t('appearancemenu.colorscheme.black-white')} placement="top" arrow>
                <Button
                  fullWidth
                  onClick={() => {
                    configStore.setColorScheme('black-white');
                  }}
                  style={{
                    transform: 'scale(0.9)',
                    ...((
                      '#ffffff' === configStore.backgroundColor
                        && '#000000' === configStore.textColor
                    ) && selectedColorStyles),
                    fontWeight: 'bold',
                    background: '#ffffff',
                    color: '#000000'
                  }}
                >{t('appearancemenu.colorscheme.black')}</Button>
              </Tooltip>
            </Grid> */}
          </Grid>}
          sx={{
            marginTop: 1,
          }}
        />

        <StyledFormControlLabel
          disableTypography
          labelPlacement="top"
          label={<Box display="flex" justifyContent="space-between" width="100%" marginBottom="0.35em">
            <Typography component="span">{t('appearancemenu.defaultjustification.caption')}:</Typography>
          </Box>}
          control={<ButtonGroup fullWidth
            variant="outlined" aria-label={t('appearancemenu.defaultjustification.caption')}
            sx={{
              pb: 2,
            }}>
            <Tooltip title={t('appearancemenu.defaultjustification.alignleft')} placement="top" arrow>
              <Button onClick={() => { configStore.setContentJustification(TextAlign.Left); }} variant={TextAlign.Left === configStore.contentJustification ? 'contained' : 'outlined'}><FormatAlignLeftIcon /></Button>
            </Tooltip>
            <Tooltip title={t('appearancemenu.defaultjustification.center')} placement="top" arrow>
              <Button onClick={() => { configStore.setContentJustification(TextAlign.Center); }} variant={'center' === configStore.contentJustification ? 'contained' : 'outlined'}><FormatAlignCenterIcon /></Button>
            </Tooltip>
            <Tooltip title={t('appearancemenu.defaultjustification.alignright')} placement="top" arrow>
              <Button onClick={() => { configStore.setContentJustification(TextAlign.Right); }} variant={'right' === configStore.contentJustification ? 'contained' : 'outlined'}><FormatAlignRightIcon /></Button>
            </Tooltip>
            <Tooltip title={t('appearancemenu.defaultjustification.justify')} placement="top" arrow>
              <Button onClick={() => { configStore.setContentJustification(TextAlign.Justify); }} variant={TextAlign.Justify === configStore.contentJustification ? 'contained' : 'outlined'}><FormatAlignJustifyIcon /></Button>
            </Tooltip>
          </ButtonGroup>}
          sx={{
            marginTop: 2,
          }}
        />

        <StyledFormControlLabel
          disableTypography
          labelPlacement="top"
          label={<Box display="flex" justifyContent="space-between" width="100%">
            <Typography component="span">{t('appearancemenu.scrollspeed')}:</Typography>
            <Typography component="span">{configStore.scrollSpeed}px/s</Typography>
          </Box>}
          control={<Slider aria-label={t('appearancemenu.scrollspeed')}
            valueLabelDisplay="off"
            min={20}
            max={500}
            value={configStore.scrollSpeed}
            onChange={(e, newValue) => {
              const proposedScrollSpeed = newValue as number;
              appController.dispatchMessage(new SetScrollSpeedMessage(proposedScrollSpeed));
            }}
          />}
          sx={{
            marginTop: 2,
          }}
        />

        {/*
        <Typography gutterBottom>Scroll Position:</Typography>
        <Slider aria-label="Scroll Position"
          valueLabelDisplay="auto"
          min={0}
          max={100000}
          value={state.scrollPosition}
          step={500}
          onChange={(e, newValue) => dispatch({ type: "setScrollPosition", scrollPosition: newValue })} />
        */}

        <StyledFormControlLabel
          labelPlacement="start"
          label={`${t('appearancemenu.fliphorizontally')}:`}
          control={<Switch
            checked={configStore.flipHorizontal}
            onChange={(e) => configStore.setFlipHorizontal(e.target.checked) }
          />}
          sx={{
            marginTop: 2,
          }}
        />

        <StyledFormControlLabel
          labelPlacement="start"
          label={`${t('appearancemenu.flipvertically')}:`}
          control={<Switch
            checked={configStore.flipVertical}
            onChange={(e) => configStore.setFlipVertical(e.target.checked) }
          />}
        />

        <StyledFormControlLabel
          labelPlacement="start"
          label={`${t('appearancemenu.showstats')}:`}
          control={<Switch
            checked={configStore.showStats}
            onChange={(e) => configStore.setShowStats(e.target.checked) }
          />}
        />

        <StyledFormControlLabel
          disableTypography
          labelPlacement="top"
          label={<Box display="flex" justifyContent="space-between" width="100%" marginBottom="0.35em">
            <Typography component="span">{t('appearancemenu.focusguide.caption')}:</Typography>
          </Box>}
          control={<ButtonGroup fullWidth variant="outlined" aria-label={t('appearancemenu.focusguide.caption')}
            sx={{
              pb: 2
            }}>
            <Tooltip title={t('appearancemenu.focusguide.top')} placement="top" arrow>
              <Button onClick={() => { configStore.setFocusGuide(FocusGuide.Top); }} variant={FocusGuide.Top === configStore.focusGuide ? 'contained' : 'outlined'}><VerticalAlignTopIcon /></Button>
            </Tooltip>
            <Tooltip title={t('appearancemenu.focusguide.middle')} placement="top" arrow>
              <Button onClick={() => { configStore.setFocusGuide(FocusGuide.Middle); }} variant={FocusGuide.Middle === configStore.focusGuide ? 'contained' : 'outlined'}><VerticalAlignCenterIcon /></Button>
            </Tooltip>
            <Tooltip title={t('appearancemenu.focusguide.bottom')} placement="top" arrow>
              <Button onClick={() => { configStore.setFocusGuide(FocusGuide.Bottom); }} variant={FocusGuide.Bottom === configStore.focusGuide ? 'contained' : 'outlined'}><VerticalAlignBottomIcon /></Button>
            </Tooltip>
            <Tooltip title={t('appearancemenu.focusguide.none')} placement="top" arrow>
              <Button onClick={() => { configStore.setFocusGuide(FocusGuide.None); }} variant={FocusGuide.None === configStore.focusGuide ? 'contained' : 'outlined'}><CheckBoxOutlineBlankIcon /></Button>
            </Tooltip>
          </ButtonGroup>}
          sx={{
            marginTop: 2,
          }}
        />
      </Collapse>
    </>
  );
};

export default PrompterAppearance;