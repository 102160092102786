import React from 'react';
import {
  BaseSelection,
} from 'slate';
import { PrompterEditor } from '../../../models/EditorTypes';

import Tooltip from '@mui/material/Tooltip';
import HoveringToolbarButton from './HoveringToolbarButton';
import HoveringToolbarButtonGroup from './HoveringToolbarButtonGroup';
import Divider from '@mui/material/Divider';
import { VoiceTypingCommands } from '../useVoiceTypingCommandHandlers';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

import useFeatureFlagsStore from '../../../state/FeatureFlagsStore';

import { isMacOs, isIOS } from 'react-device-detect';
import { useAppController } from '../../../controllers/AppController';

const CtrlOrCmdString = (isMacOs || isIOS) ? 'Cmd' : 'Ctrl';

interface AlwaysAvailableToolsProps {
  editor: PrompterEditor;
  selection: BaseSelection;
  voiceTypingCommands: VoiceTypingCommands;
}

const AlwaysAvailableTools = (props: AlwaysAvailableToolsProps) => {
  const { voiceTypingCommands } = props;
  const { isVoiceTyping } = voiceTypingCommands;

  const appController = useAppController();

  const featureFlags = useFeatureFlagsStore.getState();
  const ShouldShowVoiceTools = featureFlags.voiceDictation;

  return (<>
    <Divider orientation="vertical" variant="middle" flexItem sx={{ borderColor: '#1E1E1E', borderWidth: '1.5px' }} />

    <HoveringToolbarButtonGroup>
      <Tooltip
        title={`Split Segment (${CtrlOrCmdString}+Enter)`}
        arrow
      >
        <HoveringToolbarButton
          onMouseDown={() => {
            appController.dispatchMessage('prompter.editor.segment.split');
          }}
        ><InsertPageBreakIcon/></HoveringToolbarButton>
      </Tooltip>
    </HoveringToolbarButtonGroup>

    <Divider orientation="vertical" variant="middle" flexItem sx={{ borderColor: '#1E1E1E', borderWidth: '1.5px' }} />

    <HoveringToolbarButtonGroup>
      <Tooltip
        title={`Undo (${CtrlOrCmdString}+Z)`}
        arrow
      >
        <HoveringToolbarButton
          // disabled={true}
          onMouseDown={(e) => {
            e.preventDefault();
            appController.dispatchMessage('prompter.editor.undo');
          }}
        ><UndoIcon/></HoveringToolbarButton>
      </Tooltip>
      <Tooltip
        title={`Redo (${isMacOs ? 'Shift+Cmd+Z' : 'Ctrl+Y'})`}
        arrow
      >
        <HoveringToolbarButton
          // disabled={true}
          onMouseDown={(e) => {
            e.preventDefault();
            appController.dispatchMessage('prompter.editor.redo');
          }}
        ><RedoIcon/></HoveringToolbarButton>
      </Tooltip>

      {ShouldShowVoiceTools && <Tooltip
        title={isVoiceTyping
          ? `Stop Voice Typing (${isMacOs ? 'Cmd+Shift+S' : 'Ctrl+Shift+S'})`
          : `Start Voice Typing (${isMacOs ? 'Cmd+Shift+S' : 'Ctrl+Shift+S'})`}
        arrow
      >
        <HoveringToolbarButton
          // disabled={true}
          onMouseDown={(e) => {
            e.preventDefault();
            appController.dispatchMessage(isVoiceTyping ? 'prompter.editor.voicetyping.stop' : 'prompter.editor.voicetyping.start');
          }}
        >{isVoiceTyping ? <MicOffIcon/> : <MicIcon/>}</HoveringToolbarButton>
      </Tooltip>}
    </HoveringToolbarButtonGroup>
  </>);
};

export default AlwaysAvailableTools;