import React, { useEffect } from 'react';
import { IAccountSetupRequired } from '../../hooks/useApplicationContext/hookTypes';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import CreateStripeAccount from './CreateStripeAccount';
import GatherUserReferralSource from './GatherUserReferralSource';
import GatherUserPrompterUseCase from './GatherUserPrompterUseCase';

import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import lightTheme from '../../themes/LightTheme';
import './UserSetupPage.scss';

interface UserSetupPageProps {
  accountSetupRequired: IAccountSetupRequired;
}

const UserSetupPage = (props: UserSetupPageProps) => {

  const theme = useTheme();
  const showDialogFullscreen = useMediaQuery(theme.breakpoints.down('lg'));

  let setupComponent: React.ReactNode = undefined;
  let setupStepNumber = 1;
  if(props.accountSetupRequired.missingUserUseCase) {
    // 1st priority account setup step.
    //
    // Let's ask the user what they plan to do with FluidPrompter.
    setupComponent = (<GatherUserPrompterUseCase />);
  } else if (props.accountSetupRequired.missingStripeCustomer) {
    // 2nd priority account setup step.
    //
    // We need them to checkout on stripe!
    setupStepNumber = 2;
    setupComponent = (<CreateStripeAccount />);
  } else if (props.accountSetupRequired.missingUserReferralSource) {
    // 3rd priority account setup step.
    //
    // This user does not have a recorded source for how they found FluidPrompter! (Google, 
    // YouTube, Twitter, Friend/Colleague, Other).
    //
    setupStepNumber = 3;
    setupComponent = (<GatherUserReferralSource />);
  }

  useEffect(() => { document.title = 'FluidPrompter - Account Setup'; }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog
        maxWidth='lg'
        fullWidth={true}
        fullScreen={showDialogFullscreen}
        scroll='paper'
        PaperProps={{
          sx: {
            minHeight: 'calc(100% - 64px)'
          }
        }}
        open={true}
      >
        {/*
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>*/}
        <DialogTitle>FluidPrompter Account Setup ({setupStepNumber}/3)</DialogTitle>
        {setupComponent}
        {/*<DialogActions>
          <Button onClick={handleClose}>Save</Button>
        </DialogActions>*/}
      </Dialog>
    </ThemeProvider>
  );
};

export default UserSetupPage;