import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface RenderEmptyTimerProps {
  openMenuFn: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

function RenderEmptyTimer(props: RenderEmptyTimerProps) {

  return (
    <Button 
      size="large"
      sx={{ fontSize: '1.4em', minWidth: '200px' }}
      onFocus={(e) => { e.target.blur(); }}
      onClick={props.openMenuFn}
    >
      <Typography component={'span'} fontSize='inherit' color='#ccc' sx={{
        whiteSpace: 'pre'
      }}> </Typography>
    </Button>
  );
}

export default RenderEmptyTimer;