import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Badge, { BadgeProps } from '@mui/material/Badge';

import { ShotLogEntry, ShotLogEntryType } from '@fluidprompter/core';

import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -7,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

/**
 * Convert an number of milliseconds into a human readable timestamp
 * ex: HH:mm:ss.ms
 * @param totalMiliseconds Number of milliseconds (can be a float!)
 * @returns 
 */
const elapsedTimeStringFromMiliseconds = function(totalMiliseconds?: number): string {

  const totalElapsedMs = Math.floor(totalMiliseconds || 0);
  const totalElapsedSeconds = totalElapsedMs ? totalElapsedMs / 1000 : 0;

  const elapsedHours = Math.floor((totalElapsedSeconds / (60 * 60)) % 24);
  const elapsedMinutes = Math.floor((totalElapsedSeconds / 60) % 60);
  const elapsedSeconds = Math.floor((totalElapsedSeconds) % 60);
  const elapsedMiliSeconds = totalElapsedMs ? totalElapsedMs % 1000 : 0;

  const elapsedHoursString = ('0' + elapsedHours).slice(-2);
  const elapsedMinutesString = ('0' + elapsedMinutes).slice(-2);
  const elapsedSecondsString = ('0' + elapsedSeconds).slice(-2);
  const elapsedMiliSecondsString = ('00' + elapsedMiliSeconds).slice(-3);

  return (elapsedHours > 0) ? `${elapsedHoursString}:${elapsedMinutesString}:${elapsedSecondsString}.${elapsedMiliSecondsString}` : `${elapsedMinutesString}:${elapsedSecondsString}.${elapsedMiliSecondsString}`;
};

interface ShotlogEntryRendererProps {
  shotlogEntry: ShotLogEntry,
  sessionStartTimestamp: number,
}

const ShotlogEntryRenderer = React.memo(function ShotlogEntryRenderer(props: ShotlogEntryRendererProps) {
  const { shotlogEntry, sessionStartTimestamp } = props;

  const startTimestampString = elapsedTimeStringFromMiliseconds(shotlogEntry.startTimestamp - sessionStartTimestamp);
  const finishTimestampString = elapsedTimeStringFromMiliseconds(shotlogEntry.finishTimestamp - sessionStartTimestamp);

  return (<ListItemButton
    // selected={segmentActive}
    onFocus={(e) => { e.target.blur(); }}
    // onClick={(event) => handleListItemClick(event, segmentIndex)}
    disableRipple
    disableTouchRipple
    disableGutters
  >
    <ListItemIcon>
      <StyledBadge badgeContent={shotlogEntry.implied ? 'I' : 'E'} color="primary">
        {(shotlogEntry.type === ShotLogEntryType.GoodTake) && <ThumbUpAltIcon sx={{ color: '#00b316' }} />}
        {(shotlogEntry.type === ShotLogEntryType.OkTake) && <ThumbsUpDownIcon sx={{ color: '#fac603' }} />}
        {(shotlogEntry.type === ShotLogEntryType.BadTake) && <ThumbDownAltIcon sx={{ color: '#d40201' }} />}
      </StyledBadge>
    </ListItemIcon>
    <ListItemText primary={`${finishTimestampString}`} />
  </ListItemButton>);
});

export default ShotlogEntryRenderer;