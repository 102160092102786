import React, { useState, useCallback, createRef } from 'react';
import { RenderElementProps, useFocused, useSelected } from 'slate-react';
import { IScriptNodeStateComparator, NavigateMessage } from '@fluidprompter/core';

import useResizeObserver from '@react-hook/resize-observer';
import useConfigurationStore from '../../../state/ConfigurationStore';
import FocusGuide from '../../../models/FocusGuide';

import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import RepeatIcon from '@mui/icons-material/Repeat';
import ShotLogButton from '../ShotLogModal/ShotLogButton';

import classNames from 'classnames';
import './EndElement.scss';

function EndElement(props: RenderElementProps) {
  const { attributes, children } = props;

  const focusGuide = useConfigurationStore(state => state.focusGuide);
  const flipVertical = useConfigurationStore(state => state.flipVertical);

  let marginBottom = flipVertical ? 20 : 80;
  switch(focusGuide) {
    case FocusGuide.Middle:
    case FocusGuide.None:
      marginBottom = 50;
      break;
    case FocusGuide.Bottom:
      marginBottom = flipVertical ? 80 : 20;
      break;
  }

  // We need to keep track of our endElementContainer width so that when we
  // position sticky the end element it doesn't change size.
  const [containerWidth, setContainerWidth] = useState<number>();
  const handleContainerResized = useCallback(async (e: ResizeObserverEntry) => {
    const proposedContainerWidth = e.contentRect.width;
    if(proposedContainerWidth !== containerWidth) {
      setContainerWidth(proposedContainerWidth);
    }
  }, [containerWidth]);
  useResizeObserver(attributes.ref, handleContainerResized);

  // We need to keep track of the endElementContent height so that when we
  // position sticky the end element the script doesn't reflow (change height).
  const endElementRef = createRef<HTMLDivElement>();
  const [endElementHeight, setEndElementHeight] = useState<number>();
  const handleContentResized = useCallback(async (e: ResizeObserverEntry) => {
    const proposedHeight = e.contentRect.height;
    if(proposedHeight !== endElementHeight) {
      setEndElementHeight(proposedHeight);
    }
  }, [endElementHeight]);
  useResizeObserver(endElementRef, handleContentResized);

  const [autoContinueMilliseconds, setAutoContinueMilliseconds] = useState<number | undefined>();

  const isLastPrompterElement = false;

  return (
    <div
      className={'EndElementContainer'}
      contentEditable={false}
      {...attributes}
      style={{
        minHeight: '50vh',  // This element is now just an empty space where the EndElement will be positioned off script.
        //
        // If "Subscribe to FluidPrompter!" is hidden (End Element is the LAST element in the
        // prompter) then use our calculated `vh` based marginBottom to center the EndElement
        // in the viewport.
        //
        // If there are more elements after EndElement (like the SegmentSubscribe element)
        // then we will use a static marginBottom.
        //
        paddingBottom: isLastPrompterElement ? `${marginBottom}vh` : '2em'
      }}
    >
      {/* children is necessary for Slate DOM tracking, it will be an empty string */}
      {children}
      {/*
      <div
        ref={endElementRef}
        className={classNames('EndElement', {
          isSelected: (selected && focused),
          isEndOfScript,
          // isSticky: isEndOfScriptSticky,
        })}
        style={{
          width: containerWidth
        }}
      >
        <h1>{t('endelement.defaultcaption')}{children}</h1>
        <div className={classNames('EndElementActionsInline', { isPaused })}>
          {shotloggingEnabled && <ShotLogButton
            className="ShotLogButton FlexReverse"
            // ref={menuAnchorRef}
            endIcon={<SpeakerNotesIcon />}
            disabled={isPlaying}
            highlighted={isEndOfScript}
            // aria-expanded={menuOpen ? 'true' : undefined}
            aria-label="Additional shot logging options"
            aria-haspopup="menu"
            onFocus={(e) => { e.target.blur(); }}
            onClick={() => {
              setAutoContinueMilliseconds(undefined);

              appController.dispatchMessage('prompter.shotlog.show');
            }}
          >Review<br/>Shot Log</ShotLogButton>}

          <ShotLogButton
            className="ShotLogButton FlexReverse"
            endIcon={<RepeatIcon />}
            disabled={isPlaying}
            highlighted={isEndOfScript}
            autoClickMilliseconds={autoContinueMilliseconds}
            onFocus={(e) => { e.target.blur(); }}
            onClick={() => {
              setAutoContinueMilliseconds(undefined);

              appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Start));
            }}
          >
            <Trans>{t('endelement.restartscript')}</Trans>
          </ShotLogButton>
        </div>
      </div>*/}
    </div>
  );
}

export default EndElement;