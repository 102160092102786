import React from 'react';
import { RenderLeafProps } from 'slate-react';

function TextElement(props: RenderLeafProps) {
  const { attributes, leaf } = props;
  let { children } = props;

  if (leaf.bold) {
    children = <strong {...attributes}>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em {...attributes}>{children}</em>;
  }

  if (leaf.underline) {
    children = <u {...attributes}>{children}</u>;
  }

  if (leaf.strike) {
    children = <del {...attributes}>{children}</del>;
  }

  if (leaf.highlight) {
    children = <mark {...attributes}>{children}</mark>;
  }

  if (leaf.code) {
    children = <code {...attributes}>{children}</code>;
  }

  if(leaf.masked) {
    // This content should be masked
    children = <del {...attributes}>{children}</del>;
  }

  return <span {...attributes}>{children}</span>;
}

export default TextElement;