import { useCallback } from 'react';
import { Auth0ContextInterface } from '@auth0/auth0-react';

export const useGetApiToken = (auth0Context: Auth0ContextInterface) => {
  const getApiToken = useCallback(async (): Promise<string> => {
    return await auth0Context.getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_API_URL   // 'https://app.fluidprompter.com/api',
        /*audience: 'https://dev-zek2ko1p.us.auth0.com/api/v2/',*/
        /*scope: 'read:posts',*/
      },
    });
  }, [auth0Context]);

  return getApiToken;
};