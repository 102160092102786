import React, { useEffect } from 'react';
import {
  useParams,
} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import PrompterDrawer from '../components/PrompterDrawer';
import Navbar from '../components/Navbar';
import RemoteBody from '../components/RemoteBody';

import { EndpointRole } from '@fluidprompter/core';

// import { usePageVisibility } from 'react-page-visibility';

import { isIOS, isTablet } from 'react-device-detect';
import usePrompterSession from '../state/PrompterSessionState';
import { useAppController } from '../controllers/AppController';

function RemotePage() {
  //const isPageVisible = usePageVisibility()

  //
  // Let our AppController instance know we are currently operating with the remote role, and
  // we won't have an viewport position or height and won't take the leader role.
  //
  const appController = useAppController();
  useEffect(() => {
    appController.endpointRole = EndpointRole.Remote;
  }, [appController]);

  //
  // Grab the URL parameters from react-router-dom to get our prompter session id.
  //
  const { prompterId: prompterIdParam } = useParams();
  const prompterId = prompterIdParam as string;

  React.useEffect(() => {
    usePrompterSession.getState().setPrompterId(prompterId);
  }, [prompterId]);

  // Disconnect websocket when the remote page is not visible
  // (ie: left open on your mobile phone, but switched to another browser tab).
  /*
  React.useEffect(() => {
    console.log(`Websocket Enabled = ${isPageVisible}`);
    if(remoteControlState.isWebsocketConnected !== isPageVisible) {
      remoteControlState.setWebsocketEnabled(isPageVisible);
    }
  }, [remoteControlState.isWebsocketConnected, isPageVisible]);
  */

  const viewportPortMarginBottom = 13;  // If we are on iPad, let's add some bottom margin to make room for the drag handle.
  const prompterMaskBottomHeightCollapsed = (isIOS && isTablet ? viewportPortMarginBottom : 0);

  useEffect(() => { document.title = 'FluidPrompter Remote'; }, []);

  return (
    <>
      {/* PrompterDrawer will render in portal, position fixed, zIndex 1200 */}
      <PrompterDrawer />

      <Stack
        spacing={0}
        sx={{ maxWidth: '480px', width: '100%', height: '100vh', paddingBottom: `${prompterMaskBottomHeightCollapsed}px`, margin: '0 auto' }}
      >
        {/* <RemoteHeader /> */}
        {/* Navbar will render in portal, position fixed, zIndex 1100 */}
        <Navbar />

        <Box sx={{ flexGrow: 0.5 }} />

        <RemoteBody />

        <Box sx={{ flexGrow: 0.5 }} />

        {/*<Button
            size="large"
            endIcon={ <KeyboardArrowDownIcon /> }
            sx={{ color: '#fff', padding: '8px 11px 0 11px' }}
            onFocus={(e) => { e.target.blur(); }}
          >
          Scroll Speed:
        </Button>

        <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Slider
            aria-label="Scroll Speed"
            valueLabelDisplay="auto"
            min={1}
            max={500}
            value={remoteControlState.scrollSpeed}
            onChange={handleChangeSpeed}
          />
        </Box>*/}
        <Typography
          m='5px auto'
          sx={{
            fontSize: '0.8rem',
            color: 'rgba(238,238,238,0.6)'
          }}>Prompter ID: {prompterId}</Typography>
      </Stack>
    </>
  );
}

export default RemotePage;