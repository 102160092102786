import React from 'react';
import Collapse from '@mui/material/Collapse';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LockIcon from '@mui/icons-material/Lock';

import { useTranslation } from 'react-i18next';
import { useAppController } from '../../../controllers/AppController';

interface ScriptMenuProps {
  expanded: boolean;
  onExpand: () => void;
}
const ScriptMenu = (props: ScriptMenuProps) => {
  const appController = useAppController();
  const { t } = useTranslation('appmenu');

  return (
    <>
      <ListItemButton
        selected={props.expanded}
        onClick={() => {
          props.onExpand();
        }}
      >
        <ListItemIcon><TextSnippetIcon /></ListItemIcon>
        <ListItemText
          primary={t('scriptmenu.caption')}
        />
        {props.expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{
          pb: 2,
          background: 'rgba(25, 118, 210, 0.08)',
        }}
        in={!!props.expanded} timeout="auto"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => { appController.dispatchMessage('newscript'); }}
          >
            <ListItemIcon><NoteAddIcon /></ListItemIcon>
            <ListItemText primary={t('scriptmenu.newfile')} />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => { appController.dispatchMessage('openfile'); }}
          >
            <ListItemIcon><FileOpenIcon /></ListItemIcon>
            <ListItemText
              primary={t('scriptmenu.openfile')}
              // secondary={<><LockIcon sx={{ fontSize: '1em', verticalAlign: 'text-bottom' }} />&nbsp;Free Account Required</>}
              // secondaryTypographyProps={{
              //   fontStyle: 'italic',
              // }}
            />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => { appController.dispatchMessage('savefile'); }}
          >
            <ListItemIcon><FileDownloadIcon /></ListItemIcon>
            <ListItemText primary={t('scriptmenu.downloadscript')} />
          </ListItemButton>
          <Divider />
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => { appController.dispatchMessage('openfile'); }}
          >
            <ListItemIcon><UploadFileIcon /></ListItemIcon>
            <ListItemText
              primary={t('scriptmenu.importfile')}
              /*secondary=".DOCX (Microsoft Word) or .PDF"*/
              secondary={<><LockIcon sx={{ fontSize: '1em', verticalAlign: 'middle' }} />&nbsp;{t('plans.requiresPlanLevel1', { ns: 'common' })}</>}
              secondaryTypographyProps={{
                fontStyle: 'italic',
              }}
            />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};

export default ScriptMenu;