import { BaseControlMessage } from './BaseControlMessage';
export const PAUSEMESSAGE_KEY = 'prompter.state.pause';
/**
 * Represents the PlayMessage type.
 */
export class PauseMessage extends BaseControlMessage {
    constructor(mode = PauseMessage.Mode.Immediate, position, scriptPosition) {
        super();
        this.type = PAUSEMESSAGE_KEY;
        this.mode = mode;
        this.position = position;
        this.scriptPosition = scriptPosition;
    }
    static fromJson(data) {
        if (data.type !== PAUSEMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for PauseMessage.');
        }
        const input = data;
        const instance = new PauseMessage(input.mode, undefined, input.scriptPosition);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            mode: this.mode,
            scriptPosition: this.scriptPosition,
        };
    }
}
PauseMessage.MESSAGE_NAME = PAUSEMESSAGE_KEY;
(function (PauseMessage) {
    let Mode;
    (function (Mode) {
        Mode[Mode["Immediate"] = 0] = "Immediate";
        Mode[Mode["WhenArriveAtPosition"] = 1] = "WhenArriveAtPosition";
    })(Mode = PauseMessage.Mode || (PauseMessage.Mode = {}));
})(PauseMessage || (PauseMessage = {}));
