import { useCallback } from 'react';
import { useMessageHandler } from '../../controllers/AppController';

import { Editor, Node, NodeEntry, Transforms, Path } from 'slate';
import { ReactEditor } from 'slate-react';
import { PrompterEditor, PrompterText } from '../../models/EditorTypes';

import usePrompterSession from '../../state/PrompterSessionState';
import { IViewportFuncs } from '../PrompterViewport/usePrompterViewportFuncs';

const useEditorSelectAllCommandHandler = (editor: PrompterEditor, viewportFuncs: IViewportFuncs) => {

  const doEditorSelectAll = useCallback(async () => {
    //
    // We can't do a select-all operation if we aren't in edit mode.
    //
    if(!usePrompterSession.getState().isEditing) {
      return;
    }

    //
    // Find the first leaf node in the script where we will start our selection from.
    //
    let selectionAnchor: Path = [1];
    const firstLeafNodeIterator = Editor.nodes(editor, {
      at: selectionAnchor,  // Location = BaseRange | BasePoint | Path | Span = [Path, Path]
      match: (n: Node, p: Path) => {
        // We want to find the next leaf node after our current startLocation.
        // This is a node with a 'text' value, whose path is after the current startLocation.path.
        const node = n as PrompterText;
        return (node.text !== undefined);
      },
      mode: 'highest',  //all
    });
    const iteratorResult = firstLeafNodeIterator.next();
    if(!iteratorResult.done) {
      const [, path] = iteratorResult.value;
      selectionAnchor = path;
    }

    //
    // Second, find the last leaf node in our script where we will end our selection.
    //    
    // let nextEndNodePath: Path = [props.document.length - 2];
    let nextEndNodePath: Path = [editor.children.length - 2];
    const nextLeafNodeIterator = Editor.nodes(editor, {
      at: nextEndNodePath,  // Location = BaseRange | BasePoint | Path | Span = [Path, Path]
      match: (n: Node, p: Path) => {
        // We want to find the previous leaf node before our current endLocation.
        // This is a node with a 'text' value, whose path is before the current endLocation.path.
        const node = n as PrompterText;
        return (node.text !== undefined);
      },
      mode: 'all',
    });

    let nextLeafNode: IteratorResult<NodeEntry<Node>, void>;
    let nextEndNodeLength = 0;
    do {
      nextLeafNode = nextLeafNodeIterator.next();
      if (!nextLeafNode.done) { // Using Type discrimination to make sure we have a value: https://github.com/microsoft/TypeScript/issues/33353
        const [node, path] = nextLeafNode.value;
        nextEndNodePath = path;
        nextEndNodeLength = (node as PrompterText).text.length;
      }
    } while (!nextLeafNode.done);

    // console.log(`SelectAll from ${selectionAnchor} to ${nextEndNodePath}, ${nextEndNodeLength}`);

    Transforms.select(editor, { path: selectionAnchor, offset: 0 });
    ReactEditor.focus(editor);
    Transforms.setSelection(editor, {
      anchor: { path: selectionAnchor, offset: 0 }, 
      focus: { path: nextEndNodePath, offset: nextEndNodeLength } }
    );    
  }, [editor]);

  useMessageHandler('prompter.editor.selectall', () => {
    viewportFuncs.queueSequentialTask(async () => {
      await doEditorSelectAll();
    });
  });

};

export default useEditorSelectAllCommandHandler;