import BaseDevice from '../BaseDevice';
import { BaseDeviceEvent } from './BaseDeviceEvent';

export class DeviceConnectedEvent
  extends BaseDeviceEvent<Event>
{
  readonly type = 'DeviceConnectedEvent';

  constructor(
    device: BaseDevice, 
  ) {
    super(device, new Event('SyntheticDeviceConnectedEvent'));
  }
}