export var ScriptNodeTypes;
(function (ScriptNodeTypes) {
    ScriptNodeTypes["STARTSCRIPT"] = "startscript";
    ScriptNodeTypes["ENDSCRIPT"] = "endscript";
    ScriptNodeTypes["SCRIPT_SEGMENT"] = "promptersegment";
    ScriptNodeTypes["PAUSE"] = "pause";
    ScriptNodeTypes["PARAGRAPH"] = "paragraph";
    ScriptNodeTypes["HYPERLINK"] = "hyperlink";
    ScriptNodeTypes["BROLL"] = "broll";
    ScriptNodeTypes["IMAGE"] = "image";
    ScriptNodeTypes["CODE_BLOCK"] = "inline-code";
    ScriptNodeTypes["BLOCK_QUOTE"] = "block-quote";
})(ScriptNodeTypes || (ScriptNodeTypes = {}));
