/**
 * © Copyright 2023 fluidprompter.com
 */

export const enum EnvType {
  WEB = 'WEB',
  IPC = 'IPC'
}

export const enum Module {
  CTRL = 'CTRL',
  WEB = 'WEB',
  BLE = 'BLE',
  AUTH = 'AUTH',
  IAP = 'IAP',
  USB = 'USB',
}

export const enum Control {
  GetEnv = 'getEnv',
}

export const enum BleCommand {
  GetState = 'getState',
  StartScanning = 'startScanning',
  StopScanning = 'stopScanning',
  Connect = 'connect',
  Disconnect = 'disconnect',
  UpdateRssi = 'updateRssi',
  DiscoverServices = 'discoverServices',
  Read = 'read',
  Write = 'write',
  Notify = 'notify',
  DiscoverDescriptors = 'discoverDescriptors',
  ReadValue = 'readValue',
  WriteValue = 'writeValue',
  AddListener = 'addListener',
  RemoveListener = 'removeListener',
}

export const enum AuthCommand {
  Command = 'tbd',
}

export const enum IapCommand {
  Command = 'tbd',
}

export const enum UsbCommand {
  Command = 'tbd',
}

export const enum Status {
  Success = 'success',
  Failed = 'failed',
}

export interface IRequestCommandData {
  command: BleCommand | AuthCommand | IapCommand | UsbCommand | Control;
  arguments?: {[key: string]: string | number | boolean};
}

export interface IAppMessage {
  requestId: string;
  code?: number;
  source: Module;
  destination: Module;
  data: IRequestCommandData | unknown;
  status?: Status;
}

export function isRequestCommandData(data?: IRequestCommandData | unknown): data is IRequestCommandData {
  return (data as IRequestCommandData).command !== undefined;
}

export interface IEnvTypeMessage {
  type: EnvType;
}