import { useRef, useCallback, useEffect } from 'react';
import { Editor, Range, Transforms, Point, NodeEntry } from 'slate';
import { EditMessage, PauseMessage, PlayMessage } from '@fluidprompter/core';

import { PrompterEditor, PrompterText } from '../../models/EditorTypes';
import { IViewportFuncs } from '../PrompterViewport/usePrompterViewportFuncs';

import { useAppController, useMessageHandler } from '../../controllers/AppController';
import useFeatureFlagsStore from '../../state/FeatureFlagsStore';
import useSpeechRecognitionState from '../../state/SpeechRecognitionState';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

// const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition;
const voiceSupported = ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window);
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
// const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;

export interface VoiceTypingCommands {
  isVoiceTyping: boolean;
  startVoiceTyping: () => void;
  stopVoiceTyping: () => void;
}

const useVoiceTypingCommandHandlers = (editor: PrompterEditor, viewportFuncs: IViewportFuncs): VoiceTypingCommands => {

  const appController = useAppController();

  const featureFlags = useFeatureFlagsStore(state => ({
    voiceDictation: state.voiceDictation,
  }), shallow);

  const contentInProgress = useRef<NodeEntry<PrompterText>>();

  const speechRecognitionState = useSpeechRecognitionState(state => ({
    active: state.active,
    setActive: state.setActive,

    // audioActive: state.audioActive,
    setAudioActive: state.setAudioActive,

    // speechActive: boolean;
    setSpeechActive: state.setSpeechActive,
  }), shallow);

  const recognitionRef = useRef<SpeechRecognition>();

  const onSpeechResult = useCallback((e: Event) => {
    const recognitionEvent = e as SpeechRecognitionEvent;

    const recognitionResults = Array.from(recognitionEvent.results);

    const allFinal = recognitionResults
      .map((speechRecognitionResult) => speechRecognitionResult.isFinal)
      .reduce((previousIsFinal, currentIsFinal) => (previousIsFinal && currentIsFinal));

    const transcript = Array.from(recognitionEvent.results) // result.isFinal
      .map((result) => result[0])
      .map((result) => result.transcript)
      .join('') + ' ';


    // If we have an empty transcript, don't do anything.
    if(transcript.trim().length === 0) {
      return;
    }

    console.log(`onResult ${allFinal ? '(FINAL)' : ''}: ${transcript}`, recognitionEvent);
    const nodeEntryInProgress = contentInProgress.current;

    if(allFinal) {
      // console.log(`onResult ${allFinal ? '(FINAL)' : ''}: ${transcript}`, recognitionEvent);

      const commandString = transcript.toLowerCase();
      if('prompter start' === commandString.substring(0, 15)) {
        appController.dispatchMessage(new PlayMessage());

        if(nodeEntryInProgress) {
          const [editorNodeInProgress, pathInProgress] = nodeEntryInProgress;
          Transforms.removeNodes(editor, {
            at: pathInProgress
          });
          contentInProgress.current = undefined;
        }
        return;
      }
      // Pause, prompting. 
      if('prompter pause' === commandString.substring(0, 16)) {
        appController.dispatchMessage(new PauseMessage());

        if(nodeEntryInProgress) {
          const [editorNodeInProgress, pathInProgress] = nodeEntryInProgress;
          Transforms.removeNodes(editor, {
            at: pathInProgress
          });
          contentInProgress.current = undefined;
        }
        return;
      }
      if('prompter stop' === commandString.substring(0, 14)) {
        appController.dispatchMessage(new EditMessage());

        if(nodeEntryInProgress) {
          const [editorNodeInProgress, pathInProgress] = nodeEntryInProgress;
          Transforms.removeNodes(editor, {
            at: pathInProgress
          });
          contentInProgress.current = undefined;
        }
        return;
      }
      if('undo' === commandString.substring(0, 4)) {
        appController.dispatchMessage('prompter.editor.undo');

        if(nodeEntryInProgress) {
          const [editorNodeInProgress, pathInProgress] = nodeEntryInProgress;
          console.log('Editor node in progress', editorNodeInProgress, pathInProgress);
          Transforms.removeNodes(editor, {
            at: pathInProgress
          });
          contentInProgress.current = undefined;
        }
        return;
      }
    }

    //
    // If we got here, no "command" was detected.
    //

    //
    // Do we need to finalize some editor content in progress?
    //
    if(nodeEntryInProgress) {
      // Finalize the node in progress!

      const [editorNodeInProgress, pathInProgress] = nodeEntryInProgress;

      //
      // When we remove the `masked` attribute from contentInProgress it "may" get normalized with 
      // a prior leaf node if one exists which will make our pathInProgress become stale after 
      // normalization.
      //
      Editor.withoutNormalizing(editor, () => {
        // Replace the text content in the current node at pathInProgress.
        Transforms.insertText(editor, transcript, {
          at: pathInProgress,
        });

        // Update the leaf node attributes as to whether this content is masked or not masked.
        const finalNode: PrompterText = { ...editorNodeInProgress, masked: !allFinal };
        Transforms.setNodes<PrompterText>(
          editor,
          finalNode,
          {
            at: pathInProgress,
          }
        );


        // If we cleared the mask

        // Place the text caret at the end of the content in progress.
        Transforms.select(editor, { path: pathInProgress, offset: transcript.length });
      });

      // If this content is finalized... then we want to start a new content in progress next time we talk.
      if(allFinal) {
        contentInProgress.current = undefined;
      }
      return;
    }

    //
    // Do we need to create a new editor node?
    //
    if(!nodeEntryInProgress && editor.selection) {
      // Split the current prompter segment.
      // if (!usePrompterSession.getState().isEditing) {
      //   // We can't do anything unless we are currently editing a segment.
      //   return;
      // }

      //
      // If we have a range selection, first delete this range before performing the split.
      //
      if (editor.selection && Range.isExpanded(editor.selection)) {
        // If we have a range selection, first delete this range.
        Transforms.delete(editor);
      }

      // const targetEditorPoint = editor.selection.focus;
      const targetEditorPoint = Range.isBackward(editor.selection) ? editor.selection.focus : editor.selection.anchor;
      const currentLeafNodeEntry = Editor.node(editor, targetEditorPoint /*, {
        edge: 'end'
      }*/);
      if(!currentLeafNodeEntry) {
        // Couldn't find a current leaf node at the current selection. 
        console.log('Couldn\'t find a current leaf node at the current selection.');
        return;
      }


      // const segmentNodeEntry = Editor.above<Paragraph>(editor, {match: n => (n as Paragraph).type === ElementTypes.PARAGRAPH})
      // if(!segmentNodeEntry) {
      //   // Couldn't find a Paragraph wrapping the current selection. 
      //   return true;
      // }

      const [currentLeafNode, currentLeafPath] = currentLeafNodeEntry;
      const segmentEnd = Editor.end(editor, currentLeafPath);
      const isAtLeafEnd = Point.equals(segmentEnd, targetEditorPoint);
      if(isAtLeafEnd) {
        console.log(`isAtLeafEnd=${isAtLeafEnd}`);
      }

      const proposedNode: PrompterText = {
        text: transcript,
        masked: true,
      };

      Editor.withoutNormalizing(editor, () => {
        if(!editor.selection) {
          return;
        }

        const insertAt = Range.isBackward(editor.selection) ? editor.selection.focus : editor.selection.anchor;
        Transforms.insertNodes<PrompterText>(editor, proposedNode, {
          at: insertAt,
        });

        const insertPath = [...insertAt.path];
        const lastPathSegment = insertPath.pop();
        if(lastPathSegment !== undefined) {
          insertPath.push(
            targetEditorPoint.offset === 0  // lastPathSegment === 0 || 
              ? 0
              : lastPathSegment + 1
          );
        }
        
        //const insertedPath = (targetEditorPoint.offset === 0) ? insertAt.path : Path.next(insertAt.path);


        console.log(`Path before insert: ${insertAt.path}, Path after inserted: ${insertPath}`, currentLeafNode, proposedNode);
        
        Transforms.select(editor, {
          path: insertPath,
          offset: transcript.length,
        });
        contentInProgress.current = [proposedNode, insertPath];
      });
    }

    
    // contentInProgress.current = undefined;

    // const freshpath = ReactEditor.findPath(editor as ReactEditor, prompterSegmentElement);

    // // Insert the proposedNode after the current node.
    // const currentNodeIndex = freshpath.shift();
    // if(currentNodeIndex !== undefined) {
    //   freshpath.unshift(currentNodeIndex + 1);
    // }

    // Transforms.insertNodes<Descendant>(editor, node, {
    //   at: freshpath,
    // });
    // Transforms.select(editor, freshpath);
  }, [editor, appController]);

  const startVoiceTyping = useCallback(async () => {
    if(!featureFlags.voiceDictation) {
      console.log('Speech Recognition feature is not available.');
      return;
    }

    if(!voiceSupported) {
      alert('Speech Recognition is not supported in this browser. Please try Chrome or Edge on desktop.');
      return;
    }

    // We can only do voice dictation while editing.
    if(!usePrompterSession.getState().isEditing) {
      return;
    }

    //let speechRecognition = new webkitSpeechRecognition();
    //var recognition = new SpeechRecognition();
    const recognition = new SpeechRecognition();
    recognitionRef.current = recognition;

    // const speechRecognitionList = new SpeechGrammarList();
    // speechRecognitionList.addFromString(grammar, 1);
    // recognition.grammars = speechRecognitionList;
  
    // recognition.continuous = true;
    recognition.lang = 'en-US';
    recognition.interimResults = true;
    // recognition.maxAlternatives = 1;

    // onaudiostart: (ev: Event) => any;
    recognition.addEventListener('audiostart', () => {
      // console.log(`onaudiostart:`, e);
      speechRecognitionState.setAudioActive(true);
    });

    // onsoundstart: (ev: Event) => any;
    recognition.addEventListener('soundstart', () => {
      // console.log(`onsoundstart:`, e);
    });

    // onspeechstart: (ev: Event) => any;
    recognition.addEventListener('speechstart', () => {
      // console.log(`onspeechstart:`, e);
      speechRecognitionState.setSpeechActive(true);
    });

    // onspeechend: (ev: Event) => any;
    recognition.addEventListener('speechend', () => {
      // console.log(`onspeechend:`, e);
      speechRecognitionState.setSpeechActive(false);
    });

    // onsoundend: (ev: Event) => any;
    recognition.addEventListener('soundend', () => {
      // console.log(`onsoundend:`, e);
    });

    // onaudioend: (ev: Event) => any;
    recognition.addEventListener('audioend', () => {
      // console.log(`onaudioend:`, e);
      speechRecognitionState.setAudioActive(false);
    });

    // onresult: (ev: SpeechRecognitionEvent) => any;
    recognition.addEventListener('result', onSpeechResult);

    // onnomatch: (ev: SpeechRecognitionEvent) => any;
    // onerror: (ev: SpeechRecognitionError) => any;
    recognition.addEventListener('error', (e: Event) => {
      // no-speech
      const errorEvent = e as SpeechRecognitionError;
      console.log('onerror:', errorEvent);

      switch (errorEvent.error) {
        case 'no-speech':
          // The user didn't say anything during a set timeout.
          break;
        case 'network':
          break;
        case 'not-allowed':
        case 'service-not-allowed':
          // if permission to use the mic is denied, turn off speech recognition (don't 
          // auto-restart the recognition engine).
          speechRecognitionState.setActive(false);
          break;
      }
    });

    // onstart: (ev: Event) => any;
    recognition.addEventListener('start', () => {
      // console.log(`onstart:`, e);
    });

    // onend: (ev: Event) => any;
    recognition.addEventListener('end', () => {
      const speechRecognitionEnabled = useSpeechRecognitionState.getState().active;
      // console.log(`onend (active=${speechRecognitionEnabled}):`, e);

      // Continue speech recognition...
      if(speechRecognitionEnabled) {
        recognition.start();
        // console.log('recognition restarted after end');
      }
    });

    /*recognition.onresult = (e: Event) => {
      const recognitionEvent = e as SpeechRecognitionEvent;
      console.log(`onResult: ${event}`);
      // const color = event.results[0][0].transcript;
      // diagnostic.textContent = `Result received: ${color}.`;
      // bg.style.backgroundColor = color;
      // console.log(`Confidence: ${event.results[0][0].confidence}`);
    }*/
    
    recognition.start();

    speechRecognitionState.setActive(true);
  }, [featureFlags.voiceDictation, recognitionRef, speechRecognitionState, onSpeechResult]);
  useMessageHandler('prompter.editor.voicetyping.start', startVoiceTyping);

  const stopVoiceTyping = useCallback(async () => {
    const recognition = recognitionRef.current;
    if(recognition) {
      recognition.stop();

      recognition.removeEventListener('result', onSpeechResult);

      recognitionRef.current = undefined;
    }
    speechRecognitionState.setActive(false);
  }, [recognitionRef, speechRecognitionState, onSpeechResult]);
  useMessageHandler('prompter.editor.voicetyping.stop', stopVoiceTyping);

  useMessageHandler('prompter.editor.voicetyping.toggle', speechRecognitionState.active ? stopVoiceTyping : startVoiceTyping);

  //
  // If voice dictation becomes disabled, make sure we clean-up by stopping voice dictation.
  //
  useEffect(() => {
    if(!featureFlags.voiceDictation) {
      return;
    }

    // return clean-up function
    return () => {
      stopVoiceTyping();
    };
  }, [featureFlags.voiceDictation, stopVoiceTyping]);

  return {
    isVoiceTyping: speechRecognitionState.active,
    startVoiceTyping,
    stopVoiceTyping,
  };
};

export default useVoiceTypingCommandHandlers;