import BaseDevice from '../BaseDevice';
import { BaseDeviceEvent } from './BaseDeviceEvent';

export class DeviceReportEvent extends BaseDeviceEvent<Event> {
  readonly type = 'DeviceReportEvent';

  constructor(
    device: BaseDevice, 
  ) {
    super(device, new Event('SyntheticDeviceReportEvent'));
  }
}