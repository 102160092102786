import DeviceHost from '../../DeviceHost';
import BaseDevice, {
  DeviceComponent,
  DeviceAxisConfig,
  DeviceConnectionType,
} from '../../BaseDevice';
import { DeviceConnectedEvent } from '../../events/DeviceConnectedEvent';
import { DeviceDisconnectedEvent } from '../../events/DeviceDisconnectedEvent';
import { DeviceAxisEvent } from '../../events/DeviceAxisEvent';
import { DeviceButtonEvent } from '../../events/DeviceButtonEvent';

import DigitalButtonStateMachine, { DigitalButtonConfig } from '../../common/DigitalButtonStateMachine';
import AnalogInputStateMachine, { AnalogInputStateMachineConfigInput, AnalogInputChangedEventArgs } from '../../common/AnalogInputStateMachine';

import RemoteGamepadIcon from './images/remote-gamepad-icon.png';
import GenericGamepadUI from './UI';

type DeviceAxisEventOrUndefined = DeviceAxisEvent | undefined;

const GENERICGAMEPAD_TYPE = 'genericgamepad';

class GenericGamepadRemote extends BaseDevice {
  readonly type = GENERICGAMEPAD_TYPE;

  public static readonly DEVICE_TYPE: string = GENERICGAMEPAD_TYPE;

  private _gamepad: Gamepad;
  private _buttons: DigitalButtonStateMachine[];
  private _axes: AnalogInputStateMachine[];
  private _previousAxisEvent: DeviceAxisEventOrUndefined[] = [undefined, undefined, undefined, undefined];

  constructor(deviceHost: DeviceHost, gamepad: Gamepad) {
    super(deviceHost);
    this.icon = RemoteGamepadIcon;
    this.name = 'Generic Gamepad';
    this.connectionType = DeviceConnectionType.Gamepad;

    this._gamepad = gamepad;
    this._buttons = [];
    this._axes = [];

    const buttonConfigs: DigitalButtonConfig[] = [{
      name: 'ok', // button1 - Gamepad B
      bitmask: 1,
    }, {
      name: 'button2',  // Gamepad A
      bitmask: 1,
    }, {
      name: 'button3',  // Gamepad Y
      bitmask: 1,
    }, {
      name: 'button4',  // Gamepad X
      bitmask: 1,
    }, {
      name: 'button5',  // L1
      bitmask: 1,
    }, {
      name: 'button6',  // R1
      bitmask: 1,
    }, {
      name: 'button7',  // L2
      bitmask: 1,
    }, {
      name: 'button8',  // R2
      bitmask: 1,
    }, {
      name: 'button9',  // Select
      bitmask: 1,
    }, {
      name: 'button10', // Start
      bitmask: 1,
    }, {
      name: 'button11', //
      bitmask: 1,
    }, {
      name: 'button12',
      bitmask: 1,
    }, {
      name: 'up',     // button13 - Gamepad Up
      bitmask: 1,
    }, {
      name: 'down',   // button14 - Gamepad Down
      bitmask: 1,
    }, {
      name: 'left',   // button15 - Gamepad Left
      bitmask: 1,
    }, {
      name: 'right',  // button16 - Gamepad Right
      bitmask: 1,
    }, {
      name: 'button17',
      bitmask: 1,
    }, {
      name: 'button18',
      bitmask: 1,
    }];
    for (let i = 0; i < this._gamepad.buttons.length; i++) {
      const btnInstance = new DigitalButtonStateMachine(buttonConfigs[i], (buttonName: string, eventType: string) => {
        // const eventName = `${buttonName}.${eventType}`;
        // console.log(`GamepadRemote.onButtonEvent ${eventName}`);
        // this.emit("inputevent", eventName);
        this.emit('buttonreport', new DeviceButtonEvent(this, buttonName, eventType));
      });
      this._buttons.push(btnInstance);
    }

    const axesConfigs: AnalogInputStateMachineConfigInput[] = [{
      name: 'axes1',
      roundOutput: true,
    }, {
      name: 'axes2',
      roundOutput: true,
    }, {
      name: 'axes3',
      roundOutput: true,
    }, {
      name: 'axes4',
      roundOutput: true,
      // sourceMinimum: 0,
      // sourceMaximum: 1,
      neutralMaximum: 0.004,
      neutralMinimum: -0.004,
      // outputMinimum: 50,
      // outputMaximum: 450,
    }];

    const axisConfig = new DeviceAxisConfig();
    axisConfig.valueMinimum = -32767;
    axisConfig.valueMinimumTolerance = 135;
    axisConfig.valueNeutral = 0;
    axisConfig.valueNeutralTolerance = 32;
    axisConfig.valueMaximum = 32767;  // signed int32 maximum value
    axisConfig.valueMaximumTolerance = 135;

    for (let i = 0; i < this._gamepad.axes.length; i++) {
      const analogAxesState = new AnalogInputStateMachine(axesConfigs[i], (e: AnalogInputChangedEventArgs) => {
        // const eventName = `${e.name}.${e.value}`;
        if(e.name === 'axes4') {
          console.log(`GamepadRemote.onInputEvent ${e.name} from ${e.previousValue} to ${e.value}`);

          const axisEvent = new DeviceAxisEvent(this, 0, axisConfig, e, e.value, this._previousAxisEvent[i]);

          // Save our new AnalogPosition/Value for use in the next event to see what changed.
          this._previousAxisEvent[i] = axisEvent;

          this.emit('axisevent', axisEvent);
        }
      });

      this._axes.push(analogAxesState);
    }
  }

  async connect() {
    this.emit('connected', new DeviceConnectedEvent(this));
  }

  async disconnect() {
    // Make sure all button/axes state machines are cleared.
    for (let i = 0; i < this._gamepad.buttons.length; i++) {
      this._buttons[i].processState(0);
    }
    for (let i = 0; i < this._gamepad.axes.length; i++) {
      this._axes[i].processState(0);
    }

    // Let our DeviceHost know we have been disconnected.
    this.emit('disconnected', new DeviceDisconnectedEvent(this, true));
  }

  processButtonState(buttonIndex: number, buttonState: boolean) {
    const buttonInstance = this._buttons[buttonIndex];
    buttonInstance.processState(buttonState ? 1 : 0);
  }

  processGamepadState() {
    const myGamepad = navigator.getGamepads()[this._gamepad.index];
    if(!myGamepad) {
      return;
    }

    for (let i = 0; i < this._gamepad.buttons.length; i++) {
      this._buttons[i].processState(myGamepad.buttons[i].pressed ? 1 : 0);
    }

    for (let i = 0; i < this._gamepad.axes.length; i++) {
      const axesValue = myGamepad.axes[i].valueOf();
      this._axes[i].processState(axesValue);
    }
  }

  getDeviceUIComponent(): DeviceComponent {
    return GenericGamepadUI;
  }
}

export default GenericGamepadRemote;