import { useCallback } from 'react';
import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { useMessageHandler, MessageHandlerEvent } from '../../controllers/AppController';
import { EditMessage, EndpointRole } from '@fluidprompter/core';
import { IViewportFuncs } from './usePrompterViewportFuncs';

function usePrompterBeginEditHandler(viewportFuncs: IViewportFuncs) {

  const doEdit = useCallback(async function (e: MessageHandlerEvent<EditMessage>) {
    const { message, originatedRemotely } = e;
    const { sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);

    e.sendToPeers = !originatedRemotely || (senderIsRemote && isLeader);

    viewportFuncs.queueSequentialTask(async () => {
      const requestReversed = (sender?.scrollReversed === true);
      const configState = useConfigurationStore.getState();
      if(configState.scrollReversed !== requestReversed) {
        configState.setScrollReversed(requestReversed);
      }

      const localPrompterSession = usePrompterSession.getState();
      if(!localPrompterSession.isEditing) {
        localPrompterSession.edit();
      }
    });

  }, []);
  useMessageHandler('prompter.state.edit', doEdit);

  return doEdit;
}

export default usePrompterBeginEditHandler;