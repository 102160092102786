import { BaseControlMessage } from './BaseControlMessage';
export const EDITORACTIVEMESSAGE_KEY = 'editor.active';
/**
 * Represents the EditorActiveMessage type.
 */
export class EditorActiveMessage extends BaseControlMessage {
    constructor() {
        super(...arguments);
        this.type = EDITORACTIVEMESSAGE_KEY; // 'prompter.state.edit';
    }
    static fromJson(data) {
        const instance = new EditorActiveMessage();
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
        };
    }
}
EditorActiveMessage.MESSAGE_NAME = EDITORACTIVEMESSAGE_KEY;
