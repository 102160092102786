import React, { useEffect, useRef } from 'react';
import Collapse from '@mui/material/Collapse';
// import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';

// import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
// import KeyboardIcon from '@mui/icons-material/Keyboard';
// import MouseIcon from '@mui/icons-material/Mouse';
import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useTranslation } from 'react-i18next';

import useConfigurationStore from '../../../state/ConfigurationStore';
import { shallow } from 'zustand/shallow';

import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

// Left align the label relative to the enclosed form input element.
// https://stackoverflow.com/a/56828472
const StyledFormControlLabel = styled(FormControlLabel)({
  width: '100%',
  alignItems: 'center', // 'start',
  justifyContent: 'space-between',
  marginLeft: 0,
});

const StyledFormHelperText = styled(FormHelperText)({
  marginTop: 0,
  marginBottom: 12,
});

interface MomentarySettingsCanvasProps {
  config: unknown
}
const MomentarySettingsCanvas = (props: MomentarySettingsCanvasProps) => {
  const { config } = props;
  
  const canvasRef = useRef<HTMLCanvasElement>(null);
  
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if(!context) {
      return;
    }

    //Our first draw
    context.fillStyle = '#000000';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
  }, [config]);
  
  return (<canvas ref={canvasRef} />);
};

interface MomentarySettingsProps {
  expanded: boolean;
  onExpand: () => void;
}
const MomentarySettings = (props: MomentarySettingsProps) => {
  const { t } = useTranslation('appmenu');

  const momentaryConfig = useConfigurationStore(state => ({
    AttackGain: state.momentaryAttackGain,
    AttackMs: state.momentaryAttackTime,
    AttackEasingFunction: state.momentaryAttackFunction,
    setMomentaryAttackGain: state.setMomentaryAttackGain,

    HoldMs: state.momentaryHoldTime,
    
    DecayMs: state.momentaryDecayTime,
    DecayEasingFunction: state.momentaryDecayFunction,
    
    ReleaseMs: state.momentaryReleaseTime,
    ReleaseEasingFunction: state.momentaryReleaseFunction,
  }), shallow);

  return (
    <>
      <ListItemButton
        selected={props.expanded}
        onClick={() => {
          props.onExpand();
        }}
      >
        <ListItemText primary={t('controlsmenu.momentarysettings.caption')} />
        {props.expanded ? <ExpandLess /> : <ChevronRightIcon />}
      </ListItemButton>
      <Collapse
        sx={{
          pt: 0,
          pr: 4,
          pb: 2,
          pl: 2,
          background: 'rgba(25, 118, 210, 0.08)',
        }}
        in={!!props.expanded} timeout="auto"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {/* <MomentarySettingsCanvas config={momentaryConfig} /> */}
        <StyledFormControlLabel
          disableTypography
          labelPlacement="top"
          label={<Box display="flex" justifyContent="space-between" width="100%">
            <Typography component="span">{t('controlsmenu.momentarysettings.attackgain')}:</Typography>
            <Typography component="span">{momentaryConfig.AttackGain}</Typography>
          </Box>}
          control={<Slider aria-label={t('appearancemenu.scrollspeed')}
            valueLabelDisplay="off"
            min={1.0}
            max={3.0}
            step={0.25}
            value={momentaryConfig.AttackGain}
            onChange={(e, newValue) => {
              const proposedScrollSpeed = newValue as number;
              momentaryConfig.setMomentaryAttackGain(proposedScrollSpeed);
            }}
          />}
          sx={{
            marginTop: 2,
          }}
        />

      </Collapse>
    </>
  );
};

export default MomentarySettings;