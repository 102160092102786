import React, { useRef } from 'react';
// import AppUpdatesDialog from '../AppUpdatesDialog';
import { useMessageHandler } from '../../controllers/AppController';

import useServiceWorker from '../../hooks/useServiceWorker';

import { Id, toast, ToastContentProps } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

// export declare type ToastContent<T = unknown> = React.ReactNode | ((props: ToastContentProps<T>) => React.ReactNode);

const Msg = (props: ToastContentProps<unknown>) => (
  <div>
    App update available! You must fully close and reopen fluid prompter to apply the updates.
    <button onClick={props.closeToast}>Close</button>
  </div>
);

function PwaManager() {

  const confirm = useConfirm();

  const confirmUpdateCallback = React.useCallback(async function (applyUpdate: () => void) {
    confirm({
      title: 'FluidPrompter Update Available',
      description: 'An app update is available! Would you like to apply the update now?',
      confirmationText: 'Update Now!',
      // confirmationButtonProps: {},
      cancellationText: 'Update Later',
      // cancellationButtonProps: {},
      allowClose: false,
    }).then(() => {
      applyUpdate();
    }).catch(() => {
      /* Don't apply the PWA update now. */
    });
  }, [confirm]);

  const {
    checkUpdate,
    updateWaiting,
    applyUpdate,
  } = useServiceWorker(confirmUpdateCallback);

  const checkForUpdates = async () => {
    if(updateWaiting) {
      applyUpdate();
      return;
    }

    const checkedSuccessfully = await checkUpdate();
    if(checkedSuccessfully) {
      alert('Successfully checked for app updates.\rNext time you close and reopen the browser the latest version should be installed.');
      return;
    }

    // This browser does not support service workers (ie: FireFox)
    // Or we are running in a development environment where the service worker is not installed.
    alert('App is not currently cached as a PWA.\rReloading the page will get the latest version!');
  };
  useMessageHandler('pwa.checkforupdates', checkForUpdates);

  // return (<AppUpdatesDialog />);
  return (<></>);
}

export default PwaManager;