import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Badge, { BadgeProps } from '@mui/material/Badge';

import { ShotLogEntry, ShotLogEntryType } from '@fluidprompter/core';

import { styled } from '@mui/material/styles';

/**
 * Convert an number of milliseconds into a human readable timestamp
 * ex: HH:mm:ss.ms
 * @param totalMiliseconds Number of milliseconds (can be a float!)
 * @returns 
 */
const elapsedTimeStringFromMiliseconds = function(totalMiliseconds?: number): string {

  const totalElapsedMs = Math.floor(totalMiliseconds || 0);
  const totalElapsedSeconds = totalElapsedMs ? totalElapsedMs / 1000 : 0;

  const elapsedHours = Math.floor((totalElapsedSeconds / (60 * 60)) % 24);
  const elapsedMinutes = Math.floor((totalElapsedSeconds / 60) % 60);
  const elapsedSeconds = Math.floor((totalElapsedSeconds) % 60);
  const elapsedMiliSeconds = totalElapsedMs ? totalElapsedMs % 1000 : 0;

  const elapsedHoursString = ('0' + elapsedHours).slice(-2);
  const elapsedMinutesString = ('0' + elapsedMinutes).slice(-2);
  const elapsedSecondsString = ('0' + elapsedSeconds).slice(-2);
  const elapsedMiliSecondsString = ('00' + elapsedMiliSeconds).slice(-3);

  return (elapsedHours > 0) ? `${elapsedHoursString}:${elapsedMinutesString}:${elapsedSecondsString}.${elapsedMiliSecondsString}` : `${elapsedMinutesString}:${elapsedSecondsString}.${elapsedMiliSecondsString}`;
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -7,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

interface ShotLogTableProps {
  shotlogEntries: ShotLogEntry[];
}

const ShotLogTable = (props: ShotLogTableProps) => { 

  const { shotlogEntries } = props;

  // Find the origin timestamp that will be subtract from other timestamps to get relative 
  // distance from start of shoot.
  let sessionStartTimestamp = shotlogEntries.length ? (shotlogEntries[0].startTimestamp || shotlogEntries[0].finishTimestamp) : 0;
  for (let i = 1; i < shotlogEntries.length; i++) {
    const thisEntry = shotlogEntries[i];
    if (thisEntry.startTimestamp < sessionStartTimestamp) {
      sessionStartTimestamp = thisEntry.startTimestamp;
    }
  }

  return (<>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="left" width={'100%'}>Title</TableCell>
            <TableCell align="left">Take</TableCell>
            {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {shotlogEntries.map((shotlogEntry, entryIndex) => {
            const startTimestampString = elapsedTimeStringFromMiliseconds(shotlogEntry.startTimestamp - sessionStartTimestamp);
            const finishTimestampString = elapsedTimeStringFromMiliseconds(shotlogEntry.finishTimestamp - sessionStartTimestamp);
            // console.log(`${finishTimestampString}`, shotlogEntry);

            return (<TableRow
              key={`shotLogEntry${entryIndex}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {startTimestampString}
              </TableCell>
              <TableCell component="th" scope="row">
                {finishTimestampString}
              </TableCell>
              <TableCell align="center">
                <StyledBadge badgeContent={shotlogEntry.implied ? 'I' : 'E'} color="primary">
                  {(shotlogEntry.type === ShotLogEntryType.GoodTake) && <ThumbUpAltIcon sx={{ color: '#00b316' }} />}
                  {(shotlogEntry.type === ShotLogEntryType.OkTake) && <ThumbsUpDownIcon sx={{ color: '#fac603' }} />}
                  {(shotlogEntry.type === ShotLogEntryType.BadTake) && <ThumbDownAltIcon sx={{ color: '#d40201' }} />}
                </StyledBadge>
              </TableCell>
              <TableCell align="left" width={'100%'}>{shotlogEntry.title ? shotlogEntry.title : (<i>Untitled Segment</i>)}</TableCell>
              <TableCell align="left">{shotlogEntry.take}</TableCell>
              {/* <TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>);
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </>);
};

export default ShotLogTable;