import React from 'react';
import { ReactEditor, useSlateStatic, RenderElementProps, useFocused, useSelected } from 'slate-react';
import { Hyperlink } from '../../../models/EditorTypes';

function HyperlinkElement(props: RenderElementProps) {
  const { attributes, children, element } = props;
  const hyperlinkNode = element as Hyperlink;

  const editor = useSlateStatic();
  const editorIsReadOnly = ReactEditor.isReadOnly(editor);

  const selected = useSelected();  // Get the current selected state of an element.
  const focused = useFocused();    // Get the current focused state of the editor.

  return <span {...attributes}>{children}</span>;
}

export default HyperlinkElement;