import EventEmitter from 'eventemitter3';

import { Logger } from 'browser-bunyan';
import logger from '../utils/Logger';

import AppController from '../controllers/AppController/AppController';
import PeerScriptCollector from './PeerScriptCollector';
import BaseDevice, { DeviceConnectionType } from './BaseDevice';
import { DeviceConnectingEvent } from './events/DeviceConnectingEvent';
import { DeviceConnectedEvent } from './events/DeviceConnectedEvent';
import { DeviceDisconnectedEvent } from './events/DeviceDisconnectedEvent';
import { DeviceRemovedEvent } from './events/DeviceRemovedEvent';
import { DeviceReportEvent } from './events/DeviceReportEvent';
import { DeviceButtonEvent } from './events/DeviceButtonEvent';
import { DeviceWheelEvent } from './events/DeviceWheelEvent';
import { DeviceAxisEvent } from './events/DeviceAxisEvent';
import PrompterPeerInstance from './prompterpeer/PrompterPeerInstance';
import { ConnectionState, GetSessionMessage, PrompterSessionEndpoint } from '@fluidprompter/core';
import { IBluetoothProvider } from './BluetoothProviders';
import { BluetoothProviderWeb } from './BluetoothWeb';
import { BluetoothProviderIPC } from './BluetoothIpc';

type PrompterPeerInstanceResolver = (value: PrompterPeerInstance | PromiseLike<PrompterPeerInstance>) => void;

interface DeviceHostEvents {
  deviceConnecting: (e: DeviceConnectingEvent) => void;
  deviceConnected: (e: DeviceConnectedEvent) => void;
  deviceDisconnected: (e: DeviceDisconnectedEvent) => void;
  deviceRemoved: (e: DeviceRemovedEvent) => void;
  deviceReport: (e: DeviceReportEvent) => void;
  buttonEvent: (e: DeviceButtonEvent) => void;
  wheelEvent: (e: DeviceWheelEvent) => void;
  axisEvent: (e: DeviceAxisEvent) => void;
}
class DeviceHost
  extends EventEmitter<DeviceHostEvents>
{
  private logger: Logger;

  private _devices: Map<string, BaseDevice>;

  private _peerPromises: Map<string, PrompterPeerInstanceResolver[]> = new Map<string, PrompterPeerInstanceResolver[]>();

  get scriptCollector() {
    return this._peerScriptCollector;
  }
  private _peerScriptCollector: PeerScriptCollector;

  constructor(appController: AppController) {
    super();

    this.logger = logger.child({
      childName: 'DeviceHost'
    });

    this._appController = appController;
    this._devices = new Map<string, BaseDevice>();
    this._peerScriptCollector = new PeerScriptCollector(this);

    if(window.fluidprompterHost && window.fluidprompterHost.bluetooth === 'ipc') {
      this.logger.debug('DeviceHost using BluetoothProviderIPC');
      this._bluetoothProvider = new BluetoothProviderIPC(this._appController);
    } else {
      this.logger.debug('DeviceHost using BluetoothProviderWeb');
      this._bluetoothProvider = new BluetoothProviderWeb(this._appController);
    }
  }

  get logLevel() {
    return this.logger.level();
  }
  set logLevel(levelNumber: number) {
    this.logger.level(levelNumber);
    this.scriptCollector.logLevel = levelNumber;
  }

  public get appController() {
    return this._appController;
  }
  private _appController: AppController;

  public get BluetoothProvider() {
    return this._bluetoothProvider;
  }
  private _bluetoothProvider: IBluetoothProvider;

  private checkIfAllRemotePeersLostConnection() {
    const allRemotePeerDevices = this.getDevicesByType<PrompterPeerInstance>(PrompterPeerInstance.DEVICE_TYPE)
      .filter(device => !device.representsLocal);
    if(!allRemotePeerDevices) {
      return;
    }

    const anyPeerConnected = allRemotePeerDevices.some(peer => peer.connectionState === ConnectionState.Connected);
    if(!anyPeerConnected) {
      this.logger.trace('All peers have become disconnected.');

      // All remote peers have been disconnected. Likely the browser window/tab was frozen by the
      // OS for power management reasons when we either minimized the browser or turned of the
      // device.
      //
      // We will be in the process of reconnecting these peers and checking if our script became
      // stale while we were disconnected.
      this.scriptCollector.resetExpectedPeers();
    }
  }

  private onDeviceConnecting(e: DeviceConnectingEvent) {
    // When a prompter peer becomes disconnected, we want to check if all peers became disconnected
    // at the same time.
    //
    // If so, we are probably in the midst of reconnecting after the browser was frozen by the OS
    // for power management reasons (device turned off, or browser minimized).
    if(e.device?.type === PrompterPeerInstance.DEVICE_TYPE) {
      this.checkIfAllRemotePeersLostConnection();
    }

    this.emit('deviceConnecting', e);
  }

  private onDeviceConnected(e: DeviceConnectedEvent) {
    this.emit('deviceConnected', e);
  }

  private onDeviceDisconnected(e: DeviceDisconnectedEvent) {
    // When a prompter peer becomes disconnected, we want to check if all peers became disconnected
    // at the same time.
    //
    // If so, we are probably in the midst of reconnecting after the browser was frozen by the OS
    // for power management reasons (device turned off, or browser minimized).
    if(e.device?.type === PrompterPeerInstance.DEVICE_TYPE) {
      this.checkIfAllRemotePeersLostConnection();
    }

    // TODO: Review the difference between a user initiated device disconnect vs a "lost connect" to a peer or bluetooth remote
    // if(e.userRequested) {
    // this.unregisterDevice(e.device);
    // }
    this.emit('deviceDisconnected', e);
  }

  private onDeviceRemoved(e: DeviceRemovedEvent) {
    if(!e.device) {
      throw new Error('DeviceRemovedEvent requires device instance.');
    }
    this.unregisterDevice(e.device);

    // If a remote peer device has been removed, then we are certianly not waiting to synchronize the script!
    if(e.device.type === PrompterPeerInstance.DEVICE_TYPE) {
      this.scriptCollector.removeExpectedPeer(e.device.id);
    }

    this.emit('deviceRemoved', e);
  }

  private onDeviceReport(e: DeviceReportEvent) {
    this.emit('deviceReport', e);
  }

  private onButtonEvent(e: DeviceButtonEvent) {
    this.emit('buttonEvent', e);
  }

  private onWheelEvent(e: DeviceWheelEvent) {
    this.emit('wheelEvent', e);
  }

  private onAxisEvent(e: DeviceAxisEvent) {
    this.emit('axisEvent', e);
  }

  registerDevice(device: BaseDevice) {
    const registeredInstance = this._devices.get(device.id);
    if(registeredInstance && registeredInstance !== device) {
      throw new Error(`Another device instance was already registered with DeviceHost by the same deviceId (${device.id}).`);
    }
    if(registeredInstance) {
      throw new Error('This device instance was already registered with DeviceHost.');
    }

    this._devices.set(device.id, device);

    device.addListener('connecting', this.onDeviceConnecting, this);
    device.addListener('connected', this.onDeviceConnected, this);
    device.addListener('disconnected', this.onDeviceDisconnected, this);
    device.addListener('removed', this.onDeviceRemoved, this);
    device.addListener('devicereport', this.onDeviceReport, this);
    device.addListener('buttonreport', this.onButtonEvent, this);
    device.addListener('wheelevent', this.onWheelEvent, this);
    device.addListener('axisevent', this.onAxisEvent, this);


    // keyboards -> KeyDown, KeyUp
    // mice/pointers - MouseDown, MouseUp, Click, MouseMove, WheelEvent
    // touchscreen ->
    // gamepads
    // shuttlewheel -> Mouse Wheel?
    // analog foot pedal -> Gamepad Axis?

    // this.emit("inputevent", eventName);

    //
    // If we just registered a PrompterPeerInstance and there was any unresolved promise(s) waiting
    // for this PrompterPeerInstace, then resolve those promises now.
    //
    // ex: If we receive an SdpMessage without having an existing PrompterPeerInstance, it will
    // await a promised PrompterPeerInstance.
    //
    if(this._peerPromises.has(device.id)) {
      const registeredPrompterPeerInstance = device as PrompterPeerInstance;
      const peerPromiseResolvers = this._peerPromises.get(device.id);
      if(peerPromiseResolvers?.length) {
        peerPromiseResolvers.map(resolver => resolver(registeredPrompterPeerInstance));
      }
      this._peerPromises.delete(device.id);
    }
  }

  unregisterDevice(device: BaseDevice) {
    const registeredInstance = this._devices.get(device.id);
    if(registeredInstance && registeredInstance !== device) {
      this.logger.error(`Another device instance was registered by the same deviceId key: '${device.id}'`);
      throw new Error(`Another device instance was registered by the same deviceId key: '${device.id}'`);
    }

    device.removeAllListeners();
    // device.removeListener('connected', this.onDeviceConnected, this);
    // device.removeListener('disconnected', this.onDeviceDisconnected, this);
    // device.removeListener('removed', this.onDeviceRemoved, this);
    // device.removeListener('devicereport', this.onDeviceReport, this);
    // device.removeListener('buttonreport', this.onButtonEvent, this);
    // device.removeListener('wheelevent', this.onWheelEvent, this);
    // device.removeListener('axisevent', this.onAxisEvent, this);

    const successful = this._devices.delete(device.id);
    if(successful) {
      this.logger.info(`DeviceHost.unregisterDevice(${device.id}) successful.`);
    } else {
      this.logger.error(`ERROR: DeviceHost.unregisterDevice(${device.id}) unsuccessful.`);
    }
  }

  /**
   * Devices can register input middleware to influence input processing (such as remapping keys
   * to perform a different function, such as Ikan Elite Remote remapping number keys).
   */
  registerInputMiddleware() {
    // TODO: Maybe reconsider this and just make an abstract middlware function on all devices,
    // some middleware be empty functions that do nothing. Then just have DeviceHost iterate over
    // all connected devices' middleware functions??
    //     this._devices = new Map<string, BaseDevice>();
    for (const [deviceId, device] of this._devices) {
      this.logger.info(`${deviceId} = ${device}`);

      // Call middleware method for pre-processing keyboard events. This allows "devices" to remap keyboard keys.
      //device.preprocessKeyEvent();
    }
  }

  allDevices<T extends BaseDevice = BaseDevice>(connectionType?: DeviceConnectionType): T[] {
    return Array.from(this._devices.values())
      .filter((device) => (!connectionType || device.connectionType === connectionType)) as T[];
  }

  getDevicesByType<T extends BaseDevice = BaseDevice>(deviceType?: string): T[] {
    return Array.from(this._devices.values())
      .filter((device) => (!deviceType || device.type === deviceType)) as T[];
  }

  getDevice(deviceId: string): BaseDevice | undefined {
    return this._devices.get(deviceId);
  }

  /**
  * Return the most recent PrompterPeerInstance leader (ideally also the current leader).
  * @returns PrompterPeerInstance of current session leader
  */
  getPrompterPeerLeader(): PrompterPeerInstance | undefined {
    return Array.from(this._devices.values())
      .filter((device): device is PrompterPeerInstance => (device.type === PrompterPeerInstance.DEVICE_TYPE))
      .sort((a, b) => b.lastLeaderTimestamp - a.lastLeaderTimestamp)
      .at(0);
  }

  getPrompterPeerLocalInstance() {
    return this.getDevicesByType<PrompterPeerInstance>(PrompterPeerInstance.DEVICE_TYPE)
      .find(peer => peer.representsLocal);
  }

  /**
   * Returns the corresponding PrompterPeerInstance for a given PrompterSessionEndpoint descriptor.
   * If no PrompterPeerInstance exists, one will be created, otherwise the existing instance will
   * be returned.
   * @param endpoint
   * @returns PrompterPeerInstance
   */
  getOrCreatePeerInstance(remoteEndpoint: PrompterSessionEndpoint, representsLocal?: boolean) {
    const targetEndpointId = remoteEndpoint.endpointId;
    if(!targetEndpointId) {
      throw new Error('endpointId is required for getOrCreatePeerInstance()');
    }
    if(!this.appController.localEndpointId) {
      throw new Error('Error missing AppController.localEndpointId');
    }

    this.logger.info(`DeviceHost.getOrCreatePeerInstance(${targetEndpointId})`);

    //
    // If we already have a PrompterPeerInstance for the given targetEndpointId, return it.
    //
    let peerInstance = this.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .find((device) => device.endpointId === targetEndpointId);

    if(!peerInstance) {
      this.logger.info(`DeviceHost.getOrCreatePeerInstance(${targetEndpointId}) - requires new peer instance`);

      // Arbitrary method for determining who is the polite peer and who is the impolite peer.
      const isPolitePeer = targetEndpointId.localeCompare(this.appController.localEndpointId) > 0;

      peerInstance = new PrompterPeerInstance(this, this.appController.rtcConfig, remoteEndpoint, representsLocal === true, isPolitePeer);
    }

    // We will update even an existing PrompterPeerInstance with any meta data available from
    // the remoteEndpoint.
    peerInstance.updateFromEndpoint(remoteEndpoint);

    //
    // Is this server record representing this local instance?
    // Special case, we want to keep track of our currently assigned peerNumber in appController.
    //
    if(representsLocal && remoteEndpoint.peerNumber) {
      this.appController.peerNumber = remoteEndpoint.peerNumber;

      this.logger.info(`DeviceHost.getOrCreatePeerInstance(${targetEndpointId}) - local endpoint assigned peerNumber ${remoteEndpoint.peerNumber}`);
    }

    //
    // If we know the latitude and longitude for both this local prompter instance and the remote
    // peer instance, we can calculate the estimated distance between the peers.
    //
    const localDevice = this.getPrompterPeerLocalInstance();
    if(localDevice && localDevice.endpointId !== peerInstance.endpointId) {
      const { latitude, longitude } = localDevice;
      // console.log(`got localDevice and remoteDevice (${latitude}, ${longitude})`);
      if(latitude && longitude) {
        // console.log('got latitude and longitude');
        peerInstance.setLocalLatitudeLongitude(latitude, longitude);
      }
    }

    return peerInstance;
  }

  async promisePeerInstance(targetEndpointId?: string): Promise<PrompterPeerInstance | undefined> {
    if(!targetEndpointId) {
      return undefined;
    }

    //
    // If we already have a PrompterPeerInstance for the given targetEndpointId, return it.
    //
    const targetDevice = this.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
      .find((device) => device.endpointId === targetEndpointId);

    if(targetDevice) {
      return targetDevice;
    }

    // If we get here, we need to promise
    const devicePromise = new Promise<PrompterPeerInstance>((resolve, reject) => {

      //
      //
      //
      const wrappedResolver = (instance: PrompterPeerInstance | PromiseLike<PrompterPeerInstance>) => {
        clearTimeout(promiseTimeout);
        removeResolver();
        resolve(instance);
      };

      //
      // Add the resolver to our Map of resolvers for a given endpointId
      //
      let resolverCollection = this._peerPromises.get(targetEndpointId);
      if(!resolverCollection) {
        resolverCollection = [];
        this._peerPromises.set(targetEndpointId, resolverCollection);
      }
      resolverCollection.push(wrappedResolver);
      this.logger.info(`DeviceHost.promisePeerInstance(${targetEndpointId}) added promise, new count=${resolverCollection.length}`);

      //
      //
      //
      const removeResolver = () => {
        const existingResolverCollection = this._peerPromises.get(targetEndpointId);
        if(existingResolverCollection) {
          const newResolverCollection = existingResolverCollection.filter((e, i) => e !== wrappedResolver);
          if(newResolverCollection.length < existingResolverCollection.length) {
            this._peerPromises.set(targetEndpointId, newResolverCollection);
            this.logger.info(`DeviceHost.promisePeerInstance(${targetEndpointId}) removed promise, new count=${newResolverCollection.length}`);
          }
        }
      };

      //
      // Safety, just in case...
      //
      const promiseTimeout = setTimeout(() => {
        removeResolver();
        reject(new Error(`DeviceHost.promisePeerInstance(${targetEndpointId}) Timeout`));
      }, 5000);
    });

    //
    // If we get here, we need to retrieve the list of currently connected prompter endpoints
    // and creating an missing PrompterPeerInstances. If we get a response from the server,
    // we will resolve any pending promises for endpoints contained in that response.
    //
    // Sometimes we get flooded with a bunch of requests for the same PrompterPeerInstance.
    // We don't need to send GetSessionMessage() a bunch of times, just once.
    //
    const existingResolverCollection = this._peerPromises.get(targetEndpointId);
    if(existingResolverCollection && existingResolverCollection.length === 1) {
      this._appController.sendToServer(new GetSessionMessage());
    }

    return devicePromise;
  }
}

export default DeviceHost;