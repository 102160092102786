import { IUserProfile } from '@fluidprompter/core';

const patchUserProfileGlobal = async (apiToken: string, userProfile: IUserProfile): Promise<IUserProfile> => {
  if(!apiToken) {
    throw new Error('apiToken is required to fetchUserProfile');
  }

  const response = await fetch(`${process.env.REACT_APP_API_URL}/account/profile`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(userProfile),
  });

  if(response.status !== 200) {
    // We received an error response.
    throw new Error('Error: could not fetch user profile.');
  }

  return await response.json() as IUserProfile;
};

export default patchUserProfileGlobal;