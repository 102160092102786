import DeviceHost from '../DeviceHost';
import useRTCBackgroundClearHandler from './useRTCBackgroundClearHandler';
import useRTCBackgroundLocalSourceHandler from './useRTCBackgroundLocalSourceHandler';
import useRTCBackgroundSetHandler from './useRTCBackgroundSetHandler';

function useRTCBackgroundHandlers(deviceHost: DeviceHost) {

  //
  // This hook handles background messages to get video from a local source such as webcam or
  // screen/app capture.
  //
  useRTCBackgroundLocalSourceHandler(deviceHost);

  //
  // This hook handles background set app message.
  //
  const backgroundMediaStream = useRTCBackgroundSetHandler(deviceHost);

  //
  // This hook handles background clear app message.
  //
  useRTCBackgroundClearHandler(deviceHost);

  //
  return backgroundMediaStream;
}

export default useRTCBackgroundHandlers;