import React from 'react';
import List from '@mui/material/List';
import ShotlogEntryRenderer from './ShotlogEntryRenderer';

import usePrompterSession from '../../state/PrompterSessionState';

import './PrompterHudShotLog.scss';

const PrompterHudShotLog = React.memo(function PrompterHudShotLog() {

  const ref = React.createRef<HTMLDivElement>();

  const shotlogEntries = usePrompterSession(state => state.shotlogEntries);

  // Find the origin timestamp that will be subtract from other timestamps to get relative 
  // distance from start of shoot.
  let sessionStartTimestamp = shotlogEntries.length ? (shotlogEntries[0].startTimestamp || shotlogEntries[0].finishTimestamp) : 0;
  for (let i = 1; i < shotlogEntries.length; i++) {
    const thisEntry = shotlogEntries[i];
    if (thisEntry.startTimestamp < sessionStartTimestamp) {
      sessionStartTimestamp = thisEntry.startTimestamp;
    }
  }

  React.useEffect(() => {
    const scrollEl = ref.current;
    if(!scrollEl) {
      return;
    }

    scrollEl.scroll({ 
      top: scrollEl.scrollHeight,
      behavior: 'smooth',
    });
  }, [shotlogEntries, ref]);

  return (<div ref={ref} className="PrompterHudShotLog invisible-scrollbars">
    <List
      component="nav"
      disablePadding
      dense={true}
      // className="PrompterHudShotLog"
    >
      {shotlogEntries.map((shotlogEntry, shotlogEntryIndex) => {
        return (<ShotlogEntryRenderer
          key={`entry-${shotlogEntryIndex}`}
          shotlogEntry={shotlogEntry}
          sessionStartTimestamp={sessionStartTimestamp}
        />);
      })}
    </List>
  </div>);
});

export default PrompterHudShotLog;