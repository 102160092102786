import { useCallback } from 'react';
import { MessageHandlerEvent, useMessageHandler } from '../controllers/AppController';

import { PlayMessage, PauseMessage, EndpointRole, SetLeaderMessage } from '@fluidprompter/core';
import useConfigurationStore from '../state/ConfigurationStore';
import usePrompterSession from '../state/PrompterSessionState';

function useSessionMessageHandlers() {
  const doPlay = useCallback(async function (e: MessageHandlerEvent<PlayMessage>) {
    const { message, originatedRemotely } = e;
    const { sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    // console.log(`Received prompter.state.play message - senderIsRemote=${senderIsRemote}`, e);

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);

    //
    // If the sender is a remote and I'm not the leader, ignore this message.
    //
    if(originatedRemotely && senderIsRemote && !isLeader) {
      // The current leader will process the remote command within the context of the current
      // scroll position and transmit a more accurate message to all peers.
      return;
    }

    e.sendToPeers = !originatedRemotely || (senderIsRemote && isLeader);

    const requestReversed = (sender?.scrollReversed === true);

    const configState = useConfigurationStore.getState();
    if(configState.scrollReversed !== requestReversed) {
      configState.setScrollReversed(requestReversed);
    }

    if(!prompterSession.isPlaying) {
      prompterSession.play();
    }
  }, []);
  useMessageHandler('prompter.state.play', doPlay);

  const doPause = useCallback(async function (e: MessageHandlerEvent<PauseMessage>) {
    const { message, originatedRemotely } = e;
    const { sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);

    //
    // If the sender is a remote and I'm not the leader, ignore this message.
    //
    if(originatedRemotely && senderIsRemote && !isLeader) {
      // The current leader will process the remote command within the context of the current
      // scroll position and transmit a more accurate message to all peers.
      return;
    }

    e.sendToPeers = !originatedRemotely || (senderIsRemote && isLeader);

    const requestReversed = (sender?.scrollReversed === true);

    const configState = useConfigurationStore.getState();
    if(configState.scrollReversed !== requestReversed) {
      configState.setScrollReversed(requestReversed);
    }

    if(prompterSession.isPlaying) {
      prompterSession.pause();
    }
  }, []);
  useMessageHandler('prompter.state.pause', doPause);

  useMessageHandler('navigate', (e) => {
    const { originatedRemotely } = e;
    // const { queueSequentialTask } = message;

    e.sendToPeers = !originatedRemotely;

    /*
    switch(message.target) {
      case NavigateMessage.Target.Start: {
        break;
      }
      case NavigateMessage.Target.End: {
        break;
      }
      case NavigateMessage.Target.PageUp: {
        break;
      }
      case NavigateMessage.Target.PrevSegment: {  // Previous Segment
        break;
      }
      case NavigateMessage.Target.NextSegment: {  // Next Segment
        break;
      }
      case NavigateMessage.Target.Segment: {
        break;
      }
      case NavigateMessage.Target.Position: {
        break;
      }
    }
    */
  });

  return {
    play: doPlay,
    pause: doPause,
  };
}

export default useSessionMessageHandlers;