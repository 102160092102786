import React from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import lightTheme from '../../themes/LightTheme';

import Dialog from '@mui/material/Dialog';
import DialogTitleWithClose from '../DialogTitleWithClose';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import CancelIcon from '@mui/icons-material/Cancel';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LockSharp from '@mui/icons-material/LockRounded';

import { useTranslation, Trans } from 'react-i18next';
import { useAppController } from '../../controllers/AppController';

export interface IAvailableDevice {
  name: string;
}

interface SelectDeviceDialogRendererProps {
  handleClose: () => void;
  devices: IAvailableDevice[];
}

const SelectDeviceDialogRenderer = (props: SelectDeviceDialogRendererProps) => {
  const { handleClose, devices } = props;

  const { t } = useTranslation('prompter');

  const appController = useAppController();

  const theme = useTheme();
  const viewportSmOrXs = useMediaQuery(theme.breakpoints.down('sm'));
  const showDialogFullscreen = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    if(selectedIndex === index) {
      // We are clicking an item that is already selected.
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };

  return (<>
    <ThemeProvider theme={lightTheme}>
      <Dialog
        maxWidth='lg'
        fullWidth={showDialogFullscreen}
        // fullScreen={showDialogFullscreen}
        sx={{
          maxHeight: '50vh'
        }}
        scroll='paper'
        onClose={handleClose}
        open={true}
      >
        <DialogTitleWithClose
          onClose={handleClose}
        >Choose a device to connect:</DialogTitleWithClose>
        <DialogContent
          dividers={false}
          sx={{ padding: 0 }}
        >
          {/* <DialogContentText maxWidth={'sm'} paddingX={2}>
            Choose a device to connect:
          </DialogContentText> */}
          <List disablePadding sx={{ paddingBottom: `${Math.max(6 - devices.length, 0) * 10}vh` }}>
            {!devices.length && <>
              <ListItem>
                <ListItemText primary={'(none)'} />
              </ListItem>
            </>}
            {devices.map((value, index) => {
              return (<>
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemIcon><PersonAddIcon /></ListItemIcon>
                  <ListItemText primary={value.name} />
                </ListItemButton></>);
            })}
          </List>
        </DialogContent>
        {/*
        <DialogActions style={{
          // alignItems: 'stretch',
          padding: 0,
        }}>
          <Button
            startIcon={<CancelIcon />}
            onClick={handleClose}
            sx={{
              flex: 1,
              flexDirection: showDialogFullscreen ? 'column' : 'row'
            }}
          >{t('accountrequireddialog.cancel')}</Button>
          {/-* <div style={{
            flex: 1,
          }}></div> *-/}
          <Button
            startIcon={<PersonAddIcon />}
            onClick={() => {
              handleClose();
              // appController.dispatchMessage('prompter.local.signup');
            }}
            sx={{
              flex: 1,
              flexDirection: showDialogFullscreen ? 'column' : 'row',
              // borderTop: '1px solid #ccc'
            }}
          >Connect</Button>
        </DialogActions>
        */}
      </Dialog>
    </ThemeProvider>
  </>);
};

export default SelectDeviceDialogRenderer;