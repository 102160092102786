import BaseControlMessage from './BaseControlMessage';
export const ICECANDIDATEMESSAGE_KEY = 'icecandidate';
/**
 * Represents the IceCandidateMessage type.
 */
export class IceCandidateMessage extends BaseControlMessage {
    constructor(targetId, candidate) {
        super();
        this.type = ICECANDIDATEMESSAGE_KEY;
        this.targetId = targetId;
        this.candidate = candidate;
    }
    static fromJson(data) {
        if (data.type !== ICECANDIDATEMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for IceCandidateMessage.');
        }
        const input = data;
        const instance = new IceCandidateMessage(input.targetId, input.candidate);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            targetId: this.targetId,
            candidate: this.candidate
        };
    }
}
IceCandidateMessage.MESSAGE_NAME = ICECANDIDATEMESSAGE_KEY;
