import React, { useEffect, useRef, useState } from 'react';
import Button, { buttonClasses, ButtonProps } from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';

const ShotLogButtonBase = styled(Button)({
  fontSize: 'inherit',
  fontWeight: 'bold',
  lineHeight: '1.25',
  paddingLeft: 12,
  color: 'inherit',
  whiteSpace: 'nowrap',
  '&:hover': {
    color: '#fff',
  },
  [`&.${buttonClasses.disabled}`]: {
    color: 'inherit', // The pause type button is disabled when prompting.
  },
  ['&.Highlighted']: {
    color: '#fff',
  },
  '.MuiButton-startIcon': {
    '& > *:nth-of-type(1)': {
      fontSize: '1em',
    }
  },
  '.MuiButton-endIcon': {
    '& > *:nth-of-type(1)': {
      fontSize: '1em',
    }
  },
});

const ShotLogButtonProgress = styled(LinearProgress)({
  '& .MuiLinearProgress-bar': {
    transition: 'transform 16ms linear',  // If this transition length exceeds the time before the next DOM change, then FireFox will get really messed up as it tries to finish overlapping animations from the past.
  }
});

interface ShotLogButtonProps extends ButtonProps {
  autoClickMilliseconds?: number,
  highlighted?: boolean,
}

const ShotLogButton = (props: ShotLogButtonProps) => {
  const { autoClickMilliseconds, highlighted, className, children, ...rest } = props;

  const btnRef = useRef<HTMLButtonElement | null>(null);
  const rafRef: React.MutableRefObject<number> = useRef<number>(-1);
  const previousFrameTime: React.MutableRefObject<number> = useRef<number>(0);
  const millisecondsRemaining: React.MutableRefObject<number> = useRef<number>(0);
  
  const [percentageComplete, setPercentageComplete] = useState<number>(0);

  const animate = React.useCallback(() => {
    //
    // Calculate the next frame
    //
    const deltaTime = performance.now() - previousFrameTime.current;
    // setCountdownNumber((value) => {
    //   let proposedValue = value ? value - deltaTime : 0;
    //   if(proposedValue === 0) { proposedValue = 5000; } // TEMP: for continuous animation.
    //   if(proposedValue < 0) { proposedValue = 0; }
    //   return proposedValue;
    // });
    let proposedValue = millisecondsRemaining.current - deltaTime;
    if(proposedValue === 0) { proposedValue = 5000; } // TEMP: for continuous animation.
    if(proposedValue < 0) { proposedValue = 0; }
    millisecondsRemaining.current = proposedValue;

    const proposedPercentageComplete = (autoClickMilliseconds && millisecondsRemaining.current) ? Math.ceil((autoClickMilliseconds - millisecondsRemaining.current) * 100 / autoClickMilliseconds) : 0;
    setPercentageComplete(proposedPercentageComplete);

    //
    // If time completed, trigger our click event!
    //
    if(proposedPercentageComplete === 100) {
      btnRef.current?.click();
    }

    //
    // If aren't done counting down, let's schedule another animation frame.
    //
    previousFrameTime.current = performance.now();
    if(proposedPercentageComplete < 100) {
      rafRef.current = requestAnimationFrame(animate);
    }
  }, [autoClickMilliseconds]);  // END animate() function

  useEffect(() => {
    if(!autoClickMilliseconds){
      millisecondsRemaining.current = 0;
      setPercentageComplete(0);
      return;
    }

    millisecondsRemaining.current = autoClickMilliseconds;
    previousFrameTime.current = performance.now();
    rafRef.current = requestAnimationFrame(animate);

    // Return a clean-up function that is used if the component is re-rendered.
    return () => cancelAnimationFrame(rafRef.current);
  }, [autoClickMilliseconds, animate]);

  // const secondsRemaining = millisecondsRemaining.current ? Math.ceil(millisecondsRemaining.current / 1000) : 0;

  //
  // Take any classnames provided in props and add `Highlighted` classname if appropriate.
  //
  const inputClassNames = className?.split(' ');
  const finalClassName = classNames(inputClassNames, { Highlighted: (highlighted === true) });

  return (
    <ShotLogButtonBase
      className={finalClassName}
      ref={btnRef}
      {...rest}
    >
      <span className="ShotLogButtonLabel">
        {/*(autoClickMilliseconds !== undefined) && <>{secondsRemaining}s</>*/}
        {children}
      </span>
      {(autoClickMilliseconds !== undefined) && <ShotLogButtonProgress
        color="primary"
        variant="determinate"
        value={percentageComplete}
        className="ShotLogButtonProgress"
      />}
    </ShotLogButtonBase>
  );
};

export default ShotLogButton;