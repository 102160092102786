import { Reducer } from 'react';
import { IApplicationContextState, ReducerActionTypes, IReducerAction, IAccountSetupRequired } from './hookTypes';
import { IUserProfile } from '@fluidprompter/core';
import { shallow } from 'zustand/shallow';

import { setupRequiredForUserProfile } from './setupRequiredForUserProfile';

export const reducerFunction: Reducer<IApplicationContextState, IReducerAction> = (state: IApplicationContextState, action: IReducerAction) => {
  // console.log('Process Reducer: ', action.type);
  switch (action.type) {
    case ReducerActionTypes.setNetworkOnline: {
      const networkOnline = action.payload as boolean;

      return (state.networkOnline === networkOnline)
        ? state
        : {
          ...state,
          networkOnline,
          internetOnline: networkOnline ? state.internetOnline : false
        };
    }
    case ReducerActionTypes.setInternetOnline:
      return (state.internetOnline === action.payload) ? state : { ...state, internetOnline: (action.payload as boolean) };
    // case ReducerActionTypes.setIsOffline:
    //   return (state.isOffline === action.payload) ? state : { ...state, isOffline: action.payload, internetOnline: false };
    case ReducerActionTypes.setIsLoading:
      return (state.isLoading === action.payload) ? state : { ...state, isLoading: (action.payload as boolean) };
    case ReducerActionTypes.setIsAuthenticated:
      return (state.isAuthenticated === action.payload) ? state : { ...state, isAuthenticated: (action.payload as boolean) };
    case ReducerActionTypes.setUserProfile: {
      const userProfile = action.payload as IUserProfile;
      // Only apply changes if the userProfile data really changed. (Deduplicate reducer actions
      // when React strict mode tries to load the profile twice in one component lifecycle).
      const profileIsUnchanged = shallow(state.userProfile, userProfile);
      if(profileIsUnchanged) {
        return state;
      }
      const accountSetupRequired = setupRequiredForUserProfile(userProfile);
      return { ...state, userProfile, accountSetupRequired, isAuthenticated: true };
    }
    case ReducerActionTypes.setAccountSetupRequired:
      return { ...state, accountSetupRequired: (action.payload as IAccountSetupRequired) };
    case ReducerActionTypes.replaceState: {
      const proposedState = action.payload as IApplicationContextState;

      // Let's make a custom equality checker that compares the userprofile more deeply.
      // This prevents unnecessary state updates when set to the same values.
      //
      // Some reasons why a state update may result in the same next state is for example when React
      // fires useEffect twice in strict mode development builds resulting in fetching the same
      // userProfile with two network requests.
      if(state.networkOnline === proposedState.networkOnline
        && state.internetOnline === proposedState.internetOnline
        && state.isLoading === proposedState.isLoading
        && state.isAuthenticated === proposedState.isAuthenticated
        && shallow(state.userProfile, proposedState.userProfile)
        && shallow(state.accountSetupRequired, proposedState.accountSetupRequired))
      {
        return state;
      }

      return { ...proposedState };
    }
    default:
      throw new Error();
  }
};

export default reducerFunction;