import React, { useState } from 'react';

import { DeviceComponent, DeviceComponentProps } from '../../BaseDevice';
import NeewerRT110RemoteMenu from './NeewerRT110RemoteMenu';
// import NeewerRT110RemoteDialog from './NeewerRT110RemoteDialog';

const NeewerRT110RemoteUI = (props: DeviceComponentProps) => {
  const { onClose } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (<>
    <NeewerRT110RemoteMenu {...props} setDialogOpen={setDialogOpen} />
    {/* {dialogOpen && <GenericGamepadDialog onClose={() => {
      // Close dialog - should we close menu too?
      setDialogOpen(false);
      onClose();
    }} />} */}
  </>);
};

export default NeewerRT110RemoteUI;