import BaseControlMessage from './BaseControlMessage';
export const BACKGROUNDMESSAGE_KEY = 'background';
/**
 * Represents the BackgroundMessage type.
 */
export class BackgroundMessage extends BaseControlMessage {
    constructor(mediaStream, isLocalSource) {
        super();
        this.type = BACKGROUNDMESSAGE_KEY;
        this.mediaStream = mediaStream;
        this.isLocalSource = (isLocalSource === true);
    }
    static fromJson(data) {
        if (data.type !== BACKGROUNDMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for BackgroundMessage.');
        }
        const input = data;
        const instance = new BackgroundMessage(input.mediaStream);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            mediaStream: this.mediaStream,
        };
    }
}
BackgroundMessage.MESSAGE_NAME = BACKGROUNDMESSAGE_KEY;
