import DeviceHost from '../DeviceHost';
import BaseDevice, {
  IDeviceDescriptor,
  DeviceComponent,
  DeviceConnectionType,
  IDeviceState,
} from '../BaseDevice';
import { DeviceConnectedEvent } from '../events/DeviceConnectedEvent';
import { DeviceDisconnectedEvent } from '../events/DeviceDisconnectedEvent';
import { DeviceRemovedEvent } from '../events/DeviceRemovedEvent';
import { DeviceReportEvent } from '../events/DeviceReportEvent';
import usePrompterSession from '../../state/PrompterSessionState';

import {
  ConnectionState,
  BaseControlMessage,
  LifecycleMessage,
  GetSessionMessage,
  SdpMessage,
  IceCandidateMessage,
  HeartbeatMessage,
  HeartbeatResponse,
  PlayMessage,
  PauseMessage,
  EditMessage,
  EndpointRole,
  MessageUtils,
  BackgroundMessage,
  PrompterSessionEndpoint,
  AppLifecycleState,
  ConnectRequestMessage,
  PeerPingMessage,
  PeerStateRequest,
  PeerStateResponse,
  SenderInfo,
} from '@fluidprompter/core';

import { TFunction } from 'i18next';
import PrompterIcon from './images/prompter-icon.png';
import PrompterPeerUI from './UI';
import { zaraz } from 'zaraz-ts';

import _ from 'lodash';
import promiseRetry from 'promise-retry';

import { Logger } from 'browser-bunyan';
import logger from '../../utils/Logger';

// 1000 ms x 60 secs x 60 mins x 24 hrs x 365 days
const ONE_YEAR_MS = 1000 * 60 * 60 * 24 * 365;

const PEER_HEARTBEAT_INTERVAL_MS = 2000;

interface IQueueEntry<T> {
  timestamp: number;
  message: T;
}

export interface IPrompterState extends IDeviceState {
  representsLocal?: boolean,
  isLeader?: boolean,
  appState?: AppLifecycleState,
  cloudConnectionState?: ConnectionState,
  cloudLatency?: number,
  connectionState?: ConnectionState,
  peerLatency?: number,
  peerDistance?: number,
  peerIsLocal?: boolean,
  peerIsRelayed?: boolean,
  osName?: string,
  osVersion?: string,
  browserName?: string,
  browserVersion?: string,
  deviceType?: string,
}

type PeerConnectionResolverFn = (value: RTCPeerConnection | PromiseLike<RTCPeerConnection>) => void;

const PROMPTERPEERINSTANCE_TYPE = 'peer';

class PrompterPeerInstance
  extends BaseDevice
{
  readonly type = PROMPTERPEERINSTANCE_TYPE;

  public static readonly DEVICE_TYPE: string = PROMPTERPEERINSTANCE_TYPE;

  private logger: Logger;
  private rtcConfig?: RTCConfiguration;

  public endpointId!: string;
  public endpointRole!: EndpointRole;

  public clientIp: string | undefined;
  public connectedTimestamp: number = Date.now();
  public city: string | undefined;
  public regionCode: string | undefined;
  public region: string | undefined;
  public country: string | undefined;
  public continent: string | undefined;
  public postalCode: string | undefined;
  public latitude: string | undefined;
  public longitude: string | undefined;
  public timezone: string | undefined;

  public osName?: string;
  public osVersion?: string;
  public browserName?: string;
  public browserVersion?: string;
  public deviceType?: string;

  public peerIsLocal?: boolean;
  public peerIsRelayed?: boolean;

  public get peerNumber() {
    return this._peerNumber;
  }
  public set peerNumber(peerNumber: number) {
    this._peerNumber = peerNumber;
    this._sortPriority = peerNumber;

    this.requestDeviceReport();
  }
  private _peerNumber = 0;

  public get appLifecycleState() {
    return this._appLifecycleState;
  }
  public set appLifecycleState(lifecycleState: AppLifecycleState) {
    if(this._appLifecycleState === lifecycleState) {
      return;
    }
    this._appLifecycleState = lifecycleState;

    this.requestDeviceReport();
  }
  public _appLifecycleState!: AppLifecycleState;

  /**
   * Returns true if this prompter peer instance is hidden.
   * This state is reached when the app is both visually hidden and does not have user input focus.
   * ex: App "minimized" or switched apps/tabs on a tablet.
   */
  public get isHidden() {
    return this._appLifecycleState === AppLifecycleState.Hidden;
  }

  constructor(
    deviceHost: DeviceHost,
    rtcConfig: RTCConfiguration | undefined,
    endpoint: PrompterSessionEndpoint,
    representsLocal = false,
    polite = true
  ) {
    super(deviceHost, representsLocal, endpoint?.endpointId);
    this.parentId = undefined;
    this.suppressConnectionAlerts = true;

    this.rtcConfig = rtcConfig;

    //
    // We want to initialize the lastLeaderTimestamp to a value that respects the order in which
    // peers connected without promoting a new peer above a collection of existing peers that have
    // real lastLeaderTimestamp values that were set based on prior activity like playing/pausing.
    //
    // We will get the default timestamp to time of peer instance creation minus a year. This will
    // allow all peers to be sorted by UNIX timestamps even if some of them have a default
    // lastLeaderTimestamp and some have an explicitly set value due to recent human interaction.
    //
    this._lastLeaderTimestamp = Date.now() - ONE_YEAR_MS;

    const {
      endpointId, role, peerNumber, cloudLatency,
    } = endpoint;
    if(endpointId) {
      this.endpointId = endpointId;
      this.id = endpointId;
    }
    this.endpointRole = role;
    this._cloudLatency = cloudLatency;

    this.updateFromEndpoint(endpoint);

    this._representsLocal = representsLocal;
    this._polite = polite;

    this.icon = PrompterIcon;
    switch(this.endpointRole) {
      default:
      case EndpointRole.Prompter:
        this.icon = PrompterIcon;
        this.name = this._representsLocal ? 'This Prompter' : 'Remote Prompter';
        break;
      case EndpointRole.Viewer:
        this.icon = PrompterIcon;
        this.name = this._representsLocal ? 'This Viewer' : 'Remote Viewer';
        break;
      case EndpointRole.Remote:
        this.icon = PrompterIcon;
        this.name = this._representsLocal ? 'This Controller' : 'Remote Controller';
        break;
    }

    this.connectionType = DeviceConnectionType.Network;

    this._cloudConnectionState = ConnectionState.Connected;
    // this._cloudLatency = cloudLatency;

    this.logger = logger.child({
      childName: 'PrompterPeerInstance'
    });
    this.logger.info(`${this.getLogPrefix()} new PrompterPeerInstance() constructor`);

    this._sendRTCHeartbeat = this.sendRTCHeartbeat.bind(this);

    this._handleRTCConnectionStateChange = this.handleRTCConnectionStateChange.bind(this);
    this._handleIceConnectionStateChange = this.handleIceConnectionStateChange.bind(this);
    this._handleIceGatheringStateChange = this.handleIceGatheringStateChange.bind(this);
    this._handleSignalingStateChange = this.handleSignalingStateChange.bind(this);
    this._handleNegotiationNeeded = this.handleNegotiationNeeded.bind(this);
    this._handleIceCandidate = this.handleIceCandidate.bind(this);
    this._handleIceCandidateError = this.handleIceCandidateError.bind(this);
    // this._handleDataChannel = this.handleDataChannel.bind(this);
    this._handleTrackEvent = this.handleTrackEvent.bind(this);

    this._handleDataChannelOpen = this.handleDataChannelOpen.bind(this);
    this._handleDataChannelError = this.handleDataChannelError.bind(this);
    this._handleDataChannelClose = this.handleDataChannelClose.bind(this);
    this._handleDataChannelMessage = this.handleDataChannelMessage.bind(this);
    this._handleBufferedAmountLow = this.handleBufferedAmountLow.bind(this);

    // ******************************************************************************************
    // ******************************************************************************************

    if(this._representsLocal) {
      this.subscribeToPrompterSession();
    }
  }

  public applyTranslation(t: TFunction) {
    super.applyTranslation(t);

    //
    // Update device name using translations. This is shown in the devices menu to identify this
    // device and it's current role.
    //
    switch(this.endpointRole) {
      default:
      case EndpointRole.Prompter:
        this.name = this._representsLocal ? t('prompterpeer.thisprompter') : t('prompterpeer.remoteprompter');
        break;
      case EndpointRole.Viewer:
        this.name = this._representsLocal ? t('prompterpeer.thisviewer') : t('prompterpeer.remoteviewer');
        break;
      case EndpointRole.Remote:
        this.name = this._representsLocal ? t('prompterpeer.thiscontroller') : t('prompterpeer.remotecontroller');
        break;
    }

    this.requestDeviceReport();
  }

  /**
   * If this PrompterPeerInstance represents the local prompter, then we want to know when the
   * editor applies changes.
   */
  private subscribeToPrompterSession() {
    usePrompterSession.subscribe(
      (config, previousConfig) => {
        if(config.lastScriptChangeTimestamp && config.lastScriptChangeTimestamp !== previousConfig.lastScriptChangeTimestamp) {
          this.lastScriptChangeTimestamp = config.lastScriptChangeTimestamp;
        }
      }
    );
  }

  /**
   * Method to return a log statement prefix with timestamp and peer info.
   * @returns string
   */
  private getLogPrefix() {
    // My local instance is: (the peer number assigned to this app instance)
    const localPeerNumber = this.deviceHost.appController.peerNumber;

    // This is the other endpoint that this PrompterPeerInstance is maintaining a connection to.
    const remotePeerNumber = this.peerNumber;

    // return `${new Date().toLocaleString()} ${this._polite ? 'POLITE' : 'IMPOLITE'} PEER #${this.peerNumber}: `;
    return `RTCPeerConnection(${this._polite ? 'POLITE' : 'IMPOLITE'} PEER #${localPeerNumber} -> PEER #${remotePeerNumber}): `;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getLogState(initialState?: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const logState: any = {
      ...initialState,
      peerConnectionState: this.connectionState,
      cloudConnectionState: this.cloudConnectionState,
      makingOffer: this._makingOffer,
      hasHeartbeatInterval: this._heartbeatIntervalId > 0,
      removeAfterDisconnect: this._removeAfterDisconnect,
      reconnectAttempts: this.reconnectAttempts,
      msgType: 'log', // For BetterStack log analysis, to filter against app messages going through websocket
    };

    const pc = this._peerConnection;
    if(pc) {
      const { signalingState, iceGatheringState, iceConnectionState, connectionState, currentLocalDescription, pendingLocalDescription, currentRemoteDescription, pendingRemoteDescription } = pc;
      logState.pc_signalingState = signalingState;
      logState.pc_iceGatheringState = iceGatheringState;
      logState.pc_iceConnectionState = iceConnectionState;
      logState.pc_connectionState = connectionState;
      //
      logState.hasCurrentLocalDescription = !!currentLocalDescription;
      logState.hasPendingLocalDescription = !!pendingLocalDescription;
      logState.hasCurrentRemoteDescription = !!currentRemoteDescription;
      logState.hasPendingRemoteDescription = !!pendingRemoteDescription;
    } else {
      logState.pc_missing = true;
    }

    const dc = this._dataChannel;
    if(dc) {
      const { readyState, bufferedAmount } = dc;
      logState.datachannel_readyState = readyState;
      logState.datachannel_buffered = bufferedAmount;
    } else {
      logState.datachannel_missing = true;
    }
    return logState;
  }

  updateFromEndpoint(endpoint: PrompterSessionEndpoint) {
    const {
      // endpointId, role, peerNumber, cloudLatency,
      peerNumber,
      appLifecycleState,
      clientIp,
      osName,
      osVersion,
      browserName,
      browserVersion,
      deviceType,
      connectedTimestamp,
      city,
      regionCode,
      region,
      country,
      continent,
      postalCode,
      latitude,
      longitude,
      timezone
    } = endpoint;

    // if(endpointId) {
    //   this.endpointId = endpointId;
    // }
    // this.endpointRole = role;
    if(peerNumber) {
      this.peerNumber = peerNumber;
    }
    this.appLifecycleState = appLifecycleState;
    // this._cloudLatency = cloudLatency;

    if(osName) {
      this.osName = osName;
    }
    if(osVersion) {
      this.osVersion = osVersion;
    }
    if(browserName) {
      this.browserName = browserName;
    }
    if(browserVersion) {
      this.browserVersion = browserVersion;
    }
    if(deviceType) {
      this.deviceType = deviceType;
    }

    if(clientIp) {
      this.clientIp = clientIp;
    }
    this.connectedTimestamp = connectedTimestamp;
    if(city) {
      this.city = city;
    }
    if(regionCode) {
      this.regionCode = regionCode;
    }
    if(region) {
      this.region = region;
    }
    if(country) {
      this.country = country;
    }
    if(continent) {
      this.continent = continent;
    }
    if(postalCode) {
      this.postalCode = postalCode;
    }
    if(latitude) {
      this.latitude = latitude;
    }
    if(longitude) {
      this.longitude = longitude;
    }
    if(timezone) {
      this.timezone = timezone;
    }
  }

  //
  // We will take the polite roll when other peers are connecting to this local instance.
  // This allows new joining prompters to control how quickly the establish multiple peer connections.
  // The new peer will take the impolite role.
  private _polite: boolean;
  public setPolite(polite: boolean) {
    this._polite = polite;
  }

  private _makingOffer = false;
  private _ignoringOffers = false;
  private _peerConnection?: RTCPeerConnection;
  private _dataChannel: RTCDataChannel | undefined;
  // private _disconnectRequested: boolean = false;

  public get representsLocal() {
    return this._representsLocal;
  }
  private _representsLocal: boolean;

  public get cloudConnectionState() {
    return this._cloudConnectionState;
  }
  // usePeerDevices will update the CloudConnectionState when it receives a disconenct notification
  // on our signalling websocket.
  public setCloudConnectionState(cloudConnectionState: ConnectionState) {
    if(this._cloudConnectionState === cloudConnectionState) {
      return;
    }

    this.logger.trace(`${this.getLogPrefix()} PrompterPeerInstance.setCloudConnectionState('${cloudConnectionState}')`);
    this._cloudConnectionState = cloudConnectionState;

    if(cloudConnectionState === ConnectionState.Disconnected) {
      this._cloudLatency = undefined;
    }

    this.requestDeviceReport();
  }
  private _cloudConnectionState: ConnectionState = ConnectionState.Connected;

  // AppController will update the LocalCloudConnectionState for all peers when the local websocket
  // connection state changes
  public setLocalCloudConnectionState(connectionState: ConnectionState) {
    this.logger.trace(`${this.getLogPrefix()} PrompterPeerInstance.setLocalCloudConnectionState('${connectionState})'`);
    this._localCloudConnectionState = connectionState;
    if(this.representsLocal) {
      this.setCloudConnectionState(connectionState);
      this.requestDeviceReport();
    }

    //
    // If we've lost our websocket connection and haven't had any peer traffic in more time than
    // our peer ping interval, then this peer connection is already failed, we just didn't know it
    // yet. The RTCPeerConnection usually take ~5 seconds to notice the peer connection has failed.
    //
    if(
      connectionState === ConnectionState.Disconnected
        && (Date.now() - this.lastPeerTrafficTimestamp) > (2.5 * PEER_HEARTBEAT_INTERVAL_MS)
    ) {
      this.logger.trace(this.getLogState(), `${this.getLogPrefix} Lost connection after browser frozen`);

      // If we lost connection for sometime, we no longer have the freshest copy of the script,
      // unless we are the only peer connected - TBD when we receive a 'connect.response' after
      // websocket is reconnected.
      usePrompterSession.getState().setLastScriptChangeTimestamp(Date.now() - ONE_YEAR_MS);

      // Our RTCPeerConnection is dead already, whether we know it or not.
      // We will recreate it when we receive a 'connect.response' after the websocket is
      // reconnected.
      this.rebuildConnection = true;
      return this.destroyRTCPeerConnection();
    }
  }
  private _localCloudConnectionState: ConnectionState = ConnectionState.Connected;

  public get cloudLatency() {
    return this._cloudLatency;
  }
  private _cloudLatency: number | undefined;

  /**
   * Informs this PeerInstance about the local cloud connection latency so that we can report it
   * to other peers we are connected with.
   * @param latencyMs
   */
  setLocalCloudLatency(latencyMs: number) {
    this._localCloudLatency = latencyMs;
    if(this.representsLocal) {
      this._cloudLatency = latencyMs;
      this.requestDeviceReport();
    }
  }
  private _localCloudLatency: number | undefined;

  public peerLatency: number | undefined;

  /**
   * Used for geo-location distance calculation
   */
  private deg2rad(deg: number) {
    return deg * (Math.PI/180);
  }

  /**
   * Used for geo-location distance calculation
   *
   * Haversine Formula: http://en.wikipedia.org/wiki/Haversine_formula
   * Original Source: http://www.movable-type.co.uk/scripts/latlong.html
   * Found on Stackoverflow: https://stackoverflow.com/a/27943
   */
  private getDistanceFromLatLonInKm(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2-lon1);
    const a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const d = R * c; // Distance in km
    return d;
  }

  setLocalLatitudeLongitude(latitude: string, longitude: string) {
    // console.log(`endpoint id ${this.endpointId}: local (${latitude}, ${longitude}) - remote (${this.latitude}, ${this.longitude})`);

    if(
      !latitude
        || !longitude
        || !this.latitude
        || !this.longitude
    ) {
      this._peerDistance = undefined;
      return;
    }

    //
    // Calculate distance between this peer prompter and the local prompter instance.
    //
    try {
      this._peerDistance = Math.ceil(this.getDistanceFromLatLonInKm(
        parseFloat(latitude),
        parseFloat(longitude),
        parseFloat(this.latitude),
        parseFloat(this.longitude),
      ));
    } catch(err) {
      // If we get bad data for latitude/longitude this may fail.
      // No need to raise alarm bells, we will simply have an unkown distance.
      // console.log(`endpoint id ${this.endpointId}: setLocalLatitudeLongitude() this.getDistanceFromLatLonInKm() threw excception`, err);
      this._peerDistance = undefined;
    }

    // console.log(`endpoint id ${this.endpointId}: distance to peer ~${this._peerDistance}km`);
  }
  private _peerDistance: number | undefined;

  private setPeerConnectionState(peerConnectionState: ConnectionState) {
    this._connectionState = peerConnectionState;

    if(peerConnectionState === ConnectionState.Disconnected) {
      this.peerLatency = undefined;
    }
    this.requestDeviceReport();
  }
  private getPeerConnectionState() {
    return this._connectionState;
  }
  private testPeerConnectionStateIs(connectionState: ConnectionState) {
    return this._connectionState === connectionState;
  }

  private async transitionPeerState(proposedPeerConnectionState: ConnectionState, trigger?: string) {
    const prevConnectionState = this.getPeerConnectionState();

    this.logger.warn(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.transitionPeerState(from ${prevConnectionState} to ${proposedPeerConnectionState})${trigger ? `\n${trigger}` : ''}`);

    //
    // If this is a user requested disconnect, we will have been in the 'disconnecting' state prior
    // to arriving at disconnected.
    //
    // If this is a connection failure disconnect, we will be arriving at the disconnected state
    // from 'connecting' or 'connected' state.
    //
    this._disconnectRequested = (
      prevConnectionState === ConnectionState.Disconnecting
        && proposedPeerConnectionState === ConnectionState.Disconnected
    );

    //
    // Now handle the state transition logic.
    //
    this.setPeerConnectionState(proposedPeerConnectionState);
    switch(proposedPeerConnectionState) {
      case ConnectionState.Connecting:
        // *DONE* trigger: connection enabled [disconnected -> connecting]
        // *DONE* trigger: connection lost (unexpected) [connected -> connecting]
        // trigger: connection timeout (websocket failed to connect) [connecting -> connecting]
        await this.handleConnecting(prevConnectionState);
        break;
      case ConnectionState.Connected:
        // *DONE* trigger: connection opened
        await this.handleConnected(prevConnectionState);
        break;
      case ConnectionState.Disconnecting:
        // *DONE* trigger: connection disabled [connected -> disconnecting]
        await this.handleDisconnecting(prevConnectionState);
        break;
      case ConnectionState.Disconnected:
        // *DONE* trigger: connection closed [disconnecting -> disconnected]
        // *DONE* trigger: connection disabled [connecting -> disconnected]
        await this.handleDisconnected(prevConnectionState);
        break;
    }

    // Now that we've handled the state change, let's update the UI layer with a fresh device
    // report.
    this.requestDeviceReport();
  }

  private _dataChannelOpenTimeout = 0;

  /**
   * The handle returned by setTimeout() that we can use to cancel the timeout callback upon
   * successful connection.
   */
  private peerConnectTimeout = 0;

  /**
   * The number of reconnection attempts we've made so far when retrying.
   */
  private reconnectAttempts = 0;

  /**
   * The number of milliseconds to delay before attempting to reconnect.
   */
  private reconnectInterval = 1000;

  /**
   * The maximum number of milliseconds to delay a reconnection attempt.
   */
  private maxReconnectInterval = 30000;

  /**
   * The maximum number of reconnection attempts to make. Unlimited if null.
   */
  private maxReconnectAttempts: number | undefined = 3;

  /**
   * The rate of increase of the reconnect delay. Allows reconnect attempts to back off when
   * problems persist.
   */
  private reconnectDecay = 1.5;

  /**
   * The maximum time in milliseconds to wait for a connection to succeed before closing and retrying.
   */
  private timeoutInterval = 30000;

  /**
   * Start the peer Heartbeat interval that will time our roundtrip time and keep the channel open.
   */
  private enableHeartbeat() {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} enableHeartbeat()`);

    // We will send 1 heartbeat ASAP so the new connection receives information about this peer.
    this._sendRTCHeartbeat();

    // We will schedule continuous heartbeats every 2000ms from now.
    if(this._heartbeatIntervalId) {
      window.clearInterval(this._heartbeatIntervalId);
    }
    this._heartbeatIntervalId = window.setInterval(this._sendRTCHeartbeat, PEER_HEARTBEAT_INTERVAL_MS);
  }

  /**
   * Clear the peer heartbeat interval that is used to time our roundtrip time and keep the channel open.
   */
  private disableHeartbeat() {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} disableHeartbeat()`);

    window.clearInterval(this._heartbeatIntervalId);
    this._heartbeatIntervalId = 0;
  }

  private _heartbeatIntervalId = 0;

  private async sleep(ms: number) {
    return new Promise((resolve) => window.setTimeout(resolve, ms));
  }

  /**
   * Called when transition from another state to the Connecting state.
   *   trigger: connection enabled [disconnected -> connecting]
   *   trigger: connection timeout [connecting -> connecting]
   *   trigger: connection lost [connected -> connecting]
   */
  private async handleConnecting(previousState: ConnectionState) {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.handleConnecting(Attempt #${this.reconnectAttempts}): connectionState = ${this._peerConnection?.connectionState}, iceConnectionState = ${this._peerConnection?.iceConnectionState}, signalingState = ${this._peerConnection?.signalingState}`);

    //
    // 1. Clear any prior connection timeout in the event that we are re-entering the [connecting]
    // state from the [connecting] state due to something other than hitting the timeout (such as
    // a network failure).
    //
    clearTimeout(this.peerConnectTimeout);

    //
    // 2. We never establish a connection with ourself - though we show ourself in the list of
    // prompter devices. We should never reach this state.
    //
    if(this.representsLocal) {
      throw new Error(`PrompterPeerInstance Invalid State (${this.connectionState}). Local Peer Instance does not have an rtc connection.`);
    }

    //
    // 3. We can't establish an RTCPeerConnection if we don't currently have a WebSocket connection
    // for signaling traffic.
    //
    // Also if the websocket is not currently connected, when it does later become connected
    // it will trigger any connections or reconnections upon websocket connect (so this
    // PrompterPeerInstance doesn't need to be responsible for the WebRTC reconnect as it will
    // be instructed when to try connecting again).
    //
    if(this._localCloudConnectionState !== ConnectionState.Connected) {
      this.logger.info(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.handleConnecting() - #5 - WebSocket not connected (${this._localCloudConnectionState})`);
      return this.remove();
    }

    //
    // 6. Limit how many times we can attempt to reconnect before disconnecting.
    //
    this.reconnectAttempts++;
    if(
      this.reconnectAttempts > 1
      && this.maxReconnectAttempts
      && this.reconnectAttempts > this.maxReconnectAttempts
    ) {
      // We've exhausted the number of reconnect attempts we are allowed to make.
      this.logger.error(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance exhausted maximum number of reconnect attempts.`);
      await this.remove();

      // If we've exhausted our ability to retry connections, let's just query the server to see
      // if the peer is even still around.
      // window.setTimeout(() => {
      //   console.log(`${this.getLogPrefix()} Send new GetSessionMessage!`, this.getLogState());
      //   this.deviceHost.appController.sendToServer(new GetSessionMessage());
      // }, 500);
      return;
    }
    //
    // When this peer disconnects, it could be for many reasons. If the peer truly disconnected,
    // there will no longer be a websocket connection and the server will report the peer as gone.
    //
    // If the peer is still connected via websocket, then the `getsession.response` will end up
    // triggering the connect() method to try and restore the connection.
    // if(this.reconnectAttempts > 1) {
    //   console.log(`${this.getLogPrefix()} Send new GetSessionMessage!`, this.getLogState());
    //   this.deviceHost.appController.sendToServer(new GetSessionMessage());
    // }

    //
    // Is this the 2nd or more time we've initiated connecting?
    // If yes, has it been longer than 8 seconds since we last received network traffic from the
    // other peer? If it has been >8 seconds since our last traffic and this is a reconnect, let's
    // rebuild our RTCPeerConnection.
    //
    // const disconnectedForMs = Date.now() - this.lastPeerTrafficTimestamp;
    // if(
    //   this.isReconnect
    //     && (disconnectedForMs > 8000)
    // ) {
    //   this.logger.warn(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.handleConnecting(Attempt #${this.reconnectAttempts}): reconnect after >8 seconds, destroy old peer connection, rebuildConnection = true`);
    //
    //   // We will use this flag if we are a polite peer sending a ConnectRequestMessage() below.
    //   this.rebuildConnection = true;
    // }

    //
    // If we decided to rebuild the RTCPeerConnection either here in this function or prior to
    // entering connecting, then let's destory the old RTCPeerConnection.
    //
    if(this.rebuildConnection && this._peerConnection) {
      return this.destroyRTCPeerConnection(() => {
        // callback will be executed in a new call stack via setTimeout to allow for garbage
        // collection of old RTCPeerConnection.
        this.transitionPeerState(ConnectionState.Connecting, 'destroyRTCPeerConnection() callback');
      });
    }

    //
    // 7. The 'impolite' peer is responsible for initiating the connection. If we are the polite peer,
    // we don't need to manage the connection timeout.
    //
    this.peerConnectTimeout = window.setTimeout(() => {
      this.logger.debug(this.getLogState(), `${this.getLogPrefix()} peerConnectTimeout _connectionState=${this._connectionState} (${this._polite ? 'polite' : 'impolite'})`);

      //
      // If we are still in 'connecting' state after timeout interval, try again.
      //
      if(this.testPeerConnectionStateIs(ConnectionState.Connecting)) {
        this.transitionPeerState(ConnectionState.Connecting, 'peerConnectTimeout');
      }

      // We will send a getsession message to the backend and when we get a getsession response we
      // will reconcile the list of connected endpoints to connect or remove peers as needed.
      // this.deviceHost.appController.sendToServer(new GetSessionMessage());
    }, this.timeoutInterval);

    //
    // This will usually do nothing as we already created the RTCPeerConnection in the constructor.
    //
    // However, if the peer device was sent a 'connect.request' message with the
    // 'rebuildConnection' flag, then we will have destroyed the RTCPeerConnection before
    // transitioning to the Connecting state again.
    //
    this.createRTCPeerConnection();

    //
    // If we already have a RTCDataChannel instance, then this is a reconnection.
    //
    if(
      this._peerConnection
        && this._peerConnection.iceConnectionState === 'failed'
    ) {
      this.logger.info(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.handleConnecting(Attempt #${this.reconnectAttempts}) -> restartIce(): connectionState = ${this._peerConnection?.connectionState}, iceConnectionState = ${this._peerConnection?.iceConnectionState}, signalingState = ${this._peerConnection?.signalingState}`);
      this._peerConnection.restartIce();
      return;
    }

    //
    // perfect negotiation doesn't work perfectly, particularly on iPhone...
    //
    if(this._polite) {
      // Send a connect message to the other peer instead of an SDP message. We want the impolite
      // peer to initiate our connection.
      this.logger.info(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.handleConnecting() -> Sending new ConnectRequestMessage(rebuildConnection = ${this.rebuildConnection})`);

      //
      // Request the other peer (impolite peer) initiate the connection to us with an Sdp Offer
      // message.
      //
      const thisEndpoint = this.deviceHost.appController.getLocalEndpointDescription();
      const connectRequestMsg = new ConnectRequestMessage({
        endpoint: thisEndpoint,
        rebuildConnection: this.rebuildConnection,
      });
      this.deviceHost.appController.dispatchMessage(connectRequestMsg, this.endpointId);
      return;
    }

    //
    // If we are an impolite peer and no edge case was encountered above, then let's create our
    // datachannel which will kick off the negotiationneeded event.
    //
    // Either we are a brand new PrompterPeerInstance never before connected, or we just recreated
    // our RTCPeerConnection above because the previous one was closed.
    //
    //if(!this._polite) {
    // await Promise.allSettled([
    //   this.createRTCDataChannel(),
    //   this.createRTCBackgroundMediaStream(),
    // ]);

    //
    // If we are an impolite peer and no edge case was encountered above, then let's create our
    // datachannel which will kick off the negotiationneeded event.
    //
    // if(!this._polite) {
    this.createRTCDataChannel();
    // }

    /*
     * WIP: Experimenting with reuseable Transceivers vs adding and removing "tracks"
     *
    //
    // TODO: In the future, we should pre-negotiate a bi-directional video channel between all
    // peers which begin inactive. Then we should just replace the track in the transceiver when
    // any peer begins sending video. This is an optimization and can be deferred for future.
    //
    const newBackgroundTransceiver = this._peerConnection!.addTransceiver('video', {
      direction: 'inactive',
      sendEncodings: [{
        // rid?: string;
        //
        // active?: boolean;
        active: false,
        // maxBitrate?: number;
        // maxFramerate?: number;
        // networkPriority?: RTCPriorityType;
        // priority?: RTCPriorityType;
        // scaleResolutionDownBy?: number;
      }],
    });
    this.setBackgroundTransceiver(newBackgroundTransceiver);
    */
  }

  /**
   * Called when transitioning from Connecting state to the Connected state.
   *   trigger: connection opened [connecting -> connected]
   */
  private async handleConnected(previousState: ConnectionState) {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.handleConnected(previousState: ${previousState})`);

    //
    // Valid states we could be transitioning from are Connecting.
    //
    switch(previousState) {
      case ConnectionState.Connecting:
        // trigger: connection opened [connecting -> connected]
        break;
      case ConnectionState.Disconnected:
        // trigger: connection re-established [disconnected -> connected]
        // This happens when ICE is temporarily disconnected, then reconnected within the timeout window before it ever went to 'failed'.
        break;
      default:
        throw new Error(`Error: PrompterPeerInstance cannot transition to 'connected' state from '${previousState}'`);
    }

    // Clear our connection timeout
    clearTimeout(this.peerConnectTimeout);
    // Reset the reconnection attempt counter after a successful connection.
    this.reconnectAttempts = 0;

    this.enableHeartbeat();

    if(this.representsLocal) {
      // We never establish a connection with ourself - though we show ourself in the list of
      // prompter devices. We should never reach this state.
      throw new Error(`PrompterPeerInstance Invalid State (${this.connectionState}). Local Peer Instance does not have an rtc connection.`);
    }

    this.emit('connected', new DeviceConnectedEvent(this));
  }

  /**
   * There is only one path to get to disconnecting, which is an intentional disconnect.
   *   trigger: reconnect attempts exhausted [connecting -> disconnecting]
   *   trigger: connection disabled [connected -> disconnecting]
   */
  private async handleDisconnecting(previousState: ConnectionState) {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.handleDisconnecting(previousState ${previousState})`);

    //
    // Valid states we could be transitioning from are Connecting or Connected.
    //
    switch(previousState) {
      case ConnectionState.Connecting:
        // trigger: reconnect attempts exhausted [connecting -> disconnecting]
        break;
      case ConnectionState.Connected:
        // trigger: connection disabled [connected -> disconnecting]
        break;
      default:
        throw new Error(`Error: PrompterPeerInstance cannot transition to Disconnecting state from ${previousState}`);
    }

    // If we weren't actually connected - just skip straight to cleanup and disconnected.
    return this.transitionPeerState(ConnectionState.Disconnected);
  }

  /**
   * There are 2 paths to get to disconnected
   *   trigger: connection closed [disconnecting -> disconnected]
   *   trigger: connection disabled [connecting -> disconnected]
   */
  private async handleDisconnected(previousState: ConnectionState) {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.handleDisconnected(previousState ${previousState})`);

    //
    // Valid states we could be transitioning from are Disconnecting or Connecting
    //
    switch(previousState) {
      case ConnectionState.Disconnecting:
        // trigger: connection closed [disconnecting -> disconnected]
        break;
      case ConnectionState.Connecting:
        // trigger: connection disabled [connecting -> disconnected]
        break;
      case ConnectionState.Connected:
        // trigger: connection lost [connected -> disconnected]
        break;
      default:
        throw new Error(`Error: PrompterPeerInstance cannot transition to Disconnected state from ${previousState}`);
    }

    //
    // We no longer know anything about this peer connection as we are disconnected.
    //
    this.peerLatency = undefined;

    //
    // If we lose the RTCPeerConnection we also no longer have knowledge of that peer's cloud
    // connection as that is reported via RTCDataChannel.
    //
    // TODO: Can we ask the cloud server if it still have a connection to this peer?
    // TODO: Ping the peer via websocket instead of via RTCDataChannel.
    //
    // this.setCloudConnectionState(ConnectionState.Disconnected);

    // RTCPeerConection should not exist in the disconnected state.
    // this.destroyRTCPeerConnection();

    // This will trigger DeviceHost to unregister this device as we've indicated this was a
    // userRequested disconnect.
    this.emit('disconnected', new DeviceDisconnectedEvent(this, this._disconnectRequested));

    //
    // If we became disconnected because of loss of network connectivity (no websocket connect and
    // lost peer connection) or we exhausted our reconnection attempts, then this device will have
    // been flagged for removal after disconnect.
    //
    if(this.removeAfterDisconnect) {
      this.remove();
    }
  }

  public async removeIfDisconnected() {
    if(this.testPeerConnectionStateIs(ConnectionState.Disconnected)) {
      this.remove();
    }
  }
  private async remove() {
    clearTimeout(this.peerConnectTimeout);
    clearTimeout(this._disconnectedTimeout);

    this.emit('removed', new DeviceRemovedEvent(this));
    await this.destroyRTCPeerConnection();
  }

  /**
   * The connect() method is called when we receive a `connect` or `connect.response` message via
   * the websocket control channel that is announcing the presence of another prompter peer
   * participating in this prompter session.
   *
   * Note: it is possible for us to receive a `connect` message when the peer connection is still
   * connected in the event that the sender lost its public internet connection but not its local
   * peer connection. This method should gracefully handle being called from any given state.
   *
   * @returns
   */
  async connect(rebuildConnection?: boolean) {
    //
    // We never establish a connection with ourself - though we show ourself in the list of
    // prompter devices.
    //
    if(this.representsLocal) {
      return;
    }

    //
    // If we have no websocket connection, we cannot possibly perform ICE signaling.
    // Also the websocket when connected will send a `connect` message and receive a
    // `connect.response` in return which will reconnect any peers.
    //
    if(this._localCloudConnectionState !== ConnectionState.Connected) {
      this.logger.warn(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.connect(rebuildConnection = ${rebuildConnection === true}) - Return early. WebSocket disconnected.`);
      return;
    }

    //
    // The rebuild connection flag will be set true when the peer who sent a connect request
    // message is the polite peer and failed to open the datachannel after iceconnectionstate
    // became 'connected'.
    //
    // This failure type is not recoverable and is usually a result of certain browsers (Safari on
    // iOS) not supporting implicit rollback for WebRTC Perfect Negotiation. In this case we would
    // rather destory the current RTCPeerConnection instances and rebuild them from scratch.
    //
    if(rebuildConnection === true) {
      this.logger.warn(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.connect(rebuildConnection === true): Destroy the current RTCPeerConnection`);

      // If there was any prior promise for this endpoint to be connected to the cloud, abandon it
      // now as we are about to start over with our connection.
      this.deviceHost.appController.rejectEndpointIdIsConnectedToCloud(this.endpointId);

      // Destroy our current RTCPeerConnection and then re-enter the connecting state.
      return this.destroyRTCPeerConnection(() => {
        // callback will be executed in a new call stack via setTimeout to allow for garbage
        // collection of old RTCPeerConnection.
        this.transitionPeerState(ConnectionState.Connecting, 'PrompterPeerInstance.connect(rebuildConnection === true): Transition to Connecting state');
      });
    }

    //
    // We don't want to initiate another connection flow unless we are currently disconnected.
    // In all other cases we expect some kind of peer connection failure event to trigger the
    // disconnection/reconnection.
    //
    // If we are Connected but our iceConnectionState is not connected or our dataChannel
    // readyState is not open, we will also trigger a reconnect.
    //
    if(
      this.testPeerConnectionStateIs(ConnectionState.Disconnected)
      || (this.testPeerConnectionStateIs(ConnectionState.Connected)
        && (
          this._peerConnection?.connectionState !== 'connected'
            || this._peerConnection?.iceConnectionState !== 'connected'
            || this._dataChannel?.readyState !== 'open'
        )
      )
    ) {
      this.logger.info(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.connect(rebuildConnection = false)`);

      return this.transitionPeerState(ConnectionState.Connecting, 'PrompterPeerInstance.connect(rebuildConnection = false) peer state is Disconnected');
    }

    this.logger.warn(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.connect(rebuildConnection = false) did nothing! Check state for why.`);
  }

  async disconnect() {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.disconnect()`);

    // This is an explicit request to disconnect this device, let's remove it after disconnect.
    this._removeAfterDisconnect = true;

    switch(this.getPeerConnectionState()) {
      case ConnectionState.Connecting:
      case ConnectionState.Connected:
        return this.transitionPeerState(ConnectionState.Disconnecting, 'PrompterPeerInstance.disconnect() called while connected/connecting');
      case ConnectionState.Disconnecting:
        break;
      case ConnectionState.Disconnected:
        // If we were already in a disconnected state, then we just need to notify the DeviceHost
        // that we are fully gone.
        clearTimeout(this.peerConnectTimeout);
        await this.destroyRTCPeerConnection();
        this.emit('removed', new DeviceRemovedEvent(this));
        break;
      default:
        break;
    }
  }

  static readonly DeviceKey: string = 'prompterpeer';
  static getDeviceDescriptors(t: TFunction): IDeviceDescriptor[] {
    return [{
      connectionType: DeviceConnectionType.Keyboard,
      deviceKey: PrompterPeerInstance.DeviceKey,
      deviceName: `Remote Prompter ${t('connectdevicedialog.remote')}`,
      deviceIcon: PrompterIcon,
      requiresPlanLevel: 0,
      priority: 2,
    }];
  }

  /**
   * True if we are reconnecting the peer.
   */
  private _isReconnect = false;

  /**
   * Initiate a WebRTC connection with the remote peer this instance represents.
   */
  private createRTCPeerConnection() {
    /*
    //
    // If we have a good connection, then we don't need to do anything.
    //
    if(this._peerConnection
      && (this._peerConnection.connectionState === 'new'
        // || this._peerConnection.connectionState === 'connecting'
        || this._peerConnection.connectionState === 'connected')
    ) {
      return;
    }

    //
    // If we are still here, the peer connection must be missing or in a bad state.
    // Destory whatever connection we have and let's start over with a fresh connection.
    //
    if(this._peerConnection) {
      await this.destroyRTCPeerConnection();
    }
    */

    // NEW: never destroy-recreate RTCPeerConnect - instead we are testing `restartIce()` strategy.
    if(this._peerConnection) {
      this.logger.info(this.getLogState(), `${this.getLogPrefix()} createRTCPeerConnection() - RTCPeerConnection already exists! Return early.`);
      return;
    }

    //
    // Create a new RTCPeerConnection
    //
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} createRTCPeerConnection() - new RTCPeerConnection()`);
    const rtcPeerConnection = new RTCPeerConnection(this.rtcConfig);
    this._peerConnection = rtcPeerConnection;

    //
    // Attach our RTC event handlers
    //
    rtcPeerConnection.addEventListener('connectionstatechange', this._handleRTCConnectionStateChange);
    rtcPeerConnection.addEventListener('iceconnectionstatechange', this._handleIceConnectionStateChange);
    rtcPeerConnection.addEventListener('icegatheringstatechange', this._handleIceGatheringStateChange);
    rtcPeerConnection.addEventListener('signalingstatechange', this._handleSignalingStateChange);
    rtcPeerConnection.addEventListener('negotiationneeded', this._handleNegotiationNeeded);
    rtcPeerConnection.addEventListener('icecandidate', this._handleIceCandidate);
    rtcPeerConnection.addEventListener('icecandidateerror', this._handleIceCandidateError);
    // rtcPeerConnection.addEventListener('datachannel', this._handleDataChannel);
    rtcPeerConnection.addEventListener('track', this._handleTrackEvent);

    /*
    //
    // The impolite peer is the peer that just joined this prompter session and the peer initiating
    // connections to all other peers already joined to this prompter session.
    //
    if(!this._polite && !this._dataChannel) {
      //
      // If we are the impolite peer, we will be initiating the WebRTC connection.
      //
      this.createRTCDataChannel();

      //
      // If we are currently the source of a background media stream, then we need to include that
      // in our connection negotiation.
      //
      this.createRTCBackgroundMediaStream();
    }
    */

    //
    // If we have some event handlers waiting for this PeerConnection to exist, let them know.
    // This happens when SdpMessages or IceCandidateMessages arrive faster than we can create the
    // RTCPeerConnection. The handlers for SdpMessage and IceCandidateMessage will:
    // `await promiseRtcPeerConnection()`
    //
    // if(this._resolvePeerConnection) {
    //   this._resolvePeerConnection.forEach(resolver => resolver(rtcPeerConnection));
    //   this._resolvePeerConnection = [];
    // }
  }

  private async createRTCBackgroundMediaStream() {
    this.logger.trace(`${this.getLogPrefix()} PrompterPeerInstance.createRTCBackgroundMediaStream()`);

    if(!this._peerConnection) {
      // throw new Error('this._peerConnection undefined.');
      return;
    }

    //
    // This is the same logic that is in sendBackgroundMediaStream() method.
    // In future we should move this to a private method and also use transceivers instead of
    // senders so that we can switch background senders in a session.
    //
    if(this._backgroundTrack) {
      try {
        const videoSender = this._peerConnection.getSenders().find(e => e.track?.kind === 'video');
        if (videoSender == null) {
          this.logger.info(this.getLogState(), 'Initiating video sender');
          this._peerConnection.addTrack(this._backgroundTrack); // will create sender, streamless track must be handled on another side here
          // this._peerConnection.addTransceiver(this._backgroundTrack);
        } else {
          this.logger.info(this.getLogState(), 'Updating video sender');
          await videoSender.replaceTrack(this._backgroundTrack); // replaceTrack will do it gently, no new negotiation will be triggered
        }
      } catch(err) {
        this.logger.error(err as Error, 'Could not addTrack.');
      }
    }
  }

  private async destroyRTCPeerConnection(callback?: () => void) {
    this.logger.warn(this.getLogState(), `${this.getLogPrefix()} destroyRTCPeerConnection()`);

    if(!this.testPeerConnectionStateIs(ConnectionState.Disconnected)) {
      await this.transitionPeerState(ConnectionState.Disconnected);
    }

    this.destroyRTCDataChannel();

    if(!this._peerConnection) {
      return;
    }

    //
    // Dettach our RTC event handlers
    //
    this._peerConnection.removeEventListener('connectionstatechange', this._handleRTCConnectionStateChange);
    this._peerConnection.removeEventListener('iceconnectionstatechange', this._handleIceConnectionStateChange);
    this._peerConnection.removeEventListener('icegatheringstatechange', this._handleIceGatheringStateChange);
    this._peerConnection.removeEventListener('signalingstatechange', this._handleSignalingStateChange);
    this._peerConnection.removeEventListener('negotiationneeded', this._handleNegotiationNeeded);
    this._peerConnection.removeEventListener('icecandidate', this._handleIceCandidate);
    this._peerConnection.removeEventListener('icecandidateerror', this._handleIceCandidateError);
    // this._peerConnection.removeEventListener('datachannel', this._handleDataChannel);
    this._peerConnection.removeEventListener('track', this._handleTrackEvent);

    //
    // Close each track
    //
    // this._peerConnection.getSenders().forEach(sender => {
    //   sender.track?.stop();
    // });
    // this._peerConnection.getTransceivers().forEach(xcvr => {
    //   xcvr.sender.track?.stop();
    // });

    //
    // Close the peer connection, if not already.
    //
    this._peerConnection.close();
    this._peerConnection = undefined;

    //
    // It seems closing a prior RTCPeerConnection is somewhat asyncronous and we want to be sure
    // the browser has had a chance to clean-up any old resources before we create a new
    // RTCPeerConnection.
    //
    // This also creates a new callstack and allows the current callstack to finish and references
    // to go out of scope and garbage collected.
    //
    if(callback) {
      window.setTimeout(callback, 10);
    }
  }

  private createRTCDataChannel() {
    if(this._dataChannel && this._dataChannel.readyState === 'closed') {
      // We've got a datachannel which is closed. We need to recreate it.
      this.destroyRTCDataChannel();
    }

    if(this._dataChannel) {
      // We've already previously created our datachannel.
      this.logger.warn(this.getLogState(), `createRTCDataChannel() - DataChannel instance already exists. Returning early. (readyState = ${this._dataChannel.readyState})`);
      return;
    }

    if(!this._peerConnection) {
      this.logger.error('peerConnection is undefined');
      return;
    }

    //
    // Create our DataChannel
    //
    // interface RTCDataChannelInit {
    //   id?: number;
    //   maxPacketLifeTime?: number;
    //   maxRetransmits?: number;
    //   negotiated?: boolean;
    //   ordered?: boolean;
    //   protocol?: string;
    // }
    //

    try {
      const sendChannel = this._peerConnection.createDataChannel('messages', {
        negotiated: true,
        id: 0,
      });
      sendChannel.binaryType = 'arraybuffer';
      sendChannel.addEventListener('open', this._handleDataChannelOpen);
      sendChannel.addEventListener('error', this._handleDataChannelError);
      sendChannel.addEventListener('close', this._handleDataChannelClose);
      sendChannel.addEventListener('message', this._handleDataChannelMessage);
      // sendChannel.addEventListener('bufferedamountlow', this._handleBufferedAmountLow);

      this.logger.info(this.getLogState(), `DataChannel Initial ReadyState = ${sendChannel.readyState}`);

      this._dataChannel = sendChannel;
    } catch(err) {
      this.logger.warn(this.getLogState({
        error: err
      }), 'Could not create RTCDataChannel. This most often occurs during a reconnect when the data channel already exists.');
    }
  }

  private destroyRTCDataChannel() {
    this.logger.warn(this.getLogState(), `${this.getLogPrefix()} destroyRTCDataChannel()`);

    //
    // In case we are destroying this peer connection when we never full succeeded to connect and
    // retrieve peer state (ie: lost peer connection during the first few millisecond of
    // establishing the connection)
    //
    if(this._rejectPromisePeerState) {
      this._rejectPromisePeerState(new Error('destroyRTCDataChannel()'));
    }

    this.disableHeartbeat();

    if(!this._dataChannel) {
      return;
    }

    this._dataChannel.removeEventListener('open', this._handleDataChannelOpen);
    this._dataChannel.removeEventListener('error', this._handleDataChannelError);
    this._dataChannel.removeEventListener('close', this._handleDataChannelClose);
    this._dataChannel.removeEventListener('message', this._handleDataChannelMessage);
    // this._dataChannel.removeEventListener('bufferedamountlow', this._handleBufferedAmountLow);

    // Right now the only code calling destroyRTCDataChannel() is _handleDataChannelClose event.
    // readyState = "closed" | "closing" | "connecting" | "open";
    // if(this._dataChannel.readyState !== 'closed') {
    //   try {
    //     console.warn(`${this.getLogPrefix()} this._dataChannel.close()`, this.getLogState());
    //     this._dataChannel.close();
    //   } catch(err) {
    //     console.error('Error trying to close existing datachannel.', err);
    //   }
    // }
    // this._dataChannel.close();
    this._dataChannel = undefined;
  }

  /**
   * RTCPeerConnection 'connectionstatechange' event handler
   * @param e
   * @returns
   */
  private async handleRTCConnectionStateChange(e: Event) {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} RTCPeerConnection.onconnectionstatechange('${this._peerConnection?.connectionState}'), iceConnectionState = ${this._peerConnection?.iceConnectionState}, websocketConnectionState = ${this.deviceHost.appController.websocketConnectionState}`);

    // "closed" | "connected" | "connecting" | "disconnected" | "failed" | "new";
    switch(this._peerConnection?.connectionState) {
      case 'disconnected': {
        this._rtcDisconnectedTimestamp = Date.now();

        // When we become disconnected, we will reset the connection start timestamp so that
        // we get an accurate measurement of the reconnection time.
        //
        // Apparently we can go from connected -> disconnected -> connected skipping the
        // 'connecting' stage entirely in the case where it is a momentary network interuption
        // and not a brand new RTCPeerConnection.
        this._rtcConnectingTimestamp = Date.now();
        this._isReconnect = true;

        this._cloudLatency = undefined;
        this.peerLatency = undefined; // We don't know this anymore.

        // If the WebRTC connection becomes disconnected while we are online, then it was an
        // intermittant network failure like bad wifi. The browser will try to recover for about
        // 30 seconds before either returning to 'connected' or becoming 'failed'.
        //
        // If the navigator is not online, then the network adaptor has been turned off, such as
        // disable wifi or entering airplane mode.
        if(!window.navigator.onLine) {
          return this.remove();
        }

        break;
      }
      case 'failed':
        this._rtcFailedTimestamp = Date.now();
        this.peerLatency = undefined; // We no longer know our peer latency

        // console.log(, this._peerConnection);
        this.logger.info(this.getLogState(), `${this.getLogPrefix()} RTCPeerConnection FAILED (attempt = ${this.reconnectAttempts}, cloudConnectionState = ${this._cloudConnectionState})`);
        // Failed is more serious than disconnected. Failed means we need to start our connection
        // over again from square 1 as the ICE transport is no longer viable.
        // If the app is currently hidden, we are likely being starved of resources by the host
        // device's power management strategy. Particularly on mobile devices such as tablets and
        // phones, the OS will throttle your CPU usage, and slow down timers until you lose
        // any active connections - in that case let's just acknowledge we are disconnecting.
        // if(this.appLifecycleState === AppLifecycleState.Hidden) {
        //   return this.remove();
        // }

        //
        // A 'failed' connection cannot be recovered. We will create a new RTCPeerConnection as
        // part of our next connection attempt.
        //
        // await this.destroyRTCPeerConnection();

        //
        // If the peer was not hidden when this connection failed, then it may have lost its
        // network connection entirely or have been hard reloaded (like a browser reload).
        //
        this.checkConnectionLost();
        return;

      case 'closed':
        this.checkConnectionLost();
        break;
      case 'connecting':
        this._rtcConnectingTimestamp = Date.now();
        break;
      case 'connected':
        this._rtcConnectedTimestamp = Date.now();

        this.logger.info(this.getLogState(), `${this.getLogPrefix()} RTCPeerConnection.onconnectionstatechange(connectionState = '${this._peerConnection?.connectionState}', signalingState = '${this._peerConnection?.signalingState}', iceConnectionState = '${this._peerConnection?.iceConnectionState}', iceGatheringState = '${this._peerConnection?.iceGatheringState}')`);

        this.checkConnectionEstablished();

        // We wait to negotiate the background video stream if there is one because we don't know
        // if the background is coming from the polite peer or impolite peer before negotiating
        // the RTCPeerConnection.
        //
        // Once connected, we can renegotiate anytime!
        // await this.createRTCBackgroundMediaStream();
        return;
    }
  }
  private _handleRTCConnectionStateChange: (e: Event) => Promise<void>;

  private _rtcConnectedTimestamp = 0;

  /**
   * Save a timestamp when we begin connecting an RTCPeerConnection so that we can calculate how
   * long it took to establish the peer connection.
   */
  private _rtcConnectingTimestamp = 0;

  private _rtcDisconnectedTimestamp = 0;
  private _rtcFailedTimestamp = 0;

  /**
   * RTCPeerConnection 'iceconnectionstatechange' event handler
   * @param e
   * @returns
   */
  private async handleIceConnectionStateChange(e: Event) {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} RTCPeerConnection.oniceconnectionstatechange('${this._peerConnection?.iceConnectionState}'), connectionState = ${this._peerConnection?.connectionState}, websocketConnectionState = ${this.deviceHost.appController.websocketConnectionState}`);

    switch(this._peerConnection?.iceConnectionState) {
      case 'new':
        break;
      case 'checking':
        break;
      case 'completed':
        break;
      case 'connected':
        clearTimeout(this._disconnectedTimeout);

        //
        // Our DataChannel should fire the DataChannel Open event very shortly after our
        // IceConnectionState changes to connected. If it doesn't, something is wrong.
        //
        this._dataChannelOpenTimeout = window.setTimeout(() => {
          this.logger.warn(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.dataChannelOpenTimeout(RTCDataChannel.readyState = '${this._dataChannel?.readyState}'): Destroy the current RTCPeerConnection then send connect request`);

          // We want to escape the traditional reconnect loop here as we will initiate a rebuild connection flow.
          clearTimeout(this.peerConnectTimeout);

          if(this._polite) {
            //
            // We are going to initiate a rebuild connection flow. Destroy the current RTCPeerConnection.
            //
            return this.destroyRTCPeerConnection(() => {
              //
              // Request the other peer (impolite peer) initiate the connection and set the
              // rebuildConnection flag so the other peer also destroys and recreates its
              // RTCPeerConnection instance.
              //
              const thisEndpoint = this.deviceHost.appController.getLocalEndpointDescription();
              const connectRequestMsg = new ConnectRequestMessage({
                endpoint: thisEndpoint,
                rebuildConnection: true,
              });
              this.deviceHost.appController.dispatchMessage(connectRequestMsg, this.endpointId);
            });
          }
        }, 3500);

        return this.checkConnectionEstablished();
      case 'disconnected':
        this.peerLatency = undefined; // We no longer know our peer latency

        this._disconnectedTimeout = window.setTimeout(() => {
          this.remove();
        }, 20000);
        break;
      case 'failed':
        this.peerLatency = undefined; // We no longer know our peer latency

        clearTimeout(this._disconnectedTimeout);
        return this.checkConnectionLost();
      case 'closed':
        return this.checkConnectionLost();
    }
  }
  private _handleIceConnectionStateChange: (e: Event) => void;

  private _disconnectedTimeout = 0;

  /**
   * RTCPeerConnection 'icegatheringstatechange' event handler
   * @param e
   */
  private async handleIceGatheringStateChange(e: Event) {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} RTCPeerConnection.onicegatheringstatechange('${this._peerConnection?.iceGatheringState}')`);
  }
  private _handleIceGatheringStateChange: (e: Event) => Promise<void>;

  /**
   * RTCPeerConnection 'signalingstatechange' event handler
   * @param e
   */
  private async handleSignalingStateChange(e: Event) {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} RTCPeerConnection.onsignalingstatechange('${this._peerConnection?.signalingState}')`);

    switch(this._peerConnection?.signalingState) {
      case 'stable':
        // SignalingState will return to stable after Sdp Offer and Answer have been exchanged.
        if(this._peerConnection.remoteDescription) {
          return this.applyQueuedIceCandidates();
        }
        break;
      case 'closed':
        // If the RTCPeerConnection is closed, it seems the earliest notification we receive on
        // Google Chrome on my MacBook Pro is the `signalingstate` becomes 'closed'.
        //
        // When an RTCPeerConnection is 'closed' it cannot be re-used, we must destroy it and start
        // over with a new instance if/when ready.
        await this.checkConnectionLost();
        break;
      default:
        break;
    }
  }
  private _handleSignalingStateChange: (e: Event) => Promise<void>;

  /**
   * RTCPeerConnection 'negotiationneeded' event handler
   * @returns
   */
  private async handleNegotiationNeeded(e: Event) {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} RTCPeerConnection.onnegotiationneeded(connectionState = '${this._peerConnection?.connectionState}', signalingState = '${this._peerConnection?.signalingState}', iceConnectionState = '${this._peerConnection?.iceConnectionState}', iceGatheringState = '${this._peerConnection?.iceGatheringState}', websocketConnectionState = ${this.deviceHost.appController.websocketConnectionState})`);

    /*
     * Ben may test this concept further in future, specifically testing Safari/iOS device which
     * occassionally get a relayed peer connection when the iOS device is the polite peer, sends
     * the first Sdp Offer, receiving colliding offer, rollsback and then sends answer.
     * Could we prevent the collision by using our own application messaging on the websocket
     * instead?
     */
    //
    // If this is the polite peer, we expect the other peer to initiate negotiation with an offer.
    // If we are attempting to reconnect after the first connection attempt, then we may send a
    // connect message to the other peer to prompt it to connect with us (in the case that we were
    // suspended in a sleep state, the connection failed, and the other side has already removed us
    // from the list of connected peers).
    //
    if(this._polite) {
      //
      // We are the polite peer, so we will just send a connection request to the other peer, not
      // an SdpOffer.
      //
      this.logger.info(this.getLogState(), `${this.getLogPrefix()} Sending connect request...`);

      const thisEndpoint = this.deviceHost.appController.getLocalEndpointDescription();
      const connectRequestMsg = new ConnectRequestMessage({
        endpoint: thisEndpoint,
      });
      this.deviceHost.appController.dispatchMessage(connectRequestMsg, this.endpointId);
      return;
    }

    if(!this._peerConnection) {
      this.logger.error('peerConnection is undefined');
      return;
    }

    try {
      this._makingOffer = true;

      //
      // TODO: In the future, we should pre-negotiate a bi-directional video channel between all
      // peers which begin inactive. Then we should just replace the track in the transceiver when
      // any peer begins sending video. This is an optimization and can be deferred for future.
      //
      // const xceiver = this._peerConnection.addTransceiver('video', {
      //   direction: 'sendrecv',
      //   sendEncodings: [{
      //     // rid?: string;
      //     //
      //     // active?: boolean;
      //     active: false,
      //     // maxBitrate?: number;
      //     // maxFramerate?: number;
      //     // networkPriority?: RTCPriorityType;
      //     // priority?: RTCPriorityType;
      //     // scaleResolutionDownBy?: number;
      //   }]
      // });

      await this._peerConnection.setLocalDescription();

      //
      // Prepare a closure for sending SdpOffer. If we timeout waiting for an Sdp Answer, we will
      // retransmit the offer.
      //
      const thisPeerConnection = this._peerConnection;
      const sendSdpOffer = () => {
        if(!thisPeerConnection.localDescription) {
          // If this is null, something wrong happened.
          this.logger.error(`${this.getLogPrefix()} pc.localDescription is undefined`);
          return;
        }

        this.logger.info(this.getLogState(), `${this.getLogPrefix()} Sending offer...\n${thisPeerConnection.localDescription.sdp}`);

        const thisEndpoint = this.deviceHost.appController.getLocalEndpointDescription();
        const targetEndpointId = this.endpointId;
        const signallingMsg = new SdpMessage(thisEndpoint, targetEndpointId, thisPeerConnection.localDescription);
        this.deviceHost.appController.dispatchMessage(signallingMsg, targetEndpointId);
      };

      //
      // Set timeout to receive an Sdp Answer from other peer.
      //
      this._waitingForSdpAnswerTimeout = window.setTimeout(() => {
        this.logger.error(this.getLogState(), `${this.getLogPrefix()} Did not receive Sdp Answer after sending offer.`);
        sendSdpOffer();
      }, 6000);

      //
      // Send our first attempt SdpOffer right away!
      //
      sendSdpOffer();
    } catch (err) {
      this.logger.error(err as Error);
    } finally {
      this._makingOffer = false;
    }
  }
  private _handleNegotiationNeeded: (e: Event) => Promise<void>;
  private _waitingForSdpAnswerTimeout = 0;

  /**
   * RTCPeerConnection 'datachannel' event handler
   * @param e
   */
  /*
  private async handleDataChannel(e: RTCDataChannelEvent) {
    // if(e.channel.readyState === 'open') {
    //   // If the datachannel is already open, we aren't going to see the 'open' event fired after
    //   // this point. So let's make sure we handle this.
    // }

    console.log(`${this.getLogPrefix()} RTCPeerConnection.ondatachannel: handleDataChannel()`, this.getLogState());
  }
  private _handleDataChannel: (e: RTCDataChannelEvent) => Promise<void>;
  */

  /**
   * RTCPeerConnection 'icecandidate' event handler
   * @param e
   * @returns
   */
  private async handleIceCandidate(e: RTCPeerConnectionIceEvent) {
    const targetEndpointId = this.endpointId;

    if(!e.candidate) {
      //
      // Once all ICE transports have finished gathering candidates and the value of the
      // RTCPeerConnection object's iceGatheringState has made the transition to complete, an
      // icecandidate event is sent with the value of candidate set to null.
      //
      // console.error(`Missing e.candidate in onicecandidate event type=${e.type}`);
      // Type === 'icecandidate'
      return;
    }

    if(!targetEndpointId) {
      this.logger.error(this.getLogState(), `${this.getLogPrefix()} Missing targetEndpointId in onicecandidate event type=${e.type}`);
      return;
    }

    this.deviceHost.appController.dispatchMessage(
      new IceCandidateMessage(targetEndpointId, e.candidate),
      targetEndpointId
    );
    if(this._peerConnection) {
      let candidateType = e.candidate.type;
      if(!candidateType) {
        //
        // Firefox doesn't provide the candidate type outside of the string representation of the
        // candidate. We need to check the string ourself.
        //
        if(e.candidate.candidate.indexOf('host') > 0) {
          candidateType = 'host';
        } else if (e.candidate.candidate.indexOf('prflx') > 0) {
          candidateType = 'prflx';
        } else if (e.candidate.candidate.indexOf('srflx') > 0) {
          candidateType = 'srflx';
        } else if (e.candidate.candidate.indexOf('relay') > 0) {
          candidateType = 'relay';
        }
      }
      // console.log(`${this.getLogPrefix()} Got a new IceCandidate to send (${candidateType})`, this.getLogState({
      //   candidate: e.candidate
      // }));
    }
  }
  private _handleIceCandidate: (e: RTCPeerConnectionIceEvent) => Promise<void>;

  private async handleIceCandidateError(e: Event) {
    // if(e.type === 'icecandidateerror') {
    //   //
    // }
    const IceErrorEvent = e as RTCPeerConnectionIceErrorEvent;

    // /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/RTCPeerConnectionIceErrorEvent/address) */
    // readonly address: string | null;
    // /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/RTCPeerConnectionIceErrorEvent/errorCode) */
    // readonly errorCode: number;
    // /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/RTCPeerConnectionIceErrorEvent/errorText) */
    // readonly errorText: string;
    // readonly port: number | null;
    // /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/RTCPeerConnectionIceErrorEvent/url) */
    // readonly url: string;

    // console.error(`${this.getLogPrefix()} IceCandidateError:`, e);
  }
  private _handleIceCandidateError: (e: Event) => Promise<void>;

  private async handleTrackEvent(e: RTCTrackEvent) {
    this.logger.info(this.getLogState({
      e,
    }), `${this.getLogPrefix()} PrompterPeerInstance.handleTrackEvent(): Received new RTC track!`);

    //
    // We got a new background stream track. Dispatch a local message to our VideoBackground
    // component so that it can display the background stream.
    //
    this.setBackgroundTransceiver(e.transceiver);

    let backgroundStream = e.streams[0];
    if(!backgroundStream) {
      // We are receiving a stream-less track - let's build our own stream!
      backgroundStream = new MediaStream([e.track]);
    }

    if(backgroundStream.active) {
      this.deviceHost.appController.dispatchMessage(new BackgroundMessage(backgroundStream));
    }
  }
  private _handleTrackEvent: (e: RTCTrackEvent) => Promise<void>;

  private setBackgroundTransceiver(backgroundTransceiver: RTCRtpTransceiver) {
    this._backgroundTransceiver = backgroundTransceiver;

    this._backgroundSender = backgroundTransceiver.sender;
    this._backgroundReceiver = backgroundTransceiver.receiver;

    this._backgroundReceiverTrack = this._backgroundReceiver.track;
    this._backgroundReceiverTrack.addEventListener('mute', () => {
      console.log('_backgroundReceiverTrack MUTE');
    });
    this._backgroundReceiverTrack.addEventListener('unmute', () => {
      console.log('_backgroundReceiverTrack UN-MUTE');
    });
    this._backgroundReceiverTrack.addEventListener('ended', () => {
      console.log('_backgroundReceiverTrack ENDED');
    });
  }
  private _backgroundTransceiver: RTCRtpTransceiver | undefined;
  private _backgroundSender?: RTCRtpSender;
  private _backgroundReceiver?: RTCRtpReceiver;
  private _backgroundReceiverTrack?: MediaStreamTrack;


  private _backgroundSenders: RTCRtpSender[] | undefined;
  // TODO: private _backgroundStream: MediaStream | undefined;
  private _backgroundTrack: MediaStreamTrack | undefined;

  /**
   * Provide a MediaStream to send to the conencted peer for use as the prompter background.
   * @param mediaStream
   * @returns
   */
  async sendBackgroundMediaStream(mediaStream: MediaStream) {
    this.logger.trace(`${this.getLogPrefix()} PrompterPeerInstance.sendBackgroundMediaStream()`);

    if (mediaStream != null && 'getTracks' in mediaStream) {
      const videoTracks = mediaStream.getVideoTracks();
      if(!videoTracks || !videoTracks.length) {
        // No video tracks in the provided media stream. This would only happen in a bug scenario
        // where we allowed a user to choose an audio source for the background media.
        throw new Error('MediaStream has no video tracks.');
      }

      const proposedBackgroundTrack = videoTracks[0];

      if(this._backgroundTrack === proposedBackgroundTrack) {
        // No change. We are re-assigning the same background track we already have.
        return;
      }

      this._backgroundTrack = proposedBackgroundTrack;

      if(this._representsLocal) {
        // There will be no peer connection for the instance representing this local prompter.
        // But we still wanted to keep a reference to our current background track for muting.
        return;
      }

      //
      // If we are not currently connected when a new background stream is being provided, then
      // we won't want to continue to create the media stream as it will be created as part of
      // our initial connection negotiation.
      //
      if(this._peerConnection?.connectionState !== 'connected') {
        return;
      }

      //
      // If we are currently the source of a background media stream, then we need to include that
      // in our connection negotiation.
      //
      this.createRTCBackgroundMediaStream();
    }

    this.logger.info(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance received mediaStream for WebRTC background!`);
  }

  /**
   * Change the current background track mute state.
   * @param muted true/false for desired mute state, or undefined to toggle current mute state
   * @returns current mute state
   */
  async muteBackgroundTrack(muted?: boolean): Promise<boolean> {
    if(!this._backgroundTrack) {
      return false;
    }

    // If no explicit state provided, we toggle muted.
    if(muted === undefined) {
      this._backgroundTrack.enabled = !this._backgroundTrack.enabled;
      return this._backgroundTrack.enabled;
    }

    // If explicit muted state provided, use that.
    this._backgroundTrack.enabled = muted;
    return this._backgroundTrack.enabled;

    //
    // toggleTrack(stream,type) {
    //   stream.getTracks().forEach((track) => {
    //       if (track.kind === type) {
    //           track.enabled = !track.enabled;
    //       }
    //   });
    // }
  }

  async clearBackgroundTrack() {
    if(this._representsLocal) {
      // There will be no peer connection for the instance representing this local prompter.
      this._backgroundTrack = undefined;
    }

    if(!this._peerConnection) {
      this.logger.error(this.getLogState(), 'PrompterPeerInstance.clearBackgroundTrack() peerConnection is undefined.');
      return;
    }

    const videoSender = this._peerConnection.getSenders().find(e => e.track?.kind === 'video');
    if (videoSender) {
      this.logger.info(this.getLogState(), 'Updating video sender with null');
      await videoSender.replaceTrack(null); // replaceTrack will do it gently, no new negotiation will be triggered
    }
    return;

    // The background track should now be disabled/stopped in the PrompterBackground component
    // which owns the source MediaStream
    /*
    if(this._backgroundTrack) {
      this._backgroundTrack.enabled = false;
      this._backgroundTrack.stop();

      // if(this._backgroundTransceiver) {
      //   this._backgroundTransceiver.stop();
      // }

      // this._peerConnection.getTransceivers().find(e => e.)
      // const videoSender = this._peerConnection.getSenders().find(e => e.track?.id === this._backgroundTrack?.id);
      // if(videoSender) {
      //   this._peerConnection!.removeTrack(videoSender);
      // }
      return;
    }

    if(this._backgroundSenders === undefined) {
      return;
    }

    this._backgroundSenders.forEach((sender) => {
      // sender.track!.enabled = false;
      // sender.replaceTrack()
      sender.track?.stop();
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      this._peerConnection!.removeTrack(sender);
    });
    */
  }

  private promisePeerState() {
    return new Promise<PeerStateResponse>((resolve, reject) => {
      const promiseTimeout = window.setTimeout(() => {
        reject();
      }, 4000);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this._rejectPromisePeerState = (reason?: any) => {
        this.logger.trace(this.getLogState(), `${this.getLogPrefix()} promisePeerState('${this.id}') REJECTED`);
        clearTimeout(promiseTimeout);
        reject(reason);
        this._resolvePromisePeerState = undefined;
        this._rejectPromisePeerState = undefined;
      };

      this._resolvePromisePeerState = (value: PeerStateResponse) => {
        this.logger.trace(this.getLogState(), `${this.getLogPrefix()} promisePeerState('${this.id}') RESOLVED`);
        clearTimeout(promiseTimeout);
        resolve(value);
        this._resolvePromisePeerState = undefined;
        this._rejectPromisePeerState = undefined;
      };

      // Do we want to retry sending this request if we don't get a response?
      this.sendMessage(new PeerStateRequest());
    });
  }
  private _resolvePromisePeerState?: (value: PeerStateResponse) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _rejectPromisePeerState?: (reason?: any) => void;

  /**
   * Called when:
   * - RTCPeerConnection.iceConnectionState becomes 'connected'
   * - RTCPeerConnection.connectionState becomes 'connected'
   * - DataChannel.readyState becomes 'open'
   */
  private async checkConnectionEstablished(): Promise<boolean> {
    const peerConnection = this._peerConnection;
    const dataChannel = this._dataChannel;

    const connectIsEstablished = peerConnection && dataChannel
      && peerConnection.iceConnectionState === 'connected'
      && peerConnection.connectionState === 'connected'
      && dataChannel.readyState === 'open';

    if(!connectIsEstablished) {
      this.logger.trace(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.checkConnectionEstablished() NOT established yet (connectionState = '${this._peerConnection?.connectionState}', signalingState = '${this._peerConnection?.signalingState}', iceConnectionState = '${this._peerConnection?.iceConnectionState}', iceGatheringState = '${this._peerConnection?.iceGatheringState}', dataChannel = '${this._dataChannel?.readyState}')`);
      return false;
    }

    // ****************************************
    // If we get here, then our RTCPeerConnection is fully established with an open DataChannel.
    // ****************************************
    this.logger.trace(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.checkConnectionEstablished() WAS ESTABLISHED! (connectionState = '${this._peerConnection?.connectionState}', signalingState = '${this._peerConnection?.signalingState}', iceConnectionState = '${this._peerConnection?.iceConnectionState}', iceGatheringState = '${this._peerConnection?.iceGatheringState}', dataChannel = '${this._dataChannel?.readyState}')`);

    // Reset the rebuildConnection flag after any successful connection.
    this.rebuildConnection = false;

    // We are connected now - clean-up any handing timeouts related to the connecting phase.
    clearTimeout(this.peerConnectTimeout);
    clearTimeout(this._dataChannelOpenTimeout);

    // How long did we take to establish this peer connect?
    const connectionTime = Date.now() - this._rtcConnectingTimestamp;
    this.logger.debug(this.getLogState(), `${this.getLogPrefix()} Peer ${this._isReconnect ? 'Reconnect' : 'Connection'} Established in ${connectionTime}ms`);

    // When the RTCPeerConnection becomes 'connected', let's check if our connection was
    // established peer-to-peer or via TURN relay.
    const peerConnectionStats = await peerConnection.getStats();
    this.evaluateEstablishedConnectionPath(peerConnectionStats);

    zaraz.track('peer_connected', {
      'connection_time': connectionTime,
      'connection_islocal': this.peerIsLocal === true,
      'connection_relayed': this.peerIsRelayed === true,
      'connection_reconnect': this._isReconnect,
    });

    //
    // When a connection is opened, we will request the other peer's application state. We will keep
    // trying until we either get a PeerStateResponse or the connection fails.
    //
    // This usually happen in one request-response, but because of some interesting race conditions
    // particularly on slower devices perhaps with slower or lossy network connections, it can take
    // more than one request before we get a response.
    //
    try {
      await promiseRetry((retry, number) => {
        this.logger.trace(this.getLogState(), `${this.getLogPrefix()} promisePeerState('${this.id}') attempt number ${number}`);

        return this.promisePeerState().catch(retry);
      }, {
        retries: 3, // (3 retries + 1 initial attempt = 4 total attempts)
        minTimeout: 100,
        maxTimeout: 3000,
      });

      this.logger.trace(this.getLogState(), `${this.getLogPrefix()} promisePeerState('${this.id}').then`);

      // Update our connection state, if required (not required when we temporarily lost our
      // iceconnection due to temporary packet loss, but then got traffic flowing again)
      if(!this.testPeerConnectionStateIs(ConnectionState.Connected)) {
        await this.transitionPeerState(ConnectionState.Connected);
      }

      return true;
    } catch (err) {
      // We did not receive the remote peer state.
      this.logger.error(this.getLogState({
        error: err
      }), `${this.getLogPrefix()} promisePeerState('${this.id}').catch`);
    }

    // If we didn't return above, then we were not able to retrieve the remote peer's state.
    return false;
  }

  /**
   * When
   * - DataChannel.readyState becomes closed
   * - IceConnectionState becomes 'failed' or 'closed'
   * - ConnectionState becomes 'failed' or 'closed'
   */
  private async checkConnectionLost() {
    if(this._isHandlingConnectionLost) {
      this.logger.trace(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.checkConnectionLost() - already handling lost connection, return early.`);
      return;
    }

    const peerConnection = this._peerConnection;
    const dataChannel = this._dataChannel;

    const connectIsLost = !peerConnection || !dataChannel
      || ['closed', 'closing'].indexOf(dataChannel.readyState) >= 0
      || ['failed', 'closed'].indexOf(peerConnection.connectionState) >= 0
      || ['failed', 'closed'].indexOf(peerConnection.iceConnectionState) >= 0
      || peerConnection.signalingState === 'closed';

    if(!connectIsLost) {
      this.logger.trace(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.checkConnectionLost() NOT lost yet (connectionState = '${this._peerConnection?.connectionState}', signalingState = '${this._peerConnection?.signalingState}', iceConnectionState = '${this._peerConnection?.iceConnectionState}', iceGatheringState = '${this._peerConnection?.iceGatheringState}, dataChannel = ${this._dataChannel?.readyState}')`);
      return;
    }

    // ****************************************
    // If we get here, then our RTCPeerConnection has failed somehow.
    // ****************************************
    this._isHandlingConnectionLost = true;
    this.logger.trace(this.getLogState(), `${this.getLogPrefix()} PrompterPeerInstance.checkConnectionLost() WAS LOST! (connectionState = '${this._peerConnection?.connectionState}', signalingState = '${this._peerConnection?.signalingState}', iceConnectionState = '${this._peerConnection?.iceConnectionState}', iceGatheringState = '${this._peerConnection?.iceGatheringState}, dataChannel = ${this._dataChannel?.readyState}')`);

    //
    // In case we lost this peer connection when we never fully succeeded to connect and
    // retrieve peer state (ie: lost peer connection during the first few millisecond of
    // establishing the connection)
    //
    if(this._rejectPromisePeerState) {
      this._rejectPromisePeerState(new Error('checkConnectionLost()'));
    }

    //
    // Clear the heartbeat interval that is used to time our roundtrip time and keep the channel open.
    //
    this.disableHeartbeat();
    this.peerLatency = undefined;

    //
    // If an RTCPeerConnection becomes 'closed' it can not be reused. We need to destroy the
    // current instance, and then create a new instance if/when ready.
    //
    // Note this is different than 'failed' or 'disconnected' states which can be recovered.
    //
    if(!peerConnection
      || peerConnection.connectionState === 'closed'
      || peerConnection.iceConnectionState === 'closed'
      || peerConnection.signalingState === 'closed'
    ) {
      return this.remove();
    }

    //
    // Check if this peer still has a websocket connection with this session.
    // If yes, we will reconnect.
    // If no, we will destroy and remove this PrompterPeerInstance.
    //
    let endpointIsConnectedToCloud = false;
    try {
      endpointIsConnectedToCloud = await this.deviceHost.appController.verifyEndpointIdIsConnectedToCloud(this.endpointId);
    } catch(err) {
      // Promise rejected - we cannot verify the target endpoint is connected either because it
      // isn't responding or because we lost our own network connectivity.
      this.logger.warn(this.getLogState({
        error: err
      }), `${this.getLogPrefix()} checkConnectionLost() endpointIsConnectedToCloud ERROR ${(err as Error).message}`);

      // If verifyEndpointIdIsConnectedToCloud() rejected, we should abandon our code path
      // entirely. The peer likely reloaded the window and reconnected with the rebuildConnection
      // flag set.
      return;
    } finally {
      this.logger.debug(this.getLogState(), `${this.getLogPrefix()} checkConnectionLost() endpointIsConnectedToCloud = ${endpointIsConnectedToCloud}`);
    }

    //
    // Sometimes the peer connection is re-established while we were in the middle of checking
    // signaling viability. If the connection is re-established, let's just return, problem solved!
    //
    if(
      this._peerConnection?.iceConnectionState === 'connected'
        && this._peerConnection?.connectionState === 'connected'
        && this._dataChannel?.readyState === 'open'
    ) {
      this.enableHeartbeat();
      return;
    }

    //
    // If the other endpoint is not reachable for signaling, we will consider removing this
    // PrompterPeerInstance as there is no way we can re-negotiate our RTC connection.
    //
    if(!endpointIsConnectedToCloud) {
      return this.remove();
    }

    //
    // How long has the RTCPeerConnection been lost?
    // If it's been a while, we should rebuild the peer connection rather than trying to resume
    // a stale RTCPeerConnection.
    //
    if((Date.now() - this.lastPeerTrafficTimestamp) > (2.5 * PEER_HEARTBEAT_INTERVAL_MS)) {
      this.rebuildConnection = true;

      return this.destroyRTCPeerConnection(() => {
        // callback will be executed in a new call stack via setTimeout to allow for garbage
        // collection of old RTCPeerConnection.
        this._isHandlingConnectionLost = false;
        this.transitionPeerState(ConnectionState.Connecting, 'checkConnectionLost() -> destroyRTCPeerConnection(): Transition to Connecting state (rebuildConnection === true)');
      });
    }

    //
    // If we get here the peer does have a connection to the cloud, but does not have a peer
    // connection with us.
    //
    // Initiate the reconnect process
    //
    this._isHandlingConnectionLost = false;
    return this.transitionPeerState(ConnectionState.Connecting, 'PrompterPeerInstance.connect() peer state is Disconnected');
  }
  private _isHandlingConnectionLost = false;

  /**
   * RTCDataChannel 'open' event handler
   * @returns
   */
  private async handleDataChannelOpen(e: Event) {
    // const targetDataChannel = e.target as RTCDataChannel;
    clearTimeout(this._dataChannelOpenTimeout);

    this.logger.info(this.getLogState({
      event: e,
    }), `${this.getLogPrefix()} handleDataChannelOpen()`);

    this.checkConnectionEstablished();
  }
  private _handleDataChannelOpen: (e: Event) => Promise<void>;

  /**
   * RTCDataChannel 'error' event handler
   * @param e
   */
  private async handleDataChannelError(e: Event) {
    if(e.type !== 'error') {
      return;
    }

    const rtcErrorEvent = e as RTCErrorEvent;
    const { error: rtcError } = rtcErrorEvent;
    const { readyState, bufferedAmount } = rtcErrorEvent.target as RTCDataChannel;

    this.logger.error(this.getLogState({
      dataChannelReadyState: readyState,
      bufferedAmount,
      dataChannelError: rtcErrorEvent,
    }), `${this.getLogPrefix()} DataChannel ERROR (${rtcError.sctpCauseCode}).\nThis prompter is currently ${this._polite ? 'polite' : 'impolite'}.\nImpolite peer should initiate the reconnect.`);

    // if(rtcError.sctpCauseCode === 12) {
    //   // SCTP Cause: User Initiated Abort
    //   return;
    // }

    // On iPad when the DataChannel is lost, we get 'sctp-failure' but NO close event is fired.
    if(rtcError.errorDetail === 'sctp-failure') {
      // errorDetail: "sctp-failure"
      // message: "Transport channel closed"
      // name: "OperationError"

      //
      // I believe datachannel error 'sctp-failure' is only caused when the other end of this
      // connection is terminated. Examples include navigating away from the prompter app, or
      // terminating the browser tab or window.
      //

      // this._removeAfterDisconnect = true;
      // this.transitionPeerState(ConnectionState.Disconnecting, 'DataChannel sctp-failure');


      // We will send a getsession message to the backend and when we get a getsession response we
      // will reconcile the list of connected endpoints to connect or remove peers as needed.
      // **MOVED: to RTCConnectionStateChange === failed
      // this.deviceHost.appController.sendToServer(new GetSessionMessage());
    }

    //
    // On MacOS on Safari when the other peer closes the page.
    //
    // code: 0
    // errorDetail: "sctp-failure"
    // message: "User-Initiated Abort, reason=Close called"
    // name: "OperationError"

    //
    // code: 0
    // errorDetail: "sctp-failure"
    // httpRequestStatusCode: null
    // message: "User-Initiated Abort, reason=Close called"
    // name: "OperationError"     // All RTCError objects have their name set to OperationError.
    // receivedAlert: null        // An unsigned long integer value indicating the fatal DTLS error which was received from the network. Only valid if the errorDetail string is dtls-failure. If null, no DTLS error was received.
    // sctpCauseCode: 12          // If errorDetail is sctp-failure, this property is a long integer specifying the SCTP cause code indicating the cause of the failed SCTP negotiation. null if the error isn't an SCTP error.
    // sdpLineNumber: null        // If errorDetail is sdp-syntax-error, this property is a long integer identifying the line number of the SDP on which the syntax error occurred. null if the error isn't an SDP syntax error.
    // sentAlert: null            // If errorDetail is dtls-failure, this property is an unsigned long integer indicating the fatal DTLS error that was sent out by this device. If null, no DTLS error was transmitted.
    //

    //
    // SCTP Cause Codes
    //
    // Cause Code
    // Value           Cause Code
    // ---------      ----------------
    //  1              Invalid Stream Identifier
    //  2              Missing Mandatory Parameter
    //  3              Stale Cookie Error
    //  4              Out of Resource
    //  5              Unresolvable Address
    //  6              Unrecognized Chunk Type
    //  7              Invalid Mandatory Parameter
    //  8              Unrecognized Parameters
    //  9              No User Data
    // 10              Cookie Received While Shutting Down
    // 11              Restart of an Association with New Addresses
    // 12              User Initiated Abort
    // 13              Protocol Violation
    //
  }
  private _handleDataChannelError: (e: Event) => Promise<void>;

  /**
   * RTCDataChannel 'close' event handler
   * @returns
   */
  private async handleDataChannelClose(e: Event) {
    const targetDataChannel = e.target as RTCDataChannel;

    this.logger.error(this.getLogState({
      event: e,
    }), `${this.getLogPrefix()} handleDataChannelClose() targetDataChannel.readyState=${targetDataChannel.readyState}, peerConnection.connectionState=${this._peerConnection?.connectionState}`);

    this.checkConnectionLost();
  }
  private _handleDataChannelClose: (e: Event) => Promise<void>;

  /**
   * RTCDataChannel 'message' event handler
   * @param e
   */
  private async handleDataChannelMessage(e: MessageEvent) {
    //
    // Update the last timestamp at which we received network traffic from this peer.
    //
    // This is used to differentiate between momentary connection failures such as switching wifi
    // networks, and longer periods of lost connectivity such as minimizing the browser, turning
    // off the device, etc and then later resuming the app.
    //
    this.lastPeerTrafficTimestamp = Date.now();

    const messageJson = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;

    const currentMsg = MessageUtils.deserializeJsonMessage(messageJson);
    if(!currentMsg) {
      return;
    }

    // console.log('Received RTC DataChannel Message!', e);

    //
    // Cache our most recent sender information for this peer. This meta information is used when
    // synchronizing scroll speed between multiple peers. Scroll speed must be recalculated anytime
    // the prompter content is resized while this prompter is not the current leader. This includes
    // resizing events as a result of loading the initial script from the current leader, resizing
    // the browser window, or change prompter appearance configuration for text size, margins, etc.
    //
    if(currentMsg.sender) {
      this.lastSenderInfo = currentMsg.sender;
    }

    switch(currentMsg.type) {
      case HeartbeatMessage.MESSAGE_NAME: {
        // 'heartbeat'
        const heartbeatMessage = currentMsg as HeartbeatMessage;
        this.handleRTCHeartbeat(heartbeatMessage);
        return;
      }
      case HeartbeatResponse.MESSAGE_NAME: {
        // 'heartbeat.response'
        const heartbeatResponse = currentMsg as HeartbeatResponse;
        this.handleRTCHeartbeatResponse(heartbeatResponse);
        return;
      }
      case SdpMessage.MESSAGE_NAME:
      case IceCandidateMessage.MESSAGE_NAME: {
        // These messages should never be sent via WebRTC DataChannel... but just in case, here's
        // an explicit ignore.
        return;
      }
      //
      // Control messages
      /*
      case PlayMessage.MESSAGE_NAME:
        currentMsg = PlayMessage.fromJson(msgJson);
        break;
      case PauseMessage.MESSAGE_NAME:
        currentMsg = PauseMessage.fromJson(msgJson);
        break;
      case EditMessage.MESSAGE_NAME:
        currentMsg = EditMessage.fromJson(msgJson);
        break;
      */
      default:
        // console.log('Received RTC DataChannel Message!', e);
        // This is down below: this.deviceHost.appController.handleMessage()
        break;
    }

    // Handle the message received on the RTCDataChannel!
    this.deviceHost.appController.handlePeerMessage(currentMsg);
  }
  private _handleDataChannelMessage: (e: MessageEvent) => Promise<void>;


  /**
   * This handler is fired when the buffered amount to send on this DataChannel reaches zero. This
   * is when we will handle retransmits when we turn off reliable/ordered datachannels in favour
   * of our own implementation. Instead of exponential backoff retransmits, we will favor a more
   * aggressive retransmit system to reduce latency on lossy networks (like wifi).
   * @param e
   */
  private async handleBufferedAmountLow(e: Event) {
    // console.log('DataChannel buffered amount low');
  }
  private _handleBufferedAmountLow: (e: Event) => Promise<void>;

  /**
   * Given an stats report from the RTCPeerConnection, evaluate whether our connection is over the
   * local network, peer-to-peer across network boundaries, or a relayed connection.
   */
  evaluateEstablishedConnectionPath(results: RTCStatsReport) {
    //
    // First figure out which candidate-pair was selected for this connection.
    //
    let activeCandidatePair = null;
    // Search for the candidate pair, spec-way first.
    results.forEach(report => {
      if (report.type === 'transport') {
        activeCandidatePair = results.get(report.selectedCandidatePairId);
      }
    });
    // Fallback for Firefox.
    if (!activeCandidatePair) {
      results.forEach(report => {
        if (report.type === 'candidate-pair' && report.selected) {
          activeCandidatePair = report;
        }
      });
    }

    //
    // Grab our selected candidate-pair members
    //
    let localCandidate = null;
    let remoteCandidate = null;
    if (activeCandidatePair) {
      localCandidate = results.get(activeCandidatePair['localCandidateId']);
      remoteCandidate = results.get(activeCandidatePair['remoteCandidateId']);
    }

    // console.log('localCandidate', localCandidate);
    // console.log('remoteCandidate', remoteCandidate);

    //
    // If either our local or remote candidate is a relay candidate then we have a TURN server
    // involved in this connection.
    //
    this.peerIsLocal = localCandidate.candidateType === 'host' || remoteCandidate.candidateType === 'host';
    this.peerIsRelayed = localCandidate.candidateType === 'relay' || remoteCandidate.candidateType === 'relay';
    this.requestDeviceReport();
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} Local Network Connection: ${this.peerIsLocal ? 'true' : 'false'}, Using TURN Relay: ${this.peerIsRelayed ? 'true' : 'false'}`);
  }

  showRemoteStats(results: RTCStatsReport) {
    // const statsString = dumpStats(results);
    // receiverStatsDiv.innerHTML = `<h2>Receiver stats</h2>${statsString}`;
    // calculate video bitrate
    /*
    results.forEach(report => {
      const now = report.timestamp;

      let bitrate;
      if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
        const bytes = report.bytesReceived;
        if (timestampPrev) {
          bitrate = 8 * (bytes - bytesPrev) / (now - timestampPrev);
          bitrate = Math.floor(bitrate);
        }
        bytesPrev = bytes;
        timestampPrev = now;
      }
      if (bitrate) {
        bitrate += ' kbits/sec';
        bitrateDiv.innerHTML = `<strong>Bitrate:</strong>${bitrate}`;
      }
    });
    */

    //
    // First figure out which candidate-pair was selected for this connection.
    //
    let activeCandidatePair = null;
    // Search for the candidate pair, spec-way first.
    results.forEach(report => {
      if (report.type === 'transport') {
        activeCandidatePair = results.get(report.selectedCandidatePairId);
      }
    });
    // Fallback for Firefox.
    if (!activeCandidatePair) {
      results.forEach(report => {
        if (report.type === 'candidate-pair' && report.selected) {
          activeCandidatePair = report;
        }
      });
    }

    //
    // Grab our selected candidate-pair members
    //
    let localCandidate = null;
    let remoteCandidate = null;
    if (activeCandidatePair) {
      localCandidate = results.get(activeCandidatePair['localCandidateId']);
      remoteCandidate = results.get(activeCandidatePair['remoteCandidateId']);
    }

    //
    // If either our local or remote candidate is a relay candidate then we have a TURN server
    // involved in this connection.
    //
    this.peerIsLocal = localCandidate.candidateType === 'host' || remoteCandidate.candidateType === 'host';
    this.peerIsRelayed = localCandidate.candidateType === 'relay' || remoteCandidate.candidateType === 'relay';
    this.requestDeviceReport();
    this.logger.info(`${this.getLogPrefix()} Local Network Connection: ${this.peerIsLocal ? 'true' : 'false'}, Using TURN Relay: ${this.peerIsRelayed ? 'true' : 'false'}`);

    switch(remoteCandidate['candidateType']) {        // host, srflx, prflx or relay
      case 'host':
        // The candidate is a host candidate, whose IP address as specified in the
        // RTCIceCandidate.address property is in fact the true address of the remote peer.
        break;
      case 'srflx':
        // The candidate is a server reflexive candidate; the ip and port are a binding allocated
        // by a NAT for an agent when it sent a packet through the NAT to a server. They can be
        // learned by the STUN server and TURN server to represent the candidate's peer anonymously.
        break;
      case 'prflx':
        // The candidate is a peer reflexive candidate; the ip and port are a binding allocated by
        // a NAT when it sent a STUN request to represent the candidate's peer anonymously.
        break;
      case 'relay':
        // The candidate is a relay candidate, obtained from a TURN server. The relay candidate's
        // IP address is an address the TURN server uses to forward the media between the two peers.
        break;
    }
  }

  private async sendRTCHeartbeat() {
    if(!this._peerConnection || !this._dataChannel) {
      this.logger.warn(this.getLogState(), `${this.getLogPrefix()} sendRTCHeartbeat() missing peerConnection or dataChannel`);
      return;
    }

    if(this._dataChannel.readyState !== 'open') {
      this.logger.warn(this.getLogState(), `${this.getLogPrefix()} sendRTCHeartbeat() dataChannel not open (readyState = ${this._dataChannel.readyState})`);
      return;
    }

    // let statsOutput = '';
    // console.log('****************************************');
    // const stats = await this._peerConnection.getStats();
    // this.showRemoteStats(stats);
    /*
    stats.forEach((report, key) => {
      console.log(`REPORT[${report.type}]`);
      if(report.type === 'candidate-pair') {
        // <h2>Report: candidate-pair</h2>
        // <strong>currentRoundTripTime:</strong> 0.001<br>
        // <strong>totalRoundTripTime:</strong> 0.002<br>
        statsOutput += `currentRoundTripTime=${report['currentRoundTripTime']}\n`;
        statsOutput += `totalRoundTripTime=${report['totalRoundTripTime']}\n`;
      }
    }, this);
    console.log(statsOutput);
    */
    // console.log('----------------------------------------');

    // host, srflx, prflx or relay

    // <h2>Report: media-playout</h2>
    // <strong>ID:</strong> AP<br>
    // <strong>Timestamp:</strong> 1693178578985.876<br>
    // <strong>kind:</strong> audio<br>
    // <strong>synthesizedSamplesDuration:</strong> 0<br>
    // <strong>synthesizedSamplesEvents:</strong> 0<br>
    // <strong>totalPlayoutDelay:</strong> 0<br>
    // <strong>totalSamplesCount:</strong> 0<br>
    // <strong>totalSamplesDuration:</strong> 0<br>
    //
    // <h2>Report: certificate</h2>
    // <strong>ID:</strong> CF8E:9C:AE:06:80:DF:A2:EA:88:17:D5:92:97:A4:C7:3F:D3:97:77:4E:7A:FA:95:91:B7:38:C1:DB:DC:D5:9C:33<br>
    // <strong>Timestamp:</strong> 1693178578985.876<br>
    // <strong>base64Certificate:</strong> MIIBFjCBvaADAgECAgkAxFVPaaM+NsYwCgYIKoZIzj0EAwIwETEPMA0GA1UEAwwGV2ViUlRDMB4XDTIzMDgyNjIzMjI1NloXDTIzMDkyNjIzMjI1NlowETEPMA0GA1UEAwwGV2ViUlRDMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEk0rjlKx36dZ+sAIfDc9dBHYpfCbwluaV7Wq9sxBOvCP7I01kYT8tIR3wG/75kaE7HbyTmMSntZO1s5KLzfRtMzAKBggqhkjOPQQDAgNIADBFAiAVTgqLXAeNS9vGasCoG+lyaPV5N+ZJn3gfJEOtzotJawIhAIHzINSndJbt1HUILd9xg6wDzpxnMRom2547L9GR+tS/<br>
    // <strong>fingerprint:</strong> 8E:9C:AE:06:80:DF:A2:EA:88:17:D5:92:97:A4:C7:3F:D3:97:77:4E:7A:FA:95:91:B7:38:C1:DB:DC:D5:9C:33<br>
    // <strong>fingerprintAlgorithm:</strong> sha-256<br>
    //
    // <h2>Report: certificate</h2>
    // <strong>ID:</strong> CFF1:DF:CA:F3:DA:10:4C:A2:7C:2A:22:33:3E:43:5F:73:5B:78:C7:BB:2C:B5:04:1E:A5:74:12:0D:E2:7B:56:0E<br>
    // <strong>Timestamp:</strong> 1693178578985.876<br>
    // <strong>base64Certificate:</strong> MIIBFTCBvaADAgECAgkAu6BGgwehoqQwCgYIKoZIzj0EAwIwETEPMA0GA1UEAwwGV2ViUlRDMB4XDTIzMDgyNjIzMjI1NloXDTIzMDkyNjIzMjI1NlowETEPMA0GA1UEAwwGV2ViUlRDMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAERXpOMP66TOE2M6HraBjkYGnQ+B1fxezUPRxqCk8yJHrVVvE6Ac6v5I6POI+enwtVipDNFFc9mxwsT3hWk2xlRTAKBggqhkjOPQQDAgNHADBEAiAlZqYKfXL5TJBiM72TN20VQZh1MPIm2QJwKjCui2XECQIgXROSuzm27M+I8B8wzAZWS7IANO5GnCjOUks73HLlqzw=<br>
    // <strong>fingerprint:</strong> F1:DF:CA:F3:DA:10:4C:A2:7C:2A:22:33:3E:43:5F:73:5B:78:C7:BB:2C:B5:04:1E:A5:74:12:0D:E2:7B:56:0E<br>
    // <strong>fingerprintAlgorithm:</strong> sha-256<br>
    //
    // <h2>Report: candidate-pair</h2>
    // <strong>ID:</strong> CPeOARE60j_s6glyXV4<br>
    // <strong>Timestamp:</strong> 1693178578985.876<br>
    // <strong>bytesDiscardedOnSend:</strong> 0<br>
    // <strong>bytesReceived:</strong> 6615<br>
    // <strong>bytesSent:</strong> 1466<br>
    // <strong>consentRequestsSent:</strong> 3<br>
    // <strong>currentRoundTripTime:</strong> 0.001<br>
    // <strong>lastPacketReceivedTimestamp:</strong> 1693178578985<br>
    // <strong>lastPacketSentTimestamp:</strong> 1693178577037<br>
    // <strong>localCandidateId:</strong> IeOARE60j<br>
    // <strong>nominated:</strong> true<br>
    // <strong>packetsDiscardedOnSend:</strong> 0<br>
    // <strong>packetsReceived:</strong> 12<br>
    // <strong>packetsSent:</strong> 9<br>
    // <strong>priority:</strong> 9079290933588803000<br>
    // <strong>remoteCandidateId:</strong> Is6glyXV4<br>
    // <strong>requestsReceived:</strong> 4<br>
    // <strong>requestsSent:</strong> 4<br>
    // <strong>responsesReceived:</strong> 4<br>
    // <strong>responsesSent:</strong> 4<br>
    // <strong>state:</strong> succeeded<br>
    // <strong>totalRoundTripTime:</strong> 0.002<br>
    // <strong>transportId:</strong> T01<br>
    // <strong>writable:</strong> true<br>
    //
    // <h2>Report: data-channel</h2>
    // <strong>ID:</strong> D1<br>
    // <strong>Timestamp:</strong> 1693178578985.876<br>
    // <strong>bytesReceived:</strong> 4699<br>
    // <strong>bytesSent:</strong> 210<br>
    // <strong>dataChannelIdentifier:</strong> 0<br>
    // <strong>label:</strong> messages<br>
    // <strong>messagesReceived:</strong> 2<br>
    // <strong>messagesSent:</strong> 1<br>
    // <strong>protocol:</strong> <br>
    // <strong>state:</strong> open<br>
    //
    // <h2>Report: local-candidate</h2>
    // <strong>ID:</strong> IeOARE60j<br>
    // <strong>Timestamp:</strong> 1693178578985.876<br>
    // <strong>address:</strong> <br>
    // <strong>candidateType:</strong> host<br>
    // <strong>foundation:</strong> 888078830<br>
    // <strong>ip:</strong> <br>
    // <strong>isRemote:</strong> false<br>
    // <strong>networkType:</strong> unknown<br>
    // <strong>port:</strong> 53683<br>
    // <strong>priority:</strong> 2113937151<br>
    // <strong>protocol:</strong> udp<br>
    // <strong>transportId:</strong> T01<br>
    // <strong>usernameFragment:</strong> G99G<br>
    //
    // <h2>Report: remote-candidate</h2>
    // <strong>ID:</strong> Is6glyXV4<br>
    // <strong>Timestamp:</strong> 1693178578985.876<br>
    // <strong>address:</strong> <br>
    // <strong>candidateType:</strong> host<br>
    // <strong>foundation:</strong> 4070837939<br>
    // <strong>ip:</strong> <br>
    // <strong>isRemote:</strong> true<br>
    // <strong>port:</strong> 53289<br>
    // <strong>priority:</strong> 2122194687<br>
    // <strong>protocol:</strong> udp<br>
    // <strong>transportId:</strong> T01<br>
    // <strong>usernameFragment:</strong> FPR3<br>
    //
    // <h2>Report: peer-connection</h2>
    // <strong>ID:</strong> P<br>
    // <strong>Timestamp:</strong> 1693178578985.876<br>
    // <strong>dataChannelsClosed:</strong> 0<br>
    // <strong>dataChannelsOpened:</strong> 1<br>
    //
    // <h2>Report: transport</h2>
    // <strong>ID:</strong> T01<br>
    // <strong>Timestamp:</strong> 1693178578985.876<br>
    // <strong>bytesReceived:</strong> 6615<br>
    // <strong>bytesSent:</strong> 1466<br>
    // <strong>dtlsCipher:</strong> TLS_ECDHE_ECDSA_WITH_AES_128_GCM_SHA256<br>
    // <strong>dtlsRole:</strong> client<br>
    // <strong>dtlsState:</strong> connected<br>
    // <strong>iceLocalUsernameFragment:</strong> G99G<br>
    // <strong>iceRole:</strong> controlled<br>
    // <strong>iceState:</strong> connected<br>
    // <strong>localCertificateId:</strong> CF8E:9C:AE:06:80:DF:A2:EA:88:17:D5:92:97:A4:C7:3F:D3:97:77:4E:7A:FA:95:91:B7:38:C1:DB:DC:D5:9C:33<br>
    // <strong>packetsReceived:</strong> 12<br>
    // <strong>packetsSent:</strong> 9<br>
    // <strong>remoteCertificateId:</strong> CFF1:DF:CA:F3:DA:10:4C:A2:7C:2A:22:33:3E:43:5F:73:5B:78:C7:BB:2C:B5:04:1E:A5:74:12:0D:E2:7B:56:0E<br>
    // <strong>selectedCandidatePairChanges:</strong> 1<br>
    // <strong>selectedCandidatePairId:</strong> CPeOARE60j_s6glyXV4<br>
    // <strong>srtpCipher:</strong> AES_CM_128_HMAC_SHA1_80<br>
    // <strong>tlsVersion:</strong> FEFD<br>


    // <h2>Report: candidate-pair</h2>
    // <strong>currentRoundTripTime:</strong> 0.001<br>
    // <strong>totalRoundTripTime:</strong> 0.002<br>

    // <h2>Report: local-candidate</h2>
    // <strong>candidateType:</strong> host<br>

    // <h2>Report: remote-candidate</h2>
    // <strong>candidateType:</strong> host<br>

    // <h2>Report: transport</h2>
    // <strong>selectedCandidatePairId:</strong> CPeOARE60j_s6glyXV4<br>
    // <strong>bytesReceived:</strong> 6615<br>
    // <strong>bytesSent:</strong> 1466<br>

    /*
    stats.forEach((report) => {
      statsOutput +=
        `<h2>Report: ${report.type}</h2>\n<strong>ID:</strong> ${report.id}<br>\n` +
        `<strong>Timestamp:</strong> ${report.timestamp}<br>\n`;

      // Now the statistics for this report; we intentionally drop the ones we
      // sorted to the top above

      Object.keys(report).forEach((statName) => {
        if (
          statName !== 'id' &&
          statName !== 'timestamp' &&
          statName !== 'type'
        ) {
          statsOutput += `<strong>${statName}:</strong> ${report[statName]}<br>\n`;
        }
      });
    });
    console.log(statsOutput);
    */

    const heartbeatMessage = new HeartbeatMessage(this.deviceHost.appController.endpointRole);
    heartbeatMessage.cloudConnectionState = this._localCloudConnectionState;
    heartbeatMessage.cloudLatency = this._localCloudLatency;

    // console.log(`${this.getLogPrefix()} Send new HeartbeatMessage: sendRTCHeartbeat()`, this.getLogState());

    this.sendMessage(heartbeatMessage);
  }
  private _sendRTCHeartbeat: () => Promise<void>;

  private async waitForHeartbeatResponse(timeout?: number): Promise<HeartbeatResponse> {
    const promise = new Promise<HeartbeatResponse>((resolve, reject) => {
      const promiseTimeout = window.setTimeout(() => {
        reject();
      }, timeout);

      this._heartbeatResolve = (value: HeartbeatResponse) => {
        clearTimeout(promiseTimeout);
        resolve(value);
        this._heartbeatResolve = undefined;
      };
    });
    return promise;
  }
  private _heartbeatResolve?: (value: HeartbeatResponse) => void;

  private handleRTCHeartbeat(heartbeatMessage: HeartbeatMessage) {
    // console.log(`${this.getLogPrefix()} Received HeartbeatMessage: handleRTCHeartbeat()`, heartbeatMessage);

    // The remote peer will report its cloudLatency to us in heartbeat messages.
    const proposedCloudConnectionState = heartbeatMessage.cloudConnectionState;
    const proposedCloudLatency = heartbeatMessage.cloudLatency;
    if(
      proposedCloudConnectionState !== this._cloudConnectionState
      || proposedCloudLatency !== this._cloudLatency
    ) {
      if(proposedCloudConnectionState !== undefined) {
        this.setCloudConnectionState(proposedCloudConnectionState);
      }
      if(proposedCloudLatency !== undefined) {
        this._cloudLatency = proposedCloudLatency;
      }
      this.requestDeviceReport();
    }

    // Now let's compose a HeartbeatResponse for the remote peer.
    const heartbeatResponse = new HeartbeatResponse(heartbeatMessage);
    this.sendMessage(heartbeatResponse);
  }

  /**
   * All PrompterPeerInstances will be notified when the current leader endpointId changes.
   *
   * The current leader endpointId may be updated whenever we receive a control command from any
   * prompter, remote or local.
   */
  public handleLeaderDesignation(currentLeaderId: string) {
    const proposedThisIsLeader = (this.id === currentLeaderId);

    if(proposedThisIsLeader !== this.isLeader) {
      this.logger.debug(`Change Peer #${this.peerNumber} isLeader from ${this._isLeader} to ${proposedThisIsLeader}`);

      // Save the last timestamp this PrompterPeerInstance was known to be the leader.
      //
      // In the event that the current leader disconnects, the last known prior leader will be
      // designated leader again.
      if(proposedThisIsLeader) {
        // We are changing from isLeader=false to isLeader=true
        this._lastLeaderTimestamp = Date.now();
      }

      this.isLeader = proposedThisIsLeader;
      this.requestDeviceReport();
    }
  }

  public get isLeader() {
    return this._isLeader;
  }
  public set isLeader(value: boolean) {
    this._isLeader = value;
  }
  private _isLeader = false;

  public get lastLeaderTimestamp() {
    return this._lastLeaderTimestamp;
  }
  public set lastLeaderTimestamp(value: number) {
    this._lastLeaderTimestamp = value;
  }
  private _lastLeaderTimestamp;


  public get lastScriptChangeTimestamp() {
    return this._lastScriptChangeTimestamp;
  }
  public set lastScriptChangeTimestamp(value: number | undefined) {
    this._lastScriptChangeTimestamp = value;
  }
  private _lastScriptChangeTimestamp: number | undefined;


  public lastSenderInfo?: SenderInfo;

  private rebuildConnection = false;
  private lastPeerTrafficTimestamp = Date.now();

  /**
   * This prompter instance just received a HeartbearResponse message from connected remote peer.
   * We will update our last known roundtrip time for this peer connection and update the UI.
   *
   * @param heartbeatResponse
   * @returns
   */
  private handleRTCHeartbeatResponse(heartbeatResponse: HeartbeatResponse) {
    const { senderTimestamp } = heartbeatResponse;
    const latencyMs = Math.round(performance.now() - senderTimestamp);

    if(this.representsLocal) {
      return;
    }

    // console.log(`${this.getLogPrefix()} Received HeartbeatResponse: handleRTCHeartbeatResponse()`, this.getLogState());

    this.peerLatency = latencyMs;

    // If we have an open promise waiting for the heartbeat response, then let's resolve it.
    if(this._heartbeatResolve) {
      this._heartbeatResolve(heartbeatResponse);
    }
    this.requestDeviceReport();
  }

  async handleSdpMessage(sdpMessage: SdpMessage) {
    if(!this._peerConnection) {
      this.logger.error(this.getLogState(), `${this.getLogPrefix()} handleSdpMessage() - peerConnection is undefined`);
      return;
    }

    const { sender, sdp } = sdpMessage;
    if(!sender?.id) {
      // We need to know the sender endpoint id to do anything useful with WebRTC.
      // This is really just catching malicious traffic trying to cause problems.
      this.logger.error(this.getLogState(), 'sender.id is undefined');
      return;
    }

    // Clear timeout if we received an answer. For "perfect negotiation" we will clear our timeout
    // if we receive either an answer or an offer from the other peer.
    window.clearTimeout(this._waitingForSdpAnswerTimeout);

    //
    // If we receive an Offer when we have already sent an offer, or have already set the local
    // description (pc.signalingState will be 'have-local-offer') then we are in the midst of an
    // offer collision.
    //
    const offerCollision =
      sdp.type === 'offer' &&
        (this._makingOffer || this._peerConnection.signalingState !== 'stable');

    this.logger.info(this.getLogState({
      sdp,
      offerCollision,
    }), `${this.getLogPrefix()} Received ${offerCollision ? 'Colliding' : ''} ${sdp.type === 'offer' ? 'Offer' : 'Answer'} SdpMessage\n${sdp.sdp}`);

    //
    // If we are in the midst of an offer collision, and we are the impolite peer, then we want to
    // ignore this offer. We are expecting the other peer to be polite an accept our offer, rolling
    // back their own.
    //
    this._ignoringOffers = !this._polite && offerCollision;
    if (this._ignoringOffers) {
      this.logger.warn(this.getLogState({
        sdp,
        offerCollision,
      }), `${this.getLogPrefix()} handleSdpMessage() - Ignoring offer... I'm an IMPOLITE peer!`);
      return;
    }

    //
    // If we are a polite peer receiving an coliding offer, we will yield, rollback our prior offer
    // and instead accept the incoming offer and provide an answer.
    //
    if(this._polite && offerCollision) {
      this.logger.warn(this.getLogState({
        sdp,
        offerCollision,
      }), `${this.getLogPrefix()} handleSdpMessage() - Yielding to new offer... I'm a POLITE peer!`);
    }

    //
    // What should we do when we receive an SDP offer or answer while in the stable signaling state?
    // Should we call restartIce()? Need to confirm.
    //
    // This appears to happen when you close the lid on my MacBook Pro using Chrome browser. The
    // Chrome browser will create some odd behavior where it immediately terminates the peer
    // connection but then begins re-establishing it with lid closed while simultaneously starving
    // the javascript context of CPU resources. Some websocket messages get buffered and delivered
    // late, though we never actually see the websocket disconnect - its just heavily throttled.
    //
    /*
    if(
      sdp.type === 'answer'
      && this._peerConnection.signalingState === 'stable'
    ) {
      this.logger.warn(this.getLogState({
        sdp,
        offerCollision,
      }), `${this.getLogPrefix()} handleSdpMessage() - received SdpMessage while RTCPeerConnection.signalingState === 'stable' (connectionState = ${this._peerConnection.connectionState}), iceConnectionState = ${this._peerConnection.iceConnectionState}`);

      this._peerConnection.restartIce();
      return;
    }
    */

    //
    // If we get this far there is either no offer collision, or we are the polite peer in the
    // offer collision.
    //
    // setRemoteDescription() has built-in auto-rollback. If an offer was already sent, and this
    // peer is polite, setRemoteDescription() will cause signalingState to return to 'stable'
    //
    // See: https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Perfect_negotiation#automatic_rollback_in_setremotedescription
    //
    await this._peerConnection.setRemoteDescription(sdp);
    if (sdp.type === 'offer') {

      //
      // As a polite peer, responding to an offer, we need to prepare our RTCPeerConnection tracks
      // now so they are reflected in our local description.
      //
      this.createRTCDataChannel();
      // await this.createRTCBackgroundMediaStream();

      //
      // We got an offer and either we are polite or there was no offer collision.
      // Send an answer!
      //
      await this._peerConnection.setLocalDescription();
      if(!this._peerConnection.localDescription) {
        // If this is null, something wrong happened.
        this.logger.error(this.getLogState(), `${this.getLogPrefix()} pc.localDescription is undefined`);
        return;
      }

      const thisEndpoint = this.deviceHost.appController.getLocalEndpointDescription();
      const targetEndpointId = sender?.id;
      const signallingMsg = new SdpMessage(thisEndpoint, targetEndpointId, this._peerConnection.localDescription);
      this.deviceHost.appController.dispatchMessage(signallingMsg, targetEndpointId);
      this.logger.info(this.getLogState({
        sdp: this._peerConnection.localDescription,
      }), `${this.getLogPrefix()} Sent Answer SdpMessage\n${this._peerConnection.localDescription.sdp}`);
    }
  }

  /**
   * Add an IceCandidate to our RTCPeerConnection. If we have not yet received our
   * RemoteDescription, then queue the IceCandidate until RemoteDescription is set.
   * @param iceCandidateMessage
   * @returns
   */
  async handleIceCandidateMessage(iceCandidateMessage: IceCandidateMessage) {
    if(iceCandidateMessage.sender?.id !== this.endpointId) {
      this.logger.info(`${this.getLogPrefix()} Received IceCandidateMessage not intended for this endpoint. Ignoring.`);
      return;
    }

    if(!this._peerConnection) {
      this.logger.error(this.getLogState(), `${this.getLogPrefix()} handleIceCandidateMessage() - peerConnection is undefined`);
      return;
    }

    let candidateType = iceCandidateMessage.candidate.type;
    if(!candidateType) {
      //
      // Firefox doesn't provide the candidate type outside of the string representation of the
      // candidate. We need to check the string ourself.
      //
      if(iceCandidateMessage.candidate.candidate.indexOf('host') > 0) {
        candidateType = 'host';
      } else if (iceCandidateMessage.candidate.candidate.indexOf('prflx') > 0) {
        candidateType = 'prflx';
      } else if (iceCandidateMessage.candidate.candidate.indexOf('srflx') > 0) {
        candidateType = 'srflx';
      } else if (iceCandidateMessage.candidate.candidate.indexOf('relay') > 0) {
        candidateType = 'relay';
      }
    }

    if(
      !this._peerConnection.remoteDescription
        || this._peerConnection.signalingState !== 'stable'
    ) {
      //
      // We do not yet have our remoteDescription.
      // Queue the IceCandidate to be applied later after we receive our RemoteDescription.
      //
      // See: https://groups.google.com/g/discuss-webrtc/c/0FjewQWbNYY
      //
      this.logger.debug(this.getLogState({
        iceCandidateMessage,
      }), `${this.getLogPrefix()} Received and Queued IceCandidate Message (${candidateType}), waiting for RemoteDescription`);

      this._queuedIceCandidates.push(iceCandidateMessage.candidate);
      return;
    }

    //
    // If we didn't queue the IceCandidate above, it means we are ready to add Ice Candidates to
    // the peer connection. Let's also drain any queued candidates now so that they are added to
    // the peer connection in the order that they were received (generally Host candidates first,
    // then reflexive, then relay candidates).
    //
    await this.applyQueuedIceCandidates();

    //
    // Add the IceCandidate to our peer connection.
    //
    await this._peerConnection.addIceCandidate(iceCandidateMessage.candidate)
      .then(() => {
        this.logger.debug(this.getLogState({
          iceCandidateMessage,
        }), `${this.getLogPrefix()} Received and added IceCandidate (${candidateType})`);
      })
      .catch((e) => {
        this.logger.error(this.getLogState({
          iceCandidateMessage,
        }), `${this.getLogPrefix()} Received and FAILED to add IceCandidate (${candidateType})`);
      });
  }

  private async applyQueuedIceCandidates() {
    //
    //
    // If there are any queued RTCIceCandidates that were received before we had our
    // RemoteDescription, then add those queued candidates now.
    //
    // See: https://groups.google.com/g/discuss-webrtc/c/0FjewQWbNYY
    //
    if(this._queuedIceCandidates.length) {

      const candidateStrings = this._queuedIceCandidates.map((iceCandidate) => iceCandidate.candidate);
      this.logger.debug(this.getLogState(), `${this.getLogPrefix()} Applying ${this._queuedIceCandidates.length} Queued IceCandidate Messages, RemoteDescription received\n${candidateStrings.join('\n')}`);

      if(!this._peerConnection) {
        return;
      }

      for (const iceCandidate of this._queuedIceCandidates) {
        let candidateType = iceCandidate.type;
        if(!candidateType) {
          //
          // Firefox doesn't provide the candidate type outside of the string representation of the
          // candidate. We need to check the string ourself.
          //
          if(iceCandidate.candidate.indexOf('host') > 0) {
            candidateType = 'host';
          } else if (iceCandidate.candidate.indexOf('prflx') > 0) {
            candidateType = 'prflx';
          } else if (iceCandidate.candidate.indexOf('srflx') > 0) {
            candidateType = 'srflx';
          } else if (iceCandidate.candidate.indexOf('relay') > 0) {
            candidateType = 'relay';
          }
        }

        await this._peerConnection.addIceCandidate(iceCandidate)
          .then(() => {
            this.logger.debug(this.getLogState({
              iceCandidate,
            }), `${this.getLogPrefix()} Added queued IceCandidate (${candidateType})`);
          })
          .catch((e) => {
            this.logger.error(this.getLogState({
              iceCandidate,
            }), `${this.getLogPrefix()} FAILED to add queued IceCandidate (${candidateType})`);
          });
      }

      this._queuedIceCandidates = [];
    }
  }
  private _queuedIceCandidates: RTCIceCandidate[] = [];

  /**
   * We have received a 'peer.pong' message via our WebSocket connection indicating the remote
   * peer represented by this PrompterPeerInstance has a WebSocket connection and is reachable by
   * this device (and therefor able to participate in WebRTC connection signaling).
   */
  async handlePeerPongMessage() {
    // TODO: resolve any outstanding promise to verify signaling connectivity with the remote peer.
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} Received PeerPongMessage Message`);
  }

  /**
   * We previously tried to send a 'peer.ping' message to the remote peer represented by this
   * PrompterPeerInstance; however, our server backend doesn't have a WebSocket connection for
   * that target peer right now indicating the remote peer has either lost connectivity or
   * navigated away from the app. We should remove this PrompterPeerInstance, if the remote peer
   * re-connects we will get a new 'connect' message later to recreate this peer.
   */
  async handlePeerMissingMessage() {
    this.logger.info(this.getLogState(), `${this.getLogPrefix()} Received PeerMissingMessage Message`);
    return this.remove();
  }

  handlePeerStateResponse(peerStateResponse: PeerStateResponse) {
    if(this._resolvePromisePeerState) {
      this._resolvePromisePeerState(peerStateResponse);
    }

    return this.deviceHost.scriptCollector.receivedPeerCandidateState(peerStateResponse);
  }

  async sendMessage(message: BaseControlMessage) {
    //
    // Some messages should never be sent via WebRTC DataChannel
    //
    switch(message.type) {
      case SdpMessage.MESSAGE_NAME:
      case IceCandidateMessage.MESSAGE_NAME: {
        return;
      }
    }

    //
    // Make sure we have sender information in the message we are sending to the connected peer.
    //
    if(!message.sender) {
      message.sender = await this.deviceHost.appController.getSenderInfo();
    }

    let serializedMessage: string | undefined;
    try {
      serializedMessage = JSON.stringify(message);
    } catch(err) {
      this.logger.error(err as Error, 'Error serializing app message in PrompterPeerInstance.sendMessage().');
    }
    if(!serializedMessage) {
      return;
    }

    if(!this._dataChannel) {
      this.logger.error(this.getLogState(), `${this.getLogPrefix()} ERROR: sendMessage('${message.type}') missing _dataChannel instance`);
      return;
    }
    if(this._dataChannel.readyState !== 'open') {
      // throw new Error(`DataChannel not open, cannot send message (${message.type}).`);
      this.logger.error(this.getLogState(), `${this.getLogPrefix()} ERROR: sendMessage('${message.type}') _dataChannel is not open`);
      return;
    }

    this._dataChannel.send(serializedMessage);
  }

  getDeviceState() {
    const baseDeviceState = super.getDeviceState();
    const deviceState: IPrompterState = {
      ...baseDeviceState,
      //
      // Device meta data that can change throughout a single session.
      endpointRole: this.endpointRole,
      representsLocal: this.representsLocal,
      iconOverlay: (this.peerNumber > 0) ? `${this.peerNumber}` : '≡=',
      isLeader: this.isLeader,
      appState: this.appLifecycleState,
      //
      // OS and Browser Info
      osName: this.osName,
      osVersion: this.osVersion,
      browserName: this.browserName,
      browserVersion: this.browserVersion,
      deviceType: this.deviceType,
      //
      // Show the cloud connection state for the device being represented by this
      // PrompterPeerInstance.
      cloudConnectionState: this.cloudConnectionState,
      cloudLatency: this.cloudLatency,
      //
      // Show the peer connection state for the device being represented by this
      // PrompterPeerInstance.
      connectionState: this.getPeerConnectionState(),
      peerLatency: (!this.representsLocal) ? this.peerLatency : undefined,
      //
      // Estimated distance to peer in km using IP-geolocation.
      peerDistance: this._peerDistance,
      peerIsLocal: this.peerIsLocal,
      peerIsRelayed: this.peerIsRelayed,
    };
    return deviceState;
  }

  getDeviceUIComponent(): DeviceComponent {
    return PrompterPeerUI;
  }
}

export default PrompterPeerInstance;