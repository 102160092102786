import * as React from 'react';

import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

const elapsedTimeStringFromSeconds = function(totalSeconds?: number): string {

  const totalElapsedMs = totalSeconds || 0;

  const elapsedHours = Math.floor((totalElapsedMs / (60 * 60)) % 24);
  const elapsedMinutes = Math.floor((totalElapsedMs / 60) % 60);
  const elapsedSeconds = Math.floor((totalElapsedMs) % 60);

  const elapsedHoursString = ('0' + elapsedHours).slice(-2);
  const elapsedMinutesString = ('0' + elapsedMinutes).slice(-2);
  const elapsedSecondsString = ('0' + elapsedSeconds).slice(-2);

  return (elapsedHours > 0) ? `${elapsedHoursString}:${elapsedMinutesString}:${elapsedSecondsString}` : `${elapsedMinutesString}:${elapsedSecondsString}`;
};

const PrompterHudStats = () => {
  const { t } = useTranslation(['prompter', 'common']);

  const prompterState = usePrompterSession(state => ({
    isLeader: state.isLeader,
    textMetrics: state.textMetrics,
  }), shallow);

  //
  // Subscribe to the PrompterSession store on component mount, 
  // disconnect on component unmount, and catch state-changes in a callback.
  //
  // Avoid React re-rendering the component when FPS can change frequently.
  // We will subscribe to state changes with a callback and update the FPS 
  // indicator only when the FPS changes via a ref to the DOM element.
  //
  const refFramesPerSecond = React.createRef<HTMLSpanElement>();
  React.useEffect(() => {
    const unsubscribe = usePrompterSession.subscribe(
      (config, previousConfig) => {
        if(config.framesPerSecond !== previousConfig.framesPerSecond) {
          const fpsEl = refFramesPerSecond.current;
          if(fpsEl) {
            fpsEl.innerText = `FPS: ${config.framesPerSecond}`;
          }
        }
      }
    );

    return unsubscribe;
  }, [refFramesPerSecond]);

  return (<>
    <Typography>{prompterState.isLeader ? 'LEADER' : 'FOLLOWER'}</Typography>
    {/* <Typography>Lines: {prompterState.textMetrics.lines}</Typography> */}
    <Typography>{t('words', { ns: 'common' })}: {prompterState.textMetrics.words}</Typography>
    <Typography>{t('characters', { ns: 'common' })}: {prompterState.textMetrics.characters}</Typography>
    <Typography>NRT: ~{elapsedTimeStringFromSeconds(prompterState.textMetrics.estimatedLength)}</Typography>
    <Typography ref={refFramesPerSecond}>FPS: 0</Typography>
  </>);
};

export default PrompterHudStats;