import React, { useCallback, useState } from 'react';
import { zaraz } from 'zaraz-ts';

import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// import { literal, object, string, TypeOf } from 'zod';
import * as z from 'zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import useApplicationContext from '../../hooks/useApplicationContext';
import { IUserProfile } from '@fluidprompter/core';

import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledFormLabel = styled(FormLabel)({
  color: '#000',
  fontSize: '1.25em',
});

const GatherUserPrompterUseCase = () => {

  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up('md'));

  const {
    appContext,
    patchUserProfile,
  } = useApplicationContext();

  const registerSchema = z.object({
    fluidprompter_aloneorteam: z.string({
      invalid_type_error: 'Please choose an option.',
    }).refine((val) => ['alone', 'team'].includes(val)),
    fluidprompter_usecase: z.string()
      .min(1, 'Please choose an option.'),
    fluidprompter_usecasecustom: z.string(),
    formstatus: z.string().default(''),
  }).refine((val) => (val.fluidprompter_usecase !== 'other') || (val.fluidprompter_usecasecustom.length > 3), {
    path: ['fluidprompter_usecase'],
    message: 'Please describe your other use case.',
  });

  type RegisterInput = z.TypeOf<typeof registerSchema>;

  const {
    register,
    watch,
    setValue,
    setError,
    formState: { errors },
    setFocus,
    handleSubmit
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });

  const aloneOrTeam = watch('fluidprompter_aloneorteam') || null;
  const handleChangeAloneOrTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('fluidprompter_aloneorteam', (event.target as HTMLInputElement).value);
  };

  const useCase = watch('fluidprompter_usecase') || null;
  const handleChangeUseCase = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('fluidprompter_usecase', (event.target as HTMLInputElement).value);
  };

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const onSubmitValidHandler: SubmitHandler<RegisterInput> = useCallback(async (values) => {
    if(!appContext.userProfile) {
      return;
    }

    try {
      setIsSaving(true);
      const userProfile: IUserProfile = { ...appContext.userProfile };

      userProfile.fluidprompter_aloneorteam = values.fluidprompter_aloneorteam;
      userProfile.fluidprompter_usecase = values.fluidprompter_usecase;
      userProfile.fluidprompter_usecasecustom = values.fluidprompter_usecasecustom;

      // Rewardful affiliate
      // {
      //   id: "b533bfca-7c70-4dec-9691-e136a8d9a26c",
      //   name: "James Bond",
      //   first_name: "James",
      //   last_name: "Bond",
      //   token: "james007"
      // }
      // if(!userProfile.via) {  // Only set the affiliate token if not already set. No stealing customer attribution.
      //   userProfile.via = (window.Rewardful && window.Rewardful.referral) || ('checkout_'+(new Date()).getTime());
      // }
      // const tapVid = window.__tap.vid;
      // if(!userProfile.via && tapVid) {  // Only set the affiliate token if not already set. No stealing customer attribution.
      //   userProfile.via = tapVid;
      // }

      await patchUserProfile(userProfile);

      await zaraz.track('onboarding_usecase', {
        fluidprompter_aloneorteam: values.fluidprompter_aloneorteam,
        fluidprompter_usecase: values.fluidprompter_usecase,
      });
    } catch (err) {
      setIsSaving(false);
      setError('formstatus', { type: 'custom', message: 'Error: could not save changes. Try again or contact support.' });
    }
  }, [appContext.userProfile, patchUserProfile, setError]);

  //
  // Set focus to the first field with an error.
  //
  React.useEffect(() => {
    // Find the first field with an error.
    const firstError = (
      Object.keys(errors) as Array<keyof typeof errors>
    ).reduce<keyof typeof errors | null>((field, a) => {
      const fieldKey = field as keyof typeof errors;
      return errors[fieldKey] ? fieldKey : a;
    }, null);

    // Handle special case when usecase==='other'
    if(firstError === 'fluidprompter_usecase' && useCase === 'other') {
      setFocus('fluidprompter_usecasecustom');
      return;
    }

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, useCase, setFocus]);

  return (
    <>
      <DialogContent dividers={true}>
        <Box
          maxWidth={'md'}
          sx={{
            m: '0 auto',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          <h3 style={{
            textAlign: 'center',
            fontSize: viewportIsLarge ? '1.2rem' : '1rem',
            fontWeight: viewportIsLarge ? 'bold' : '400'
          }}>Let&apos;s finish your FluidPrompter account setup.</h3>
          <Stepper activeStep={0} alternativeLabel>
            <Step>
              <StepLabel>Use Case</StepLabel>
            </Step>
            <Step>
              <StepLabel>Choose Plan</StepLabel>
            </Step>
            <Step>
              <StepLabel>Finish</StepLabel>
            </Step>
          </Stepper>
        </Box>
        {/* <Divider orientation="horizontal" sx={{
          marginTop: 3,
          marginBottom: 3,
        }} /> */}

        <Container
          component={'form'}
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit(onSubmitValidHandler)}
          maxWidth="sm"
          sx={{
            m: '0 auto'
          }}
        >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isSaving}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Stack spacing={3} alignItems={'center'}>

            <FormControl fullWidth error={!!errors['fluidprompter_aloneorteam']}>
              <StyledFormLabel id="soloOrTeam-group">Do you mostly work alone or with a production team?</StyledFormLabel>
              <RadioGroup
                aria-labelledby="soloOrTeam-group"
                value={aloneOrTeam}
                onChange={handleChangeAloneOrTeam}
              >
                <FormControlLabel value="alone" {...register('fluidprompter_aloneorteam')} control={<Radio />} label="I mostly work alone" />
                <FormControlLabel value="team" {...register('fluidprompter_aloneorteam')} control={<Radio />} label="I mostly work with a production team" />
              </RadioGroup>
              <FormHelperText error={!!errors['fluidprompter_aloneorteam']}>
                {errors['fluidprompter_aloneorteam'] ? errors['fluidprompter_aloneorteam'].message : ''}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth error={!!errors['fluidprompter_usecase']}>
              <StyledFormLabel id="usecase-group">What best describes you?</StyledFormLabel>
              <RadioGroup
                aria-labelledby="usecase-group"
                value={useCase}
                onChange={handleChangeUseCase}
              >
                <FormControlLabel value="content-creator" {...register('fluidprompter_usecase')} control={<Radio />} label="Content Creator/Influencer (ie: YouTube)" />
                <FormControlLabel value="business-presenter" {...register('fluidprompter_usecase')} control={<Radio />} label="Business Presenter (ie: Webinars/Pitches)" />
                <FormControlLabel value="instructor" {...register('fluidprompter_usecase')} control={<Radio />} label="Instructor (ie: Courses/Compliance/Training)" />
                <FormControlLabel value="corporate-videographer" {...register('fluidprompter_usecase')} control={<Radio />} label="Corporate Videographer (ie: Business Owner or Executive Interviews, Commercials)" />
                <FormControlLabel value="politician" {...register('fluidprompter_usecase')} control={<Radio />} label="Politician or Government Employee" />
                <FormControlLabel
                  value="other"
                  {...register('fluidprompter_usecase')}
                  disableTypography
                  control={<Radio />}
                  label={(<FormControlLabel
                    labelPlacement="start"
                    control={
                      <TextField
                        fullWidth
                        {...register('fluidprompter_usecasecustom')}
                        error={(useCase === 'other') && !!errors['fluidprompter_usecase']}
                        onFocus={() => { setValue('fluidprompter_usecase', 'other'); }}
                        size="small"
                        sx={{ ml: 2 }}
                      />
                    }
                    label={'Other: '}
                    sx={{ ml: 0, width: '100%' }} />)
                  }
                  sx={{
                    width: '100%',
                  }}
                />
              </RadioGroup>
              <FormHelperText error={!!errors['fluidprompter_usecase']}>
                {errors['fluidprompter_usecase'] ? errors['fluidprompter_usecase'].message : ''}
              </FormHelperText>
            </FormControl>
            {!!errors['formstatus'] &&
            <Alert severity="error">{errors['formstatus'] ? errors['formstatus'].message : ''}</Alert>
            }
            <Container
              maxWidth="xs"
            >
              <Button
                fullWidth
                type='submit'
                variant='contained'
              >Save</Button>
            </Container>
          </Stack>
        </Container>
      </DialogContent>
      {/* <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            maxWidth: 'xs',
          }}
          onClick={handleSave}
        >Save</Button>
      </DialogActions> */}
    </>
  );
};

export default GatherUserPrompterUseCase;