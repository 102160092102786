import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import lightTheme from '../../../themes/LightTheme';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import TheatersIcon from '@mui/icons-material/Theaters';

import useInputMiddleware, { InputMiddlewareProps } from '../../../hooks/useInputMiddleware';
import { BRoll } from '../../../models/EditorTypes';

interface BRollEditorProps {
  editorNode: BRoll;
  onCancel: () => void;
  onSave: (nodeData: BRoll) => void;
}

const BRollEditor = (props: BRollEditorProps) => {
  const { onSave } = props;

  const [editorNode, setEditorNode] = useState<BRoll>(props.editorNode);

  //
  // Take over processing of human inputs while this modal dialog is open.
  //
  const inputMiddlewareProps = React.useMemo<InputMiddlewareProps>(() => {
    const results: InputMiddlewareProps = {
      exclusive: true,  // Prevent all user input hooks above this one from receiving input while this model is open.
      keyboard: [{
        hotkeys: 'enter',
        keydown: (e) => {
          e.preventDefault();
          onSave(editorNode);
        }
      }]
    };

    return results;
  }, [onSave, editorNode]);  
  useInputMiddleware(inputMiddlewareProps);

  const saveAndReturn = () => {
    //
    // Create a new BRoll node record updating our previous instance with any changed data.
    //
    onSave(editorNode);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog onClose={() => { props.onCancel(); }} open={true}>
        <DialogTitle><TheatersIcon sx={{
          verticalAlign: 'text-bottom'
        }} /> BRoll Shot Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            These details can be output as a b-roll shotlist later.
          </DialogContentText>
          <TextField
            autoFocus={true}
            margin="dense"
            id="name"
            label="Shot Description"
            type="text"
            fullWidth
            variant="standard"
            value={editorNode.description}
            onChange={(e) => {
              setEditorNode({ ...editorNode, description: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { props.onCancel(); }}>Cancel</Button>
          <Button variant="contained" color="info" onClick={saveAndReturn}>Save</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default BRollEditor;