import BaseDevice, { DeviceAxisConfig } from '../BaseDevice';
import { BaseDeviceEvent } from './BaseDeviceEvent';
import AnalogPosition from '../../models/AnalogPosition';

/**
 * AxisEvent -> like a gamepad axis, but could be an analog foot pedal or jog wheel
 */
export class DeviceAxisEvent extends BaseDeviceEvent<Event> {
  readonly type = 'DeviceAxisEvent';
  private _config: DeviceAxisConfig;

  /**
   * Constructor for DeviceAxisEvent
   * @param device Device generating this event.
   * @param value The axis position on a scale between -1.0 – 1.0
   */
  constructor(device: BaseDevice, axisNumber: number, config: DeviceAxisConfig, e: Event, rawValue: number, previousEvent?: DeviceAxisEvent) {
    super(device, e);

    if(previousEvent) {
      // Avoid having a huge reference chain to all previous events causing memory problems.
      previousEvent.clearPreviousEvent();
    }
    this._previousEvent = previousEvent;

    this._axisNumber = axisNumber;
    this._config = config;
    this.rawValue = rawValue;
  }

  /**
   * Axis number is a zero based index of which axis changed.
   */
  get axisNumber(): number {
    return this._axisNumber;
  }
  private _axisNumber: number;

  /**
   * previousEvent is used to calculate the delta or change in state.
   */
  get previousEvent(): DeviceAxisEvent | undefined {
    return this._previousEvent;
  }
  protected clearPreviousEvent() {
    this._previousEvent = undefined;
  }
  private _previousEvent: DeviceAxisEvent | undefined;

  /**
   * Axis position on a scale between -1.0 – 1.0
   */
  get rawValue(): number {
    return this._rawValue;
  }
  set rawValue(value: number) {
    this._rawValue = value;

    let proposedValue = this._rawValue;

    // Are we at or below minimum range for this axis?
    if(proposedValue <= this._config.valueMinimum + this._config.valueMinimumTolerance) {
      proposedValue = this._config.valueMinimum;
      this.analogPosition = AnalogPosition.Minimum; // 1
    }

    // Are we at or over maximum range for this axis?
    if(proposedValue >= (this._config.valueMaximum - this._config.valueMaximumTolerance)) {
      proposedValue = this._config.valueMaximum;
      this.analogPosition = AnalogPosition.Maximum; // 5
    }

    // Are we within the tolerance range of neutral on this axis?
    const minNeutralValue = (this._config.valueNeutral - this._config.valueNeutralTolerance);
    const maxNeutralValue = (this._config.valueNeutral + this._config.valueNeutralTolerance);
    if((proposedValue > minNeutralValue)
      && (proposedValue < maxNeutralValue)
    ) {
      proposedValue = this._config.valueNeutral;
      this.analogPosition = AnalogPosition.Neutral; // 3
    }

    // Are we within the negative range of this axis? (below neutral)
    if(proposedValue > this._config.valueMinimum
      && proposedValue < (this._config.valueNeutral - this._config.valueNeutralTolerance)
    ) {
      this.analogPosition = AnalogPosition.Negative;  // 2
    }

    // Are we within the positive range of this axis? (above neutral)
    if(proposedValue > (this._config.valueNeutral + this._config.valueNeutralTolerance)
      && proposedValue < this._config.valueMaximum
    ) {
      this.analogPosition = AnalogPosition.Positive;  // 4
    }

    //
    // TODO: Do we need to scale this value?
    //

    this._value = proposedValue;
  }
  private _rawValue = 0;

  /**
   * This method will process the rawValue enforcing any minimum, maximum or value scaling options.
   */
  get value(): number {
    return this._value;
  }
  private _value = 0;

  get analogPosition(): AnalogPosition {
    return this._analogPosition;
  }
  private set analogPosition(analogPosition: AnalogPosition) {
    this._analogPosition = analogPosition;
  }
  private _analogPosition: AnalogPosition = AnalogPosition.Unknown;

  get analogPositionChanged(): boolean {
    return this._analogPosition !== this._previousEvent?.analogPosition;
  }

  get analogPositionPrevious(): AnalogPosition {
    return this._previousEvent?.analogPosition || AnalogPosition.Unknown;
  }
}