import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function NoSleepIcon({ viewBox, ...rest }: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 512 512' {...rest}>
      <path d="M138.019,490.425H50.791l93.234-75.788c3.101-2.537,4.271-6.671,2.924-10.447c-1.349-3.773-4.923-6.211-8.93-6.211H24.239
			c-5.236,0-9.482,4.245-9.482,9.482c0,5.236,4.245,9.482,9.482,9.482h87.228l-93.234,76.51c-3.101,2.537-4.271,6.308-2.924,10.084
			c1.348,3.773,4.923,5.852,8.93,5.852h113.78c5.236,0,9.482-4.245,9.482-9.482C147.501,494.671,143.255,490.425,138.019,490.425z"
      />
      <path d="M487.761,158.568H354.203L493.767,44.853c3.101-2.537,4.271-6.671,2.924-10.447c-1.349-3.773-4.923-6.211-8.93-6.211
			H327.652c-5.236,0-9.482,4.245-9.482,9.482c0,5.236,4.245,9.482,9.482,9.482h133.558L321.646,161.596
			c-3.101,2.537-4.271,6.308-2.924,10.084c1.348,3.773,4.923,5.852,8.93,5.852h160.109c5.236,0,9.482-4.245,9.482-9.482
			C497.242,162.814,492.997,158.568,487.761,158.568z"/>
      <path d="M411.3,497.22L235.712,243.623l22.093-18.086c3.101-2.537,4.271-6.935,2.924-10.713c-1.349-3.773-4.923-6.477-8.93-6.477
			h-40.252L69.961,4.019c-2.981-4.303-8.89-5.28-13.193-2.303c-4.306,2.982-5.38,8.748-2.398,13.053l134.111,193.577h-50.463
			c-5.236,0-9.482,4.245-9.482,9.482c0,5.236,4.245,9.482,9.482,9.482h63.591l8.55,12.537l-78.147,64.066
			c-3.101,2.537-4.271,6.262-2.924,10.037c1.348,3.773,4.923,5.806,8.93,5.806h113.78c4.494,0,8.24-2.602,9.215-6.801
			l134.695,194.827c1.84,2.657,4.797,4.218,7.803,4.218c1.862,0,3.743-0.482,5.389-1.62
			C413.207,507.398,414.282,501.525,411.3,497.22z M225.247,227.683l-0.365,0.299l-0.207-0.299H225.247z M251.799,300.793H164.57
			l56.419-45.651l32.062,46.045C252.638,301.131,252.228,300.793,251.799,300.793z"/>
    </SvgIcon>
  );
}

export default NoSleepIcon;