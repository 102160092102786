import { StateCreator } from 'zustand';
import { PrompterMode, ConnectionState } from '@fluidprompter/core';
import { IPrompterAttributesSlice } from './PrompterAttributesSlice';

export interface IPrompterModeSlice {
  sessionStartTimestamp: number;
  setSessionStartTimestamp: (sessionStartTimestamp: number) => void;
  prompterMode: PrompterMode;
  setPrompterMode: (prompterMode: PrompterMode, withBlanking?: boolean) => void;
  isPlaying: boolean;
  isPaused: boolean;
  isEditing: boolean;
  play: () => void;
  pause: (withBlanking?: boolean) => void;
  edit: () => void;
  isBlanking: boolean;
  setBlanking: (blanking?: boolean) => void;
  unsetBlanking: () => void;

  reset: () => void;

  websocketState: ConnectionState;
  setWebsocketState: (connectionState: ConnectionState) => void;
}

const createPrompterModeSlice: StateCreator<
  IPrompterAttributesSlice & IPrompterModeSlice,
  [],
  [],
  IPrompterModeSlice
> = (set, get) => ({
  sessionStartTimestamp: 0,
  setSessionStartTimestamp: (sessionStartTimestamp: number) => set(() => ({ sessionStartTimestamp })),
  prompterMode: PrompterMode.Editing,
  setPrompterMode: (prompterMode: PrompterMode, withBlanking?: boolean) => {
    set((state) => {
      const isBlanking = (prompterMode !== PrompterMode.Paused) ? false : ((withBlanking === true) || state.isBlanking);
      const stateChanges: Partial<IPrompterModeSlice> = {
        // isBlanking: (prompterMode !== PrompterMode.Paused) ? false : state.isBlanking,
        isBlanking,
        prompterMode: prompterMode,
        isPlaying: prompterMode === PrompterMode.Playing,
        isPaused: prompterMode === PrompterMode.Paused,
        isEditing: prompterMode === PrompterMode.Editing,
        // cursorHidden: (prompterMode === PrompterMode.Editing) ? false : state.cursorHidden,
      };
      if(!state.sessionStartTimestamp && prompterMode === PrompterMode.Playing) {
        // The first time we go into play mode, record our session start timestamp.
        stateChanges.sessionStartTimestamp = new Date().getTime();
      }
      return stateChanges;
    });
  },
  isPlaying: false,
  isPaused: false,
  isEditing: true,
  play: () => { get().setPrompterMode(PrompterMode.Playing); },
  pause: (withBlanking?: boolean) => { get().setPrompterMode(PrompterMode.Paused, withBlanking); },
  edit: () => { get().setPrompterMode(PrompterMode.Editing); },
  isBlanking: false,
  setBlanking: (blanking = true) => {
    if(blanking) {
      get().setPrompterMode(PrompterMode.Paused, true);
    } else {
      set({ isBlanking: blanking });
    }
  },
  unsetBlanking: () => { get().setBlanking(false); },

  reset: () => {
    set((state) => {
      state.setPrompterMode(PrompterMode.Editing);
      state.setElapsedPlayTime(0);

      // sessionStartTimestamp: undefined
      // shotlogEntries: [],
      // nextShotlogEntry: undefined,
      return {
        framesPerSecond: 0,
        //
        sessionStartTimestamp: undefined,
        shotlogEntries: [],
        nextShotlogEntry: undefined,
      };
    });
  },

  websocketState: ConnectionState.Disconnected,
  setWebsocketState: (connectionState: ConnectionState) => set(() => ({ websocketState: connectionState })),
});

export default createPrompterModeSlice;