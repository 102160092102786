import { useCallback } from 'react';
import { useMessageHandler } from '../../controllers/AppController';

import DeviceHost from '../DeviceHost';

import useBackgroundState, { BackgroundTypes } from '../../state/BackgroundState';
import { BackgroundMessage } from '@fluidprompter/core';

function useRTCBackgroundLocalSourceHandler(deviceHost: DeviceHost) {
  const connectUserMedia = useCallback(async () => {
    const mediaDevices = navigator.mediaDevices;

    const videoPromise = await mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });

    const localBackgroundState = useBackgroundState.getState();
    if(localBackgroundState.backgroundType !== BackgroundTypes.Webcam) {
      localBackgroundState.setBackgroundType(BackgroundTypes.Webcam);
    }

    //
    // Let all our peer connections know that we have a video background to share.
    // PrompterPeer instances will begin sharing this media track via WebRTC.
    //
    deviceHost.appController.dispatchMessage(new BackgroundMessage(videoPromise, true));
  }, []);
  useMessageHandler('prompter.background.webcam', connectUserMedia);

  const connectDesktop = useCallback(async () => {
    const mediaDevices = navigator.mediaDevices;

    const videoPromise = await mediaDevices.getDisplayMedia({
      video: true,
      audio: false,
    });

    const localBackgroundState = useBackgroundState.getState();
    if(localBackgroundState.backgroundType !== BackgroundTypes.Desktop) {
      localBackgroundState.setBackgroundType(BackgroundTypes.Desktop);
    }

    //
    // Let all our peer connections know that we have a video background to share.
    // PrompterPeer instances will begin sharing this media track via WebRTC.
    //
    deviceHost.appController.dispatchMessage(new BackgroundMessage(videoPromise, true));
  }, []);
  useMessageHandler('prompter.background.desktop', connectDesktop);
}

export default useRTCBackgroundLocalSourceHandler;