import { useCallback } from 'react';
import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { useMessageHandler, MessageHandlerEvent } from '../../controllers/AppController';
import { IViewportFuncs } from './usePrompterViewportFuncs';
import { EndpointRole, PlayMessage } from '@fluidprompter/core';

function usePrompterPlayHandler(viewportFuncs: IViewportFuncs) {

  const doPlay = useCallback(async function (e: MessageHandlerEvent<PlayMessage>) {
    const { message, originatedRemotely } = e;
    const { sender } = message;
    const senderIsRemote = sender?.role === EndpointRole.Remote;

    // console.log(`Received prompter.state.play message - senderIsRemote=${senderIsRemote}`, e);

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);

    e.sendToPeers = !originatedRemotely || (senderIsRemote && isLeader);

    viewportFuncs.queueSequentialTask(async () => {

      if(
        originatedRemotely
        && !isLeader
        && !senderIsRemote
      ) {
        //
        // Apply the remote prompter script position to the local prompter.
        //
        e.syncScrollPosition();

        //
        // Apply the remote prompter scroll speed to the local prompter.
        //
        e.syncScrollSpeed();
      }

      const requestReversed = (sender?.scrollReversed === true);

      const configState = useConfigurationStore.getState();
      if(configState.scrollReversed !== requestReversed) {
        configState.setScrollReversed(requestReversed);
      }

      const localPrompterSession = usePrompterSession.getState();
      if(localPrompterSession.isBlanking) {
        e.dispatchMessage('prompter.content.show');
      }
      if(!localPrompterSession.isPlaying) {
        localPrompterSession.play();
      }
    });

  }, []);
  useMessageHandler('prompter.state.play', doPlay);
}

export default usePrompterPlayHandler;