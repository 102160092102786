import React, { useLayoutEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ITrayItem } from './TrayContainer';
import TrayItemIconMenuItem from './TrayItemIconMenuItem';
import TrayItemIconButton from './TrayItemIconButton';

import TranslateIcon from '@mui/icons-material/Translate';

import { useTranslation } from 'react-i18next';

interface ILanguageTrayItemProps {
  renderAsMenuItem?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  requestCloseTray: () => void;
}

const LanguageTrayItem = (props: ILanguageTrayItemProps) => {

  const { renderAsMenuItem } = props;

  const { t, i18n } = useTranslation('prompter');

  // useState will hold reference to Websocket Menu anchor element.
  const [trayMenuAnchorEl, setTrayMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const onTrayMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    if(props.onClick) { props.onClick(e); }
    setTrayMenuAnchorEl(e.currentTarget);
  };
  const onTrayMenuClose = () => {
    setTrayMenuAnchorEl(null);
  };

  // useState will hold reference to Websocket Menu anchor element.
  const [languageCode, setLanguageCode] = React.useState<string>(i18n.language);
  const doChangeLanguage = (languageCode: string) => {
    setLanguageCode(languageCode);
    i18n.changeLanguage(languageCode);
    onTrayMenuClose();
    props.requestCloseTray();
  };

  useLayoutEffect(() => {
    // When renderAsMenuItem changes, we need to close any open menus.
    onTrayMenuClose();
  }, [renderAsMenuItem]);

  const trayItemProps: ITrayItem = {
    title: t('trayicons.language'),
    icon: (<TranslateIcon />),
    onClick: onTrayMenuClick,
  };

  return (<>
    {renderAsMenuItem ? (<TrayItemIconMenuItem {...trayItemProps} />) : (<TrayItemIconButton {...trayItemProps} />)}
    <Menu
      id="language-menu"
      anchorEl={trayMenuAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(trayMenuAnchorEl)}
      onClose={onTrayMenuClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem
        selected={languageCode === 'en'}
        onClick={() => { doChangeLanguage('en'); }}
      >English</MenuItem>
      <MenuItem
        selected={languageCode === 'fr'}
        onClick={() => { doChangeLanguage('fr'); }}
      >Français (French)</MenuItem>
      <MenuItem
        selected={languageCode === 'es'}
        onClick={() => { doChangeLanguage('es'); }}
      >Español (Spanish)</MenuItem>
      <MenuItem
        selected={languageCode === 'it'}
        onClick={() => { doChangeLanguage('it'); }}
      >Italiano (Italian)</MenuItem>
      <MenuItem
        selected={languageCode === 'fi'}
        onClick={() => { doChangeLanguage('fi'); }}
      >Suomi (Finnish)</MenuItem>
      {/* <MenuItem
        selected={languageCode === 'de'}
        onClick={() => { doChangeLanguage('de'); }}
      >Deutsch (German)</MenuItem> */}
      {/* <MenuItem
        selected={languageCode === 'pt'}
        onClick={() => { doChangeLanguage('pt'); }}
      >Português (Portuguese)</MenuItem> */}
      <MenuItem
        selected={languageCode === 'ko'}
        onClick={() => { doChangeLanguage('ko'); }}
      >한국어 (Korean)</MenuItem>
      <MenuItem
        selected={languageCode === 'ru'}
        onClick={() => { doChangeLanguage('ru'); }}
      >Русский (Russian)</MenuItem>
      <MenuItem
        selected={languageCode === 'ua'}
        onClick={() => { doChangeLanguage('ua'); }}
      >Українська (Ukrainian)</MenuItem>
    </Menu>
  </>);
};

export default LanguageTrayItem;