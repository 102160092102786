import { useEffect, useState, useCallback, useRef } from 'react';
// import useConfigurationStore from '../state/ConfigurationStore';
// import usePrompterSession from '../state/PrompterSessionState';

import DeviceHost from './DeviceHost';

// import { useAppController } from '../controllers/AppController';
// import { PlayMessage, PauseMessage, SetScrollSpeedMessage, ResetMessage, NavigateMessage } from '@fluidprompter/core';

import GamepadRemote from './gamepads/generic';

const useGamepadDevices = (deviceHost: DeviceHost) => {
  // const appController = useAppController();
  const [gamepad0, setGamepad0] = useState<GamepadRemote | null>(null);
  const [gamepad1, setGamepad1] = useState<GamepadRemote | null>(null);
  const [gamepad2, setGamepad2] = useState<GamepadRemote | null>(null);
  const [gamepad3, setGamepad3] = useState<GamepadRemote | null>(null);

  /*
  const onInputEvent = useCallback(async (event: string) => {
    switch(event) {
      case 'ok.down': {
        const isPlaying = usePrompterSession.getState().isPlaying;
        appController.dispatchMessage(isPlaying ? new PauseMessage() : new PlayMessage());
        break;
      }
      case 'ok.longPress': {
        appController.dispatchMessage(new ResetMessage());
        break;
      }
      case 'left.down': {
        let newSpeed = useConfigurationStore.getState().scrollSpeed;
        newSpeed -= 10;
        if(newSpeed < 0) { newSpeed = 0; }
        appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
        break;
      }
      case 'left.longPress': {
        let newSpeed = useConfigurationStore.getState().scrollSpeed;
        newSpeed -= 12;
        if(newSpeed < 0) { newSpeed = 0; }
        appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
        break;
      }
      case 'left.longPressRepeat': {
        let newSpeed = useConfigurationStore.getState().scrollSpeed;
        newSpeed -= 15;
        if(newSpeed < 0) { newSpeed = 0; }
        appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
        break;
      }
      case 'right.down': {
        let newSpeed = useConfigurationStore.getState().scrollSpeed;
        newSpeed += 10;
        if(newSpeed > 500) { newSpeed = 500; }
        appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
        break;
      }
      case 'right.longPress': {
        let newSpeed = useConfigurationStore.getState().scrollSpeed;
        newSpeed += 12;
        if(newSpeed > 500) { newSpeed = 500; }
        appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
        break;
      }
      case 'right.longPressRepeat': {
        let newSpeed = useConfigurationStore.getState().scrollSpeed;
        newSpeed += 15;
        if(newSpeed > 500) { newSpeed = 500; }
        appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
        break;
      }
      case 'up.down': {
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageUp));
        break;
      }
      case 'down.down': {
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageDown));
        break;
      }

      default:
        break;
    }
  }, [appController]);

  const onAnalogValue = useCallback(async (value: number, previousValue: number) => {
    const currentScrollSpeed = useConfigurationStore.getState().scrollSpeed;
    const isPlaying = usePrompterSession.getState().isPlaying;

    if(value === 0 && previousValue > 0 && isPlaying) {
      // PAUSE!
      appController.dispatchMessage(new PauseMessage());
      return;
    }

    if(value > 0 && previousValue === 0 && !isPlaying) {
      // PLAY!
      appController.dispatchMessage(new PlayMessage());
      return;
    }

    // Scale our analogue value range of 0-32767 to be 50-350 as a reasonable range for 
    // teleprompter scrolling speed. In the future this minimum and maximum could be 
    // user preferences.
    const newSpeed = Math.round((value * 300) / 32767) + 50;

    if(newSpeed !== currentScrollSpeed) {
      // console.log(`analog value ${value} scaled to ${newSpeed}`);
      appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
    }
  }, [appController]);
  */

  const onGamepadConnected = useCallback(async (e: GamepadEvent) => {
    const gp = navigator.getGamepads()[e.gamepad.index];
    if(!gp) {
      return;
    }
  
    const gamepadRemote = new GamepadRemote(deviceHost, gp);
    switch(gp.index) {
      default:
      case 0:
        setGamepad0(gamepadRemote);
        break;
      case 1:
        setGamepad1(gamepadRemote);
        break;
      case 2:
        setGamepad2(gamepadRemote);
        break;
      case 3:
        setGamepad3(gamepadRemote);
        break;
    }
    await gamepadRemote.connect();

    console.log('Gamepad connected!', gp);

    console.log('Gamepad connected at index %d: %s. %d buttons, %d axes.',
      e.gamepad.index, e.gamepad.id,
      e.gamepad.buttons.length, e.gamepad.axes.length);
  }, [deviceHost]);

  const onGamepadDisconnected = useCallback(async (e: GamepadEvent) => {
    console.log('Gamepad disconnected from index %d: %s', e.gamepad.index, e.gamepad.id);

    let targetGamepad: GamepadRemote | null;
    switch(e.gamepad.index) {
      default:
      case 0:
        targetGamepad = gamepad0;
        setGamepad0(null);
        break;
      case 1:
        targetGamepad = gamepad1;
        setGamepad1(null);
        break;
      case 2:
        targetGamepad = gamepad2;
        setGamepad2(null);
        break;
      case 3:
        targetGamepad = gamepad3;
        setGamepad3(null);
        break;
    }

    if(targetGamepad) {
      await targetGamepad.disconnect();
    }
  }, [gamepad0, gamepad1, gamepad2, gamepad3]);

  useEffect(() => {
    window.addEventListener('gamepadconnected', onGamepadConnected);
    window.addEventListener('gamepaddisconnected', onGamepadDisconnected);

    return () => {
      window.removeEventListener('gamepadconnected', onGamepadConnected);
      window.removeEventListener('gamepaddisconnected', onGamepadDisconnected);
    };
  }, [onGamepadConnected, onGamepadDisconnected]);

  const processGamepadState = useCallback(() => {
    if(gamepad0) {
      gamepad0.processGamepadState();
    }

    if(gamepad1) {
      gamepad1.processGamepadState();
    }

    if(gamepad2) {
      gamepad2.processGamepadState();
    }

    if(gamepad3) {
      gamepad3.processGamepadState();
    }    
  }, [gamepad0, gamepad1, gamepad2, gamepad3]);

  const intervalHRef = useRef<number>();
  useEffect(() => {
    if(!intervalHRef.current) {
      intervalHRef.current = window.setInterval(processGamepadState, 1000/60);
    }

    return () => {
      const intervalHandle = intervalHRef.current;
      if(intervalHandle) {
        window.clearInterval(intervalHandle);
        intervalHRef.current = 0;
      }
    };
  }, [processGamepadState]);

  return processGamepadState;
};

export default useGamepadDevices;