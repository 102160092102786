import { BaseControlMessage } from './BaseControlMessage';
export const PEERMISSINGMESSAGE_KEY = 'peer.missing';
/**
 * Represents the PeerMissingMessage type.
 */
export class PeerMissingMessage extends BaseControlMessage {
    constructor(targetId) {
        super();
        this.type = PEERMISSINGMESSAGE_KEY; // 'prompter.state.edit';
        this.targetId = targetId;
    }
    static fromJson(data) {
        if (data.type !== PEERMISSINGMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for PeerMissingMessage.');
        }
        const input = data;
        const instance = new PeerMissingMessage(input.targetId);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            targetId: this.targetId,
        };
    }
}
PeerMissingMessage.MESSAGE_NAME = PEERMISSINGMESSAGE_KEY;
