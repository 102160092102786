import { useCallback } from 'react';
import { useAppController, useMessageHandler } from '../../../controllers/AppController';
import usePrompterSession, { PrompterSessionState } from '../../../state/PrompterSessionState';

import { ScriptNodeTypes, ScriptNodeStateChangeTypes, ScriptNodeStateChangedEvent, PrompterMode, PauseMessage } from '@fluidprompter/core';
import { Pause } from '../../../models/EditorTypes';

function usePauseElementLogic() {

  const appController = useAppController();

  const handleEnteringViewport = useCallback((e: ScriptNodeStateChangedEvent, prompterSession: PrompterSessionState) => {
    // console.log(`handleEnteringViewport(${e?.type}, ${e?.changeType})`);
    if(!e || !e.nodeMeta || !e.previousState || !e.viewportInfo) {
      throw new Error('`prompter.node.pause.enteringviewport` event requires ScriptNodeStateChangedEvent args.');
    }

    const pauseNode = e.node as Pause;
    const pausePosition = pauseNode.pausePosition || 'CUE'; // Default to CUE position if undefined.

    const reverse = e.previousState.aboveViewport || e.previousState.topClipped;  // We don't want to pause if we are just skipping backwards in the script.
    if(pausePosition === 'BOTTOM' && e.prompterMode === PrompterMode.Playing && !reverse) {
      // const prompterSession = usePrompterSession.getState();
      // console.log(`handleEnteringViewport - isLeader:${prompterSession.isLeader} - appController.dispatchMessage(new PauseMessage());`);

      const pauseAtPosition = e.nodeMeta.top - e.viewportInfo.viewportHeight + e.nodeMeta.height;
      const pauseAtScriptPosition = prompterSession.getScriptPositionByScrollPosition(pauseAtPosition + (prompterSession.scriptNodesMeta?.cuePositionOffset || 0));
      appController.dispatchMessage(new PauseMessage(
        PauseMessage.Mode.WhenArriveAtPosition,
        pauseAtPosition,
        pauseAtScriptPosition,
      ));
    }
  }, []);

  const handleEnteringCue = useCallback((e: ScriptNodeStateChangedEvent, prompterSession: PrompterSessionState) => {
    // console.log(`handleEnteringCue(${e?.type}, ${e?.changeType})`);
    if(!e || !e.nodeMeta || !e.viewportInfo) {
      throw new Error('`prompter.node.pause.enteringviewport` event requires ScriptNodeStateChangedEvent args.');
    }

    const pauseNode = e.node as Pause;
    const pausePosition = pauseNode.pausePosition || 'CUE'; // Default to CUE position if undefined.

    if(pausePosition === 'CUE' && e.prompterMode === PrompterMode.Playing) {
      // console.log(`handleEnteringCue - isLeader:${prompterSession.isLeader} - appController.dispatchMessage(new PauseMessage());`);
      const pauseAtPosition = e.nodeMeta.top - (e.viewportInfo.viewportCuePosition - e.viewportInfo.viewportTop) + (e.nodeMeta.height / 3);
      const pauseAtScriptPosition = prompterSession.getScriptPositionByScrollPosition(pauseAtPosition + (prompterSession.scriptNodesMeta?.cuePositionOffset || 0));
      appController.dispatchMessage(new PauseMessage(
        PauseMessage.Mode.WhenArriveAtPosition,
        pauseAtPosition,
        pauseAtScriptPosition,
      ));
    }
  }, []);

  const handleLeavingViewport = useCallback((e: ScriptNodeStateChangedEvent, prompterSession: PrompterSessionState) => {
    // console.log(`handleLeavingViewport(${e?.type}, ${e?.changeType})`);
    if(!e || !e.nodeMeta || !e.previousState) {
      throw new Error('`prompter.node.pause.enteringviewport` event requires ScriptNodeStateChangedEvent args.');
    }

    const pauseNode = e.node as Pause;
    const pausePosition = pauseNode.pausePosition || 'CUE'; // Default to CUE position if undefined.

    const reverse = e.previousState.bottomClipped;  // We don't want to pause if we are just skipping backwards in the script.
    if(pausePosition === 'TOP' && e.prompterMode === PrompterMode.Playing && !reverse) {
      // console.log(`handleLeavingViewport - isLeader:${prompterSession.isLeader} - appController.dispatchMessage(new PauseMessage());`);
      const pauseAtPosition = e.nodeMeta.top + 1;
      const pauseAtScriptPosition = prompterSession.getScriptPositionByScrollPosition(pauseAtPosition + (prompterSession.scriptNodesMeta?.cuePositionOffset || 0));
      appController.dispatchMessage(new PauseMessage(
        PauseMessage.Mode.WhenArriveAtPosition,
        pauseAtPosition,
        pauseAtScriptPosition,
      ));
    }
  }, []);

  useMessageHandler('prompter.node.statechanged', (e) => {
    const { message } = e;
    if(message.node.type !== ScriptNodeTypes.PAUSE) {
      return;
    }

    const prompterSession = usePrompterSession.getState();
    if(!prompterSession.isLeader) {
      return;
    }

    switch(message.changeType) {
      case ScriptNodeStateChangeTypes.ENTERING_VIEWPORT:
        handleEnteringViewport(message, prompterSession);
        break;
      case ScriptNodeStateChangeTypes.ENTERING_CUE:
        handleEnteringCue(message, prompterSession);
        break;
      case ScriptNodeStateChangeTypes.LEAVING_VIEWPORT:
        handleLeavingViewport(message, prompterSession);
        break;
    }
  });
}

export default usePauseElementLogic;