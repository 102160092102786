import { BaseControlMessage } from './BaseControlMessage';
export const HEARTBEATRESPONSEMESSAGE_KEY = 'heartbeat.response';
/**
 * Represents the HeartbeatResponse type.
 */
export class HeartbeatResponse extends BaseControlMessage {
    constructor(heartbeat) {
        super();
        this.type = HEARTBEATRESPONSEMESSAGE_KEY;
        this._senderTimestamp = this._instantiatedTimestamp = Math.round(performance.now());
        if (heartbeat) {
            this.sender = heartbeat.sender;
            this.senderTimestamp = heartbeat.senderTimestamp;
        }
    }
    /**
     * Used for heartbeat sender to calculate roundtrip time.
     */
    get senderTimestamp() {
        return this._senderTimestamp;
    }
    set senderTimestamp(senderTimestamp) {
        this._senderTimestamp = senderTimestamp;
    }
    get roundtripLatency() {
        if (!this._senderTimestamp) {
            return undefined;
        }
        return this._instantiatedTimestamp - this._senderTimestamp;
    }
    static fromJson(data) {
        if (data.type !== HEARTBEATRESPONSEMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for HeartbeatResponse.');
        }
        const input = data;
        const instance = new HeartbeatResponse();
        instance.senderTimestamp = input.senderTimestamp;
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            senderTimestamp: this.senderTimestamp,
        };
    }
}
HeartbeatResponse.MESSAGE_NAME = HEARTBEATRESPONSEMESSAGE_KEY;
