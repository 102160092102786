import { Descendant, Text } from 'slate';
import { PrompterElement, Hyperlink, Pause, PrompterSegment, ElementTypes, BRoll } from '../../models/EditorTypes';
import MarkedTextSerializer from './MarkedTextSerializer';

class ScriptSerializerResults {
  private body: string[] = [];

  /**
   * Append a string to the markdown body.
   * @param body 
   */
  push(body: string) {
    this.body.push(body);
  }

  /**
   * Merge a recursive serialization result into this result set.
   * @param results 
   */
  merge(results: ScriptSerializerResults) {
    results.body.forEach((value) => {
      this.body.push(value);
    });
  }

  toString() {
    // Serialize the serializer results to the final markdown file.
    return this.body.join('\r\n');
  }
}

class ScriptSerializer {

  serialize(nodes: PrompterElement[]): string {

    const results: ScriptSerializerResults = new ScriptSerializerResults();

    // Start at the beginning!
    let _currentIndex = 0;
    let _lookahead = nodes[_currentIndex];

    do {
      // This is the node we are currently serializing.
      const currentNode = _lookahead;

      // Advance the lookahead to peek at the next node we are serializing.
      _currentIndex++;
      _lookahead = nodes[_currentIndex];

      switch(currentNode.type) {
        case ElementTypes.STARTSCRIPT:
          // results.push(`-----START OF SCRIPT-----\r\n`);
          results.push('[START OF SCRIPT]\r\n');
          break;
        case ElementTypes.ENDSCRIPT:
          // results.push(`-----END OF SCRIPT-----`);
          results.push('[END OF SCRIPT]');
          break;
        case ElementTypes.PAUSE: {
          const pauseNode = currentNode as Pause;
          const pausePosition = pauseNode.pausePosition || 'CUE';
          // results.push(`-----PAUSE AT ${pausePosition}-----\r\n`);
          results.push(`[PAUSE AT ${pausePosition}]\r\n`);
          break;
        }
        case ElementTypes.SCRIPT_SEGMENT:
          results.merge(this.serializeNode(currentNode as Descendant));

          // If our next node to serialize is also a promptersegment, then serialize the break here.
          if(_lookahead && _lookahead.type === ElementTypes.SCRIPT_SEGMENT) {
            const nextSegment = _lookahead as PrompterSegment;
            if(!nextSegment.title) {
              // results.push(`-----BREAK-----\r\n`);
              results.push('\r\n# \r\n');
            }
          }
          break;
      }
    } while(_lookahead);

    return results.toString();
  }

  serializeNode(node: Descendant, marksDeduplicator?: MarkedTextSerializer): ScriptSerializerResults {
    const results = new ScriptSerializerResults();
 
    const marks = marksDeduplicator || new MarkedTextSerializer();
    if (Text.isText(node)) {
      // return marks.processNode(node);
      results.push(marks.processNode(node));
      return results;
    }
  
    const serializedChildren = node.children.map(n => this.serializeNode(n, marks)).join('');

    const finalOutput = marks.closeOpenMarks(serializedChildren);
  
    switch (node.type) {
      case ElementTypes.SCRIPT_SEGMENT: {
        const prompterSegment = node as PrompterSegment;
        // return prompterSegment.title ? `\r\n# ${prompterSegment.title} #\r\n\r\n${finalOutput}` : finalOutput;
        results.push(prompterSegment.title ? `\r\n# ${prompterSegment.title} #\r\n\r\n${finalOutput}` : finalOutput);
        return results;
      }
      case ElementTypes.PARAGRAPH:
        //return `${finalOutput}\r\n`;
        results.push(`${finalOutput}\r\n`);
        return results;  
      case ElementTypes.BROLL: {
        const brollElement = node as BRoll;
        // return `[BROLL description="${brollElement.description}" /]\r\n`;
        results.push(`[BROLL description="${brollElement.description}" /]\r\n`);
        return results;
      }
      /*
      case 'hyperlink':
        const hyperlinkNode = node as Hyperlink;
        return `<a href="${escapeHtml(node.url)}">${children}</a>`
      */
      default:
        break;
    }

    results.push(finalOutput);
    return results;  
  }  
}

export default ScriptSerializer;