/**
 * @file Compute response value for Davinci Resolve Speed Editor authentication challenge.
 * @author Ben Swayne
 * @copyright Ben Swayne 2023
 * 
 * This TypeScript/Javascript implementation is based off the following works:
 * https://github.com/smunaut/blackmagic-misc
 * 
 * Copyright (C) 2021 Sylvain Munaut <tnt@246tNt.com>
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Rotate the 64 bits 8 spaces to the left (or, rotate the 8 bytes 1 byte to the left).
 * @param v 
 * @returns 
 */
const rol8 = (v: bigint): bigint => {
  return ((v << BigInt(56)) | (v >> BigInt(8))) & BigInt('0xffffffffffffffff');
};

/**
 * Rotate the 64 bits 8 spaces to the left, n times.
 * @param v 
 * @param n 
 * @returns 
 */
const rol8n = (v: bigint, n: number): bigint => {
  let returnV = v;
  for(let i = 0; i < n; i++) {
    returnV = rol8(returnV);
  }

  return returnV;
};

/**
 * Compute response value for Davinci Resolve Speed Editor authentication challenge.
 * @param challenge 
 * @returns 
 */
const challengeResponse = (challenge: bigint): bigint => {

  const AUTH_EVEN_TBL = [
    BigInt('0x3ae1206f97c10bc8'),
    BigInt('0x2a9ab32bebf244c6'),
    BigInt('0x20a6f8b8df9adf0a'),
    BigInt('0xaf80ece52cfc1719'),
    BigInt('0xec2ee2f7414fd151'),
    BigInt('0xb055adfd73344a15'),
    BigInt('0xa63d2e3059001187'),
    BigInt('0x751bf623f42e0dde'),
  ];
  const AUTH_ODD_TBL = [
    BigInt('0x3e22b34f502e7fde'),
    BigInt('0x24656b981875ab1c'),
    BigInt('0xa17f3456df7bf8c3'),
    BigInt('0x6df72e1941aef698'),
    BigInt('0x72226f011e66ab94'),
    BigInt('0x3831a3c606296b42'),
    BigInt('0xfd7ff81881332c89'),
    BigInt('0x61a3f6474ff236c6'),
  ];
  const MASK = BigInt('0xa79a63f585d37bf0');

  // Mask off lower three bits of the challenge, use as iteration count.
  const n = Number(challenge & BigInt(7));

  // Rotate the challenge 64 bits 8 spaces to the left, n times.
  let v = rol8n(challenge, n);

  let k: bigint;
  // Even parity of v[bit0] and (0x78 >> n):
  if(Number(v & BigInt(1)) === ((0x78 >> n) & 1)) {
    k = AUTH_EVEN_TBL[n];
  }
  // Odd parity, xor with self rotated one last time:
  else {
    v = v ^ rol8(v);
    k = AUTH_ODD_TBL[n];
  }

  // Return v xored with (self rol8 bitmasked with mask) xored with k:
  return v ^ (rol8(v) & MASK) ^ k;
};

export default challengeResponse;

/*
	* TESTING OUR ALGORITHMS!
	*
// TEST 1 BEGIN
// c=0x22c580124c8acf7f
// n=7
// v=0xc580124c8acf7f22
// k=0x751bf623f42e0dde (EVEN)
// r=0x921be47f7a63398c
// TEST 1 END
console.log();
const challenge1 = BigInt("0x22c580124c8acf7f");
const response1 = bmd_kbd_auth(challenge1);
console.log(`r=${response1.toString(16)}`);
console.log();
// n=7
// v=c580124c8acf7f22
// k=751bf623f42e0dde (EVEN)
// r=921be47f7a63398c


// TEST 2 BEGIN
// c=0x6ce312c12fe474c0
// n=0
// v=0x6ce312c12fe474c0
// k=0x3ae1206f97c10bc8 (EVEN)
// r=0xd60a51be39261f78
// TEST 2 END
console.log();
const challenge2 = BigInt("0x6ce312c12fe474c0");
const response2 = bmd_kbd_auth(challenge2);
console.log(`r=${response2.toString(16)}`);
console.log();
// n=0
// v=6ce312c12fe474c0
// k=3ae1206f97c10bc8 (EVEN)
// r=d60a51be39261f78    
// n=0
// v=6ce312c12fe474c0
// k=3ae1206f97c10bc8 (EVEN)
// r=d60a51be39261f78
// n=0
// v=6ce312c12fe474c0
// k=3ae1206f97c10bc8 (EVEN)
// r=d60a51be39261f78
return;
*/