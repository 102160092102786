import React, { useLayoutEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import { ITrayItem } from './TrayContainer';
import TrayItemIconMenuItem from './TrayItemIconMenuItem';
import TrayItemIconButton from './TrayItemIconButton';

import Check from '@mui/icons-material/Check';
import WallpaperIcon from '@mui/icons-material/Wallpaper';

import { useTranslation } from 'react-i18next';
import { useAppController } from '../../controllers/AppController';

import useBackgroundState, { BackgroundLayout, BackgroundTypes } from '../../state/BackgroundState';
import { shallow } from 'zustand/shallow';

interface BackgroundsTrayItemProps {
  renderAsMenuItem?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
}

const BackgroundsTrayItem = (props: BackgroundsTrayItemProps) => {

  const { renderAsMenuItem } = props;

  //
  // Feature detection. Sharing a display is not supported on iPad for example.
  //
  const webcamSharingAvailable = ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices);
  const displaySharingAvailable = ('mediaDevices' in navigator && 'getDisplayMedia' in navigator.mediaDevices);

  const { t, i18n } = useTranslation();

  const appController = useAppController();

  const backgroundState = useBackgroundState(state => ({
    backgroundType: state.backgroundType,
    // setBackgroundType: state.setBackgroundType,
    backgroundLayout: state.backgroundLayout,
    // setBackgroundLayout: state.setBackgroundLayout,
  }), shallow);

  // useState will hold reference to Websocket Menu anchor element.
  const [trayMenuAnchorEl, setTrayMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const onTrayMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    if(props.onClick) { props.onClick(e); }
    setTrayMenuAnchorEl(e.currentTarget);
  };
  const onTrayMenuClose = () => {
    setTrayMenuAnchorEl(null);
  };

  useLayoutEffect(() => {
    // When renderAsMenuItem changes, we need to close any open menus.
    onTrayMenuClose();
  }, [renderAsMenuItem]);

  const trayItemProps: ITrayItem = {
    title: 'Backgrounds',
    icon: (<WallpaperIcon />),
    onClick: onTrayMenuClick,
  };

  const isLocalBackgroundSource = (backgroundState.backgroundType === BackgroundTypes.Webcam) || (backgroundState.backgroundType === BackgroundTypes.Desktop);
  const isRemoteBackgroundSource = (backgroundState.backgroundType === BackgroundTypes.Remote);
  return (<>
    {renderAsMenuItem ? (<TrayItemIconMenuItem {...trayItemProps} />) : (<TrayItemIconButton {...trayItemProps} />)}
    <Menu
      id="background-menu"
      anchorEl={trayMenuAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(trayMenuAnchorEl)}
      onClose={onTrayMenuClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem
        disabled={isRemoteBackgroundSource}
        onClick={
          () => {
            setTrayMenuAnchorEl(null);
            appController.dispatchMessage('prompter.background.clear');
          }
        }
      >
        {(backgroundState.backgroundType === BackgroundTypes.None) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText inset={backgroundState.backgroundType !== BackgroundTypes.None}>None</ListItemText>
      </MenuItem>
      {webcamSharingAvailable && <MenuItem
        disabled={isRemoteBackgroundSource}
        onClick={
          () => {
            setTrayMenuAnchorEl(null);
            appController.dispatchMessage('prompter.background.webcam');
          }
        }
      >
        {(backgroundState.backgroundType === BackgroundTypes.Webcam) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText inset={backgroundState.backgroundType !== BackgroundTypes.Webcam}>Webcam</ListItemText>
      </MenuItem>}
      {displaySharingAvailable && <MenuItem
        disabled={isRemoteBackgroundSource}
        onClick={
          () => {
            setTrayMenuAnchorEl(null);
            appController.dispatchMessage('prompter.background.desktop');
          }
        }
      >
        {(backgroundState.backgroundType === BackgroundTypes.Desktop) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText inset={backgroundState.backgroundType !== BackgroundTypes.Desktop}>Desktop</ListItemText>
      </MenuItem>}
      <MenuItem onClick={
        () => {
          setTrayMenuAnchorEl(null);
          // appController.dispatchMessage('prompter.background.desktop');
        }
      }>
        {(backgroundState.backgroundType === BackgroundTypes.Remote) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText inset={backgroundState.backgroundType !== BackgroundTypes.Remote}>Remote Source</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        disabled={!isLocalBackgroundSource}
        onClick={
          () => {
            setTrayMenuAnchorEl(null);
            appController.dispatchMessage('prompter.background.togglemute');
          }
        }
      >Toggle Background Mute</MenuItem>
      <Divider />
      <MenuItem onClick={
        () => {
          setTrayMenuAnchorEl(null);
          appController.dispatchMessage('prompter.background.contain');
        }
      }>
        {(backgroundState.backgroundLayout === BackgroundLayout.Contain) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText inset={backgroundState.backgroundLayout !== BackgroundLayout.Contain}>Background Contain</ListItemText>
      </MenuItem>
      <MenuItem onClick={
        () => {
          setTrayMenuAnchorEl(null);
          appController.dispatchMessage('prompter.background.cover');
        }
      }>
        {(backgroundState.backgroundLayout === BackgroundLayout.Cover) && <ListItemIcon>
          <Check />
        </ListItemIcon>}
        <ListItemText inset={backgroundState.backgroundLayout !== BackgroundLayout.Cover}>Background Cover</ListItemText>
      </MenuItem>
      {/* <Divider />
      <MenuItem
        disabled={!isLocalBackgroundSource}
        onClick={
          () => {
            setTrayMenuAnchorEl(null);
            appController.dispatchMessage('prompter.background.clear');
          }
        }
      >Clear Background</MenuItem> */}
    </Menu>
  </>);
};

export default BackgroundsTrayItem;