import { ApplySyncAdjustmentMessage, SenderInfo } from '@fluidprompter/core';

import useConfigurationStore from '../state/ConfigurationStore';
import usePrompterSession from '../state/PrompterSessionState';

function calculateScrollPositionAdjustment(leaderInfo?: SenderInfo): ApplySyncAdjustmentMessage | undefined {
  if(!leaderInfo) {
    // `senderState` is optional and not included in certain app messages - mostly messages that
    // do not affect the prompter position/state.
    // console.log('calculateScrollPositionAdjustment() leaderInfo === undefined, returning early.');
    return;
  }

  const { scriptPosition, scrollSpeed, scrollReversed } = leaderInfo;
  if(!scriptPosition) {
    // scriptPosition is optional and will be undefined if the message sender has not yet
    // rendered the DOM and measured the DOM elements on screen (first couple frames after
    // loading the page or loading a new script).
    // console.log('calculateScrollPositionAdjustment() leaderInfo.scriptPosition === undefined, returning early.');
    return;
  }

  const configState = useConfigurationStore.getState();
  const prompterSession = usePrompterSession.getState();
  const { scriptNodesMeta: localScriptNodesMeta, scrollPosition: localScrolPosition, scriptNodesState: localScriptNodesState } = prompterSession;
  if(!localScriptNodesMeta) {
    // We can't calculate a scroll position if we've not yet rendered the UI/measured DOM nodes.
    // console.log('calculateScrollPositionAdjustment() localScriptNodesMeta === undefined, returning early.');
    return;
  }

  const remoteViewportPositionAsLocal = prompterSession.getScrollPositionByScriptPosition(scriptPosition);
  if(!remoteViewportPositionAsLocal) {
    // We can't calculate a scroll position if we've not yet rendered the UI/measured DOM nodes.
    // console.log('calculateScrollPositionAdjustment() remoteViewportPositionAsLocal === undefined, returning early.');
    return;
  }

  const { scrollPosition: targetScrollPositionFromRemote, heightRemaining } = remoteViewportPositionAsLocal;
  if(targetScrollPositionFromRemote === undefined) {
    // console.log('calculateScrollPositionAdjustment() targetScrollPositionFromRemote === undefined, returning early.');
    return;
  }

  let localCurrentScrollPosition = localScrolPosition;
  localCurrentScrollPosition += localScriptNodesMeta.cuePositionOffset;

  //
  // The time we take to apply a scroll position sync adjustment must never exceed how long it
  // will take to finish scrolling past the end of the current node at the current scroll
  // speed.
  //
  // But if we have the time to spare with a longer node (like a paragraph of text), then let's
  // take 500ms to make the scroll position sync adjustment.
  //
  const msToCompleteNode = Math.floor(heightRemaining * 1000 / configState.scrollSpeed);

  //
  // Never apply a sync adjustment that would result in a slight jitter of reverse
  // scrolling.
  // If the adjustment is significant enough, we will allow reverse scrolling.
  // If the adjustment is minor, cap the adjustment at slightly less than the current scroll
  // speed.
  //
  // The distance we would naturally scroll over the proposed sync adjustment time frame:
  // scrollReversed
  let syncAdjustmentDistance = targetScrollPositionFromRemote - localCurrentScrollPosition;

  //
  // Don't apply a scroll position sync for nodes that are < 2 line heights
  // This reduces jitter when quickly passing over empty lines or space between script
  // segments.
  //
  // if(heightRemaining < (localScriptNodesMeta.lineHeight * 1.5)) {
  //   console.log(`skipped applying small sync adjustment of ${syncAdjustmentDistance}px for ${heightRemaining}px remaining node height at [${scriptPosition.nodePath}]`);
  //   return;
  // }

  //
  // Scale the sync adjustment time as a shorter time for smaller sync delta and longer
  // adjustment time for big adjustments.
  //
  const syncAdjustmentTime = Math.min(
    1000,                // We really never want to take longer than 1/2 second to apply a sync adjustment.
    msToCompleteNode,   // We never want to take longer to sync then it will take to finish scrolling past the end of the current node (as we would then potentially receive another sync adjustment).
    Math.abs(syncAdjustmentDistance) / localScriptNodesMeta.lineHeight * 1000  // Really small sync adjustments of 1-20 pixels can also be completed in a really short period of time.
  );

  //
  // How far would the prompter scroll naturally in the same period as our proposed sync
  // adjustment time window?
  //
  const naturalScrollDistanceDuringSyncTime = (scrollSpeed || 0) * (scrollReversed ? -1.5: 1) * syncAdjustmentTime / 1000;

  /*
  // if(this._message.type === 'sync') {
  console.log(`
    nodePath=${scriptPosition.nodePath},
    nodeHeight=${scriptPosition.nodeHeight}
    nodePosition=${scriptPosition.position},
    remotePosition=${targetScrollPositionFromRemote},
    localPosition=${localCurrentScrollPosition},
    syncAdjustmentDistance=${syncAdjustmentDistance}`);
  // }
  */

  //
  // If the sync adjustment distance is really small, its not likely to be very accurate either
  // depending on network latency. Therefor we won't apply very small sync adjustment distances.
  //
  const syncThresholdLineHeight = localScriptNodesMeta.lineHeight * 0.05;
  if(prompterSession.isPlaying && Math.abs(syncAdjustmentDistance) < syncThresholdLineHeight) {
    // This sync adjustment is too small to be relevant. Network jitter can cause slight +/- based
    // on changing latency from leader over time. To avoid appearing too jittery in animation, we
    // will only compensate for materially significant differences in scroll position.
    // console.log(`skipped applying small sync adjustment ${syncAdjustmentDistance} vs 5% LH = ${syncThresholdLineHeight}`);
    return;
  }

  //
  // What would be the combined effect of the current scroll speed, plus the proposed sync
  // adjustment distance? We will use this to detect small reverse movement jitters.
  //
  const syncDelta = naturalScrollDistanceDuringSyncTime + syncAdjustmentDistance;

  //
  // We want to smooth out reverse movement that would appear like jitter in the animation,
  // without preventing intentional reverse navigation where two prompters are wildly out of
  // sync.
  //
  if(
    prompterSession.isPlaying
      && Math.abs(syncAdjustmentDistance) < (localScriptNodesMeta.viewportHeight / 3)
  ) {
    // The sync delta is minor enough we want to prevent jitter.
    const naturalScrollDistanceIsNegative = naturalScrollDistanceDuringSyncTime < 0;
    const syncDeltaIsNegative = syncDelta < 0;

    // Does the proposed sync adjustment result in reversing the scroll direction?
    if(naturalScrollDistanceIsNegative !== syncDeltaIsNegative) {
      const originalAdjustmentDistance = syncAdjustmentDistance;

      //
      // This prompter is "ahead of" the leader scroll position.
      //
      // The natural scroll distance is a positive value, the syncAdjustment distance is a negative
      // value, and combined together the net sync distance will be a negative value resulting in
      // reverse movement.
      //
      // We want to prevent the reverse movement and just slow down a little bit so we become more
      // in line with the leader.
      //
      // The maximum distance we are willing to adjust will be the total height of the natural
      // scroll distance. So syncAdjustmentDistance <= -(naturalScrollDistanceDuringSyncTime). This
      // would result in a pause without scrolling backwards. To make smooth it out more, we will
      // do even less so that we always continue to scroll forwards.
      //
      syncAdjustmentDistance = -(naturalScrollDistanceDuringSyncTime * 0.8);
      // console.warn(`SyncAdjustment of ${originalAdjustmentDistance}px would result in reverse movement! Replace with sync adjustment of ${syncAdjustmentDistance}px.`);
    }
  }

  //
  // Now we know how much of a sync correction we want to apply!
  //
  return new ApplySyncAdjustmentMessage(
    syncAdjustmentDistance,
    syncAdjustmentTime,
  );
}

export default calculateScrollPositionAdjustment;