import React, { useEffect } from 'react';
import { FluidPrompterPlans } from '@fluidprompter/core';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import lightTheme from '../../themes/LightTheme';

import Dialog from '@mui/material/Dialog';
import DialogTitleWithClose from '../DialogTitleWithClose';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Container } from '@mui/material';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import PersonIcon from '@mui/icons-material/Person';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SecurityIcon from '@mui/icons-material/Security';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import useInputMiddleware, { InputMiddlewareProps } from '../../hooks/useInputMiddleware';

import useApplicationContext from '../../hooks/useApplicationContext';
import useApplicationState from '../../state/ApplicationState';
import { shallow } from 'zustand/shallow';

import ProfileView from './ProfileView/ProfileView';
import BillingAndPlansView from './BillingAndPlansView/BillingAndPlansView';
import PasswordAndAuthView from './PasswordAndAuthView';
import ComparePlansDialog from '../ComparePlansDialog';
import DeleteAccountDialog from './DeleteAccountDialog';

import { useTranslation } from 'react-i18next';

import useMediaQuery from '@mui/material/useMediaQuery';
import './AccountSettingsDialog.scss';

const getAccountPlanLevelDescription = (plan?: FluidPrompterPlans) => {
  switch(plan) {
    default:
    case 'free':
      return 'Free plan';
    case 'pro':
      return 'Pro plan';
    case 'studio':
      return 'Studio plan';
  }
};

function AccountSettingsDialog() {

  const { t } = useTranslation('account');

  const appState = useApplicationState(state => ({
    isAccountSettingsOpen: state.isAccountSettingsOpen,
    setAccountSettingsOpen: state.setAccountSettingsOpen,
    isAccountDeleteOpen: state.isAccountDeleteOpen,
    isComparePlansOpen: state.isComparePlansOpen,
    setComparePlansOpen: state.setComparePlansOpen,
  }), shallow);

  const theme = useTheme();
  const showDialogFullscreen = useMediaQuery(theme.breakpoints.down('lg'));

  const {
    appContext,
  } = useApplicationContext();
  const { isLoading, isAuthenticated, userProfile } = appContext;

  useEffect(() => {
    if(!isAuthenticated) {
      // You can't actually view this component right now.
      appState.setAccountSettingsOpen(false);
    }
  }, [isAuthenticated, appState]);

  const handleClose = () => {
    appState.setAccountSettingsOpen(false);
  };

  /* https://auth0.com/docs/libraries/auth0-react#call-an-api */
  /*
  useEffect(() => {
    (async () => {
      if(!isAuthenticated || !appState.isAccountSettingsOpen ) {
        return;
      }

      try {
        const token = await getAccessTokenSilently({
          audience: 'https://app.fluidprompter.com/api',
          /-*audience: 'https://dev-zek2ko1p.us.auth0.com/api/v2/',*-/
          /-*scope: 'read:posts',*-/
        });
        console.log(`Got app API token from Auth0`, token);
        // http://localhost:8787/api/account/hello
        const apiHostname = (window.location.hostname === 'app.fluidprompter.com') ? 'app.fluidprompter.com' : 'app.fluidprompter.dev';
        const response = await fetch('https://${apiHostname}/api/account/hello', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(`Got app API account response from API serverless function`, response);

        //setPosts(await response.json());
      } catch (e) {
        /-*
        if (e.error === 'login_required') {
          loginWithRedirect();
        }
        if (e.error === 'consent_required') {
          loginWithRedirect();
        }
        *-/
        console.error(e);
      }
    })();
  }, [appState, isAuthenticated, getAccessTokenSilently]);
  */

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    if(selectedIndex === index) {
      // We are clicking an item that is already selected.
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };

  const renderCurrentSection = function(index: number) {
    switch(index) {
      default:
      case 1:
        return (<ProfileView />);
      case 2:
        return (<BillingAndPlansView />);
      case 3:
        return (<PasswordAndAuthView />);
    }
  };

  //
  // Take over processing of human inputs while this modal dialog is open.
  //
  const inputMiddlewareProps = React.useMemo<InputMiddlewareProps>(() => {
    const results: InputMiddlewareProps = {
      exclusive: appState.isAccountSettingsOpen,  // Prevent all user input hooks above this one from receiving input while this model is open.
    };

    return results;
  }, [appState.isAccountSettingsOpen]);
  useInputMiddleware(inputMiddlewareProps);

  return (
    <>
      {(isAuthenticated && appState.isAccountSettingsOpen) && <ThemeProvider theme={lightTheme}>
        <Dialog
          maxWidth='lg'
          fullWidth={true}
          fullScreen={showDialogFullscreen}
          scroll='paper'
          PaperProps={{
            sx: {
              minHeight: 'calc(100% - 64px)'
            }
          }}
          onClose={handleClose}
          open={appState.isAccountSettingsOpen}
        >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading === true}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitleWithClose onClose={handleClose}>{t('title')}</DialogTitleWithClose>
          <DialogContent dividers={true} sx={{ padding: 0 }}>
            <Container maxWidth="lg">
              <Grid container spacing={{ sm: 0, md: 2 }}>
                <Grid item sm={12} md={3} width='100%'>
                  <List disablePadding>
                    <ListItem alignItems="flex-start" sx={{ marginTop: 2, marginBottom: 2, padding: 2 }}>
                      <ListItemAvatar>
                        {isAuthenticated && userProfile ? (<Avatar alt={(userProfile.user_nickname || userProfile.email)} src={userProfile.avatar_url} />) : (<Avatar><PersonIcon/></Avatar>)}
                      </ListItemAvatar>
                      <ListItemText
                        primary={isAuthenticated && userProfile ? (userProfile.user_nickname || userProfile.email) : 'Sign In'}
                        primaryTypographyProps={{
                          style: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }
                        }}
                        secondary={isAuthenticated && userProfile ? getAccountPlanLevelDescription(userProfile.fluidprompter_plan) : 'Get a FREE account to unlock special features!'}
                        secondaryTypographyProps={{
                          style: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }
                        }}
                      />
                    </ListItem>
                    {/*<ListSubheader component="div" id="nested-list-subheader">
                      Nested List Items
                    </ListSubheader>*/}
                    <ListItemButton
                      selected={selectedIndex === 1}
                      onClick={(event) => handleListItemClick(event, 1)}
                    >
                      <ListItemIcon><PersonIcon /></ListItemIcon>
                      <ListItemText primary={t('profilesection.title')} />
                    </ListItemButton>
                    <ListItemButton
                      selected={selectedIndex === 2}
                      onClick={(event) => handleListItemClick(event, 2)}
                    >
                      <ListItemIcon><CreditCardIcon /></ListItemIcon>
                      <ListItemText primary={t('billingplanssection.title')} />
                    </ListItemButton>
                    <ListItemButton
                      selected={selectedIndex === 3}
                      onClick={(event) => handleListItemClick(event, 3)}
                    >
                      <ListItemIcon><SecurityIcon /></ListItemIcon>
                      <ListItemText primary={t('authenticationsection.title')} />
                    </ListItemButton>
                  </List>
                </Grid>
                <Grid item sm={12} md={9}>
                  <Box className="AccountSettingsViewContainer" padding={2} flexGrow={1}>
                    {renderCurrentSection(selectedIndex)}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('done')}</Button>
          </DialogActions>
        </Dialog>
        {appState.isAccountSettingsOpen && appState.isComparePlansOpen && <ComparePlansDialog />}
        {appState.isAccountSettingsOpen && appState.isAccountDeleteOpen && <DeleteAccountDialog />}
      </ThemeProvider>}
    </>
  );
}

export default AccountSettingsDialog;