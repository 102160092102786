import React, { Suspense, useState } from 'react';
import { useCallback } from 'react';
import { MessageHandlerEvent, useMessageHandler } from '../../controllers/AppController';
import { GenericMessage } from '@fluidprompter/core';

const DebugToolsRenderer = React.lazy(() => import('./DebugToolsRenderer'));

function DebugTools() {

  const [debugToolsOpen, setDebugToolsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setDebugToolsOpen(false);
  };

  const showHelp = useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    e.sendToPeers = false;
    console.log('setDebugToolsOpen(true)');
    setDebugToolsOpen(true);
  }, []);
  useMessageHandler('prompter.local.showdebugtools', showHelp);

  return (
    <Suspense>
      {debugToolsOpen && <DebugToolsRenderer handleClose={handleClose} />}
    </Suspense>
  );
}

export default DebugTools;