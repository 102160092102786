import React from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import lightTheme from '../../themes/LightTheme';

import Dialog from '@mui/material/Dialog';
import DialogTitleWithClose from '../DialogTitleWithClose';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import CancelIcon from '@mui/icons-material/Cancel';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LockSharp from '@mui/icons-material/LockRounded';
import UpgradeIcon from '@mui/icons-material/Upgrade';

import useApplicationContext from '../../hooks/useApplicationContext';
import { ISubscriptionUtils } from '../../hooks/useApplicationContext/useSubscriptionUtils';
import { useAppController } from '../../controllers/AppController';
import AppController from '../../controllers/AppController/AppController';
import { useTranslation, Trans } from 'react-i18next';
import { TFunction } from 'i18next';

interface AccountRequiredDialogRendererProps {
  minimumPlan: string;
  featureName: string | undefined;
  handleClose: () => void;
}

interface UnauthenticatedButtonsProps {
  handleClose: () => void;
  appController: AppController;
  t: TFunction;
  showDialogFullscreen: boolean;
}

/**
 * These are the buttons to show on the upsell dialog when the user is not authenticated.
 * @param props
 * @returns
 */
const UnauthenticatedButtons = (props: UnauthenticatedButtonsProps) => {
  const {
    handleClose,
    appController,
    t,
    showDialogFullscreen,
  } = props;
  return <>
    <Button
      startIcon={<LoginIcon />}
      onClick={() => {
        handleClose();
        appController.dispatchMessage('prompter.local.login');
      }}
      sx={{
        // flex: 1,
        flexDirection: showDialogFullscreen ? 'column' : 'row'
      }}
    >{t('accountrequireddialog.signin')}</Button>
    <Button
      startIcon={<PersonAddIcon />}
      onClick={() => {
        handleClose();
        appController.dispatchMessage('prompter.local.signup');
      }}
      sx={{
        // flex: 1,
        flexDirection: showDialogFullscreen ? 'column' : 'row'
      }}
    >{t('accountrequireddialog.createaccount')}</Button>
  </>;
};


interface PlanUpgradeButtonsProps {
  handleClose: () => void;
  appController: AppController;
  subscription: ISubscriptionUtils;
  t: TFunction;
  showDialogFullscreen: boolean;
}

/**
 * These are the buttons to show when the user is authenticated, but needs a higher plan to use
 * the feature.
 * @param props
 * @returns
 */
const PlanUpgradeButtons = (props: PlanUpgradeButtonsProps) => {
  const {
    handleClose,
    appController,
    subscription,
    t,
    showDialogFullscreen,
  } = props;
  return <>
    <Button
      startIcon={<UpgradeIcon />}
      onClick={() => {
        handleClose();
        subscription.startChangePlans();
      }}
      sx={{
        // flex: 1,
        flexDirection: showDialogFullscreen ? 'column' : 'row'
      }}
    >{t('accountrequireddialog.upgradeplan')}</Button>
  </>;
};

const AccountRequiredDialogRenderer = (props: AccountRequiredDialogRendererProps) => {
  const { minimumPlan, featureName, handleClose } = props;

  const { t } = useTranslation('prompter');

  const {
    appContext,
    subscription,
  } = useApplicationContext(false);
  const {
    isAuthenticated,
  } = appContext;

  const appController = useAppController();

  const theme = useTheme();
  const viewportSmOrXs = useMediaQuery(theme.breakpoints.down('sm'));
  const showDialogFullscreen = useMediaQuery(theme.breakpoints.down('md'));

  return (<>
    <ThemeProvider theme={lightTheme}>
      <Dialog
        maxWidth='lg'
        fullWidth={showDialogFullscreen}
        // fullScreen={showDialogFullscreen}
        scroll='paper'
        onClose={handleClose}
        open={true}
      >
        <DialogTitleWithClose onClose={handleClose}><LockSharp sx={{ mr: 1, verticalAlign: 'text-bottom' }} />{t('accountrequireddialog.title')}</DialogTitleWithClose>
        <DialogContent dividers={true}>
          <DialogContentText maxWidth={'md'}>
            <Trans>{t('accountrequireddialog.text')}</Trans>
            <ul>
              <li>{featureName}</li>
            </ul>
            {(minimumPlan === 'free') && <Trans>{t('accountrequireddialog.includedfree')}</Trans>}
            {(minimumPlan === 'pro') && <Trans>{t('accountrequireddialog.includedpro')}</Trans>}
            {(minimumPlan === 'studio') && <Trans>{t('accountrequireddialog.includedstudio')}</Trans>}
          </DialogContentText>

          {/* <Container maxWidth="lg">
          You need a FluidPrompter account to use this feature.
        </Container> */}
        </DialogContent>
        <DialogActions>
          {!viewportSmOrXs && <>
            <Button
              startIcon={<CancelIcon />}
              onClick={handleClose}
              sx={{
                // flex: 1,
                flexDirection: showDialogFullscreen ? 'column' : 'row'
              }}
            >{t('accountrequireddialog.cancel')}</Button>
            <div style={{
              flex: 1,
            }}></div>
          </>}

          {!isAuthenticated && <UnauthenticatedButtons handleClose={handleClose} appController={appController} t={t} showDialogFullscreen={showDialogFullscreen} />}
          {isAuthenticated && <PlanUpgradeButtons handleClose={handleClose} appController={appController} subscription={subscription} t={t} showDialogFullscreen={showDialogFullscreen} />}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  </>);
};

export default AccountRequiredDialogRenderer;