import { BaseControlMessage } from './BaseControlMessage';
export const SEGMENTCHANGEDMESSAGE_KEY = 'segmentchanged';
/**
 * Represents the SegmentChangedMessage type.
 */
export class SegmentChangedMessage extends BaseControlMessage {
    constructor(segmentIndex) {
        super();
        this.type = SEGMENTCHANGEDMESSAGE_KEY;
        this.currentSegmentIndex = segmentIndex;
    }
    static fromJson(data) {
        if (data.type !== SEGMENTCHANGEDMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for SegmentChangedMessage.');
        }
        const input = data;
        if (!input.sender
            || !input.sender.n
            || !input.sender.n.length) {
            throw new SyntaxError('Invalid JSON for SegmentChangedMessage. Missing sender.');
        }
        const instance = new SegmentChangedMessage(input.sender.n[0]);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
        };
    }
}
SegmentChangedMessage.MESSAGE_NAME = SEGMENTCHANGEDMESSAGE_KEY;
