import { useCallback } from 'react';
import { useMessageHandler, MessageHandlerEvent } from '../../controllers/AppController';
import usePrompterSession from '../../state/PrompterSessionState';

import { IViewportRefs } from './usePrompterViewportRefs';
import { PauseMessage } from '@fluidprompter/core';

export interface PauseAtPositionArgs {
    scrollPosition: number,
}
function usePrompterPauseAtPositionHandler(viewportRefs: IViewportRefs) {
  const doPauseAtPosition = useCallback(async function (e: MessageHandlerEvent<PauseMessage>) {
    const currentLedger = viewportRefs.previousLedgerRef.current;
    if(!e || !currentLedger || (e.message.position === undefined)) {
      return;
    }

    const prompterSession = usePrompterSession.getState();

    if(prompterSession.isPlaying) {
      currentLedger.pauseAtPosition = e.message.position;  //pixels - whenPosition
    }
  }, [viewportRefs.previousLedgerRef]);
  // useListener('prompter.pauseAtPosition', doPauseAtPosition);
  // useMessageHandler('prompter.pauseAtPosition', doPauseAtPosition);
}

export default usePrompterPauseAtPositionHandler;