import { v4 as uuidv4 } from 'uuid';

import { Logger, TRACE } from 'browser-bunyan';
import logger from '../../utils/Logger';
import usePrompterSession from '../../state/PrompterSessionState';

/**
 * 'hello' = announce new browser tab (if collision, master will reply collision)
 * 'bye' = optionally, tell other tabs we are leaving
 * 'ping' = heartbeat to other tabs that we are still here
 */
type WindowEventTypes = 'hello' | 'bye' | 'ping' | 'collision_check' | 'collision_confirm';

interface IWindowDescriptor {
  windowId: string;
  lastPingTime: number;
}

interface IWindowEvent {
  windowId: string;
  type: WindowEventTypes;
}

/**
 * How often should this browser window 'ping' other browser windows to let them know we are still
 * here.
 */
const WINDOW_PING_INTERVAL_MS = 17000;

/**
 * JavaScript Timers in the browser are not accurate down the milliseconds and will fluctuate with
 * regular use. They may also be temporarily blocked (delayed) if the UI thread is heavily loaded,
 * which may happen at initial page load.
 *
 * When we checkout for a stale window timeout, we will add a margin for error.
 */
const WINDOW_PING_JITTER_MARGIN = 5000;

/**
 * How often should we check for expired window entries in our openWindows list? Arbitrary number,
 * if we are not sensitive about how quickly we notice stale window, this could be larger and/or
 * combined with our ping timer to reduce the number of timers we have.
 */
const WINDOW_CHECK_INTERVAL_MS = 9000;

export default class WindowTracker {

  /**
   * Static singleton instance of WindowTracker
   */
  public static instance: WindowTracker = new WindowTracker();

  private log: Logger;

  private _proposedWindowId?: string;
  private _confirmedWindowId?: string;
  private _broadcastChannel?: BroadcastChannel;

  private _pingTimeout = 0;
  private _checkTimeout = 0;
  private collision_check_timeout = 0;

  private constructor() {

    this.log = logger.child({
      childName: 'WindowTracker',
    });
    this.log.trace('WindowTracker.constructor()');

    // BroadcastChannel will send messages between multiple browser tabs.
    if('BroadcastChannel' in window) {  // BroadcastChannel is not defined in unit tests
      this._broadcastChannel = new BroadcastChannel('WindowTracker');
      this._broadcastChannel.addEventListener('message', this.handleMessageEvent.bind(this));
    }

    //
    // Retrieve the previous known windowId, if one has been cached.
    this._proposedWindowId = sessionStorage.getItem('windowId') || undefined;

    //
    // Retrieve the list of currently open browser windows
    const openWindowsJson = localStorage.getItem('openWindows');
    if(openWindowsJson) {
      try {
        // Load the list of open browser windows/tabs from localstorage
        const recordedOpenWindows = JSON.parse(openWindowsJson) as IWindowDescriptor[];

        // Filter out expired windows (stale data in localStorage from prior sessions)
        const proposedOpenWindows = recordedOpenWindows.filter(
          window =>
            (Date.now() - window.lastPingTime) < (WINDOW_PING_INTERVAL_MS + WINDOW_PING_JITTER_MARGIN)
              || window.windowId === this._proposedWindowId
        );

        // Did we expire any stale records?
        if(proposedOpenWindows.length !== recordedOpenWindows.length) {
          this.log.trace(`Pruned ${recordedOpenWindows.length - proposedOpenWindows.length}/${recordedOpenWindows.length} stale window entries`);
        }

        this.openWindows = proposedOpenWindows;
      } catch(err) {
        // Bad data in localStorage
        localStorage.removeItem('openWindows');
      }
    }

    //
    // If we either don't have a windowId yet, or have a windowId collision, then we need tp
    // generate a new windowId now.
    const missingWindowId = !this._proposedWindowId;
    if(missingWindowId) {
      this.log.trace('No cached windowId, generate new windowId.');
      this.setWindowId(uuidv4());
      return;
    }

    //
    // A collision only happens when you 'duplicate' or clone a browser tab or window (in which
    // case the sessionStorage is also copied to the new tab).
    //
    // But we need to differentiate between reloading one unique browser window/tab vs duplicating
    // or cloning a browser tab.
    //
    const haveCollision = this.openWindows.findIndex((window) => window.windowId === this._proposedWindowId) >= 0;
    if(haveCollision) {
      this.log.trace('BEGIN windowId Collision! Query for other tab with same windowId');

      //
      // We have detected a potential windowId collision. If we cannot confirm the collision with
      // the another browser window/tab then we will keep using the same windowId.
      //
      this.collision_check_timeout = window.setTimeout(() => {
        this.log.trace('FINISH windowId Collision - timed out - keep using same windowId');
        this.setWindowId(this._proposedWindowId);
      }, 1000);

      //
      // We have detected a potential windowId collision, let's confirm the collision by
      // broadcasting a 'collision_check' message. If another browser window/tab has the same
      // windowId, it will respond with a 'collision_confirm' message. Otherwise we will hit
      // our collision_check_timeout above and continue using the same windowId (this is when we
      // refresh the current page).
      //
      this.broadcast('collision_check');
      return;
    }

    //
    // If we have an existing windowId in sessionStorage, but no entry in localStorage.
    //
    this.log.trace('Have sessionStorage, but no collision');
    this.setWindowId(this._proposedWindowId);
  }

  get logLevel() {
    return this.log.level();
  }
  set logLevel(levelNumber: number) {
    this.log.level(levelNumber);
  }

  get windowId() {
    return this._confirmedWindowId;
  }

  get openWindows() {
    return this._openWindows;
  }
  private set openWindows(value: IWindowDescriptor[]) {
    if(this._openWindows === value) {
      return;
    }

    //
    // update our own local windowId timestamp whenever we re-write out OpenWindows list.
    //
    this._openWindows = value.map(window => window.windowId === this._confirmedWindowId ? {
      windowId: this._confirmedWindowId,
      lastPingTime: Date.now(),
    } : window);

    //
    // The first window in the openWindows list is always the master, and is responsible for
    // writing our openWindows list to localStorage.
    //
    if(
      this._openWindows.length === 0
        || this._openWindows[0].windowId === this._confirmedWindowId
    ) {
      this.log.trace(`write ${this._openWindows.length} openWindows to localStorage`);
      const openWindowsJson = JSON.stringify(this._openWindows);
      localStorage.setItem('openWindows', openWindowsJson);
    }
  }
  private _openWindows: IWindowDescriptor[] = [];

  /**
   * Called when we have an acceptable windowId. Either there was no collision to begin with, or we
   * detected a false collision that was never confirmed. The windowId we are being passed is safe
   * to use without conflict with another browser window/tab.
   * @param windowId
   */
  private setWindowId(windowId?: string) {
    if(!windowId) {
      throw new Error('setWindowId() invalid windowId');
    }
    this.log.trace(`setWindowId(${windowId})`);

    this._confirmedWindowId = windowId;
    sessionStorage.setItem('windowId', this._confirmedWindowId);
    usePrompterSession.getState().setInstanceId(windowId);

    //
    // Add ourself to the list of open windows.
    this.upsertOpenWindow(this._confirmedWindowId);

    //
    // Announce to any other open browser windows/tabs that we are here.
    this.broadcast('hello');

    //
    // Setup our watchdog timers
    const check = () => {
      this.checkForExpiredWindows();
      this._checkTimeout = window.setTimeout( check, WINDOW_CHECK_INTERVAL_MS );
    };
    const ping = () => {
      this.sendPing();
      this._pingTimeout = window.setTimeout( ping, WINDOW_PING_INTERVAL_MS );
    };
    this._checkTimeout = window.setTimeout( check, WINDOW_CHECK_INTERVAL_MS );
    this._pingTimeout = window.setTimeout( ping, WINDOW_PING_INTERVAL_MS );
  }

  /**
   * Called when we are assigned a unique local windowId as well as when we receive a 'ping'
   * message from another browser. We will insert or update the window record with the current
   * timestamp.
   * @param targetWindowId
   * @returns
   */
  private upsertOpenWindow(targetWindowId: string) {
    const existingWindowIndex = this.openWindows.findIndex(window => window.windowId === targetWindowId);

    //
    // Do we need a new window entry?
    if(existingWindowIndex < 0) {
      // Not found, just push
      this.log.trace(`upsertOpenWindow(${targetWindowId}) did insert`);
      this.openWindows = [
        ...this.openWindows,
        {
          windowId: targetWindowId,
          lastPingTime: Date.now(),
        }
      ];
      return;
    }

    //
    // Otherwise we need to update an existing entry.
    this.log.trace(`upsertOpenWindow(${targetWindowId}) did update`);
    this.openWindows = this.openWindows.map(window => window.windowId === targetWindowId ? {
      windowId: targetWindowId,
      lastPingTime: Date.now(),
    } : window);
  }

  /**
   * Check for expired windows that haven't pinged us in a while.
   */
  private checkForExpiredWindows() {
    const now = Date.now();

    //
    // Check if any windowIds are stale.
    const proposedOpenWindows = this.openWindows.filter(
      window =>
        (now - window.lastPingTime) < (WINDOW_PING_INTERVAL_MS + WINDOW_PING_JITTER_MARGIN)
          || window.windowId === this._confirmedWindowId
    );
    const expiredWindows = this.openWindows.length - proposedOpenWindows.length;
    if(expiredWindows) {
      this.log.trace(`checkForExpiredWindows(): ${expiredWindows} windows have expired`);
      this.openWindows = proposedOpenWindows;
    }
  }

  /**
   * Called when this browser window/tab receives a message via our BroadcastChannel.
   * @param e
   */
  private handleMessageEvent(e: MessageEvent<IWindowEvent>) {
    const { data } = e;
    try {
      //
      // Execute the method on this WindowTracker given the data.type, and passing data to the
      // method.
      //
      // ex: call this.hello(data)
      // ex: call this.bye(data)
      //
      this[data.type](data);
    } catch ( error ) {
      // This basically has to be a malicious message.
    }
  }

  private sendPing() {
    this.broadcast('ping');
  }

  private broadcast(type: WindowEventTypes) {
    const currentWindowId = this._confirmedWindowId ?? this._proposedWindowId;
    if(!currentWindowId) {
      throw new Error('broadcast() missing localWindowId');
    }

    const event: IWindowEvent = {
      windowId: currentWindowId,
      type: type
    };

    this._broadcastChannel?.postMessage(event);
  }

  private destroy() {
    clearTimeout( this._pingTimeout );
    clearTimeout( this._checkTimeout );

    this.broadcast('bye');
  }

  /**
   * Called when another browser tab has joined us and sent us a 'hello' message.
   * @param event
   */
  private hello(event: IWindowEvent) {
    this.log.trace(`New browser window '${event.windowId}' announced themself to us`);

    //
    // Handle the ping message which will upsert the record of the other browser window in our
    // openWindows list.
    this.ping(event);

    //
    // Reply to the new browser window with our windowId.
    this.sendPing();
  }

  /**
   * Called when another browser tab has joined us and believes it may have a colliding windowId.
   * If we receive a 'collision_check' message from a window/tab with the same windowId as ours,
   * we will reply with a 'collision_confirm' which will instruct the other tab to generate a new
   * windowId for itself.
   * @param event
   */
  private collision_check(event: IWindowEvent) {
    const collisionConfirmed = event.windowId === this._confirmedWindowId;
    this.log.trace(`Received 'collision_check' for windowId '${event.windowId}'${collisionConfirmed ? ', Collision Confirmed': ''}`);
    if(collisionConfirmed) {
      this.broadcast('collision_confirm');
    }
  }

  /**
   * If we receive a 'collision_confirm' message from another window/tab that has the same windowId
   * as this window/tab, we will re-generate our windowId. This happens when you 'duplicate' or
   * clone a browser tab in chromium based browsers.
   * @param event
   */
  private collision_confirm(event: IWindowEvent) {
    this.log.trace(`Received 'collision_confirm' for windowId '${event.windowId}'`);
    if(!this._confirmedWindowId || event.windowId === this._confirmedWindowId) {
      // We have confirmed a windowId collision! Generate a new windowId.
      this.log.trace('FINISH windowId Collision - collision confirmed - generate new windowId');

      window.clearTimeout(this.collision_check_timeout);

      this.setWindowId(uuidv4());
    }
  }

  /**
   * Called when another browser tab is unloading.
   * @param event
   */
  private bye(event: IWindowEvent) {
    this.checkForExpiredWindows();
  }

  /**
   * Called when another browser tab sent us a message to let us know they are still around.
   * We will update the lastPingTime for that browsers so we don't the window record doesn't
   * expire.
   * @param event
   */
  private ping(event: IWindowEvent) {
    this.upsertOpenWindow(event.windowId);
  }
}