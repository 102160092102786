import React, { useEffect } from 'react';

import Navbar from '../components/Navbar';
import ControlBar from '../components/ControlBar';
import PrompterDrawer from '../components/PrompterDrawer';
import PrompterBackground from '../components/PrompterBackground';
import PrompterViewport from '../components/PrompterViewport';
import MouseInputMask from '../components/MouseInputMask';

import AccountSettingsDialog from '../components/AccountSettingsDialog';
import RemoteDialog from '../components/RemoteDialog';
import HelpDialog from '../components/HelpDialog';
import ShotLogReviewDialog from '../components/ShotLogReviewDialog';

import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import usePrompterSession from '../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

function ProjectorPage() {

  const prompterSession = usePrompterSession(state => ({
    prompterId: state.prompterId,
    setPrompterId: state.setPrompterId,
    hasUnsavedChanges: state.hasUnsavedChanges,
  }), shallow);

  const [searchParams, setSearchParams] = useSearchParams();
  const prompterId = searchParams.get('prompterId')?.toUpperCase();

  /*
  React.useEffect(() => {
    const currentTime = new Date().toLocaleTimeString();
    if(isPageVisible) {
      toast.success(`${currentTime} Page became visible!`, {
        hideProgressBar: true,
        delay: 500
      });
    } else {
      // page went into hiding.
      toast.error(`${currentTime} Page became hidden!`, {
        hideProgressBar: true,
        delay: 250
      });
    }
  }, [isPageVisible]);
  */

  React.useEffect(() => {
    if(!prompterId) {
      // console.log('Generating new prompterId!');
      const newPrompterId = uuidv4().split('-')[0].toUpperCase();
      const searchParams2 = { ...searchParams, prompterId: newPrompterId };
      setSearchParams(searchParams2, { replace: true });
    }

    if(prompterId && prompterId !== prompterSession.prompterId) {
      prompterSession.setPrompterId(prompterId);
    }

    // Return a clean-up function that is used if the component is re-rendered.
    // return () => cancelAnimationFrame(requestRef.current);
  }, [searchParams, setSearchParams, prompterId, prompterSession]);

  useEffect(() => { document.title = 'FluidPrompter Viewer'; }, []);

  return (
    <>
      <PrompterBackground />

      {/* This is our main Prompter content that will be scrolled. */}
      <PrompterViewport />

      {/* PrompterOverlay should contain the masks <PrompterOverlay /> */}
      {/* MaskTop */}
      {/* MaskTop */}
      {/* MaskBottom */}

      {/* Navbar will render in portal, position fixed, zIndex 1100 */}
      <Navbar />

      {/* PrompterDrawer will render in portal, position fixed, zIndex 1200 */}
      <PrompterDrawer />

      {/* MouseInputMask is zIndex 1075 */}
      <MouseInputMask />

      <AccountSettingsDialog />
      <RemoteDialog />
      <HelpDialog />
      <ShotLogReviewDialog />

      {/* ControlBar will render in portal, position fixed, zIndex 1100 */}
      <ControlBar />
    </>
  );
}

export default ProjectorPage;