import React from 'react';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';

// import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
// import KeyboardIcon from '@mui/icons-material/Keyboard';
// import MouseIcon from '@mui/icons-material/Mouse';
import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useTranslation } from 'react-i18next';

import useConfigurationStore from '../../../state/ConfigurationStore';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Left align the label relative to the enclosed form input element.
// https://stackoverflow.com/a/56828472
const StyledFormControlLabel = styled(FormControlLabel)({
  width: '100%',
  alignItems: 'center', // 'start',
  justifyContent: 'space-between',
  marginLeft: 0,
});

const StyledFormHelperText = styled(FormHelperText)({
  marginTop: 0,
  marginBottom: 12,
});

interface ControlMethodsProps {
  expanded: boolean;
  onExpand: () => void;
}
const ControlMethods = (props: ControlMethodsProps) => {
  const configStore = useConfigurationStore();
  const { t } = useTranslation('appmenu');

  return (
    <>
      <ListItemButton
        selected={props.expanded}
        onClick={(event) => {
          props.onExpand();
        }}
      >
        <ListItemText primary={t('controlsmenu.controlmethods.caption')} />
        {props.expanded ? <ExpandLess /> : <ChevronRightIcon />}
      </ListItemButton>
      <Collapse
        sx={{
          pt: 2,
          pr: 2,
          pb: 2,
          pl: 4,
          background: 'rgba(25, 118, 210, 0.08)',
        }}
        in={!!props.expanded} timeout="auto"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <StyledFormControlLabel
          labelPlacement="start"
          label={`${t('controlsmenu.controlmethods.promptermousecontrols.caption')}:`}
          control={<Switch
            aria-describedby="prompter-mouse-controls-helper-text"
            checked={configStore.mouseControlsEnabled}
            onChange={(e) => configStore.setMouseControlsEnabled(e.target.checked) }
          />}
        />
        <StyledFormHelperText id="prompter-mouse-controls-helper-text">{t('controlsmenu.controlmethods.promptermousecontrols.helpertext')}</StyledFormHelperText>

        <StyledFormControlLabel
          labelPlacement="start"
          label={`${t('controlsmenu.controlmethods.alwaysshownavigation.caption')}:`}
          control={<Switch
            aria-describedby="show-prompting-toolbar-helper-text"
            checked={configStore.alwaysShowNavigation}
            onChange={(e) => configStore.setAlwaysShowNavigation(e.target.checked) }
          />}
        />
        <StyledFormHelperText id="show-prompting-toolbar-helper-text">{t('controlsmenu.controlmethods.alwaysshownavigation.helpertext')}</StyledFormHelperText>
      </Collapse>
    </>
  );
};

export default ControlMethods;