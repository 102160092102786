export class SenderInfo {
    get scriptPosition() {
        if (this.nodePath === undefined
            || this.nodeHeight === undefined
            || this.nodeChildrenTop === undefined
            || this.nodeChildrenHeight === undefined
            || this.nodePosition === undefined) {
            return undefined;
        }
        return {
            nodePath: this.nodePath,
            nodeHeight: this.nodeHeight,
            nodeChildrenTop: this.nodeChildrenTop,
            nodeChildrenHeight: this.nodeChildrenHeight,
            position: this.nodePosition,
        };
    }
    set scriptPosition(scriptPosition) {
        if (!scriptPosition) {
            return;
        }
        this._scriptPosition = scriptPosition;
        this.nodePath = this._scriptPosition.nodePath;
        this.nodeHeight = this._scriptPosition.nodeHeight;
        this.nodeChildrenTop = this._scriptPosition.nodeChildrenTop;
        this.nodeChildrenHeight = this._scriptPosition.nodeChildrenHeight;
        this.nodePosition = this._scriptPosition.position;
    }
    constructor(id, sequence, role, mode) {
        this.id = id;
        this.sequence = sequence;
        this.role = role;
        this.mode = mode;
    }
    static fromJson(data) {
        const instance = new SenderInfo(data.id, data.sq, data.r, data.m);
        instance.scrollSpeed = data.s;
        instance.scrollReversed = data.sr;
        instance.scrollPosition = data.p;
        instance.viewportHeight = data.vh;
        instance.contentHeight = data.ch;
        instance.nodePath = data.n;
        instance.nodeHeight = data.nh;
        instance.nodeChildrenTop = data.nct;
        instance.nodeChildrenHeight = data.nch;
        instance.nodePosition = data.np;
        return instance;
    }
    toJSON() {
        return {
            id: this.id,
            sq: this.sequence,
            r: this.role,
            m: this.mode,
            // Root prompter state
            s: this.scrollSpeed,
            sr: this.scrollReversed,
            p: this.scrollPosition,
            vh: this.viewportHeight,
            ch: this.contentHeight,
            // Flattened IScriptPosition
            n: this.nodePath,
            nh: this.nodeHeight,
            nct: this.nodeChildrenTop,
            nch: this.nodeChildrenHeight,
            np: this.nodePosition,
        };
    }
}
export default SenderInfo;
