import React from 'react';
import OfflineUserMenu from './OfflineUserMenu';
import OnlineUserMenu from './OnlineUserMenu';

import useApplicationContext from '../../../hooks/useApplicationContext';

import './UserMenu.scss';

interface UserMenuProps {
  expanded: boolean;
  onExpand: () => void;
}
const UserMenu = (props: UserMenuProps) => {
  const {
    appContext,
  } = useApplicationContext();
  const { internetOnline, isLoading, isAuthenticated, userProfile } = appContext;

  if(internetOnline) {
    return (<OnlineUserMenu {...props} isLoading={isLoading} isAuthenticated={isAuthenticated} userProfile={userProfile} />);
  }

  return (<OfflineUserMenu />);
};

export default UserMenu;