import { ShepherdOptionsWithType } from 'react-shepherd';
import { offset} from '@floating-ui/dom';

import { TourStepFactoryMethod, TourStepFactoryParams } from './TourStepTypes';

const HelpTourStepFactory: TourStepFactoryMethod = (params: TourStepFactoryParams) => {
  const { t } = params;

  const HelpTourStep: ShepherdOptionsWithType = {
    id: 'HelpMenu',
    text: [t('welcometour.helpstep.body')],
    // text: [
    //   `
    //   <p>
    //   Anytime you need a little help, click the help icon (<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="HelpIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"></path></svg>).
    //   </p>
    //   <p>
    //   You can also open the help screen using either <kbd>F1</kbd> or <kbd>Alt</kbd> + <kbd>H</kbd> keyboard shortcuts.
    //   </p>
    //   `
    // ],
    attachTo: { element: '.TourTargetHelpIcon', on: 'bottom-end' },
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: t('back')
      },
      {
        type: 'next',
        text: t('next')
      }
    ],
    when: {
      show: () => {
        // const currentStep = Shepherd.activeTour?.getCurrentStep();
        // const currentStepElement = currentStep?.getElement();
        const shepherdElements = document.querySelectorAll('[data-shepherd-step-id="HelpMenu"]');
        if(shepherdElements.length === 0) {
          return;
        }

        const currentStepElement = shepherdElements.item(0);
        if(!currentStepElement) {
          return;
        }

        const footer = currentStepElement.querySelector('.shepherd-footer');
        const progress = document.createElement('span');
        progress.className = 'shepherd-progress';
        // progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1} of ${Shepherd.activeTour?.steps.length}`;
        // progress.innerText = `Tip 1 of 3`;
        progress.innerHTML = t('tip', { number: '1', total: '3' });

        footer?.insertBefore(progress, currentStepElement.querySelector('.shepherd-button:first-child'));
      },
      hide: () => {
        console.log('hide step');
      }
    },
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 12, crossAxis: 0 })]
    }
  };

  return HelpTourStep;
};

export default HelpTourStepFactory;