import BaseControlMessage from './BaseControlMessage';
export const SCRIPTREQUESTMESSAGE_KEY = 'script.request';
/**
 * Represents the ScriptRequestMessage type.
 */
export class ScriptRequestMessage extends BaseControlMessage {
    constructor() {
        super();
        this.type = SCRIPTREQUESTMESSAGE_KEY;
    }
    static fromJson(data) {
        if (data.type !== SCRIPTREQUESTMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for ScriptRequestMessage.');
        }
        // const input = data as ScriptRequestMessageJson;
        const instance = new ScriptRequestMessage();
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
        };
    }
}
ScriptRequestMessage.MESSAGE_NAME = SCRIPTREQUESTMESSAGE_KEY;
