import React, { useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import { PlayMessage, PauseMessage, SetScrollSpeedMessage, NavigateMessage } from '@fluidprompter/core';

import { useAppController, useMessageHandler } from '../../controllers/AppController';
import _ from 'lodash';
// import * as _ from "lodash";
import useStatsCollector from '../../hooks/useStatsCollector';

import classNames from 'classnames';
import './MouseInputMask.scss';


const CURSOR_HIDE_TIMEOUT = 2000;
const MOUSE_TRAVEL_REQUIRED_TO_SHOW_CURSOR = 400;
const MOUSE_TRAVEL_REQUIRED_TO_PREVENT_HIDE_CURSOR = 50;

/**
 * This component renders a transparent mask element on top of all
 * other teleprompter UI elements in order to capture mouse event
 * while prompting.
 *
 * We can then capture mouse clicks and mousewheel events that can
 * be used to control the teleprompter.
 *
 * We will also hide the cursor when the cursor is not moving (or
 * not moving much) and then reshow the cursor if you wiggle the
 * mouse around.
 */
// For Zustand store: const selector = state => state.cursorHidden;
function MouseInputMask() {
  // console.log('MouseInputMask constructor');

  const mouseControlsEnabled = useConfigurationStore(state => state.mouseControlsEnabled);

  const prompter = usePrompterSession(state => ({
    isPlaying: state.isPlaying,
    isEditing: state.isEditing,
  }), shallow);

  const cursor = usePrompterSession(state => ({
    cursorHidden: state.cursorHidden,
    setCursorHidden: state.setCursorHidden,
  }), shallow);

  const collectMouseMove = useStatsCollector();

  const appController = useAppController();

  //
  // Setup Drag-drop file handler. This hook also owns our refs to the prompter viewport and the
  // file input element - which we will pass along.
  //
  const { getRootProps, getInputProps, isDragActive, rootRef, inputRef, open } = useDropzone({
    //
    // The list of supported file types
    //
    accept: {
      'text/plain': ['.txt'],
      'text/markdown': ['.md'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/pdf': ['.pdf'],
    },

    //
    // We can only open 1 file at a time. Do not allow drag-drop of multiple files.
    //
    maxFiles: 1,

    //
    // Do not show the open file picker when you click the drag-drop zone.
    //
    noClick: true,

    //
    // Do not show the open file picker via any key pressed in the drag-drop zone.
    //
    noKeyboard: true,

    //
    // Attach handler when a file is drag-dropped or otherwise opened.
    //
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      appController.dispatchMessage('handlefile', {
        acceptedFiles,
        fileRejections,
      });
    }
  });

  // const maskRef = React.useRef<HTMLDivElement>(null);
  // const maskRef = React.createRef<HTMLDivElement>();

  // Reference to hold accumulated scroll amount between animation frames.
  const amountRef = React.useRef<number>(0);

  // Reference to hold setTimeout handle for hide cursor timer.
  const timeoutRef = React.useRef<number>(0);

  /**
   * Function that will set our cursor hidden if it is not already hidden and the prompter is not
   * in edit mode (cursor hiding is disabled in edit mode)
   */
  const hideCursor = useCallback(() => {
    const currentState = usePrompterSession.getState();
    if(!currentState.cursorHidden && !currentState.isEditing) {
      currentState.setCursorHidden(true);

      if(document.activeElement) {
        // Make sure no user inputs are currently focused, stealing keypresses.
        const activeElement = document.activeElement as HTMLElement;
        activeElement.blur();  // THIS WAS CAUSING SLATE EDITOR TO LOSE FOCUS
      }
    }
  }, []);

  /**
   * Function that will clear any current hide cursor timeout and request we hide the cursor
   * immediately.
   */
  const expireHideCursorTimer = useCallback(() => {
    if(timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Immediately hide the cursor.
    hideCursor();
  }, [hideCursor]);
  useMessageHandler('prompter.local.HideCursorTimer.Expire', expireHideCursorTimer);

  /**
   * Function used to request we show the cursor. This function is throttled as it may be called
   * many times within a mouse move event.
   */
  const showCursorThrottled = useCallback(_.throttle(() => {
    if(cursor.cursorHidden) { cursor.setCursorHidden(false); }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(hideCursor, CURSOR_HIDE_TIMEOUT);
  }, 1000), [cursor]);

  useMessageHandler('prompter.local.HideCursorTimer.Restart', () => {
    /*
    if(timeoutRef.current) {
      // clear previous timer.
      clearTimeout(timeoutRef.current);
    }

    if(prompterSession.isEditing) {
      // Don't worry about hiding the cursor in edit mode.
      return;
    }

    // Restart the 3 second hide cursor timer.
    timeoutRef.current = window.setTimeout(hideCursor, CURSOR_HIDE_TIMEOUT);
    */

    showCursorThrottled();
  });

  // After initial rendering of the MouseInputMask, start our 3 second timer
  // to hide the mouse cursor.
  React.useEffect(() => {
    if(prompter.isEditing) {
      // Don't worry about hiding the cursor in edit mode.
      return;
    }
    timeoutRef.current = window.setTimeout(hideCursor, CURSOR_HIDE_TIMEOUT);

    return () => { clearTimeout(timeoutRef.current); };
  }, [prompter.isEditing, hideCursor]);

  React.useEffect(() => {
    //
    // Reset the wheel accumulator when we toggle play/pause.
    //
    amountRef.current = 0;

    //
    // Is we are transitioning from editing/paused to playing, expire the hide cursor timer.
    //
    if(prompter.isPlaying) {
      expireHideCursorTimer();
    }
  }, [prompter.isPlaying, expireHideCursorTimer]);

  const dispatchUpdatesThrottled = React.useCallback(_.throttle(() => {
    const scrollAmount = amountRef.current;

    if(usePrompterSession.getState().isPlaying) {
      const scrollSpeed = useConfigurationStore.getState().scrollSpeed;
      appController.dispatchMessage(new SetScrollSpeedMessage(scrollSpeed + scrollAmount));
    } else {
      appController.dispatchMessage('prompter.scrollby', {
        deltaY: scrollAmount
      });
    }

    amountRef.current = 0;
  }, 1000/60), []);

  // We're using `MouseEvent` as type for the event.
  // It contains properties an event can have
  // and methods (such as `preventDefault` an others).
  const clickHandler = useCallback((e: MouseEvent): void => {
    if(!cursor.cursorHidden) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const isPlaying = usePrompterSession.getState().isPlaying;
    if(!mouseControlsEnabled && isPlaying) {
      return;
    }

    // alert(`Clicked at ${e.pageX} ${e.pageY}`);

    // click { target: div.MouseInputMask, buttons: 0, clientX: 494, clientY: 214, layerX: 494, layerY: 214 }

    // For left click on MacBook
    // button: 0
    // buttons: 0
    // which: 1

    // For right click on MacBook
    // button: 2
    // buttons: 2
    // which: 3
    switch(e.button) {
      case 0: { // Left click
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageUp));
        break;
      }
      case 1: { // Middle click
        appController.dispatchMessage(isPlaying ? new PauseMessage() : new PlayMessage());
        break;
      }
      case 2: { // Right click
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageDown));
        break;
      }
      default:
        break;
    }

    // console.log(`Mouse click event`, e);
  }, [appController, mouseControlsEnabled, cursor]);

  const wheelHandler = useCallback((e: WheelEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if(!mouseControlsEnabled && prompter.isPlaying) {
      return;
    }

    // Do something
    // deltaMode: number;
    // deltaX: number;
    // deltaY: number;
    // deltaZ: number;

    // wheel { target: div.MouseInputMask, buttons: 0, clientX: 700, clientY: 220, layerX: 700, layerY: 220 }

    // altKey
    // ctrlKey
    // deltaMode: 0
    // deltaX: 0
    // deltaY: 1
    // deltaZ: 0
    // shiftKey
    // wheelDelta: -3
    // wheelDeltaX: 0
    // wheelDeltaY: -3
    // which: 1
    if(prompter.isPlaying) {
      if(e.deltaY > 0) {
        //dispatch({ type: 'SPEED/INCREASE', amount: 1 });
        amountRef.current += Math.round(e.deltaY / 2);
      }
      if(e.deltaY < 0) {
        // dispatch({ type: 'SPEED/DECREASE', amount: 1 });
        amountRef.current += Math.round(e.deltaY / 2);
      }
    } else {
      // When paused (not editing, not playing), we want to accumulate scroll wheel deltaY
      // and will dispatch scroll events to the underlying PrompterSegmentsContainer.
      amountRef.current += e.deltaY;
    }
    dispatchUpdatesThrottled();

    // console.log(`Mouse wheel event`, e);
  }, [prompter, mouseControlsEnabled, dispatchUpdatesThrottled]);

  const mouseMoveHandler = useCallback((e: MouseEvent) => {
    // Calculate the line of sight distance the cursor moved.
    // https://stackoverflow.com/a/20916980
    const distanceMoved = Math.hypot(Math.abs(e.movementX), Math.abs(e.movementY));

    // Use our stats collector hook to calculate mouse movement stats over
    // the last second.
    const distanceOverLastSecond = collectMouseMove(distanceMoved);

    // If the user is moving the mouse around enough we think its intentional,
    // then show the mouse cursor and hidden UI elements again.
    // If the cursor is already visible, the threshhold of movement required
    // to keep it visible is lower than the threshhold required to initial
    // show the cursor.
    // usePrompterSession.getState().cursorHidden
    if(distanceOverLastSecond.sum > (cursor.cursorHidden ? MOUSE_TRAVEL_REQUIRED_TO_SHOW_CURSOR : MOUSE_TRAVEL_REQUIRED_TO_PREVENT_HIDE_CURSOR)) {
      showCursorThrottled();
    }
  }, [collectMouseMove, showCursorThrottled, cursor.cursorHidden]);


  const touchStartHandler = useCallback((e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    //
    showCursorThrottled();
  }, [showCursorThrottled]);

  // Bubble vs Capture Events: https://javascript.info/bubbling-and-capturing
  React.useEffect(() => {
    const maskElement = rootRef.current;
    if(!maskElement) {
      return;
    }

    maskElement.addEventListener('click', clickHandler, { capture: true, passive: false });
    maskElement.addEventListener('auxclick', clickHandler, { capture: true, passive: false });
    maskElement.addEventListener('contextmenu', clickHandler, { capture: true, passive: false });
    maskElement.addEventListener('wheel', wheelHandler, { capture: true, passive: false });

    maskElement.addEventListener('mousemove', mouseMoveHandler, { capture: true, passive: false });

    maskElement.addEventListener('touchstart', touchStartHandler, { capture: true, passive: false });
    /*
    maskElement.addEventListener('touchmove', mouseMoveHandler, { capture: true, passive: false });
    maskElement.addEventListener('touchend', mouseMoveHandler, { capture: true, passive: false });
    maskElement.addEventListener('touchcancel', mouseMoveHandler, { capture: true, passive: false });
    */

    return () => {
      // cleanup function
      maskElement.removeEventListener('click', clickHandler, { capture: true });
      maskElement.removeEventListener('auxclick', clickHandler, { capture: true });
      maskElement.removeEventListener('contextmenu', clickHandler, { capture: true });
      maskElement.removeEventListener('wheel', wheelHandler, { capture: true });

      maskElement.removeEventListener('mousemove', mouseMoveHandler, { capture: true });

      maskElement.removeEventListener('touchstart', touchStartHandler, { capture: true });
    };
  }, [rootRef, clickHandler, wheelHandler, mouseMoveHandler, touchStartHandler]);

  return (
    <>
      {!prompter.isEditing && <div
        {...getRootProps()}
        className={classNames('MouseInputMask', {
          DragActive: isDragActive,
        })}
        style={{
          cursor: cursor.cursorHidden ? 'none' : 'default',
          // overflowX: 'hidden',
          // overflowY: prompterSession.isEditing ? 'scroll' : 'hidden',
        }}
        tabIndex={-1} /* This is required to capture input events on a non-interactive input element. */
        onFocus={(e) => { e.target.blur(); }}
      ></div>}
    </>
  );
}

export default MouseInputMask;