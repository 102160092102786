import * as React from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import useFeatureFlagsStore from '../../state/FeatureFlagsStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import NavigateSpeedDial from './NavigateSpeedDial';
import ShotLogSpeedDial from './ShotLogSpeedDial';
import VoiceSpeedDial from './VoiceSpeedDial';

import { useAppController } from '../../controllers/AppController';
import { PauseMessage } from '@fluidprompter/core';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import './PrompterHudLeft.scss';

const PrompterHudLeft = React.memo(function PrompterHudLeft() {

  const appController = useAppController();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const prompterState = usePrompterSession(state => ({
    isPlaying: state.isPlaying,
    isEditing: state.isEditing,
    cursorHidden: state.cursorHidden,
    shotloggingEnabled: state.shotloggingEnabled,
    // framesPerSecond: state.framesPerSecond,
    // textMetrics: state.textMetrics,

    startShotLogEntry: state.startShotLogEntry,
    updateShotLogEntry: state.updateShotLogEntry,
    finalizeShotLogEntry: state.finalizeShotLogEntry,
  }), shallow);

  const [activeSpeedDial, setActiveSpeedDial] = React.useState<number | undefined>(undefined);
  const toggleSpeedDial = (item: number) => {
    const proposedValue = (activeSpeedDial === item) ? undefined : item;
    if(proposedValue) {
      // Pause whenever a SpeedDial is activated.
      appController.dispatchMessage(new PauseMessage());

      // Also restart the hide cursor timer.
      appController.dispatchMessage('prompter.local.HideCursorTimer.Restart');
    }
    setActiveSpeedDial(proposedValue);
  };

  //
  // If the cursor is becoming hidden and a speedial is currently open, close it!
  //
  React.useEffect(() => {
    if(prompterState.cursorHidden && activeSpeedDial) {
      setActiveSpeedDial(undefined);
    }
  }, [prompterState.cursorHidden, activeSpeedDial]);
  
  const featureFlags = useFeatureFlagsStore.getState();
  const ShouldShowVoiceSpeedDial = featureFlags.voiceDictation && prompterState.isEditing;

  return (
    <Stack
      className={classNames('PrompterHudLeft', { hideMode: (!prompterState.isEditing && prompterState.cursorHidden) })}
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      sx={{
        /*transform: (!prompterSession.isEditing && prompterSession.cursorHidden) ? 'translateY(-100%)': 'none',*/
        transition: 'transform 500ms ease-in-out',   /* This needs to be in sx styles */
      }}      
    >
      {/*isLargeScreen && <Box sx={{ flexGrow: 1 }} />*/}

      <NavigateSpeedDial isLargeScreen={isLargeScreen} isOpen={(activeSpeedDial === 1)} toggleOpen={() => { toggleSpeedDial(1); }} />
      {prompterState.shotloggingEnabled && <ShotLogSpeedDial isLargeScreen={isLargeScreen} isOpen={(activeSpeedDial === 2)} toggleOpen={() => { toggleSpeedDial(2); }} />}
      {ShouldShowVoiceSpeedDial && <VoiceSpeedDial isLargeScreen={isLargeScreen} toggleOpen={() => { toggleSpeedDial(3); }} />}

      <Box sx={{ flexGrow: 1 }} />
    </Stack>
  );
});

export default PrompterHudLeft;