import { useAppController, useMessageHandler } from '../controllers/AppController';

import DeviceHost from './DeviceHost';
import IkanEliteRemote from './ikan/IkanEliteRemote';

import BaseDevice, { GenericDeviceConstructor } from './BaseDevice';

const genericDeviceConstructors: { [name: string]: GenericDeviceConstructor } = {
  [IkanEliteRemote.DeviceKey]: IkanEliteRemote,
};

const useGenericDevices = (deviceHost: DeviceHost) => {

  const appController = useAppController();

  //
  // Handle a request to connect a new generic device.
  //
  useMessageHandler('devices.generic.connect', (e) => {
    const deviceType: string = e.message.payload as string;

    console.log('GenericEvent \'devices.generic.connect\' received!');

    const callbackFn = async (err?: Error) => {
      if(err) {
        // The current user is not logged in or does not have a sufficient account level for this
        // feature.
        return;
      }
      if(!deviceType) {
        throw new Error('deviceType required for \'devices.generic.connect\' command.');
      }

      //
      // Find our device implementation and instantiate an instance.
      //
      let deviceInstance: (BaseDevice | undefined) = undefined;
      try {
        const DeviceImplementation = genericDeviceConstructors[deviceType];
        if(!DeviceImplementation) {
          throw new Error('Unsupported device type.');
        }

        deviceInstance = new DeviceImplementation(deviceHost);

        await deviceInstance.connect();
      } catch(error) {
        // Error
        alert('Error trying to connect generic device.\rPlease try again or contact FluidPrompter support.');

        if(deviceInstance) {
          deviceInstance.disconnect();
          deviceHost.unregisterDevice(deviceInstance);
        }
      }
    };

    // accountrequired will call our callback function to let us know if we can continue.
    appController.dispatchMessage('prompter.local.accountrequired', {
      plan: 'free',
      featureName: 'Connect device',
      callback: callbackFn,
    });
  });

};

export default useGenericDevices;