import React, { useCallback } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { Descendant } from 'slate';
import { ElementTypes, PrompterElement } from '../../models/EditorTypes';
import { DropzoneInputProps, DropzoneRootProps, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { GenericMessage, NavigateMessage, ResetMessage, ScriptStateMessage } from '@fluidprompter/core';
import { MessageHandlerEvent, useAppController, useMessageHandler } from '../../controllers/AppController';
import usePrompterSession from '../../state/PrompterSessionState';

import ScriptSerializer from '../../utils/scripts/ScriptSerializer';
import { IScriptFileParser } from '../../utils/scripts/IScriptFileParser';
import { astNode } from '../../utils/scripts/parsing/ParsingContext';

import WarningIcon from '@mui/icons-material/Warning';

interface HandleFileArgs {
  acceptedFiles: File[];
  fileRejections: FileRejection[];
}

interface useScriptOpenFileHandlerExports {
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  rootRef: React.RefObject<HTMLElement>;
  isDragActive: boolean;
}

function useScriptOpenFileHandler(): useScriptOpenFileHandlerExports {
  const appController = useAppController();

  const confirm = useConfirm();

  const { t } = useTranslation('prompter');

  const doSaveScript = React.useCallback(async function () {

    // This is the non-react hook way to grab Zustand state.
    //
    // This way we don't need to redefine the onSaveScript callback everytime
    // the script changes (could happeb often with WYSIWYG on-screen editing).
    const prompterState = usePrompterSession.getState();
    const prompterSegments = prompterState.scriptNodes;

    const serializer = new ScriptSerializer();
    const textFileContents = serializer.serialize(prompterSegments as PrompterElement[]);

    const now = new Date();
    const nowISO = now.toISOString();

    // Remove all HTML tags via Regex.
    // var strippedHtml = myHTML.replace(/<[^>]+>/g, '');

    const blob = new Blob([textFileContents], { type: 'text/plain' });
    const objectURL = window.URL.createObjectURL(blob);

    const element = document.createElement('a');
    element.setAttribute('href', objectURL);
    element.setAttribute('download', `FluidPrompter Script - ${nowISO}.txt`);
    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    window.URL.revokeObjectURL(objectURL);

    // Mark the session state as clean.
    prompterState.unsetHasUnsavedChanges();
  }, []);
  useMessageHandler('savefile', doSaveScript);

  //
  // If we have any unsaved script changes, prompt the user to save changes before continuing.
  //
  const doPromptSaveChanges = React.useCallback(async function () {
    // If we have no unsaved changes, we are done here.
    if(!usePrompterSession.getState().hasUnsavedChanges) {
      return;
    }

    try {
      // If the user cancels, an exception will be thrown, otherwise we will continue to doSaveScript.
      await confirm({
        title: <><WarningIcon sx={{ mr: 1, verticalAlign: 'middle', color: '#eed202' }} />{t('script.promptsavechanges.title')}</>,
        description: t('script.promptsavechanges.message'),
        confirmationText: t('script.promptsavechanges.confirm'),
        confirmationButtonProps: {
          variant: 'contained',
          size: 'small',
          color: 'success',
        },
        cancellationText: t('script.promptsavechanges.cancel'),
        cancellationButtonProps: {
          variant: 'contained',
          size: 'small',
          color: 'error',
        }
      });

      await doSaveScript();
    } catch(err) {
      // We don't care if an exception was thrown, that just means the user cancelled.
    }
  }, [confirm, t, doSaveScript]);
  useMessageHandler('promptsaveshanges', doPromptSaveChanges);

  //
  //
  //
  const finishOpenFile = useCallback(async (scriptFileParser: IScriptFileParser, selectedFile: File) => {
    //
    // If we have unsaved changes to our current script, prompt the user to save them before we
    // open the new script file.
    //
    await doPromptSaveChanges();

    //
    // Kick off file parsing - this is where the real work happens using the correct file parser
    // loaded above depending on the file extension or content type.
    //
    let AST: astNode;
    try {
      AST = await scriptFileParser.parseFile(selectedFile);
    } catch(err) {
      // Could not parse the file...
      alert('Error: Could not parse the opened file.');
      console.error('Error: Could not parse the opened file.');
      console.error(err);
      return;
    }

    //
    // Now that we have an AST data structure, let's load the parsed script into FluidPrompter!
    //
    const msgBundle = [
      new GenericMessage('prompter.local.appmenu.close'),
      new ResetMessage(true),
      new ScriptStateMessage({
        scriptNodes: AST.children,
        lastChangedTimestamp: Date.now(),
        hasUnsavedChanges: false,
        applyOnSender: true,
      }),
    ];
    appController.dispatchMessages(msgBundle);

    //
    // Show use
    //
    toast(t('script.file_opened', { name: selectedFile.name }), {
      type: 'success',
      isLoading: false,
      autoClose: 3000,
      hideProgressBar: false,
    });
  }, [t]);

  //
  // Handle file input event whether triggered by drag-drop, or open file picker.
  //
  const startOpenFile = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if(fileRejections?.length) {
      toast(t('script.file_unsupported'), {
        type: 'error',
        isLoading: false,
        autoClose: 3000,
        hideProgressBar: false,
      });
      return;
    }

    /*
    lastModified: 1590939665938
    name: "TubeBuddy Down 2020-05-29.png"
    size: 43015
    type: "image/png"
    webkitRelativePath: ""
    */
    //
    // XML = type: "text/xml"
    // TXT = type: "text/plain"
    // MD = type: "text/markdown"
    // DOCX = type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    // PDF = type: "application/pdf"
    //
    const selectedFile = (acceptedFiles && acceptedFiles.length) ? acceptedFiles[0] : undefined;
    if(!selectedFile) {
      // No file selected...
      return;
    }
    // console.log('File open', selectedFile);

    //
    // Determine which script file parser implementation we want to use based on the file extension
    // and mime type.
    //
    let requiredPlanLevel = 'free';
    let upsellFeatureName = t('script.openfile_feature_name');
    let scriptFileParser: IScriptFileParser;
    const fileext = selectedFile.name.split('.').pop();       // ex: returns 'txt' | 'docx' | 'rtf'
    if(
      fileext === 'docx'
      || selectedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      //
      // Parser for DOCX Word Document files
      //
      // Use a "dynamic import" with code splitting so this module is not loaded by default in
      // FluidPrompter app until you use it.
      //
      const { DocxScriptFileParser } = await import('../../utils/scripts/docx/DocxScriptFileParser');
      scriptFileParser = new DocxScriptFileParser();

      //
      // DOCX requiers a paid plan.
      //
      requiredPlanLevel = 'pro';
      upsellFeatureName = t('script.docx_feature_name');
    } else if(
      fileext === 'pdf'
        || selectedFile.type === 'application/pdf'
    ) {
      //
      // Parser for PDF files
      //
      // Use a "dynamic import" with code splitting so this module is not loaded by default in
      // FluidPrompter app until you use it.
      //
      const { PdfScriptFileParser } = await import('../../utils/scripts/pdf/PdfScriptFileParser');
      scriptFileParser = new PdfScriptFileParser();

      //
      // PDF requiers a paid plan.
      //
      requiredPlanLevel = 'pro';
      upsellFeatureName = t('script.pdf_feature_name');
    } else if(
      fileext === 'txt'
        || selectedFile.type === 'text/plain'
        || fileext === 'md'
        || selectedFile.type === 'text/markdown'
    ) {
      //
      // Parser for TXT files
      //
      // Use a "dynamic import" with code splitting so this module is not loaded by default in
      // FluidPrompter app until you use it.
      //
      const { TxtScriptFileParser } = await import('../../utils/scripts/txt/TxtScriptFileParser');
      scriptFileParser = new TxtScriptFileParser();
    } else {
      //
      // Unknown or unsupported file format
      //
      alert('Error: Unspported file type. Files with extensions other than .txt are currently not supported.');
      console.log(`selectedFile.name = ${selectedFile.name}, selectedFile.type = ${selectedFile.type}`);
      return;
    }

    //
    // Safety check - we might be able to make this larger, but an extremely large file may just
    // lock up the browser. 2mb is a lot of text and we don't currently support images.
    //
    if(selectedFile.size > 2000000) {
      alert('Error: Files larger than 2mb are currently not supported.');
      return;
    }

    appController.dispatchMessage('prompter.local.accountrequired', {
      plan: requiredPlanLevel,
      featureName: upsellFeatureName,
      callback: () => {
        finishOpenFile(scriptFileParser, selectedFile);
      },
    });
  }, [t, finishOpenFile]);

  //
  //
  //
  const doHandleFile = React.useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    const args: HandleFileArgs = e.message.payload as HandleFileArgs;
    if(!args) {
      return;
    }

    const {
      acceptedFiles,
      fileRejections,
    } = args;

    startOpenFile(acceptedFiles, fileRejections);
  }, [startOpenFile]);
  useMessageHandler('handlefile', doHandleFile);

  //
  // Setup Drag-drop file handler. This hook also owns our refs to the prompter viewport and the
  // file input element - which we will pass along.
  //
  const { getRootProps, getInputProps, isDragActive, rootRef, inputRef, open } = useDropzone({
    //
    // The list of supported file types
    //
    accept: {
      'text/plain': ['.txt'],
      'text/markdown': ['.md'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/pdf': ['.pdf'],
    },

    //
    // We can only open 1 file at a time. Do not allow drag-drop of multiple files.
    //
    maxFiles: 1,

    //
    // Do not show the open file picker when you click the drag-drop zone.
    //
    noClick: true,

    //
    // Do not show the open file picker via any key pressed in the drag-drop zone.
    //
    noKeyboard: true,

    //
    // Attach handler when a file is drag-dropped or otherwise opened.
    //
    onDrop: startOpenFile
  });

  const doNewScript = React.useCallback(async function () {
    const newScript: Descendant[] = [{
      type: ElementTypes.STARTSCRIPT,
      children: [{ text: '' }]
    }, {
      type: ElementTypes.SCRIPT_SEGMENT,
      children: [{
        type: ElementTypes.PARAGRAPH,
        children: [{
          text: 'Type Your Script Here...'
        }]
      }]
    }, {
      type: ElementTypes.ENDSCRIPT,
      children: [{ text: '' }]
    }];

    // bus.emit('promptsaveshanges');
    await doPromptSaveChanges();

    const msgBundle = [
      new GenericMessage('prompter.local.appmenu.close'),
      new ResetMessage(true),
      new ScriptStateMessage({
        scriptNodes: newScript,
        lastChangedTimestamp: Date.now(),
        hasUnsavedChanges: false,
        applyOnSender: true,
      }),
      new NavigateMessage(NavigateMessage.Target.Position, {
        contentNumber: 1,
        targetPosition: 'top',
        queueSequentialTask: true
      }),
      new GenericMessage('prompter.editor.selectall'),
    ];
    appController.dispatchMessages(msgBundle);

  }, [doPromptSaveChanges, appController]);
  useMessageHandler('newscript', doNewScript);

  const doOpenFile = React.useCallback(async function () {
    const inputEl = inputRef.current;
    if(!inputEl) {
      return;
    }

    //
    // You need to be logged in to open a script file - even a free account login.
    //
    appController.dispatchMessage('prompter.local.accountrequired', {
      plan: 'free',
      featureName: t('script.openfile_feature_name'),
      callback: async () => {
        inputEl.click();
      },
    });
  }, [doPromptSaveChanges, inputRef]);
  useMessageHandler('openfile', doOpenFile);

  return {
    getRootProps,
    getInputProps,
    rootRef,
    isDragActive,
  };
}

export default useScriptOpenFileHandler;