import DeviceHost from '../../DeviceHost';
import {
  IDeviceDescriptor,
  DeviceComponent,
  DeviceConnectionType,
} from '../../BaseDevice';
import BaseHidDevice from '../../BaseHidDevice';
import { DeviceConnectedEvent } from '../../events/DeviceConnectedEvent';
import { DeviceDisconnectedEvent } from '../../events/DeviceDisconnectedEvent';
import { DeviceReportEvent } from '../../events/DeviceReportEvent';
import { DeviceButtonEvent } from '../../events/DeviceButtonEvent';
import { DeviceWheelEvent } from '../../events/DeviceWheelEvent';

import challengeResponse from './challengeResponse';
import buttonCodes from './buttonCodes';
// import buttonNames from './buttonNames';

import { TFunction } from 'i18next';
import BlackmagicSpeededitorIcon from './images/blackmagic-speededitor-icon.png';
import SpeedEditorUI from './UI';

enum MainLeds {
  None = 0,
  LED1 = 1 << 0,    // 00000000 00000001 = CLOSE UP
  //
  LED2 = 1 << 1,    // 00000000 00000010 = CUT
  LED3 = 1 << 2,    // 00000000 00000100 = DIS
  LED4 = 1 << 3,    // 00000000 00001000 = SMTH CUT
  //
  LED5 = 1 << 4,    // 00000000 00010000 = TRANS
  LED6 = 1 << 5,    // 00000000 00100000 = SNAP
  //
  LED7 = 1 << 6,    // 00000000 01000000 = CAM7
  LED8 = 1 << 7,    // 00000000 10000000 = CAM8
  LED9 = 1 << 8,    // 00000001 00000000 = CAM9
  LED10 = 1 << 9,   // 00000010 00000000 = LIVE OWR
  //
  LED11 = 1 << 10,    // 00000100 00000000 = CAM4
  LED12 = 1 << 11,    // 00001000 00000000 = CAM5
  LED13 = 1 << 12,    // 00010000 00000000 = CAM6
  LED14 = 1 << 13,    // 00100000 00000000 = VIDEO ONLY
  //
  LED15 = 1 << 14,    // 00000000 01000000 00000000 = CAM1
  LED16 = 1 << 15,    // 00000000 10000000 00000000 = CAM2
  LED17 = 1 << 16,    // 00000001 00000000 00000000 = CAM3
  LED18 = 1 << 17,    // 00000010 00000000 00000000 = AUDIO ONLY
  //
  All = ~(~0 << 18)   // 00000011 11111111 11111111 = ALL LEDS
}

enum JogWheelLeds {
  None = 0,
  JOG = 1 << 0,     // 0001 = JOG
  SHTL = 1 << 1,    // 0010 = SHTL
  SCRL = 1 << 2,    // 0100 = SCRL
  All = ~(~0 << 3)  // 0111
}

enum JogWheelMode {
  RELATIVE = 0,
  JOG = 0x01,     // Send an "absolute" position (based on the position when mode was set) -4096 -> 4096 range ~ half a turn
  SHTL = 0x02,    // Same as mode 0 ?
  SCRL = 0x03,    // Same as mode 1 but with a small dead band around zero that maps to 0
}

const SPEEDEDITOR_TYPE = 'speededitor';

/**
 * Implements connectivity with a BlackMagic Speed Editor control surface.
 */
class SpeedEditor extends BaseHidDevice {
  readonly type = SPEEDEDITOR_TYPE;

  public static readonly DEVICE_TYPE: string = SPEEDEDITOR_TYPE;

  static HID_VID = 0x1edb; // 7899
  static HID_PID = 0xda0e; // 55822

  private _buttonsDown: Set<number>;
  private _reauthenticateTimeoutId = 0;

  constructor(deviceHost: DeviceHost) {
    super(deviceHost);
    this.icon = BlackmagicSpeededitorIcon;
    this.name = 'Speed Editor';
    this.connectionType = DeviceConnectionType.Hid;

    this._buttonsDown = new Set<number>();
  }

  getRequestDeviceOptions(): HIDDeviceRequestOptions {
    const deviceFilters: HIDDeviceRequestOptions = {
      filters: [{
        vendorId: SpeedEditor.HID_VID,
        productId: SpeedEditor.HID_PID,
      }]
    };
    return deviceFilters;
  }

  private async handleInputReport(ev: HIDInputReportEvent) {
    const { data, reportId } = ev;

    switch(reportId) {
      case 3: // JogWheel Moved - 6 bytes, first byte always zero, 2 bytes for position, 2 bytes for forward/reverse, 1 byte always 6.
        return this.handleJogwheelReport(data);
      case 4: // Buttons Pressed/Released (max 6 buttons down at one time) - 12 bytes, 6x 16bit button ids for currently pressed buttons.
        return this.handleButtonReport(data);
      case 7: // Battery Status Report
        return this.handleBatteryReport(data);
    }
  }

  private _raf = 0;
  private _deltaY = 0;
  private async handleJogwheelReport(data: DataView) {
    //
    // JOG WHEEL:
    //
    // Report ID: 03
    // u8   - Report ID
    // u8   - Jog mode
    // le32 - Jog value (signed)
    // u8   - Unknown ?
    //
    // const mode = data.getUint8(0);
    const position = data.getInt32(1, true);
    // console.log(`Wheel moved, mode ${mode}, position ${position}`);

    //
    // Accumulate wheel movements and throttle our wheel events based on prompter framerate.
    //
    this._deltaY += position;
    cancelAnimationFrame(this._raf);
    this._raf = requestAnimationFrame(this.emitWheelEvent.bind(this));
  }
  private emitWheelEvent() {
    // TODO: We could support a configurable "wheel sensitivity" configuration, and/or change
    // sensitivity based on the jog wheel mode.
    let currentDeltaY = Math.round(this._deltaY / 3600);
    this._deltaY = 0;

    // Maximum movement in 1 frame is 500px/s / 60 fps = 8px per frame max.
    if(currentDeltaY > 8) {
      currentDeltaY = 8;
    }

    // Small jitters on the jog wheel may accumulate some very small +/- events that aggregate to 0.
    // Don't fire useless events for 0 practical movement of the jogwheel.
    if(currentDeltaY !== 0) {
      this.emit('wheelevent', new DeviceWheelEvent(this, 0, 0, currentDeltaY, 0));
    }
  }

  private async handleButtonReport(data: DataView) {
    //
    // BUTTON PRESS:
    //
    // Key Presses are reported in Input Report ID 4 as an array of 6 LE16 keycodes
    // that are currently being held down. 0x0000 is no key. No auto-repeat, no hw
    // detection of the 'fast double press'. Every time the set of key being held
    // down changes, a new report is sent.
    //
    // Report ID: 04
    // u8      - Report ID
    // le16[6] - Array of keys held down
    //
    const oldButtonsDown = new Set<number>(this._buttonsDown);
    const newButtonsDown = new Set<number>();
    for(let i = 0; i < 12; i += 2) {
      const buttonId = data.getUint8(i);
      if(buttonId) {
        // Returns true if an element in the Set existed
        if(!oldButtonsDown.delete(buttonId)) {
          // If this buttonId wasn't in the set of pressed buttons already, then it is newly pressed.
          newButtonsDown.add(buttonId);
        }
      }
    }

    oldButtonsDown.forEach((value) => {
      let buttonName = 'unknown';
      if(Object.hasOwn(buttonCodes, value)) {
        buttonName = buttonCodes[value];
      }

      console.log(`Released ${buttonName} (${value})`);
      this._buttonsDown.delete(value);
    });
    newButtonsDown.forEach((value) => {
      let buttonName = 'unknown';
      if(Object.hasOwn(buttonCodes, value)) {
        buttonName = buttonCodes[value];
      }

      console.log(`Pressed ${buttonName} (${value})`);
      this.emit('buttonreport', new DeviceButtonEvent(this, buttonName, 'down'));
      // 7 = IN = PAGE UP
      // 8 = OUT = PAGE DOWN
      //
      // 10 = TRIM OUT = UP ARROW
      //
      // 12 = SLIP SRC = LEFT ARROW
      // 13 = SLIP DEST = DOWN ARROW
      // 14 = TRANS DUR = RIGHT ARROW

      this._buttonsDown.add(value);
    });
  }

  private async getBatteryReport() {
    // const batteryReport = await this.device.receiveFeatureReport(1);
    // {
    //   "0": 1,
    //   "1": 238,
    //   "2": 12,
    //   "3": 82,
    //   "4": 126,
    //   "5": 1,
    //   "6": 4,
    //   "7": 1
    // }

    // 8746288
    // const batteryReport2 = await this.device.receiveFeatureReport(8);
    // {
    //   "0": 8,
    //   "1": 51,
    //   "2": 68,
    //   "3": 52,
    //   "4": 49,
    //   "5": 50,
    //   "6": 49,
    //   "7": 53,
    //   "8": 53,
    //   "9": 49,
    //   "10": 66,
    //   "11": 56,
    //   "12": 52,
    //   "13": 52,
    //   "14": 68,
    //   "15": 54,
    //   "16": 53,
    //   "17": 65,
    //   "18": 48,
    //   "19": 70,
    //   "20": 51,
    //   "21": 67,
    //   "22": 55,
    //   "23": 49,
    //   "24": 52,
    //   "25": 57,
    //   "26": 66,
    //   "27": 65,
    //   "28": 70,
    //   "29": 53,
    //   "30": 69,
    //   "31": 48,
    //   "32": 56
    // }

    // const batteryReport = await this.device.receiveFeatureReport(8);
    // console.log(`Battery Report`, batteryReport);
  }

  private async handleBatteryReport(data: DataView) {
    //
    // BATTERY STATUS:
    //
    // Report ID: 07
    // u8 - Report ID
    // u8 - Charging (1) / Not-charging (0)
    // u8 - Battery level (0-100)
    //
    this.batteryCharging = (data.getUint8(0) > 0);
    console.log(this.batteryCharging ? 'Charging!' : 'Not Charging.');

    this.batteryPercentage = data.getUint8(1);
    console.log(`${this.batteryPercentage}% Charged`);

    this.emit('devicereport', new DeviceReportEvent(this));
  }

  private async authenticate() {
    if(!this.hidDevice) {
      throw new Error('Error: hidDevice required to authenticate SpeedEditor.');
    }
    if(!this.hidDevice.opened) {
      throw new Error('Error: SpeedEditor hidDevice not opened.');
    }

    //
    // 1. Step 1 - Reset state machine
    //
    const msg1 = new Uint8Array([0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00]);
    await this.hidDevice.sendFeatureReport(6, msg1);

    //
    // 2. Receive Reponse 1 from SpeedEditor
    // This response will contain the authentication challenge material
    //
    let speedEditorResponse1: DataView;
    try {
      speedEditorResponse1 = await this.hidDevice.receiveFeatureReport(6);
      const byte1 = speedEditorResponse1.getUint8(0);
      const byte2 = speedEditorResponse1.getUint8(1);
      if(byte1 !== 6 || byte2 !== 0) {
        throw new Error(`Failed authentication get_kbd_challenge (byte1:${byte1}, byte2:${byte2})`);
      }
    }
    catch (err) {
      //
      // Why do I get this?
      // DOMException: Failed to receive the feature report.
      //
      setTimeout(() => {
        console.log('Closing HID device connection.');
        if(!this.hidDevice) {
          return;
        }

        this.hidDevice.close();
      }, 1000);
      console.log('Error receiving HID feature report 1', err);

      throw err;
    }
    const challenge = speedEditorResponse1.getBigUint64(2, true);

    //
    // 3. Send to SpeedEditor
    // This theoretically provides authentication challenge information to the keyboard
    // to prove the keyboard is legitimate. We don't care if this is a real BMD keyboard
    // or not. So we will ignore this
    //
    const msg2 = new Uint8Array([0x01, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00]);
    await this.hidDevice.sendFeatureReport(6, msg2);

    //
    // 4. Receive Response 2 from SpeedEditor
    // This will be the SpeedEditor's response to our challenge. We aren't even going to valid
    // this, as long as we received a challenge-response we know this handshake is on track.
    //
    const speedEditorResponse2 = await this.hidDevice.receiveFeatureReport(6);
    const byte3 = speedEditorResponse2.getUint8(0);
    const byte4 = speedEditorResponse2.getUint8(1);
    if(byte3 !== 6 || byte4 !== 2) {
      setTimeout(() => {
        console.log('Closing HID device connection.');
        if(!this.hidDevice) {
          return;
        }

        this.hidDevice.close();
      }, 1000);
      throw new Error(`Failed authentication get_kbd_response (byte3:${byte3}, byte4:${byte4})`);
    }

    //
    // 5. Send to SpeedEditor
    // This is where we prove to the SpeedEditor that we are a legitimate program by computing
    // our response to the SpeedEditor's challenge material.
    //
    const response = challengeResponse(challenge);
    const msg3 = new Uint8Array([0x03, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00]);
    const msg3view = new DataView(msg3.buffer);
    msg3view.setBigUint64(1, response, true);
    await this.hidDevice.sendFeatureReport(6, msg3view);

    //
    // 6. Receive Response 3 from SpeedEditor
    // This should be our final authentication response from the SpeedEditor confirming the device
    // is initialized and ready for use.
    //
    // I "think" what gets returned here is the timeout after which auth needs to be done again
    // (returns 600 for me which is plausible)
    // return int.from_bytes(data[2:4], 'little')
    //
    const speedEditorResponse3 = await this.hidDevice.receiveFeatureReport(6);
    const byte5 = speedEditorResponse3.getUint8(0);
    const byte6 = speedEditorResponse3.getUint8(1);
    if(byte5 !== 6 || byte6 !== 4) {
      setTimeout(() => {
        console.log('Closing HID device connection.');
        if(!this.hidDevice) {
          return;
        }

        this.hidDevice.close();
      }, 1000);
      throw new Error(`Failed authentication get_kbd_status (byte5:${byte5}, byte6:${byte6})`);
    }
    const reauthenticateSeconds = speedEditorResponse3.getInt32(2, true);
    console.log(`Final response timeoutValue=${reauthenticateSeconds}`);

    //
    // The device would like us to re-authenticate periodically.
    //
    this._reauthenticateTimeoutId = window.setTimeout(async () => {
      await this.authenticate();
    }, (reauthenticateSeconds - 1) * 1000);

    //
    // Reset our LEDs state based on either initial state of none or the last written state when
    // re-authenticating/re-connecting.
    //
    await this.setMainLEDs(this._lastWrittenMainLeds);
    await this.setJogWheelLEDs(this._lastWrittenJogWheelLeds);
  }

  async connect() {
    try {
      // TODO find the device...
      const hidDevices = await navigator.hid.requestDevice({
        filters: [{
          vendorId: SpeedEditor.HID_VID,
          productId: SpeedEditor.HID_PID,
          // usagePage: 0x0c,
          // usage: 0x01,
        }]
      });

      if(!hidDevices.length) {
        throw new Error('HidDevice required.');
      }

      this.hidDevice = hidDevices[0];

      if(!this.hidDevice.opened) {
        await this.hidDevice.open();
      }

      //
      // When Davinci Resolve starts-up it requests these feature reports.
      //
      // const mysteryReport1 = await this.hidDevice.receiveFeatureReport(1);
      // Returns 01 ee 0c 52 7e 01 04 01
      // const mysteryReport2 = await this.hidDevice.receiveFeatureReport(8);
      // Returns

      await this.setMainLEDs(MainLeds.None);          // ReportId 2
      await this.setJogWheelLEDs(JogWheelLeds.None);  // ReportId 4
      await this.setJogMode(JogWheelMode.RELATIVE);   // JogWheelMode.SHTL

      await this.authenticate();

      this.emit('connected', new DeviceConnectedEvent(this));

      // This doesn't work yet... how can we pro-actively fetch the battery status?
      // await this.getBatteryReport();

      if(this.hidDevice.vendorId !== SpeedEditor.HID_VID
        || this.hidDevice.productId !== SpeedEditor.HID_PID
      ) {
        throw new Error('HID Device has incorrect VID or PID.');
      }

      this.hidDevice.addEventListener('inputreport', this.handleInputReport.bind(this));
    } catch(err) {
      alert('Error connecting Speed Editor. Sometimes it just goes to sleep. Press a few buttons and try again.');
    }
  }

  async disconnect() {
    if(this._reauthenticateTimeoutId) {
      window.clearInterval(this._reauthenticateTimeoutId);
    }

    if(this.hidDevice) {
      await this.setMainLEDs(MainLeds.None);
      await this.setJogWheelLEDs(JogWheelLeds.None);

      await this.hidDevice.close();
    }

    this.emit('disconnected', new DeviceDisconnectedEvent(this, true));
  }

  private _lastWrittenMainLeds: MainLeds = MainLeds.None;
  private async setMainLEDs(ledState: MainLeds) {
    if(!this.hidDevice) {
      throw new Error('HidDevice required.');
    }

    //
    // Set main button LEDs (everything except the Jog Wheel buttons):
    // ReportId: 2, followed by 4 bytes for LED status data
    //
    // Davinci sets report type 2 with byte 20 00 00 00 on startup
    //
    const mainLedData = new Uint8Array([0, 0, 0, 0]);
    const mainLedDataview = new DataView(mainLedData.buffer);

    mainLedDataview.setUint32(0, ledState, true);

    const sendPromise = this.hidDevice.sendReport(2, mainLedDataview);
    this._lastWrittenMainLeds = ledState;
    return sendPromise;
  }

  private _lastWrittenJogWheelLeds: JogWheelLeds = JogWheelLeds.None;
  private async setJogWheelLEDs(ledState: JogWheelLeds) {
    if(!this.hidDevice) {
      throw new Error('HidDevice required.');
    }

    //
    // Set the three jog wheel LEDs:
    // ReportId: 4, followed by 1 byte for LED status data
    //
    const sendPromise = this.hidDevice.sendReport(4, new Uint8Array([ledState]));
    this._lastWrittenJogWheelLeds = ledState;
    return sendPromise;
  }

  private async setJogMode(jogMode: JogWheelMode) {
    if(!this.hidDevice) {
      throw new Error('HidDevice required.');
    }

    //
    // Set Jog Mode
    // ReportId: 3
    // (little-endian) unsigned char, unsigned char, unsigned int (4 bytes), unsigned char
    // 3, jogmode, 0, 255
    // [3, jogMode, 0, 0, 0, 0, 255];
    //
    // Davinci sets report type 3 with bytes 02 00 00 00 00 ff on startup
    //
    const jogModeData = new Uint8Array([0, 0, 0, 0, 0, 255]);
    const jogModeDataview = new DataView(jogModeData.buffer);
    jogModeDataview.setUint8(0, jogMode);

    return this.hidDevice.sendReport(3, jogModeDataview);
  }

  static readonly DeviceKey: string = 'speededitor';
  static getDeviceDescriptors(t: TFunction): IDeviceDescriptor[] {
    return [{
      connectionType: DeviceConnectionType.Hid,
      deviceKey: SpeedEditor.DeviceKey,
      deviceName: 'Blackmagic SpeedEditor',
      deviceIcon: BlackmagicSpeededitorIcon,
      requiresPlanLevel: 1,
      requiresHid: true,
    }];
  }

  getDeviceUIComponent(): DeviceComponent {
    return SpeedEditorUI;
  }
}

export default SpeedEditor;