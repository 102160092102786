import BaseControlMessage from './BaseControlMessage';
export const PEERSTATEREQUESTMESSAGE_KEY = 'peer.state.request';
/**
 * Represents the PeerStateRequest type.
 */
export class PeerStateRequest extends BaseControlMessage {
    constructor() {
        super();
        this.type = PEERSTATEREQUESTMESSAGE_KEY;
    }
    static fromJson(data) {
        if (data.type !== PEERSTATEREQUESTMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for PeerStateRequest.');
        }
        // const input = data as PeerStateRequestJson;
        const instance = new PeerStateRequest();
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
        };
    }
}
PeerStateRequest.MESSAGE_NAME = PEERSTATEREQUESTMESSAGE_KEY;
