import React, { useState, useEffect } from 'react';
import { ShepherdTour, ShepherdOptionsWithType } from 'react-shepherd';

import { useAppController } from '../../controllers/AppController';
import { useTranslation } from 'react-i18next';

import { TourFactoryMethod, TourStepFactoryParams } from './TourStepTypes';
import WelcomeTourStepFactory from './WelcomeTourStep';
import AppMenuTourStepFactory from './AppMenuTourStep';
import HelpTourStepFactory from './HelpTourStep';
import SpeedControlTourStepFactory from './SpeedControlTourStep';
import CenterControlsTourStepFactory from './CenterControlsTourStep';
import TourController from './TourController';

import './TourStyles.scss';

const welcomeTourFactory: TourFactoryMethod = (tourStepFactoryParams: TourStepFactoryParams) => {
  const newTourSteps: ShepherdOptionsWithType[] = [
    WelcomeTourStepFactory(tourStepFactoryParams), 
    HelpTourStepFactory(tourStepFactoryParams), 
    SpeedControlTourStepFactory(tourStepFactoryParams), 
    CenterControlsTourStepFactory(tourStepFactoryParams)
  ];

  return newTourSteps;
};

interface TourContainerProps {
  children?: React.ReactNode;
}

const TourContainer = (props: TourContainerProps) => {
  const { children } = props;

  const { t } = useTranslation('tours');

  const appController = useAppController();

  const tourOptions = {
    defaultStepOptions: {
      title: t('welcometour.title'),
      classes: 'HelpKeyboardShortcuts',
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          type: 'back',
          text: t('back')
        },
        {
          type: 'next',
          text: t('next')
        }
      ],
      // when: {
      //   show() {
      //     const currentStep = Shepherd.activeTour?.getCurrentStep();
      //     const currentStepElement = currentStep?.getElement();
      //     const footer = currentStepElement?.querySelector('.shepherd-footer');
      //     const progress = document.createElement('span');
      //     progress.className = 'shepherd-progress';
      //     progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1} of ${Shepherd.activeTour?.steps.length}`;
      //     footer?.insertBefore(progress, currentStepElement.querySelector('.shepherd-button:last-child'));
      //   }
      // }
    },
    useModalOverlay: true
  };

  const [tourFactoryMethod, setTourFactoryMethod] = useState<TourFactoryMethod>(() => welcomeTourFactory);
  const [tourSteps, setTourSteps] = useState<ShepherdOptionsWithType[]>([]);

  //
  // Rebuild the Toursteps when either the current tour changes or the current language changes.
  //
  useEffect(() => {
    const tourStepFactoryParams: TourStepFactoryParams = {
      t,
      appController,
    };

    const newTourSteps = tourFactoryMethod(tourStepFactoryParams);
    // const newTourSteps: ShepherdOptionsWithType[] = [
    //   WelcomeTourStepFactory(tourStepFactoryParams), 
    //   HelpTourStepFactory(tourStepFactoryParams), 
    //   SpeedControlTourStepFactory(tourStepFactoryParams), 
    //   CenterControlsTourStepFactory(tourStepFactoryParams)
    // ];

    setTourSteps(newTourSteps);
  }, [t, appController, tourFactoryMethod]);

  // const requestTour = useCallback(function () {
  //   if(!tour) {
  //     return;
  //   }

  //   tour.start();
  // }, [tour]);
  // useListener('prompter.local.tour', requestTour);

  return (<ShepherdTour steps={tourSteps} tourOptions={tourOptions}>
    {children}
    <TourController />
  </ShepherdTour>);
};

export default TourContainer;