import React, { useState } from 'react';

import { DeviceComponent, DeviceComponentProps } from '../../BaseDevice';
import AirturnRemoteMenu from './AirturRemoteMenu';
// import AirturnDigitIIIDialog from './AirturnDigitIIIDialog';

const AirturnRemoteUI = (props: DeviceComponentProps) => {
  const { onClose } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (<>
    <AirturnRemoteMenu {...props} setDialogOpen={setDialogOpen} />
    {/* {dialogOpen && <AirturnDigitIIIDialog onClose={() => {
      // Close dialog - should we close menu too?
      setDialogOpen(false);
      onClose();
    }} />} */}
  </>);
};

export default AirturnRemoteUI;