import React, { useCallback, useEffect } from 'react';

import { Editor, Descendant, Range, BaseSelection, NodeEntry, Node } from 'slate';
import { Slate, Editable, ReactEditor } from 'slate-react';

import { PrompterViewportProps } from '..';
import { PrompterEditor } from '../../../models/EditorTypes';
import SlateFocusMonitor from '../SlateFocusMonitor';
import { FormatCommands } from '../useEditorFormatCommandHandlers';
import { VoiceTypingCommands } from '../useVoiceTypingCommandHandlers';

import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import HoveringToolbar from '../HoveringToolbar';
import SegmentSubscribe from '../SegmentSubscribe';
import HorizontalLine from '../HorizontalLine';

import renderElement from './renderElement';
import { renderLeaf } from './renderLeaf';
import classNames from 'classnames';

export interface PrompterEditorRendererProps
  extends PrompterViewportProps
{
  prompterContentRef: React.RefObject<HTMLDivElement>;

  lineHeight: number;
  selection: BaseSelection,
  readOnly: boolean;

  editor: PrompterEditor;
  editableKey: number;
  formatCommands: FormatCommands;
  voiceTypingCommands: VoiceTypingCommands;
  // document: Descendant[];

  onChange: (doc: Descendant[]) => void;
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
  onPaste: React.ClipboardEventHandler<HTMLDivElement>;
}

const PrompterEditorRenderer = React.memo(function PrompterEditorRenderer(props: PrompterEditorRendererProps) {

  const {
    prompterContentRef,

    lineHeight,
    selection,
    readOnly,

    textSize,
    textColor,
    contentWidth,
    leftGutter,
    rightGutter,
    flipHorizontal,
    flipVertical,

    editableKey,
    editor,
    onChange,
    onKeyDown: onKeyDownHandler,
  } = props;

  // console.log('PrompterContentRender() method');
  const scriptNodes = usePrompterSession(state => state.scriptNodes);

  const prompterSession = usePrompterSession(state => ({
    shotlogEntries: state.shotlogEntries,
    scriptNodesMeta: state.scriptNodesMeta,
    // scriptNodesState: state.scriptNodesState,
    getScrollPositionMax: state.getScrollPositionMax,
  }), shallow);
  const { shotlogEntries, getScrollPositionMax } = prompterSession;

  // const currentScrollPosition = scriptNodesState?.currentScrollPosition || 0;
  const scrollPositionMax = getScrollPositionMax();
  const lastShotlogEntry = shotlogEntries.length ? shotlogEntries[shotlogEntries.length - 1] : undefined;
  // console.log(`scrollPositionMax=${scrollPositionMax}`, lastShotlogEntry);

  //
  // Figure out our list of CSS Transforms that apply any configured flipping
  // horizontally or vertically as well as scroll position.
  //
  const transformDirectives: string[] = [];
  /* transformDirectives.push(`translateY(-${scrollPosition}px)`); */
  if(flipHorizontal) { transformDirectives.push('rotateY(180deg)'); }
  if(flipVertical) { transformDirectives.push('rotateX(180deg)'); }
  /*
   * This causes the component to not re-render when disabling both flipHorizontal and flipVertical.
   * The only purpose of this was to try and force GPU compositing, there is no functional purpose.
   * Maybe in future we can consolidate the rotateX() rotateY() and transale3d() into one transform?
   *
  if(!flipHorizontal && !flipVertical) {
    //
    // If we have no desire to flip the prompter content in either direction, we still want a CSS
    // transform in order to hint to the browser that we want GPU acceleration used on this element
    // for animation.
    //
    transformDirectives.push('transale3d(0,0,0)');
  }
  */
  const contentTransform = transformDirectives.join(' ');

  const decorateHandler = useCallback((entry: NodeEntry<Node>) => {
    const [node, path] = entry;
    if (
      editor.selection !== null &&
      !Editor.isEditor(node) &&
      Editor.string(editor, path) === '' &&
      Range.includes(editor.selection, path) &&
      Range.isCollapsed(editor.selection)
    ) {
      return [
        {
          ...editor.selection,
          placeholder: true,
        },
      ];
    }
    return [];
  }, [editor]);

  //
  // If we transition to readonly, make sure the editor is not focused
  //
  useEffect(() => {
    if(readOnly) {
      ReactEditor.blur(editor);
    }
  }, [readOnly]);

  return (
    <>
      <Slate editor={editor} value={scriptNodes} onChange={onChange}>
        <div
          className={classNames('PrompterContent', {
            ReadOnly: readOnly,
            FlipHorizontal: flipHorizontal,
            FlipVertical: flipVertical,
          })}
          ref={prompterContentRef}
          style={{
            color: textColor,
            // transform: contentTransform,
            width: `${contentWidth}%`,
            marginLeft: `${leftGutter}%`,
            fontSize: `${textSize}pt`,
            lineHeight: `${lineHeight}`,
          }}
          //onContextMenu={handleContextMenu}
        >
          <Editable
            key={editableKey}   // change the key to restart editor w/ new editorValue
            className='ContentEditor'
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            decorate={decorateHandler}
            // onKeyDown={onKeyDown}
            onKeyDownCapture={onKeyDownHandler}
            onPaste={props.onPaste}
            readOnly={readOnly}
            spellCheck={false}
          />
          {lastShotlogEntry && <HorizontalLine caption="Last ShotLog Entry" position={Math.floor((lastShotlogEntry.endScriptPosition || lastShotlogEntry.startScriptPosition) * scrollPositionMax)} />}
          <SegmentSubscribe />
        </div>
        <SlateFocusMonitor />
      </Slate>
      <HoveringToolbar editor={editor} selection={selection} editorRect={prompterContentRef.current?.getBoundingClientRect()} formatCommands={props.formatCommands} voiceTypingCommands={props.voiceTypingCommands} />
    </>
  );
});

export default PrompterEditorRenderer;