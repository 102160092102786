import BaseDevice from '../BaseDevice';
import { BaseDeviceEvent } from './BaseDeviceEvent';

export class DeviceKeyboardEvent extends BaseDeviceEvent<KeyboardEvent> {
  readonly type = 'DeviceKeyboardEvent';

  constructor(device: BaseDevice | undefined, e: KeyboardEvent) {
    super(device, e);
  }

  // readonly altKey: boolean;
  get altKey(): boolean {
    return this.nativeEvent.altKey;
  }

  // /** @deprecated */
  get charCode(): number {
    return this.nativeEvent.charCode;
  }

  // readonly code: string;
  get code(): string {
    return this.nativeEvent.code;
  }

  // readonly ctrlKey: boolean;
  get ctrlKey(): boolean {
    return this.nativeEvent.ctrlKey;
  }

  // readonly isComposing: boolean;
  get isComposing(): boolean {
    return this.nativeEvent.isComposing;
  }

  // readonly key: string;
  get key(): string {
    return this.nativeEvent.key;
  }

  // /** @deprecated */
  get keyCode(): number {
    return this.nativeEvent.keyCode;
  }

  // readonly location: number;
  get location(): number {
    return this.nativeEvent.location;
  }

  // readonly metaKey: boolean;
  get metaKey(): boolean {
    return this.nativeEvent.metaKey;
  }

  // readonly repeat: boolean;
  get repeat(): boolean {
    return this.nativeEvent.repeat;
  }

  // readonly shiftKey: boolean;
  get shiftKey(): boolean {
    return this.nativeEvent.shiftKey;
  }

  /** @deprecated */
  get which(): number {
    return this.nativeEvent.which;
  }
}