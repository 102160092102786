import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/material/styles';

import AspectRatioIconButton from '../AspectRatioIconButton';

import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import noSleep from '../../hooks/useNoSleep';

import { useAppController } from '../../controllers/AppController';
import { PlayMessage, PauseMessage, SetScrollSpeedMessage, /*PageUpMessage, PageDownMessage,*/ NavigateMessage } from '@fluidprompter/core';

import useConfigMessageHandlers from '../../hooks/useConfigMessageHandlers';
import useSessionMessageHandlers from '../../hooks/useSessionMessageHandlers';

const SpeedSlider = styled(Slider)({
  color: '#fff',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    transition: 'none', // Disable CSS transitions for high frequency updates.
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    transition: 'none', // Disable CSS transitions for high frequency updates.
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    color: '#333',
    backgroundColor: '#fff',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

function RemoteBody() {
  const configStore = useConfigurationStore(state => ({
    scrollSpeed: state.scrollSpeed,
    increaseSpeed: state.increaseSpeed,
    decreaseSpeed: state.decreaseSpeed,
  }), shallow);
  
  const prompterState = usePrompterSession(state => ({
    prompterMode: state.prompterMode,
    isPlaying: state.isPlaying,
    isPaused: state.isPaused,
    // scrollSpeed: state.s
    play: state.play,
    pause: state.pause,
  }), shallow);

  const appController = useAppController();

  useConfigMessageHandlers();
  useSessionMessageHandlers();

  const handleChangeSpeed = (e: Event, newValue: number | number[]) => {
    const proposedScrollSpeed = newValue as number;

    appController.dispatchMessage(new SetScrollSpeedMessage(proposedScrollSpeed));
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AspectRatioIconButton
            heightPercentage={33.33}
            onClick={() => {
              noSleep.setNoSleepEnabled(true);
              appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageUp));
            }}
          >
            <KeyboardArrowUpIcon sx={{ fontSize: '80px' }} />
          </AspectRatioIconButton>
        </Grid>
        <Grid item xs={4}>
          <AspectRatioIconButton
            onClick={() => {
              noSleep.setNoSleepEnabled(true);
              let newSpeed = useConfigurationStore.getState().scrollSpeed;
              newSpeed -= 10;
              if(newSpeed < 0) { newSpeed = 0; }
              appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
            }}
          >
            <RemoveIcon sx={{ fontSize: '80px' }} />
          </AspectRatioIconButton>
        </Grid>
        <Grid item xs={4}>
          <AspectRatioIconButton
            onClick={() => {
              noSleep.setNoSleepEnabled(true);
              appController.dispatchMessage(prompterState.isPlaying ? new PauseMessage() : new PlayMessage());
            }}
          >
            {prompterState.isPlaying ? <PauseIcon sx={{ fontSize: '80px' }} /> : <PlayArrowIcon sx={{ fontSize: '80px' }} /> }
          </AspectRatioIconButton>
        </Grid>
        <Grid item xs={4}>
          <AspectRatioIconButton
            onClick={() => {
              noSleep.setNoSleepEnabled(true);
              let newSpeed = useConfigurationStore.getState().scrollSpeed;
              newSpeed += 10;
              if(newSpeed > 500) { newSpeed = 500; }
              appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
            }}
          >
            <AddIcon sx={{ fontSize: '80px' }} />
          </AspectRatioIconButton>
        </Grid>
        <Grid item xs={12}>
          <AspectRatioIconButton
            heightPercentage={33.33}
            onClick={() => {
              noSleep.setNoSleepEnabled(true);
              appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageDown));
            }}
          >
            <KeyboardArrowDownIcon sx={{ fontSize: '80px' }} />
          </AspectRatioIconButton>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ m: 2 }}>
            <SpeedSlider
              valueLabelDisplay="auto"
              aria-label="Prompter Speed"
              min={20}
              max={500}
              value={configStore.scrollSpeed}
              onChange={handleChangeSpeed}
              onMouseDown={() => {
                noSleep.setNoSleepEnabled(true);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RemoteBody;