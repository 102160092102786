export var ScriptNodeStateChangeTypes;
(function (ScriptNodeStateChangeTypes) {
    ScriptNodeStateChangeTypes["UNKNOWN"] = "UNKNOWN";
    ScriptNodeStateChangeTypes["ENTERING_VIEWPORT"] = "enteringviewport";
    ScriptNodeStateChangeTypes["ENTERED_VIEWPORT"] = "enteredviewport";
    ScriptNodeStateChangeTypes["LEAVING_VIEWPORT"] = "leavingviewport";
    ScriptNodeStateChangeTypes["LEFT_VIEWPORT"] = "leftviewport";
    ScriptNodeStateChangeTypes["ENTERING_CUE"] = "enteringcue";
    ScriptNodeStateChangeTypes["LEAVING_CUE"] = "leavingcue";
})(ScriptNodeStateChangeTypes || (ScriptNodeStateChangeTypes = {}));
