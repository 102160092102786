import React, { useEffect, useRef } from 'react';
import { useFocused } from 'slate-react';
import { useAppController } from '../../../controllers/AppController';
import { EditorActiveMessage } from '@fluidprompter/core';
import usePrompterSession from '../../../state/PrompterSessionState';

/**
 * Detects change in the focus state of the slate editor, using the useFocused() hook.
 *
 * Will notify registered callback of changes in the slate focus state so that it can be propogated
 * outside of the Slate component context.
 * @param props
 * @returns
 */
const SlateFocusMonitor = () => {
  // const appController = useAppController();

  const focusedRef = useRef<boolean>();
  const focused = useFocused();

  useEffect(() => {
    if(focusedRef.current === focused) {
      return;
    }
    focusedRef.current = focused;

    usePrompterSession.getState().setEditorFocused(focused);

    // The focus state of Slate has changed! Did we just focus the slate editor?
    // if(focused) {
    //   appController.dispatchMessage(new EditorActiveMessage());
    // }
  }, [focused]);

  return (<></>);
};

export default SlateFocusMonitor;