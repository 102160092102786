import { useCallback, useEffect } from 'react';
import { useAppController, useMessageHandler } from '../controllers/AppController';

import DeviceHost from './DeviceHost';
import SpeedEditor from './blackmagic/speededitor';
import ShuttlePro from './contour/shuttlepro';

import { DeviceConnectionType } from './BaseDevice';
import BaseHidDevice, { HidDeviceConstructor } from './BaseHidDevice';

const hidDeviceConstructors: { [name: string]: HidDeviceConstructor } = {
  [SpeedEditor.DeviceKey]: SpeedEditor,
  [ShuttlePro.DeviceKey]: ShuttlePro,
};

const useHidDevices = (deviceHost: DeviceHost) => {

  const appController = useAppController();

  //
  // Handle a request to connect a new HID device.
  //
  useMessageHandler('devices.hid.connect', (e) => {
    e.sendToPeers = false;
    const deviceType: string = e.message.payload as string;

    console.log('GenericEvent \'devices.hid.connect\' received!');

    const callbackFn = async (err?: Error, data?: unknown) => {
      if(!navigator.hid) {
        // HID not supported on this browser.
        alert('This browser does not support HID devices. Try Chrome, Edge, or Opera.');
        return;
      }

      if(err) {
        // The current user is not logged in or does not have a sufficient account level for this
        // feature.
        return;
      }
      if(!deviceType) {
        throw new Error('deviceType required for \'devices.hid.connect\' command.');
      }

      //
      // Find our device implementation and instantiate an instance.
      //
      let deviceInstance: (BaseHidDevice | undefined) = undefined;
      try {
        const DeviceImplementation = hidDeviceConstructors[deviceType];
        if(!DeviceImplementation) {
          throw new Error('Unsupported HID device type.');
        }

        // DeviceImplementation.get

        deviceInstance = new DeviceImplementation(deviceHost);

        await deviceInstance.connect();
      } catch(error) {
        // Error
        alert('Error trying to connect HID device.\rPlease try again or contact FluidPrompter support.');

        if(deviceInstance) {
          deviceInstance.disconnect();
          deviceHost.unregisterDevice(deviceInstance);
        }
      }
    };

    // accountrequired will call our callback function to let us know if we can continue.
    appController.dispatchMessage('prompter.local.accountrequired', {
      plan: 'pro',
      featureName: 'Connect HID device',
      callback: callbackFn,
    });
  });

  //
  // Listener to navigator events for disconnected devices (device physically removed from system).
  //
  const onHidDeviceConnect = useCallback(async (e: HIDConnectionEvent) => {
    console.log('HIDConnectionEvent - connect', e);
  }, []);
  const onHidDeviceDisconnect = useCallback(async (e: HIDConnectionEvent) => {
    // console.log('HIDConnectionEvent - disconnect', e);

    // 1.) Find the device being disconnected based on the native HID device instance.
    const targetDevice = deviceHost.allDevices<BaseHidDevice>(DeviceConnectionType.Hid)
      .find((device) => device.hidDevice === e.device);

    // 2.) Execute the disconnect method!
    targetDevice?.disconnect();
  }, []);
  useEffect(() => {
    if(!navigator.hid) {
      // HID not supported on this browser.
      return;
    }

    navigator.hid.addEventListener('connect', onHidDeviceConnect);
    navigator.hid.addEventListener('disconnect', onHidDeviceDisconnect);

    return () => {
      // Cleanup
      navigator.hid.removeEventListener('connect', onHidDeviceConnect);
      navigator.hid.removeEventListener('disconnect', onHidDeviceDisconnect);
    };
  }, [onHidDeviceConnect, onHidDeviceDisconnect]);
};

export default useHidDevices;