/**
 * Represents the PageUpMessage type.
 */
export class PageUpMessage {
    constructor() {
        this.type = PageUpMessage.MESSAGE_NAME;
    }
    static fromJson() {
        return new PageUpMessage();
    }
    toJSON() {
        return { type: this.type };
    }
}
PageUpMessage.MESSAGE_NAME = 'prompter.navigate.pageup';
