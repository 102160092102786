import IconButton, { iconButtonClasses } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const PrompterIconButton = styled(IconButton)({
  transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  color: 'inherit', // #4E4E4E
  '&:hover': {
    color: '#fff',
  },
  [`&.${iconButtonClasses.disabled}`]: {
    pointerEvents: 'unset', // allow :hover styles to be triggered
    cursor: 'not-allowed', // and custom cursor can be defined without :hover state
    color: 'transparent', // The delete button is disabled when prompting.
  },  
});

export default PrompterIconButton;