import React from 'react';
import { useTranslation } from 'react-i18next';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';

import LockIcon from '@mui/icons-material/Lock';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import classNames from 'classnames';
// import './ConnectDeviceButton.scss';

export interface ConnectDeviceButtonProps {
  avatarSrc?: string | undefined
  primary?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  requiresPlanLevel?: number;
  hasPlanLevel?: number;
  requiresBluetooth?: boolean;
  hasBluetooth?: boolean;
  requiresHid?: boolean;
  hasHid?: boolean;
  priority?: number;
}
const ConnectDeviceButton = (props: ConnectDeviceButtonProps) => {
  const {
    avatarSrc, primary, onClick,
    requiresPlanLevel, hasPlanLevel,
    requiresBluetooth, hasBluetooth,
    requiresHid, hasHid,
  } = props;

  const { t } = useTranslation('common');

  let errorMsg: React.ReactNode = undefined;
  if(requiresPlanLevel !== undefined
    && (!hasPlanLevel || (requiresPlanLevel > (hasPlanLevel || 0)))
  ) {
    // The user doesn't have a good enough plan to connect this device.
    switch(requiresPlanLevel) {
      case 0:
        errorMsg = `*${t('plans.requiresPlanLevel0')}`;
        break;
      case 1:
        errorMsg = <><LockIcon sx={{ fontSize: '1em', verticalAlign: 'text-bottom' }} />&nbsp;{t('plans.requiresPlanLevel1')}</>;
        // TODO: Use a CTA like "click for more info" for locked features when we have a dialog available to explain the upsell and ask for the upsell.
        // errorMsg = <><LockIcon sx={{ fontSize: '1em', verticalAlign: 'text-bottom' }} />&nbsp;Click for more info{/*t('plans.requiresPlanLevel1')*/}</>;
        break;
      case 2:
        errorMsg = <><LockIcon sx={{ fontSize: '1em', verticalAlign: 'text-bottom' }} />&nbsp;{t('plans.requiresPlanLevel2')}</>;
        break;
    }
  }

  if(!errorMsg && requiresBluetooth && !hasBluetooth) {
    errorMsg = `*${t('capabilities.bluetoothRequired')}`;
  }

  if(!errorMsg && requiresHid && !hasHid) {
    errorMsg = `*${t('capabilities.hidRequired')}`;
  }

  return (
    <ListItemButton
      className={classNames('ConnectDeviceButton', { 'unavailable': errorMsg })}
      onClick={onClick}
    >
      <ListItemAvatar>
        <Avatar src={avatarSrc} variant="square" />
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        primaryTypographyProps={{
          fontSize: '1.1em'
        }}
        secondary={errorMsg}
        secondaryTypographyProps={{
          fontStyle: 'italic',
          // color: 'error'
        }}
      />
      {/* <ListItemSecondaryAction style={{
        height: '24px',
        color: '#1976DC',
        textDecoration: 'underline'
      }}>
        Info<ChevronRightIcon sx={{ verticalAlign: 'middle' }} />
      </ListItemSecondaryAction> */}
    </ListItemButton>
  );
};

export default ConnectDeviceButton;