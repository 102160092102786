import React, { useState } from 'react';
import AccountRequiredDialogRenderer from './AccountRequiredDialogRenderer';
import { MessageHandlerEvent, useMessageHandler } from '../../controllers/AppController';
import { GenericMessage } from '@fluidprompter/core';
import useApplicationContext from '../../hooks/useApplicationContext';

export type AccountRequiredCallback = (err: Error | undefined, data: unknown) => void;

interface AccountRequiredInfo {
  plan: string,
  featureName?: string,
}
export interface AccountRequiredArgs extends AccountRequiredInfo {
  callback?: AccountRequiredCallback,
}

const convertPlanStringToPlanLevel = (plan?: string) => {
  let currentPlanLevel = 0;
  switch(plan?.toLocaleLowerCase()) {
    default:
    case 'free':
      currentPlanLevel = 0;
      break;
    case 'pro':
      currentPlanLevel = 1;
      break;
    case 'studio':
      currentPlanLevel = 2;
      break;
  }
  return currentPlanLevel;
};

const AccountRequiredDialog = () => {

  const {
    appContext,
  } = useApplicationContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [requiredPlan, setRequiredPlan] = useState<AccountRequiredInfo>({
    plan: 'free',
    featureName: undefined,
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAccountRequired = React.useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    const args: AccountRequiredArgs = e.message.payload as AccountRequiredArgs;
    if(!args) {
      return;
    }

    const { plan, featureName } = args;
    const minimumPlan = plan || 'free';
    setRequiredPlan({
      plan: minimumPlan,
      featureName,
    });

    //
    // Something we are about to do requires an Account.
    //
    // If we aren't logged in, show a dialog that prompts for Login OR Create Account.
    //
    if(!appContext.isAuthenticated) {
      setIsOpen(true);
      return;
    }

    //
    // If we are logged in, check the account level is equal to or greater than the required
    // account level. If not, prompt for account upgrade.
    //
    const minimumPlanLevel = convertPlanStringToPlanLevel(minimumPlan);
    const currentPlanLevel = convertPlanStringToPlanLevel(appContext.userProfile?.fluidprompter_plan);
    if(currentPlanLevel < minimumPlanLevel) {
      setIsOpen(true);
      return;
    }

    //
    // If we get here, the user is authenticated and has a sufficient plan level.
    //
    const callbackInstance = args?.callback;
    if(callbackInstance) {
      callbackInstance(undefined, true);
    }
  }, [appContext]);
  useMessageHandler('prompter.local.accountrequired', handleAccountRequired);

  return (<>
    {isOpen && <AccountRequiredDialogRenderer minimumPlan={requiredPlan.plan} featureName={requiredPlan.featureName} handleClose={handleClose} />}
  </>);
};

export default AccountRequiredDialog;