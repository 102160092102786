import { useCallback } from 'react';
import { useMessageHandler } from '../../controllers/AppController';

import { Editor, Transforms, Range, Descendant, Point, Text } from 'slate';
import { PrompterEditor, ElementTypes, PrompterElement, PrompterSegment } from '../../models/EditorTypes';

import usePrompterSession from '../../state/PrompterSessionState';

const useEditorSplitSegmentCommandHandler = (editor: PrompterEditor) => {

  const doEditorSplitSegment = useCallback(() => {
    // Split the current prompter segment.
    if (!usePrompterSession.getState().isEditing) {
      // We can't do anything unless we are currently editing a segment.
      return;
    }

    //
    // If we have a range selection, first delete this range before performing the split.
    //
    if (editor.selection && Range.isExpanded(editor.selection)) {
      // If we have a range selection, first delete this range.
      Transforms.delete(editor);
    }

    //
    // To help us make better decisions about splitting a prompter segment, let's first find the 
    // prompter segment the current selection is a part of.
    //
    if(editor.selection && Range.isCollapsed(editor.selection) && editor.selection.anchor.path.length > 1) {
      const segmentNodeEntry = Editor.above<PrompterSegment>(editor, {match: n => (n as PrompterElement).type === ElementTypes.SCRIPT_SEGMENT});
      if(!segmentNodeEntry) {
        // Couldn't find a PrompterSegment wrapping the current selection. 
        // This means we may have the StartElement or EndElement or a PauseElement selected.
        return true;
      }

      const [segmentNode, segmentPath] = segmentNodeEntry;
      const segmentStart = Editor.start(editor, segmentPath);
      const isAtSegmentStart = Point.equals(segmentStart, editor.selection.anchor);
      if(isAtSegmentStart) {
        // Insert a new PrompterSegment BEFORE this PrompterSegment
        const proposedNode: Descendant = {
          type: ElementTypes.SCRIPT_SEGMENT,
          children: [{
            type: ElementTypes.PARAGRAPH,
            children: [{
              text: ''
            }]
          }]
        };

        Transforms.insertNodes<Descendant>(editor, proposedNode, {
          at: segmentPath,
        });
        Transforms.select(editor, segmentPath);

        return true;
      }

      const segmentEnd = Editor.end(editor, segmentPath);
      const isAtSegmentEnd = Point.equals(segmentEnd, editor.selection.anchor);
      if(isAtSegmentEnd) {
        // Insert a new PrompterSegment AFTER this PrompterSegment
        const proposedNode: Descendant = {
          type: ElementTypes.SCRIPT_SEGMENT,
          children: [{
            type: ElementTypes.PARAGRAPH,
            children: [{
              text: ''
            }]
          }]
        };

        // Insert the proposedNode after the current node.
        const currentNodeIndex = segmentPath.shift();
        if(currentNodeIndex !== undefined) {
          segmentPath.unshift(currentNodeIndex + 1);
        }

        Transforms.insertNodes<Descendant>(editor, proposedNode, {
          at: segmentPath,
        });
        Transforms.select(editor, segmentPath);

        return true;
      }
    }

    //
    // If we are on an empty line (empty paragraph), then delete the empty line before performing 
    // the split.
    //
    if (
      editor.selection
      && Range.isCollapsed(editor.selection)
      && (editor.selection.anchor.offset === 0)
    ) {
      // We are at the very start of a node with a collapsed selection. 
      const [currentNode] = Editor.node(editor, editor.selection);

      // First check if this is an empty leaf node.
      if (Text.isText(currentNode) && currentNode.text.length === 0) {

        // Now check if the parent of this leaf node is a Paragraph, with no other children.
        const [parentNode, paragraphPath] = Editor.parent(editor, editor.selection);
        const parentNodeAsPrompterElement = parentNode as PrompterElement;
        if(parentNodeAsPrompterElement.type === ElementTypes.PARAGRAPH && parentNodeAsPrompterElement.children.length <= 1) {
          // The parent is a paragraph with no other children, delete it before split!
          Transforms.delete(editor, { at: paragraphPath });
        }  
      }
    }

    //
    // Now split the ScriptSegment node at the selection anchor.
    //
    Transforms.splitNodes(editor, {
      at: editor.selection?.anchor,
      match: (node, path) => {
        return (node as PrompterElement).type === ElementTypes.SCRIPT_SEGMENT;
      }
    });
  }, [editor]);

  useMessageHandler('prompter.editor.segment.split', doEditorSplitSegment);

};

export default useEditorSplitSegmentCommandHandler;