import React, { useEffect } from 'react';
import { useAppController, useMessageHandler, MessageHandlerEvent } from '../controllers/AppController';
import { GenericMessage } from '@fluidprompter/core';

// import useConfigurationStore from '../state/ConfigurationStore';
import useBluetoothState from '../state/BluetoothState';
// import usePrompterSession from '../state/PrompterSessionState';

import useInputMiddleware from '../hooks/useInputMiddleware';

import DeviceHost from './DeviceHost';

import { AppBluetoothEventType } from './BluetoothProviders';
import BaseRemote, { BluetoothRemoteConstructor } from './BaseRemote';
import { DeviceConnectingEvent } from './events/DeviceConnectingEvent';
import { DeviceConnectedEvent } from './events/DeviceConnectedEvent';
import { DeviceDisconnectedEvent } from './events/DeviceDisconnectedEvent';
import { DeviceReportEvent } from './events/DeviceReportEvent';

import AirturnRemote from './airturn/AirTurnRemote';
import YCOnionRemote from './yconion/YCOnionRemote';
import NeewerRT110Remote from './neewer/NeewerRT110Remote';
import MomanFS1Pedal from './moman/MomanFS1Pedal';

import { ConnectionState } from '@fluidprompter/core';
// import { SetScrollSpeedMessage, ResetMessage } from '@fluidprompter/core';

const bleDeviceConstructors: { [name: string]: BluetoothRemoteConstructor } = {
  [AirturnRemote.DeviceKey]: AirturnRemote,
  [YCOnionRemote.DeviceKey]: YCOnionRemote,
  [NeewerRT110Remote.DeviceKey]: NeewerRT110Remote,
  [MomanFS1Pedal.DeviceKey]: MomanFS1Pedal,
};

interface AvailabilityChangedEvent extends Event {
  readonly value?: boolean;
}
interface DisconnectDeviceRequest {
  readonly deviceId: string;
}

const useBleDevices = (deviceHost: DeviceHost) => {

  const appController = useAppController();
  const bluetoothState = useBluetoothState();

  // TODO send an event to our useInputHook
  const {
    dispatchRemoteEvent
  } = useInputMiddleware();

  const connectNewDevice = React.useCallback(async function (deviceType: string) {

    const onDeviceConnecting = async (e: DeviceConnectingEvent) => {
      const device = e.device as BaseRemote;
      bluetoothState.addDevice(device);

      // bluetoothState.checkBluetoothConnected();
    };

    const onDeviceConnected = async (e: DeviceConnectedEvent) => {
      const device = e.device as BaseRemote;
      bluetoothState.addDevice(device);

      // bluetoothState.checkBluetoothConnected();
    };

    const onDeviceDisconnected = async (e: DeviceDisconnectedEvent) => {
      const device = e.device as BaseRemote;
      bluetoothState.checkBluetoothConnected();

      if(device && device.connectionState !== ConnectionState.Reconnecting) {
        device.disconnect();
        bluetoothState.removeDevice(device.id);
      }
    };

    const onDeviceStatusUpdate = async (e: DeviceReportEvent) => {
      // Something about this device changed like battery level or charging status. We want to
      // trigger a UI re-render when the device updates.
      const device = e.device as BaseRemote;
      bluetoothState.addDevice(device);
    };

    /*
    const onInputEvent = async (event: string) => {

      switch(event) {
        case 'ok.down': {
          const isPlaying = usePrompterSession.getState().isPlaying;
          // appController.dispatchMessage(isPlaying ? new PauseMessage() : new PlayMessage());

          //
          // interface EventInit {
          //     bubbles?: boolean;
          //     cancelable?: boolean;
          //     composed?: boolean;
          // }
          //
          //
          // interface UIEventInit extends EventInit {
          //     detail?: number;
          //     view?: Window | null;
          //     /** @deprecated *-/
          //     which?: number;
          // }
          //
          //
          // interface EventModifierInit extends UIEventInit {
          //     altKey?: boolean;
          //     ctrlKey?: boolean;
          //     metaKey?: boolean;
          //     modifierAltGraph?: boolean;
          //     modifierCapsLock?: boolean;
          //     modifierFn?: boolean;
          //     modifierFnLock?: boolean;
          //     modifierHyper?: boolean;
          //     modifierNumLock?: boolean;
          //     modifierScrollLock?: boolean;
          //     modifierSuper?: boolean;
          //     modifierSymbol?: boolean;
          //     modifierSymbolLock?: boolean;
          //     shiftKey?: boolean;
          // }
          //
          //
          // interface KeyboardEventInit extends EventModifierInit {
          //     /** @deprecated *-/
          //     charCode?: number;
          //     code?: string;
          //     isComposing?: boolean;
          //     key?: string;
          //     /** @deprecated *-/
          //     keyCode?: number;
          //     location?: number;
          //     repeat?: boolean;
          // }
          //
          dispatchRemoteEvent(new KeyboardEvent('keydown', {
            cancelable: true,
            key: 'Enter',
            keyCode: 13,
            which: 13,
            code: 'Enter',
          }));
          break;
        }
        case 'ok.longPress': {
          appController.dispatchMessage(new ResetMessage());
          break;
        }
        case 'left.down': {
          let newSpeed = useConfigurationStore.getState().scrollSpeed;
          newSpeed -= 10;
          if(newSpeed < 0) { newSpeed = 0; }
          appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
          break;
        }
        case 'left.longPress': {
          let newSpeed = useConfigurationStore.getState().scrollSpeed;
          newSpeed -= 12;
          if(newSpeed < 0) { newSpeed = 0; }
          appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
          break;
        }
        case 'left.longPressRepeat': {
          let newSpeed = useConfigurationStore.getState().scrollSpeed;
          newSpeed -= 15;
          if(newSpeed < 0) { newSpeed = 0; }
          appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
          break;
        }
        case 'right.down': {
          let newSpeed = useConfigurationStore.getState().scrollSpeed;
          newSpeed += 10;
          if(newSpeed > 500) { newSpeed = 500; }
          appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
          break;
        }
        case 'right.longPress': {
          let newSpeed = useConfigurationStore.getState().scrollSpeed;
          newSpeed += 12;
          if(newSpeed > 500) { newSpeed = 500; }
          appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
          break;
        }
        case 'right.longPressRepeat': {
          let newSpeed = useConfigurationStore.getState().scrollSpeed;
          newSpeed += 15;
          if(newSpeed > 500) { newSpeed = 500; }
          appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
          break;
        }
        case 'up.down': {
          dispatchRemoteEvent(new KeyboardEvent('keydown', {
            cancelable: true,
            key: 'ArrowUp',
            keyCode: 38,
            which: 38,
            code: 'ArrowUp',
          }));
          break;
        }
        case 'down.down': {
          dispatchRemoteEvent(new KeyboardEvent('keydown', {
            cancelable: true,
            key: 'ArrowDown',
            keyCode: 40,
            which: 40,
            code: 'ArrowDown',
          }));
          break;
        }

        default:
          break;
      }

    };
    */

    const startNewConnect = async (deviceType: string) => {

      let deviceInstance: (BaseRemote | undefined) = undefined;

      try {
        bluetoothState.setBluetoothConnecting(true);

        const DeviceImplementation = bleDeviceConstructors[deviceType];
        if(!DeviceImplementation) {
          throw new Error('Unsupported device type.');
        }
        deviceInstance = new DeviceImplementation(deviceHost);
        bluetoothState.addDevice(deviceInstance);

        // Attach listeners for when this specific device is connected/disconnected, etc.
        deviceInstance.addListener('connecting', onDeviceConnecting);
        deviceInstance.addListener('connected', onDeviceConnected);
        deviceInstance.addListener('disconnected', onDeviceDisconnected);
        deviceInstance.addListener('devicereport', onDeviceStatusUpdate);

        // deviceInstance.addListener('inputevent', onInputEvent);

        // Ok now let's start the actual connection!
        await deviceInstance.connect();
      } catch(error) {
        // Particularly on a mobile device like Android phone in Chrome, if Chrome doesn't have the
        // "Location" permission, then BLE scanning will throw a "NotFoundError: User denied the
        // browser permission to scan for Bluetooth devices."
        alert('Error trying to connect bluetooth remotes.\r1.) You may have cancelled the bluetooth pairing dialog.\r2.) Bluetooth may be turned off on your device.\r3.) This browser may not support bluetooth (Safari/Firefox).\r4.) Otherwise check your browser app permissions.');

        console.log('Argh! ' + error);

        if(deviceInstance) {
          deviceInstance.disconnect();
          bluetoothState.removeDevice(deviceInstance.id);
        }
      } finally {
        bluetoothState.setBluetoothConnecting(false);
      }
    };

    // Start our async function that will cover the whole connection process start-to-finish.
    startNewConnect(deviceType);
  }, [appController, deviceHost, bluetoothState, dispatchRemoteEvent]);

  useMessageHandler('devices.bluetooth.connect', (e) => {
    e.sendToPeers = false;
    const deviceType = e.message.payload as string;

    const callbackFn = (err?: Error) => {
      if(err) {
        // The current user is not logged in or does not have a sufficient account level for this
        // feature.
        return;
      }
      if(!deviceType) {
        throw new Error('deviceType required for \'devices.bluetooth.connect\' command.');
      }
      connectNewDevice(deviceType);
    };

    // accountrequired will call our callback function to let us know if we can continue.
    appController.dispatchMessage('prompter.local.accountrequired', {
      plan: 'pro',
      featureName: 'Connect Bluetooth remote control',
      callback: callbackFn,
    });
  });

  //
  // Handler implementation for any request to disconnect a currently connected bluetooth device.
  //
  const disconnectDevice = React.useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    e.sendToPeers = false;
    const args = e.message.payload as DisconnectDeviceRequest;
    if(!args) {
      throw new Error('Invalid arguments supplied for \'devices.bluetooth.disconnect\' event.');
    }

    // 1. Retrieve the bluetooth device entry from our bluetooth state store.
    const deviceInstance = bluetoothState.getDevice(args.deviceId);
    if(!deviceInstance) {
      return;
    }

    // 2. Disconenct the BLE connection
    deviceInstance.disconnect();

    // 3. Remove from Bluetooth State
    bluetoothState.removeDevice(args.deviceId);

    console.log(`Request to disconnect bluetooth device with id ${args?.deviceId}`);
  }, [bluetoothState]);
  useMessageHandler('devices.bluetooth.disconnect', disconnectDevice);

  //
  // Attach global/root listeners to webbluetooth events in the browser.
  //
  useEffect(() => {
    // Subscribe to future notifications of the bluetooth becoming available/unavailable.
    deviceHost.BluetoothProvider.addEventListener('*', AppBluetoothEventType.AvailabilityChangedEvent, (event: AvailabilityChangedEvent) => {
      console.log(`> Bluetooth is ${event.value ? 'available' : 'unavailable'}`);
      bluetoothState.setBluetoothAvailable(event.value === true);
    });

    // Check bluetooth availability at this exact point in time (depends if bluetooth is enabled on your device, can be toggled later while we are running).
    const getBluetoothAvailable = async () => {
      let isBluetoothAvailable = false;

      const bluetoothProviderState = await deviceHost.BluetoothProvider.getState();
      if (bluetoothProviderState === 'PoweredOn' || bluetoothProviderState === 'PoweredOff') {
        isBluetoothAvailable = true;
      }

      bluetoothState.setBluetoothAvailable(isBluetoothAvailable);
    };
    getBluetoothAvailable();
  }, []); // Make sure this iseEffect runs only once
};

export default useBleDevices;